import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";

export const AppointmentTypeReasonsEditForm = ({
  modeToEdit,
  allOrgReasons,
  servicesToModeMap,
  handleServiceChange,
}) => {
  return (
    <>
      {allOrgReasons.map((reasonObj) => (
        <FormControlLabel
          control={
            <Checkbox
              color={"primary"}
              checked={
                servicesToModeMap[modeToEdit.id] &&
                servicesToModeMap[modeToEdit.id].includes(reasonObj.name.trim())
              }
              onChange={() =>
                handleServiceChange(
                  servicesToModeMap,
                  modeToEdit.id,
                  reasonObj.name
                )
              }
              name={reasonObj.name}
            />
          }
          label={reasonObj.name}
        />
      ))}
    </>
  );
};
