import { Grid, IconButton, useMediaQuery } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
// Dialog box
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CloseIcon from "@material-ui/icons/Close";
import EventIcon from "@material-ui/icons/Event";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import MenuIcon from "@material-ui/icons/Menu";
//icons
import PeopleIcon from "@material-ui/icons/People";
import RoomIcon from "@material-ui/icons/Room";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import TuneIcon from "@material-ui/icons/Tune";
import VideocamIcon from "@material-ui/icons/Videocam";
import WebIcon from "@material-ui/icons/Web";
import clsx from "clsx";
import json2mq from "json2mq";
//vertical tabs
import PropTypes from "prop-types";
import React from "react";
//routes
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
//useContext
import GlobalContext from "../context/GlobalContext";
//logo
import portonLogo from "../img/porton.png";
import AccountProfilePage from "../pages/AccountProfilePage";
import AppSettingPage from "../pages/AppSettingPage";
import Calendar from "../pages/CalendarPage/patientCalendar";
import HelpPage from "../pages/HelpPage";
import Tour from "../pages/HelpPage/component/Tour";
//pages
import ManageUserPage from "../pages/ManageUserPage";
import PatientActions from "../pages/PatientActions/PatientActions";
import PatientQueue from "../pages/PatientQueue";
// import CreateAccountPage from "../pages/PlugInAccount/CreateAccountPage";
import SettingPage from "../pages/SettingPage";
import ToolsPage from "../pages/ToolsPage";
import notificationSound from "../sound/paitent-successfully-checks-in.mp3";
import PlaySound from "../sound/soundPlay";
import settingsStyles from "../styles/DrawerMenuStyles";
import ChangePass from "./changePass";
import ForgotPass from "./ForgotPass";
import LoginPage from "./LoginPage";
// import CreateUserAccountPage from "../pages/CreateUserAccount/CreateUserAccountPage";
import GetPatientReports from "../pages/GetPatientReports/GetPatientReports";

//For vertical tabs

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#767676",
    fontWeight: 550,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
      color: "#003FBA",
    },
    "&:hover": {
      opacity: 1,
    },
    alignItems: "left",
    "&$selected": {
      color: "#003FBA",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

let firstRun = true;

export default function PermanentDrawerLeft(props) {
  const classes = settingsStyles();
  const global = React.useContext(GlobalContext);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dropdown, setDropdown] = React.useState(false);
  const [runToken, setRunToken] = React.useState(false);
  const [revealContainer, setRevealContainer] = React.useState(true);
  const [listening, setListening] = React.useState(false);
  const [state, setState] = React.useState({});
  const [profile, setProfile] = React.useState(false);
  const [menuItems, setMenuItems] = React.useState([
    {
      name: "Help",
      url: "/help",
      icon: <HelpOutlineIcon className={classes.icon} />,
      child: <HelpPage />,
    },
    {
      name: "Apps",
      url: "/appsetting",
      icon: <WebIcon className={classes.icon} />,
      child: <AppSettingPage />,
    },
    {
      name: "Calendar",
      url: "/Calendar",
      icon: <InsertInvitationIcon className={classes.icon} />,
    },
    {
      name: "Queues",
      url: "/patientqueue",
      icon: <img className={classes.icon} src={require("../img/queues.svg")} />,
      child: <PatientQueue />,
    },
    {
      name: "Users",
      url: "/users",
      icon: <PeopleIcon className={classes.icon} />,
      child: <ManageUserPage />,
    },
    {
      disabled: true,
      name: "Partnership",
      url: "/partnership",
      icon: (
        <img
          className={classes.icon}
          src={require("../img/partnerships.svg")}
        />
      ),
      clild: <div>partnership</div>,
    },
    {
      disabled: true,
      name: "Report",
      url: "/Report",
      icon: <AssignmentIcon className={classes.icon} />,
    },
    {
      name: "Tools",
      url: "/Tools",
      icon: <ScreenShareIcon className={classes.icon} />,
    },
    {
      name: "Settings",
      url: "/settings",
      icon: <TuneIcon className={classes.icon} />,
      chlid: <SettingPage />,
    },
  ]);
  const [moaMenuItems] = React.useState([
    {
      name: "Help",
      url: "/help",
      icon: <HelpOutlineIcon className={classes.icon} />,
      child: <HelpPage />,
    },
    {
      name: "Apps",
      url: "/appsetting",
      icon: <WebIcon className={classes.icon} />,
      child: <AppSettingPage />,
    },
    {
      disabled: true,
      name: "Calendar",
      url: "/Calendar",
      icon: <InsertInvitationIcon className={classes.icon} />,
    },
    {
      name: "Queues",
      url: "/patientqueue",
      icon: <img className={classes.icon} src={require("../img/queues.svg")} />,
      child: <PatientQueue />,
    },
    {
      name: "Users",
      url: "/users",
      icon: <PeopleIcon className={classes.icon} />,
      child: <ManageUserPage />,
    },
    {
      name: "Settings",
      url: "/settings",
      icon: <TuneIcon className={classes.icon} />,
      chlid: <SettingPage />,
    },
  ]);
  const [patientMenuItems] = React.useState([
    {
      disabled: true,
      name: "Calendar",
      url: "/Calendar",
      icon: <InsertInvitationIcon className={classes.icon} />,
    },
    {
      disabled: true,
      name: "Report",
      url: "/Report",
      icon: <AssignmentIcon className={classes.icon} />,
    },
    {
      name: "Settings",
      url: "/settings",
      icon: <TuneIcon className={classes.icon} />,
      chlid: <SettingPage />,
    },
  ]);
  const [doctorMenuItems] = React.useState([
    {
      disabled: true,
      name: "Calendar",
      url: "/Calendar",
      icon: <InsertInvitationIcon className={classes.icon} />,
    },
    {
      name: "Queues",
      url: "/patientqueue",
      icon: <img className={classes.icon} src={require("../img/queues.svg")} />,
      child: <PatientQueue />,
    },
    {
      name: "Users",
      url: "/users",
      icon: <PeopleIcon className={classes.icon} />,
      child: <ManageUserPage />,
    },
    {
      disabled: true,
      name: "Partnership",
      url: "/partnership",
      icon: (
        <img
          className={classes.icon}
          src={require("../img/partnerships.svg")}
        />
      ),
      clild: <div>partnership</div>,
    },
    {
      disabled: true,
      name: "Report",
      url: "/Report",
      icon: <AssignmentIcon className={classes.icon} />,
    },
    {
      name: "Settings",
      url: "/settings",
      icon: <TuneIcon className={classes.icon} />,
      chlid: <SettingPage />,
    },
  ]);
  const [appMenuItems] = React.useState([
    {
      name: "BookApp",
      url: "/bookApp",
      icon: <EventIcon className={classes.icon} />,
    },
    {
      name: "CheckInApp",
      url: "/checkin",
      icon: <RoomIcon className={classes.icon} />,
    },
    {
      name: "MeetApp",
      url: "/meetApp",
      icon: <VideocamIcon className={classes.icon} />,
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("false");
  const [headerTitle, setHeaderTitle] = React.useState("");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (!DetectIOS()) {
    Notification.permission !== "granted" &&
      Notification.permission !== "denied" &&
      Notification.requestPermission();
  }

  const smallScreenView = useMediaQuery(json2mq({ maxWidth: 960 }));
  //check if there are token already in the db, if there is, set the status to login
  React.useEffect(() => {
    setLoading(true);
    let localToken = localStorage.getItem("token");
    if (localToken) {
      global.api.tokenLogin();
      setRunToken(true);
    }
    if (window.location.pathname.split("/")[1] === "changePassword") {
      setRunToken(false); // fixes changepassword infinite loading that can occur
    }
    setLoading(false);
  }, [global.api]);
  React.useEffect(() => {
    if (smallScreenView) {
      setRevealContainer(false);
    } else {
      setRevealContainer(true);
    }
  }, [smallScreenView]);
  const toggleExpand = (index) => {
    let tempElement = menuItems[index];
    if (tempElement.displayChildren) {
      tempElement.displayChildren = false;
    } else {
      tempElement.displayChildren = true;
    }
  };

  const expandDropdown = () => {
    setDropdown(!dropdown);
  };

  const getBookAppEnv = async () => {
    let bookEnvVar;
    try {
      bookEnvVar = await global.api.GetEnvVariable({ name: "book-app" });
      bookEnvVar && window.location.assign(`${bookEnvVar.data.res}/${state.whiteLabelUrl}`);
    } catch (error) {
      // console.error(error.response ? error.response.data : error);
    }
  };

  const getCheckinAppEnv = async () => {
    let checkinEnvVar;
    try {
      checkinEnvVar = await global.api.GetEnvVariable({ name: "checkin-app" });
      checkinEnvVar && window.location.assign(
        `${checkinEnvVar.data.res}/${state.whiteLabelUrl}`
      );
    } catch (error) {
      // console.error(error.response ? error.response.data : error);
    }
  };

  const getvirtualAppEnv = async () => {
    let virtualEnvVar;
    try {
      virtualEnvVar = await global.api.GetEnvVariable({ name: "virtual-app" });
      virtualEnvVar && window.location.assign(
        `${virtualEnvVar.data.res}/${
          state.whiteLabelUrl
        }?token=${localStorage.getItem("token")}`
      );
    } catch (error) {
      // console.error(error.response ? error.response.data : error);
    }
  };

  const redirectApp = (index) => {
    switch (index) {
      case 0:
        getBookAppEnv();
        break;
      case 1:
        getCheckinAppEnv();
        break;
      default:
        getvirtualAppEnv();
        break;
    }
  };

  let orgLogo;
  React.useEffect(() => {
    const orgId = global.state.selectedOrg._id;
    orgLogo = global.state.selectedOrg.logo;

    if (orgId && !listening) {
      let audio;

      if (!DetectIOS()) {
        audio = new Audio(notificationSound);
        audio.mute = true;
      }

      if (localStorage.getItem("clientConnectionId") === null) {
        localStorage.setItem("clientConnectionId", uuidv4());
      }

      const connId = localStorage.getItem("clientConnectionId");

      let PortonAdminAppBaseURL = process.env.REACT_APP_PORTON_ADMIN_API;
      if (!process.env.REACT_APP_PORTON_ADMIN_API) {
        PortonAdminAppBaseURL = "/api";
      }

      const events = new EventSource(
        `${PortonAdminAppBaseURL}/appointment/subscribeToNotificationStream?orgId=${orgId}&connId=${connId} 
            `
      );

      events.onmessage = (event) => {
        const notification = JSON.parse(event.data);
        if (!notification.ping) {
          if (
            notification.call === "updateAppointment" ||
            notification.call === "updateStatus"
          ) {
            global.setCount(!global.count);
          }

          if (notification.call === "updateStatus") {
            const currentOrg = global?.state?.selectedOrg
              ? global.state.selectedOrg
              : global.state.defaultOrg;
            const account_settings = currentOrg.account_settings ?? {};
            const notification_settings = account_settings.notification ?? {};

            if (
              global.state.selectedOrg &&
              global.state.selectedOrg._id === notification.orgId &&
              notification.name &&
              notification_settings.browserEnabled
            ) {
              const message = `Patient ${notification.name} has checked in`;
              notify(message, audio);
              showSnackBar({
                patientName: notification.name,
                appointmentId: notification.appointmentId,
              });
            }
          }
        }
      };
      setListening(true);
    }
    fetchAppInfo();

    if (
      global.state.user &&
      firstRun &&
      (global.state.user.document.roles[0] === "patient" ||
        global.state.user.document.roles[0] === "doctor")
    ) {
      firstRun = false;
      setValue(1);
    }
  }, [global.state, global.count, listening]);

  React.useEffect(() => {
    if (value !== window.location.pathname) {
      setValue(window.location.pathname);
    }
  });

  React.useEffect(() => {
    let element = document.getElementById("sidebar-tab-help");
    if (element) {
      element.scrollIntoView();
    }
  }, [dropdown]);
  const fetchAppInfo = async () => {
    let org = {};
    if (
      global.state.selectedOrg &&
      Object.keys(global.state.selectedOrg).length !== 0
    ) {
      org = global.state.selectedOrg;
    } else if (
      global.state.defaultOrg &&
      Object.keys(global.state.defaultOrg).length !== 0
    ) {
      org = global.state.defaultOrg;
    }

    let whiteLabelURL = org.whiteLabelURL;
    setState({ ...state, whiteLabelUrl: whiteLabelURL });
  };

  const showSnackBar = async ({ patientName = "" }) => {
    // show the snack bar
    const message = `Patient ${patientName} has checked in`;
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // this function sends the notification to the OS
  const notify = (message, audio) => {
    if (!("Notification" in window) || DetectIOS()) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      var notification = new Notification(message);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          var notification = new Notification(message);
        }
      });
    }

    if (!DetectIOS) {
      // Play a notification check-in sound
      audio.mute = false;
      audio.play().catch((err) => {
        /*
                Edge case: audio is not allowed to "auto-play" without the user interacting with the DOM first.
                Render a popup that allows DOM interaction, then play the audio.
                */
        handleDialogOpen();
      });
    }
  };

  function DetectIOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  const handleLogin = async () => {
    setOpen(true);
  };

  const handleLogout = async () => {
    // function calls the logout api and sets the defaultOrg to an empty object. then it reloads the page so the changes take place. when reload it will redirect you to the login page
    await global.api.logout();
    global.state.deafultOrg = {};
    window.location.reload();
  };

  const handleMenuClick = () => {
    setRevealContainer((prev) => !prev);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    if (!DetectIOS) {
      const audio = new Audio(notificationSound);
      audio.play();
    }
  };


  React.useEffect(() => {
    if (window.location.pathname === "/account") {
      setProfile(true);
    } else {
      setProfile(false);
    }
  }, [window.location.pathname]);

  if (
    global.state.user &&
    (global.state.user.document.roles[0] === "oa" ||
      global.state.user.document.roles[0] === "doctor" ||
      global.state.user.document.roles[0] === "patient" ||
      global.state.user.document.roles[0] === "healthOrgAdmin")
  ) {
    global.state.selectedOrg = global.state.defaultOrg;
  }

  if (Object.keys(global.state.defaultOrg).length === 0 && runToken) {
    //if the defaultOrg object is empty BUT the runToken state is true then load
    return (
      <div>
        <CssBaseline />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="90vh"
        >
          <CircularProgress
            style={{ margin: "10px", color: "#1766b3" }}
            size={70}
          />
        </Box>
      </div>
    );
  } else if (Object.keys(global.state.defaultOrg).length === 0) {
    // if the defaultOrg object is empty then redirect to login page
    return (
      <div>
        <CssBaseline />
        <Router>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/forgotPassword">
              <ForgotPass />
            </Route>
            {/*<Route path="/plugInCreateAccount">*/}
            {/*  <CreateAccountPage />*/}
            {/*</Route>*/}
            <Route path="/changePassword/:resetToken">
              <ChangePass />
            </Route>
            {/*<Route path="/patient/:orgId/:patientHin">*/}
            {/*  <PatientActions />*/}
            {/*</Route>*/}
            {/*<Route path="/createAccount/:paymentId/:planType">*/}
            {/*  <CreateAccountPage />*/}
            {/*</Route>*/}

            <Route path="/getReport/:orgName/:orgId/:hashedId/:sessionId">
              <GetPatientReports />
            </Route>
            {/*<Route path="/createUserAccount">*/}
            {/*  <CreateUserAccountPage />*/}
            {/*</Route>*/}

            <Route path="/">
              {window.location.href.includes("oauth_verifier") &&
              window.location.href.includes("oauth_token") ? (
                <Redirect
                  to={{
                    pathname: "/settings",
                    state: { prevUrl: window.location.href },
                  }}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    search: `?next=${window.location.pathname}`,
                  }}
                />
              )}
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }

  const closeTour = () => {
    global.setTourOpen(false);
  };

  const params = new URLSearchParams(window.location.search);
  const nextPath = params.get("next");

  return (
    <Router>
      <div className={classes.root}>
        <div>
          {snackbarOpen && <PlaySound url={notificationSound} />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={snackbarOpen}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleSnackbarClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Notifications"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Patient check-in. Please click OK to enable sound on your
                browser.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <CssBaseline />

        <Drawer
          className={classes.drawer}
          variant={smallScreenView ? "temporary" : "permanent"}
          open={revealContainer}
          onClose={handleMenuClick}
          classes={{
            paper: clsx(classes.drawerPaper),
          }}
          anchor="left"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "3rem" }}
          >
            {global.state.login && (
              <img
                className={classes.logoImage}
                src={
                  global.state.selectedOrg && global.state.selectedOrg.logo
                    ? global.state.selectedOrg.logo
                    : global.state.defaultOrg.logo
                    ? global.state.defaultOrg.logo
                    : portonLogo
                }
                alt="portonhealth-logo"
              />
            )}
          </Box>
          {/*<Box>*/}
          {/*  {global.state.user &&*/}
          {/*  global.state.user.document.roles[0] === "oa" ? (*/}
          {/*    <Typography className={classes.accountName}>*/}
          {/*      {global.state.user.document.contact_name_enc*/}
          {/*        ? global.state.user.document.contact_name_enc*/}
          {/*        : "MOA"}*/}
          {/*    </Typography>*/}
          {/*  ) : global.state.user &&*/}
          {/*    global.state.user.document.roles[0] === "healthOrgAdmin" ? (*/}
          {/*    <Typography className={classes.accountName}>*/}
          {/*      {global.state.user.document.contact_name_enc*/}
          {/*        ? global.state.user.document.contact_name_enc*/}
          {/*        : "HAdmin"}*/}
          {/*    </Typography>*/}
          {/*  ) : global.state.user &&*/}
          {/*    global.state.user.document.roles[0] === "patient" ? (*/}
          {/*    <Typography className={classes.accountName}>*/}
          {/*      {global.state.user.document.contact_name_enc*/}
          {/*        ? global.state.user.document.contact_name_enc*/}
          {/*        : "Patient"}*/}
          {/*    </Typography>*/}
          {/*  ) : global.state.user &&*/}
          {/*    global.state.user.document.roles[0] === "doctor" ? (*/}
          {/*    <Box*/}
          {/*      component={Link}*/}
          {/*      to={"/account"}*/}
          {/*      onClick={() => setProfile(true)}*/}
          {/*      className={classes.accountLink}*/}
          {/*    >*/}
          {/*      <Typography className={classes.accountName}>*/}
          {/*        {global.state.user.document.contact_name_enc*/}
          {/*          ? global.state.user.document.contact_name_enc*/}
          {/*          : "Doctor"}*/}
          {/*      </Typography>*/}
          {/*    </Box>*/}
          {/*  ) : (*/}
          {/*    <div className="testTour">*/}
          {/*      <Typography className={classes.accountName}>Janitor</Typography>*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</Box>*/}
          {global.state.login && (
            <Box>
              <Typography color="primary" className={classes.selectedOrg}>
                {global.state.selectedOrg && global.state.selectedOrg.org_name
                  ? global.state.selectedOrg.org_name
                  : global.state.defaultOrg.org_name + "\n(Default Org)"}
              </Typography>
            </Box>
          )}
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Menu Tabs"
            className={classes.tabs}
            classes={
              profile
                ? { indicator: classes.profileOn }
                : { indicator: classes.profileOff }
            }
          >
            {global.state.user &&
            global.state.user.document.roles[0] === "patient"
              ? patientMenuItems.map((v, i) => {
                  return (
                    <StyledTab
                      key={i}
                      classes={{
                        wrapper: classes.iconLabelWrapper,
                        labelContainer: classes.labelContainer,
                        labelIcon: classes.labelIcon,
                      }}
                      id={"sidebar-tab" + i}
                      disabled={v.disabled}
                      onClick={() => {
                        toggleExpand(i);
                      }}
                      icon={v.icon}
                      className={classes.menuItemOuterContainer}
                      component={Link}
                      to={v.url}
                      label={v.name}
                      value={v.url}
                    ></StyledTab>
                  );
                })
              : global.state.user &&
                global.state.user.document.roles[0] === "doctor"
              ? doctorMenuItems.map((v, i) => {
                  return (
                    <StyledTab
                      key={i}
                      classes={{
                        wrapper: classes.iconLabelWrapper,
                        labelContainer: classes.labelContainer,
                        labelIcon: classes.labelIcon,
                      }}
                      id={"sidebar-tab" + i}
                      disabled={v.disabled}
                      onClick={() => {
                        toggleExpand(i);
                      }}
                      icon={v.icon}
                      className={classes.menuItemOuterContainer}
                      component={Link}
                      to={v.url}
                      label={v.name}
                      value={v.url}
                    ></StyledTab>
                  );
                })
              : global.state.user &&
                global.state.user.document.roles[0] === "oa"
              ? moaMenuItems.map((v, i) => {
                  return (
                    <StyledTab
                      key={i}
                      classes={{
                        wrapper: classes.iconLabelWrapper,
                        labelContainer: classes.labelContainer,
                        labelIcon: classes.labelIcon,
                      }}
                      id={"sidebar-tab" + i}
                      disabled={v.disabled}
                      onClick={() => {
                        toggleExpand(i);
                      }}
                      icon={v.icon}
                      className={classes.menuItemOuterContainer}
                      component={Link}
                      to={v.url}
                      label={v.name}
                      value={v.url}
                    ></StyledTab>
                  );
                })
              : menuItems.map((v, i) => {
                  return (
                    <StyledTab
                      key={i}
                      classes={{
                        wrapper: classes.iconLabelWrapper,
                        labelContainer: classes.labelContainer,
                        labelIcon: classes.labelIcon,
                      }}
                      id={"sidebar-tab-" + i}
                      disabled={v.disabled}
                      onClick={() => {
                        toggleExpand(i);
                      }}
                      icon={v.icon}
                      className={classes.menuItemOuterContainer}
                      component={Link}
                      to={v.url}
                      label={v.name}
                      value={v.url}
                    ></StyledTab>
                  );
                })}

            {/* <StyledTab
              classes={{
                wrapper: classes.iconLabelWrapper,
                labelContainer: classes.labelContainer,
                labelIcon: classes.labelIcon,
              }}
              id={"expand-goTo"}
              onClick={() => expandDropdown()}
              icon={
                dropdown ? (
                  <ExpandLessIcon className={classes.icon} />
                ) : (
                  <ExpandMoreIcon className={classes.icon} />
                )
              }
              className={classes.menuItemOuterContainer}
              label={"Go To"}
            /> */}

            {dropdown &&
              appMenuItems.map((v, i) => {
                return (
                  <StyledTab
                    key={i}
                    classes={{
                      wrapper: classes.iconLabelWrapper,
                      labelContainer: classes.labelContainer,
                      labelIcon: classes.labelIcon,
                    }}
                    id={"appRedirect-tab-" + i}
                    onClick={() => {
                      redirectApp(i);
                    }}
                    icon={v.icon}
                    className={classes.menuItemOuterContainer}
                    label={v.name}
                  ></StyledTab>
                );
              })}
          </Tabs>

          {global.state.login ? (
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <Button
                style={{ display: "none" }}
                color="primary"
                variant="contained"
                onClick={global.api.test}
              >
                secure api call
              </Button>
              <Button
                elevation={10}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  color: "#FFF",
                  background: `radial-gradient(73.95% 73.95% at 14.58% 11.46%, #4393DD 0%, #003FBA 100%)`,
                }}
                variant="text"
                startIcon={<ExitToAppIcon />}
                onClick={handleLogout}
              >
                SIGN OUT
              </Button>
            </div>
          ) : (
            <Button color="primary" variant="contained" onClick={handleLogin}>
              Login
            </Button>
          )}

          <div
            style={{
              textAlign: "center",
              marginBottom: "20px",
              color: "#767676",
            }}
          >
            <Typography variant="body2">
              © {new Date().getFullYear()} Porton Health
            </Typography>
          </div>
        </Drawer>

        <Grid container direction="column">
          {smallScreenView && !revealContainer && (
            <Grid container direction="row">
              <Grid item className={classes.hamburgerIconSpacing}>
                <IconButton
                  color="primary"
                  component="div"
                  onClick={handleMenuClick}
                >
                  <MenuIcon className={classes.hamburger} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  style={{
                    marginTop: "8px",
                    padding: "2px",
                    fontWeight: "600",
                  }}
                >
                  {headerTitle}
                </Typography>
              </Grid>
            </Grid>
          )}

          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              <Route path="/about">
                <div>about</div>
              </Route>
              <Route path="/users">
                <ManageUserPage setTitle={setHeaderTitle} />
              </Route>
              <Route path="/patientqueue">
                <PatientQueue setTitle={setHeaderTitle} />
              </Route>
              <Route path="/appsetting">
                <AppSettingPage setTitle={setHeaderTitle} />
              </Route>
              <Route path="/reports">
                <div>reports</div>
              </Route>
              <Route path="/tools">
                <ToolsPage setTitle={setHeaderTitle} />
              </Route>
              <Route path="/help">
                <HelpPage setTitle={setHeaderTitle} />
              </Route>
              <Route
                path="/settings"
                render={(props) => (
                  <SettingPage {...props} setTitle={setHeaderTitle} />
                )}
              />

              <Route path="/calendar/patients/:patientId">
                <Calendar setTitle={setHeaderTitle} />
              </Route>

              <Route path="/calendar/providers/:doctorId">
                <Calendar setTitle={setHeaderTitle} />
              </Route>
              <Route path="/Calendar">
                <Calendar setTitle={setHeaderTitle} />
              </Route>
              <Route path="/account">
                <AccountProfilePage setTitle={setHeaderTitle} />
              </Route>
              <Route path="/">
                {global.state.user &&
                global.state.user.document.roles[0] === "doctor" ? (
                  <Redirect
                    to={{
                      pathname: `/patientqueue`,
                    }}
                  />
                ) : global.state.user &&
                  global.state.user.document.roles[0] === "patient" ? (
                  <Redirect
                    to={{
                      pathname: `/settings`,
                    }}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: nextPath || "/help",
                    }}
                  />
                )}
              </Route>
            </Switch>
          </main>
          <Tour isOpen={global.tourOpen} closeTour={closeTour} />
        </Grid>
      </div>
    </Router>
  );
}
