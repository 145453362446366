import React, { useState } from 'react';
import {
  Typography,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import settingsStyles from '../../../../../styles/settingsPageStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export default function Timezones({state, setState}) {

  const classes = settingsStyles();

  const [timezoneExpanded, setTimezoneExpanded] = useState(false)

  let locations = [
    "America/Vancouver",
    "America/Edmonton",
    "America/Winnipeg",
    "America/Toronto",
    "America/Halifax",
    "America/St_Johns"
  ]

  const handleTimezoneExpandClick = () => {
    setTimezoneExpanded(!timezoneExpanded)
  }

  const handleTimezoneItemClick = (event) => {
    setState({ ...state, selectedLocation: event.target.id })
    setTimezoneExpanded(!timezoneExpanded)
  }

  return(
    <Box>
      <Typography className={classes.labelSmall}>
          Timezone
      </Typography>
      <List>
          <ListItem
              style={{ border: '1px #c7c7c7 solid', borderRadius: '5px' }}
              button
              aria-expanded={timezoneExpanded}
              onClick={handleTimezoneExpandClick}
          >
              <ListItemText primary={state.selectedLocation} />
              <ArrowDropDownIcon />
          </ListItem>
          <Collapse
              in={timezoneExpanded}
              timeout="auto"
              unmountOnExit
          >
              <List style={{ maxHeight: 200, overflow: 'auto' }}>
                  {locations.map((item, index) => (
                      <ListItem
                          button
                          key={index}
                          id={item}
                          selected={item === state.selectedLocation}
                          onClick={(e) => handleTimezoneItemClick(e)}
                      >
                          {item}
                      </ListItem>
                  ))}
              </List>
          </Collapse>
      </List>
  </Box>
  );
}