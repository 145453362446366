/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-10 05:07:32
 * @LastEditors: Tyler Bowman
 * @LastEditTime: 2021-01-22 12:36:56
 * @Description: Style Reformating
 */
import { CircularProgress, Switch, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import {
    fade,

    makeStyles, withStyles
} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';
import GlobalContext from "../../../../context/GlobalContext";
import combineStyles from '../../../../styles/combineStyles';
import settingsStyles from '../../../../styles/settingsPageStyles';
import ToolStyle from '../../../../styles/ToolStyle.js';
import AnnouncementComponent from './components/AnnouncementComponent';
import RoomTheme from './components/RoomTheme';
import UrlComponent from './components/UrlComponent';



// const CustomTextInput = withStyles((theme) => ({
//     root: {
//         'label + &': {
//             marginTop: theme.spacing(0.5),
//         },
//         width: "100%",
//         '&$disabled': {
//             color: "black"
//         },
//     },
//     input: {
//         width: "100%",
//         borderRadius: 4,
//         position: 'relative',
//         backgroundColor: "#DBDBDB",
//         border: 'none',
//         fontSize: 16,
//         color: "black",
//         fontWeight: 500,
//         padding: '10px 12px',
//         transition: theme.transitions.create(['border-color', 'box-shadow']),

//         '&:focus': {
//             boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//             borderColor: theme.palette.primary.main,
//         },
//         '&$disabled': {
//             color: "black"
//         },
//     },

// }))(InputBase);
// const CustomTextInputLabel = withStyles((theme) => ({
//     root: {
//         fontSize: "1.5rem",
//         color: "black",
//         fontWeight: 500,
//         width: "100%"
//     },

// }))(InputLabel);
const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        border: "1px solid #c7c7c7",
        borderRadius: "5px",
        width: "100%",
        padding: "2px 8px",
    },
    disabledSettingsBackground: {
        backgroundColor: "#F5F5F5",
        pointerEvents: "none",
    },

}));

let oldState;
//combines both styles from this file and from settingPageStyles
const combinedStyles = combineStyles(settingsStyles, ToolStyle, useStyles);
let oldWhiteLabelUrl = "";
const maxLength = 500;
let oldTheme = '#fff';

function RoomURL() {
    const [expanded, setExpanded] = React.useState(false);
    const classes = combinedStyles()
    const global = React.useContext(GlobalContext)
    const [state, setState] = React.useState({})
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleChange = (event) => {
        if(event.target.hasOwnProperty('checked')){
            setState({ ...state, [event.target.name]: event.target.checked})
        } else if(event.target.hasOwnProperty('value')){
            setState({ ...state, [event.target.name]: event.target.value})
        }
    }

    const saveOldState = () => {
        oldState = JSON.parse(JSON.stringify(state));
        setState({ ...state, isEdit: true })
    }

    const loadOldState = () => {
        setState({ ...oldState, isEdit: false}); 
    }

    const urlTextChange = (event) => {
        if (event.target.value === undefined) {
            return;
        }
        //prevents special characters except _ and -
        return setState({ ...state, [event.target.name]: event.target.value.replace(/[^-\w]/gi, "")})
    }

    const handleTextChange = (oldMessage) => (event) => {
        //checks to see if oldmessage is defined first, then if so checks length to allow the user to input up to the maxlength
        //also allows the user to delete if the message has somehow exceeded the maxlength
        if (oldMessage === undefined || (event.target.value.length > maxLength && oldMessage.length < event.target.value.length)) {
            return;
        }
        return setState({ ...state, [event.target.name]: event.target.value})
    }
    
    const editUrl = () => {
        setState({ ...state, editUrl: !state.editUrl})
        oldWhiteLabelUrl = state.whiteLabelUrl;
    }

    const cancelEditUrl = () => {
        setState({ ...state, whiteLabelUrl: oldWhiteLabelUrl, editUrl: !state.editUrl})
    }
    const confirmEditUrl = () => {
        setState({ ...state, whiteLabelUrl: state.whiteLabelUrl, editUrl: !state.editUrl})
    }
    const editTheme = () => {
        oldTheme = state.theme;
        setState({ ...state, editTheme: !state.editTheme}) 
    }
    const cancelEditTheme = () => {
        setState({ ...state, theme: oldTheme, editTheme: !state.editTheme})
    }
    const confirmEditTheme = () => {
        setState({ ...state, theme: state.theme, editTheme: !state.editTheme})
    }
    let defaultSettings = {
        virtualAppSettings: {
            checkedEnableSettings: true,
            announcement: "",
            enableThemeColour: false,
            theme: '#fff',
        }
    }
    
    let orgID = global.state.selectedOrg != 'undefined' && global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0 ? global.state.selectedOrg._id : global.state.defaultOrg._id

    let currentOrgSettings
    let whiteLabelURL
    let settingsPermission = global.state.user.document.roles.includes("admin")
    React.useEffect(() => {
        let PortonAdminAppBaseURL = process.env.REACT_APP_PORTON_ADMIN_API
        if(!PortonAdminAppBaseURL){
            //console.log("not detecting the REACT_APP_PORTON_ADMIN_API")
            PortonAdminAppBaseURL="/api"
        }

        let getVirtualEnvVar = async () => {
      
            if (typeof global.state.selectedOrg != 'undefined' && global.state.selectedOrg && global.state.selectedOrg != null  && JSON.stringify(global.state.selectedOrg) != "{}") {
                orgID = global.state.selectedOrg._id
                whiteLabelURL = global.state.selectedOrg.whiteLabelURL
                if(global.state.selectedOrg && typeof global.state.selectedOrg !='undefined'){
                    currentOrgSettings = global.state.selectedOrg
                }else{
                    currentOrgSettings = defaultSettings
                }
        
            } else { //default org
                orgID = global.state.defaultOrg._id
                whiteLabelURL = global.state.defaultOrg.whiteLabelURL 
                if(typeof global.state.defaultOrg !='undefined' && global.state.defaultOrg ){
                    currentOrgSettings = global.state.defaultOrg
                }else{
                    currentOrgSettings = defaultSettings
                }
            }
            
            setState({
                ...state,
                checkedEnableSettings: currentOrgSettings.virtualAppSettings ? currentOrgSettings.virtualAppSettings.checkedEnableSettings : true,
                isLoading: false,
                isEdit: false,
                btnColor: "primary",
                editUrl: false,
                whiteLabelUrl: whiteLabelURL,
                announcement: currentOrgSettings.virtualAppSettings ? currentOrgSettings.virtualAppSettings.announcement : '',
                enableThemeColour: currentOrgSettings.virtualAppSettings ? currentOrgSettings.virtualAppSettings.enableThemeColour : false,
                editTheme: false,
                theme: currentOrgSettings.virtualAppSettings ? currentOrgSettings.virtualAppSettings.theme : '#fff',
                 
            })
        }
        
        getVirtualEnvVar()
    }, [global.state.defaultOrg, global.state.selectedOrg])

    orgID = JSON.stringify(global.state.selectedOrg) != "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg._id : global.state.defaultOrg._id
    
    const saveSettings = async () => {
        //save setDisplay configs to porton DB
        
        if (!global.state.selectedOrg && !global.state.defaultOrg){
            alert("No org!")
            return
        }

        setState({ ...state, isLoading: true})
        

        if (state.whiteLabelUrl.length === 0) {
            alert("Invalid WhitelabelUrl")
            return
        }
         
        let result = await global.api.UpdateTextInDb(orgID, state.whiteLabelUrl, "whiteLabelURL")
        
        if (result.status && result.status === 400){
            alert("Error updating WhiteLabelUrl, This whitelabel may already be in use by another organization")
            setState({ ...state, isLoading: false})
            return
        }

        let settings = {
            checkedEnableSettings: state.checkedEnableSettings,
            announcement: state.announcement,
            enableThemeColour: state.enableThemeColour,
            theme: state.theme,
        }

        result = await global.api.UpdateVirtualAppSettings(orgID, settings)
        
        if (result.status && result.status === 200) {
            if(JSON.stringify(global.state.selectedOrg) != "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' ){
                let org = global.state.selectedOrg
                org.virtualAppSettings = settings
                org.whiteLabelURL = state.whiteLabelUrl
            
                global.setState((prev)=>{
                    return { ...prev, selectedOrg: org }
                })
            }else { //default org
                let org = global.state.defaultOrg
                org.virtualAppSettings = settings
                org.whiteLabelURL = state.whiteLabelUrl
               
                global.setState((prev)=>{
                    return { ...prev, defaultOrg: org }
                })
            }
        }
        
        setState({ ...state, isLoading: false, isEdit: false, editTheme: false, editUrl: false})
    }
    
    const handleChangeComplete = (color) => {
        setState({...state, theme: color.hex });
    };


    return (
        <Paper elevation={3} className={classes.container} style={{border:expanded?"2px solid #003FBA":"2px solid rgba(255,255,255,0)",borderRadius:"10px"}}>
            <Grid container>
               
                <Grid item xs={12} className={classes.paperComponment}>
                    <Box onClick={handleExpandClick} className={classes.labelWithEdit, classes.labelWithEditDropdown}>
                        <Typography className={clsx(classes.label, {
	                        	[classes.expandedLabel]: expanded
	                        })}>MeetApp</Typography>
                        <Typography className={classes.edit}>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Typography>
                    </Box>
                </Grid>

                
               
                <Grid item xs={12}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Box className={!state.isEdit ? classes.disabledBackground : ''}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>

                                <Grid item xs={12} className={classes.paperComponment}>
                                    <Box className={classes.labelWithEdit}>
                                        {!state.isEdit &&
                                            <Typography className={classes.labelSmall}>Enable MeetApp</Typography>
                                        }
                                        {state.isEdit && 
                                            <Typography className={classes.labelSmallEnabled}>Enable MeetApp</Typography>    
                                        }
                                        <Switch
                                            color='primary'
                                            size='small'
                                            className={classes.switch}
                                            checked={state.checkedEnableSettings === undefined ? true : state.checkedEnableSettings}
                                            onChange={handleChange}
                                            name='checkedEnableSettings'
                                            disabled={!state.isEdit}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container flex>
                                        <Grid item md={6} xs={12} className={classes.dividerBorder}>
                                            <Grid item xs={12}>
                                                <Divider className={classes.divider} />
                                            </Grid>
                                            <UrlComponent state={state} classes={classes} handleChange={handleChange} editUrl={editUrl} 
                                                setState={setState} urlTextChange={urlTextChange} cancelEditUrl={cancelEditUrl}
                                                confirmEditUrl={confirmEditUrl} settingsPermission={settingsPermission} />   
                                        </Grid>        

                                        <Grid item md={6} xs={12} >
                                            <Grid item xs={12}>
                                                <Divider className={classes.divider} />
                                            </Grid>  

                                            <AnnouncementComponent state={state} classes={classes} handleChange={handleChange}
                                            handleTextChange={handleTextChange}/>

                                            <RoomTheme state={state} classes={classes} handleChange={handleChange} editTheme={editTheme} 
                                                handleChangeComplete={handleChangeComplete} confirmEditTheme={confirmEditTheme} 
                                                cancelEditTheme={cancelEditTheme} />

                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Box>
                        <Grid item xs={12}><Divider className={classes.divider}/> </Grid>

                        {state.isEdit ?
                            (<Grid item md={12} sm={12} >
                                <Box item md={12} sm={12} className={clsx(classes.labelWithEditBottomSpacing, classes.labelWithEdit)}>
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        {state.isLoading ? 
                                            (<CircularProgress className={classes.loadingCircle}/>) : 
                                            <Button md={5} sm={12} className={clsx(classes.saveChangesBtn, classes.editToggleBtn)}
                                                variant="outlined" color={state.btnColor} onClick={saveSettings}>Save Changes
                                            </Button>
                                        }
                                    </Box>
                                    {state.isLoading ? 
                                        (<CircularProgress className={classes.loadingCircle}/>) : 
                                        <Button md={5} sm={12} className={classes.editToggleBtn} 
                                            variant="outlined" color={state.btnColor} onClick={() => loadOldState()}>Cancel
                                        </Button>
                                    }
                                </Box>
                            </Grid>)
                        :
                            (<Grid item md={12} sm={12}>
                                <Box item md={12} sm={12} className={clsx(classes.labelWithEditBottomSpacing, classes.labelWithEdit)}>
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        <Button md={5} sm={12} className={classes.editToggleBtn} 
                                            variant="outlined" color={state.btnColor} onClick={() => saveOldState()} 
                                            >
                                                Edit
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>)
                        }
                    </Collapse>
                </Grid>
            </Grid>

        </Paper>
    );
}

export default RoomURL;
