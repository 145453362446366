/*
 * @Author: William Lee
 * @Date: 2021-07-07 15:15:00
 * @LastEditors: William Lee
 * @LastEditTime: 2021-07-07 15:16:00
 * @Description: Iris scan modal
 */

import { Box, Button, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import React from 'react';
import Webcam from 'react-webcam';
import settingsStyles from '../../../../../styles/settingsPageStyles';

// const steps = [
//   '',
//   '',
//   ''
// ];

// Progress Bar
  // const ProgressStepper = () => {
  //   return (
  //     <Box sx={{ width: '75%' }}>
  //       <Stepper style={{ width: '100%' }} activeStep={0} alternativeLabel>
  //         {steps.map((label) => (
  //           <Step key={label}>
  //             <StepLabel style={{ color: 'green' }}>{label}</StepLabel>
  //           </Step>
  //         ))}
  //       </Stepper>
  //     </Box>
  //   );
  // }

const FingerprintScan = ({ user, open, close, setPassword }) => {
    const classes = settingsStyles()

    const svgIcon = () => (
        <svg
            width="100%"
            height="100%"
            className="svg"
            viewBox="0 0 260 200"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <mask id="overlay-mask" x="0" y="0" width="100%" height="100%">
                    <rect x="0" y="0" width="100%" height="100%" fill="#fff"/>
                    <circle cx="25%" cy="50%" r="50" stroke="#fff" stroke-width="5"/>
                    <circle cx="75%" cy="50%" r="50" stroke="#fff" stroke-width="5"/>
                </mask>
            </defs>
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="97.5%" mask="url(#overlay-mask)" fillOpacity="0.7"/>
        </svg>
    );

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography className={classes.label}>Scan Iris</Typography>
                    <IconButton>
                        <CloseIcon onClick={close} />
                    </IconButton>
                </Box>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography style={{color:'#767676', marginTop:'2%'}}>Hold your device 25-35 cm away from your face and place your eyes in the circles shown on the screen</Typography>
                </Box>
                <div className={classes.irisScanDiv}>
                    <Webcam
                    height={300}
                    width={400}
                    className={classes.webcamBorder}
                    />
                    <div className="overlay-container" className={classes.irisScanOverlay}>
                        {svgIcon()}
                    </div>
                </div>
                <div className={classes.fingerprintIconDiv}>
                    <Button>
                        <Typography className={classes.labelSmallIris} style={{color:'#003FBA'}}>SCAN NOW</Typography>
                    </Button>
                    {/*<CircularProgress className={classes.cancelIcon}/>*/}
                </div>
                {/*Can use stepper below in the case of showing a stepper like original Figma design*/}
                {/*<div className={classes.fingerprintIconDiv}>
                    <ProgressStepper/>
                </div>*/}
            </DialogTitle>
        </Dialog>
    )
}

export default FingerprintScan;