//material UI components and icons
import { Box, Collapse, Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';
//global
import GlobalContext from "../../../context/GlobalContext";
//styling
import AccountProfileStyle from '../../../styles/AccountProfileStyles';
import Bio from "./components/bio";
import Org from "./components/organization";
//components
import SaveEdit from "./components/saveEdit";
import Specialty from "./components/specialty";


const Profile = (props) => {
    const classes = AccountProfileStyle()
    const global = useContext(GlobalContext)
    const user = global.state.user && global.state.user.document ? global.state.user.document : {}
    const defaultUser = {
        isEdit: false,
        isLoading: false,
        user: user,
    }
    
    const userItem = {
            id: user._id,
            firstname: user.firstname,
            lastname: user.lastname,
            specialty: user.specialty,
            bio: user.bio,
            profileSet: true,
            title: user.title,
            contact_name: user.firstname + " " + user.lastname,
            role: user.roles && user.roles.length ? user.roles[0] : 'doctor',
            doctor_board_certified: user.cert,
            country: user.certCountry,
            doctor_license_location: user.certProv,
            doctor_certified_year: user.certYear,
            orgs:user.orgs,
            default_org:user.default_org
    }

    const [userState, setUserState] = useState(userItem)
    const prevUserState=useRef(userItem)
    const [cancel,setCancel] = useState(false)

    const [state, setState] = useState(defaultUser)
    const [expanded, setExpanded]=useState(false)
    const handleExpandClick = () => {
        setExpanded(!expanded);
    }
    const changeEdit = () => {
        if(state.isEdit){
            setState({...state, isEdit:false})
        }
        else{
            setState({...state, isEdit:true})
        }
        
    } 

    const save = async () => {
        let result = await global.api.updateAccount({
            id: user._id,
            firstname: user.firstname,
            lastname: user.lastname,
            specialty: userState.specialty,
            bio: userState.bio,
            profileSet: true,
            title: user.title,
            contact_name: user.firstname + " " + user.lastname,
            role: user.roles && user.roles.length ? user.roles[0] : 'doctor',
            doctor_board_certified: user.cert,
            country: user.certCountry,
            doctor_license_location: user.certProv,
            doctor_certified_year: user.certYear,
            orgs:userState.orgs,
            orgId: global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id
        });
        if (result?.status === 200) {
            prevUserState.current=userState
        } else {
            alert('Unable to update profile.');
        }
    }

    const cancelTrigger = () => {
        setCancel(!cancel)
        setUserState(prevUserState.current)  
    }
    
    useEffect(() => {
        props.setTitle("Profile");
    }, []);
    
    return (
        <>
        
        <Grid container component={Paper} elevation={3} className={expanded ? classes.rootExpanded : classes.root}>
            <Grid item xs={12} className={classes.paperComponment}>
                <Box className={classes.labelIconFormat}>
                    <Typography className={classes.label}>Profile</Typography>
                    <Typography className={classes.iconFormat}>
                        <IconButton
                            className={clsx(classes.expandDefault, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >  
                            <ExpandMoreIcon /> 
                        </IconButton>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={expanded} timeout='auto' unmountOnExit >
                    <Grid item xs={12} >
                        <Divider/>
                    </Grid>
                    <Grid container xs={12} >
                        <Grid item xs={6} className={!state.isEdit ? classes.disabledBackground : classes.topPadding }>
                            {/* <Certificates/> */}
                            <Divider/>
                            <Specialty setUserState={setUserState} userState={userState} cancelTrigger={cancelTrigger} />
                            <Divider/>
                            <Bio cancelTrigger={cancelTrigger} setUserState={setUserState} userState={userState} cancel={cancel} prev={prevUserState.current} />
                        </Grid>
                        <Grid item xs={6} className={!state.isEdit ? classes.disabledBackground :  classes.topPadding}>
                            <Org user={user} setUserState={setUserState} userState={userState} cancel={cancel}/>
                            <Divider/>
                        </Grid>
 
                        {/* Save/edit portion */}
                        <Grid item xs ={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <SaveEdit save={save} editCheck={changeEdit} state={state} cancelTrigger={cancelTrigger} />
                        </Grid>
                    </Grid> 
                </Collapse>
            </Grid>
        </Grid>
        {/* <Button onClick={ ()=>{console.log(userState)}}>test</Button> */}
        </>

    )
}

export default Profile