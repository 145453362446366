import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import SendFaxModal from "./modal/SendFaxModal";

export default function SendFax(props) {
  const classes = props.classes;
  const [state, setState] = useState({
    sendFaxOpen: false,
  });

  // function saveByteArray(fileName, byte) {
  //  /*   var bytes = new Uint8Array(byte)
  //     var blob = new Blob([bytes], {type: "application/pdf"})  //resultByte to bytes
  //     var link = document.createElement('a')
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = fileName
  //     link.click()*/
  // }

  // const BUCKET_NAME = process.env.REACT_APP_S3_BUCKETNAME?process.env.REACT_APP_S3_BUCKETNAME:"porton-shared"

  const DeleteUploadedFileDB = (formKey) => {
    /* let filteredForms = state.forms.filter(function(form ) {
            //if(form.key === formKey) formsToBeDeletedFromAws.push(form.key)
            form.key === formKey && state.formsToBeDeletedFromAws.push(form.key)
            return form.key !== formKey;
        });
        setState({...state, forms: filteredForms})*/
    //console.log("filteredForms: ", filteredForms)
    //console.log("formsToBeDeletedFromAws: ", state.formsToBeDeletedFromAws)
  };

  const fileRetrieveFromAWS = async (fileKey, fileName) => {
    /*   let res = await global.api.DownloadFileFomAws({fileKey, fileName, BUCKET_NAME})
        saveByteArray(fileName, res.data.Body.data)*/
  };

  return (
    <Box xs={12} className={classes.paperComponment}>
      <Box className={classes.labelWithEdit}>
        <Typography
          className={classes.labelSmall}
          style={{
            fontWeight: 500,
            fontSize: "18px",
            marginTop: "10px",
            marginBottom: "24px",
          }}
        >
          Fax
        </Typography>

        <Button
          color="primary"
          onClick={() => setState({ ...state, sendFaxOpen: true })}
        >
          <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
            SEND&nbsp;FAX
          </Typography>
        </Button>
      </Box>
      <SendFaxModal
        open={state.sendFaxOpen}
        close={() => setState({ ...state, sendFaxOpen: false })}
        state={state}
        setState={setState}
        fileRetrieveFromAWS={fileRetrieveFromAWS}
        DeleteUploadedFileDB={DeleteUploadedFileDB}
        classes={classes}
      />

      <Typography
        className={classes.statusText}
        style={{
          fontWeight: 400,
          fontSize: "16px",
          fontFamily: "Roboto",
          lineHeight: "18.75px",
        }}
      >
        Enter sender and recipient information and attach files to send a fax.
      </Typography>
    </Box>
  );
}
