import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import React from 'react';



export default function ConfirmationPanelComponent(props) {
    const classes = props.classes;
    const state = props.state;
    const maxLength = props.maxLength;
    const handleChangeText = props.handleChangeText;
    return (
        <div className="panelDisplayTour">
        <Grid item xs={12} className={classes.paperComponment}>
            
            <Typography className={classes.labelSmall}>Confirmation Panel Display</Typography>
            
            <Box >
                <Typography className={classes.labelSmall}>Health and Safety Procedure</Typography>
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    name="procedureMessage"
                    rows={4}
                    onChange={handleChangeText(state.procedureMessage)}
                    value={state.procedureMessage ? state.procedureMessage : ""}
                    variant="outlined"
                    placeholder="Enter Message"
                    className={classes.textBox}
                />
                <div className={classes.textCounter}>
                    {state.procedureMessage ? state.procedureMessage.length : 0}/{ maxLength }
                </div>
            </Box> 
        </Grid>
        </div>
    );
}

