/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-06 02:09:34
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-06 05:02:11
 * @Description: file content
 */

let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  // {
  //   id: createEventId(),
  //   title: 'All-day event',
  //   start: todayStr,
  //   color: '#378006'
  // },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00',
    eventColor: '#378006'
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T13:00:00',
    eventColor: '#378006'
  }
]

export function createEventId() {
  return String(eventGuid++)
}
