import { MenuItem, Select, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import React from "react";

export default function ManageScheduleDays(props) {
    const { classes, state, handleChangeDropdown } = props;
    return (
        <Grid item xs={12} className={classes.paperComponment}>
            <Box>
                <Typography
                    className={classes.labelSmall}
                    style={{ marginBottom: "10px" }}
                >
                    Schedule Days
                </Typography>
                <Typography variant="body2" className={classes.labelText}>
                    Select Booking Days
                </Typography>
                <Box className={classes.subButton}>
                    <FormControl className={classes.formControl}>
                        <Select
                            displayEmpty
                            disableUnderline
                            value={
                                state !== undefined ? state.scheduleDays : 90
                            }
                            onChange={handleChangeDropdown}
                            name="scheduleDays"
                            className={classes.textList}
                            placeholder="Select Schedule Days"
                            inputProps={{
                                "aria-label": "Without label",
                                disableUnderline: true,
                            }}
                        >
                            <option className={classes.optionList} value={state !== undefined ? state.scheduleDays : 90} hidden> Select Schedule Days </option>

                            
                            <MenuItem className={classes.optionList} value={30}>
                                <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                >
                                    30 Days
                                </Typography>
                            </MenuItem>
                            <MenuItem className={classes.optionList} value={60}>
                                <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                >
                                    60 Days
                                </Typography>
                            </MenuItem>
                            <MenuItem className={classes.optionList} value={90}>
                                <Typography
                                    variant="body2"
                                    className={classes.labelText}
                                >
                                    90 Days
                                </Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Grid>
    );
}
