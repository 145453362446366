
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import {
    makeStyles
} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';
import combineStyles from '../../../../styles/combineStyles';
import popupStyles from '../../../../styles/PopupStyle.js';
import settingsStyles from '../../../../styles/settingsPageStyles';
import ToolStyle from '../../../../styles/ToolStyle.js';
import UserPageStyles from '../../../../styles/usersPageStyles';
import FaxHistory from './subcomponent/FaxHistory';
import SendFax from './subcomponent/SendFax';


const useStyles = makeStyles((theme) => ({
    root: {
        "& h3": {
            margin: 0
        }
    },
    head: {
        display: "flex",
    },
    title: {
        paddingTop: "0.2rem",
        fontWeight: 500,
        display: "block",
        marginRight: "0rem",
        marginBottom: "2rem",
        fontSize: "2rem"
    },
    paperTitle: {
        padding: "1.5rem",
        borderBottom: "1px solid #CCCCCC"
    },
    paperComponment: {
        padding: "1rem 1rem"
    },
    toolBox: {
        padding: "1.5rem"
    },
    toolsContainer: {
        display: "flex",
        flexWrap: "nowrap",
        borderTop:"1px solid #CCCCCC",
    },
    leftContainer: {
        borderRight: "1px solid #CCCCCC"
    },
    dummyUsers: {
        borderBottom: "1px solid #CCCCCC"
    },
    cleanupDB: {
        borderBottom: "1px solid #CCCCCC"
    },
    labelWithBtn: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end"
    },
}));

const combinedStyles = combineStyles(settingsStyles, ToolStyle, popupStyles,  UserPageStyles, useStyles);





function FaxComponent() {

    const [expanded, setExpanded] = React.useState(false);
    const classes = combinedStyles()
    const [state] = React.useState({})



    const handleExpandClick = () => {
        setExpanded(!expanded);
    };



    return (
        <Paper elevation={3} className={classes.container} style={{border:expanded?"2px solid #003FBA":"2px solid rgba(255,255,255,0)",borderRadius:"10px", width:'100%'
       
        }}>
           <Grid container>   
               <Grid item xs={12} className={classes.paperComponment}>
                   <Box onClick={handleExpandClick} className={classes.labelWithEdit, classes.labelWithEditDropdown}>
                       <Typography 
                           className={clsx(classes.label, {
                               [classes.expandedLabel]: expanded
                           })}>
                           Fax
                           </Typography>
                       <Typography className={classes.edit}>
                           <IconButton
                               className={clsx(classes.expand, {
                                   [classes.expandOpen]: expanded,
                               })}
                               onClick={handleExpandClick}
                               aria-expanded={expanded}
                               aria-label="show more"
                           >
                               <ExpandMoreIcon />
                           </IconButton>
                       </Typography>
                   </Box>
               </Grid>
              



              {// app contents inside drawer
              }
               <Grid item xs={12} >
                   <Collapse in={expanded} timeout="auto" unmountOnExit >
                   <Grid item xs={12} >
                    <Divider className={classes.divider} />
                    </Grid>

                    <Grid item xs={12}>
                     <Grid container flex>
                    <Grid item md={6} xs={12} className={classes.dividerBorder}  style={{paddingLeft: "19px",
    paddingRight: "21px"}}    >
                         <Grid item xs={12}     >
                             <Divider className={classes.divider} />
                            </Grid>
                        <SendFax classes={classes}  />  
                        
                         </Grid>        
                      
                   <Grid item md={6} xs={12} style={{ height:"267px", paddingLeft: "19px",
    paddingRight: "21px" }}>
                <Grid item xs={12} >
                <Divider className={classes.divider} />
                 </Grid>  
                  <FaxHistory state={state} classes={classes} />
                    </Grid>
                </Grid>
    </Grid>
   </Collapse>
               </Grid>
           </Grid>

       </Paper>
   )
}


export default FaxComponent;



































