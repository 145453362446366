/*
 * @Author: Vincent Tran
 * @Date: 2020-08-20 02:10:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-08-20 05:00:00
 * @Description: file content
 */
/**
 * TO DO:
 * Connect this component to the database...
 * 1) Retrieve provider data from database, save info to state, and display.
 * 2) Edits made to provider data must update in the database (handleSave).
 * 
 * Other minor enhancements:
 * 1) Verify email and phone changes with regex before updating provider info.
 */

 import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle';
import UserPageStyles from '../../../../../styles/usersPageStyles';
import OnImportSuccess from '../meatBallOptions/successUnlinkComplete';

 const Transition = React.forwardRef(function Transition(props, ref) {
     return <Slide direction="up" ref={ref} {...props} />;
 });
 
 
 // combine specific styles created here in useStyles and general styles from PopupStyles.js
 // NOTE: Include styles in params from general to specific
 const combinedStyles = combineStyles(popupStyles, UserPageStyles);
 
 export default function UnlinkFromOrganization(props) {
     const global = React.useContext(GlobalContext)
     
   
     const [open, setOpen] = React.useState(false);

     const [successOpen,setsuccessOpen] = React.useState(false);

    const [uploadingStart,setUploadingStart] = React.useState(false);

    const [orgDetails, setOrgDetails] = React.useState({})

    const details = () => {
        let data = Object.keys(global.state.selectedOrg).length > 0 ? global.state.selectedOrg : global.state.defaultOrg;
        console.log(data);
        setOrgDetails(data)
        return
    }
    React.useEffect(details, [])

    
     const handleClickOpen = () => {
         setOpen(true);
     };
     const handleClose = () => {
         setOpen(false);
         props.handleClose();
     };

  
    const unlink = async () => {
       
     let result = await global.api.unlinkProviderFromOrg(
      props.account._id,           
      global.state.selectedOrg._id            
      );                    
      if (result && result.status === 200) {                     
        props.refresh()                           
      } else {                   
      alert('Unable to unlink org.');            
       }                                           
    }

     const handleSave = async () => {
    console.log(props.account);
     
           let result = await global.api.sendUnlinkFromOrgEmail(
           props.account.firstname,
           props.account.lastname,
           props.account.email,
           orgDetails.org_name,
           orgDetails.org_email,
           orgDetails.contact_info,
           orgDetails.logo
           );
            if (result && result.status === 200) {
              console.log("email sent was success")
               
           } else {
               alert('Unable to send unlink email.');
           } 
           setsuccessOpen(true);
           setUploadingStart(true)
     }
 
     const classes = combinedStyles();
 
     const closeSuccessPopup = () =>{
        setUploadingStart(false);
        setsuccessOpen(false);
    }

     return (
         <div>
             <ButtonBase className={props.className} onClick={handleClickOpen}> <Typography className={classes.typography}>Unlink From Organization</Typography></ButtonBase>
 
             <Dialog
                 open={open}
                 TransitionComponent={Transition}
                 keepMounted
                 onClose={handleClose}
                 aria-labelledby="alert-dialog-slide-title"
                 aria-describedby="alert-dialog-slide-description"
             >
 
                 <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">Remove From Organization<IconButton onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} /> </DialogTitle>
                 <DialogContent className={classes.content}>
                     <Grid container>
                         <Grid container spacing={3} className={classes.row}>
                             
                             <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    style={{
                        marginTop: -27,
                        fontSize: "16px",
                        color: "#000000",
                        fontFamily:"Roboto"
                    }}
                >
                    <p>
                       Are you sure you want to remove<br/> {props.account.contact_name} from {global.state.selectedOrg.org_name}?</p>
                </DialogContentText>
            </DialogContent>
                         
                     </Grid>
                     </Grid>
                 </DialogContent>
                 <DialogActions className={classes.dialogAction}>
                     <Button onClick={handleClose} color="primary" className={classes.actionBtn}>
                         CANCEL
                     </Button>
                     <Button onClick={handleSave} color="primary" className={classes.actionBtn} >
                         YES
                     </Button>
                 </DialogActions>
             </Dialog>
             { uploadingStart && <OnImportSuccess 
                                        open = {successOpen}
                                         onClose = {closeSuccessPopup}
                                         handleClose = {handleClose}
                                         account={props.account} 
                                         unlink={unlink}
                                    />} 
         </div>
     );
 }
 