/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-10 05:07:32
 * @LastEditors: Tyler Bowman
 * @LastEditTime: 2021-01-22 10:49:56
 * @Description: Style Reformating
 */

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import MicIcon from '@material-ui/icons/Mic';
import SmsIcon from '@material-ui/icons/Sms';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import React from 'react';
import GlobalContext from "../../../../context/GlobalContext";
import combineStyles from '../../../../styles/combineStyles';
import settingsStyles from '../../../../styles/settingsPageStyles';
import ToolStyle from '../../../../styles/ToolStyle.js';


const dotenv = require("dotenv")
dotenv.config()


// const CustomTextInput = withStyles((theme) => ({
    
//     root: {
//         'label + &': {
//             marginTop: theme.spacing(0.5),
//         },
//         width: "100%",
//         '&$disabled': {
//             color: "black"
//         },
//     },
//     input: {
//         width: "100%",
//         borderRadius: 4,
//         position: 'relative',
//         backgroundColor: "#DBDBDB",
//         border: 'none',
//         fontSize: 16,
//         color: "black",
//         fontWeight: 500,
//         padding: '10px 12px',
//         transition: theme.transitions.create(['border-color', 'box-shadow']),

//         '&:focus': {
//             boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//             borderColor: theme.palette.primary.main,
//         },
//         '&$disabled': {
//             color: "black"
//         },
//     },


// }))(InputBase);
// const CustomTextInputLabel = withStyles((theme) => ({
//     root: {
//         fontSize: "1.5rem",
//         color: "black",
//         fontWeight: 500,
//         width: "100%"
//     },

// }))(InputLabel);


const useStyles = makeStyles((theme) => ({
    switch: {
        margin :"auto 0"
    },
    formControl: {
        //margin: theme.spacing(1),
        minWidth: 120,
        border: "1px solid #343434",
        boxSizing: "border-box",
        borderRadius: "4px",
        width: "100%",
        padding: "2px 8px",
    },
    selectEmpty: {
        //marginTop: theme.spacing(2),
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    subLabel: {
        fontSize: "12px",
        lineHeight: "14px",
        color: "#767676",
        marginBottom: "8px"
    },
}));

let oldState;
//combines both styles from this file and from settingPageStyles
const combinedStyles = combineStyles(settingsStyles, ToolStyle, useStyles);

function RoomURL() {
    const [expanded, setExpanded] = React.useState(false);
    const classes = combinedStyles()
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const global = React.useContext(GlobalContext)

    const BUCKET_NAME = process.env.REACT_APP_S3_BUCKETNAME?process.env.REACT_APP_S3_BUCKETNAME:"porton-shared"
    let remSettings= {
        reminderSettings: {
            sms: {
                messageType: "message",
                message: "0This is a reminder for _Patient_ from _Clinic_. You have an appointment with _Doctor_ on _Date_ at _Time_. Please note that you will be billed _Bill:#_ for appointments cancelled within _Cancel:#_.",
                daysBeforeReminder : "1",
                on: false
            },
            email: {
                messageType: "message",
                message: "0This is a reminder for _Patient_ from _Clinic_. You have an appointment with _Doctor_ on _Date_ at _Time_. Please note that you will be billed _Bill:#_ for appointments cancelled within _Cancel:#_.",
                daysBeforeReminder : "1",
                on: false
            },
            voice: {
                messageType: "message",
                message: "0This is a reminder for _Patient_ from _Clinic_. You have an appointment with _Doctor_ on _Date_ at _Time_. Please note that you will be billed _Bill:#_ for appointments cancelled within _Cancel:#_.",
                daysBeforeReminder : "1",
                on: false
            },
            checkedCustomMessage: false,
            checkedEnableSettings: false,
            checkedTextToSpeech: false,
            records: []
        }
    }

    let currentOrg
    let orgID = global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg._id : global.state.defaultOrg._id
    let [state, setState] = React.useState({});
    
    React.useEffect(()=>{
        //gets the settings from porton db if exist, otherwise apply default settings and set the state
        if (typeof global.state.selectedOrg != 'undefined' && global.state.selectedOrg && global.state.selectedOrg != null  && JSON.stringify(global.state.selectedOrg) != "{}") {
            orgID = global.state.selectedOrg._id
            if(global.state.selectedOrg.reminderSettings && typeof global.state.selectedOrg.reminderSettings !='undefined'){
                currentOrg = global.state.selectedOrg.reminderSettings
                if(!currentOrg.hasOwnProperty('checkedCustomMessage') || !currentOrg.hasOwnProperty('checkedEnableSettings') ||!currentOrg.hasOwnProperty('checkedTextToSpeech')){
                    currentOrg.checkedCustomMessage = false
                    currentOrg.checkedEnableSettings = false
                    currentOrg.checkedTextToSpeech = false
                    currentOrg.records = []
                }
            }else{
                currentOrg = remSettings.reminderSettings
            }
    
        } else { //default org
            orgID = global.state.defaultOrg._id
            if(typeof global.state.defaultOrg.reminderSettings !='undefined' && global.state.defaultOrg.reminderSettings ){
                currentOrg = global.state.defaultOrg.reminderSettings
                if(typeof currentOrg.checkedCustomMessage == 'undefined' || typeof currentOrg.checkedEnableSettings == 'undefined' ||typeof currentOrg.checkedTextToSpeech == 'undefined'){
                    currentOrg.checkedCustomMessage = false
                    currentOrg.checkedEnableSettings = false
                    currentOrg.checkedTextToSpeech = false
                    currentOrg.records = []
                }
            }else{
                currentOrg = remSettings.reminderSettings
            }
        }

        setState({ ...state, 
            checkedEmail: currentOrg.email.on,
            checkedSms: currentOrg.sms.on,
            checkedVoice: currentOrg.voice.on,
            checkedCustomMessage: currentOrg.checkedCustomMessage,
            checkedEnableSettings: currentOrg.checkedEnableSettings,
            checkedTextToSpeech: currentOrg.checkedTextToSpeech,
            daysBeforeReminderEmail: currentOrg.email.daysBeforeReminder,
            daysBeforeReminderSms: currentOrg.sms.daysBeforeReminder,
            daysBeforeReminderVoice: currentOrg.voice.daysBeforeReminder,
            emailMessage: currentOrg.email.message,
            voiceMessage: currentOrg.voice.message,
            records: currentOrg.records,
            currentUploadedFile: null,
            recordsToBeDeletedFromAws: [],
            btnColor: "primary",
            isEditPressed: false,
            isLoading: false,
        })
    },[global.state.defaultOrg,global.state.selectedOrg])
       
    let org = {}
    if (global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0) {
        org = global.state.selectedOrg
    } else if (global.state.defaultOrg && Object.keys(global.state.defaultOrg).length !== 0) {
        org = global.state.defaultOrg
    }
    orgID = JSON.stringify(global.state.selectedOrg) != "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg._id : global.state.defaultOrg._id

    const handleChange = (event) => {
        if(event.target.hasOwnProperty('checked')){
            return setState({ ...state, [event.target.name]: event.target.checked})
        }else if(event.target.hasOwnProperty('value')){
            return setState({ ...state, [event.target.name]: event.target.value})
        }
    }
    
    //saves old state
    const saveOldState = () => {
        oldState = JSON.parse(JSON.stringify(state));
        setState({ ...state, isEditPressed: true })
    }

    //This function technically is not needed, as it is one line, but here for consistency with the checkinApp
    const loadOldState = () => {
        setState({ ...oldState, isEditPressed: false});
    }


    const fileUploadButton = async (event) => {
        setState({ ...state, currentUploadedFile: event.target.files[0] });
        event.target.value = null 
    }

    const DeleteUploadedFileAWS = async() => {
        let deleteRecords = state.recordsToBeDeletedFromAws
        let deletedNumber = 0
        for (const recordKey of deleteRecords){
            let result = await global.api.DeleteFileFromAws({          
                Key: recordKey,
                BUCKET_NAME:  BUCKET_NAME
            })
            result.status === 200 && deletedNumber++
        }
    }

    const DeleteUploadedFileDB = (recordKey) => {
        let filteredRecords = state.records.filter(function(record ) {
            record.key === recordKey && state.recordsToBeDeletedFromAws.push(record.key)
            return record.key !== recordKey;
        });
        setState({...state, records: filteredRecords})
    }

    function saveByteArray(fileName, byte) {
        var bytes = new Uint8Array(byte)
        var blob = new Blob([bytes], {type: "application/pdf"})  //resultByte to bytes
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName
        link.click()
    }

    const fileRetrieveFromAWS =async (fileKey, fileName) => {
        let res = await global.api.DownloadFileFomAws({fileKey, fileName, BUCKET_NAME})
    }

      const saveSettings = async () => {
        //upload files to AWS by calling admin-api if that is needed 
        //save settings to porton DB
        //delete files from AWS if that is needed 
        //update global state

        if(!global.state.selectedOrg && !global.state.defaultOrg){
            alert("No org!")
            return
        }

        setState({ ...state, isLoading: true})

        let record = {}
        if(state.currentUploadedFile){
            const data = new FormData()
            data.append('file',  state.currentUploadedFile)
            data.append('filename', state.currentUploadedFile.name)
            data.append('mimetype', 'MP3')
            data.append('contentType', 'audio/mpeg')
            data.append('BUCKET_NAME', process.env.REACT_APP_S3_BUCKETNAME?process.env.REACT_APP_S3_BUCKETNAME:"porton-shared")
            data.append('folderDir', 'audio-records/orgs/reminder-records')

            let res = await global.api.UploadFileToAws(data)

            if (res.status === 200) {
                record = {
                        name: state.currentUploadedFile.name,
                        location: res.data.Location,
                        key: res.data.key,
                }
                state.forms.push(record)
            }else{

            }
        }


        let settings ={
                sms: {
                    messageType: "messageee",
                    message: state.emailMessage,
                    daysBeforeReminder : state.daysBeforeReminderSms,
                    on: state.checkedSms
                },
                email: {
                    messageType: "message",
                    message: state.emailMessage,
                    daysBeforeReminder : state.daysBeforeReminderEmail,
                    on: state.checkedEmail
                },
                voice: {
                    messageType: "message",
                    message: state.voiceMessage,
                    daysBeforeReminder : state.daysBeforeReminderVoice,
                    on: state.checkedVoice
                },
                checkedCustomMessage: state.checkedCustomMessage,
                checkedEnableSettings: state.checkedEnableSettings,
                checkedTextToSpeech: state.checkedTextToSpeech,
                records: state.records,
        }
        
        let result = await global.api.UpdateReminderSettings(orgID, settings)

        await DeleteUploadedFileAWS()
        if (result.status && result.status === 200) {
        if(JSON.stringify(global.state.selectedOrg) != "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' ){
            let org = global.state.selectedOrg
            org.reminderSettings = settings
            global.setState((prev)=>{
                return {...prev, selectedOrg: org}
            })
        }else { //default org
            let org = global.state.defaultOrg
            org.reminderSettings = settings
            global.setState((prev)=>{
                return { ...prev, defaultOrg: settings }
            })
        }
        }
        result.status === 200 && setState({...state, btnColor : 'inherit'})
        setState({ ...state, isLoading: false, isEditPressed: false})
    }

      
    if((typeof global.state.selectedOrg != 'undefined' && global.state.selectedOrg && global.state.selectedOrg != null  && JSON.stringify(global.state.selectedOrg) != "{}" || (typeof global.state.defaultOrg != 'undefined' && global.state.defaultOrg && global.state.defaultOrg != null  && JSON.stringify(global.state.defaultOrg) != "{}")))
{
    return (
        <Paper elevation={3} className={classes.container} style={{border:expanded?"2px solid #003FBA":"2px solid rgba(255,255,255,0)",borderRadius:"10px"}}>
            <Grid container>
                <Grid item md={12} sm={12} xs={12} className={classes.paperComponment}>
                    <Box onClick={handleExpandClick} className={clsx(classes.labelWithEdit, classes.labelWithEditDropdown)}>
                        <Typography className={clsx(classes.label, {
	                        	[classes.expandedLabel]: expanded
	                        })}>ReminderApp</Typography>
                        <Typography className={classes.edit}>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton></Typography>
                    </Box>
                </Grid>
               
                <Grid item md={12} sm={12} xs={12}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Grid item md={12} sm={12} xs={12}><Divider className={classes.divider} />
                        </Grid>
                      
                        <Box className={!state.isEditPressed && classes.disabledSettingsBackground} style={{paddingTop: '14px'}}>
                            <Grid container>
                                <Grid item md={12} sm={12} xs={12} style={{'padding' : '0 25px'}}>
                                        <Box item md={12} sm={12} xs={12} className={classes.labelWithEdit} style={{"marginBottom": "15px"}}>
                                            <Box className={classes.labelWithEdit} style ={{"width": "100%"}}>
                                                {!state.isEditPressed &&
                                                    <Typography className={classes.labelSmall}>Enable ReminderApp</Typography>
                                                }
                                                {state.isEditPressed &&
                                                    <Typography className={classes.labelSmallEnabled}>Enable ReminderApp</Typography>
                                                }
                                                <Switch 
                                                    className={classes.switch}
                                                    size="small"
                                                    checked={state.checkedEnableSettings}
                                                    onChange={handleChange}
                                                    color={!state.isEditPressed ? '#767676' : 'primary'}
                                                    name="checkedEnableSettings"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                            </Box>
                                        </Box>
                                </Grid>
                                
                                <Grid item md={12} sm={12} xs={12}><Divider className={classes.divider} /></Grid>

                                <Grid container >
                                    <Grid item md={3} sm={12} xs={12} style={{'padding' : '0 25px'}}>
                                        <Typography className={classes.labelSmall} >Notifications</Typography>
                                        <Box className={classes.labelWithEdit}>
                                            <IconButton style={{"padding-left": 0}} aria-label="Email"><EmailIcon/></IconButton>
                                            <Typography className={classes.labelSmall} style={{"margin": "auto 0"}}>Email</Typography>
                                            <Switch
                                            className={classes.switch}
                                            size="small"
                                            color={!state.isEditPressed ? '#767676' : 'primary'}
                                            checked={state.checkedEmail}
                                            onChange={handleChange}
                                            name="checkedEmail"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            disabled={org.appSettingsJanitor && !org.appSettingsJanitor.emailToggle}
                                            />
                                        </Box>
                                        {state.checkedEmail && 
                                            <FormControl className={classes.formControl}>
                                            <Select
                                            value={state.daysBeforeReminderEmail}
                                            onChange={handleChange}
                                            displayEmpty
                                            name="daysBeforeReminderEmail"
                                            disableUnderline={true}  
                                            className={classes.selectEmpty}
                                            inputProps={{ 'aria-label': 'Without label', disableUnderline: true}}
                                            >
                                                <MenuItem value="" disabled>
                                                    Choose Timeframe
                                                </MenuItem>
                                                <MenuItem value={"1"}>1  day before</MenuItem>
                                                <MenuItem value={"2"}>2  days before</MenuItem>

                                                {/* <MenuItem value={"2"}>2 days before (9AM)</MenuItem>
                                                <MenuItem value={"3"}>3 days before (9AM)</MenuItem>
                                                <MenuItem value={"4"}>4 days before (9AM)</MenuItem>
                                                <MenuItem value={"5"}>5 days before (9AM)</MenuItem>
                                                <MenuItem value={"6"}>6 days before (9AM)</MenuItem>
                                                <MenuItem value={"7"}>7 days before (9AM)</MenuItem> */}
                                            </Select>
                                            </FormControl>
                                        }

                                        <Box className={classes.labelWithEdit}>
                                            <IconButton style={{"padding-left": 0}} aria-label="SMS"><SmsIcon/></IconButton>
                                            <Typography className={classes.labelSmall} style={{"margin": "auto 0"}} >SMS</Typography>
                                            <Switch 
                                            className={classes.switch}
                                            size="small"
                                            checked={state.checkedSms}
                                            onChange={handleChange}
                                            color={!state.isEditPressed ? '#767676' : 'primary'}
                                            name="checkedSms"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            disabled={org.appSettingsJanitor && !org.appSettingsJanitor.voiceToggle}
                                            />
                                        </Box>
                                        {state.checkedSms && 
                                            <FormControl className={classes.formControl}>
                                            <Select
                                            value={state.daysBeforeReminderSms}
                                            onChange={handleChange}
                                            name="daysBeforeReminderSms"
                                            displayEmpty
                                            disableUnderline={true}  
                                            className={classes.selectEmpty}
                                            inputProps={{ 'aria-label': 'Without label', disableUnderline: true}}
                                            >
                                            <MenuItem value="" disabled>
                                                Choose Timeframe
                                            </MenuItem>
                                            <MenuItem value={"1"}>1 day before (9AM)</MenuItem>
                                            <MenuItem value={"2"}>2 days before (9AM)</MenuItem>
                                            <MenuItem value={"3"}>3 days before (9AM)</MenuItem>
                                            <MenuItem value={"4"}>4 days before (9AM)</MenuItem>
                                            <MenuItem value={"5"}>5 days before (9AM)</MenuItem>
                                            <MenuItem value={"6"}>6 days before (9AM)</MenuItem>
                                            <MenuItem value={"7"}>7 days before (9AM)</MenuItem>
                                            </Select>
                                            </FormControl>
                                        }

                                        <Box className={classes.labelWithEdit}>
                                            <IconButton style={{"padding-left": 0}} aria-label="Voice"><MicIcon/></IconButton>
                                            <Typography className={classes.labelSmall} style={{"margin": "auto 0"}} >Voice</Typography>
                                            <Switch 
                                            className={classes.switch}
                                            size="small"
                                            checked={state.checkedVoice}
                                            onChange={handleChange}
                                            color={!state.isEditPressed ? '#767676' : 'primary'}
                                            name="checkedVoice"
                                            disabled
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            // disabled={org.appSettingsJanitor && !org.appSettingsJanitor.voiceToggle}
                                            />
                                        </Box>
                                        {state.checkedVoice && 
                                            <FormControl className={classes.formControl}>
                                            <Select
                                            value={state.daysBeforeReminderVoice}
                                            onChange={handleChange}
                                            name="daysBeforeReminderVoice"
                                            displayEmpty
                                            disableUnderline={true}  
                                            className={classes.selectEmpty}
                                            inputProps={{ 'aria-label': 'Without label', disableUnderline: true}}
                                            >
                                            <MenuItem value="" disabled>
                                                Choose Timeframe
                                            </MenuItem>
                                            <MenuItem value={"1"}>1 day before (9AM)</MenuItem>
                                            <MenuItem value={"2"}>2 days before (9AM)</MenuItem>
                                            <MenuItem value={"3"}>3 days before (9AM)</MenuItem>
                                            <MenuItem value={"4"}>4 days before (9AM)</MenuItem>
                                            <MenuItem value={"5"}>5 days before (9AM)</MenuItem>
                                            <MenuItem value={"6"}>6 days before (9AM)</MenuItem>
                                            <MenuItem value={"7"}>7 days before (9AM)</MenuItem>
                                            </Select>
                                            </FormControl>
                                        }
                                    </Grid>
                                    
                                    <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}}/>
                                    
                                    <Grid item md={9} sm={12} xs={12}>
                                        <div style={{"padding" : '0 25px'}}>
                                        <Grid container style={{"width": "100%"}}>
                                            <Grid item md={12}>
                                                <Typography className={classes.labelSmall} >Reminder Message</Typography>
                                                <Typography className={classes.subLabel} >Please select the default reminder message or enter a custom reminder message.</Typography>
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <Box className={classes.labelWithEdit} style ={{"width": "100%"}}>
                                                    <Typography className={classes.labelSmall} >Custom Message</Typography>
                                                    <Switch
                                                        className={classes.switch}
                                                        size="small"
                                                        checked={state.checkedCustomMessage}
                                                        onChange={handleChange}
                                                        color={!state.isEditPressed ? '#767676' : 'primary'}
                                                        name="checkedCustomMessage"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </Box>
                                                {state.checkedCustomMessage ? [
                                                <Typography className={classes.subLabel}>Message Legend</Typography> ,
                                                <Typography className={classes.subLabel} style={{color: "#003FBA"}}>_Patient_ , _Clinic_ , _Doctor_ , _Date_ , _Time_ , _Bill:#_ , _Cancel:#_</Typography> ,
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    //label="Multiline"
                                                    multiline
                                                    name="emailMessage"
                                                    rows={4}
                                                    onChange={handleChange}
                                                    disabled={!state.checkedCustomMessage}
                                                    value={state.emailMessage}
                                                    variant="outlined"
                                                    style={{"width": "100%", "marginTop": "15px", "marginBottom": "40px"}}
                                                />
                                                ] :
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    // label="Multiline"
                                                    multiline
                                                    name="emailMessage"
                                                    rows={4}
                                                    onChange={handleChange}
                                                    disabled={!state.checkedCustomMessage}
                                                    value= 'This is a reminder for _Patient_ from _Clinic_. You have an appointment with _Doctor_ on _Date_ at _Time_. Please note that you will be billed _Bill:#_ for appointments cancelled within _Cancel:#_.'
                                                    variant="outlined"
                                                    style={{"width": "100%", "marginTop": "15px", "marginBottom": "40px"}}
                                                />
                                            }
                                            </Grid>
                                        </Grid>
                                        </div>

                                        <Grid item md={12} sm={12} xs={12}><Divider className={classes.divider} /></Grid>
                                        
                                        <div style={{"padding" : "0 25px"}}>
                                        <Grid container>
                                            {/* <Grid item md={12} sm={12} xs={12}>
                                                <Typography className={classes.labelSmall} >Voice Message</Typography>
                                                <Typography className={classes.subLabel}>Please upload a voice recording MP3 file, or use the text-to-speech option to generate a voice message file.</Typography>

                                              <input
                                                accept=".mp3"
                                                style={{"display": "none"}}
                                                className={classes.input}
                                                id="contained-button-file"
                                                multiple
                                                onChange={fileUploadButton}
                                                type="file"
                                                disabled
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button
                                                        variant="contained"
                                                        color="default"
                                                        component="span"
                                                        style={{backgroundColor: !state.isEditPressed && '#f9f9f9'}}
                                                        className={classes.buttonUpload}
                                                        startIcon={<AddIcon />}
                                                        disabled
                                                    >
                                                    Upload
                                                    </Button>
                                                </label>
                                                
                                                {state.currentUploadedFile && 
                                                    <Box className={classes.labelWithEdit} style ={{"width": "100%", "marginTop": "15px"}}>
                                                        <Typography >{state.currentUploadedFile.name}</Typography>
                                                        <ButtonGroup variant="text" size="small" aria-label="text primary button group">
                                                            
                                                            <IconButton aria-label="View" target = '_blank' href={state.currentUploadedFile.location}><VisibilityIcon /></IconButton>
                                                            <IconButton aria-label="Download" download={state.currentUploadedFile.name} href={'./'+state.currentUploadedFile.location}><GetAppIcon/></IconButton>
                                                           
                                                            <IconButton aria-label="Delete" onClick={() => setState({ ...state, currentUploadedFile: null })}><DeleteIcon/></IconButton>
                                                        </ButtonGroup>
                                                    </Box>
                                                }
                                                {
                                                    state.records &&
                                                    state.records.map((record, i) => (
                                                        <Box key={i} className={classes.labelWithEdit} style ={{"width": "100%", "marginTop": "15px"}}>
                                                            <Typography >{record.name}</Typography>
                                                            <ButtonGroup variant="text" size="small" aria-label="text primary button group">
                                                                <IconButton aria-label="View" target = '_blank' href={record.location}><VisibilityIcon /></IconButton>
                                                                <IconButton aria-label="Download" onClick={() => fileRetrieveFromAWS(record.key, record.name)}><GetAppIcon/> </IconButton>
                                                                <IconButton aria-label="Delete" onClick={() => DeleteUploadedFileDB(record.key, record.name)}><DeleteIcon/></IconButton>
                                                            </ButtonGroup>
                                                        </Box>
                                                    ))
                                                }

                                            </Grid> */}
                                            <Grid item md={12} sm={12} xs={12}>
                                                <Box className={classes.labelWithEdit} style ={{"width": "100%"}}>
                                                    <Typography className={classes.labelSmall} >Text-To-Speech</Typography>
                                                    <Switch 
                                                        className={classes.switch}
                                                        size="small"
                                                        checked={state.checkedTextToSpeech}
                                                        onChange={handleChange}
                                                        color={!state.isEditPressed ? '#767676' : 'primary'}
                                                        name="checkedTextToSpeech"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled
                                                    />
                                                </Box>
                                                {state.checkedTextToSpeech && [
                                                    <Typography className={classes.subLabel}>Message Legend</Typography> ,
                                                    <Typography className={classes.subLabel} style={{color: "#003FBA"}}> _Patient_ , _Clinic_ , _Doctor_ , _Date_ , _Time_ , _Bill:#_ , _Cancel:#_</Typography> ,
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        //label="Multiline"
                                                        multiline
                                                        name="voiceMessage"
                                                        rows={4}
                                                        onChange={handleChange}
                                                        disabled={!state.checkedTextToSpeech}
                                                        value={state.voiceMessage}
                                                        variant="outlined"
                                                        style={{"width": "100%", "marginTop": "15px", "marginBottom": "25px"}}
                                                        disabled
                                                        
                                                    />
                                                ]}
                                            </Grid>
                                        </Grid>
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid item md={12} sm={12}><Divider className={classes.divider} /></Grid>
                        {state.isEditPressed ?
                            (<Grid item md={12} sm={12} >
                                <Box item md={12} sm={12} className={clsx(classes.labelWithEdit, classes.labelWithEditBottomSpacing)}>
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        {state.isLoading ? 
                                            (<CircularProgress className={classes.loadingCircle} />) : 
                                            <Button md={6} sm={12} xs={12} className={clsx(classes.saveChangesBtn, classes.editToggleBtn, classes.saveChangeCheckinBtn)} 
                                                variant="outlined" color={state.btnColor} onClick={saveSettings}>Save Changes 
                                            </Button>
                                        }
                                    </Box>
                                        <Button md={6} sm={12} xs={12} className={clsx(classes.editToggleBtn, classes.saveChangeCheckinBtn)} 
                                            variant="outlined" color={state.btnColor} onClick={() => loadOldState()}>Cancel
                                        </Button>
                                </Box>
                            </Grid>)
                        :
                            (<Grid item md={12} sm={12}>
                                <Box item md={12} sm={12} className={clsx(classes.labelWithEdit, classes.labelWithEditBottomSpacing)}>
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        <Button md={5} sm={12} className={classes.editToggleBtn} 
                                            variant="outlined" color={state.btnColor} onClick={() => saveOldState()}>Edit
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>)
                        }
                    </Collapse>
                </Grid>
            </Grid>

        </Paper>
    );
}else {
    return(<div></div>)
}
}

export default RoomURL;
