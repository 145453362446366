import { Box, Button, CircularProgress, Collapse, Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from "../../../../context/GlobalContext";
import settingsStyles from '../../../../styles/settingsPageStyles';
import LoginInfo from './components/LoginInfo';
import Notifications from './components/Notifications';

const AccountSetting = () => {
    const classes = settingsStyles()
    const global = useContext(GlobalContext)
    const [expanded, setExpanded] = useState(false)
    let user = global.state.user && global.state.user.document ? global.state.user.document : {}
    let defaultUser = {
        isEdit: false,
        isLoading: false,
        user: user,
        password: '',
    }
    const [state, setState] = useState(defaultUser)
    const [email, setEmail] = useState(false)
    const [browser, setBrowser] = useState(false)
    const [oldEmailState, setOldEmailState] = useState()
    const [oldBrowserState, setOldBrowserState] = useState()
    let org = global.state.selectedOrg != 'undefined' && global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0 ? global.state.selectedOrg : global.state.defaultOrg
    const handleExpandClick = () => {
        setExpanded(!expanded);
    }
    let orgId = global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id

    useEffect(() => {
        getAccountSettings();
    }, [])

    const getAccountSettings = async () => {
        try {
            let res = await global.api.fetchAccountSettings(orgId);
            if (res.status === 200) {
                if(res.data.result.account_settings?.emailEnabled) {
                    setEmail(res.data.result.account_settings.emailEnabled);
                }
                if(res.data.result.account_settings?.browserEnabled) {
                    setBrowser(res.data.result.account_settings.browserEnabled)
                }
            }
        } catch (error) {

        }

    }
    const saveChanges = async () => {
        setState({ ...state, isLoading: true })
        let result = await global.api.updateAccount({
            id: user._id,
            password: state.password,
            orgId: global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id,
        })

        let notificationSettingsResult = await global.api.updateNotificationSettings(orgId, email, browser);

        if (result && result.status === 200 && notificationSettingsResult && notificationSettingsResult.status === 200 ) {
            if (state.password) {
                alert('Password updated. Please re-login your account.')
                await global.api.logout()
                return window.location.reload()
            }
            global.setState((prevState) => ({ ...prevState, user: { document: user } }));
           
            alert('Update account settings successful.')
        } else {
            setState(defaultUser)
            // alert('Update account settings failed.')
        }
         
        setState(defaultUser)
    }

  /*  useEffect(() => {
        const currentOrg = global?.state?.selectedOrg ? global.state.selectedOrg : global.state.defaultOrg;

        setCurrentOrgId(currentOrg._id);
        if(currentOrg?.account_settings) {
            setAccountSettings(currentOrg.account_settings)
        }
    }, [global, global.state, global.state.selectedOrg ])*/

    const saveOldState = () => {
        setOldEmailState(email)
        setOldBrowserState(browser)
    }

    const loadOldState = () => {
      setEmail(oldEmailState)
       setBrowser(oldBrowserState)
    }

    const handleEmailChange = () => {
      setEmail(!email);      
    }
   
    const handleBrowserChange = () => {
        setBrowser(!browser);
    }

    const rootClassNames = clsx({
        [classes.rootExpanded]: expanded,
        [classes.root]: !expanded,
        [classes.disabled]: org.disabled
    })
    return (
        <Grid container component={Paper} elevation={3} className={rootClassNames}>
            <Grid item xs={12} className={classes.paperComponment}>
                <Box onClick={handleExpandClick} className={`${classes.labelWithEdit} ${classes.labelWithEditDropdown}`}>
                    <Typography className={clsx(classes.label, {
	                        	[classes.expandedLabel]: expanded
	                        })}>Account Settings</Typography>
                    <Typography className={classes.edit}>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            disabled={org.disabled}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <Grid container>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className={!state.isEdit ? classes.disabledBackground : ''}>
                                <Grid item md={6} xs={12} className={classes.dividerBorder}>
                                    <LoginInfo user={state.user} setPassword={(newPassword) => setState({ ...state, password: newPassword })} />
                                </Grid>
                                <Grid item md={6} xs={12} className={classes.dividerBorder}>
                                    <Notifications email={email} browser={browser} handleEmailChange={handleEmailChange} handleBrowserChange={handleBrowserChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            {state.isEdit ?
                                <Box>
                                    {state.isLoading ?
                                        <Box display='flex' justifyContent='flex-end'>
                                            <CircularProgress className={classes.loadingCircle} />
                                        </Box>
                                        :
                                        <Box display='flex' justifyContent='flex-end' className={classes.saveBtnGroup}>
                                            <Button variant="outlined" color='primary' onClick={saveChanges}>
                                                Save Changes
                                            </Button>
                                            <Button variant="outlined" color='primary' className={classes.saveChangesBtn} onClick={() => {setState(defaultUser); loadOldState()}}>
                                                Cancel
                                            </Button>
                                        </Box>
                                    }
                                </Box>
                                :
                                <Box display='flex' justifyContent='flex-end' className={classes.saveBtnGroup}>
                                    <Button xs={12} variant="outlined" color='primary' onClick={() => {setState({ ...state, isEdit: true }); saveOldState()}}>
                                        Edit
                                    </Button>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    )
}

export default AccountSetting;
