/*
 * @Author: Andy Qiu
 * @Date: 2020-10-30 11:50:12
 * @LastEditors: Andy Qiu
 * @LastEditTime: 2020-10-30 11:50:12
 * @Description: remove patient from queue modal
 */

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

export default function RemoveDialog({onClose, selectedValue, open, onRemove}) {
    const handleClose = () => {
        onClose(selectedValue);
    };
    
    const handleCloseRemoveQueue = (value) => {
        onRemove()
        onClose(value);
    };

    let patientName;
    for (let i in selectedValue) {
        if (selectedValue[i].name) {
            patientName = selectedValue[i].name
        }
    }
    
    return (
        <div>
            <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='sm'
            >
                <DialogTitle>{"Remove Appointment?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{color:"#000000"}}>
                        Are you sure you want to remove {patientName} <br></br> appointment from the queue? <br></br>You will not be able to revert this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{margin: "auto",marginRight: "55%" }} onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCloseRemoveQueue} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>   
        </div>
    )
}
