import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React from "react";

export default function CheckInForms(props) {
    const classes = props.classes;
    const state = props.state;
    const setState = props.setState;
    const handleChange = props.handleChange;
    const fileUploadButton = props.fileUploadButton;
    const fileRetrieveFromAWS = props.fileRetrieveFromAWS;
    const DeleteUploadedFileDB = props.DeleteUploadedFileDB;

    return (
        <div style={{ padding: "0 25px" }}>
            {/* <Grid item md={12} xs={12}>
                <Box
                    className={classes.labelWithEdit}
                    style={{ width: "100%" }}
                >
                    <Typography className={classes.labelSmall}>
                        Check-In Forms
                    </Typography>
                    <Switch
                        className={classes.switch}
                        size="small"
                        checked={state.checkedEnableForms}
                        onChange={handleChange}
                        color={!state.isEditPressed ? "#767676" : "primary"}
                        name="checkedEnableForms"
                        inputProps={{ "aria-label": "primary checkbox" }}
                        disabled={!state.isEditPressed}
                    />
                </Box>
                <input
                    accept=".pdf"
                    style={{ display: "none" }}
                    className={classes.input}
                    multiple
                    id="fileButton"
                    onChange={fileUploadButton}
                    type="file"
                />
                <label htmlFor="fileButton">
                    <Button
                        style={{
                            backgroundColor: !state.isEditPressed && "#f9f9f9",
                        }}
                        variant="contained"
                        color="default"
                        component="span"
                        className={classes.buttonUpload}
                        startIcon={<AddIcon />}
                    >
                        Upload File
                    </Button>
                </label>

                {state.currentUploadedFile && (
                    <Box
                        className={classes.labelWithEdit}
                        style={{ width: "100%", marginTop: "15px" }}
                    >
                        <Typography>
                            {state.currentUploadedFile.name}
                        </Typography>
                        <ButtonGroup
                            variant="text"
                            size="small"
                            aria-label="text primary button group"
                        >
                            <IconButton
                                aria-label="Delete"
                                onClick={() =>
                                    setState({
                                        ...state,
                                        currentUploadedFile: null,
                                    })
                                }
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ButtonGroup>
                    </Box>
                )}
                {state.forms &&
                    state.forms.map((form, i) => (
                        <Box
                            key={i}
                            className={classes.labelWithEdit}
                            style={{
                                width: "100%",
                                marginTop: "15px",
                                backgroundColor: "#EAEAEA",
                                borderRadius: "5px",
                            }}
                        >
                            <Typography
                                style={{ padding: "2%", paddingLeft: "2.5%" }}
                            >
                                {form.name}
                            </Typography>
                            <Grid>
                                <IconButton
                                    aria-label="View"
                                    target="_blank"
                                    href={form.location}
                                    style={{ marginRight: "-5%" }}
                                >
                                    <VisibilityIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="Download"
                                    onClick={() =>
                                        fileRetrieveFromAWS(form.key, form.name)
                                    }
                                    style={{ marginRight: "-5%" }}
                                >
                                    <GetAppIcon />{" "}
                                </IconButton>
                                <IconButton
                                    aria-label="Delete"
                                    onClick={() =>
                                        DeleteUploadedFileDB(
                                            form.key,
                                            form.name
                                        )
                                    }
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Box>
                    ))}
            </Grid> */}
        </div>
    );
}
