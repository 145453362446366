/*
 * @Author: Andy Qiu
 * @Date: 2020-10-30 11:50:12
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-12-07 14:26:53
 * @Description: remove patient from queue modal
 */

import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import moment from 'moment';
import React, { useContext, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";

const useStyles = makeStyles({
  text: {
    display: "inline-block",
    width: "100px",
    color: "black",
    fontWeight: "500",
  },
  continue: {
    background: '#118761',
    color: 'white',
    borderRadius: '7px',
    padding: '0.5rem 1rem',
    margin: '1rem'
},
});

export default function PatientContact({ onClose, info, open, doctors, org, cache, ttl, date }) {
    const global = useContext(GlobalContext)
    const classes = useStyles();
    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    if (info) {
        function getDoctorName(post) {
            const doctor = doctors.find(x => x.providerNo === String(post))
            if (doctor) {
                return `Dr. ${doctor.firstName} ${doctor.lastName}`
            }
        }

        function typeName(type) {
            if (type === 'email') {
                return 'Email'
            } else {
                return 'Phone Number'
            }
        }
        
        function typeAction(type) {
            if (type === 'email') {
                return 'Send Email'
            } else if (type === 'phone') {
                return 'Send Text'
            } else if (type === 'voice') {
                return 'Send Call'
            }
        }
        
        const handleSend = async () => {
            try {
                setLoading(true)
                let sendNotification = await global.api.sendNotification({
                    communicationSetting: org.commcommunicationSetting,
                    type: info.type,
                    email: info.type === 'email' ? info.string : null,
                    phoneNum: info.type === 'phone' ? info.string : null,
                    voiceNum: info.type === 'voice' ? info.string : null,
                    id: info.info.id,
                    name: info.info.name,
                    reason: info.info.reason ? info.info.reason : 'N/A',
                    orgName: org.org_name,
                    date: moment(info.info.appointmentDate).format('LL'),
                    startTime: moment(info.info.appointmentStartDateTime).format('LT'),
                    endTime: moment(info.info.appointmentEndDateTime).format('LT'),
                    eventType: info.info.type ? info.info.type : 'In-Person',
                    address: org.address,
                    logo: org.logo ? org.logo : 'https://porton-shared.s3.ca-central-1.amazonaws.com/images/porton-main-logo.png',
                    drName: getDoctorName(info.info.providerNo),
                    teleNum: info.type === 'phone' || info.type === 'voice' ? info.string : null
                })
                //console.log('sendNotification', sendNotification)
                if (sendNotification.status === 200) {
                    const key = `${org._id}_sentNotification`
                    cache[date][info.info.id] = 'sent'
                    await global.api.postCache({key: key, value: cache, ttl: ttl})
                    setLoading(false);
                    setSent(true);
                    setError(false);
                } else {
                    setLoading(false);
                    setError(true);
                }
            } catch(error) {
                // console.log('error', error)
            }
        }

        const handleClose = () => {
            setSent(false)
            setLoading(false)
            setError(false)
            onClose();
        };

        return (
            <div>
                <Dialog open={open} onClose={handleClose} style={{overflow: 'hidden'}}>
                    <DialogTitle>
                        Notify Patient
                    </DialogTitle>                   
                    <DialogContent>
                        <Typography>{typeName(info.type)}: {info.string}</Typography>
                    </DialogContent>
                    {loading && <DialogContent><CircularProgress /></DialogContent>}
                    {sent && <DialogContent><CheckCircleOutlineIcon fontSize="large" style={{color: "#003FBA"}}/></DialogContent>}
                    {cache[date][info.info.id] === 'sent' && !sent && <DialogContent><CheckCircleOutlineIcon fontSize="large" style={{color: "#003FBA"}}/></DialogContent>}
                    {!loading && !sent && cache[date][info.info.id] !== 'sent' &&
                    <Button variant="contained" className={classes.continue} onClick={handleSend}>
                        {typeAction(info.type)}
                    </Button>
                    }
                    {error && <DialogContent>Could not send, please try again</DialogContent>}
                </Dialog>
            </div>
        );    
    } else {
        return (<></>)
    } 
}
