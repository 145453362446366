import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import GlobalContext from '../context/GlobalContext';
import PasswordChecklist from "react-password-checklist";
import Header from './LoginHeader.js';
import Footer from './LoginFooter.js';
import {
    IconButton,
    InputAdornment,
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    OutlinedInput,
    Paper,
    Typography,
    TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import LoginPageStyles from "./styles/LoginPageStyle";

export default function ChangePass() {
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState({ newPassword: '' });
    const [showConfirmationPage, setShowConfirmationPage] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState({ newPasswordConfirm: '' });
    const [showPass, setShowPass] = useState(false);
    const [showPassConfirm, setShowPassConfirm] = useState(false);
    const [passChanged, setPassChanged] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validToken, setValidToken] = useState(true);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [email, setEmail] = useState("");
    const [userId, setUserId] = useState("");
    const [orgId, setOrgId] = useState("");

    const [passwordValid, setPasswordValid] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [error, setError] = useState({ password: false, password_confirm: false });

    let { resetToken } = useParams();
    const history = useHistory();

    const tokenValid = async () => {
        const result = await global.api.resetTokenValidate(resetToken);
        if (result.status !== 200) {
            setValidToken(false);
        }
        if (result.status === 200) {
            setEmail(result.data.data.user.document.emails[0].address);
            setUserId(result.data.data.user.document._id);
            setOrgId(result.data.data.user.document.default_org);
        }
    }

    const handleSetPassword = (pass) => {
        setPassword({ newPassword: pass });
    }

    useEffect(() => {
        tokenValid();
    }, []);

    useEffect(() => {
        if (password.newPassword === passwordConfirm.newPasswordConfirm && passwordConfirm.newPasswordConfirm !== "") {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
        }
    }, [passwordConfirm]);

    useEffect(() => {
        if(!error.password || !error.password_confirm || !password.newPassword.length === 0 || !passwordConfirm.newPasswordConfirm.length === 0 || passwordConfirm.newPasswordConfirm === password.newPassword){
            setPasswordValid(true)
        }
    }, [password, passwordConfirm, error]);

    const routeLogin = () => {
        let path = `/login`;
        history.push(path);
    }

    const routeForgotPass = () => {
        let path = `/forgotPassword`;
        history.push(path);
    }

    const handleShowPassword = () => {
        setShowPass(!showPass);
    };

    const handleShowPasswordConfirm = () => {
        setShowPassConfirm(!showPassConfirm);
    };

    const handleChangePass = async (e) => {
        e.preventDefault();
        if (passwordValid) {
            setLoading(true);
            try {
                const data = { userId: userId, newPassword: password.newPassword, orgId: orgId };
                const result = await global.api.changePass(data);
                if (result && result.status === 200) {
                    setSubmitted(true);
                    setLoading(false);
                    setPassChanged(true);
                } else {
                    setLoading(false);
                    alert("Error while updating password. Please try again");
                }
            } catch (error) {
                setLoading(false);
                alert("An error occurred while updating password. Please try again.");
            }
        }
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
        loginPaper: {
            width: '60%',
            maxWidth: '650px',
            height: 'auto',
            padding: '35px 35px 35px 35px',
            borderRadius: '8px',
            marginTop: '-4%',
            [theme.breakpoints.down(1200)]: {
                width: '44%',
            },
            [theme.breakpoints.down(1100)]: {
                width: '60%',
            },
            [theme.breakpoints.down(600)]: {
                width: '80%',
            },
            [theme.breakpoints.down(300)]: {
                width: '90%',
            },
            [theme.breakpoints.down(310)]: {
                marginLeft: '2rem',
            },
            [theme.breakpoints.down(290)]: {
                marginLeft: '3rem',
            },
        },
    }));

    const classes = useStyles();
    const styles = LoginPageStyles();

    return (
        <div className={styles.loginPage}>
            <Header />

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="70vh"
                className={styles.loginBox}
            >
                <Paper className={classes.loginPaper} elevation={3}>
                    {validToken && !submitted &&
                        <Box>
                            <Typography align='center' className={styles.signInTypography} variant='h4'>Reset Your Password</Typography>
                            <Typography className='email'>{email}</Typography>
                            {!passwordMatch &&
                                <Typography className='invalidEmail'>Passwords Do Not Match</Typography>
                            }
                            {!passwordValid &&
                                <Typography className='invalidEmail'>{errorMsg}</Typography>
                            }
                            <form onSubmit={handleChangePass}>
                                <Typography className='description'>Use 12 or more characters with a mix of letters, numbers and special characters.</Typography>
                                <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" style={{ display: 'block', width: '100%' }}>
                                    <TextField
                                        id="password"
                                        label="Password"
                                        type="password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        onFocus={() => setShowConfirmationPage(true)}
                                        value={password.newPassword}
                                        variant="outlined"
                                        size="small"
                                        onChange={e => { setPassword({ ...password, newPassword: e.target.value }) }}
                                        onBlur={(e) => {
                                            setError({
                                                ...error,
                                                password:
                                                    !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{12,}/.test(
                                                        password.newPassword
                                                    ),
                                            });
                                            setShowConfirmationPage(false);
                                        }}
                                        required
                                        error={error["password"]}
                                    />

                                </FormControl>
                                <FormControl>
                                    {showConfirmationPage && (
                                        <PasswordChecklist
                                            rules={["minLength", "specialChar", "number", "capital"]}
                                            minLength={12}
                                            value={password.newPassword}
                                            onChange={(isValid) => { }}
                                            style={{ marginBottom: "20px" }}
                                        />
                                    )}
                                </FormControl>



                                <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" style={{ display: 'block', width: '100%' }}>
                                    <TextField
                                        id="password_confirm"
                                        label="Confirm Password"
                                        type="password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        value={passwordConfirm.newPasswordConfirm}
                                        size="small"
                                        onChange={e => { setPasswordConfirm({ ...passwordConfirm, newPasswordConfirm: e.target.value }) }}
                                        required
                                        onBlur={(e) => {
                                            setError({
                                                ...error,
                                                password_confirm: passwordConfirm.newPasswordConfirm !== password.newPassword,
                                            });
                                        }}
                                        error={error["password_confirm"]}
                                        helperText={
                                            passwordConfirm.newPasswordConfirm &&
                                            passwordConfirm.newPasswordConfirm !== password.newPassword &&
                                            "Please ensure that your passwords match"
                                        }
                                    />
                                </FormControl>
                                <Box display='flex' justifyContent='center' className='signInBox'>
                                    {loading ?
                                        <CircularProgress className='circularProgress' />
                                        :
                                        <Button type="submit" variant='contained' className='signInButton' style={{ 'marginLeft': '6px' }} fullWidth
                                                disabled={error.password || error.password_confirm || password.newPassword.length === 0 || passwordConfirm.newPasswordConfirm.length === 0 || passwordConfirm.newPasswordConfirm !== password.newPassword}>RESET PASSWORD</Button>
                                    }
                                </Box>
                            </form>
                        </Box>
                    }
                    {!validToken &&
                        <Box>
                            <Typography align='center' className='SignInTypography' variant='h4'>Link Not Valid</Typography>
                            <Typography className='description'>Your link is not valid or has expired. Please reset your password again</Typography>
                            <form onSubmit={() => { routeForgotPass() }}>

                                <Box display='flex' justifyContent='center' marginTop='1.5rem' className='signInBox'>
                                    {loading ?
                                        <CircularProgress className='circularProgress' />
                                        :
                                        <Button type="submit" variant='contained' className='signInButton' style={{ 'marginLeft': '6px' }} fullWidth>RESET PASSWORD</Button>
                                    }
                                </Box>
                            </form>
                        </Box>
                    }
                    {passChanged &&
                        <Box>
                            <Typography align='center' className='SignInTypography' variant='h4'>Password Reset Successful</Typography>
                            <Typography className='description'>Your password has been reset. You may use this password to sign in henceforth.</Typography>
                            <form onSubmit={() => { routeLogin() }}>

                                <Box display='flex' justifyContent='center' marginTop='1.5rem' className='signInBox'>
                                    {loading ?
                                        <CircularProgress className='circularProgress' />
                                        :
                                        <Button type="submit" variant='contained' className='signInButton' style={{ 'marginLeft': '6px' }} fullWidth>SIGN IN</Button>
                                    }
                                </Box>
                            </form>
                        </Box>
                    }
                </Paper>

            </Box>
            {/* Footer */}
            <Footer />
            {/* <LoginFooterContainer/> old footer */}
        </div>
    )
}
