/*
 * @Author: Vincent Tran
 * @Date: 2020-07-29 04:30:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-07-31 03:54:00
 * @Description: Implemented Popover modals
 */
/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-10 02:55:49
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-07-14 05:32:19
 * @Description: file content
 */

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';
import UserPageStyles from '../../../../styles/usersPageStyles';
import AddAppointment from "./meatBallOptions/AddAppointment";
import AddToGroup from "./meatBallOptions/AddToGroup";
import DisablePopup from "./meatBallOptions/DisableEnable";
import EditAdminProfile from './meatBallOptions/EditAdminProfile';
import EditNotification from './meatBallOptions/EditNotification';
import EditPatientProfile from './meatBallOptions/EditPatientProfile';
import EditPaymentMethod from "./meatBallOptions/EditPaymentMethod";
import EditProviderAccount from './meatBallOptions/EditProviderAccount';
import EditProviderProfile from './meatBallOptions/EditProviderProfile';
import EditServices from "./meatBallOptions/EditServices";
import ForceResetPass from "./meatBallOptions/ForceResetPass";
import GenerateReport from "./meatBallOptions/GenerateReport";
import MakePayout from "./meatBallOptions/MakePayout";
import ModifyRole from "./meatBallOptions/ModifyRole";
import RemoveUser from "./meatBallOptions/RemoveUser";
import Transactions from "./meatBallOptions/Transactions";
import UnlinkFromOrganization from "./meatBallOptions/UnlinkFromOrganization";

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#DBDBDBD",
    '&:hover': {
      backgroundColor: "#CCCCCC",
      boxShadow: "none",
    },
    borderRadius: "1.5rem",
    fontSize: "0.875rem",
    margin: 0,
    padding: "0.3rem ",
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 450
  },
}))(IconButton);

function MeatBall(props) {
  const classes = UserPageStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const globalState = props.globalState;
  const id = open ? 'simple-popover' : undefined;
  const _id = props._id;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }
  

  return (
    <Box>
      <ColorButton children={<MoreHorizIcon />} aria-describedby={id} variant="contained" onClick={handleClick}/>
      
      <Popover
        className={classes.popOver}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          props.type == 'patients' &&
          <div>
            <AddAppointment info={props} className={classes.popOverItemLayout} handleClose={handleClose}/>
            <AddToGroup info={props} className={classes.popOverItemLayout} handleClose={handleClose} refresh={props.refresh}/>
            <EditPatientProfile className={classes.popOverItemLayout} account={props.account} handleClose={handleClose} refresh={props.refresh} doctors={props.doctors} />
            
            {globalState.user && (globalState.user.document.roles[0] === 'healthOrgAdmin' || globalState.user.document.roles[0] === 'admin') &&              
            <ModifyRole className={classes.popOverItemLayout} account={props.account}/>
            }
            <EditNotification className={classes.popOverItemLayout} account={props.account} handleClose={handleClose} refresh={props.refresh} doctors={props.doctors} />
            {globalState.user && (globalState.user.document.roles[0] === 'healthOrgAdmin' || globalState.user.document.roles[0] === 'admin') &&
              <DisablePopup className={classes.popOverItemLayout} account={props.account} type={props.type} handleClose={handleClose} email={props.account.email} refresh={props.refresh} />
              
            }
            {globalState.user && (globalState.user.document.roles[0] === 'healthOrgAdmin' || globalState.user.document.roles[0] === 'admin') && 
              <>
             {/* <MedicationRecord info={props} className={classes.popOverItemLayout} handleClose={handleClose}/> */}

              <ForceResetPass className={classes.popOverItemLayout} account={props.account}/>
              <RemoveUser className={classes.popOverItemLayout} account={props.account} type={props.type} handleClose={handleClose} refresh={props.refresh}/>
              </>
              
            }
            {globalState.user && globalState.user.document.roles[0] === 'oa' &&
                <>
                  <ForceResetPass className={classes.popOverItemLayout} account={props.account}/>
                </>
                
            }
          </div>
        }
        
        {
          props.type == 'providers' && globalState.user.document.roles[0] !== 'oa' &&
          <div>
            <AddAppointment info={props} className={classes.popOverItemLayout} handleClose={handleClose}/>
            <AddToGroup className={classes.popOverItemLayout} />
            <EditProviderProfile className={classes.popOverItemLayout} account={props.account} handleClose={handleClose} refresh={props.refresh} />
            <EditProviderAccount className={classes.popOverItemLayout} account={props.account} handleClose={handleClose} refresh={props.refresh} />
            <EditServices _id={_id} className={classes.popOverItemLayout} handleClose={handleClose} />
            <EditPaymentMethod className={classes.popOverItemLayout} />
            <MakePayout className={classes.popOverItemLayout} />
            <GenerateReport className={classes.popOverItemLayout} />
            <Transactions className={classes.popOverItemLayout} />
            <DisablePopup className={classes.popOverItemLayout} account={props.account} type={props.type} handleClose={handleClose} refresh={props.refresh} />
            <RemoveUser className={classes.popOverItemLayout} account={props.account} type={props.type} handleClose={handleClose} refresh={props.refresh}/>
            <UnlinkFromOrganization className={classes.popOverItemLayout} account={props.account} handleClose={handleClose} refresh={props.refresh} />
            <ForceResetPass className={classes.popOverItemLayout} account={props.account} />
          </div>
        }

        {
          props.type == 'providers' && globalState.user.document.roles[0] === 'oa' &&
          <div>
            <AddAppointment info={props} className={classes.popOverItemLayout} handleClose={handleClose}/>
            <EditServices _id={_id} className={classes.popOverItemLayout} handleClose={handleClose} />
          </div>
        }

        {
          props.type == 'moas' &&
          <div>
            <AddToGroup className={classes.popOverItemLayout}/>
            <EditAdminProfile className={classes.popOverItemLayout} account={props.account} handleClose={handleClose} refresh={props.refresh} />
            <EditProviderAccount className={classes.popOverItemLayout} account={props.account} handleClose={handleClose} refresh={props.refresh} />
            {globalState.user && globalState.user.document.roles[0] === 'admin' &&
              <UnlinkFromOrganization className={classes.popOverItemLayout} account={props.account} handleClose={handleClose} refresh={props.refresh} />
            }
            <DisablePopup className={classes.popOverItemLayout} account={props.account} type={props.type} handleClose={handleClose} refresh={props.refresh} />
            {globalState.user && globalState.user.document.roles[0] === 'admin' && <>
              <ForceResetPass className={classes.popOverItemLayout} account={props.account} />
              <RemoveUser className={classes.popOverItemLayout} account={props.account} type={props.type} handleClose={handleClose} refresh={props.refresh}/></>
            }
          </div>
        }
        {
          props.type == 'admins' &&
          <div>
            <AddToGroup className={classes.popOverItemLayout} />
            {globalState.user && (globalState.user.document.roles[0] === 'admin' || globalState.user.document.roles[0] === 'healthOrgAdmin') && <>
              <EditAdminProfile className={classes.popOverItemLayout} account={props.account} handleClose={handleClose} refresh={props.refresh} />
              <EditProviderAccount className={classes.popOverItemLayout} account={props.account} handleClose={handleClose} refresh={props.refresh} />
              <DisablePopup className={classes.popOverItemLayout} account={props.account} type={props.type} handleClose={handleClose} refresh={props.refresh} />
              <Transactions className={classes.popOverItemLayout} />
              <ForceResetPass className={classes.popOverItemLayout} account={props.account} />
              <RemoveUser className={classes.popOverItemLayout} account={props.account} type={props.type} handleClose={handleClose} refresh={props.refresh}/>
            <UnlinkFromOrganization className={classes.popOverItemLayout} account={props.account} handleClose={handleClose} refresh={props.refresh} /> </>
            }
            <Transactions className={classes.popOverItemLayout} />
            {globalState.user && globalState.user.document.roles[0] === 'admin' && <>
              <DisablePopup className={classes.popOverItemLayout} account={props.account} type={props.type} handleClose={handleClose} refresh={props.refresh} />
              <ForceResetPass className={classes.popOverItemLayout} account={props.account} />
              <RemoveUser className={classes.popOverItemLayout} account={props.account} type={props.type} handleClose={handleClose} refresh={props.refresh}/></>
            }
          </div>
        }

      </Popover>

    </Box>
  );
}

export default MeatBall;