import {
    Box, Button,


    Grid,
    IconButton, Typography
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import React, { useEffect, useState } from 'react';
import settingsStyles from '../../../../../styles/settingsPageStyles';


export default function StatusCode( {statusesNewConfig, setStatusesNewConfig, statuses}) {
    
    const classes = settingsStyles()
    const [filteredStatuses, setFilteredStatuses] = useState([]);

    const handleStatusDropDownChange = (event) => {
        return setStatusesNewConfig({ ...statusesNewConfig, [event.target.name]: event.target.value})
    }

    useEffect(() => {
        try{
            setFilteredStatuses(statuses.filter((status) => {
                return status.active;
            }))
        }catch(e) {
            // console.log("No statuses");
            // console.log(e);
        }
    }, [])
   
    return (
    <Box>
        <Typography className={classes.labelSmall}>Status Code Designation </Typography>
        <Typography className={classes.statusText}>Designate Status Codes to each of the five types to configure the Check-In queue management system: </Typography>

            <Grid container md={12} sm={12} style={{padding: '5px'}} spacing={4}>
                <Grid item md={1} sm={1} xs={1}>
                    <IconButton  style={{"padding-left": 0}} aria-label="Email">
                        {statusesNewConfig.not_checked_in ? <CheckIcon/> : <CancelIcon/>}
                    </IconButton>
                </Grid>
                <Grid item md={10} sm={10} xs={10} style={{alignSelf: 'center'}}>
                    <Typography  className={classes.statusText}>Not Checked In - The patient has not yet checked in for this appointment</Typography>
                </Grid>
            </Grid>

            <Grid container md={12} sm={12} style={{padding: '5px'}} spacing={4}>
                <Grid item md={1} sm={1} xs={1}>
                    <IconButton  style={{"padding-left": 0}} aria-label="Email">
                        {statusesNewConfig.pending ? <CheckIcon/> : <CancelIcon/>}
                    </IconButton>
                </Grid>
                <Grid item md={10} sm={10} xs={10} style={{alignSelf: 'center'}}>
                    <Typography  className={classes.statusText}>Pending - The patient is in the process of checking in</Typography>
                </Grid>
            </Grid>

            <Grid container md={12} sm={12} style={{padding: '5px'}} spacing={4}>
                <Grid item md={1} sm={1} xs={1}>
                    <IconButton  style={{"padding-left": 0}} aria-label="Email">
                        {statusesNewConfig.checked_in ? <CheckIcon/> : <CancelIcon/>}
                    </IconButton>
                </Grid>
                <Grid item md={10} sm={10} xs={10} style={{alignSelf: 'center'}}>
                    <Typography  className={classes.statusText}>Checked In - The patient has checked in and is now waiting to be called in for their appointment</Typography>
                </Grid>
            </Grid>

            <Grid container md={12} sm={12} style={{padding: '5px'}} spacing={4}>
                <Grid item md={1} sm={1} xs={1}>
                    <IconButton  style={{"padding-left": 0}} aria-label="Email">
                        {statusesNewConfig.picked ? <CheckIcon/> : <CancelIcon/>}
                    </IconButton>
                </Grid>
                <Grid item md={10} sm={10} xs={10} style={{alignSelf: 'center'}}>
                    <Typography  className={classes.statusText}>Picked - The patient has been called in for their appointment and removed from the queue</Typography>
                </Grid>
            </Grid>

            <Grid container md={12} sm={12} style={{padding: '5px'}} spacing={4}>
                <Grid item md={1} sm={1} xs={1}>
                    <IconButton  style={{"padding-left": 0}} aria-label="Email">
                        {statusesNewConfig.noshow ? <CheckIcon/> : <CancelIcon/>}
                    </IconButton>
                </Grid>
                <Grid item md={10} sm={10} xs={10} style={{alignSelf: 'center'}}>
                    <Typography  className={classes.statusText}>No-Show - The patient has not shown up and missed their appointment</Typography>
                </Grid>
            </Grid>

        <Grid container md={12} sm={12} style={{padding: '5px', marginBottom: '15px'}} spacing={4}>
            <Grid item md={1} sm={1} xs={1}>
                <IconButton  style={{"padding-left": 0}} aria-label="Email">
                    {statusesNewConfig.cancel ? <CheckIcon/> : <CancelIcon/>}
                </IconButton>
            </Grid>
            <Grid item md={10} sm={10} xs={10} style={{alignSelf: 'center'}}>
                <Typography  className={classes.statusText}>Cancel - The appointment has been cancelled</Typography>
            </Grid>
        </Grid>

        <Grid container>

            {/*Status code for 'Not Checked In'*/}
            <Grid container md={6} xs={12} style={{marginBottom: '10px'}}>
                <Grid item md={5} sm={5} xs={5}>
                    <Button variant="outlined" color="#DB7676" style={{background: 'rgba(219, 88, 88, 0.3)', width: '95%'}} className={classes.statusButton}>Not Checked In</Button>
                </Grid>
                <Grid item md={7} sm={7} xs={7}>
                    <FormControl className={classes.formControl}  style={{width:'90.5%'}}>
                        <Select
                        value={statusesNewConfig.not_checked_in}
                        name='not_checked_in'
                        displayEmpty
                        disableUnderline={true} 
                        inputProps={{ 'aria-label': 'Without label', disableUnderline: true}}
                        onChange={handleStatusDropDownChange}
                        label="Status Code"
                        >
                        
                        {statuses && filteredStatuses.map((status, i) => (
                            <MenuItem value={status.status} key={i}>{status.status + ' - ' + status.description}</MenuItem>
                        ))}
                        <MenuItem value={null}>-</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {/*Status code for 'Pending'*/}
            <Grid container md={6} xs={12} style={{marginBottom: '10px'}}>
                <Grid item md={5} sm={5} xs={5}>
                    <Button variant="outlined" color="#000" style={{background: 'rgba(241, 190, 0, 0.3)', width: '95%'}} className={classes.statusButton}>Pending</Button>
                </Grid>
                <Grid item md={7} sm={7} xs={7}>
                    <FormControl className={classes.formControl}  style={{width:'90.5%'}}>
                        <Select
                        displayEmpty
                        disableUnderline={true} 
                        inputProps={{ 'aria-label': 'Without label', disableUnderline: true}}
                        name='pending'
                        value={statusesNewConfig.pending}
                        onChange={handleStatusDropDownChange}
                        label="Status Code"
                        >
                        {statuses && filteredStatuses.map((status, i) => (
                            <MenuItem value={status.status} key={i}>{status.status + ' - ' + status.description}</MenuItem>
                        ))}
                        <MenuItem value={undefined}>-</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

        </Grid>

            <Grid container>

                {/*Status code for 'Checked In'*/}
                <Grid container md={6} xs={12} style={{marginBottom: '10px'}}>
                    <Grid item md={5} sm={5} xs={5}>
                        <Button variant="outlined" color="#000" style={{background: 'rgba(102, 198, 167, 0.3)', width: '95%'}} className={classes.statusButton}>Checked In</Button>
                    </Grid>
                    <Grid item md={7} sm={7} xs={7}>
                        <FormControl className={classes.formControl}  style={{width:'90.5%'}}>
                            <Select
                            value={statusesNewConfig.checked_in}
                            name='checked_in'
                            displayEmpty
                            disableUnderline={true} 
                            inputProps={{ 'aria-label': 'Without label', disableUnderline: true}}
                            onChange={handleStatusDropDownChange}
                            label="Status Code"
                            >
                            {statuses && filteredStatuses.map((status, i) => (
                                <MenuItem value={status.status} key={i}>{status.status + ' - ' + status.description}</MenuItem>
                            ))}
                            <MenuItem value={null}>-</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {/*Status code for 'Picked'*/}
                <Grid container md={6} xs={12} style={{marginBottom: '10px'}}>
                    <Grid item md={5} sm={5} xs={5}>
                        <Button variant="outlined" color="#000" style={{background: 'rgba(196, 149, 205, 0.3)', width: '95%'}} className={classes.statusButton}>Picked</Button>
                    </Grid>
                    <Grid item md={7} sm={7} xs={7}>
                        <FormControl className={classes.formControl}  style={{width:'90.5%'}}>
                            <Select
                            displayEmpty
                            disableUnderline={true} 
                            inputProps={{ 'aria-label': 'Without label', disableUnderline: true}}
                            value={statusesNewConfig.picked}
                            name='picked'
                            onChange={handleStatusDropDownChange}
                            label="Status Code"
                            >
                            {statuses && filteredStatuses.map((status, i) => (
                                <MenuItem value={status.status} key={i}>{status.status + ' - ' + status.description}</MenuItem>
                            ))}
                            <MenuItem value={null}>-</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

            </Grid>

            <Grid container>

                {/*Status code for 'No-Show'*/}
                <Grid container md={6} xs={12} style={{marginBottom: '10px'}}>
                <Grid item md={5} sm={5} xs={5}>
                    <Button variant="outlined" color="#000" style={{background: 'rgba(93, 148, 200, 0.3)', width: '95%'}} className={classes.statusButton}>No-Show</Button>
                </Grid>
                <Grid item md={7} sm={7} xs={7}>
                    <FormControl className={classes.formControl}  style={{width:'90.5%'}}>
                        <Select
                        displayEmpty
                        disableUnderline={true} 
                        inputProps={{ 'aria-label': 'Without label', disableUnderline: true}}
                        value={statusesNewConfig.noshow}
                        name='noshow'
                        onChange={handleStatusDropDownChange}
                        label="Status Code"
                        >
                        {statuses && filteredStatuses.map((status, i) => (
                            <MenuItem value={status.status} key={i}>{status.status + ' - ' + status.description}</MenuItem>
                        ))}
                        <MenuItem value={null}>-</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {/*Status code for 'Cancelled'*/}
            <Grid container md={6} xs={12} style={{marginBottom: '3px'}}>
                <Grid item md={5} sm={5} xs={5}>
                    <Button variant="outlined" color="#000" style={{background: '#cfd8dc', width: '95%'}} className={classes.statusButton}>Cancelled</Button>
                </Grid>
                <Grid item md={7} sm={7} xs={7}>
                    <FormControl className={classes.formControl} style={{width:'90.5%'}}>
                        <Select
                        displayEmpty
                        disableUnderline={true} 
                        inputProps={{ 'aria-label': 'Without label', disableUnderline: true}}
                        value={statusesNewConfig.cancel}
                        name='cancel'
                        onChange={handleStatusDropDownChange}
                        label="Status Code"
                        >
                        {statuses && filteredStatuses.map((status, i) => (
                            <MenuItem value={status.status} key={i}>{status.status + ' - ' + status.description}</MenuItem>
                        ))}
                        <MenuItem value={null}>-</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            
        </Grid>
     </ Box>                      
    );
}