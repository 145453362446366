/*
 * @Author: Dongchen Xie
 * @Date: 2020-08-06 02:28:49
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-06 02:43:15
 * @Description: file content
 */
import React from 'react';
import { sliceEvents, createPlugin } from '@fullcalendar/react';

function CustomView(props){

  
    let segs = sliceEvents(props, true); // allDay=true

    return (
      <React.Fragment>
        <div class='view-title'>
          {props.dateProfile.currentRange.start.toUTCString()}
        </div>
        <div class='view-events'>
          {segs.length} events
        </div>
      </React.Fragment>
    );

}

export default createPlugin({
  views: {
    custom: CustomView
  }
});