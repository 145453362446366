/*
 * @Author: Vincent Tran
 * @Date: 2020-08-20 02:10:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-08-20 05:00:00
 * @Description: file content
 */
/**
 * TO DO:
 * Connect this component to the database...
 * 1) Retrieve provider data from database, save info to state, and display.
 * 2) Edits made to provider data must update in the database (handleSave).
 * 
 * Other minor enhancements:
 * 1) Verify email and phone changes with regex before updating provider info.
 */

import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import BootstrapInput from '../../../../../styles/BootstrapInput';
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle';
import UserPageStyles from '../../../../../styles/usersPageStyles';
import { TextMaskPhoneSimple } from '../../TextMask';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, UserPageStyles);

export default function EditPatientProfile(props) {
    const global = React.useContext(GlobalContext)
    // temporary default values..
    // Real values should be pulled from database in the future
    const userInfo = {
        email: props.account.email || '',
        phone: props.account.phone || '',
        whatsapp: props.account.whatsapp || '',
    }
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({
        ...userInfo,
        newEmail: '',
        newPhone: '',
        newWhatsapp: ''
    });
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    };
    const handleChange = (event) => {
        setState({ 
            ...state, 
            [event.target.name]: event.target.value
        })
    }
    const handleSave = async () => {
        // Check if the user typed something into the new inputs
        // Need to also confirm with regex...
        if (state.newEmail) {
            setState({
                ...state,
                email: state.newEmail
            })
        }
        if (state.newPhone) {
            setState({
                ...state,
                phone: state.newPhone
            })
        }
        if (state.newWhatsapp) {
            setState({
                ...state,
                whatsapp: state.newWhatsapp
            })
        }
        // Save user profile info to database here...
        let result = await global.api.updateAccount({
            id: props.account._id,
            emails: { address: state.newEmail.toLowerCase() || userInfo.email, verified: true },
            username: state.newEmail.toLowerCase() || userInfo.email,
            phone: state.newPhone.replace(/\s/g, '') || userInfo.phone,
            whatsapp: state.newWhatsapp.replace(/\s/g, '') || userInfo.whatsapp,
            role: props.account.roles && props.account.roles.length ? props.account.roles[0] : 'doctor',
            accountSet: true,
            orgId: global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id
        });
        if (result && result.status === 200) {
            handleClose();
            props.refresh();
        } else {
            alert('Unable to update account.');
        }
    }

    const classes = combinedStyles();

    return (
        <div>
            <ButtonBase className={props.className} onClick={handleClickOpen}> <Typography className={classes.typography}>Edit Account</Typography></ButtonBase>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
            >

                <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">Edit Account<IconButton onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} /> </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid container>
                        <Grid container spacing={3} className={classes.row}>
                            <Grid item xs={12} sm={6}>
                                <InputLabel className={classes.formLabel} shrink htmlFor="current-email">
                                    Current email
                                </InputLabel>
                                <BootstrapInput 
                                    id="current-email" 
                                    name="email" 
                                    value={state.email} 
                                    fullWidth 
                                    disabled />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel className={classes.formLabel} shrink htmlFor="new-email">
                                    New email
                                </InputLabel>
                                <BootstrapInput 
                                    id="new-email" 
                                    name="newEmail" 
                                    value={state.newEmail}
                                    fullWidth
                                    placeholder='doctor@email.com'
                                    onChange={(e) => handleChange(e)} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className={classes.row}>
                            <Grid item xs={12} sm={6}>
                                <InputLabel className={classes.formLabel} shrink htmlFor="current-phone">
                                    Current phone number
                                </InputLabel>
                                <BootstrapInput 
                                    id="current-phone" 
                                    name="phone" 
                                    value={state.phone} 
                                    fullWidth 
                                    disabled />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel className={classes.formLabel} shrink htmlFor="new-phone">
                                    New phone number
                                </InputLabel>
                                <BootstrapInput 
                                    id="new-phone" 
                                    name="newPhone" 
                                    value={state.newPhone}
                                    fullWidth 
                                    onChange={(e) => handleChange(e)} 
                                    inputComponent={TextMaskPhoneSimple}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} className={classes.row}>
                            <Grid item xs={12} sm={6}>
                                <InputLabel className={classes.formLabel} shrink htmlFor="current-whatsapp">
                                    Current whatsapp ID
                                </InputLabel>
                                <BootstrapInput 
                                    id="current-whatsapp" 
                                    name="whatsapp" 
                                    value={state.whatsapp} 
                                    fullWidth 
                                    disabled 
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel className={classes.formLabel} shrink htmlFor="new-whatsapp">
                                    New whatsapp ID
                                </InputLabel>
                                <BootstrapInput 
                                    id="new-whatsapp" 
                                    name="newWhatsapp" 
                                    value={state.newWhatsapp}
                                    fullWidth 
                                    onChange={(e) => handleChange(e)}
                                    inputComponent={TextMaskPhoneSimple}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleClose} color="primary" className={classes.actionBtn}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" className={classes.actionBtn} disabled={!state.newEmail && !state.newPhone && !state.newWhatsapp}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
