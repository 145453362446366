import { Grid, Typography } from "@material-ui/core";
import React from "react";
import settingsStyles from "../../../../../styles/settingsPageStyles";
import OperationHourSelect from "./OperationHourSelect";

const OperationHours = (props) => {
    const classes = settingsStyles();

    return (
        <Grid container className={classes.section}>
            <Grid item xs={12}>
                <Typography className={classes.labelSmall}>
                    Operation Hours
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <OperationHourSelect
                        Weekday="Sunday"
                        operationHours={props.operationHours}
                        setOperationHours={props.setOperationHours}
                    />
                    <OperationHourSelect
                        Weekday="Monday"
                        operationHours={props.operationHours}
                        setOperationHours={props.setOperationHours}
                    />
                    <OperationHourSelect
                        Weekday="Tuesday"
                        operationHours={props.operationHours}
                        setOperationHours={props.setOperationHours}
                    />
                    <OperationHourSelect
                        Weekday="Wednesday"
                        operationHours={props.operationHours}
                        setOperationHours={props.setOperationHours}
                    />
                    <OperationHourSelect
                        Weekday="Thursday"
                        operationHours={props.operationHours}
                        setOperationHours={props.setOperationHours}
                    />
                    <OperationHourSelect
                        Weekday="Friday"
                        operationHours={props.operationHours}
                        setOperationHours={props.setOperationHours}
                    />
                    <OperationHourSelect
                        Weekday="Saturday"
                        operationHours={props.operationHours}
                        setOperationHours={props.setOperationHours}
                    />
                </Grid>
                
            </Grid>
        </Grid>
    );
};

export default OperationHours;
