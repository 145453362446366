/*
 * @Author: Vincent Tran
 * @Date: 2020-08-05 09:36:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-08-05 03:55:00
 * @Description: file content
 */

import React from 'react';
import MaskedInput from 'react-text-mask';


function TextMaskPhone(props) {
    return (
        <MaskedInput
            {...props}
            mask={['+', /[1-9]/, ' ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
            placeholder={'+1 604 111 1111'}
            guide={false}
            // showMask
            />
    );
}

function TextMaskPhoneSimple(props) {
    return (
        <MaskedInput
            {...props}
            mask={[/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
            placeholder={'604 111 1111'}
            guide={false}
            // showMask
            />
    );
}

function TextMaskTime(props) {
    return (
        <MaskedInput
            {...props}
            // mask={[/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/]}
            mask={[/[0-9]/, /\d/,':',/\d/, /\d/]}
            placeholder={'HH:MM'}
            guide={false}
            // showMask
            />
    );
}

function TextMaskDate(props) {
    return (
        <MaskedInput
            {...props}
            // mask={[/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/]}
            mask={[/[1-2]/, /\d/, /\d/, /\d/, '-',/[0-1]/, /\d/, '-', /[0-3]/, /\d/]}
            placeholder={'YYYY-MM-DD'}
            guide={false}
            // showMask
            />
    );
}

export {
    TextMaskPhone,
    TextMaskPhoneSimple,
    TextMaskDate,
    TextMaskTime
}