import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  loginPage: {
    backgroundColor: "#F5F6FA",
    minHeight: "100vh",
    height: "auto !important",
  },

  header: {
    padding: "4% 0 0 7%",
  },

  portonImgHeader: {
    width: "auto",
    height: "auto",
    minWidth: "104.25px",
    maxWidth: "150px",
  },
  getReportPortonImgHeader: {
    width: "auto",
    height: "auto",
    minWidth: "104.25px",
    maxWidth: "240px",
  },

  loginBox: {
    "@media (max-height: 750px)": {
      paddingTop: "6%",
      paddingBottom: "6%",
    },
    [theme.breakpoints.up(1000)]: {
      minHeight: "80vh",
    },
  },

  loginPaper: {
    width: "60%",
    maxWidth: "650px",
    height: "auto",
    padding: "35px 35px 15px 35px",
    borderRadius: "8px",
    marginTop: "-3%",
    [theme.breakpoints.down(1200)]: {
      width: "44%",
    },
    [theme.breakpoints.down(1100)]: {
      width: "60%",
    },
    [theme.breakpoints.down(600)]: {
      width: "80%",
    },
    [theme.breakpoints.down(300)]: {
      width: "90%",
    },
    [theme.breakpoints.down(310)]: {
      marginLeft: "2rem",
    },
    [theme.breakpoints.down(290)]: {
      marginLeft: "3rem",
    },
  },

  loginTitle: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "32px",
    lineHeight: "38px",
    letterSpacing: "0.25px",
    marginBotton: "2px",
    [theme.breakpoints.down(414)]: {
      fontSize: "24px",
      lineHeight: "28px",
    },
    [theme.breakpoints.down(340)]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down(317)]: {
      fontSize: "18px",
    },
  },

  createAccountGrid: {
    textAlign: "center",
    fontSize: "80%",
    width: "100%",
    marginTop: "2%",
  },

  noAccountText: {
    color: "#767676",
    fontWeight: "500",
    [theme.breakpoints.down(651)]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down(440)]: {
      fontSize: "0.6rem",
    },
    [theme.breakpoints.down(365)]: {
      fontSize: "0.4rem",
    },
  },

  createAccountButton: {
    color: "#CB4D00",
    fontWeight: "500",
    [theme.breakpoints.down(651)]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down(440)]: {
      fontSize: "0.6rem",
    },
    [theme.breakpoints.down(365)]: {
      fontSize: "0.4rem",
    },
  },

  signInTypography: {
    [theme.breakpoints.down(470)]: {
      fontSize: "1.5rem",
      fontWeight: "500",
    },
  },
}));
