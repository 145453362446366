import { Switch, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { SketchPicker } from 'react-color';

export default function UrlComponent(props) {
    const classes = props.classes;
    const state = props.state;
    const handleChange = props.handleChange;
    const handleChangeComplete = props.handleChangeComplete;
    const editTheme = props.editTheme;
    const cancelEditTheme = props.cancelEditTheme;
    const confirmEditTheme = props.confirmEditTheme;

    return (
        
        <Grid item xs={12} className={classes.paperComponment}>
            <Typography style={{fontFamily:"Roboto",fontSize:"20px" }}className={classes.labelSmall}>Room Theme</Typography>
            <Box className={classes.labelWithEdit} >
                {!state.isEdit &&
                    <Typography className={classes.labelSmall}>Apply Organization Theme Colour</Typography>
                }
                {state.isEdit &&
                    <Typography className={classes.labelSmall} style={{color:'#767676',ontFamily:"Roboto"}}>Apply Organization Theme Colour</Typography>
                }
                <Switch
                    color='primary'
                    size='small'
                    className={classes.switch}
                    checked={state.enableThemeColour === undefined ? true : state.enableThemeColour}
                    onChange={handleChange}
                    name='enableThemeColour'
                    // disabled={!state.isEdit}
                    disabled
                />
            </Box>

            <Box className={classes.labelWithEdit} >
                <Typography style={{color:'#767676',ontFamily:"Roboto"}} variant="subtitle1">First Canadian Theme</Typography>
                <Button color="primary" onClick={editTheme} disabled={!state.isEdit}>
                    <Typography variant="subtitle2">EDIT&nbsp;THEME</Typography>
                </Button>
            </Box>

            {state.editTheme && [

                <Paper elevation={3} className={classes.smallContainer}>
                    <Box xs={12} className={classes.editUrlBox}>

                            
                            <Typography className={classes.labelSmallerSpacing}>Change Room Theme</Typography>
                            <Typography >Change the colour of your virtual walk-in room</Typography>

                            <Box className={classes.labelWithEdit} >
                                <SketchPicker
                                    color={ state.theme }
                                    onChangeComplete={ handleChangeComplete }
                                    width="100%"
                                />
                            </Box>
                            


                            <Grid container justify={"space-between"}>
                                <Button color="primary" className={classes.subButton} onClick={cancelEditTheme} disabled={!state.isEdit}>
                                    <Typography variant="body2">CANCEL</Typography>
                                </Button>
                                <Button color="primary" className={classes.subButton} onClick={confirmEditTheme} disabled={!state.isEdit}>
                                    <Typography variant="body2">SAVE CHANGES</Typography>
                                </Button>
                            </Grid>
                        
                    </Box>
                </Paper>

            ]}
        </Grid>
        
    );
}

