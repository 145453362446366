import { Box, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from "@material-ui/core/Grid";
import Switch from '@material-ui/core/Switch';
import React from 'react';





export default function CheckInForms(props) {
    const {classes, verification, handleVerificationChange, isEditPressed} = props;
    const {
        enabled,
        dob,
        hin,
        email,
        postal,
        phone
    } = verification;

    return (
        
        <div style={{padding: '0 25px'}}>
            <Box className={classes.labelWithEdit} style ={{"width": "100%"}}>
                    <Typography className={classes.labelSmall} >Patient Verification Options (Maximum 2 Identifier)</Typography>
                    <Switch 
                        className={classes.switch}
                        size="small"
                        checked={enabled}
                        onChange={handleVerificationChange}
                        color={!isEditPressed ? '#767676' : 'primary'}
                        name="enabled"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disabled={!isEditPressed}
                    />
                </Box>
            <Grid container item xs={12}>
                {!enabled || ([dob, hin, phone, email, postal].filter((v) => v).length === 2 && dob !== true) ?
                    <FormControlLabel
                        control={<Checkbox color={!isEditPressed ? '#767676' : 'primary'} checked={dob} disabled />}
                        label={<Typography className={classes.typography}>Date of Birth</Typography>}
                    />
                :
                    <FormControlLabel
                        control={<Checkbox color={!isEditPressed ? '#767676' : 'primary'} checked={dob} onChange={handleVerificationChange} name="dob"/>}
                        label={<Typography className={classes.typography}>Date of Birth</Typography>}
                    />
                }
            </Grid>
            <Grid container item xs={12}>
                {!enabled || ([dob, hin, phone, email, postal].filter((v) => v).length === 2 && hin !== true) ?
                    <FormControlLabel
                        control={<Checkbox color={!isEditPressed ? '#767676' : 'primary'} checked={hin} disabled />}
                        label={<Typography className={classes.typography}>Personal Health No.</Typography>}
                    />
                :
                    <FormControlLabel
                        control={<Checkbox color={!isEditPressed ? '#767676' : 'primary'} checked={hin} onChange={handleVerificationChange} name="hin"/>}
                        label={<Typography className={classes.typography}>Personal Health No.</Typography>}
                    />
                }
            </Grid>
            <Grid container item xs={12}>
                {!enabled || ([dob, hin, phone, email, postal].filter((v) => v).length === 2 && phone !== true) ?
                    <FormControlLabel
                        control={<Checkbox color={!isEditPressed ? '#767676' : 'primary'} checked={phone} disabled />}
                        label={<Typography className={classes.typography}>Phone Number</Typography>}
                    />
                :
                    <FormControlLabel
                        control={<Checkbox color={!isEditPressed ? '#767676' : 'primary'} checked={phone} onChange={handleVerificationChange} name="phone"/>}
                        label={<Typography className={classes.typography}>Phone Number</Typography>}
                    />
                }
            </Grid>
            <Grid container item xs={12}>
                {!enabled || ([dob, hin, phone, email, postal].filter((v) => v).length === 2 && email !== true) ?
                    <FormControlLabel
                        control={<Checkbox color={!isEditPressed ? '#767676' : 'primary'} checked={email} disabled />}
                        label={<Typography className={classes.typography}>Email Address</Typography>}
                    />
                :
                    <FormControlLabel
                        control={<Checkbox color={!isEditPressed ? '#767676' : 'primary'} checked={email} onChange={handleVerificationChange} name="email"/>}
                        label={<Typography className={classes.typography}>Email Address</Typography>}
                    />
                }
            </Grid>
            <Grid container item xs={12}>
                {!enabled || ([dob, hin, phone, email, postal].filter((v) => v).length === 2 && postal !== true) ?
                    <FormControlLabel
                        control={<Checkbox color={!isEditPressed ? '#767676' : 'primary'} checked={postal} disabled />}
                        label={<Typography className={classes.typography}>Postal Code</Typography>}
                    />
                :
                    <FormControlLabel
                        control={<Checkbox color={!isEditPressed ? '#767676' : 'primary'} checked={postal} onChange={handleVerificationChange} name="postal"/>}
                        label={<Typography className={classes.typography}>Postal Code</Typography>}
                    />
                }
            </Grid>
            
        </div>

        
    );
}

