import { Box, Button, Grid, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { useEffect, useState } from "react";
import GlobalContext from "../../../../../context/GlobalContext";
import settingsStyles from "../../../../../styles/settingsPageStyles";

export default function StatusCodes(props) {
    const global = React.useContext(GlobalContext);
    const classes = settingsStyles();
    const [filteredStatuses, setFilteredStatuses] = useState([]);

    const state = props.state;
    // console.log('state is',state.statusCodes.inPerson);
    const setState = props.setState;
    let org = {};
    let videoArr = [];
    let voiceArr = [];
    let inPersonArr = [];

    if (
        global.state.selectedOrg &&
        Object.keys(global.state.selectedOrg).length !== 0
    ) {
        org = global.state.selectedOrg;
    } else if (
        global.state.defaultOrg &&
        Object.keys(global.state.defaultOrg).length !== 0
    ) {
        org = global.state.defaultOrg;
    }

    const handleStatusChangeInPerson = (event) => {
        if (event.target.value !== null) {
            setState({
                ...state,
                statusCodes: {
                    inPerson: {
                        value: event.target.value,
                        status: filteredStatuses[event.target.value].status,
                        description:
                            filteredStatuses[event.target.value].description,
                        appointmentTypes:
                            state.statusCodes.inPerson.appointmentTypes,
                    },

                    voiceCall: { ...state.statusCodes.voiceCall },
                    videoCall: { ...state.statusCodes.videoCall },
                    textChat: { ...state.statusCodes.textChat },
                },
            });
        } else {
            setState({
                ...state,
                statusCodes: {
                    inPerson: {
                        value: null,
                        status: "-",
                        description: "null",
                        appointmentTypes:
                            state.statusCodes.inPerson.appointmentTypes,
                    },
                    voiceCall: { ...state.statusCodes.voiceCall },
                    videoCall: { ...state.statusCodes.videoCall },
                    textChat: { ...state.statusCodes.textChat },
                },
            });
        }
    };
    const handleStatusChangeVoiceCall = (event) => {
        if (event.target.value !== null) {
            setState({
                ...state,
                statusCodes: {
                    inPerson: { ...state.statusCodes.inPerson },
                    voiceCall: {
                        value: event.target.value,
                        status: filteredStatuses[event.target.value].status,
                        description:
                            filteredStatuses[event.target.value].description,
                        appointmentTypes:
                            state.statusCodes.voiceCall.appointmentTypes,
                    },
                    videoCall: { ...state.statusCodes.videoCall },
                    textChat: { ...state.statusCodes.textChat },
                },
            });
        } else {
            setState({
                ...state,
                statusCodes: {
                    inPerson: { ...state.statusCodes.inPerson },
                    voiceCall: {
                        value: null,
                        status: "-",
                        description: "null",
                        appointmentTypes:
                            state.statusCodes.voiceCall.appointmentTypes,
                    },
                    videoCall: { ...state.statusCodes.videoCall },
                    textChat: { ...state.statusCodes.textChat },
                },
            });
        }
    };
    const handleStatusChangeVideoCall = (event) => {
        if (event.target.value !== null) {
            setState({
                ...state,
                statusCodes: {
                    inPerson: { ...state.statusCodes.inPerson },

                    voiceCall: { ...state.statusCodes.voiceCall },

                    videoCall: {
                        value: event.target.value,
                        status: filteredStatuses[event.target.value].status,
                        description:
                            filteredStatuses[event.target.value].description,
                        appointmentTypes:
                            state.statusCodes.videoCall.appointmentTypes,
                    },

                    textChat: { ...state.statusCodes.textChat },
                },
            });
        } else {
            setState({
                ...state,
                statusCodes: {
                    inPerson: { ...state.statusCodes.inPerson },
                    voiceCall: { ...state.statusCodes.voiceCall },
                    videoCall: {
                        value: null,
                        status: "-",
                        description: "null",
                        appointmentTypes:
                            state.statusCodes.videoCall.appointmentTypes,
                    },
                    textChat: { ...state.statusCodes.textChat },
                },
            });
        }
    };
    const handleStatusChangeTextChat = (event) => {
        if (event.target.value !== null) {
            setState({
                ...state,
                statusCodes: {
                    inPerson: { ...state.statusCodes.inPerson },
                    voiceCall: { ...state.statusCodes.voiceCall },
                    videoCall: { ...state.statusCodes.videoCall },

                    textChat: {
                        value: event.target.value,
                        status: filteredStatuses[event.target.value].status,
                        description:
                            filteredStatuses[event.target.value].description,
                        appointmentTypes:
                            state.statusCodes.textChat.appointmentTypes,
                    },
                },
            });
        } else {
            setState({
                ...state,
                statusCodes: {
                    inPerson: { ...state.statusCodes.inPerson },
                    voiceCall: { ...state.statusCodes.voiceCall },
                    videoCall: { ...state.statusCodes.videoCall },
                    textChat: {
                        value: null,
                        status: "-",
                        description: "null",
                        appointmentTypes:
                            state.statusCodes.textChat.appointmentTypes,
                    },
                },
            });
        }
    };

    useEffect(() => {
        loadCodes();
    }, [global.state.selectedOrg, global.state.defaultOrg, org]);

    const loadCodes = async () => {
        let orgID =
            global.state.selectedOrg != "undefined" &&
            global.state.selectedOrg &&
            Object.keys(global.state.selectedOrg).length !== 0
                ? global.state.selectedOrg._id
                : global.state.defaultOrg._id;

        let statusInfo = await global.api.fetchStatuses(orgID);

        if (statusInfo.data) {
            setFilteredStatuses(statusInfo.data.message);
        }

        if (!org.serviceStatus) org.serviceStatus = [];

        if (!org.org_services) {
            setState({ ...state, codesInfo: [], templateSelect: -1 });
            return;
        }

        //converts org.org_services into a format that is acceptable by translateappointmentmodeArray
        let tempModesArray = Object.keys(org.org_services).map((key) => {
            return key;
        });

        let modesObject = await global.api.translateAppintmentModeArray(
            tempModesArray
        );
        //console.log("modesObject = ", modesObject.data)

        for (let i = 0; i < modesObject.data.length; i++) {
            if (modesObject.data[i].type === "video") {
                videoArr.push({
                    id: modesObject.data[i]._id,
                    name: modesObject.data[i].name,
                });
            }
            if (modesObject.data[i].type === "voice") {
                voiceArr.push({
                    id: modesObject.data[i]._id,
                    name: modesObject.data[i].name,
                });
            }
            if (modesObject.data[i].type === "inPerson") {
                inPersonArr.push({
                    id: modesObject.data[i]._id,
                    name: modesObject.data[i].name,
                });
            }
            if (modesObject.data[i].type === "textChat") {
                inPersonArr.push({
                    id: modesObject.data[i]._id,
                    name: modesObject.data[i].name,
                });
            }
        }

        //converts serviceCodes into a format that allows easier access to modes
        let tempStatusArray = Object.keys(org.serviceStatus).map((key) => {
            return { key: key, modes: org.serviceStatus[key] };
        });

        //converting org.org_services into an array with enabled values, as translateappointmentmodearray does not allow true or false values
        tempModesArray = Object.keys(org.org_services).map((key) => {
            return { key: key, enabled: org.org_services[key] };
        });

        let tempArr = [];
        //makes sure templatecodeinfo was retrieved properly
        if (statusInfo.status !== 900) {
            statusInfo = statusInfo.data.message;
            //sets up the template code editor dropdown, matching tempmodes array to tempcode array, and getting the name of the template code
            //uses the find and findindex function sometimes as the order of the modes and org_services can be different
            for (let r = 0; r < statusInfo.length; r++) {
                let tempArr2 = [];
                if (modesObject.data) {
                    let statusIndex = tempStatusArray.findIndex(
                        (code) => code.key === String(statusInfo[r].code)
                    );

                    for (let i = 0; i < modesObject.data.length; i++) {
                        let index = tempModesArray.findIndex(
                            (mode) => mode.key === modesObject.data[i]._id
                        );

                        if (index !== -1 && tempModesArray[index].enabled) {
                            if (
                                statusIndex !== -1 &&
                                tempStatusArray[statusIndex].modes.includes(
                                    tempModesArray[index].key
                                )
                            ) {
                                tempArr2.push({
                                    id: modesObject.data[i]._id,
                                    name: modesObject.data[i].name,
                                    enabled: true,
                                });
                            } else {
                                tempArr2.push({
                                    id: modesObject.data[i]._id,
                                    name: modesObject.data[i].name,
                                    enabled: false,
                                });
                            }
                        }
                    }
                }

                tempArr.push({
                    index: r,
                    key: statusInfo[r].status,
                    codes: tempArr2,
                    name: statusInfo[r].description,
                });
                // console.log("tempArr = ", tempArr)
            }
        }

        if (state !== undefined && state.statusCodes !== undefined) {
            setState({
                ...state,
                statusInfo: tempArr,
                statusSelect: -1,
                statusCodes: {
                    inPerson: {
                        ...state.statusCodes.inPerson,
                        appointmentTypes: inPersonArr,
                    },
                    voiceCall: {
                        ...state.statusCodes.voiceCall,
                        appointmentTypes: voiceArr,
                    },
                    videoCall: {
                        ...state.statusCodes.videoCall,
                        appointmentTypes: videoArr,
                    },
                    textChat: { ...state.statusCodes.textChat },
                },
            });
        }
    };

    return (
        <Box p={3}>
            <Typography className={classes.labelSmall}>
                {" "}
                Appointment Status Code Assignment{" "}
            </Typography>
            <Typography className={classes.statusText}>
                {" "}
                Designate status codes to each appointment type to configure
                appointment bookings in the BookApp:{" "}
            </Typography>

            <Grid container>
                {/*Status code for 'Not Checked In'*/}

                <Grid container xs={12} style={{ margin: "2rem 0 1rem 0" }}>
                    <Grid
                        item
                        md={5}
                        sm={5}
                        xs={5}
                        style={{ marginBottom: "1rem" }}
                    >
                        <Button
                            variant="outlined"
                            color="#DB7676"
                            style={{
                                background: "rgba(93, 148, 200, 0.1)",
                                width: "95%",
                                border: "1px solid #7EAAD3",
                            }}
                            className={classes.statusButton2}
                        >
                            In-Person
                        </Button>
                    </Grid>
                    <Grid
                        item
                        md={7}
                        sm={7}
                        xs={7}
                        style={{ marginBottom: "1rem" }}
                    >
                        <FormControl
                            className={classes.formControl}
                            style={{ width: "97.5%" }}
                        >
                            <Select
                                disableUnderline={true}
                                inputProps={{
                                    "aria-label": "Without label",
                                    disableUnderline: true,
                                }}
                                onChange={handleStatusChangeInPerson}
                                label="Status Codes"
                                style={{ width: "100%" }}
                                value={
                                    state !== undefined &&
                                    state.statusCodes !== undefined
                                        ? state.statusCodes.inPerson.value
                                        : -1
                                }
                                defaultValue={-1}
                                displayEmpty
                            >
                                <option value={-1} hidden>
                                    {" "}
                                    Select Code{" "}
                                </option>

                                {filteredStatuses.map((status, i) => {
                                    return (
                                        <MenuItem value={i} key={i}>
                                            {status.status +
                                                " - " +
                                                status.description}
                                        </MenuItem>
                                    );
                                })}
                                <MenuItem value={null}>-</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        md={5}
                        sm={5}
                        xs={5}
                        style={{ marginBottom: "1rem" }}
                    >
                        <Button
                            variant="outlined"
                            color="#DB7676"
                            style={{
                                background: "rgba(102, 198, 167, 0.3)",
                                width: "95%",
                                border: "1px solid #71BDA4",
                            }}
                            className={classes.statusButton2}
                        >
                            Voice Call
                        </Button>
                    </Grid>
                    <Grid
                        item
                        md={7}
                        sm={7}
                        xs={7}
                        style={{ marginBottom: "1rem" }}
                    >
                        <FormControl
                            className={classes.formControl}
                            style={{ width: "97.5%" }}
                        >
                            <Select
                                disableUnderline={true}
                                inputProps={{
                                    "aria-label": "Without label",
                                    disableUnderline: true,
                                }}
                                onChange={handleStatusChangeVoiceCall}
                                label="Status Codes"
                                style={{ width: "100%" }}
                                value={
                                    state !== undefined &&
                                    state.statusCodes !== undefined
                                        ? state.statusCodes.voiceCall.value
                                        : -1
                                }
                                defaultValue={-1}
                                displayEmpty
                            >
                                <option value={-1} hidden>
                                    {" "}
                                    Select Code{" "}
                                </option>

                                {filteredStatuses.map((status, i) => {
                                    return (
                                        <MenuItem value={i} key={i}>
                                            {status.status +
                                                " - " +
                                                status.description}
                                        </MenuItem>
                                    );
                                })}
                                <MenuItem value={null}>-</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        md={5}
                        sm={5}
                        xs={5}
                        style={{ marginBottom: "1rem" }}
                    >
                        <Button
                            variant="outlined"
                            color="#DB7676"
                            style={{
                                background: "rgba(241, 190, 0, 0.3)",
                                width: "95%",
                                border: "1.5px solid #EFCF59",
                            }}
                            className={classes.statusButton2}
                        >
                            Video Call
                        </Button>
                    </Grid>
                    <Grid
                        item
                        md={7}
                        sm={7}
                        xs={7}
                        style={{ marginBottom: "1rem" }}
                    >
                        <FormControl
                            className={classes.formControl}
                            style={{ width: "97.5%" }}
                        >
                            <Select
                                disableUnderline={true}
                                inputProps={{
                                    "aria-label": "Without label",
                                    disableUnderline: true,
                                }}
                                onChange={handleStatusChangeVideoCall}
                                label="Status Codes"
                                style={{ width: "100%" }}
                                value={
                                    state !== undefined &&
                                    state.statusCodes !== undefined
                                        ? state.statusCodes.videoCall.value
                                        : -1
                                }
                                defaultValue={-1}
                                displayEmpty
                            >
                                <option value={-1} hidden>
                                    {" "}
                                    Select Code{" "}
                                </option>

                                {filteredStatuses.map((status, i) => {
                                    return (
                                        <MenuItem value={i} key={i}>
                                            {status.status +
                                                " - " +
                                                status.description}
                                        </MenuItem>
                                    );
                                })}
                                <MenuItem value={null}>-</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item md={5} sm={5} xs={5}>
                        <Button
                            variant="outlined"
                            color="#DB7676"
                            style={{
                                background: "rgba(196, 149, 205, 0.3)",
                                width: "95%",
                                border: "1px solid #CC9DD5",
                            }}
                            className={classes.statusButton2}
                        >
                            In-Person[extended]
                        </Button>
                    </Grid>
                    <Grid item md={7} sm={7} xs={7}>
                        <FormControl
                            className={classes.formControl}
                            style={{ width: "97.5%" }}
                        >
                            <Select
                                disableUnderline={true}
                                inputProps={{
                                    "aria-label": "Without label",
                                    disableUnderline: true,
                                }}
                                onChange={handleStatusChangeTextChat}
                                label="Status Codes"
                                style={{ width: "100%" }}
                                value={
                                    state !== undefined &&
                                    state.statusCodes !== undefined
                                        ? state.statusCodes.textChat.value
                                        : -1
                                }
                                defaultValue={-1}
                                displayEmpty
                            >
                                <option value={-1} hidden>
                                    {" "}
                                    Select Code{" "}
                                </option>
                                {filteredStatuses.map((status, i) => {
                                    return (
                                        <MenuItem value={i} key={i}>
                                            {status.status +
                                                " - " +
                                                status.description}
                                        </MenuItem>
                                    );
                                })}
                                <MenuItem value={null}>-</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
