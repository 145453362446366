import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Select,
  Slider,
  Typography
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
//Dialog box
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Popper from "@material-ui/core/Popper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClearIcon from "@material-ui/icons/Clear";
//Icons
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PhoneIcon from "@material-ui/icons/Phone";
import WebIcon from "@material-ui/icons/Web";
//Utilities
import clsx from "clsx";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import Cropper from "react-easy-crop";
import InputMask from "react-input-mask";
// Form
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import validator from "validator";
import GlobalContext from "../../../context/GlobalContext";
// Default Porton logo
import portonLogo from "../../../img/porton.png";
import orgInfoStyles from "../../../styles/orgInfoHeaderStyles";
import { getCroppedImg } from "./utils/canvas-utils";

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default function OrgInfoHeader({
  orgId,
  role,
  logo,
  address,
  phone,
  website,
  orgName,
  uploadAwsImage,
  deleteAwsImage,
  updateOrgLogo,
  updateGlobalState,
  updateOrgProfileSettigs,
  removeOrganization,
  defaultOrg,
  disabled,
}) {
  // S3 Bucket setting
  const bucketName = process.env.REACT_APP_S3_BUCKETNAME
    ? process.env.REACT_APP_S3_BUCKETNAME
    : "porton-shared";

  const classes = orgInfoStyles();
  const global = useContext(GlobalContext);
  const [openLogo, setOpenLogo] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openError, setErrorOpen] = useState(false);
  const [deleteImageOpen, setDeleteImageOpen] = useState(false);
  const [profileSettingsOpen, setProfileSettingsOpen] = useState(false);
  const [disableOrgDialogOpen, setDisableOrgDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [imageWidth, setImageWidth] = useState("150px");
  const [removeOrgOpen, setRemoveOrgOpen] = useState(false);
  const [disableOrgOpen, setDisableOrgOpen] = useState(false);
  const [imageSrc, setImageSrc] = React.useState(null);
  const [imageCropDialogOpen, setImageCropDialogOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);


  // Profile settings form
  const [formInput, setFormInput] = useState({
    orgName: orgName,
    address: address,
    phNumber: phone,
    protocol: "https://",
    website: website,
  });

  const profileRef = useRef(null);
  const logoRef = useRef(null);
  const formRef = useRef();
  const prevOpenProfile = useRef(openProfile);
  const prevOpenLogo = useRef(openProfile);

  const smallScreen = useMediaQuery("(max-width:960px)");

  const uploadImageErrorHandling = (imageFile) => {
    // The image file size is greater than 1MB
    if (imageFile.size > 1048576) {
      setErrorMessage(
        "Please use an image size that is less than 1MB in size."
      );
      return false;
    }
    // Only accept png and jpg/jpeg image formats (.jpg also uses image/jpeg file type)
    if (!(imageFile.type === "image/png" || imageFile.type === "image/jpeg")) {
      setErrorMessage(
        "Please use an image that is in either png or jpg/jpeg format."
      );
      return false;
    }
    return true;
  };

  const getLogoKey = (prevLogo) => {
    const keySearchTerm = "amazonaws.com/";
    const index = prevLogo.indexOf(keySearchTerm);
    return prevLogo.slice(index + keySearchTerm.length, prevLogo.length);
  };

  const handleDisableOrg = async (disable = false, onCloseP) => {
    const res = await global.api.updateDisableOrg(orgId, !disabled);
    if (disable) {
      setDisableOrgDialogOpen(false);
      setDisableOrgOpen(false);
    }

    if (res.status === 200) {
      // console.log("Update disabled settings was successfull");
      // If sucessful, update the global state
      updateGlobalState((prevState) => {
        if (role === "healthOrgAdmin" || role === "oa") {
          return {
            ...prevState,
            defaultOrg: {
              ...prevState.defaultOrg,
              disabled: disable,
            },
          };
        } else {
          if (Object.keys(prevState.selectedOrg).length) {
            return {
              ...prevState,
              selectedOrg: {
                ...prevState.selectedOrg,
                disabled: disable,
              },
            };
          } else {
            return {
              ...prevState,
              defaultOrg: {
                ...prevState.defaultOrg,
                disabled: disable,
              },
            };
          }
        }
      });
    } else {
    }
  };
  const removeOrg = async () => {
    await removeOrganization({ orgId: orgId });
    setRemoveOrgOpen(false);
    window.location.reload();
  };

  const handleImageSelection = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageCropDialogOpen(true);

      setImageSrc(imageDataUrl);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const uploadImageFile = async (event) => {
    setImageCropDialogOpen(false);
    const imageFile = await getCroppedImg(
      imageSrc,
      croppedAreaPixels,
      rotation
    );
    const prevLogo = logo;

    if (!uploadImageErrorHandling(imageFile)) {
      setErrorOpen(true);
      document.querySelector("#fileButton").value = "";
      return;
    }

    const data = new FormData();
    data.append("file", imageFile);
    data.append("filename", imageFile.name);
    data.append("contentType", imageFile.type);
    data.append("BUCKET_NAME", bucketName);
    data.append("folderDir", "images");

    let res = await uploadAwsImage(data);

    if (res.status === 200) {
      /*
       * If file upload was good, then store the image reference in the Porton DB
       * The file name gets saved in the format {UUID}-{filename}
       */
      const storedFileLocation = res.data.Location;

      // Update the image reference in the database
      res = await updateOrgLogo(orgId, storedFileLocation);
      if (res.status === 200) {
        // If successful, we can update the state and add the new logo instead of making another API fetch
        updateGlobalState((prevState) => {
          if (role === "healthOrgAdmin" || role === "oa") {
            return {
              ...prevState,
              defaultOrg: {
                ...prevState.defaultOrg,
                logo: storedFileLocation,
              },
            };
          } else {
            if (Object.keys(prevState.selectedOrg).length) {
              return {
                ...prevState,
                selectedOrg: {
                  ...prevState.selectedOrg,
                  logo: storedFileLocation,
                },
              };
            } else {
              return {
                ...prevState,
                defaultOrg: {
                  ...prevState.defaultOrg,
                  logo: storedFileLocation,
                },
              };
            }
          }
        });
      } else {
        // console.log("Error: updating logo image reference in database");
      }
    } else {
      // console.log("Error: while uploading to AWS");
    }

    /*
     * Remove the previous image in the S3 bucket
     * The key is simply the filepath
     */
    if (prevLogo) {
      const logoKey = getLogoKey(prevLogo);

      res = await deleteAwsImage({
        Key: logoKey,
        BUCKET_NAME: bucketName,
      });

      if (res.status === 200) {
        //console.log("Previous logo deleted successfully");
      } else {
        // console.log("Error: Previous logo not deleted");
      }
    }
    // Reset the input value so that onChange takes effect
    document.querySelector("#fileButton").value = "";
  };

  const deleteImageFileDialog = () => {
    setDeleteImageOpen(true);
  };

  const deleteImageFileYes = async () => {
    /*
     * Update the image reference in the database to null
     * If successfull, then delete the image on S3 and update the global state
     */
    let res = await updateOrgLogo(orgId, null);

    if (res.status === 200) {
      const logoKey = getLogoKey(logo);

      if (res.status === 200) {
        res = await deleteAwsImage({
          Key: logoKey,
          BUCKET_NAME: bucketName,
        });
        updateGlobalState((prevState) => {
          if (role === "healthOrgAdmin" || role === "oa") {
            return {
              ...prevState,
              defaultOrg: { ...prevState.defaultOrg, logo: null },
            };
          } else {
            if (Object.keys(prevState.selectedOrg).length) {
              return {
                ...prevState,
                selectedOrg: {
                  ...prevState.selectedOrg,
                  logo: null,
                },
              };
            } else {
              return {
                ...prevState,
                defaultOrg: {
                  ...prevState.defaultOrg,
                  logo: null,
                },
              };
            }
          }
        });
      } else {
        // console.log("Error: Previous logo not deleted");
      }
    } else {
      // console.log("Error: updating logo image reference in database");
    }
  };

  const submitProfileSettings = async (event) => {
    event.preventDefault();

    //makes a copy of forminput to update information, as not making a copy and updating website would make website appear on the ui differently for half a second
    let tempInfo = JSON.parse(JSON.stringify(formInput));
    tempInfo.website = formInput.protocol + formInput.website;

    const res = await updateOrgProfileSettigs(orgId, tempInfo);
    if (res.status === 200) {
      //console.log("Update profile settings was successfull")
      // If sucessful, update the global state
      updateGlobalState(
        (prevState) => {
          if (role === "healthOrgAdmin" || role === "oa") {
            return {
              ...prevState,
              defaultOrg: {
                ...prevState.defaultOrg,
                org_name: tempInfo.orgName,
                address: tempInfo.address,
                contact_info: tempInfo.phNumber,
                website: tempInfo.website,
              },
            };
          } else {
            if (Object.keys(prevState.selectedOrg).length) {
              return {
                ...prevState,
                selectedOrg: {
                  ...prevState.selectedOrg,
                  org_name: tempInfo.orgName,
                  address: tempInfo.address,
                  contact_info: tempInfo.phNumber,
                  website: tempInfo.website,
                },
              };
            } else {
              return {
                ...prevState,
                defaultOrg: {
                  ...prevState.defaultOrg,
                  org_name: tempInfo.orgName,
                  address: tempInfo.address,
                  contact_info: tempInfo.phNumber,
                  website: tempInfo.website,
                },
              };
            }
          }
        },
        () => {
          setFormInput({
            ...tempInfo,
            orgName: orgName,
            address: address,
            phNumber: phone,
            website: website,
          });
        }
      );
    } else {
      // console.log("Failed to update profile settings");
    }
    setProfileSettingsOpen(false);
  };

  /**********************
   * Dropdown menu hooks *
   **********************/

  const handleToggleProfile = () => {
    setOpenProfile((prevOpen) => !prevOpen);
  };

  const handleToggleLogo = () => {
    setOpenLogo((prevOpen) => !prevOpen);
  };

  const handleCloseProfile = (event) => {
    if (profileRef.current && profileRef.current.contains(event.target)) {
      return;
    }

    setOpenProfile(false);
  };

  const handleRemoveOrg = () => {
    setRemoveOrgOpen(!removeOrgOpen);
  };

  const handleCloseLogo = (event) => {
    if (logoRef.current && logoRef.current.contains(event.target)) {
      return;
    }

    setOpenLogo(false);
  };

  function handleListKeyDownProfile(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenProfile(false);
    }
  }

  function handleListKeyDownLogo(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenLogo(false);
    }
  }

  /**********************
   * Error message hooks *
   **********************/

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleDeleteImageClose = () => {
    setDeleteImageOpen(false);
  };

  /************************
   * Profile Setting hooks *
   ************************/

  const handleFormInputChange = (event) => {
    setFormInput({ ...formInput, [event.target.name]: event.target.value });
  };

  const handleProfileSettingsClose = () => {
    setProfileSettingsOpen(false);
  };

  const handleProfileSettingsOpen = () => {
    let url = website;
    let protocol = "https://";
    //removes protocol from beginning of url so that it does not stack alongside the dropdown addition
    //also sets the protocol to whatever the url was using
    if (url.startsWith("https://")) {
      url = url.substr(protocol.length);
    } else if (url.startsWith("http://")) {
      protocol = "http://";
      url = url.substr(protocol.length);
    }
    setFormInput({
      orgName: orgName,
      address: address,
      phNumber: phone,
      protocol: protocol,
      website: url,
    });
    setProfileSettingsOpen(true);
  };

  useEffect(() => {
    if (prevOpenProfile.current === true && openProfile === false) {
      profileRef.current.focus();
    }

    prevOpenProfile.current = openProfile;
  }, [openProfile]);

  useEffect(() => {
    if (prevOpenLogo.current === true && openLogo === false) {
      logoRef.current.focus();
    }

    prevOpenLogo.current = openLogo;
  }, [openLogo]);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      // These heights need to match the css class
      const ratioHeight = (smallScreen ? 66.5 : 100) / image.height;
      // Apply this ratio to the width
      setImageWidth(image.width * ratioHeight);
    };
    image.src = logo;
  }, [logo, smallScreen]);

  useEffect(() => {
    /*
Input validation rules. These are used to add error helperText and supplement set input contstraints.
*/
    ValidatorForm.addValidationRule("validNumber", (ph) => {
      // The last digit must be a number
      return ph.slice(-1) !== " ";
    });

    ValidatorForm.addValidationRule("validAddress", (address) => {
      // Must contain at least one letter and one number
      return /(?=.*?[0-9])(?=.*?[A-Za-z]).+/.test(address);
    });

    ValidatorForm.addValidationRule("minValidInput", (input) => {
      return input.length > 2;
    });

    ValidatorForm.addValidationRule("validUrl", (url) => {
      return validator.isURL(url);
    });
  });

  const applyDropdownLogo = () => {
    return (
      <>
        {/* Input needs to be outside the dropdown, otherwise it gets removed and onChange does not trigger */}
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="fileButton"
          onChange={handleImageSelection}
          type="file"
        />

        <Popper
          open={openLogo}
          anchorEl={logoRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ marginLeft: "105px" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseLogo}>
                  <MenuList
                    autoFocusItem={openLogo}
                    id="menu-list-grow-logo"
                    onKeyDown={handleListKeyDownLogo}
                    disablePadding
                  >
                    <label htmlFor="fileButton">
                      <MenuItem
                        onClick={handleCloseLogo}
                        className={clsx(classes.hoverColor, classes.dropDown)}
                      >
                        Upload New
                      </MenuItem>
                    </label>

                    // <MenuItem
                      onClick={(event) => {
                        handleCloseLogo(event);
                        deleteImageFileDialog();
                      }}
                      className={clsx(classes.hoverColor, classes.dropDown)}
                    >
                      Remove
                    </MenuItem>

                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const applyImageCropper = () => {
    return (
      <Dialog
        open={imageCropDialogOpen}
        onClose={handleErrorClose}
        style={{
          width: "450px",
          margin: "auto",
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Edit Thumbnail
          <IconButton
            onClick={() => setImageCropDialogOpen(false)}
            className={classes.dialogClose}
            children={<ClearIcon />}
            style={{
              cursor: "pointer",
              float: "right",
              margin: "-8px",
              color: "#444444",
            }}
          />{" "}
          <Typography
            style={{
              color: "##767676",
              fontSize: "12px",
            }}
          >
            Drag to reposition
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid style={{ width: 360, height: 250 }}>
            <div className={classes.cropContainer}>
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={5 / 2}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          </Grid>
          <div className={classes.controls}>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Rotation
              </Typography>
              {/* <AddIcon
                         style={{ color: "#767676",float: "right",margin: "-31px"}}
                        />
                        <RemoveIcon
                        style={{ color: "#767676",float: "left",margin: "-31px"}}
                         /> */}

              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                classes={{ root: classes.slider }}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Box style={{ margin: "auto", marginLeft: "2%" }}>
            <Button
              color="primary"
              onClick={() => setImageCropDialogOpen(false)}
            >
              CANCEL
            </Button>
          </Box>
          <Box style={{ margin: "auto", marginRight: "2%" }}>
            <Button
              color="primary"
              // onClick={handleErrorClose}
              autoFocus
              onClick={uploadImageFile}
            >
              SAVE
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

  const applyDropDownProfile = () => {
    return (
      <Popper
        open={openProfile}
        anchorEl={profileRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ marginRight: "100px" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseProfile}>
                <MenuList
                  autoFocusItem={openProfile}
                  id="menu-list-grow-profile"
                  onKeyDown={handleListKeyDownProfile}
                  disablePadding
                >
                  <MenuItem
                    onClick={(event) => {
                      handleCloseProfile(event);
                      handleProfileSettingsOpen();
                    }}
                    className={clsx(classes.hoverColor, classes.dropDown)}
                  >
                    Edit Profile
                  </MenuItem>
                  {/* <MenuItem
                    onClick={handleCloseProfile}
                    className={clsx(classes.hoverColor, classes.dropDown)}
                  >
                    Edit Theme
                  </MenuItem> */}

                  <MenuItem
                    onClick={
                      disabled
                        ? () => handleDisableOrg(false)
                        : () => setDisableOrgDialogOpen(true)
                    }
                    className={clsx(classes.hoverColor, classes.dropDown)}
                  >
                    {disabled ? "Enable Organization" : "Disable Organization"}
                  </MenuItem>
                  {role === "admin" && (
                    <MenuItem
                      onClick={handleRemoveOrg}
                      className={clsx(classes.hoverColor, classes.dropDown)}
                    >
                      Remove Organization
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  const applyError = () => {
    return (
      <Dialog
        open={openError}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Image Upload Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box style={{ margin: "auto" }}>
            <Button onClick={handleErrorClose} color="primary" autoFocus>
              OK
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

  const applyConfirmDeleteImage = () => {
    return (
      <Dialog
        open={deleteImageOpen}
        onClose={handleDeleteImageClose}
        disableBackdropClick="true"
        aria-labelledby="alert-dialog-delete"
        aria-describedby="alert-dialog-description-delete"
        style={{ width: "450px", margin: "auto" }}
      >
        <DialogTitle id="alert-dialog-delete">{"Upload Logo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-delete">
          Please contact the organization Admin to upload or change your logo.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box style={{ margin: "auto", marginLeft: "2%" }}>
            <Button
              onClick={handleDeleteImageClose}
              color="primary"
              // style={{ float: "left" }}
            >
              OK
            </Button>
          </Box>
          <Box style={{ margin: "auto", marginRight: "2%" }}>
            {/* <Button
              onClick={(event) => {
                handleDeleteImageClose();
                deleteImageFileYes();
              }}
              color="primary"
              style={{ float: "right" }}
              autoFocus
            >
              YES
            </Button> */}
          </Box>
        </DialogActions>
      </Dialog>
    );
  };
  // Disable org
  const disableOrgDialog = (onClose) => {
    return (
      <Dialog
        open={disableOrgDialogOpen}
        onClose={() => setDisableOrgDialogOpen(false)}
        maxWidth="xs"
        style={{ margin: "auto" }}
      >
        <DialogTitle>
          Disable Organization{" "}
          <IconButton
            onClick={() => setDisableOrgDialogOpen(false)}
            className={classes.dialogClose}
            children={<ClearIcon />}
            style={{
              cursor: "pointer",
              float: "right",
              margin: "-8px",
              color: "#444444",
            }}
          />{" "}
        </DialogTitle>
        <Box>
          <DialogContent>
            <DialogContentText
              style={{
                fontSize: "16px",
                color: "#000000",
                fontweight: "400",
                marginTop: "-28px",
                fontFamily: "Roboto",
              }}
            >
              <p>
                Are you sure you want to disable <strong> {orgName}?</strong>?.
                The organization will not be able to log in until the account is
                re-enabled.
              </p>
              <p> You can re-enable the account anytime.</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ marginBottom: "20px" }}>
            <Box
              style={{
                margin: "auto",
                marginLeft: "2%",
                fontweight: "400",
              }}
            >
              <Button
                onClick={() => setDisableOrgDialogOpen(false)}
                color="primary"
              >
                BACK
              </Button>
            </Box>
            <Box
              style={{
                margin: "auto",
                marginRight: "6%",
                fontweight: "400",
              }}
            >
              <Button
                onClick={() => handleDisableOrg(true)}
                type="submit"
                style={{ float: "right", color: "#D14444" }}
                autoFocus
              >
                DISABLE
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    );
  };
  const removeOrgDialog = () => {
    return (
      <Dialog
        open={removeOrgOpen}
        onClose={handleRemoveOrg}
        maxWidth="xs"
        style={{ margin: "auto" }}
      >
        <DialogTitle>Remove Organization</DialogTitle>
        {orgId !== defaultOrg ? (
          <Box>
            <DialogContent>
              <DialogContentText>
                {" "}
                Are you sure you want to remove this organizaiton? You will not
                be able to revert this.
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginBottom: "20px" }}>
              <Box style={{ margin: "auto", marginLeft: "7%" }}>
                <Button
                  onClick={handleRemoveOrg}
                  color="primary"
                  style={{ float: "left" }}
                >
                  CANCEL
                </Button>
              </Box>
              <Box style={{ margin: "auto", marginRight: "6%" }}>
                <Button
                  onClick={removeOrg}
                  type="submit"
                  style={{ float: "right", color: "#D14444" }}
                  autoFocus
                >
                  REMOVE
                </Button>
              </Box>
            </DialogActions>
          </Box>
        ) : (
          <Box>
            <DialogContent>
              <DialogContentText>
                {" "}
                You cannot remove the Default Organization (Janitor). Please
                choose another organization
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginBottom: "20px" }}>
              <Box style={{ margin: "auto" }}>
                <Button
                  onClick={handleRemoveOrg}
                  color="primary"
                  style={{ float: "left" }}
                >
                  OKAY
                </Button>
              </Box>
            </DialogActions>
          </Box>
        )}
      </Dialog>
    );
  };

  const applyEditProfile = () => {
    return (
      <Dialog
        open={profileSettingsOpen}
        onClose={handleProfileSettingsClose}
        disableBackdropClick="true"
        aria-labelledby="alert-dialog-delete"
        aria-describedby="alert-dialog-description-delete"
        style={{ margin: "auto"}}
        >

         <DialogTitle>
                    Edit Profile
                    <IconButton
                onClick={handleProfileSettingsClose}
                className={classes.dialogClose}
                        children={<ClearIcon />}
                        style={{
                            cursor: "pointer",
                            float: "right",
                            margin: "-8px",
                            color: "#000000",
                        }}
                    />{" "}
                </DialogTitle>

        <ValidatorForm
          ref={formRef}
          onSubmit={submitProfileSettings}
          style={{ width: "520px" }}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid xs={12}   style={{marginTop:-20}} className={classes.formRow}>
              <Typography style={{marginLeft:3,color:"#343434", fontFamily:"Roboto"}}>Organization Name</Typography>
                <TextValidator
                  required
                  name="orgName"
                  fullWidth={true}
                  value={formInput.orgName}                  
                  placeholder="Please Enter your Organization Name"
                  type="text"
                  variant="outlined"
                  onChange={handleFormInputChange}
                  validators={["minValidInput"]}
                  errorMessages={["Please enter a valid organization name"]}
                />
              </Grid>
              <Grid xs={12}  style={{marginTop:-20}} className={classes.formRow}>
              <Typography style={{marginLeft:3,color:"#343434", fontFamily:"Roboto"}}>Address</Typography>
                <TextValidator
                  required
                  name="address"
                  fullWidth={true}
                  value={formInput.address}
                  placeholder="Please Enter your address"
                  type="text"
                  variant="outlined"
                  onChange={handleFormInputChange}
                  onChange={(event) => {
                    // For some reason, this regex does not work using pattern inputProps
                    if (/^[\w-#',/ .]*$/.test(event.target.value))
                      handleFormInputChange(event);
                  }}
                  validators={["validAddress", "minValidInput"]}
                  errorMessages={[
                    "Please enter a valid address",
                    "Please enter a valid address",
                  ]}
                />
              </Grid>
              <Grid xs={12}  style={{marginTop:-20}} className={classes.formRow}>
              Phone Number
                <InputMask
                  mask="(999) 999 9999"
                  value={formInput.phNumber}
                  onChange={handleFormInputChange}
                  disabled={false}
                  maskChar=" "
                >
                  {() => (
                    <TextValidator
                      required
                      variant="outlined"
                      placeholder="Please Enter your Phone Number"
                      fullWidth={true}
                      name="phNumber"
                      validators={["validNumber"]}
                      errorMessages={[
                        "Please enter a 10-digit number (includes area code)",
                      ]}
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid xs={12}  style={{marginTop:-20}} className={classes.formRow}>
                <Typography variant="body2" className={classes.labelText}>
                </Typography>
                <Typography style={{marginLeft:3,color:"#343434", fontFamily:"Roboto"}}>Protocol</Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    displayEmpty
                    disableUnderline
                    value={formInput.protocol}
                    onChange={handleFormInputChange}
                    placeholder="Please Enter your Protocol"
                    name="protocol"
                    defaultValue="https://"
                    className={classes.textList}
                    inputProps={{
                      "aria-label": "Without label",
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem value={"https://"}>
                      {" "}
                      {"https://" + formInput.website}{" "}
                    </MenuItem>
                    <MenuItem value={"http://"}>
                      {" "}
                      {"http://" + formInput.website}{" "}
                    </MenuItem>
                  </Select>
                </FormControl>
                <Typography style={{marginLeft:3,color:"#343434", fontFamily:"Roboto"}}>Website</Typography>
                <TextValidator
                  required
                  name="website"
                  fullWidth={true}
                  value={formInput.website}
                  placeholder="Please Enter your Website"
                  type="text"
                  variant="outlined"
                  onChange={handleFormInputChange}
                  validators={["validUrl"]}
                  errorMessages={["Please enter a valid website URL"]}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginBottom: "20px",fontweight:800, }}>
            <Box style={{ margin: "auto", marginLeft: "7%" }}>
              <Button
                onClick={handleProfileSettingsClose}
                color="primary"
                style={{ float: "left",color:"#003FBA",fontWeight: "600",}}
              >
                CANCEL
              </Button>
            </Box>
            <Box style={{  margin: "auto", marginRight: "6%" }}>
              <Button
                type="submit"
                color="primary"
                style={{ float: "right",color:"#003FBA",fontWeight: "600", }}
                autoFocus
              >
                SAVE
              </Button>
            </Box>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  };

  return (
    <>
      <Box className={classes.container}>
        <Box
          className={classes.logoContainer}
          onMouseEnter={() => (logoRef.current.style.display = "block")}
          onMouseLeave={() => (logoRef.current.style.display = "none")}
          style={{ width: imageWidth + (smallScreen ? 20 : 30) }}
        >
          {/* TODO: Get original dimensions of image, and decide new dimensions to render */}
          <img
            className={classes.logo}
            src={logo ? logo : portonLogo}
            style={{ width: imageWidth }}
            alt="PortonHealth-logo"
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.imageButton}
            ref={logoRef}
            // aria-controls={openLogo ? "menu-list-grow-logo" : undefined}
            aria-haspopup="true"
            // onClick={handleToggleLogo}
            onClick={(event) => {
              handleCloseLogo(event);
              deleteImageFileDialog();
            }}
          >
            <Box className={classes.subtitleContainer}>
              EDIT<ArrowDropDownIcon />
            </Box>
          </Button> 
           {applyDropdownLogo()}
          {/* {applyImageCropper()} */}
        </Box>


        <Box className={classes.info}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <Box className={classes.subtitleContainer}>
                {/* Weight should be 500 */}
                <Typography className={classes.title} variant="h3">
                  {orgName} {disabled && "Disabled"}
                </Typography>

                <IconButton
                  className={classes.hoverColor}
                  style={{ marginLeft: "auto" }}
                  ref={profileRef}
                  aria-controls={
                    openProfile ? "menu-list-grow-profile" : undefined
                  }
                  aria-haspopup="true"
                  onClick={handleToggleProfile}
                >
                  <MoreHorizIcon color="action" />
                </IconButton>

                {applyDropDownProfile()}
              </Box>
            </Grid>
            <Grid item xs style={{ marginRight: "15%" }}>
              <Grid container justify="space-around" spacing={2}>
                <Grid item xs>
                  <Box className={classes.subtitleContainer}>
                    <LocationOnIcon color="action" />
                    <Typography variant="body2" color="textSecondary">
                      {address}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs>
                  <Box className={classes.subtitleContainer}>
                    <PhoneIcon color="action" />
                    <Typography variant="body2" color="textSecondary">
                      {phone}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs>
                  <Box className={classes.subtitleContainer}>
                    <WebIcon color="action" />
                    <Typography variant="body2" color="textSecondary">
                      <a
                        style={{
                          color: "inherit",
                          textDecoration: "inherit",
                        }}
                        href={website}
                        target="_blank"
                      >
                        {website}
                      </a>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
  
        </Box>
      </Box>

      {applyError()}
      {applyConfirmDeleteImage()}
      {applyEditProfile()}
      {disableOrgDialog()}
      {removeOrgDialog()}
    </>
  );
}
