import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import settingsStyles from '../../../../../styles/settingsPageStyles';


const FaxSetting = ({faxSetting, setFaxSetting}) => {
    const classes = settingsStyles()

    const handleChangeText = (event) => {
       setFaxSetting({...faxSetting, [event.target.name]: event.target.value})
    }

    return (
        <Grid container className={classes.section}>
            <Grid item xs={12}>
                <Typography style={{fontWeight:600}} className={classes.labelSmall}>Fax Settings</Typography>
            </Grid>
            <Grid item xs={12}>
            	<Typography variant="body2" className={classes.labelText}>Service Username</Typography>
            	<TextField
            	    InputProps={{ disableUnderline: true }}
            	    onChange={handleChangeText}
            	    variant="outlined"
                    value={faxSetting.fax_username || ""}
            	    fullWidth
            	    className={classes.textBox}
            	    placeholder="Service Username"
            	    name="fax_username"
            	    size="small"
            	/>
            </Grid> 

             <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelText}>Service Password</Typography>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    onChange={handleChangeText}
                    variant="outlined"
                    value={faxSetting.fax_service_password || ""}
                    fullWidth
                    className={classes.textBox}
                    placeholder="Service Password"
                    name="fax_service_password"
                    size="small"
                />
            </Grid>   

             <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelText}>Server Link</Typography>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    onChange={handleChangeText}
                    variant="outlined"
                    value={faxSetting.fax_server_link || ""}
                    fullWidth
                    className={classes.textBox}
                    placeholder="Server Link"
                    name="fax_server_link"
                    size="small"
                />
            </Grid>   

             <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelText}>User</Typography>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    onChange={handleChangeText}
                    variant="outlined"
                    value={faxSetting.fax_user || ""}
                    fullWidth
                    className={classes.textBox}
                    placeholder="User"
                    name="fax_user"
                    size="small"
                />
            </Grid>   

             <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelText}>Password</Typography>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    onChange={handleChangeText}
                    variant="outlined"
                    value={faxSetting.fax_password || ""}
                    fullWidth
                    className={classes.textBox}
                    placeholder="Password"
                    name="fax_password"
                    size="small"
                />
            </Grid>   

             <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelText}>Fax Number</Typography>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    onChange={handleChangeText}
                    variant="outlined"
                    value={faxSetting.fax_number || ""}
                    fullWidth
                    className={classes.textBox}
                    placeholder="Fax Number"
                    name="fax_number"
                    size="small"
                />
            </Grid>     
        </Grid>
    )
}

export default FaxSetting;