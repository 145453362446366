/*
 * @Author: Vincent Tran
 * @Date: 2020-08-04 04:30:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-08-05 03:58:00
 * @Description: file content
 */
/*
 * @Author: Vincent Tran
 * @Date: 2020-07-30 04:23:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-07-31 04:35:00
 * @Description: file content
 */
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle';
import UserPageStyles from '../../../../../styles/usersPageStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, UserPageStyles);

export default function EditPatientProfile(props) {
    // temporary default values..
    // Real values should be pulled from database in the future
    // const userInfo = {
    //     fName: 'John',
    //     lName: 'Doe',
    //     provider: 'Dr. Oscardoc',
    //     hin: '1234567890',
    //     dob: '08/20/1990',
    //     email: 'johndoe@email.com',
    //     phone: '+1 555 555 5555',
    //     sex: 'Male',
    //     bType: 'O-',
    //     height: '178',
    //     weight: '70',
    //     allergies: 'N/A',
    //     famHistory: 'N/A'
    // }
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        // save user profile info to database here...

        handleClose();
    }

    const classes = combinedStyles();

    return (
        <div>
            <ButtonBase disabled className={props.className} onClick={handleClickOpen}> <Typography className={classes.typography}>Add To Group </Typography></ButtonBase>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >

                <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">Edit Patient Information<IconButton onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} /> </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid container>
                        <Grid container spacing={3} className={classes.row}>
                            
                        </Grid>

                        <Grid container spacing={3} className={classes.row}>
                            
                        </Grid>

                        <Grid container spacing={3} className={classes.row}>
                            
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleClose} color="primary" className={classes.actionBtn}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" className={classes.actionBtn}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
