/*
 * @Author: Vincent Tran
 * @Date: 2020-07-16 01:06:00
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-10-01 07:03:51
 * @Description: file content
 */
import { Grid, MenuItem, Select } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle.js';
import settingsStyles from '../../../../../styles/settingsPageStyles';

// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, settingsStyles);
function App(props) {
    const global = React.useContext(GlobalContext)
    const classes = combinedStyles();

    const state = props.state;
    const setState = props.setState;

    let org = {}
    if (global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0) {
        org = global.state.selectedOrg
    } else if (global.state.defaultOrg && Object.keys(global.state.defaultOrg).length !== 0) {
        org = global.state.defaultOrg
    }

    const handleChangeDropdown = (event) =>{
        setState({...state, templateSelect:event.target.value})
    }
    //this function loads all the data necessary for the template code edit to work.
    //Unfortunately it is very complicated
    const loadCodes = async () =>{

        let orgID = global.state.selectedOrg != 'undefined' && global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0 ? global.state.selectedOrg._id : global.state.defaultOrg._id

        let templateCodeInfo = await global.api.getScheduleTemplateCode(orgID)

        if(!org.serviceCodes)
            org.serviceCodes = []

        if(!org.org_services){
            setState({...state, codesInfo: [], templateSelect: -1});
            return;
        }

        let tempModesArray = Object.keys(org.org_services).map(key => {
            return key;
        })

        let modesObject = await global.api.translateAppintmentModeArray(tempModesArray)

        //converts serviceCodes into a format that allows easier access to modes 
        let tempCodeArray = Object.keys(org.serviceCodes).map(key => {
            // console.log("key = ", key)
            // console.log("org.serviceCodes[key] = ", org.serviceCodes[key])
            org.serviceCodes[key] = org.serviceCodes[key] ? org.serviceCodes[key] : [];
            return {key:key, modes:org.serviceCodes[key]};
        })

        //converting org.org_services into an array with enabled values, as translateappointmentmodearray does not allow true or false values
        tempModesArray = Object.keys(org.org_services).map(key => {
            return {key:key, enabled:org.org_services[key]}
        })

        let tempArr = []
        //makes sure templatecodeinfo was retrieved properly
        if(templateCodeInfo.status !== 900){
            let codeInfo = templateCodeInfo.data.data;
            //sets up the template code editor dropdown, matching tempmodes array to tempcode array, and getting the name of the template code
            //uses the find and findindex function sometimes as the order of the modes and org_services can be different  
            for(let r = 0; r < codeInfo.length; r++){
                let tempArr2 = []
                if(modesObject.data){
                    let codeIndex = tempCodeArray.findIndex((code) => (code.key === String(codeInfo[r].code)))

                    for(let i = 0; i < modesObject.data.length; i++){
                        let index = tempModesArray.findIndex((mode) => (mode.key === modesObject.data[i]._id))

                        if(index !== -1 && tempModesArray[index].enabled){
                            if(codeIndex !== -1 && tempCodeArray[codeIndex].modes.includes(tempModesArray[index].key)){
                                tempArr2.push({id:modesObject.data[i]._id, name:modesObject.data[i].name, enabled: true})
                            } else{
                                tempArr2.push({id:modesObject.data[i]._id, name:modesObject.data[i].name, enabled: false})
                            }
                        }
                    }
                }

                tempArr.push({key:codeInfo[r].code, codes:tempArr2, name:codeInfo[r].description})
            }
        }

        setState({...state, codesInfo: tempArr, templateSelect: -1}, ()=>{console.log("state = ", state)});
    }

    const handleChangeModes = (i, index) => (event) => {
        let tempArr = state.codesInfo[i].codes;
        tempArr[index].enabled = event.target.checked;
        setState({...state, codesInfo: [
            ...state.codesInfo.slice(0,i),
            {key:state.codesInfo[i].key, name:state.codesInfo[i].name, codes:tempArr},
            ...state.codesInfo.slice(i+1)
        ],
        });
    }

    useEffect(() => {
        loadCodes()
    }, [global.state.selectedOrg, global.state.defaultOrg, org])
    
    return (
        <Grid item xs={12} className={classes.paperComponment}>
            <Box>
                <Typography className={classes.labelSmall}>Template Code Appointment Type Assignment</Typography>
                <Typography className={classes.statusText}>
                    Assign available appointment type to template codes from EMR:
                </Typography>
                
                {state && state.codesInfo && state.codesInfo.length !== 0 && 
                    <>
                        <Typography variant="body2" className={classes.labelText}>Template Code</Typography>
                        <Box className={classes.subButton}>
                        <FormControl className={classes.formControl}>
                            <Select
                                displayEmpty
                                disableUnderline
                                value={state !== undefined ? state.templateSelect : -1}
                                onChange={handleChangeDropdown}
                                name='templateSelect'
                                className={classes.textList}
                                inputProps={{ 'aria-label': 'Without label', disableUnderline: true}}
                            >
                            <option value={-1} hidden> Select Code</option>
                            {state.codesInfo !== undefined && state.codesInfo.length !== 0 && state.codesInfo.map((code, i) => {
                                return (
                                    <MenuItem className={classes.optionList} key={i} value={i}> {code.name + ` (${code.key})`} </MenuItem>
                                )
                            })}
                            </Select>
                        </FormControl>
                        </Box>
                    </>
                }

                {state.templateSelect !== -1 && [
                    <Grid className={classes.dropdownAppointmentSpacing}> 
                        <Typography className={classes.labelSmall}>Appointment Type</Typography>
                        <Grid container className={classes.checkBoxMargin}>
                            {org?.org_services && state.codesInfo && state.codesInfo[state.templateSelect].codes.map((mode, i) => (
                                <Grid container item xs={12} key={i}>
                                    <FormControlLabel
                                        key={mode.id} control={<Checkbox color={'primary'} checked={mode.enabled} onChange={handleChangeModes(state.templateSelect, i)} />}
                                        label={<Typography className={classes.typography}>{mode.name}</Typography>}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                ]}
                
            </Box>
        </Grid>
    );
}

export default App;
