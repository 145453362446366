/*
 * @Author: Samaneh Vajdi
 * @Date: 2021-12-10 16:20:12
 */

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import React from "react";

const useStyles =  makeStyles((theme) => ({
  dialogTitle: {
    fontWeight: "550",
    color: "#343434",
    fontSize: "150%",
    textAlign: "center",
    marginTop: "auto",
    "@media (max-width:400px)": {
      fontSize: "0.7rem",
    },
  },
  dialogTitleLeft: {
    fontWeight: "550",
    color: "#343434",
    fontSize: "120%",
    marginTop: "1.5rem",
    "@media (max-width:400px)": {
      fontSize: "1rem",
      marginTop: "1rem",
    },
    "@media (max-width:300px)": {
      fontSize: "0.7rem",
    },
    marginLeft: "1rem",
  },
  verifyDialog: {
    width: "150%",
  },
  checkinButton: {
    background: '#fff',
    borderRadius:'10px',
  },
  checkinText: {
    fontWeight: '600',
    color: '#003FBA',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alert: {
    paddingTop:'5%',
    width: '27%',
    height: '15%',
  },
  tickContainer: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1%',
    paddingBottom: '1%',
  },
  queue: {
    fontSize: '125%',
    fontWeight: 'bold',
    paddingBottom:'5%',
    color:"#000000"
  },
  queueLight: {
    fontSize: '125%',
    fontWeight: '500',
    paddingBottom:'5%',
    color:"#000000",
    marginLeft: '1rem',
    "@media (max-width:400px)": {
      fontSize: "0.8rem",
    },
    "@media (max-width:300px)": {
      fontSize: "0.6rem",
    },
  },
  btnContainer: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1%',
    paddingBottom: '5%',
  },
  checkinButtonBg: {
    padding: "16px 48px",
    width: "216px",
    height: "51px",
    background: 'radial-gradient(73.95% 73.95% at 14.58% 11.46%, #EE8F37 0%, #E47029 100%)',
    boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',
    borderRadius:'10px',
    color: "white",
    fontWeight: "bold",
    "@media (max-width:400px)": {
      width: "50%",
      fontSize: "0.7rem",
    },
  },  
}));  
  export default function InPersonServerDownModal(props) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    // const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(true);
  
    const handleClose = () => {
        setOpen(false);
    };
  
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className="verifyDialog"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle onClose={handleClose}>
                    {/* Alert image */}
                    {/* <div className={classes.tickContainer}>
                        <img src={Alert} className={classes.alert} alt={Alert} />
                    </div> */}
                     <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.dialogTitleLeft}>
                            Maintenance Alert
                        </Typography>
                        <IconButton onClick={handleClose} style={{"marginTop": "1.2rem"}}><Close fontSize="medium"/></IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                  <Typography className={classes.queueLight}>
                    Apologies as the servers are currently undergoing maintenance. Please check back in a few minutes.
                  </Typography>
                    <div className={classes.btnContainer}>
                        <Button
                            type="button"
                            className={classes.checkinButtonBg}
                            variant="contained"
                            onClick={handleClose}
                        >
                          REFRESH
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
  }
  