/*
 * @Author: Vincent Tran
 * @Date: 2020-07-31 03:42:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-07-31 03:47:00
 * @Description: file content
 */
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useContext } from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle';
import UserPageStyles from '../../../../../styles/usersPageStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, UserPageStyles);

export default function RemoveUser(props) {
    const global = useContext(GlobalContext);
    const [open, setOpen] = React.useState(false);
    let org = {}
    if (global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0) {
        org = global.state.selectedOrg
    } else if (global.state.defaultOrg && Object.keys(global.state.defaultOrg).length !== 0) {
        org = global.state.defaultOrg
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    };
    const handleSubmit = async () => {
        let result = await global.api.removeAccount({
            id: props.account._id,
            role: global.state.user && global.state.user.document.roles[0] || '',
            accountRole: props.type === 'patients' ? 'patient' : props.type === 'providers' ? 'doctor' : props.type === 'moas' ? 'oa' : props.type === 'admins' ? 'healthOrgAdmin' : props.type,
            org: org._id,
            username: props.account.username,
        });
        if (result && result.status === 200) {
            handleClose();
            props.refresh();
        } else {
            alert('Unable to remove account.');
        }
    }

    const classes = combinedStyles();

    return (
        <div>
            <ButtonBase className={props.className} onClick={handleClickOpen}> <Typography className={classes.typography}>Remove</Typography></ButtonBase>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">Remove Account<IconButton onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} /> </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.contentText}>
                        Are you sure you want to remove <strong>{props.account.contact_name}'s</strong> account? You will not be able to revert this.
                    </DialogContentText>
                </DialogContent>

                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleClose} color="primary" className={classes.actionBtn}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" className={classes.actionBtn}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}