/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-10 02:32:33
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-26 07:28:35
 * @Description: file content
 */ 
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from "react-router-dom";


const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#DBDBDBD",
      '&:hover': {
        backgroundColor: "#CCCCCC",
        boxShadow:"none",
      },
      '&:link':{
        textDecoration:"none"
      },
      textDecoration:"none",
      borderRadius:"1.5rem",
      fontSize:"0.875rem",
      marginTop:"0.3rem",
      padding:"0rem 1rem",
      boxShadow:"none",
      textTransform:"none",
      fontWeight:450,
      marginRight:"2rem"
    },
  }))(Button);
function CalendarButton(props) {
    
    return (
      <Box>  
        <ColorButton  to={`/calendar/${props.type}/${props.id}`} variant="contained" component={Link}>
          Calendar
        </ColorButton>
      </Box>
    );
     
}

export default CalendarButton;