import { Box, Button, Card, Checkbox, FormControlLabel, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../../../context/GlobalContext";
import EMRIntegrationStatus from "./EMRIntegrationStatus";

const useStyles = makeStyles((theme) => ({
    labelSmall: {
        ...theme.labelSmall,
        width: 'unset',
        margin: 0,
        marginBottom: 0,
        lineHeight: 'unset',
        flex: '1',
        fontFamily:"Roboto",
        fontSize:"20px",
        fontWeight:600
    },
    titleContainer: {
        display: 'flex',
        width: '100%',
        fontFamily:"Roboto",

    },
    formContainerCard: {
        padding: theme.spacing(3),
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: theme.spacing(2),
    },
    formFieldsContainer: {
        display: "grid",
        gap: theme.spacing(2),
        gridTemplateColumns: "1fr"
    },
    formButtonsContainer: {
        display: "flex",
        justifyContent: "flex-end",
        gap: theme.spacing(2)
    },
    inputFields: {
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingRight: '5px',
        paddingLeft: '5px',
      },
    addEmrButton: {
        marginLeft: 'auto'
    },
    AuthenticationText:{
        color:"#343434",
        fontWeight: 500,
        fontSize:"0.9rem",
        fontFamily:"Roboto",
    }
}));



const EMRIntegrationNext = ({prevUrl, setNewProviderAdded, expandImportProviderDrawer, setProviderList, handleEditForStep2}) => {
    let callBackURL = process.env.REACT_APP_CALLBACK_URL || "https://admin-dev.portonhealth.com/appsetting";

    const formFields = {
        rest: [
            'clientKey', 'clientSecret', 'callbackURL'
        ],
        soap: [
            'username', 'password'
        ]
    }

    const initialEmrConfigState = {
        emrName: '',
        emrURL: '',
        callbackURL: callBackURL,
        clientKey: '',
        clientSecret: '',
        username: '',
        password: '',
        authenticationMethods: {
            rest: true,
            soap: true
        },
    };
    const classes = useStyles();
    const global = useContext(GlobalContext);

    const [state, setState] = useState({
        step: 1,
        orgId: '',
        selectedOrg: null,
        addEMRInit: false,
        emrConfig: initialEmrConfigState
    });

    const [incorrectSoapPassword, setIncorrectSoapPassword] = useState('');

 
    const savePullAppAutomation = async (newValue) => {
        try {
            let res = await global.api.savePullAppAutomationSetting(state.orgId, {
                    savePullAppAutomation: newValue
            })
            if (res.status === 200) {

            }
        } catch (error) {

        }
    }

    const saveIntegrationComplete = async (newValue) => {
        try {
            let res = await global.api.saveIntegrationComplete(state.orgId, {
                integrationComplete: {
                    integrationComplete: newValue
                }
            })
            if (res.status === 200) {

            }
        } catch (error) {

        }
    }
            
        const deletePullAppAutomation = async (newValue) => {
            try {
                let res = await global.api.savePullAppAutomationSetting(state.orgId, {
                    pullAppAutomation: {
                        savePullAppAutomation: newValue
                    }
                })
                if (res.status === 200) {

                }
            } catch (error) {

            }} 

            const deleteEMRComplete = async (newValue) => {
                try {
                    let res = await global.api.saveIntegrationComplete(state.orgId, {
                        integrationComplete: {
                            integrationComplete: newValue
                        }
                    })
                    if (res.status === 200) {

                        setProviderList([])
                    }
                } catch (error) {

                }} 
  
    const updateEmrGlobalState = (newEmr) => {
        const role = global.state.user.document.roles[0];
        global.setState((prevState)=>{
          if(role === 'healthOrgAdmin' || role === 'oa'){
            return { ...prevState, defaultOrg: {...prevState.defaultOrg, emr: newEmr }};
          }else {
            // The user is a Janitor
            if(Object.keys(prevState.selectedOrg).length) {
              return { ...prevState, selectedOrg: {...prevState.selectedOrg, emr: newEmr }};
            }else {
              return { ...prevState, defaultOrg: {...prevState.defaultOrg, emr: newEmr }};
            }
          }
        });
      };
    
      const getEmrFromGlobalState = () => {
          const _emr =  Object.keys(global.state.selectedOrg).length !== 0 ? global.state.selectedOrg?.emr || {} : global.state.defaultOrg?.emr || {};
          // to work with old data, we need to check if the authentication methods are present or not

          if(_emr.emrName && !_emr.authenticationMethods) {
              // this is old data, push rest and soap both as true.
              _emr.authenticationMethods = {rest: true, soap: true};
          }

          return _emr;
        }

    const updateAccessTokens = async (retrievedPrevOrgId, retrievedPrevOrgEmr, oauth_verifier, oauth_token) => {
        const accessTokens = { 
          emrURL: retrievedPrevOrgEmr.emrURL,
          clientKey: retrievedPrevOrgEmr.clientKey,
          clientSecret: retrievedPrevOrgEmr.clientSecret,
          req_oauth_token_secret: retrievedPrevOrgEmr.req_oauth_token_secret,
          oauth_verifier, 
          oauth_token
        };
    
    
        const res = await global.api.updateEmrIntegrationAccessTokens(retrievedPrevOrgId, accessTokens);
        if(res.status === 200) {
          // We need to do a check to see if we are returning for selected or default org
          // If selected org this gets updated in default org, so we do not perform the state update
          const _selectedOrgId = global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id;
          if(retrievedPrevOrgId === _selectedOrgId) {
              updateEmrGlobalState(res.data)
          }
        } else {

        }
      };

    

    const handleAddEMR = () => {
        setState({...state, addEMRInit: true})
    }

    const handleCheckBox = (evt, authenticationMethodName) => {
        setState({
            ...state,
            emrConfig: {
                ...state.emrConfig,
                authenticationMethods: {
                    ...state.emrConfig.authenticationMethods,
                    [authenticationMethodName]: evt.target.checked
                }
            }
        })
    }

    const handleTextInput = (evt) => {
        setState({
            ...state,
            emrConfig: {
                ...state.emrConfig,
                [evt.target.name]: evt.target.value
            }
        })
    }

    const handleCancel = () => {
        setState({
            ...state,
            step: 1,
            addEMRInit: false,
            emrConfig: initialEmrConfigState
        })
    }

    const handleContinue = async () => {
        if(state.step === 1) {
            let res = await global.api.createNewEmrIntegration(state.orgId, {
                emrName: state.emrConfig.emrName,
                emrURL: state.emrConfig.emrURL,
                authenticationMethods: state.emrConfig.authenticationMethods
            });

            if(res.status === 200) {
                handleEditForStep2();
                setState({
                    ...state,
                    emrConfig: {
                        ...state.emrConfig,
                        ...res.data
                    },
                    step: 2
                })

                updateEmrGlobalState(res.data);
                
            } else {

            } 
        } else if (state.step === 2) {
            
            // if soap is included:
            if(state.emrConfig.authenticationMethods.soap) {
                const res = await global.api.createSoapAuth(
                    state.orgId,
                    {
                        emrURL: state.emrConfig.emrURL,
                        username: state.emrConfig.username,
                        password: state.emrConfig.password,
                    }
                )

                delete res.data.username;
                delete res.data.password;
                if(res.status === 200) {
                    
                    
                    updateEmrGlobalState(res.data);
                    setIncorrectSoapPassword('');
                    saveIntegrationComplete(true)                         
                    savePullAppAutomation(true)
                    
                } else {
                    setIncorrectSoapPassword("Error: Incorrect SOAP username or password")
                }
            }
            if(state.emrConfig.authenticationMethods.rest) {
                // if rest is included:
                // call getEmrIntegrationRequestTokens 
                const reqData = {
                    emrName: state.emrConfig.emrName,
                    emrURL: state.emrConfig.emrURL,
                    callbackURL: state.emrConfig.callbackURL,
                    clientKey: state.emrConfig.clientKey,
                    clientSecret: state.emrConfig.clientSecret,
                };
               
               const integrationTokensRes = await global.api.getEmrIntegrationRequestTokens(state.orgId, reqData)
                // save the response from that in local storage (will include the secret)
                // secret is also returned by the above request
                
                const uri = integrationTokensRes.data.uri;
                delete integrationTokensRes.data.uri;

                localStorage.setItem('prevOrgId', state.orgId);
                integrationTokensRes && localStorage.setItem(
                    'prevOrgEmrData', 
                    JSON.stringify(integrationTokensRes.data)
                );

                // redirct browser to the uri from the returned response
                    
                window.open(uri, "_self");
                
                savePullAppAutomation(true)
                saveIntegrationComplete(true)
        
            }
        }
    }

    const deleteEmr = async () => {
        let res = await global.api.deleteEmrIntegration(state.orgId);
        if (res.status === 200) {
            setState({
                ...state,
                step: 1,
                addEMRInit: false,
                emrConfig: initialEmrConfigState
            });
            updateEmrGlobalState({});
            deletePullAppAutomation(false)
            deleteEMRComplete(false)
        }else{
          // console.log("Emr deletion failed")
        }
      };

    const shouldDisplayField = (fieldName) => {
        if(state.step !== 2) {
            return false;
        }

        return (state.emrConfig.authenticationMethods.rest && formFields.rest.includes(fieldName)) ||
            (state.emrConfig.authenticationMethods.soap && formFields.soap.includes(fieldName));
        
    }

    const checkCanContinue = () => {
        if ((state.step === 1 && !!state.emrConfig.emrName && !!state.emrConfig.emrURL)){
            return true;
        }
        if(state.step === 2 ) {
            // TODO: simplify.
            if(state.emrConfig.authenticationMethods.rest && state.emrConfig.authenticationMethods.soap) {
                return !!state.emrConfig.callbackURL && 
                !!state.emrConfig.clientKey &&
                !!state.emrConfig.clientSecret &&
                !!state.emrConfig.username &&
                !!state.emrConfig.password;
            }
            if(state.emrConfig.authenticationMethods.rest) {
                return !!state.emrConfig.callbackURL && 
                        !!state.emrConfig.clientKey &&
                        !!state.emrConfig.clientSecret;
            }
            if(state.emrConfig.authenticationMethods.soap) {
                return !!state.emrConfig.username &&
                    !!state.emrConfig.password;
            }
            return false;
        }
        return false;
    }
    // calculate if the continue should work
    const canContinue = checkCanContinue();

    useEffect(() => {
        if(!global?.state?.defaultOrg) {
            // nothing will work without default org being set
            return;
        }
        const globalEmr = getEmrFromGlobalState();
        const _tempState = {...state};
        // check if auth completed or not to display the form
        if(globalEmr.emrName) {
            if(globalEmr.authenticationMethods.rest && globalEmr.authenticationMethods.soap) {
                // if global EMR Is set with rest AND soap auth
                _tempState.addEMRInit = !globalEmr.authorized || !globalEmr.securityId;
            }else if(globalEmr.authenticationMethods.rest) {
                // if global EMR Is set with only rest auth
                _tempState.addEMRInit =  !globalEmr.authorized;
            } else if(globalEmr.authenticationMethods.soap) {
                // if global EMR Is set with only soap auth,
                
                _tempState.addEMRInit =  !globalEmr.securityId;
            }
           
        }
        
        /// global state or selected org is changing
        const _selectedOrg = global.state.selectedOrg?._id ? global.state.selectedOrg : global.state.defaultOrg;
  

        _tempState.selectedOrg = _selectedOrg;
        _tempState.orgId = _selectedOrg._id;
        _tempState.emrConfig = {..._tempState.emrConfig, ..._selectedOrg.emr};

        /// update in local state based on global
        setState(_tempState);
    },[
        global,
        global.state,
        global.state.selectedOrg,
        global.state.defaultOrg
    ]);

    useEffect(() => {
        const globalEmr = getEmrFromGlobalState();
        if(globalEmr.emrName) {
            if(prevUrl) {

                const url = new URL(prevUrl);
                const args =  new URLSearchParams(url.search);
                const oauth_verifier = args.get('oauth_verifier');
                const oauth_token = args.get('oauth_token');

                const retrievedPrevOrgId = localStorage.getItem('prevOrgId') || "";
                const prevOrgEmrData = localStorage.getItem('prevOrgEmrData') || "";

                if (!retrievedPrevOrgId || !prevOrgEmrData) {
                    return;
                }

                const retrievedPrevOrgEmr = JSON.parse(prevOrgEmrData);

                updateAccessTokens(retrievedPrevOrgId, retrievedPrevOrgEmr, oauth_verifier, oauth_token);

                localStorage.removeItem("prevOrgId");
                localStorage.removeItem("prevOrgEmrData");
            }
        }

        
    }, [prevUrl]);

    

    return (
        <Box padding={3}>
            <div className={classes.titleContainer}>
                <Typography className={classes.labelSmall}>
                    EMR Integration
                </Typography>

                {(!state.emrConfig.emrName ) && (<Button 
                    disabled={state.addEMRInit}
                    className={classes.addEmrButton}
                    onClick={handleAddEMR}
                    color="primary">
                    + Add EMR
                </Button>)}
            </div>
            {
                
                !!state.emrConfig.emrName && (<EMRIntegrationStatus deleteEmr={deleteEmr} setNewProviderAdded={setNewProviderAdded} expandImportProviderDrawer={expandImportProviderDrawer}/>)
            }
            {
                state.addEMRInit && (
                    <>
                        <Card elevation={2} className={classes.formContainerCard}>
                            <div>
                                <Typography className={classes.AuthenticationText}>Authentication Method(s)</Typography> 
                                <FormControlLabel 
                                    control={<Checkbox style={{color:"blue"}}checked={state.emrConfig.authenticationMethods.rest} onClick={e => handleCheckBox(e, 'rest')}/>}
                                    label="REST API"
                                    disabled={state.step !== 1}
                                    
                                />
                                <FormControlLabel 
                                    control={<Checkbox style={{color:"blue"}} checked={state.emrConfig.authenticationMethods.soap} onClick={e => handleCheckBox(e, 'soap')}/>}
                                    label="SOAP API"
                                    disabled={state.step !== 1}
                                />
                            </div>
                            <div className={classes.formFieldsContainer}>
                                {state.step === 1 && (
                                    <>
                                    
                                        <TextField
                                            name="emrName"
                                            id="emrName"
                                            label="EMR Name"
                                            placeholder="EMR Name"
                                            variant="outlined"
                                            margin="dense"
                                            onChange={handleTextInput}
                                            value={state.emrConfig.emrName}

                                        />
                                       
                                        <TextField
                                            name="emrURL"
                                            id="emrURL"
                                            label="EMR URL"
                                            placeholder="EMR URL"
                                            variant="outlined"
                                            margin="dense"
                                            onChange={handleTextInput}
                                            value={state.emrConfig.emrURL}
                                        />
                                      
                                    </>
                                )}
                                {state.step === 2 && (
                                    <>
                                        {shouldDisplayField('clientKey') && (
                                            <TextField
                                                name="clientKey"
                                                id="clientKey"
                                                label="Client Key"
                                                placeholder="Client Key"
                                                variant="outlined"
                                                margin="dense"
                                                onChange={handleTextInput}
                                                value={state.emrConfig.clientKey}
                                            />
                                        )}
                                        {shouldDisplayField('clientSecret') && (
                                            <TextField
                                                name="clientSecret"
                                                id="clientSecret"
                                                label="Client Secret"
                                                placeholder="Client Secret"
                                                variant="outlined"
                                                margin="dense"
                                                onChange={handleTextInput}
                                                value={state.emrConfig.clientSecret}
                                            />
                                        )}
                                        {shouldDisplayField('callbackURL') && (
                                            <TextField
                                                name="callbackURL"
                                                id="callbackURL"
                                                label="Callback URL"
                                                placeholder="Callback URL"
                                                variant="outlined"
                                                margin="dense"
                                                onChange={handleTextInput}
                                                value={state.emrConfig.callbackURL}
                                            />
                                        )}
                                        {shouldDisplayField('username') && (
                                            <TextField
                                                name="username"
                                                id="username"
                                                label="SOAP Username"
                                                placeholder="SOAP Username"
                                                variant="outlined"
                                                margin="dense"
                                                onChange={handleTextInput}
                                                value={state.emrConfig.username}
                                            />
                                        )}
                                        {shouldDisplayField('password') && (
                                            <TextField
                                                name="password"
                                                type="password"
                                                id="password"
                                                label="SOAP Password"
                                                placeholder="SOAP Password"
                                                variant="outlined"
                                                margin="dense"
                                                onChange={handleTextInput}
                                                value={state.emrConfig.password}
                                            />
                                        )}
                                    </>
                                )}
                                <div className={classes.formButtonsContainer}>
                                    {state.step === 1 && <Button type="submit" style={{float: 'right'}} color="primary" onClick={handleCancel}>Cancel</Button>}
                                    <Button type="submit" style={{float: 'right'}} color="primary" onClick={handleContinue} disabled={!canContinue}>Continue</Button>
                                </div>
                                {incorrectSoapPassword && <Typography variant="body1" color="error">
                                    {incorrectSoapPassword}
                                </Typography>}
                            </div>

                            
                        </Card>
                    </>
                )
            }
        </Box>
    )
}

export default EMRIntegrationNext;