import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import GlobalContext from "../../context/GlobalContext";
import HelpStyles from '../../styles/HelpStyles';
import FAQ from './component/FAQ';
import TechSupp from './component/TechSupp';
import Tutorial from './component/Tutorial';


const Index = (props) => {
    const classes = HelpStyles()

    const global = useContext(GlobalContext);
    const userType = global.state.user.document.roles;

    React.useEffect(() => {
        props.setTitle("Help");
    },[]);

    return (
        <div className={classes.pageMargin}> 
            <Typography className={classes.title}>Help</Typography>

            <Paper elevation={2}>
                <Grid container>
                    <Grid item xs={12} md={6} sm={6} className={classes.borderRightSide}>
                        <FAQ/>
                        <Divider/>
                    </Grid>

                    <Grid item xs={12}md={6} sm={6}>
                        <TechSupp/>
                    </Grid>
                    <Grid item xs={12} md={6}sm={6} className={classes.borderRightSide}>
                        <Divider/>
                        {userType.includes("oa") || userType.includes("healthOrgAdmin") || userType.includes("admin") ? <Tutorial/>:""}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default Index;
