import {
  Box,


  Grid, Typography
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// Utilities
import clsx from 'clsx';
import React from 'react';
import settingsStyles from '../../../../../styles/settingsPageStyles';

export default function ServiceTypes(
  { 
    state,
    setState,
    isEdit,
  }
  ) {

  const classes = settingsStyles();
    
  const handleChangeServices = index => (event) => {
    setState({...state, services: [
        ...state.services.slice(0,index),
        {id:state.services[index].id, description:state.services[index].description, name:state.services[index].name, enabled:event.target.checked},
        ...state.services.slice(index+1)
    ],
    
    });
  
  }
  
  return (
    <Box p={2}>
      <Grid container className={classes.appointmentReasonSpacing}>
        <Grid item md={11} sm={11}>
          <Typography className={clsx(classes.appointmentReasonFormating ,classes.labelSmall)}> Appointment Reason </Typography>
        </Grid>
      </Grid>

      {
        state.services && state.services.map((service, i) => (
          <Grid container key={i}>
           
            <Grid container item xs={10}>
                <FormControlLabel
                    key={i} control={<Checkbox color={!isEdit ? '#767676' : 'primary'} checked={service.enabled} onChange={handleChangeServices(i)} />}
                    label = {
                      <Grid item >
                        <Typography className={clsx(classes.appointmentReasonListName, classes.labelSmall)}> {service.name} </Typography>
                        <Typography className={clsx(classes.appointmentReasonListDescription, classes.subLabel)}> {service.description} </Typography>
                      </Grid>
                    }
                />
            </Grid>
  
          </Grid>
        ))

      }
    </Box>


  );
}