import moment from "moment";

export const AppointmentTypes = {
  TELEPHONE: "Telephone",
};

export default class Appointment {
  type;
  reason;
  patientName;
  doctorName;
  providerNo;
  appointmentDate;
  appointmentStartDateTime;
  appointmentEndDateTime;
  isTelephoneAppointment;
  id;
  startTime;

  constructor(obj) {
    for (let i in obj) {
      if (obj[i].post) {
        Object.assign(this, obj[i].post);

        // standardize start and end times to ISO format
        this.appointmentStartDateTime = moment(
          this.appointmentStartDateTime
        ).toISOString();
        this.appointmentEndDateTime = moment(
          this.appointmentEndDateTime
        ).toISOString();
      } else if (obj[i].status) {
        this.status = obj[i].status;
      } else if (obj[i].name) {
        this.patientName = obj[i].name;
      }
    }

    const reasonSplit = this.reason.split(",");

    if (this.type === AppointmentTypes.TELEPHONE) {
      this.isTelephoneAppointment = true;
      this.patientPhone = reasonSplit.shift();
    }

    this.reason = reasonSplit[0];
    this.comment = reasonSplit[1];
  }

  getOscarValidatedReason() {
    let { reason: oscarValidatedReason } = this;
    if (this.isTelephoneAppointment)
      oscarValidatedReason = `${this.patientPhone}, ${oscarValidatedReason}`;
    if (this.comment)
      oscarValidatedReason = `${oscarValidatedReason}, ${this.comment}`;

    return oscarValidatedReason;
  }
}
