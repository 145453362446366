//material UI components and icons
import { Box, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
//styling
import AccountProfileStyle from '../../../../styles/AccountProfileStyles';
import MorePopup from './specialtyComponents/morePopup';
//components
import Popup from './specialtyComponents/popup';



const Specialty = (props) => {
    const classes = AccountProfileStyle()
    

    const [specialty, setSpecialty]=useState([])

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSpecialty(props.userState.specialty.split(","))
        setOpen(false);
    };
    
    useEffect(() => { 
        const temp=props.userState.specialty.split(",")
        setSpecialty(temp)
    },[props.userState.specialty])

    const removeSpecialty = (event) => {
        let key=event.currentTarget.id
        let temp=[...specialty]
        temp.splice(key,1)
        setSpecialty(temp)
    }

    const addSpecialty = (name) => {
        if(name?.length){
            setSpecialty([...specialty, name])   
        }
    }

    const done = () => {
        const str=specialty.join(",")
        props.setUserState({...props.userState, specialty:str})
        setOpen(false)
    }

    const specialtyRemove = (event) =>{
        let key=event.currentTarget.id
        let temp=[...specialty]
        temp.splice(key,1)
        setSpecialty([...temp])
        //TODO before had specialty but wouldnt update userState but works with temp. why?
        const str=temp.join(",")
        props.setUserState({...props.userState, specialty:str})
    }


return(
    <>
    <Box className={classes.spacingTopHeader}>
        <Typography className={classes.headingLabel}>Specialty</Typography>
        <Button disableRipple className={classes.editLabel} onClick={handleClickOpen}>EDIT</Button>
        <Popup open={open} close={handleClose} specList={specialty} remove={removeSpecialty} add={addSpecialty} done={done} />
    </Box>
    <Box className={classes.bottomMargin}>
        {specialty.map((name,key)=>{
            return(
                <Box key={key} className={classes.spreadHeader}>
                    <Typography className={classes.specialtyName}>{name}</Typography>
                    <MorePopup id={key} remove={specialtyRemove} done={done} />
                </Box> 
            )
        })}
    </Box>
    </>
)
}

export default Specialty