import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import bcrypt from 'bcryptjs'
import React, { useEffect, useState } from 'react'
import settingsStyles from '../../../../../styles/settingsPageStyles'
import PasswordChecklist from "react-password-checklist";

const ChangePassword = ({ user, open, close, setPassword }) => {
    const classes = settingsStyles()
    let defaultValues = {
        password: '',
        passwordConfirm: '',
        passwordCurrent: '',
        showPassword: false,
        showConfirmPassword: false,
        showCurrentPassword: false
    }
    const [passwordValid, setPasswordValid]=useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [showConfirmationPage, setShowConfirmationPage] = useState(false);


    const [error, setError] = useState({
        password: false,
        password_confirm: false,
    });
    const [values, setValues] = useState(defaultValues)

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    }

    const handleClickShowPassword = (event) => {
        setValues({...values, [event.currentTarget.name]: !values[event.currentTarget.name]})
    }

    const handleSave = async () => {
        if (!passwordValid){
            alert(errorMsg);
            return
        }
        let vaildPass = await bcrypt.compare(values.passwordCurrent, user.password)
        if (!vaildPass) {
            alert('Incorrect Password. Please try it again.')
            return
        }
        setPassword(values.password)
        setValues(defaultValues)
        close()
    }

    const handleCancel = () => {
        setValues(defaultValues)
        close()
    }

    useEffect(()=>{
        // regex order: at least one letter, at least one number, length > 8, at least one special char
        const fullValidator = new RegExp("(?=.*[a-zA-Z])"+"(?=.*[0-9])" +
            "(?=.{7,})" + "(?=.*[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?])");

        const letterValidator = new RegExp("(?=.*[a-zA-Z])");
        const numberValidator = new RegExp("(?=.*[0-9])");
        const specValidator = new RegExp("(?=.*[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?])");
        const lengthValidator = new RegExp("(?=.{8,})");

        const letterError = "at least one letter, ";
        const numberError = "at least one number, ";
        const specError = "at least one special character, ";

        const lengthError = "Password should contain 12 or more characters";
        let error = "";


        //console.log("b4: "+passwordValid);
        if (fullValidator.test(values.password)){
            setPasswordValid(true);
            error = "";
        }else{
            if (!lengthValidator.test(values.password)){
                error = lengthError;
            }else{
                error = "Password should contain "
                if (!letterValidator.test(values.password)){
                    error += letterError;
                }
                if (!numberValidator.test(values.password)){
                    error += numberError;
                }
                if (!specValidator.test(values.password)){
                    error += specError;
                }
            }
            setPasswordValid(false);
        }
        setErrorMsg(error);
        //console.log("after: "+passwordValid+" "+error);
    },[values])

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography className={classes.label}>Change Password</Typography>
                    <IconButton>
                        <CloseIcon onClick={close} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <TextField
                        label='Current password'
                        variant="outlined"
                        type={values.showCurrentPassword ? 'text' : 'password'}
                        value={values.passwordCurrent}
                        onChange={handleChange('passwordCurrent')}
                        error={!values.passwordCurrent}
                        helperText={!values.passwordCurrent ? 'Please enter your current password' : ''}
                        required
                        fullWidth
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle current password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                        name='showCurrentPassword'
                                    >
                                        {values.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <TextField
                        style={{ wordWrap: "break-word" }}
                        className={classes.confirmPassword}
                        label='New password'
                        variant="outlined"
                        onFocus={() => setShowConfirmationPage(true)}
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        error={!passwordValid}
                        helperText={errorMsg}
                        onBlur={(e) => {
                            setError({
                                ...error,
                                password:
                                    !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{12,}/.test(
                                        values.password
                                    ),
                            });
                            setShowConfirmationPage(false);
                        }}
                        required
                        fullWidth
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                        name='showPassword'
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    {showConfirmationPage && (
                        <PasswordChecklist
                            rules={["minLength", "specialChar", "number", "capital"]}
                            minLength={12}
                            value={values.password}
                            onChange={(isValid) => {}}
                            style={{ marginBottom: "20px" }}
                        />
                    )}
                    <TextField
                        className={classes.confirmPassword}
                        label='Confirm new password'
                        variant="outlined"
                        type={values.showConfirmPassword ? 'text' : 'password'}
                        value={values.passwordConfirm}
                        onBlur={(e) => {
                            setError({
                                ...error,
                                password_confirm: values.passwordConfirm !== values.password,
                            });
                        }}
                        onChange={handleChange('passwordConfirm')}
                        error={values.passwordConfirm && values.passwordConfirm !== values.password}
                        helperText={values.passwordConfirm && values.passwordConfirm !== values.password ? 'Please confirm the password' : ''}
                        required
                        fullWidth
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle confirm password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                        name='showConfirmPassword'
                                    >
                                        {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActions }}>
                <Button onClick={handleCancel} color='primary'>Cancel</Button>
                <Button onClick={handleSave} color='primary' disabled={values.password !== values.passwordConfirm || !values.password || !values.passwordConfirm || !values.passwordCurrent || !passwordValid  || !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{12,}/.test(
                    values.password
                )}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangePassword