/*
 * @Author: Tyler Bowman
 * @Date: 2021-01-22 10:49:56
 * @LastEditors: Tyler Bowman
 * @LastEditTime: 2021-01-22 10:49:56
 * @Description: Style Reformating
 */ 
import { makeStyles } from '@material-ui/core/styles';

//Warning: If any of these settings are overwritten by a style of the same name in one file, 
//ie. if reminderapp has a head with different attributes in their makestyles function
//it may apply that overwrite globally, changing the styles for all other files using this file
export default makeStyles((theme) => ({
    container: {
        display: 'flex',
        marginBottom: '1.5%'
    },
    logoContainer: {
        width: '180px',
        height: '100px',
        position: 'relative',
    
        '@media (max-width:960px)': { 
        width: '120px',
        height: '66.5px'
        }
    },
    logo: {
        height: '100px',
        width: '150px',
        cursor: 'pointer',
    
        ['@media (max-width:960px)']: { 
        width: '100px',
        height: '66.5px'
        }
    },
    imageButton: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-60%, -50%)',
        backgroundColor: 'white',
        color: 'black',
        '&:hover': {
        backgroundColor: 'white',
        color: 'black', 
        },
        display: 'none',
    },
    info: {
        flexGrow: 1,
    },
    title: {
        paddingTop: "0.2rem",
        fontWeight: 500,
        display: "block",
        fontSize: "2.35rem",
    
        ['@media (max-width:960px)']: { 
        fontSize: "29px"
        }
    },
    subtitleContainer: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap'
    },
    dropDown: {
        width:"190px",
        height: "48px"
    },
    hoverColor: {
        '&:hover': {
        backgroundColor: "#D3E2FF"
        }
    },
    formRow: {
        padding: '15px'
    },
    formControl: {
        width: '100%',
        boxSizing: "border-box",
        borderRadius: "4px",
        border: '1px solid #c7c7c7', 
        padding: '3px',
        marginBottom: '12px'
    },
    labelText: {
        fontSize: "12px",
        lineHeight: "14px",
        color: "#343434",
        marginLeft: "4px",
    },
    controls: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },

    cropContainer: {
        position: 'absolute',
        top:75,
        bottom:230,
        left:0,
        right:0,

      },


    textList: {
        letterSpacing: "0.1px",
        paddingLeft: "11px",
        paddingTop: "3px",
        paddingBottom: "4px",
    },


    
}))
