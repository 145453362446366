/*
 * @Author: Vincent Tran
 * @Date: 2020-08-04 04:30:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-08-05 03:58:00
 * @Description: file content
 */
/*
 * @Author: Vincent Tran
 * @Date: 2020-07-30 04:23:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-07-31 04:35:00
 * @Description: file content
 */
import { Box, Input, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useContext } from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle';
import UserPageStyles from '../../../../../styles/usersPageStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, UserPageStyles);


export default function EditPatientProfile(props) {
    const global=useContext(GlobalContext)
    const [roleNames, setRoleNames] = React.useState(props.account.roles);
    const roles =['oa','patient', 'doctor', 'hadmin']
    const [open, setOpen] = React.useState(false);
    const [openSelect, setOpenSelect] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        console.log(props.account)
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenSelect = () => {
        setOpenSelect(true);
    };
    const handleChange = (e) => {
        setRoleNames(e.target.value);
        setOpenSelect(false);

    }
    const handleSave = async() => {
        const temp=roleNames
        let result = await global.api.updateAccount({ 
            id: props.account._id,
            firstname: props.account.firstname,
            lastname: props.account.lastname,
            role: temp,
            orgId: global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id
           })
        if (result && result.status === 200) {
            handleClose();
            props.account.roles=temp
        } else {
            alert('Unable to update role.');
        }
    }

    const classes = combinedStyles();

    return (
        <div>
            <ButtonBase className={props.className} onClick={handleClickOpen}> <Typography className={classes.typography}>Modify Role</Typography></ButtonBase>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="xs"
                fullWidth
            >

                <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">Modify Role<IconButton onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} /> </DialogTitle>
                <DialogContent className={classes.content}>
                    <Box >
                        <Select
                            className={classes.selectBox}
                            labelId="demo-mutiple-name-label"
                            id="demo-mutiple-name"
                            multiple
                            labelWidth={3}
                            value={roleNames}
                            onChange={handleChange}
                            input={<Input />}
                            open={openSelect}
                            onOpen={handleOpenSelect}

                        >
                            {roles.map((name) => (
                                <MenuItem key={name} value={name}>
                                {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleClose} color="primary" className={classes.actionBtn}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" className={classes.actionBtn}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
