import { Box, IconButton, MenuItem, Popover } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useState } from 'react';
import AccountProfileStyle from '../../../../../styles/AccountProfileStyles';


const MorePopup = (props) =>{
    const classes = AccountProfileStyle()

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    //to end org agreement have to end on the orgs end as well
    return(
        <div>
            <IconButton className={classes.moreIcon} onClick={handleClick} >
                <MoreHorizIcon />
            </IconButton>
            <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
            elevation='2'
            >
                {props.default ? <MenuItem className={classes.popoverRemove}>End Agreement</MenuItem>
                : 
                <Box >
                    <MenuItem className={classes.popoverRemove} onClick={props.setDefault} id={props.id}>Set as Default</MenuItem> 
                    <MenuItem className={classes.popoverRemove}>End Agreement</MenuItem> 
                </Box>
                }
                
            </Popover>
        </div> 
    )
}

export default MorePopup