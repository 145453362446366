import {
    Box, Button,CircularProgress, Collapse,Divider, Grid,IconButton, Paper, Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import GlobalContext from "../../../../context/GlobalContext";
import settingsStyles from '../../../../styles/settingsPageStyles';
import AppointmentTypes from './components/AppointmentTypes';
import Bookings from './components/Bookings';
import ServiceTypes from './components/ServiceTypes';

let oldModesState;
let oldServiceState;
let oldState;

function AppointmentSetting() {

    const [expanded, setExpanded] = useState(false);

    const classes = settingsStyles()
    const global = useContext(GlobalContext)
    const [modesState, setModesState] = React.useState({});
    const [serviceState, setServiceState] = React.useState({});
    let org = {}
    if (global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0) {
        org = global.state.selectedOrg
    } else if (global.state.defaultOrg && Object.keys(global.state.defaultOrg).length !== 0) {
        org = global.state.defaultOrg
    }

    const loadDoctorModes = async (pulledModes) => {
       
        let arr2 = []
        
        //converts org.org_services into a format that is acceptable by translateappointmentmodeArray
        let tempModesArray = Object.keys(org.org_services).map(key => {
            return key;
        })
        let modesObject = await global.api.translateAppintmentModeArray(tempModesArray)

        //converting org.org_services into an array with enabled values, as translateappointmentmodearray does not allow true or false values
        tempModesArray = Object.keys(org.org_services).map(key => {
            return {key:key, enabled:org.org_services[key]}
            
        })
        //searches through available modes for corresponding id in org_services, as sometimes the order between the two are different
        for(let i = 0; i < modesObject.data.length; i++){
            let index = tempModesArray.findIndex((mode) => (mode.key === modesObject.data[i]._id))
            if(index !== -1 && tempModesArray[index].enabled){
                if(pulledModes && pulledModes !== null && pulledModes.includes(tempModesArray[index].key)){
                    arr2.push({id:modesObject.data[i]._id, name:modesObject.data[i].name, theme:modesObject.data[i].color, enabled: true})
                } else{
                    arr2.push({id:modesObject.data[i]._id, name:modesObject.data[i].name, theme:modesObject.data[i].color, enabled: false})
                } 
            }
            
        }

        setModesState({...modesState, modes:arr2})
    }

    const loadDoctorServices = async (pulledServices) => {
        
        let arr = []
        for(let i = 0; i < org.services.length; i++){
            if(pulledServices && pulledServices !== null && pulledServices.includes(org.services[i].name)){
                arr.push({id:i, name:org.services[i].name, description:org.services[i].description, enabled: true})
            } else{
                arr.push({id:i, name:org.services[i].name, description:org.services[i].description, enabled: false})
            }   
        }
        
        setServiceState({...state, services:arr})
    }

    const [state, setState] = useState({})

    const loadAllowWalkIn = async (allowWalkIns) => {
       
        setState({ 
            ...state,
            allowWalkIns: allowWalkIns.allowWalkIns ? allowWalkIns.allowWalkIns : false,
        })
    }

    const loadInfo = async () => {
        let resultDoctor
        let pulledServices
        let pulledModes
        let allowWalkIns
        try {
            resultDoctor = await global.api.getProviderDoctorByID(_id, org._id) 
            pulledServices = resultDoctor.data.data.data.org_services;
            pulledModes = resultDoctor.data.data.data.modes;
            allowWalkIns = resultDoctor.data.data.data.settings ? resultDoctor.data.data.data.settings[org._id] : false;
        } catch (error) {
            // console.log(error.response ? error.response.data : error);
            return;
        }     
        loadDoctorModes(pulledModes)
        loadDoctorServices(pulledServices)
        
        loadAllowWalkIn(allowWalkIns)

    }
    React.useEffect(() => {
        loadInfo()
        
        
    }, [org]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    const saveOldState = () => {
        oldModesState = JSON.parse(JSON.stringify(modesState));
        oldServiceState = JSON.parse(JSON.stringify(serviceState));
        oldState = JSON.parse(JSON.stringify(state));
        setState({ ...state, isEdit: true })
    }

    const loadOldState = () => {

        setModesState({...oldModesState})
        setServiceState({...oldServiceState})

        setState({ ...setState, isEdit: false})
        
    }
    let _id = global.state.user.document._id
    let orgId = global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id
    let tempModes = [];
    let tempServices = [];
    const saveModes = async () => {
        
        tempModes = []
        for(let i = 0; i < modesState.modes.length; i++){
            if(modesState.modes[i].enabled){
                tempModes.push(modesState.modes[i].id)
            }
        }
        await global.api.updateDoctorModes(orgId, _id, tempModes)
       
        
    }

    const saveServices = async () => {
        
        tempServices = []
        for(let i = 0; i < serviceState.services.length; i++){
            if(serviceState.services[i].enabled){
                tempServices.push(serviceState.services[i].name)
            }
        }
        
        await global.api.updateProviderServices(orgId, _id, tempServices)

    }
    const saveSettings = async () => {
        if (!org) {
            alert("No org!")
            return
        }

        setState({ ...state, isLoading: true })

        saveModes();
        
        saveServices();

        let result = await global.api.updateProviderAllowWalkIns({
            orgId: org._id,
            doctorId: _id,
            allowWalkIns: state.allowWalkIns
        })
    

        setState({ ...state, isLoading: false, isEdit: false })
    }
    return (
        <Paper elevation={3} className={clsx(classes.container, expanded ? classes.borderExpanded : classes.borderClosed)}>
            <Grid container>
                <Grid item xs={12} className={classes.paperComponment}>
                    <Box onClick={handleExpandClick} className={classes.labelWithEdit, classes.labelWithEditDropdown}>
                        <Typography className={clsx(classes.label, {
	                        	[classes.expandedLabel]: expanded
	                        })}>
                            Appointment Settings
                        </Typography>
                        <Typography className={classes.edit}>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Box className={!state.isEdit ? classes.disabledBackground : ''}>
                            <Grid item md={12} sm={12}>
                                <Divider className={classes.divider} />
                            </Grid>

                            <Grid item xs={12} className={classes.containerSpacing}>
                                <Bookings
                                state={state} 
                                setState= {setState}
                                />
                            </Grid>

                            <Grid item md={12} sm={12}>
                                <Divider className={classes.divider} />
                            </Grid>

                               
                            <Grid item xs={12}>
                                <Grid container flex>
                                    <Grid item md={6} xs={12} className={classes.dividerBorder}>
                                
                                        <AppointmentTypes isEdit={state.isEdit} state={modesState} setState={setModesState}/>

                                    </Grid>  

                                    <Grid item md={6} xs={12} >

                                        <Grid item xs={12}>
                                            <Divider className={classes.divider} />
                                        </Grid> 
                                        <ServiceTypes
                                            state = {serviceState}
                                            setState = {setServiceState}
                                            isEdit={state.isEdit}
                                            />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid item md={12} sm={12}>
                            <Divider className={classes.divider} />
                        </Grid>           

                        <Grid item md={12} sm={12} >
                            <Box className={clsx(classes.labelWithEditBottomSpacing, classes.labelWithEdit)}>
                                {state.isEdit ?
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        {state.isLoading ?
                                            <CircularProgress className={classes.loadingCircle} />
                                            :
                                            <Box md={5} sm={12}>
                                                <Button md={6} sm={12} className={clsx(classes.saveChangesBtn, classes.editToggleBtn)} variant="outlined" color='primary' onClick={saveSettings}>
                                                    Save Changes
                                                </Button>
                                                <Button md={6} sm={12} className={classes.editToggleBtn} variant="outlined" color='primary' onClick={() => loadOldState()}>
                                                    Cancel
                                                </Button>
                                            </Box>
                                        }
                                    </Box>
                                    :
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        
                                        {!modesState.modes ?
                                            <CircularProgress className={classes.loadingCircle} />
                                            :
                                            <Button md={5} sm={12} className={classes.editToggleBtn} variant="outlined" color='primary' onClick={() => saveOldState()}>
                                                Edit
                                            </Button>
                                        }
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>

        </Paper>
    );
}

export default AppointmentSetting;