import DateFnsUtils from "@date-io/date-fns";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useState } from "react";
import FaxHistoryModal from "./modal/FaxHistoryModal";

export default function MessageComponent(props) {
    const classes = props.classes;
    const [selectedDate, handleDateChange] = useState(new Date());
    const [selectedDate2, handleDateChange2] = useState(new Date());
    const [state, setState] = useState({
        sendFaxOpen: false,
    });

    return (
        <Box item xs={12} className={classes.paperComponment}>
            <Box className={classes.labelWithEdit}>
                <Typography
                    className={classes.labelSmall}
                    style={{
                        fontWeight: 500,
                        fontSize: "18px",
                        marginTop: "17px",
                        marginBottom: "19px",
                    }}
                >
                    Fax History
                </Typography>
                <Button color="primary">
                    <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 500 }}
                        onClick={() =>
                            setState({ ...state, sendFaxOpen: true })
                        }
                    >
                        VIEW&nbsp;HISTORY
                    </Typography>
                </Button>
            </Box>

            <FaxHistoryModal
                open={state.sendFaxOpen}
                close={() => setState({ ...state, sendFaxOpen: false })}
                state={state}
                setState={setState}
                classes={classes}
            />

            <Typography
                style={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "14.06px",
                    paddingBottom: "3px",
                    paddingTop: "1px",
                    paddingLeft: "5px",
                }}
            >
                Start Date
            </Typography>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    format="MMM dd, yyyy"
                    value={selectedDate}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(date) => handleDateChange(date)}
                />
            </MuiPickersUtilsProvider>

            <Typography
                style={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "14.06px",
                    paddingBottom: "3px",
                    paddingTop: "12px",
                    paddingLeft: "5px",
                }}
            >
                End Date
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    format="MMM dd, yyyy"
                    value={selectedDate2}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(date) => handleDateChange2(date)}
                />
            </MuiPickersUtilsProvider>
        </Box>
    );
}
