import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    Button,
    MenuItem,
} from "@material-ui/core";
import GlobalContext from "../../context/GlobalContext";

const useStyles = makeStyles((theme) => ({
    muiListItemButton: {
        "&:hover": {
            backgroundColor: "#D3E2FF",
        },
        dialogContentText: {
            fontSize: "16px",
            color: "red",
            fontweight: "400",
        },
    },
}));

const RemoveOrg = ({
    org,
    onMenuClick = () => {},
    onRemoveComplete = () => {},
}) => {
    const [removeOrgOpen, setRemoveOrgOpen] = useState(false);
    const global = useContext(GlobalContext);

    const handleOnMenuClick = () => {
        setRemoveOrgOpen(true);
        onMenuClick();
    };

    const handleRemoveOrg = async (evt) => {
        evt.preventDefault();
        const res = await global.api.removeOrg({ orgId: org._id });

        if (res.status === 200) {
            onRemoveComplete({ orgName: org.org_name });
            setRemoveOrgOpen(false);
        }
    };
    const classes = useStyles();

    return (
        <>
            <MenuItem
                onClick={handleOnMenuClick}
                className={classes.muiListItemButton}
            >
                Remove Organization
            </MenuItem>
            <Dialog
                open={removeOrgOpen}
                onClose={() => setRemoveOrgOpen(false)}
                maxWidth="xs"
                style={{ 
                    margin: "auto", 
                    width: "400px", 
                    marginTop: "5px",
                    borderRadius: "30px" }}
            >
                <DialogTitle>
                    <p style={{
                            fontSize: "24px",
                            fontFamily:"Roboto",
                            color: "#222222",
                            marginTop: "15px",
                            marginBottom: "5px"}}>
                         Remove Organizations
                    </p>
                </DialogTitle>
                {global.state.defaultOrg._id !== org._id ? (
                    <>
                        <DialogContent>
                            <DialogContentText
                                style={{
                                    fontSize: "16px",
                                    color: "#000000",
                                    fontweight: "400",
                                    fontFamily: "Roboto",
                                    marginTop: "-5px",
                                }}
                            >
                                Are you sure you want to remove{" "}
                                <strong> {org.org_name}?</strong> You will not
                                be able to revert this.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ marginBottom: "20px" }}>
                            <Box
                                style={{
                                    margin: "auto",
                                    marginLeft: "7%",
                                    fontweight: "400",
                                }}
                            >
                                <Button
                                    onClick={() => setRemoveOrgOpen(false)}
                                    color="primary"
                                    style={{ fontWeight: "bold" }}
                                >
                                    BACK
                                </Button>
                            </Box>
                            <Box style={{ margin: "auto", marginRight: "6%" }}>
                                <Button
                                    onClick={handleRemoveOrg}
                                    type="submit"
                                    style={{ float: "right", color: "#D14444", fontWeight: "bold" }}
                                    autoFocus
                                >
                                    REMOVE
                                </Button>
                            </Box>
                        </DialogActions>
                    </>
                ) : (
                    <>
                        <DialogContent>
                            <DialogContentText>
                                You cannot remove the Default Organization
                                (Janitor). Please choose another organization.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ marginBottom: "20px" }}>
                            <Box style={{ margin: "auto" }}>
                                <Button
                                    onClick={() => setRemoveOrgOpen(false)}
                                    color="primary"
                                    style={{ float: "left", fontWeight: "bold" }}
                                >
                                    OKAY
                                </Button>
                            </Box>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default RemoveOrg;
