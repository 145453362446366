import {
    Box,
    Button,
    Grid,
    InputBase,
    Paper,
    Typography
} from '@material-ui/core';
import React from 'react';
import settingsStyles from '../../../../../styles/settingsPageStyles';

// API Key Segment of Drawer
export default function APIKey({statusesNewConfig, setStatusesNewConfig, statuses}) {
    const classes = settingsStyles()

    return (
        <Box p={3}>
            <div className="emrTour">
                <Grid container style={{marginBottom:'0%'}}>
                    <Grid item md={10} sm={10}>
                        <Typography className={classes.labelSmall} style={{margin: '0', paddingTop: '9px'}}> API Key </Typography>
                        
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Typography style={{color:'#767676', marginBottom:'10px', marginTop:'10px'}}>
                                    Generate a key to get access to Porton's API. Make sure to keep your key safe!
                                </Typography>
                            </Grid>

                            <Grid item xs={6} md={6}>
                                <Box className={classes.copyLinkButtonContainer}>
                                    <Paper component="form" className={classes.paperRoot}>
                                        <InputBase></InputBase>
                                    </Paper>
                                    <Button variant="contained" className={classes.copyButton} color="primary">
                                        COPY
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item  md={2} xs={2}>
                        <Button color="primary" style={{float: 'right'}}> GENERATE </Button>
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
}
