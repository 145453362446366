import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme)=>({

    mainBody:{
        marginTop:'2rem',
    },
    
    //*** PROFILE COMPONENTS ***\\

    //create border shape
    root: {
        border: "2px solid rgba(255,255,255,0)",
        borderRadius: "10px"
    },
    //if expanded changes border
    rootExpanded: {
        border: "2px solid #ea8030",
        borderRadius: "10px"
    },
    //padding aroung label and icon
    paperComponment: {
        padding: "1rem 1rem"
    },
    //vertically center and have space between label and dropdown icon
    labelIconFormat: {
        display: "flex",
        justifyContent: "space-between"
    },
    label: {
        fontSize: "1.2rem",
        color: "black",
        fontWeight: 500,
        width: "100%",
        margin:"auto 0",
        '@media (max-width:960px)': { 
            display: 'none',
        },
    },
    
    iconFormat: {
        color: "#003FBA",
        marginRight: "0.5rem",
    },
    //sets icon facing in default position with transition time
    expandDefault: {
        transform: 'rotate(0deg)',
        //marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    //sets icon in expanded position
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    //if edit button not selected 
    disabledBackground: {
        pointerEvents: "none",
        backgroundColor: "#7B7B7B",
        paddingTop:'1rem',
        borderRight:'solid 1px rgba(0, 0, 0, 0.12)'
    },
    topPadding: {
        paddingTop:'1rem',
        borderRight:'solid 1px rgba(0, 0, 0, 0.12)'
    },
    
    //*** EDITSAVE COMPONENT ***\\
    
    //icon placement when clicking save
    loadingIcon:{
        display:'flex',
        justifyContent:'flex-end'
    },
    saveEditBtn: {
        padding: '20px 25px',
        display:'flex',
        justifyContent:'flex-end',
    },
    cancelBtn:{
        color:'#ea8030',
        borderColor:'#ea8030',
        '&:hover':{
            color:'#ea8030',
            borderColor:'#ea8030',
        },
        marginLeft: "25px",
        display:'flex',
        justifyContent:'flex-end'
    },
    buttonColor:{
        color:'#ea8030',
        borderColor:'#ea8030',
        '&:hover':{
            color:'#ea8030',
            borderColor:'#ea8030',

        }
    },

    //*** CERTIFICATE COMPONENT ***\\

    spacingTopHeader:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        margin:'1.1rem 1rem 0rem 2rem'
    },

    headingLabel:{
        fontSize: "1rem",
        color: "#343434",
        fontWeight: 500,
        fontFamily: "Roboto",
    },
    editLabel:{
        fontSize: "0.85rem",
        color: "#ea8030",
        "&:hover": {
            backgroundColor: "transparent",
            textDecoration:'underline'
        },
        
    },
    certInput:{
        borderRadius: 4,
        border: '1px solid #ced4da',
        fontSize: '1rem',
        minWidth: '20rem'
    },
    addFile:{
        marginTop:'1.25rem',
        border:'solid 1px #343434',
    },

    iconRight:{
        display:'flex',
        justifyContent:'flex-end',
        marginRight:'1rem'
    },

    sectionBody:{
        margin:'0 2rem'
    },

    //*** SPECIALTY COMPONENT ***\\
    bottomMargin:{
        marginBottom:'1rem'
    },
    spreadHeader:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        margin:'0 2rem'
    },
    spreadSubHeader:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    closeIcon:{
        marginRight:'24px',
        cursor:'pointer'
    },
    specialties:{
        padding:'0 0.2rem 0 0.75rem',
        margin:'0.25rem 0.25rem', 
        display:'inline-flex', 
        alignItems:'center',
        color:'black',
        backgroundColor:'#eaeaea',
        borderRadius: '15px'
    },
    removeIcon:{
        cursor:'pointer',
        marginLeft:'0.5rem',
        zIndex:'3'
    },
    searchBar:{
        margin:'1rem 2rem',
    },
    specialtiesBox:{
        margin:"0 2rem",
        maxWidth:"25rem"
    },
    cancelSave:{
        margin:'1rem 2rem',
        justifyContent:'space-between',
    },
    cancelSaveButton:{
        color:'#ea8030',
        fontSize:'1rem'
    },
    moreIcon:{
        padding:'0'
    },
    popover:{
        '& hover':{
            backgroundColor:'red'
        }
    },
    popoverRemove:{
        padding:'0.5rem 1rem',
    },

    specialtyName:{
        marginBottom:'0.5rem',
        display:'inline'
    },
    //*** BIO COMPONENT ***\\
    
    textFieldBody:{
        margin:'1rem  2rem 0 2rem'
    },

    labelHeading:{
        marginTop:'1rem',
        fontSize: "1rem",
        color: "#343434",
        fontWeight: 500,
        fontFamily: "Roboto",
    },
    countLabelBox:{
        display:'flex',
        justifyContent:'flex-end',
        margin:'0rem 2rem'
    },
    subText:{
        fontSize: "0.85rem",
        color: "#999999",
        marginBottom:'1rem',
        lineHeight:'110%'
    },
    textField:{
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ea8030"
        }
    },

    ///*** ORG COMPONENT ***\\\
    bodyMargin:{
        margin:'1rem 2rem'
    },
    dialogText:{
        textAlign:'center'
    },
    centerButton:{
        justifyContent:'center'
    }
    
    

    



}))