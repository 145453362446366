/*
 * @Author: Tyler Bowman
 * @Date: 2021-01-22 10:49:56
 * @LastEditors: Tyler Bowman
 * @LastEditTime: 2021-01-22 10:49:56
 * @Description: Style Reformating
 */ 
import { makeStyles } from '@material-ui/core/styles';

//Warning: If any of these settings are overwritten by a style of the same name in one file, 
//it may apply that overwrite globally, changing the styles for all other files using this file

const drawerWidth = 240;

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
 
       
    
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    listItem: {
        paddingLeft: "3rem",

    },
    hamburgerIconSpacing: {
        padding: "6px",  
    },
    hamburger: {
        width: '30px',
        height: '30px', color: 'black', 
    },

    listText: {
        fontWeight: 550,
        color: "#767676",
    },
    // necessary for content to be below app bar
    toolbar: {
        minHeight:"0"
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3), paddingTop:'10px', 
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    menuContentContainer: {
        display: 'flex',
    },
    menuItemOuterContainer: {
        alignItems: "left",
    },

    icon: {
        marginTop: "0.3rem",
        marginRight: "1.2rem",
        marginLeft: "2rem"
    },
    labelIcon: {
        minHeight: 60
    },
    labelContainer: {
        width: "auto",
        padding: 0
    },
    iconLabelWrapper: {
        flexDirection: "row",
        justifyContent: "left"
    },

    mutiTabWrapper: {
        alignItems: "left",
    },
    logoImage: {
        width: "220px",
    },
    drawerLogo: {
        marginTop: "3rem",
        marginLeft: "3rem"
    },
    drawerAccount: {
        marginTop: "3rem",
        marginLeft: "3rem",
        marginBottom: "3rem"
    },
    accountName: {
        marginTop: "3rem",
        textAlign: "center",
        marginBottom: "-0.25rem",
        fontSize: "1.5rem",
        fontWeight: 500,
    },
    selectedOrg:{
        textAlign: "center",
        padding:"0 3rem 0 3rem",
        marginBottom:"3rem",
        fontWeight: 500
    },
    transparencyDropsProps: {
        opacity: 0.8,
    },
    profileOff:{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor:'transparent',
        background: 'radial-gradient(196.67% 240.67% at -54.22% -111.25%, #FFFFFF 0, #003FBA 100%)',
        width: '0.5rem',
    },
    profileOn:{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor:'transparent',
        width: 8,
        
    },
    accountLink:{
        textDecoration:'none',
        color: 'black',

        '&:hover':{
            textDecoration:'underline'
        }  
    },
   

}));
