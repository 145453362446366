import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import BootstrapInput from '../../../../../../styles/BootstrapInput';
import { TextMaskDate, TextMaskTime } from '../../../TextMask';

export default function UrlComponent(props) {

    const classes = props.classes;
    const state = props.state;
    const handleChange = props.handleChange;
    return (
        
        <>
            
            <Grid container spacing={3} className={classes.row}>
                {(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) ?
                    <Grid item xs={12} sm={6}>
                        <InputLabel className={classes.formLabel} shrink htmlFor="date-input">
                            Date
                        </InputLabel>
                        <BootstrapInput 
                            fullWidth 
                            name="date" 
                            value={state.date} 
                            id="date-input" 
                            placeholder={'YYYY-MM-DD'}
                            onChange={(e) => handleChange(e)} 
                            inputComponent={TextMaskDate}/>
                    </Grid>
                    :
                    <Grid item xs={12} sm={6}>
                    <InputLabel className={classes.formLabel} shrink htmlFor="bootstrap-input">
                        Date
                    </InputLabel>
                    <BootstrapInput fullWidth type="date" name="date" value={state.date} onChange={(e) => handleChange(e)} id="bootstrap-input" />
                </Grid>
                }
                

               
                
                {(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))|| navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ?
                    <Grid item xs={12} sm={6}>
                        <InputLabel className={classes.formLabel} shrink htmlFor="time-input">
                            time
                        </InputLabel>
                        <BootstrapInput 
                            fullWidth 
                            name="time" 
                            value={state.time} 
                            id="time-input" 
                            placeholder={'HH:MM'}
                            onChange={(e) => handleChange(e)} 
                            inputComponent={TextMaskTime}/>
                
                    </Grid>
                    :
                    <Grid item xs={12} sm={6}>
                        <InputLabel className={classes.formLabel} shrink htmlFor="bootstrap-input">
                            Time
                        </InputLabel>
                        <BootstrapInput onChange={(e) => handleChange(e)} name="time" value={state.time} fullWidth type="time" id="bootstrap-input" />
                    </Grid>
                }
            </Grid>
            
            <Grid container spacing={3} className={classes.row}>
                <Grid item xs={12}>
                    <InputLabel className={classes.formLabel} shrink htmlFor="comments-input">
                        Comments
                    </InputLabel>
                    <BootstrapInput 
                        fullWidth 
                        multiline 
                        rows={4} 
                        name="comments" 
                        value={state.comments} 
                        id="comments-input"
                        className={classes.textArea}
                        onChange={(e) => handleChange(e)} />
                </Grid>
            </Grid>

        </>
    );
}

