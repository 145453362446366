import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  // header: {
  //   padding: "4% 0 0 7%",
  // },

  getReportPortonImgHeader: {
    width: "auto",
    height: "auto",
    minWidth: "104.25px",
    maxWidth: "240px",
    marginBottom: "10px",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    // Additional styles for the container when displayed as a column
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // Additional styles for the container when displayed as a row
  },
  addressAndPhone: {
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 768px)": {
      justifyContent: "space-between",
    },
  },

  pageRefresh: {
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  footer: {
    //position: 'fixed',
    bottom: "5%",
    paddingLeft: "5%",
    paddingRight: "5%",
    width: "90%",
    [theme.breakpoints.down(1000)]: {
      position: "static",
    },
  },

  footerText: {
    [theme.breakpoints.down(440)]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "0.6rem",
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    "@media (max-width: 768px)": {
      fontSize: "0.7rem",
    },
  },

  portonImgHeader: {
    width: "auto",
    height: "auto",
    minWidth: "104.25px",
    maxWidth: "150px",
  },
  portonImgHeaderDiv: {
    display: "flex",
    justifyContent: "flex-start",
    "@media (max-width: 768px)": {
      justifyContent: "center",
    },
  },

  portonImgFooter: {
    width: "auto",
    height: "90px",
  },
}));
