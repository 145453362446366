import { Menu, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, { useCallback, useContext, useEffect, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import DisableOrg from "./OrgEditorDialogs/DisableOrg";
import EditOrgProfile from "./OrgEditorDialogs/EditOrgProfile";
import EditOrgTheme from "./OrgEditorDialogs/EditOrgTheme";
import RemoveOrg from "./OrgEditorDialogs/RemoveOrg";
import RemoveSuccessDialog from "./OrgEditorDialogs/RemoveSuccessDialog";
import OrgSelectorConfirmationDialog from "./OrgSelectorConfirmationDialog";
import OrgSelectorSuccessDialog from "./OrgSelectorSuccessDialog";

const useStyles = makeStyles((theme) => ({
    head: {
        display: "flex",
    },
    title: {
        paddingTop: "0.2rem",
        fontWeight: 500,
        display: "block",
        marginRight: "2rem",
        fontSize: "2rem",
        marginBottom: "2rem",
    },
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    divider: {
        marginTop: "1rem",
        marginBottom: "2rem",
    },
    container: {
        marginTop: "2rem",
    },
    typography: {
        padding: theme.spacing(1),
    },
    paper: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        textAlign: "left",
        marginBottom: theme.spacing(2),
    },
    buttonPapar: {
        width: "100%",
        height: "100%",
    },
    buttonViewAllPatients: {
        width: "100%",
        height: "100%",
        fontSize: "1rem",
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#eeeeee",
            // borderColor: '#0062cc',
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#dddddd",
        },
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
        },
    },
    buttonInvites: {
        width: "100%",
        height: "100%",
        fontSize: "1rem",
        boxShadow: "none",
        color: "white",
        background:
            "radial-gradient(196.67% 240.67% at -54.22% -111.25%, #FFFFFF 0, #003FBA 100%)",
        alignItems: "left",

        "&:hover": {
            backgroundColor: "#eeeeee",
            // borderColor: '#0062cc',
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#dddddd",
        },
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
        },
    },
    iconButton: {
        padding: 10,
    },

    dialogActions: {
        display: "flex",
        justifyContent: "space-between",
        margin: 11,
        fontSize: "14px",
        fontWeight: "bold",
    },
    muiListItemButton: {
        "&:hover": {
            backgroundColor: "#D3E2FF",
        },
    },
    '@global': {
        '*::-webkit-scrollbar': {
        width: '0.4em',
        height: '0.4em',
        },
        '::-webkit-scrollbar-track': {
            borderRadius:'50px',
        },
        '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        }
    },
}));

const OrgItem = ({
    org,
    isSelected = false,
    handleEnableOrg = () => {},
    handleOrgSelectionChange = () => {},
    onSaveComplete = () => {},
    onRemoveComplete = () => {},
    onDisableComplete = () => {},
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isPopoverOpen = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const editProfile = () => {
    //     handleClose();
    // };

    const onHandleEnableOrg = () => {
        handleClose();
        handleEnableOrg();
    };
    
    return (
        <Paper
            elevation={1}
            className={classes.paper}
            container
            style={{
                paddingLeft: "9px",
                paddingRight: "9px",
                backgroundColor: org.disabled ? "#eaeaea" : "unset",
            }}
        >
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-around"
                alignItems="center"
            >
                <Grid item alignItems="center" xs={5}>
                <p  title={org.org_name} style={{overflow: "hidden", textOverflow: "ellipsis"}} >
                    {org.org_name}
                    </p>                </Grid>
                <Grid item alignItems="center" xs={5}>
                    <p  title={org.admins[0].email} style={{overflow: "hidden", textOverflow: "ellipsis"}} >
                    {org.admins[0].email}
                    </p>
                </Grid>
                <Grid item alignItems="center" xs={1}>
                    {!org.disabled && (
                        <Radio
                            alignItems="center"
                            color="primary"
                            inputProps={{
                                "aria-label": "secondary radio",
                            }}
                            checked={isSelected}
                            onChange={handleOrgSelectionChange}
                            style={{float:'right'}}
                        />
                    )}
                </Grid>
                <Grid item xs={1} alignItems="center">
                    <IconButton
                        aria-label="menu"
                        aria-controls="menu"
                        aria-haspopup="true"
                        onClick={(evt) => setAnchorEl(evt.target)}
                        style={{float:'right'}}
                    >
                        <MoreHorizIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={isPopoverOpen}
                        onClose={handleClose}
                    >
                        <EditOrgProfile
                            org={org}
                            onMenuClick={handleClose}
                            onClose={handleClose}
                            onSaveComplete={onSaveComplete}
                            className={classes.muiListItemButton}
                        />
                        <EditOrgTheme
                            org={org}
                            onMenuClick={handleClose}
                            onClose={handleClose}
                            onSaveComplete={onSaveComplete}
                            

                        />
                        {org.disabled ? (
                            <MenuItem
                                onClick={onHandleEnableOrg}
                                className={classes.muiListItemButton}
                            >
                                Enable Organization
                            </MenuItem>
                        ) : (
                            <DisableOrg
                                org={org}
                                onMenuClick={handleClose}
                                onDisableComplete={onDisableComplete}
                                className={classes.muiListItemButton}
                            />
                        )}
                        <RemoveOrg
                            org={org}
                            onMenuClick={handleClose}
                            onRemoveComplete={onRemoveComplete}
                        />
                    </Menu>
                </Grid>
            </Grid>
        </Paper>
    );
};

const OrgSelectorPopup = ({ open, onClose }) => {
    const classes = useStyles();
    const global = useContext(GlobalContext);

    const [allOrgs, setAllOrgs] = useState([]);
    // const [savedOrg, setSavedOrg] = useState(global.state.selectedOrg);

    const [selectedOrg, setSelectedOrg] = useState(undefined);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [isRemoveSuccessDialogOpen, setIsRemoveSuccessDialogOpen] = useState(
        false
    );

    const fetchAllOrgs = async () => {
        try {
            const results = await global.api.listOrgs();
            results && setAllOrgs(results.data);
        } catch (err) {
            // console.error("unable to get all orgs from api", );
            // hanlde cases when the orgs cannot be fetched
        }
    };

    const onSaveComplete = useCallback(async () => {
        await fetchAllOrgs();
    }, [fetchAllOrgs]);

    const onDisableComplete = useCallback(async () => {
        await fetchAllOrgs();
    }, [fetchAllOrgs]);

    const handleEnableOrg = useCallback(
        async (orgId) => {
            await global.api.updateDisableOrg(orgId, false);
            await fetchAllOrgs();
        },
        [fetchAllOrgs, global]
    );

    const onRemoveComplete = useCallback(
        async ({ orgName }) => {
            setIsRemoveSuccessDialogOpen(true);
            await fetchAllOrgs();
        },
        [fetchAllOrgs]
    );

    useEffect(() => {
        fetchAllOrgs();
        setSelectedOrg(
            global.state?.selectedOrg?._id
                ? global.state?.selectedOrg
                : global.state?.defaultOrg
        );
    }, [
        open,
        global,
        global.state,
        global.state.selectedOrg,
        global.state.defaultOrg,
    ]);

    const onSave = () => {
        setIsConfirmationOpen(true);
    };

    const confirmOrgSelectionChange = () => {
        global.setState((prev) => {
            return {
                ...prev,
                selectedOrg,
            };
        });
        setIsConfirmationOpen(false);
        onClose();
        setIsSuccessDialogOpen(true);
    };

    const handleOrgSelectionChange = (org) => {
        setSelectedOrg(org);
    };

    return (
        <Dialog
            open={open}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{overflowY: "scroll"}}
        >
            <DialogTitle
                className={classes.dialogTitle}
                id="alert-dialog-slide-title"
                maxWidth="300"
                style={{
                    fontSize: "24px",
                    color: "#444444",
                    fontWeight: "500",
                }}
            >
                All Organizations
                <IconButton
                    onClick={onClose}
                    className={classes.dialogClose}
                    children={<ClearIcon />}
                    style={{
                        cursor: "pointer",
                        float: "right",
                        margin: "-8px",
                        color: "#000000",
                    }}
                />
                <DialogContentText
                    style={{
                        fontSize: "15px",
                        fontWeight: "300",
                        fontFamily: "Roboto",
                        marginTop:"2%",
                    }}
                >
                    Please select an organization to change your current
                    organization
                </DialogContentText>
            </DialogTitle>
            <DialogContent
                style={{
                    padding: 20,
                    marginTop: -31,
                    fontFamily: "Roboto",
                    fontSize: "16px",
                }}
            >
                <Grid container spacing={1}>
                    <Grid item justifyContent="flex" xs={5}>
                        <div
                            className={classes.paper}
                            style={{
                                color: "#343434",
                                marginLeft: -17,
                                fontSize: "14px",
                                fontWeight: 500,
                            }}
                        >
                            Organization's Name
                        </div>
                    </Grid>
                    <Grid item justifyContent="flex-start" xs={5}>
                        <div
                            className={classes.paper}
                            style={{
                                color: "#343434",
                                fontSize: "14px",
                                fontWeight: 500,
                            }}
                        >
                            Organization's Email
                        </div>
                    </Grid>
                </Grid>
                {allOrgs?.length > 0 &&
                    allOrgs.map((org) => (
                        <OrgItem
                            key={org._id}
                            org={org}
                            isSelected={org._id === selectedOrg?._id}
                            handleOrgSelectionChange={() =>
                                handleOrgSelectionChange(org)
                            }
                            onSaveComplete={onSaveComplete}
                            onRemoveComplete={onRemoveComplete}
                            onDisableComplete={onDisableComplete}
                            handleEnableOrg={() => handleEnableOrg(org._id)}
                        />
                    ))}
                <OrgSelectorConfirmationDialog
                    open={isConfirmationOpen}
                    onClose={() => setIsConfirmationOpen(false)}
                    newOrgName={selectedOrg?.org_name}
                    onConfirm={confirmOrgSelectionChange}
                />
                <OrgSelectorSuccessDialog
                    newOrgName={selectedOrg?.org_name}
                    open={isSuccessDialogOpen}
                    onClose={() => setIsSuccessDialogOpen(false)}
                />
                <RemoveSuccessDialog
                    orgName="disney"
                    open={isRemoveSuccessDialogOpen}
                    onClose={() => setIsRemoveSuccessDialogOpen(false)}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onClose} color="primary">
                    CANCEL
                </Button>
                <Button onClick={onSave} color="primary">
                    SAVE
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrgSelectorPopup;
