/*
 * @Author: Vincent Tran
 * @Date: 2020-07-21 01:10:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-07-21 01:24:00
 * @Description: Styling for popups (Material-UI Dialog components)
 */

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    /* The "X" button on the top right to close the popup */
    dialogClose: {
        padding: 0,
        float: "right"
    },

    /* Title of the popup */
    dialogTitle: {
        marginBottom: 0,
        paddingBottom: 0,
        paddingRight: "1rem"
    },

    /* Description in the popup */
    dialogDescription: {
        color: "black",
        fontSize: "0.9rem",
        maxWidth: "18rem",
        wordWrap: "break-word"
    },

    /* Group of buttons at the bottom of the popup (i.e. Cancel or OK) */
    dialogAction: {
        display: "flex",
        justifyContent: "space-between"
    },

    /* Single button at the bottom of the popup (i.e. Cancel or OK) */
    actionBtn: {
        margin: "0rem 1rem 0.5rem 1rem"
    },

    /* Popup confirming the results of an earlier action */
    resultsModal: {
        textAlign: "center"
    },

    /* Description of results */
    resultsDescription: {
        marginTop: "1rem",
        color: "black",
        fontSize: "1rem",
        maxWidth: "20rem",
        wordWrap: "break-word",
        fontWeight: "bold"
    },

    /* Group of buttons at the bottom of the results popup (i.e. Cancel or OK) */
    resultsAction: {
        margin: "0 auto"
    },

    /* Image of a tick (check-mark) for the success popup */
    tick: {
        marginTop: "1rem",
        width: "4rem"
    },
    button3: {
        width: "100%",
        height: "40px",
        fontSize: "16px",
        boxShadow: 'none',
        paddingTop: "0rem",
        paddingBottom: "0rem",
        // height: "42px",
        color: "#003FBA",
        borderColor:"#003FBA",
        

        '&:hover': {
            backgroundColor: '#D3E2FF',
             borderColor: "#003FBA",
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#dddddd',

        },
        '&:focus': {
            boxShadow: 'none',
            backgroundColor: '#D3E2FF',
        },

    },
}));