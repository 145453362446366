/*
 * @Author: Vincent Tran
 * @Date: 2020-08-05 04:39:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-08-20 02:00:00
 * @Description: file content
 */
/**
 * TO DO: 
 * Connect this component to the database...
 * 1) Retrieve provider data from database, save info to state, and display.
 * 2) Edits made to provider data must update in the database (handleSave).
 * 
 * Other minor enhancements:
 * 1) Possibly need to add more dropdown options for Title
 * 2) Switch current dropdown selects for country, year, to be virtualized lists for faster loading
 */

import { TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from "@material-ui/core/ButtonBase";
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import allCountries from '../../../../../resources/countries';
import allProvinces from '../../../../../resources/provinces';
import allSpecialties from '../../../../../resources/specialties';
import BootstrapInput from '../../../../../styles/BootstrapInput';
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle';
import UserPageStyles from '../../../../../styles/usersPageStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, UserPageStyles);

export default function EditPatientProfile(props) {
    // temporary default values..
    // Real values should be pulled from database in the future
    let arr = []
    if(!props.account.specialty){
        if(props.account.specialty_1 && props.account.specialty_1 !== ""){
            arr.push(props.account.specialty_1)
        }
        if(props.account.specialty_2 && props.account.specialty_2 !== ""){
            arr.push(props.account.specialty_2)
        }
        if(props.account.specialty_3 && props.account.specialty_3 !== ""){
            arr.push(props.account.specialty_3)
        }
    }
    const userInfo = {
        title: props.account.title || 'Dr',
        fName: props.account.firstname || props.account.first_name,
        lName: props.account.lastname || props.account.last_name,
        cert: props.account.doctor_board_certified || "",
        certCountry: props.account.country || "",
        certProv: props.account.doctor_license_location || "",
        certYear: props.account.doctor_certified_year || "",
        specialties: props.account.specialty ? props.account.specialty.split(',') : arr,
        bio: props.account.bio || '',

    }
    const global = React.useContext(GlobalContext);
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({...userInfo});
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    };
    const handleChange = (event) => {
        setState({ 
            ...state, 
            [event.target.name]: event.target.value
        })
    }
    const handleAutoComplete = (event, values) => {
        setState({ 
            ...state, 
            specialties: values
        })
    }
    const handleSave = async () => {
        // save user profile info to database here...
        let result = await global.api.updateAccount({
            id: props.account._id,
            firstname: state.fName,
            lastname: state.lName,
            specialty: state.specialties.toString(),
            bio: state.bio,
            profileSet: true,
            title: state.title,
            contact_name: state.fName + " " + state.lName,
            role: props.account.roles && props.account.roles.length ? props.account.roles[0] : 'doctor',
            doctor_board_certified: state.cert,
            country: state.certCountry,
            doctor_license_location: state.certProv,
            doctor_certified_year: state.certYear,
            orgId: global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id
        });
        if (result && result.status === 200) {
            handleClose();
            props.refresh();
        } else {
            alert('Unable to update profile.');
        }
    }

    const classes = combinedStyles();

    const years = [];
    for (let year = new Date().getFullYear(); year >= 1900; year--) {
        years.push(<MenuItem value={year}>{year}</MenuItem>);
    }

    const countryList = allCountries.map((country, i) => {
        return <MenuItem key={i} value={country}>{country}</MenuItem>
    })

    const provList = [];
    for (var prov in allProvinces) {
        provList.push(<MenuItem value={allProvinces[prov]}>{allProvinces[prov]}</MenuItem>)
    }

    return (
        <div>
            <ButtonBase className={props.className} onClick={handleClickOpen}> <Typography className={classes.typography}>Edit Profile</Typography></ButtonBase>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >

                <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">Edit Profile<IconButton onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} /> </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid container>
                        <Grid container spacing={3} className={classes.row}>
                            <Grid item xs={12} sm={6}>
                                <InputLabel className={classes.formLabel} shrink htmlFor="title-input">
                                    Title
                                </InputLabel>
                                {/* May need to add more dropdown options here...  */}
                                <FormControl fullWidth variant="outlined" className={classes.dropdown}>
                                    <Select
                                        fullWidth
                                        name="title"
                                        value={state.title}
                                        onChange={(e) => handleChange(e)}
                                        input={<BootstrapInput/>}
                                        id="title-input"
                                    >
                                        <MenuItem value="Dr">Dr.</MenuItem>
                                        <MenuItem value="Mr">Mr.</MenuItem>
                                        <MenuItem value="Mrs">Mrs.</MenuItem>
                                        <MenuItem value="Ms">Ms.</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputLabel className={classes.formLabel} shrink htmlFor="fName-input">
                                    First Name
                                </InputLabel>
                                <BootstrapInput 
                                    fullWidth 
                                    name="fName" 
                                    value={state.fName} 
                                    id="fName-input" 
                                    onChange={(e) => handleChange(e)} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputLabel className={classes.formLabel} shrink htmlFor="lName-input">
                                    Last Name
                                </InputLabel>
                                <BootstrapInput 
                                    fullWidth 
                                    name="lName" 
                                    value={state.lName} 
                                    id="lName-input" 
                                    onChange={(e) => handleChange(e)} />
                            </Grid>
                        </Grid>

                        {props.account && props.account.roles[0] === 'doctor' &&
                            <>
                                <Typography>Certification</Typography>

                                <Grid container spacing={3} className={classes.row}>
                                    <Grid item xs={12} sm={3}>
                                        <InputLabel className={classes.formLabel} shrink htmlFor="cert-input">
                                            Eligible or Certified
                                        </InputLabel>
                                        {/* May need to add more dropdown options here...  */}
                                        <FormControl fullWidth variant="outlined" className={classes.dropdown}>
                                            <Select
                                                fullWidth
                                                name="cert" 
                                                value={state.cert} 
                                                defaultValue=""
                                                id="cert-input" 
                                                onChange={(e) => handleChange(e)}
                                                input={<BootstrapInput />}
                                            >
                                                <MenuItem value="" className={classes.option}></MenuItem>
                                                <MenuItem value="Certified">Board Certified</MenuItem>
                                                <MenuItem value="Eligible">Eligible to Practice</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <InputLabel className={classes.formLabel} shrink htmlFor="country-input">
                                            Country
                                        </InputLabel>
                                        {/** 
                                         *  May need to change to virtualized list here for faster loading...  
                                         **/}
                                        <FormControl fullWidth variant="outlined" className={classes.dropdown}>
                                            <Select
                                                fullWidth
                                                name="certCountry" 
                                                value={state.certCountry} 
                                                defaultValue=""
                                                id="country-input" 
                                                onChange={(e) => handleChange(e)}
                                                input={<BootstrapInput />}
                                            >
                                                <MenuItem value="" className={classes.option}></MenuItem>
                                                {countryList}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <InputLabel className={classes.formLabel} shrink htmlFor="province-input">
                                            Province
                                        </InputLabel>
                                        <FormControl fullWidth variant="outlined" className={classes.dropdown}>
                                            <Select
                                                fullWidth
                                                name="certProv" 
                                                value={state.certProv} 
                                                defaultValue=""
                                                id="province-input" 
                                                onChange={(e) => handleChange(e)}
                                                input={<BootstrapInput />}
                                            >
                                                <MenuItem value="" className={classes.option}></MenuItem>
                                                {provList}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                    <InputLabel className={classes.formLabel} shrink htmlFor="year-input">
                                            Year
                                        </InputLabel>
                                        {/** 
                                        *  May need to change to virtualized list here for faster loading...  
                                        **/}
                                        <FormControl fullWidth variant="outlined" className={classes.dropdown}>
                                            <Select
                                                fullWidth
                                                name="certYear" 
                                                value={state.certYear} 
                                                defaultValue=""
                                                id="year-input" 
                                                onChange={(e) => handleChange(e)}
                                                input={<BootstrapInput />}
                                            >
                                                {years}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className={classes.row}>
                                    <Grid item xs={12}>
                                        <Typography>Specialty</Typography>
                                        <Autocomplete
                                            multiple
                                            name="specialties" 
                                            id="specialties-input" 
                                            options={allSpecialties}
                                            getOptionLabel={(option) => option}
                                            value={state.specialties} 
                                            fullWidth
                                            disableClearable
                                            onChange={(e,v) => handleAutoComplete(e,v)} 
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip 
                                                        key={index}
                                                        {...getTagProps({ index })} 
                                                        size="small"
                                                        label={option} 
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className={classes.autocomplete}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        disableUnderline: true
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        }
                        

                        <Grid container spacing={3} className={classes.row}>
                            <Grid item xs={12} sm={12}>
                                <Typography>Biography</Typography>
                                <BootstrapInput 
                                    fullWidth 
                                    multiline 
                                    rows={5} 
                                    name="bio" 
                                    value={state.bio} 
                                    id="bio-input"
                                    placeholder="Introduce yourself, talk about your experience, education, training, research..."
                                    className={classes.textArea}
                                    onChange={(e) => handleChange(e)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleClose} color="primary" className={classes.actionBtn}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" className={classes.actionBtn}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
