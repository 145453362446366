//material UI components and icons
import { Box, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
//global
import GlobalContext from "../../../../context/GlobalContext";
//styling
import AccountProfileStyle from '../../../../styles/AccountProfileStyles';


const Bio = (props) => {
    const classes = AccountProfileStyle()
    const global = useContext(GlobalContext)
    const user = global.state.user && global.state.user.document ? global.state.user.document : {}
    const [value, setValue] = useState(user.bio)
    const [count, setCount]= useState(value.length)
    const [backSpaceMaxLimit, setBackSpaceMaxLimit] = useState(false)
    const charLimit=500

    
    //TODO currently using cancel trigger which is hacky try to change later
    useEffect(() => { 
        setValue(props.userState.bio)
        setCount(props.userState.bio.length)
    },[props.cancel])


    const handleChange = (e) => {
        if(count<charLimit || backSpaceMaxLimit){
            setValue(e.target.value)
            setCount(e.target.value.length)
            props.setUserState({...props.userState, bio:e.target.value})  
            setBackSpaceMaxLimit(false)
        }
    }

    const handleDeleteAtMaxChar = (e) => {
        if(e.key==='Backspace' && count===charLimit){
            setBackSpaceMaxLimit(true)
        }
    }

    useEffect(() =>{
        if(value.length>charLimit){
            setValue(value.slice(0,charLimit))
            setCount(charLimit)
        }
    }, [value])

 return(
    <>
    <Box >
        <Box className={classes.spreadHeader}>
            <Typography className={classes.labelHeading}>Biography</Typography>
        </Box>
        <Box className={classes.textFieldBody}>
            <TextField
                id="standard-multiline-flexible"
                label=""
                className={classes.textField}
                multiline
                fullWidth
                variant="outlined"
                rows={10}
                value={value}
                height="50rem"
                onChange={handleChange}
                onKeyDown={handleDeleteAtMaxChar}
            />
        </Box>
        <Box className={classes.countLabelBox}>
            <Typography className={classes.subText}>{count}/{charLimit}</Typography>
        </Box>
    </Box>
    </>
)
}

export default Bio