/*
 * @Author: Tyler Bowman
 * @Date: 2021-01-22 10:49:56
 * @LastEditors: Tyler Bowman
 * @LastEditTime: 2021-01-22 10:49:56
 * @Description: Style Reformating
 */
import { makeStyles } from "@material-ui/core/styles";

//Warning: If any of these settings are overwritten by a style of the same name in one file,
//ie. if reminderapp has a head with different attributes in their makestyles function
//it may apply that overwrite globally, changing the styles for all other files using this file
export default makeStyles((theme) => ({
  head: {
    display: "flex",
  },
  title: {
    paddingTop: "0rem",
    fontWeight: 500,
    display: "block",
    marginRight: "2rem",
    fontSize: "3rem",
  },
  signupButton: {
    color: "white",
    borderRadius: "5px",
    marginBottom: "10px",
    backgroundColor: "#EA8331",
    border: "1px solid #EA8331", 
    "&:hover": {
      color: "black",
    },
  },
  content: {
    marginTop: "16px",
    overflow: "hidden",
  },
  typography: {
    fontWeight: 500,
    padding: theme.spacing(1),
  },
  formLabel: {
    color: "#222222",
  },
  row: {
    padding: "0.5rem 0",
  },
  dropdown: {
    marginTop: "0.25rem",
  },
  option: {
    minHeight: "2rem",
  },
  textArea: {
    padding: "0",
  },
  fileUpload: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "#FFFFFF",
  },
  autocomplete: {
    borderRadius: 4,
    border: "1px solid #767676",
    fontSize: 16,
    padding: "5px",
  },
  checkBoxMargin: {
    marginLeft: "5px",
  },
  contentText: {
    width: "360px",
    color: "#222222",
    fontSize: "1rem",
    fontWeight: 500,
  },
  popOver: {
    marginTop: "0.5rem",
    width: "100%",
  },
  popOverItemLayout: {
    width: "100%",
    display: "flex",
    justifyContent: "left",
    minWidth: "200px",
    "&:hover": {
      backgroundColor: "#CCCCCC",
    },
  },
  root: {
    flexGrow: 1,
  },
  media: {
    background:
      "linear-gradient(90deg, rgba(56,128,210,1) 13%, rgba(23,102,179,1) 37%, rgba(29,62,138,1) 97%)",
    boxShadow: "1px 2px 10px #888888",
    color: "white",
    borderRadius: "10px",
    padding: "0 25px",
    paddingBottom: "15px",
    height: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  textBox: {
    width: "100%",
    marginTop: "2px",
    marginBottom: "2px",
    borderColor: "#222222",
    color: "#222222",
  },
  notification: {
    color: "#003FBA",
  },
  notifButton: {
    background: "white",
    border: "2px solid #003FBA",
    color: "#003FBA",
    borderRadius: "10px",
    "&:focus": {
      background: "#D3E2FF",
    },
  },
  notifButtonDisabled: {
    background: "white",
    border: "2px solid #003FBA",
    color: "grey",
    borderRadius: "10px",
    padding: "5px 30px 5px 30px",
    margin: "5px",
    "&:focus": {
      background: "#003FBA",
      color: "white",
    },
  },
  notifSendButton: {
    background: "white",
    border: "2px solid #003FBA",
    color: "#003FBA",
    borderRadius: "10px",
    padding: "10px 30px 10px 30px",
    margin: "5px",
  },
  finish: {
    background: "white",
    boxShadow: "1px 2px 5px #888888",
    color: "black",
    borderRadius: "10px",
    padding: "10px 30px 10px 30px",
  },
  input: {
    border: "2px solid #343434",
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "white",
    padding: "0 5px",
  },
  icon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  continue: {
    background:
      "linear-gradient(90deg, rgba(56,128,210,1) 13%, rgba(23,102,179,1) 37%, rgba(29,62,138,1) 97%)",
    boxShadow: "1px 2px 5px #888888",
    color: "white",
    borderRadius: "10px",
    padding: "15px 30px 15px 30px",
  },
  cancel: {
    background: "white",
    boxShadow: "1px 2px 5px #888888",
    color: "black",
    borderRadius: "10px",
    padding: "15px 30px 15px 30px",
  },
  text: {
    fontWeight: "550",
    color: "#003FBA",
    fontSize: "1rem",
    "@media (max-width:400px)": {
      fontSize: "0.7rem",
    },
  },
  disabledText: {
    fontWeight: "550",
    color: "grey",
    fontSize: "1rem",
    "@media (max-width:400px)": {
      fontSize: "0.7rem",
    },
  },

  hideInput: {
    display: "none",
  },
  iconButtonContainer: {
    display: "flex",
  },
  fileManagement: {
    background: "#EAEAEA",
    display: "inline-block",
  },
  fileButtonText: {
    font: "16px",
    marginTop: "12px",
    marginLeft: "16px",
    marginRight: "48px",
  },
  labelText: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "#343434",
    marginTop: "8px",
    marginLeft: "4px",
  },
  typeSpacing: {
    padding: "0 5px",
  },
  colorButton: {
    backgroundColor: "#DBDBDBD",
    "&:hover": {
      backgroundColor: "#CCCCCC",
      boxShadow: "none",
    },
    borderRadius: "1.5rem",
    fontSize: "0.875rem",
    marginTop: "0.3rem",
    padding: "0rem 1rem",
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 450,
    marginRight: "2rem",
  },

  title: {
    backgroundColor: "#EA8331",
    color: "black",
    borderRadius: "5px",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "#EA8331",
      boxShadow: "none",
      color: "black",
      borderRadius: "5px",
      marginBottom: "10px",
    },
  },
  signup: {
    color: "white",
    borderRadius: "5px",
    marginBottom: "10px",
    backgroundColor: "#EA8331",
    "&:hover": {
      boxShadow: "none",
      color: "white",
    },
  },
  cancelButton: {
    border: "1px grey solid",
    background: "white",
    color: "black",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  loadingCircle: {
    margin: "10px",
    color: "#1766b3",
  },
  fileBox: {
    display: "flex",
    width: "100%",
    marginTop: "15px",
  },
  iconSpacing: {
    padding: "0px",
    marginLeft: "10px",
  },
  signupDisabled: {
    background: "#f0f0f0", 
    color: "#a0a0a0",      
    border: "1px solid #d0d0d0", 
    borderRadius: "5px",   
    borderRadius: "5px",
    marginBottom: "10px",
    opacity: 0.6,
  },
  marginSpacing: {
    marginBottom: "17px",
  },
  rowTextSpacing: {
    width: "30%",
    marginBottom: "10px",
  },
  rowTextSpacing50: {
    width: "49%",
    marginBottom: "10px",
  },
  getReportTextSpacing: {
    width: "80%",
    marginBottom: "10px",
    marginLeft: "2rem",
    marginRight: "70px",
    marginTop: "1rem",
    "@media (max-width: 768px)": {
      marginLeft: "1rem",
    },
  },
  rowTextMargin: {
    marginRight: "2%",
  },
  autoCompleteBox: {
    width: "100%",
    marginBottom: "15px",
    minWidth: "500px",
  },
  extraPadding: {
    padding: "2px",
  },
  notificationPadding: {
    paddingRight: "12px",
  },
  iconButtonSpacing: {
    paddingRight: "16px",
  },
  notificationTextboxSpacing: {
    paddingLeft: "14px",
  },
  notificationRowSpacing: {
    marginTop: "5px",
  },
  appointmentModeSampleColor: {
    textAlign: "left",
    height: "1rem",
    width: "1rem",
    marginRight: "1rem",
    borderRadius: "10%",
  },
  labelSmall: {
    fontSize: "1rem",
    color: "#343434",
    fontWeight: 500,
    width: "100%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    lineHeight: "19px",
    letterApacing: "0.15px",
    marginTop: "2px",
    marginBottom: "5px",
  },
  upperSpacing: {
    marginTop: "15px",
  },
  hamburgerIconSpacing: {
    padding: "0px",
    display: "block",
  },
  sideBarDisplay: {
    display: "block",
  },
  hamburger: {
    width: "30px",
    height: "30px",
  },
  selectBox: {
    minWidth: "100%",
  },
  styledHeader: {
    background: "white",
    "& h2": {
      color: "#444444",
      fontSize: "2rem",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    dialogClose: {
      color: "#444444",
      fontSize: "2rem",
      padding: "0px",
      float: "right",
    },
  },
}));
