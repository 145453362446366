import { Box, Button, CircularProgress, Collapse, Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from "../../../../context/GlobalContext";
import settingsStyles from '../../../../styles/settingsPageStyles';
import About from './components/About';
import CancellationPolicy from './components/CancellationPolicy';
import OperationHours from './components/OperationHours';


const OperationSetting = () => {
    const classes = settingsStyles()
    const global = useContext(GlobalContext)
    const [expanded, setExpanded] = useState(false)
    let user = global.state.user && global.state.user.document ? global.state.user.document : {}
    const orgId= global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id
    let defaultUser = {
        isEdit: false,
        isLoading: false,
        user: user,
        password: '',
    }
    let org = global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg : global.state.defaultOrg

    const [operationHours, setOperationHours] = useState(
        {Sunday:{open:'', close:''}, Monday:{open:'', close:''}, Tuesday:{open:'', close:''}, Wednesday:{open:'', close:''}, Thursday:{open:'', close:''}, Friday:{open:'', close:''}, Saturday:{open:'', close:''}}
    )
    const [charsAbout, setCharsAbout]=useState('')
    const [charsCancellation, setCharsCancellation]=useState('')

    const [state, setState] = useState(defaultUser)

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    useEffect(()=> {
        const getOperationHours = async () => {
            let result= await global.api.getOrgById(orgId)
            if(result?.data?.OperationHours) {
                setOperationHours(result.data.OperationHours)
            }
            if(result?.data?.cancellationPolicy){
                setCharsCancellation(result.data.cancellationPolicy)
            }
            if(result?.data?.about){
                setCharsAbout(result.data.about)
            }
            
        }
        getOperationHours()
    },[])

    const cancelChanges = async () => {
        let result= await global.api.getOrgById(orgId)
        result && setOperationHours(result.OperationHours)
        if(result.cancellationPolicy){
            setCharsCancellation(result.cancellationPolicy)
        }
        if(result.about){
            setCharsAbout(result.about)
        }
        setState({ ...state, isLoading: false, isEdit:false })
    }

    const saveChanges = async () => {
        setState({ ...state, isLoading: true })
        try{
            await global.api.updateOperationHours({orgId:orgId, operationHours:operationHours})
        }catch(e){
            // console.log(e)
        }
        try{
            await global.api.UpdateTextInDb(orgId, charsCancellation, "cancellationPolicy")
        }catch(e){
            // console.log(e)
        }
        try{
            await global.api.UpdateTextInDb(orgId, charsAbout, "about")
        }catch(e){
            // console.log(e)
        }
        setState({ ...state, isLoading: false, isEdit:false })
        
    }
    const rootClassNames = clsx({
        [classes.rootExpanded]: expanded,
        [classes.root]: !expanded,
        [classes.disabled]: org.disabled
    })
    
    return (
        <Grid container component={Paper} elevation={3} className={rootClassNames}>
            <Grid item xs={12} className={classes.paperComponment}>
                <Box onClick={handleExpandClick} className={classes.labelWithEdit, classes.labelWithEditDropdown}>
                    <Typography className={clsx(classes.label, {
	                        	[classes.expandedLabel]: expanded
	                        })}>Operation Settings</Typography>
                    <Typography className={classes.edit}>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            disabled={org.disabled}

                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <Grid container>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item md={6}xs={12}>
                            <Grid container className={!state.isEdit ? classes.disabledBackground : ''}>
                                <Grid item xs={12} className={classes.dividerBorder}>
                                    <About charsAbout={charsAbout} setCharsAbout={setCharsAbout} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} className={classes.operationsDividerBorder}>
                                    <CancellationPolicy  charsCancellation={charsCancellation} setCharsCancellation={setCharsCancellation} />
                                   </Grid>
                            </Grid>
                        </Grid> 
                       
                            
                        
                        <Grid item xs={12}md={6}><Divider/>
                            <Grid container className={!state.isEdit ? classes.disabledBackground : ''}>
                                <Grid item xs={12} className={classes.dividerBorder}>
                                    <OperationHours operationHours={operationHours} setOperationHours={setOperationHours} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.divider}/>
                        </Grid>
                        <Grid item xs={12}>
                            {state.isEdit ?
                                <Box>
                                    {state.isLoading ?
                                        <Box display='flex' justifyContent='flex-end'>
                                            <CircularProgress className={classes.loadingCircle} />
                                        </Box>
                                        :
                                        <Box display='flex' justifyContent='flex-end' className={classes.saveBtnGroup}>
                                            <Button variant="outlined" color='primary' onClick={saveChanges}>
                                                Save Changes
                                            </Button>
                                            <Button variant="outlined" color='primary' className={classes.saveChangesBtn} onClick={cancelChanges}>
                                                Cancel
                                            </Button>
                                        </Box>
                                    }
                                </Box>
                                :
                                <Box display='flex' justifyContent='flex-end' className={classes.saveBtnGroup}>
                                    <Button xs={12} variant="outlined" color='primary' onClick={() => setState({ ...state, isEdit: true })}>
                                        Edit
                                    </Button>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    )
}

export default OperationSetting