/*
 * @Author: Vincent Tran
 * @Date: 2020-07-16 01:06:00
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-10-01 06:55:40
 * @Description: file content
 */
import { Collapse, Grid, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import json2mq from 'json2mq';
import React, { useEffect, useState } from 'react';
import GlobalContext from '../../context/GlobalContext';
import OrgSelector from "../../frame/OrgSeletor";
import combineStyles from '../../styles/combineStyles';
import popupStyles from '../../styles/PopupStyle.js';
import settingsStyles from '../../styles/settingsPageStyles';
import ToolStyle from '../../styles/ToolStyle.js';
import ControlPullData from './/component/ControlPullData';
import CleanupPopup from './component/CleanupDB';
import DummyUsersPopup from './component/DummyUsers';
import EncryptUsersPopup from './component/EncryptUsers';
import FaxComponent from './component/Fax';
import OrganizationReports from './component/OrganizationReports';



const useStyles = makeStyles((theme) => ({
    
    paperTitle: {
        padding: "1.5rem",
        borderBottom: "1px solid #CCCCCC"
    },
    paperComponment: {
        padding: "1rem 1rem"
    },
    toolBox: {
        padding: "1.5rem"
    },
    toolsContainer: {
        display: "flex",
        flexWrap: "nowrap",
        borderTop:"1px solid #CCCCCC",
    },
    leftContainer: {
        borderRight: "1px solid #CCCCCC"
    },
    dummyUsers: {
        borderBottom: "1px solid #CCCCCC"
    },
    cleanupDB: {
        borderBottom: "1px solid #CCCCCC"
    },
    labelWithBtn: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end"
    },
    head: {
        display: 'flex',
    },
    title: {
        paddingTop: "0.9rem",
        fontWeight: 500,
        display: "block",
        marginRight: "2rem",
        fontSize: "2rem",
        '@media (max-width:960px)': { 
            display: 'none',
        },
    },
    titleAdmin: {
        paddingTop: "0.9rem",
        fontWeight: 500,
        display: "block",
        marginRight: "2rem",
        fontSize: "2rem",
    },
    appBox: {
        margin: "2rem 0"
    },
    // The following style classes are used in DummyUsers.js, EncryptUsers.js, and ModifyDB.js
    // These files are all modal pop-ups that share the same styling
    toolBtn: {
        fontSize: "0.8rem",
        color: "#003FBA",
        fontWeight: 600,
        marginRight: "0.5rem",
        cursor: "pointer"
    },
    listBox: {
        marginTop: "1rem"
    },
    listSubheader: {
        color: "black",
        textAlign: "left",
        border: "1px solid #CCCCCC",
        borderBottom: "none",
        height: "2.5rem",
        lineHeight: "normal",
        display: "flex",
        alignItems: "center"
    },
    listItems: {
        padding: "0",
        border: "1px solid #CCCCCC",
        maxHeight: "10rem",
        overflow: "auto",
    },
    userEmailItem: {
        border: "1px solid #CCCCCC",
        borderLeft: "none",
        borderTop: "none",
        paddingTop: "0.3rem",
        paddingBottom: "0.3rem"
    },
    userEmail: {
        fontSize: "0.8rem",
    },
    list:{
        fontSize:"13px"
    },
    label: {
        // paddingTop: "0.5rem",
        fontSize: "1.2rem",
        color: "black",
        fontWeight: 500,
        width: "100%",
        margin:"auto 0",
    },
    labelWithEdit: {
        display: "flex",
        justifyContent: "space-between",
    },
    edit: {
        fontSize: "0.8rem",
        color: "#003FBA",
        fontWeight: 600,
        marginRight: "0.5rem",
        cursor: "pointer"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandedLabel: {
        color: "#003FBA",
    },
}));

// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(settingsStyles, ToolStyle, popupStyles, useStyles);

function App(props) {
    const classes = combinedStyles();

    const global = React.useContext(GlobalContext);

    let orgId = global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id

    const [expanded, setExpanded] = React.useState(false);

	const smallScreenView = useMediaQuery(json2mq({
	    maxWidth: 960
	}));
	const [pullAppAutomation, setPullAppAutomation] = useState(false)

	const [isEmrIntegrationComplete, setIsEmrIntegrationComplete] = useState(false);
    
    let settingsPermission = global.state.user && global.state.user.document.roles[0] === 'admin';

    useEffect(()=> {
        getPullAppAutomation();
        checkIfEmrIntegrationIsComplete();

        if (settingsPermission) {
            props.setTitle("Select Organization");
        } else {
            props.setTitle("Database Tools");
        }
    }, [])

    const checkIfEmrIntegrationIsComplete = async () => {
        try{
          let res = await global.api.getIntegrationComplete(orgId);
              if(res.status === 200){
                    // console.log("get emr integration complete status", res.data.integrationComplete)
                    setIsEmrIntegrationComplete(res.data.integrationComplete);  
                    if(res.data.integrationComplete === false){   
                    savePullAppAutomation(false)}
                } } catch(error) {
                  // console.log("FAILED, emr integration complete status failed", error)
                }
          }
    

    const getPullAppAutomation = async () => {
        try {
            let res = await global.api.getPullAppAutomationSetting(orgId);
            if (res.status === 200) {
                setPullAppAutomation(res.data.savePullAppAutomation);
            }
        } catch (error) {
            // console.log("Get automation failed, error", error)
        }
    }

    const savePullAppAutomation = async (newValue) => {
        try {
            let res = await global.api.savePullAppAutomationSetting(orgId, {
                savePullAppAutomation: newValue
            });
            if (res.status === 200) {
                await getPullAppAutomation();
            }
            if (!newValue) {
                await global.api.setAutoPullAppToggle(orgId, {
                    doctorToggle: false,
                    PatientToggle: false,
                    AppointmentToggle: false,
                    QueueToggle:false,
                });
            }
        } catch (error) {
            // console.log("save automation failed, error")
        }
    }

	const handleExpandClick = () => {
	    setExpanded(!expanded);
	};

    return (
        <>
            <Container className={classes.root}>
            {settingsPermission && <OrgSelector />}
                    <div style={{marginBottom:'2.5%', }}>
                        <Box className={classes.head}>
                            <Typography className={settingsPermission ? classes.titleAdmin : classes.title} variant="h4" >Database Tools</Typography>
                        </Box>
                        <Paper elevation={3} className={classes.container} style={{ border:expanded?"2px solid #003FBA":"2px solid rgba(255,255,255,0)",borderRadius:"10px", width:'100%', marginTop: "25px"}}>
                            <Grid container >
                                <Grid item xs={12} className={classes.paperComponment}>
                                    <Box onClick={handleExpandClick} className={clsx(classes.labelWithEdit, classes.labelWithEditDropdown)}>
                                        <Typography 
                                            className={clsx(classes.label, {
                                                [classes.expandedLabel]: expanded
                                            })}>
                                            Database Tools
                                            </Typography>
                                        <Typography className={classes.edit}>
                                            <IconButton
                                                className={clsx(classes.expand, {
                                                    [classes.expandOpen]: expanded,
                                                })}
                                                onClick={handleExpandClick}
                                                aria-expanded={expanded}
                                                aria-label="show more"
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </Typography>
                                    </Box>
                                </Grid>
                                {smallScreenView &&
                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                    <Grid container className={classes.toolsContainer}>
                                        <Grid container>
                                            <Grid item className={`${classes.toolBox} ${classes.dummyUsers}`}>
                                                <Box className={classes.labelWithBtn}>
                                                    <h3>Create Dummy Users</h3>
                                                    <DummyUsersPopup classes={classes} />
                                                </Box>
                                                <p>Creating dummy users action will check if the default test users are in the database. 
                                                It will display all default test user accounts and missing test user accounts.</p>
                                            </Grid>
                                            <Grid item className={`${classes.toolBox} ${classes.cleanupDB}`}>
                                                <Box className={classes.labelWithBtn}>
                                                    <h3>Clean-up and Modify Database</h3>
                                                    <CleanupPopup classes={classes} />
                                                </Box>
                                                <p>Cleaning up and modifying the database will provide an overall clean-up and maintenance service for the user's database.</p>
                                            </Grid>
                                            <Grid item className={`${classes.toolBox} ${classes.dummyUsers}`}>
                                                <Box className={classes.labelWithBtn}>
                                                    <h3>Encrypt All Users</h3>
                                                    <EncryptUsersPopup classes={classes} />
                                                </Box>
                                                <p>Encrypting all users will show the number of users who have unencrypted files and 
                                                provide the option to encrypt the displayed files.</p>

                                                {/* Please set up integration to control Pull App Settings.  */}
                                            </Grid>
                                           
                                        </Grid>
                                    </Grid>
                                </Collapse>
                                }
                                {!smallScreenView &&
                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                    <Grid container className={classes.toolsContainer}>
                                        <Grid container className={classes.leftContainer}>
                                            <Grid item className={`${classes.toolBox} ${classes.dummyUsers}`}>
                                                <Box className={classes.labelWithBtn}>
                                                    <h3>Create Dummy Users</h3>
                                                    <DummyUsersPopup classes={classes} />
                                                </Box>
                                                <p>Creating dummy users action will check if the default test users are in the database. 
                                                It will display all default test user accounts and missing test user accounts</p>
                                            </Grid>
                                            <Grid item className={classes.toolBox}>
                                                <Box className={classes.labelWithBtn}>
                                                    <h3>Clean-up and Modify Database</h3>
                                                    <CleanupPopup classes={classes} />
                                                </Box>
                                                <p>Cleaning up and modifying the database will provide an overall clean-up and maintenance service for the user's database.</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item className={`${classes.toolBox} ${classes.dummyUsers}`}>
                                                <Box className={classes.labelWithBtn}>
                                                    <h3>Encrypt All Users</h3>
                                                    <EncryptUsersPopup classes={classes} />
                                                </Box>
                                                <p>Encrypting all users will show the number of users who have unencrypted files and 
                                                provide the option to encrypt the displayed files.</p>
                                            </Grid>
                                </Grid>

                                </Grid>
                                </Collapse>
                                }
                            </Grid>
                        </Paper>
                    </div>
                 <Box className={classes.appBox}>
                 <ControlPullData/>
                </Box>
                    <Box className={classes.appBox}>
                    <FaxComponent/>
                    </Box>

                {/*    <Box className={classes.appBox}>*/}
                {/*<OrganizationReports/>*/}
                {/*</Box>*/}
                
            </Container>
        </>

    );
}

export default App;
