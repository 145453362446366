/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-11 00:07:21
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-08 03:43:46
 * @Description: file content
 */
import React from 'react';
import Box from '@material-ui/core/Box'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Typography } from '@material-ui/core';
const CopyButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        borderRadius: "0px 4px 4px 0px",
        lineHeight: 1.5,
        backgroundColor: "#eeeeee",
        borderColor: '#eeeeee',
        color: "#003FBA",
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#efefef',
            borderColor: '#efefefef',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#cccccc',
            borderColor: '#cccccc',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
})(Button);
const CustomTextInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(0.5),
        },
        width: "100%",
        '&$disabled': {
            color: "black"
        },
        borderRadius: "4px 0 0 4px",
        padding: 0
    },
    input: {
        width: "100%",
        borderRadius: "4px 0 0 4px",
        position: 'relative',
        backgroundColor: "#eeeeee",
        border: 'none',
        fontSize: "0.8rem",
        color: "black",
        fontWeight: 500,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),

        // '&:focus': {
        //     boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        //     borderColor: theme.palette.primary.main,
        // },
        '&$disabled': {
            color: "black"
        },
    },

}))(InputBase)
const useStyles = makeStyles((theme) => ({
    head: {
        display: 'flex',
    },
    title: {
        paddingTop: "0rem",
        fontWeight: 500,
        display: "block",

        fontSize: "0.875rem"
    },
    textButtonContainer: {
        display: "flex"
    },
    helperText: {
        textAlign: "right",
        marginRight: "0.5rem"
    },


}));

function CodeTextField(props) {
    const classes = useStyles()
    const [displayValue, setDisplayValue] = React.useState("")
    React.useState(() => {
        if (typeof props.value == "string") {
            let display = props.value.substring(0, 20) + "..." + props.value.substring(props.value.length - 13, props.value.length)
            setDisplayValue(display)
        }

    }, [props.value])
    return (
        <Box>

            <Typography className={classes.title}>{props.title}</Typography>
            <Box className={classes.textButtonContainer}>
                <CustomTextInput
                    // onClick={onTextChange}
                    // onChange={onTextChange}
                    // error={wordCount > 120}
                    fullWidth
                    multiline
                    value={displayValue}
                    rows={1}
                    aria-describedby={props.id}

                />
                <CopyButton
                onClick={() => {navigator.clipboard.writeText(props.value)}}
                 startIcon={<LinkIcon />}
                 >Copy</CopyButton>


            </Box>
            {/* {wordCount == 0 ? <div></div> : <FormHelperText className={classes.helperText} id="component-error-text">{wordCount}/120</FormHelperText>} */}
        </Box>
    );
}

export default CodeTextField;