import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import GlobalContext from "../../context/GlobalContext"
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Pagination from "@material-ui/lab/Pagination";
import React from 'react';




export default function FaxHistoryModal({open, close, state, setState,  classes}) {
   // const global = useContext(GlobalContext
   


    const styles = { 

        root: { },
        paper: { borderRadius: 15 } 
    
    } 

    const StyledTableCell = withStyles((theme) => ({
        head: {
            paddingTop: "2rem",
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            fontSize: "1rem",
            fontWeight: 600,
            borderBottom: "1px solid #BDBDBD",
        },
        body: {
            fontSize: "1rem",
            borderBottom: "none",
            fontWeight: 480
        },
    
    
    }))(TableCell);


    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.action.hover,
            },
    
        },
    }))(TableRow);


    return (
            <div style={{borderRadius: "18px"}}>
            <Dialog 
            classes={{ 
                root: classes.root, 
                paper: classes.paper }}
            fullWidth
            maxWidth="md"
                open={open}>
            
                <DialogTitle>
                <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.extraPadding} style={{marginBottom: "-16px"}} >
                    <Typography style={{fontFamily: "Roboto", fontWeight: 500, fontSize: "24px", lineHeight: "28.14px", 
                     }}>Fax History</Typography>
                    <IconButton>
                        <CloseIcon onClick={close} style={{color: "black"}}/>
                    </IconButton>
                </Box>
            </DialogTitle>
                
                <DialogContent  style={{height: "361px"}}>
               
                <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table" style={{border: "solid 1px #bdbdbd",
    borderRadius: "8px"}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell >Time</StyledTableCell>
                                        <StyledTableCell>Sender</StyledTableCell>
                                        <StyledTableCell >Recipient</StyledTableCell>
                                        <StyledTableCell >Status</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow >
                                            
                                            
                                          
                                            <StyledTableCell align="right" >
                                                
                                                  
                                                
                                            </StyledTableCell>
                                            
                                            <StyledTableCell align="right">
                                              
                                            </StyledTableCell>
                                            

                                        </StyledTableRow>
                                        <StyledTableRow >
                                            
                                            
                                          
                                            <StyledTableCell  fullWidth>
                                                
                                                    
                                                
                                            </StyledTableCell>
                                            
                                          
                                            <StyledTableCell  fullWidth>
                                                
                                                    
                                                
                                                </StyledTableCell>


                                                <StyledTableCell  fullWidth>
                                                
                                                    
                                                
                                                </StyledTableCell>

                                                <StyledTableCell  fullWidth>
                                                
                                                    
                                                
                                            </StyledTableCell>


                                            <StyledTableCell  fullWidth>
                                                
                                                    
                                                
                                            </StyledTableCell>

                                        </StyledTableRow>
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                   
                    <Grid container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        item xs={12}>
                        <Grid item xs={6}>
                          <Pagination count={2}  />  
                        </Grid>
                    </Grid>
                    
                </DialogContent>
            

            
            </Dialog>
        </div>
    )
}