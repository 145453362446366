import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Grid,
    List,
    ListItem,
    Collapse,
    ListItemText,
} from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import settingsStyles from '../../../../../styles/settingsPageStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    dialogActions: {
      display: "flex",
      justifyContent: "space-between",
    },
  }));

const ChangeRoleFlow = (props) => {
    const classes = settingsStyles();
    const [role, setRole] = useState(props.mode === "Edit" ? props.row.role : "");
    const [description, setDescription] = useState(props.mode === "Edit" ? props.row.description : "");
    let title = props.mode + " Role";
    let oldRow = props.row;

    const handleRoleChange = (event) => {
      setRole(event.target.value);
    }

    const handleDescriptionChange = (event) => {
      setDescription(event.target.value);
    }

    const handleSave = () => {
      let copiedRows = [...props.rows];
      if (props.mode === "Add New") {
        if (role.length > 0 || description.length > 0) {
          copiedRows.push(props.createData(role, description, false, false, false));
          props.setRows(copiedRows);
        }
        setRole("");
        setDescription("");
        props.onClose();
        return;
      }

      if (props.index >= 0 && props.index < copiedRows.length) {
        if (oldRow.role !== role || oldRow.description !== description) {
          copiedRows[props.index].isEdited = true;
        }
        copiedRows[props.index].role = role;
        copiedRows[props.index].description = description;
        props.setRows(copiedRows);
      } 
      props.onClose();
    }

    const handleCancel = () => {
      if (props.mode === "Add New") {
        setRole("");
        setDescription("");
        props.onClose();
        return;
      }

      setRole(props.row.role);
      setDescription(props.row.description);
      props.onClose();
    }

    return (
        <React.Fragment>
        <Dialog 
            open={props.open} 
            onClose={props.onClose}
            maxWidth="md"
            fullWidth 
        >
            <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
            <DialogContent>
            <Typography variant="body2" className={classes.labelText}>Role Name</Typography>
            	<TextField
            	    InputProps={{ disableUnderline: true }}
            	    onChange={handleRoleChange}
            	    variant="outlined"
            	    value={role}
            	    fullWidth
            	    className={classes.textBox}
            	    placeholder="Role Name"
            	    name="roleName"
            	    size="medium"
            />

              <Typography variant="body2" className={classes.labelText}>Description</Typography>
            	<TextField
            	    InputProps={{ disableUnderline: true }}
            	    onChange={handleDescriptionChange}
            	    variant="outlined"
            	    value={description}
            	    fullWidth
            	    className={classes.textBox}
            	    placeholder="Description"
            	    name="roleDescription"
            	    size="medium"
              />

            <Grid container style={{'height' : '500px', 'marginTop' : '10px'}}>
              <Grid item xs={5}>
              <Typography variant="body2" className={classes.labelText}>Choose features from:</Typography>
              <List>
                <ListItem
                  style={{ border: '1px #c7c7c7 solid', borderRadius: '5px' }}
                  button
                  aria-expanded={false}
                  onClick={null}
                  disabled
                  >
                  <ListItemText />
                  <ArrowDropDownIcon />
                </ListItem>
                <Collapse
                  
                >
                <List style={{ maxHeight: 200, overflow: 'auto' }}>
                  
                </List>
                </Collapse>
              </List>
              <TextField
            	    InputProps={{ disableUnderline: true }}
            	    onChange={null}
            	    variant="outlined"
            	    value={null}
            	    fullWidth
            	    className={classes.textBox}
            	    placeholder=""
            	    name=""
                  multiline
                  maxRows={20}
                  minRows={20}
            	    size="medium"
                  disabled
              />
              </Grid>

              <Grid container xs={2} alignItems='center' alignContent='center' justifyContent="center">
                <Button variant="contained" disabled style={{'marginBottom' : '1rem'}}>ADD &gt;</Button>
                <Button variant="contained" disabled>&lt; Remove</Button>
              </Grid>

              <Grid item xs={5} style={{'height' : '100%'}}>
                <Typography variant="body2" className={classes.labelText}>Selected features</Typography>
            	  <TextField
            	    InputProps={{ disableUnderline: true }}
            	    onChange={null}
            	    variant="outlined"
            	    value={null}
            	    fullWidth
            	    className={classes.textBox}
            	    placeholder=""
            	    name="selected features"
            	    size="medium"
                  multiline
                  minRows={23}
                  maxRows={23}
                  style={{'height' : '480px'}}
                  disabled
                />
              </Grid>
            </Grid>

            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                  <Button onClick={handleCancel} color="primary">
                    CANCEL
                  </Button>
                  <Button onClick={handleSave} color="primary">
                    SAVE
                  </Button>
            </DialogActions>
        </Dialog>
        </React.Fragment>
    );
};

export default ChangeRoleFlow;
