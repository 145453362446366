/*
 * @Author: Zhilong Gan
 * @Date: 2021-07-29 01:10:00
 * @LastEditors: Zhilong Gan
 * @LastEditTime: 2021-07-29 01:24:00
 * @Description: Styling for tool page
 */

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    expandedLabel: {
        color: "#003FBA",
    },
    orgSection: { 
        padding: "1.125rem 2.063rem",
        borderTop: "1px solid #BDBDBD",
        borderBottom: "1px solid #BDBDBD",
        width: "100%",
    },
    orgName: {
        fontSize: "20px",
        fontWeight: "500",
        marginBottom: "10px",
    },
    orgSectionTop: {
        height: "40px"
    },
    statusSection: {
        width: "100%",
        padding: "20px 30px",
    },
    statusSectionBox: {
        width: "100%",
        margin: "15px auto",
        borderRadius: "10px",
        border: "1px solid #CCCCCC",
        boxShadow: "5px 5px 10px 5px #E2E2E2",
    },
    statusSectionBoxSummary: {
        height: "68px",
        padding: "4px 33px",
        borderRadius: "10px 10px 0 0",
        [theme.breakpoints.down(635)]: {
            padding: "4px 10px",
        }
    },
    statusSectionBoxTitleText: {
        fontSize: "16px",
    },
    statusSectionBoxDetailText: {
        fontSize: "14px",
    },
    statusSectionDetail: {
        padding: 0,
        borderTop: "1px solid #CCCCCC",
    },
    statusDataItem: {
        margin: "0",
        border: "1px solid #EAEAEA",
    },
    statusDataItem2: {
        margin: "0",
    },
    statusImg: {
        width: "25px",
        height: "25px",
        margin: "0px 1em",
    },
    patientStatusErrorLeft: {
        borderRight: "1px solid #BDBDBD",
        padding: "1em"
    },
    patientStatusErrorRight: {
        
    },
    patientProflieContainer:{
        paddingLeft: "5em",
        fontSize: "14px"
    },
    patientProflie: {
        padding: "10px 0",
    },
    patientErrorMessageBox: {
        padding: "10px",
    },
    patientErrorMessage:{
        fontSize: "15px",
        lineHeight: "16px"
    },
    pullStatusID: {
        marginTop:"2.5%",
    },
    pullStatusID2: {
        marginTop:"7%",
    }
}));