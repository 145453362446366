/*
 * @Author: Andy Qiu
 * @Date: 2020-10-29 11:50:12
 * @LastEditors: Andy Qiu
 * @LastEditTime: 2020-10-29 11:50:12
 * @Description: patient queue page
 */

import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from '@material-ui/icons/Search';
import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from "../../context/GlobalContext";
import OrgSelector from "../../frame/OrgSeletor";
import AppointmentTable from './component/AppointmentTable';

const useStyles = makeStyles((theme) => ({
    head: {
        display: 'flex',
    },
    title: {
        fontWeight: 500,
        display: "block",

        marginRight: "10px",
        fontSize: "2rem",
        paddingTop: "0.9rem",
        '@media (max-width:960px)': { 
            display: 'none',
        },
    },
    
    search: {
        position: 'relative',
        borderRadius: '8px',
        backgroundColor: 'white',
        width: '100%',
        marginTop: theme.spacing(2),
        boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: '20ch',
        },
    },
    button: {
        position: 'relative',
        background: 'FFFFFF',
        boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',
        borderRadius: '8px',
        color: '#767676',
        width: '85%',
        marginTop: theme.spacing(2),
        '&:hover': {
            backgroundColor: 'white',
            color: '#767676',
            boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',
        },
        '&:active': {
            
            backgroundColor: '#003FBA',
            color: 'white',
            boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',

        },
        '&:focus': {
            backgroundColor: '#003FBA',
            color: 'white',
            boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',

        }
        
        
    },
    buttonLiveQueue: {
        color: '#767676',
        position: 'relative',
        background: 'FFFFFF',
        boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',
        borderRadius: '8px',
        width: '85%',
        marginTop: theme.spacing(2),
        '&:hover': {
            backgroundColor: 'white',
            color: '#767676',
            // borderColor: '#0062cc',
            boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',
        },
        '&:active': {
            
            backgroundColor: '#003FBA',
            color: 'white',
            boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',

        },
        '&:focus': {
        backgroundColor: '#003FBA',
            color: 'white',
            boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',
        },
    },
    filter: {
        width: '100%',
        borderRadius: '8px',
        boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),
    },
    typography: {
        padding: theme.spacing(1),
        fontFamily: 'roboto',
        fontWeight: '500',
        color: '#767676',
        textTransform: 'uppercase',
        fontSize: '0.875rem'
    },
    queue: {
        color: "#003FBA",
        fontWeight: 500,
        display: "block",
        marginLeft: "0px",
        fontSize: "24px"
    },
    bookings:{ color: "#767676",
    fontWeight: 500,
    display: "block",
    marginLeft: "0px",
    fontSize: "24px",

    },
    filterBy: {
        fontSize: 16,
        fontWeight: 500,
        padding: '6px 8px'
    }
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -20,
        top: 16,
        borderRadius: '7px',
        padding: '7px',
        fontSize: '1rem'
    },
}))(Badge);

export default function App(props) {
    const [appointments, setAppointments] = useState();
    // const [address, setAddress] = useState([]);
    const [doctors, setDoctors] = useState([])
    const [searchResults, setSearchResults] = useState();
    const [openDisplay, setOpenDisplay] = useState(false);
    const [displayValues, setDisplayValues] = useState({});
    const [values, setValues] = useState({});
    const [queueOption, setQueueOption] = useState(false);
    const [filter, setFilter] = useState('');
    const [providerFilter, setProviderFilter] = useState({})
    const [checkedIn, setCheckedIn] = useState(0);
    const [tempFilter, setTempFilter] = useState({})
    const [tab, setTab] = useState(0)
    const [openFilter, setOpenFilter] = useState(false)
    const [statuses, setStatuses] = useState([])
    const [providerFilters, setProviderFilters] = useState({})
    const [statusFilters, setStatusFilters] = useState({})
    const [providerSelected, setProviderSelected] = useState(0)
    const [statusSelected, setStatusSelected] = useState(0)
    const [activeStatuses, setActiveStatuses] = useState(0)
    const [activeDoctors, setActiveDoctors] = useState(0)
    const [providerFiltersDialog, setProviderFiltersDialog] = useState({})
    const [statusFiltersDialog, setStatusFiltersDialog] = useState({})
    const global = useContext(GlobalContext)
    let settingsPermission = global.state.user && global.state.user.document.roles[0] === 'admin';

    let defaultSettings = {
        // email: false,
        provider: true,
        // hin: false,
        // phone: false,
        time: true,
        type: true,
        status: true,
       
    }
    let currentOrgSettings,
        orgID = global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg._id : global.state.defaultOrg._id,
        whiteLabelURL

    useEffect(() => {
        //get configs from porton db for "setDisplay" panel (currently disabled)
        let envVar = async () => {
            let checkinEnvVar = await global.api.GetEnvVariable({name: 'checkin-app'})
            checkinEnvVar = checkinEnvVar.data.res
            if (typeof global.state.selectedOrg != 'undefined' && global.state.selectedOrg && global.state.selectedOrg != null  && JSON.stringify(global.state.selectedOrg) != "{}") {
                orgID = global.state.selectedOrg._id
                whiteLabelURL = global.state.selectedOrg.whiteLabelURL
                if(global.state.selectedOrg.infoDisplaySettings && typeof global.state.selectedOrg.infoDisplaySettings !='undefined'){
                    currentOrgSettings = global.state.selectedOrg.infoDisplaySettings
                }else{
                    currentOrgSettings = defaultSettings
                }
        
            } else { //default org
                orgID = global.state.defaultOrg._id
                whiteLabelURL = global.state.defaultOrg.whiteLabelURL 
                if(typeof global.state.defaultOrg.infoDisplaySettings !='undefined' && global.state.defaultOrg.infoDisplaySettings ){
                    currentOrgSettings = global.state.defaultOrg.infoDisplaySettings
                }else{
                    currentOrgSettings = defaultSettings
                }
            }
            //console.log("currentOrgSettings checkin: ", currentOrgSettings)
            if (typeof currentOrgSettings === 'undefined') {
                // setDisplayValues(defaultSettings)
                setValues(defaultSettings)
            } else {
                setDisplayValues({
                    // email: typeof currentOrgSettings.email !== 'undefined' ? currentOrgSettings.email : false,
                    provider: typeof currentOrgSettings.provider !== 'undefined' ? currentOrgSettings.provider : true,
                    // hin: typeof currentOrgSettings.hin !== 'undefined' ? currentOrgSettings.hin : false,
                    // phone: typeof currentOrgSettings.phone !== 'undefined' ? currentOrgSettings.phone : false,
                    time: typeof currentOrgSettings.time !== 'undefined' ? currentOrgSettings.time : true,
                    type: typeof currentOrgSettings.type !== 'undefined' ? currentOrgSettings.type : true,
                    status: typeof currentOrgSettings.status !== 'undefined' ? currentOrgSettings.status : true,
                })
                setValues({
                    // email: typeof currentOrgSettings.email !== 'undefined' ? currentOrgSettings.email : false,
                    provider: typeof currentOrgSettings.provider !== 'undefined' ? currentOrgSettings.provider : true,
                    // hin: typeof currentOrgSettings.hin !== 'undefined' ? currentOrgSettings.hin : false,
                    // phone: typeof currentOrgSettings.phone !== 'undefined' ? currentOrgSettings.phone : false,
                    time: typeof currentOrgSettings.time !== 'undefined' ? currentOrgSettings.time : true,
                    type: typeof currentOrgSettings.type !== 'undefined' ? currentOrgSettings.type : true,
                    status: typeof currentOrgSettings.status !== 'undefined' ? currentOrgSettings.status : true,
                })
            }
        }
        envVar();

        if (settingsPermission) {
            props.setTitle("Select Organization");
        } else {
            props.setTitle("Manage Appointments");
        }
    }, [])


    const sendSettings = async () => {
        //save setDisplay configs to porton DB
        
        setDisplayValues({...values, ...tempFilter})
        if (!global.state.selectedOrg && !global.state.defaultOrg){
            alert("No org!")
            return
        }
        setValues({...values, ...tempFilter})
        let result = await global.api.updateInfoDisplay(orgID, {...values, ...tempFilter})

        //update the global state with new configs
        if (result && result.status === 200) {
        if(JSON.stringify(global.state.selectedOrg) != "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' ){
            let org = global.state.selectedOrg
            org.infoDisplaySettings = {...values, ...tempFilter}
            global.setState((prev) => {
                return { ...prev, selectedOrg: org }
            })
        } else { //default org
            let org = global.state.defaultOrg
            org.infoDisplaySettings = {...values, ...tempFilter}
            global.setState((prev) => {
                return { ...prev, defaultOrg: org }
            })
        }
        }
    }

    const handleSearchChange = event => {
        setFilter(event.target.value);
    };

    // function findAddressByDemoNo(demoNo) {
    //     const obj = address.find(x => x.demographicNo === demoNo)
    //     return obj
    // }

    function getDoctorName(post) {
        if (!doctors.length) return;
        const doctor = doctors.find(x => x.providerNo === String(post))
        if (doctor !== null) {
            return `${doctor.firstName} ${doctor.lastName}`
        }
    }

    // if (appointments && address !== []) {
    //     for (const i in appointments) {
    //         const obj = findAddressByDemoNo(appointments[i].demographicNo)
    //         if (obj) {
    //             if (appointments[i].demographicNo === obj.demographicNo) {
    //                 const {providerNo, ...objFiltered} = obj
    //                 let merged = {...appointments[i], ...objFiltered}
    //                 appointments[i] = merged
    //             }
    //         }
    //     }        
    // }
    
    useEffect(() => {
        if (doctors.length) {
            doctors.map(post => {
                setProviderFilter(providerFilter => ({...providerFilter, [post.firstName]: false}));
            })
        }
        if (appointments && appointments.length && doctors.length) {
            appointments.map(appointment => {
                let doc = doctors.find(doctor => String(doctor.providerNo) === String(appointment.providerNo))
                if (doc) {
                    setProviderFilters(providerFilters => ({ ...providerFilters, [doc.providerNo]: providerFilters[doc.providerNo] || false }))
                    setProviderFiltersDialog(providerFilters => ({ ...providerFilters, [doc.providerNo]: providerFilters[doc.providerNo] || false }))
                }
            })
        }
    },[doctors, appointments])

    useEffect(() => {
        if (appointments && appointments.length && statuses.length) {
            appointments.map(appointment => {
                let status = statuses.find(status => String(status.status) === String(appointment.status))
                if (status) {
                    setStatusFilters(statusFilters => ({ ...statusFilters, [status.description]: statusFilters[status.description] || false }))
                    setStatusFiltersDialog(statusFilters => ({ ...statusFilters, [status.description]: statusFilters[status.description] || false }))
                }
            })
        }
    },[statuses, appointments])

    useEffect(() => {
        //search feature
        let list = [];
        if (appointments) {
            appointments.filter(apt => {
                let res = Object.keys(apt).some(key => {
                    // if (key === 'name' || key === 'hin' || key === 'phone' || key === 'startTime' || key === 'providerNo' || key === 'appointmentDate' || key === 'email') {
                    if (key === 'name' || key === 'startTime' || key === 'providerNo' || key === 'appointmentDate') {
                        if (key === 'providerNo') {
                            const doctorName = getDoctorName(apt.providerNo)
                            if (doctorName.toLowerCase().includes(filter.toLowerCase()) === true) {
                                return true
                            } else {
                                return false
                            }
                        } else {
                            if (apt[key].toLowerCase().includes(filter.toLowerCase()) === true) {
                                return true
                            } else {
                                return false
                            }
                        }
                    }
                })
                if (res === true) {
                    list.push(apt)
                }
            });
            setSearchResults(list)
        } 
    },[filter])


    // const handleProviderSelect = (event) => {
    //     setProviderFilter({...providerFilter, [event.target.name]: event.target.checked})
    // }

    const handleChange = (event) => {
        //setValues({ ...values, [event.target.name]: event.target.checked });
        setTempFilter({ ...tempFilter, [event.target.name]: event.target.checked });
        
    };

    const handleClose = () => {
        setOpenDisplay(false)
        setOpenFilter(false)
        setProviderFilters(providerFiltersDialog)
        setStatusFilters(statusFiltersDialog)
    };

    const handleTabChange = (event, index) => {
        setTab(index)
    }

    const handleProviderFiltersChange = (event) => {
        let checked = event.target.checked
        let name = event.target.name
        setProviderFilters(providerFilters => ({...providerFilters, [name]: checked}))
        if (event.target.name === 'selectAll') {
            for (let property in providerFilters) {
                setProviderFilters(providerFilters => ({ ...providerFilters, [property]: checked }))
            }
        }
    }

    const handleStatusFiltersChange = (event) => {
        let checked = event.target.checked
        let name = event.target.name
        setStatusFilters(statusFilters => ({...statusFilters, [name]: checked}))
        if (event.target.name === 'selectAll') {
            for (let property in statusFilters) {
                setStatusFilters(statusFilters => ({ ...statusFilters, [property]: checked }))
            }
        }
    }

    const handleProviderDialogChange = (event) => {
        let checked = event.target.checked
        let name =  event.target.name
        setProviderFilters(providerFilters => ({...providerFilters, [name]: checked}))
        setProviderFiltersDialog(providerFilters => ({ ...providerFilters, [name]: checked }))
    }

    const handleStatusDialogChange = (event) => {
        let checked = event.target.checked
        let name = event.target.name
        setStatusFilters(statusFilters => ({...statusFilters, [name]: checked}))
        setStatusFiltersDialog(statusFilters => ({ ...statusFilters, [name]: checked }))
    }

    const handleApply = () => {
        setProviderFiltersDialog(providerFilters)
        setStatusFiltersDialog(statusFilters)
        setOpenFilter(false)
    }

    const clearAll = () => {
        for (let property in providerFilters) {
            setProviderFilters(providerFilters => ({ ...providerFilters, [property]: false }))
            setProviderFiltersDialog(providerFilters => ({ ...providerFilters, [property]: false }))
        }

        for (let property in statusFilters) {
            setStatusFilters(statusFilters => ({ ...statusFilters, [property]: false }))
            setStatusFiltersDialog(statusFilters => ({ ...statusFilters, [property]: false }))
        }
    }

    useEffect(() => {
        if (providerSelected && activeDoctors && providerSelected === activeDoctors) {
            setProviderFilters(providerFilters => ({...providerFilters, selectAll: true}))
        } else {
            setProviderFilters(providerFilters => ({...providerFilters, selectAll: false}))
        }
    }, [providerSelected, activeDoctors])

    useEffect(() => {
        if (statusSelected && activeStatuses && statusSelected === activeStatuses) {
            setStatusFilters(statusFilters => ({...statusFilters, selectAll: true}))
        } else {
            setStatusFilters(statusFilters => ({...statusFilters, selectAll: false}))
        }
    }, [statusSelected, activeStatuses])

    useEffect(() => {
        const getTotalProviderSelected = () => {
            let selected = Object.keys(providerFilters).filter(providerNo => providerNo !== 'selectAll' && providerFilters[providerNo] === true).length
            let total = Object.keys(providerFilters).filter(providerNo => providerNo !== 'selectAll').length
            setProviderSelected(selected)
            setActiveDoctors(total)
        }
        const getTotalStatusSelected = () => {
            let selected = Object.keys(statusFilters).filter(status => status !== 'selectAll' && statusFilters[status] === true).length
            let total = Object.keys(statusFilters).filter(status => status !== 'selectAll').length
            setStatusSelected(selected)
            setActiveStatuses(total)
        }
        getTotalProviderSelected()
        getTotalStatusSelected()
    }, [providerFilters, statusFilters])

    const classes = useStyles()
    // console.log('checkedIn', checkedIn)

    return (

        <Container  >
            {global.state.user && global.state.user.document.roles[0] === 'admin' &&
                <OrgSelector />
            }
            <Grid container direction='row' alignItems="flex-end" justify="flex-start" style={{marginBottom: '.5rem'}}>
                <Grid item lg={4} md={12} sm={12}xs={12}>
                <Typography className={classes.title} variant="h4"> Manage Appointments </Typography></Grid>
                <Grid item  lg={4} md={6} sm={12}xs={12} >

                <StyledBadge badgeContent={checkedIn} color="primary">
                    <Typography className={classes.queue} variant="h4"> Organization&nbsp;Queue <div style={{borderBottom: '5px solid', margin: 'auto', color: '#003FBA', position: 'relative', width: '40px'}}/> </Typography>       
                </StyledBadge>
                </Grid>
                <Grid item  lg={4} md={6} sm={12}xs={12}>
                <StyledBadge badgeContent={checkedIn} color="primary">
                    <Typography className={classes.bookings} variant="h4"> Bookings </Typography>       
                </StyledBadge>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container item justify="space-between" alignItems="center" md={5}sm={5}xs={11}>
                    <div className={classes.search} style={{marginBottom: '20px'}}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="TYPE TO SEARCH…"
                            classes={{
                                root: classes.inputRoot, 
                                input: classes.inputInput,
                            }}
                            style={{ width: '100%', fontWeight:"700", }}
                            inputProps={{ 'aria-label': 'search' }}
                            value={filter}
                            onChange={handleSearchChange}
                        />
                    </div>        
                </Grid>
                
                <Grid container item justify="flex-end"alignItems="center" md={3}sm={3}xs={5}>
                    {/*{!queueOption ? }*/}
                        <Button className={classes.buttonLiveQueue} style={{marginBottom: '20px'}} onClick={() => setQueueOption(false)}>
                           All Appointments
                        </Button> 
                  </Grid>
                  <Grid container item justify="flex-end" alignItems="center" sm={2}xs={3}>     
                        <Button className={classes.button} style={{marginBottom: '20px'}} onClick={() => setQueueOption(true)}>
                        Live Queue   
                        </Button> 
                    
                </Grid>
                <Grid className="filterTour" container item justify="flex-end" alignItems="center" md={2}sm={2}xs={3}>
                    <Button className={classes.button} style={{marginBottom: '20px'}} onClick={() => setOpenFilter(true)} disabled={!doctors.length || !statuses.length}>
                        Filter By
                    </Button>
                    
                </Grid>
                {(Object.keys(providerFiltersDialog).filter(providerNo => providerNo !== 'selectAll' && providerFiltersDialog[providerNo] === true).length || Object.keys(statusFiltersDialog).filter(status => status !== 'selectAll' && statusFiltersDialog[status] === true).length) ?
                    <Grid item container xs={12}>
                        <Grid item xs={2}>
                            <Typography className={classes.filterBy}>Filter&nbsp;By:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl>
                                <FormGroup row>
                                    {Object.keys(providerFiltersDialog).filter(providerNo => providerNo !== 'selectAll' && providerFiltersDialog[providerNo] === true).slice(0, 5).map((providerNo, index) => (
                                        <FormControlLabel
                                            control={<Checkbox checked={providerFiltersDialog[providerNo]} onChange={handleProviderDialogChange} name={String(providerNo)} color='primary' />}
                                            label={getDoctorName(providerNo)}
                                            key={index}
                                        />
                                    ))}
                                </FormGroup>
                                <FormGroup row>
                                    {Object.keys(statusFiltersDialog).filter(status => status !== 'selectAll' && statusFiltersDialog[status] === true).slice(0, 5).map((status, index) => (
                                        <FormControlLabel
                                            control={<Checkbox checked={statusFiltersDialog[status]} onChange={handleStatusDialogChange} name={status} color='primary' />}
                                            label={status}
                                            key={index}
                                        />
                                    ))}
                                    {providerSelected + statusSelected - 8 > 0 ?
                                        <Button color='primary' onClick={() => { setOpenFilter(true) }}>+{providerSelected + statusSelected - 8} MORE</Button>
                                        : <></>
                                    }
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item container xs={2} justify='flex-end' alignItems='flex-end'>
                            <Button color='secondary' onClick={clearAll}>CLEAR&nbsp;ALL</Button>
                        </Grid>
                    </Grid>
                    :
                    <></>
                }
            </Grid>
            <AppointmentTable
                queueOption={queueOption} 
                displayValues={defaultSettings} 
                data={appointments} 
                filter={filter} 
                setData={setAppointments} 
                setDataDoctors={setDoctors} 
                searchResults={searchResults}
                providerFilter={providerFilter}
                checkedIn={checkedIn}
                setCheckedIn={setCheckedIn}
                setAllStatuses={setStatuses}
                providerFilters={providerFiltersDialog}
                statusFilters={statusFiltersDialog}
            />
            <Dialog
                open={openFilter}
            >
                <DialogTitle>
                    <Grid container alignItems='center' style={{padding: 8}}>
                        <Grid item xs={9}>
                            Filter By
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={handleClose} edge="end" size="small">
                                <CloseIcon style={{ color: "#3b3b3b" }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container style={{padding: 8}}>
                        <Grid item xs={12}>
                            <Tabs
                                value={tab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleTabChange}
                                variant='fullWidth'
                                TabIndicatorProps={{
                                    display: 'none'
                                }}
                            >
                                <Tab style={{border: "2px solid"}} label={providerSelected && !providerFilters.selectAll ? `PROVIDER [${providerSelected}/${activeDoctors}]` : "PROVIDER" } />
                                <Tab  style={{border: "2px solid"}}label={statusSelected && !statusFilters.selectAll ? `STATUS [${statusSelected}/${activeStatuses}]` : "STATUS" } />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '20px', padding: '10px 20px',border: "1px solid gray ", borderRadius: 8, maxHeight: 230, overflowY: 'auto' }}>
                            <Grid container>
                                {tab === 0 &&
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={providerFilters.selectAll || false} onChange={handleProviderFiltersChange} name='selectAll' color='primary' />}
                                                label='Select All'
                                            />
                                            {Object.keys(providerFilters).filter(providerNo => providerNo !== 'selectAll').map((providerNo, index) => (
                                                <FormControlLabel
                                                    control={<Checkbox checked={providerFilters[providerNo] || false} onChange={handleProviderFiltersChange} name={String(providerNo)} color='primary' />}
                                                    label={getDoctorName(providerNo)}
                                                    key={index}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                }
                                {tab === 1 &&
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={statusFilters.selectAll || false} onChange={handleStatusFiltersChange} name='selectAll' color='primary' />}
                                                label='Select All'
                                            />
                                            {Object.keys(statusFilters).filter(status => status !== 'selectAll').map((status, index) => (
                                                <FormControlLabel
                                                    control={<Checkbox checked={statusFilters[status] || false} onChange={handleStatusFiltersChange} name={status} color='primary' />}
                                                    label={status}
                                                    key={index}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justify='space-between' style={{ padding: '0 20px' }}>
                        <Grid item>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleApply} color="primary" autoFocus>
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDisplay}
                onClose={handleClose}
                maxWidth='xl'
            >
                <DialogTitle>{"Information Display"}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{color: 'black'}}>
                        Set the information you want to display on default
                    </DialogContentText>
                    <DialogContent>
                        <Grid container direction="row">
                            <Grid container item xs={12}>
                                <FormControl required component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" defaultChecked={values.provider} onChange={handleChange} name="provider" />}
                                            label="Provider"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox color="primary" defaultChecked={values.time} onChange={handleChange} name="time" />}
                                            label="Time"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox color="primary" defaultChecked={values.type} onChange={handleChange} name="type" />}
                                            label="Type"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox color="primary" defaultChecked={values.status} onChange={handleChange} name="status" />}
                                            label="Status"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => (sendSettings(), handleClose())} color="primary" autoFocus>
                        Set Values
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
 