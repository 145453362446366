import { MenuItem, Select, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import BootstrapInput from '../../../../../../styles/BootstrapInput';

export default function UrlComponent(props) {
    const classes = props.classes;
    const state = props.state;
    const handleChange = props.handleChange;
    return (
        
        <Grid container spacing={3} className={classes.row}>
            <Grid item xs={12} sm={6}>
                <InputLabel className={classes.formLabel} shrink htmlFor="provider-input">
                    Appointment Type
                </InputLabel>
                <FormControl fullWidth variant="outlined" className={classes.dropdown}>
                    <Select
                        fullWidth
                        name="modesSelect"
                        value={state.modesSelect}
                        onChange={(e) => handleChange(e)}
                        input={<BootstrapInput/>}
                        id="modesSelect-input"
                        disabled={state && state.userList && state.userList[state.provider] && state.userList[state.provider].modes && state.userList[state.provider].modes.length === 0 ? true : false}
                    >
                        <option value={-1} hidden>
                            {state && state.userList && state.userList[state.provider] && state.userList[state.provider].modes && state.userList[state.provider].modes.length === 0 ? 
                                "Provider has no Types enabled" : "Select Type"
                            }
                        </option>
                        {state && state.userList && state.userList[state.provider] && state.userList[state.provider].modes && state.userList[state.provider].modes.map((mode, i) => {
                            return (
                                <MenuItem className={classes.optionList} value={i} key={i}> 
                                    <Grid container>
                                        <Box className={classes.appointmentModeSampleColor} bgcolor={mode.color} /> 
                                        <Typography>{mode.name}</Typography>
                                    </Grid>
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputLabel className={classes.formLabel} shrink htmlFor="reasonSelect-input">
                    Appointment Reason
                </InputLabel>
                <FormControl fullWidth variant="outlined" className={classes.dropdown}>
                    <Select
                        fullWidth
                        name="reasonSelect"
                        value={state.reasonSelect}
                        onChange={(e) => handleChange(e)}
                        input={<BootstrapInput/>}
                        id="reasonSelect-input"
                        disabled={state && state.userList && state.userList[state.provider] && state.userList[state.provider].reasons && state.userList[state.provider].reasons.length === 0 ? true : false}
                    >
                        <option value={-1} hidden> 
                            {state && state.userList && state.userList[state.provider] && state.userList[state.provider].reasons && state.userList[state.provider].reasons.length === 0 ? 
                                "Provider has no Reasons enabled" : "Select Reason"
                            } 
                        </option>
                        {state && state.userList && state.userList[state.provider] && state.userList[state.provider].reasons && state.userList[state.provider].reasons.map((reason, i) => {
                            return (
                                <MenuItem className={classes.optionList} value={i} key={i}> {reason} </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

