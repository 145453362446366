/*
 * @Author: Vincent Tran
 * @Date: 2020-07-21 11:20:00
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-07-24 05:31:25
 * @Description: file content
 */

/**
 * Short description.  Util function to combine style functions and/or style objects.
 * 
 * NOTE: If multiple style functions/objects have the same class name, 
 * the class will be overidden by the class in the style function/
 * object that is listed last as an argument. This does not mean the 
 * entire style function/object will be overidden, just the class with 
 * the same name.
 * 
 * Due to this concern, styles should be listed from least specific to 
 * most specific.
 * E.g. combineStyles(generalStyle, ... , componentSpecificStyle)
 * 
 * @param  {...any} styles 
 */

function combineStyles(...styles) {
    return function CombineStyles(theme) {
        const outStyles = styles.map((arg, i) => {
            // Apply the "theme" object for style functions.
            if (typeof arg === 'function') {
                return arg(theme);
            }
            // Objects need no change.
            return arg;
        });
  
        return outStyles.reduce((acc, val) => Object.assign(acc, val));
    };
}

export default combineStyles;