import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import GlobalContext from "../../../../context/GlobalContext";
import settingsStyles from "../../../../styles/settingsPageStyles";
import AppointmentTypes from "./components/AppointmentTypes";
import Bookings from "./components/Bookings";
import PatientIdentifier from "./components/PatientIdentifier";
import PaymentMethod from "./components/PaymentMethod";
import ServiceTypes from "./components/ServiceTypes";
import Timezones from "./components/Timezones";

let oldModesState;

function AppointmentSetting() {
  const global = useContext(GlobalContext);
  let org = {};
  if (
    global.state.selectedOrg &&
    Object.keys(global.state.selectedOrg).length !== 0
  ) {
    org = global.state.selectedOrg;
  } else if (
    global.state.defaultOrg &&
    Object.keys(global.state.defaultOrg).length !== 0
  ) {
    org = global.state.defaultOrg;
  }

  const [expanded, setExpanded] = useState(false);
  const [revealAddServicesForm, setRevealAddServicesForm] = useState(false);
  const [serviceData, setServiceData] = useState({
    serviceName: "",
    serviceDescription: "",
  });

  const classes = settingsStyles();

  const [modesState, setModesState] = React.useState({});
  const [modeServiceMap, setModeServiceMap] = React.useState(
    org.modeServiceMap
  );
  // let org = global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg : global.state.defaultOrg

  const [patientIdentifiers, setPatientIdentifiers] = useState({
    email: false,
    phone: false,
    hin: false,
    fingerprint: false,
    iris: false,
  });
  const [paymentMethod, setPaymentMethod] = useState({
    currency: "CAD",
    primaryPM: "Insurance",
    secondaryPM: "Credit/Debit Card",
    paymentServiceProvider: "Stripe Payments (North A)",
  });
  const theme = useTheme();
  const loadModes = async (e) => {
    let arr2 = [];

    let modesObject = await global.api.findAllModes();
    let tempModesArray = [];

    if (org.org_services) {
      //converting org.org_services into an array with enabled values for ease of use
      tempModesArray = Object.keys(org.org_services).map((key) => {
        return { key: key, enabled: org.org_services[key] };
      });
    }

    for (let i = 0; i < modesObject.data.length; i++) {
      let index = tempModesArray.findIndex(
        (mode) => mode.key === modesObject.data[i]._id
      );

      arr2.push({
        id: modesObject.data[i]._id,
        description: modesObject.data[i].description,
        name: modesObject.data[i].name,
        type: modesObject.data[i].type,
        enabled: tempModesArray[index] ? tempModesArray[index].enabled : false,
        theme: modesObject.data[i].color
          ? modesObject.data[i].color
          : "#FFFFFF",
        index: i,
        menuOpen: false,
      });
    }

    setModesState({
      ...modesState,
      modes: arr2,
      index: 0,
      tempTheme: "#FFFFFF",
      editTheme: false,
      tempTitle: "",
      tempType: "",
      tempDescription: "",
      removedModes: [],
    });
  };

  const [state, setState] = useState({
    isEdit: false,
    isLoading: false,
    selectedLocation: org.location,
    patientSettings: {
      allowWalkIns: org.settings ? org.settings.allowWalkInPatients : false,
      restrictPrimary: org.settings
        ? org.settings.restrictPrimaryProvider
        : false,
      allowNewEmrPatients: org.settings
        ? org.settings.allowNewEmrPatients
        : false,
    },
  });

  useDeepCompareEffect(() => {
    loadModes();

    setState({
      ...state,
      patientSettings: {
        allowWalkIns: org.settings ? org.settings.allowWalkInPatients : false,
        restrictPrimary: org.settings
          ? org.settings.restrictPrimaryProvider
          : false,
        allowNewEmrPatients: org.settings
          ? org.settings.allowNewEmrPatients
          : false,
      },
    });
  }, [org.settings]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const saveOldState = () => {
    oldModesState = JSON.parse(JSON.stringify(modesState));
    setState({ ...state, isEdit: true });
  };

  const loadOldState = () => {
    if (revealAddServicesForm) {
      setRevealAddServicesForm(false);
      setServiceData({ serviceName: "", serviceDescription: "" });
    }
    setModesState({ ...oldModesState, editTheme: false });
    setState({
      ...state,
      isEdit: false,
      selectedLocation: org.location,
      patientSettings: {
        allowWalkIns: org.settings ? org.settings.allowWalkInPatients : false,
        restrictPrimary: org.settings
          ? org.settings.restrictPrimaryProvider
          : false,
        allowNewEmrPatients: org.settings
          ? org.settings.allowNewEmrPatients
          : false,
      },
    });
  };
  const fetchOrgModeServiceMap = async (org) => {
    try {
      // Check if org.modeServiceMap is available and not empty
      if (org.modeServiceMap) {
        return org.modeServiceMap;
      }

      // If org.modeServiceMap is not available or empty, fetch data from mode and service
      const modeServiceMap = {};

      // Fetch data from mode and service and populate modeServiceMap
      for (const mode of org.modes) {
        const modeId = mode.id;
        modeServiceMap[modeId] = [];

        // Assuming service data is available in org.services array
        for (const service of org.services) {
          modeServiceMap[modeId].push(service.name);
        }
      }

      return modeServiceMap;
    } catch (error) {
      throw new Error("Error fetching modeServiceMap: " + error.message);
    }
  };

  useEffect(() => {
    const fetchModeServiceMap = async () => {
      try {
        // Fetch org.modeServiceMap from the database
        const orgModeServiceMap = await fetchOrgModeServiceMap(org); // Implement this function

        // If org.modeServiceMap exists, set modeServiceMap to this value
        if (orgModeServiceMap) {
          setModeServiceMap(orgModeServiceMap);
        }
      } catch (error) {
        console.error("Error fetching org.modeServiceMap:", error);
      }
    };

    fetchModeServiceMap();
  }, [org]);

  //updates the modes doctors have enabled, so that if you remove or disable a mode, the doctor's modes are also updated to reflect that change
  const UpdateDoctorsModes = async () => {
    let arr = [];
    let resultDoctors;
    let len = 0;
    try {
      resultDoctors = await global.api.getDoctorsByOrgs(org._id, 1, -1);
      len = resultDoctors.data.data.length;
    } catch (error) {
      return;
    }

    for (let i = 0; i < len; i++) {
      let modesArr = resultDoctors.data.data[i].modes;
      if (!modesArr) {
        modesArr = [];
      }

      //filters out modes that are no longer in the modes array from the modes offered by the doctor
      let temp = modesArr.filter((mode) => {
        for (let j = 0; j < modesState.modes.length; j++) {
          if (modesState.modes[j].enabled && modesState.modes[j].id === mode) {
            return true;
          }
        }
        return false;
      });

      arr.push({
        key: i,
        id: resultDoctors.data.data[i]._id,
        modes: temp,
      });
    }
    for (let i = 0; i < arr.length; i++) {
      await global.api.updateDoctorModes(org._id, arr[i].id, arr[i].modes);
    }
  };

  //updates, removes and adds modes to the db
  const saveModeList = async () => {
    let modeListArray = [];
    //checks if this element is in oldmodesState, meaning it is already in the db or not
    let newElement = true;
    //a array for all the new modes that have been added, that are not in db
    let newModeArray = [];

    for (let i = 0; i < modesState.modes.length; i++) {
      for (let j = 0; j < oldModesState.modes.length; j++) {
        if (oldModesState.modes[j].id === modesState.modes[i].id) {
          //checks to see if the mode was changed, if it was, add it to the array to update modes in the db
          if (
            oldModesState.modes[j].name !== modesState.modes[i].name ||
            oldModesState.modes[j].description !==
              modesState.modes[i].description ||
            oldModesState.modes[j].theme !== modesState.modes[i].theme ||
            oldModesState.modes[j].type !== modesState.modes[i].type
          ) {
            modeListArray.push({
              modeId: modesState.modes[i].id,
              name: modesState.modes[i].name,
              type: modesState.modes[i].type,
              description: modesState.modes[i].description,
              color: modesState.modes[i].theme,
            });
          }

          newElement = false;
        }
      }
      //checks to make sure that this element doesn't share the same id as an old deleted element, as it could be a new element, with an old id
      if (!newElement) {
        for (let r = 0; r < modesState.removedModes.length; r++) {
          if (modesState.modes[i].id === modesState.removedModes[r].id) {
            newElement = true;
          }
        }
      }

      if (newElement) {
        newModeArray.push({
          modeId: modesState.modes[i].id,
          name: modesState.modes[i].name,
          type: modesState.modes[i].type,
          description: modesState.modes[i].description,
          color: modesState.modes[i].theme,
        });
      }
      newElement = true;
    }
    let removedModes = [];
    //makes list of applicable modes to remove, in case the removed mode was a newly added mode, in which case, no need to update db
    for (let i = 0; i < modesState.removedModes.length; i++) {
      for (let j = 0; j < oldModesState.modes.length; j++) {
        if (oldModesState.modes[j].id === modesState.removedModes[i].id) {
          removedModes.push({
            modeId: modesState.removedModes[i].id,
          });
        }
      }
    }
    await global.api.removeFromModeList(removedModes);
    await global.api.addToModeList(newModeArray);
    await global.api.updateModesList(modeListArray);
  };
  const saveModes = async () => {
    let modes = {};

    for (let i = 0; i < modesState.modes.length; i++) {
      modes[modesState.modes[i].id] = modesState.modes[i].enabled;
    }
    let result = await global.api.updateProviderModes(org._id, modes);

    UpdateDoctorsModes();

    saveModeList();

    if (result.status && result.status === 200) {
      if (
        global.state.selectedOrg &&
        Object.keys(global.state.selectedOrg).length !== 0
      ) {
        org.org_services = modes;
        global.setState((prev) => {
          return { ...prev, selectedOrg: org };
        });
      } else if (
        global.state.defaultOrg &&
        Object.keys(global.state.defaultOrg).length !== 0
      ) {
        org.org_services = modes;
        global.setState((prev) => {
          return { ...prev, defaultOrg: org };
        });
      }
    } else {
      // console.log("Update failed.")
    }
    setModesState({ ...modesState, editTheme: false });
  };
  const saveSettings = async () => {
    if (!global.state.selectedOrg && !global.state.defaultOrg) {
      alert("No org!");
      return;
    }

    if (revealAddServicesForm) {
      setRevealAddServicesForm(false);
      setServiceData({ serviceName: "", serviceDescription: "" });
    }

    setState({ ...state, isLoading: true });

    saveModes();
    let result = await global.api.updateAppointmentSettings(org._id, {
      location: state.selectedLocation,
      modeServiceMap: modeServiceMap,
      settings: {
        allowWalkInPatients: state.patientSettings.allowWalkIns,
        restrictPrimaryProvider: state.patientSettings.restrictPrimary,
        allowNewEmrPatients: state.patientSettings.allowNewEmrPatients,
      },
    });

    if (result.status && result.status === 200) {
      if (
        global.state.selectedOrg &&
        Object.keys(global.state.selectedOrg).length !== 0
      ) {
        org.location = state.selectedLocation;
        org.settings.allowWalkInPatients = state.patientSettings.allowWalkIns;
        org.settings.restrictPrimaryProvider =
          state.patientSettings.restrictPrimary;
        org.settings.allowNewEmrPatients =
          state.patientSettings.allowNewEmrPatients;
        global.setState((prev) => {
          return { ...prev, selectedOrg: org };
        });
      } else if (
        global.state.defaultOrg &&
        Object.keys(global.state.defaultOrg).length !== 0
      ) {
        org.location = state.selectedLocation;
        org.settings.allowWalkInPatients = state.patientSettings.allowWalkIns;
        org.settings.restrictPrimaryProvider =
          state.patientSettings.restrictPrimary;
        org.settings.allowNewEmrPatients =
          state.patientSettings.allowNewEmrPatients;
        global.setState((prev) => {
          return { ...prev, defaultOrg: org };
        });
      }
    } else {
      // console.log("Update failed.")
    }

    setState({ ...state, isLoading: false, isEdit: false });
  };

  const paperClassNames = clsx(classes.container, {
    [classes.disabled]: org.disabled,
  });

  return (
    <Paper
      elevation={3}
      className={paperClassNames}
      style={{
        border: expanded
          ? "2px solid #003FBA"
          : "2px solid rgba(255,255,255,0)",
        borderRadius: "10px",
      }}
    >
      <Grid container>
        <Grid item xs={12} className={classes.paperComponment}>
          <Box
            onClick={handleExpandClick}
            className={clsx(
              classes.labelWithEdit,
              classes.labelWithEditDropdown
            )}
          >
            <Typography
              className={clsx(classes.label, {
                [classes.expandedLabel]: expanded,
              })}
            >
              Appointment Settings
            </Typography>
            <Typography className={classes.edit}>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                disabled={org.disabled}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box className={!state.isEdit ? classes.disabledBackground : ""}>
              <Grid item md={12} sm={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid item xs={12} className={classes.containerSpacing}>
                <Bookings state={state} setState={setState} />
              </Grid>

              <Grid item md={12} sm={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid item xs={12}>
                <Grid container flex>
                  <Grid item md={6} xs={12} className={classes.dividerBorder}>
                    <PatientIdentifier
                      patientIdentifiers={patientIdentifiers}
                      setPatientIdentifiers={setPatientIdentifiers}
                    />

                    <Divider className={classes.divider} />

                    <AppointmentTypes
                      isEdit={state.isEdit}
                      state={modesState}
                      setState={setModesState}
                      services={org.services}
                      modeServiceMap={modeServiceMap}
                      setModeServiceMap={setModeServiceMap}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    {useMediaQuery(theme.breakpoints.down("sm")) ? (
                      <Divider className={classes.divider} />
                    ) : null}

                    <PaymentMethod
                      paymentMethod={paymentMethod}
                      setPaymentMethod={setPaymentMethod}
                    />

                    <Divider className={classes.divider} />

                    <ServiceTypes
                      orgId={
                        global.state.selectedOrg._id
                          ? global.state.selectedOrg._id
                          : global.state.defaultOrg._id
                      }
                      createAppointmentService={
                        global.api.createAppointmentService
                      }
                      deleteAppointmentService={
                        global.api.deleteAppointmentService
                      }
                      updateAppointmentService={
                        global.api.updateAppointmentService
                      }
                      services={org.services}
                      role={global.state.user.document.roles[0]}
                      updateGlobalState={global.setState}
                      revealAddServicesForm={revealAddServicesForm}
                      setRevealAddServicesForm={setRevealAddServicesForm}
                      updateProviderServices={global.api.updateProviderServices}
                      serviceData={serviceData}
                      setServiceData={setServiceData}
                      getDoctorsByOrgs={global.api.getDoctorsByOrgs}
                      roles={global.state.user.document.roles}
                      isEdit={state.isEdit}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={12} sm={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid item xs={12} className={classes.containerSpacing}>
                <Timezones state={state} setState={setState} />
              </Grid>
            </Box>

            <Grid item md={12} sm={12}>
              <Divider className={classes.divider} />
            </Grid>

            <Grid item md={12} sm={12}>
              <Box
                className={clsx(
                  classes.labelWithEditBottomSpacing,
                  classes.labelWithEdit
                )}
              >
                {state.isEdit ? (
                  <Box
                    className={clsx(
                      classes.labelWithEdit,
                      classes.labelWithEditWidth
                    )}
                  >
                    <div md={7} sm={0}></div>
                    {state.isLoading ? (
                      <CircularProgress className={classes.loadingCircle} />
                    ) : (
                      <Box md={5} sm={12}>
                        <Button
                          md={6}
                          sm={12}
                          className={clsx(
                            classes.saveChangesBtn,
                            classes.editToggleBtn
                          )}
                          variant="outlined"
                          color="primary"
                          onClick={saveSettings}
                        >
                          Save Changes
                        </Button>
                        <Button
                          md={6}
                          sm={12}
                          className={classes.editToggleBtn}
                          variant="outlined"
                          color="primary"
                          onClick={() => loadOldState()}
                        >
                          Cancel
                        </Button>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box
                    className={clsx(
                      classes.labelWithEdit,
                      classes.labelWithEditWidth
                    )}
                  >
                    <div md={7} sm={0}></div>

                    {!modesState.modes ? (
                      <CircularProgress className={classes.loadingCircle} />
                    ) : (
                      <Button
                        md={5}
                        sm={12}
                        className={classes.editToggleBtn}
                        variant="outlined"
                        color="primary"
                        onClick={() => saveOldState()}
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AppointmentSetting;
