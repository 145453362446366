/*
 * @Author: Tyler Bowman
 * @Date: 2021-04-15 01:12:16
 * @LastEditors: Tyler Bowman
 * @LastEditTime: 2021-04-15 01:12:16
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { get, includes, isEqual } from "lodash";
import Papa from "papaparse/papaparse.min.js";
import React, { useContext, useEffect, useState } from "react";
import { useSendInviteEmail } from "../SendEmail";
import GlobalContext from "../context/GlobalContext";
import OnImportSuccess from "../frame/successAccountAdded";
import UserPageStyles from "../styles/usersPageStyles";
import PasswordChecklist from "react-password-checklist";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
let roles = [{ role: "Patient" }];

export default function SignUp({ open, onClose, role }) {
  const global = useContext(GlobalContext);
  {
    /*This is to send the organisation data when sending emails*/
  }
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    phone: "",
    org: "",
    role: [],
    password_confirm: "",
    tabSelect: 0,
    currentUploadedFile: null,
  });
  const [error, setError] = useState({
    password: false,
    password_confirm: false,
  });
  const [validEmail, setValidEmail] = useState(true);
  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validPhone, setValidPhone] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validPasswordCon, setValidPasswordCon] = useState(true);
  const [orgDetails, setOrgDetails] = useState({});
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);

  const [successOpen, setsuccessOpen] = useState(false);


  const [uploadingStart, setUploadingStart] = useState(false);

  const details = () => {
    let data =
      Object.keys(global.state.selectedOrg).length > 0
        ? global.state.selectedOrg
        : global.state.defaultOrg;
    setOrgDetails(data);
    return;
  };
  useEffect(details, []);

  const handleRoles = () => {
    const roleMap = {
      HAdmin: "healthOrgAdmin",
      MOA: "oa",
      Doctor: "doctor",
      Patient: "patient",
      Clinic: "clinic"
    };

    const role = get(values, "role[0].role");

    if (isEqual(role, "HAdmin")) {
      if (includes(["admin"], get(global, "state.user.document.roles[0]"))) {
        return roleMap.HAdmin;
      }
      return;
    }

    return roleMap[role];
  };
  const handleSignUp = async () => {
    setLoading(true);
    let role = handleRoles();
    if (!role) {

      alert(`Failed to create account`);
      setLoading(false);
      return;
    }
    let isValidFirst = /^[a-zA-Z ]{2,30}$/.test(values.firstname);
    let isValidLast = /^[a-zA-Z ]{2,30}$/.test(values.lastname);
    let isValidPhone =
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(values.phone);
    let isValidEmail = /^\S+@\S+\.\S+$/.test(values.email);
    let isValidPass =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(
        values.password
      );
    let isValidPassCon = values.password === values.password_confirm;
    setValidLastName(isValidLast);
    setValidFirstName(isValidFirst);
    setValidPhone(isValidPhone);
    setValidEmail(isValidEmail);
    setValidPassword(isValidPass);
    setValidPasswordCon(isValidPassCon);
    if (
      !isValidFirst ||
      !isValidLast ||
      !isValidPhone ||
      !isValidEmail ||
      !isValidPass ||
      !isValidPassCon
    ) {
      setLoading(false);
      return;
    }

    const result = await global.api.register(
      values.firstname,
      values.lastname,
      values.phone,
      values.email,
      values.password,
      values.org,
      role
    );

    if (result && result.status === 200) {
      {
        /* Emmanuel June 29 sending the necessary data to populate emails*/
      }

      const result = await global.api.sendInviteEmail(
        values.firstname,
        values.lastname,
        values.email,
        values.org,
        orgDetails.org_name,
        orgDetails.logo,
        values.password
      );
      if (result.status === 200) {
        setsuccessOpen(true);
        setUploadingStart(true);
      }
    } else if (result && result.status === 400) {
      alert(result.error);
    } else {
      alert(`Failed to create account`);
    }

    setLoading(false);
    setValues({
      ...values,
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      phone: "",
      org: "",
      role: [],
      password_confirm: "",
      tabSelect: 0,
      currentUploadedFile: null,
    });

    onClose();
  };

    
  const {sendEmail} = useSendInviteEmail();

  const handleInvite = async () => {
    // figure out what to do here.

    setLoading(true);
    await sendEmail({
        contactName: `${values.firstname} ${values.lastname}`,
        email: values.email,
        subject: "Here is your invite",
        content: "Please use below link for register or take appointment ",
    });

    setLoading(false);
    setValues({
      ...values,
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      phone: "",
      org: "",
      role: [],
      password_confirm: "",
      tabSelect: 0,
      currentUploadedFile: null,
    });

    onClose();
  }

  useEffect(() => {
    if (role === "admin" || role === "healthOrgAdmin") {
      roles = [
        // { role: "HAdmin" },
        { role: "MOA" },
        { role: "Doctor" },
        //{ role: "Patient" },
        {role:"Clinic"}
      ];
    }
  }, [role]);

  const closeSuccessPopup = () => {
    setUploadingStart(false);
    setsuccessOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValues({ ...values, tabSelect: newValue });
  };

  const fileUploadButton = async (event) => {
    setValues({ ...values, currentUploadedFile: event.target.files[0] });
    event.target.value = null;
  };
  //The function called when papaparse finishes parsing its file
  const dataRead = async (result) => {
    let temp = result.data;
    let role = handleRoles();
    let returned;
    let errors = [];
    if (role) {
      returned = await global.api.registerGroup(
        temp,
        global.state.selectedOrg._id
          ? global.state.selectedOrg._id
          : global.state.defaultOrg._id,
        role
      );
    } else {
      alert("no role");
      setLoading(false);
      return;
    }
    if (returned && returned.status === 200 && returned.data.data.data) {
      for (let i = 0; i < returned.data.data.data.length; i++) {
        if (
          returned.data.data.data[i] &&
          returned.data.data.data[i].status !== 200
        ) {
          errors.push(i + 2);
        }
      }
    }
    if (errors.length === 0) {
      alert("Completed: No Errors");
    } else {
      alert(
        `Errors on lines: ${errors} Please check if formatting is correct, passwords are longer than 8 characters or whether emails may already be in use. All other lines aside from those with errors have been registered successfully`
      );
    }

    setLoading(false);
    setValues({
      ...values,
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      phone: "",
      org: "",
      role: [],
      password_confirm: "",
      tabSelect: 0,
      currentUploadedFile: null,
    });
    onClose();
  };

  const handleFiles = async (files) => {
    let fileName = values.currentUploadedFile.name.toLowerCase();
    if (!fileName.endsWith(".csv")) {
      alert("Please upload a CSV file");
      return false;
    }
    setLoading(true);
    Papa.parse(values.currentUploadedFile, {
      complete: dataRead,
      header: true,
    });
  };

  const classes = UserPageStyles();
  return (
      <div>
          <Dialog open={open} onClose={onClose}>
              <DialogTitle>Invite New Account </DialogTitle>

              <DialogContent>
                  <form>
                      <Box className={classes.extraPadding}>
                          <div>
                              <Tabs
                                  value={values.tabSelect}
                                  onChange={handleChange}
                                  indicatorColor="primary"
                                  aria-label="tabs"
                              >
                                  {/* <Tab label="Single" {...a11yProps(0)} /> */}
                                  {/* <Tab label="Multiple" {...a11yProps(1)} /> */}
                                  {/* <Tab label="Patient" {...a11yProps(2)} /> */}
                              </Tabs>
                          </div>
                      </Box>
                      {values.tabSelect !== 2 && (
                      <Autocomplete
                          multiple
                          fullWidth
                          id="tags-standard"
                          options={roles}
                          onChange={(event, value) =>
                              setValues({ ...values, role: value })
                          }
                          getOptionLabel={(option) => option.role}
                          className={classes.autoCompleteBox}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="standard"
                                  label="Roles"
                                  placeholder="Select"
                                  error={values.role.length < 1}
                                  helperText={
                                      values.role.length < 0 &&
                                      values.firstname.length > 0 &&
                                      "Please enter a valid role"
                                  }
                              />
                          )}
                      />)}

                      {values.tabSelect === 0 && (
              <>
                {/* TODO: HERE THIS ONE  */}
                              <TextField
                                  id="firstname"
                                  label="First Name"
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) =>
                                      setValues({
                                          ...values,
                                          firstname: e.target.value,
                                      })
                                  }
                                  value={values.firstname}
                                  className={clsx(
                                      classes.rowTextSpacing50,
                                      classes.rowTextMargin
                                  )}
                                  required
                                  error={
                                      values.firstname.length < 1 ||
                                      !validFirstName
                                  }
                                  helperText={
                                      !validFirstName &&
                                      "Please enter a valid first name. Names must be at least 2 characters long"
                                  }
                />
                       {/* TODO: HERE THIS ONE  */}
                              <TextField
                                  id="lastname"
                                  label="Last Name"
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) =>
                                      setValues({
                                          ...values,
                                          lastname: e.target.value,
                                      })
                                  }
                                  value={values.lastname}
                                  className={classes.rowTextSpacing50}
                                  required
                                  error={
                                      values.lastname.length < 1 ||
                                      !validLastName
                                  }
                                  helperText={
                                      !validLastName &&
                                      "Please enter a valid last name. Names must be at least 2 characters long"
                                  }
                              />
                              <TextField
                                  id="phone"
                                  label="Phone"
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) =>
                                      setValues({
                                          ...values,
                                          phone: e.target.value,
                                      })
                                  }
                                  value={values.phone}
                                  className={classes.marginSpacing}
                                  required
                                  error={
                                      values.phone.length < 10 || !validPhone
                                  }
                                  helperText={
                                      !validPhone &&
                                      "Please set a valid phone number"
                                  }
                              />
                              <TextField
                                  id="email"
                                  label="Email"
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) =>
                                      setValues({
                                          ...values,
                                          email: e.target.value,
                                      })
                                  }
                                  value={values.email}
                                  className={classes.marginSpacing}
                                  required
                                  error={
                                      !/^\S+@\S+\.\S+$/.test(values.email) ||
                                      !validEmail
                                  }
                                  helperText={
                                      !validEmail && "Please set a valid email"
                                  }
                              />
                              <TextField
                                  id="password"
                                  label="Password"
                                  type="password"
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  fullWidth
                                  onFocus={() => setShowConfirmationPage(true)}
                                  variant="outlined"
                                  size="small"
                                  onBlur={(e) => {
                                    setError({
                                      ...error,
                                      password:
                                          !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{12,}/.test(
                                              values.password
                                          ),
                                    });
                                    setShowConfirmationPage(false);
                                  }}
                                  onChange={(e) =>
                                      setValues({
                                          ...values,
                                          password: e.target.value,
                                          org: global.state.selectedOrg._id
                                              ? global.state.selectedOrg._id
                                              : global.state.defaultOrg._id,
                                      })
                                  }
                                  value={values.password}
                                  className={classes.marginSpacing}
                                  required
                                  error={
                                      values.password.length < 8 ||
                                      !validPassword
                                  }
                                  helperText={
                                      !validPassword &&
                                      "Password must be at least 12 characters including one special character and a number"
                                  }
                              />
                            {showConfirmationPage && (
                                <PasswordChecklist
                                    rules={["minLength", "specialChar", "number", "capital"]}
                                    minLength={12}
                                    value={values.password}
                                    onChange={(isValid) => {}}
                                    style={{ marginBottom: "20px" }}
                                />
                            )}
                              <TextField
                                  id="password_confirm"
                                  label="Confirm Password"
                                  type="password"
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  onBlur={(e) => {
                                      setError({
                                          ...error,
                                          password_confirm: values.password_confirm !== values.password,
                                      });
                                  }}
                                  onChange={(e) =>
                                      setValues({
                                          ...values,
                                          password_confirm: e.target.value,
                                          org: global.state.selectedOrg._id
                                              ? global.state.selectedOrg._id
                                              : global.state.defaultOrg._id,
                                      })
                                  }
                                  value={values.password_confirm}
                                  className={classes.marginSpacing}
                                  required
                                  error={
                                      values.password_confirm !==
                                      values.password
                                  }
                                  helperText={
                                      values.password_confirm &&
                                      values.password_confirm !==
                                          values.password &&
                                      "Please ensure that your passwords match"
                                  }
                              />
                          </>
                      )}
                      {values.tabSelect === 1 && (
                          <>
                              <Typography className={classes.labelSmall}>
                                  Upload csv to invite multiple accounts
                              </Typography>
                              <a
                                  href="/sampleFiles/sample.csv"
                                  download="sample.csv"
                              >
                                  <Typography>Download Sample.csv</Typography>
                              </a>
                              {values.currentUploadedFile && (
                                  <Box className={classes.fileBox}>
                                      <Typography>
                                          {values.currentUploadedFile.name}
                                      </Typography>
                                      <IconButton
                                          aria-label="Delete"
                                          onClick={() =>
                                              setValues({
                                                  ...values,
                                                  currentUploadedFile: null,
                                              })
                                          }
                                          className={classes.iconSpacing}
                                      >
                                          <DeleteIcon />
                                      </IconButton>
                                  </Box>
                              )}
                              <input
                                  accept=".csv"
                                  className={classes.hideInput}
                                  multiple
                                  id="fileButtonRegister"
                                  onChange={fileUploadButton}
                                  type="file"
                              />
                              <label htmlFor="fileButtonRegister">
                                  <Button
                                      variant="contained"
                                      component="span"
                                      className={classes.fileUpload}
                                      startIcon={<AddIcon />}
                                  >
                                      Upload File
                                  </Button>
                              </label>
                          </>
                      )}
                      {values.tabSelect === 2 && (
                        <Box padding={3}> 
                        <TextField
                                  id="firstname"
                                  label="First Name"
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) =>
                                      setValues({
                                          ...values,
                                          firstname: e.target.value,
                                      })
                                  }
                                  value={values.firstname}
                                  className={clsx(
                                      classes.rowTextSpacing,
                                      classes.rowTextMargin
                                  )}
                                  required
                                  error={
                                      values.firstname.length < 1 ||
                                      !validFirstName
                                  }
                                  helperText={
                                      !validFirstName &&
                                      "Please enter a valid first name. Names must be at least 2 characters long"
                                  }
                              />
                              <TextField
                                  id="lastname"
                                  label="Last Name"
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) =>
                                      setValues({
                                          ...values,
                                          lastname: e.target.value,
                                      })
                                  }
                                  value={values.lastname}
                                  className={classes.rowTextSpacing}
                                  required
                                  error={
                                      values.lastname.length < 1 ||
                                      !validLastName
                                  }
                                  helperText={
                                      !validLastName &&
                                      "Please enter a valid last name. Names must be at least 2 characters long"
                                  }
                              />
                              <TextField
                                  id="email"
                                  label="Email"
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) =>
                                      setValues({
                                          ...values,
                                          email: e.target.value,
                                      })
                                  }
                                  value={values.email}
                                  className={classes.marginSpacing}
                                  required
                                  error={
                                      !/^\S+@\S+\.\S+$/.test(values.email) ||
                                      !validEmail
                                  }
                                  helperText={
                                      !validEmail && "Please set a valid email"
                                  }
                              />
                        </Box>
                      )}
                        
                  </form>

                  {loading ? (
                      <CircularProgress className={classes.loadingCircle} />
                  ) : (
                    <>
                      {values.tabSelect !== 2 ?(<>
                          {(values.tabSelect !== 0 &&
                              (values.role.length === 0 ||
                                  values.currentUploadedFile === null)) ||
                          (values.tabSelect !== 1 &&
                              (values.role.length === 0 ||
                                  values.email === "" ||
                                  values.role === "" ||
                                  values.password === "" ||
                                  values.firstname === "" ||
                                  values.lastname === "" ||
                                  values.phone === "" ||
                                  values.password !==
                                      values.password_confirm)) ? (
                              <Button
                                  onClick={handleSignUp}
                                  color="primary"
                                  autoFocus
                                  fullWidth
                                  className={(!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{12,}/.test(values.password) || values.password !== values.password_confirm) ? classes.signupDisabled : classes.signupButton}
                                  disabled={!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{12,}/.test(values.password) || values.password !== values.password_confirm}
                              >Sign Up
                              </Button>
                          ) : values.tabSelect === 1 ? (
                              <Button
                                  onClick={handleFiles}
                                  color="primary"
                                  autoFocus
                                  fullWidth
                                  className={classes.signupButton}
                              >
                                  Sign Up
                              </Button>
                          ) : (
                              <Button
                                  onClick={handleSignUp}
                                  color="primary"
                                  autoFocus
                                  fullWidth
                                  className={classes.signupButton}
                              >
                                  Sign Up
                              </Button>
                          )}
                      </>):(<>
                        <Button
                                  onClick={handleInvite}
                                  color="primary"
                                  autoFocus
                                  fullWidth
                                  className={classes.title}         
                                  disabled={
                                    !validFirstName ||!validLastName || !validEmail
                                  }
                              >
                                  Invite
                              </Button>
                      </>)}
                    </>
                  )}
                  <Button
                      onClick={onClose}
                      color="primary"
                      fullWidth
                      className={classes.cancelButton}
                  >
                      Cancel
                  </Button>
              </DialogContent>
          </Dialog>
          {uploadingStart && (
              <OnImportSuccess open={successOpen} onClose={closeSuccessPopup} />
          )}
      </div>
  );
}
