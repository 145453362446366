import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../../context/GlobalContext";
import settingsStyles from "../../../../styles/settingsPageStyles";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import MoreIcon from '@material-ui/icons/MoreHorizSharp';
import ChangeRoleFlow from './components/ChangeRoleFlow';
import AlertModal from "./components/AlertModal";


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },

  menuItem: {
    minWidth: '160px',
    minHeight: '42px',
    boxShadow: '0 0',
  },
});

function createData(role, description, editOpen, menuOpen, isEdited) {
  return { role, description, editOpen, menuOpen, isEdited };
}

const defaultRows = [
    createData("Admin", "Admin description", false, null, false),
    createData("Provider", "Provider description", false, null, false),
    createData("MOA", "MOA description", false, null, false),
    createData("Patient", "Patient description", false, null, false),
];

const defaults = [...defaultRows];

const RoleManagementSetting = () => {
    const [rows, setRows] = useState([...defaultRows]);
    const [addNewRoleOpen, setAddNewRoleOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [targetRow, setTargetRow] = useState({'role' : null, 'index' : -1});
    const [alertMode, setAlertMode] = useState("del");

  const classes = settingsStyles();
  const classes2 = useStyles();
  const global = useContext(GlobalContext);
  const [expanded, setExpanded] = useState(false);
  let user = global.state.user && global.state.user.document ? global.state.user.document : {};
  let defaultUser = {
    isEdit: false,
    isLoading: false,
    user: user,
    password: "",
  };
  const [state, setState] = useState(defaultUser);
  let org = global.state.selectedOrg != "undefined" && global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0
      ? global.state.selectedOrg
      : global.state.defaultOrg;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let orgId = global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id;

  useEffect(() => {
    getAccountSettings();
  }, []);

  const getAccountSettings = async () => {
    try {
      let res = await global.api.fetchAccountSettings(orgId);
      if (res.status === 200) {
      }
    } catch (error) {
      // console.log("Get automation failed, error", error);
    }
  };


  const saveOldState = () => {
    // setOldEmailState(email)
    // setOldBrowserState(browser)
  };

  const loadOldState = () => {
    // setEmail(oldEmailState)
    // setBrowser(oldBrowserState)
  };

  const rootClassNames = clsx({
    [classes.rootExpanded]: expanded,
    [classes.root]: !expanded,
    [classes.disabled]: org.disabled,
  });

  const isValidIndex = (index) => {
    if (index == undefined || index < 0 || index >= rows.length) {
        return false;
    }
    return true;
  }

    const handleClick = (event, index) => {
        if (!isValidIndex(index)) {
            return;
        }
        let copiedRows = [...rows];
        copiedRows[index].menuOpen = event.currentTarget;
        setRows(copiedRows);
    };

    const handleClose = (event, index) => {
        if (!isValidIndex(index)) {
            return;
        }
        let copiedRows = [...rows];
        copiedRows[index].menuOpen = null;
        setRows(copiedRows);
    };

    const editHandler = (event, index) => {
        if (!isValidIndex(index)) {
            return;
        }
        let copiedRows = [...rows];
        copiedRows[index].editOpen = true;
        copiedRows[index].menuOpen = false;
        setRows(copiedRows);
    };

    const closeEdit = (event, index) => {
        if (!isValidIndex(index)) {
            return;
        }
        let copiedRows = [...rows];
        copiedRows[index].editOpen = false;
        setRows(copiedRows);
    };

    const closeAdd = () => {
      setAddNewRoleOpen(false);
    };

    const openConfirmModal = (event, index, mode) => {
      if (!isValidIndex(index)) {
        return;
      }
      let copiedRows = [...rows];
      copiedRows[index].menuOpen = false;
      setTargetRow({'role' : rows[index].role, 'index' : index});
      setAlertMode(mode);
      setConfirmModalOpen(true);
    }

    const deleteRole = () => {
      let copiedRows = [...rows];
      if (targetRow.index > -1) {
        copiedRows.splice(targetRow.index, 1);
      }
      setRows(copiedRows);
    };

    const resetRole = () => {
      let copiedRows = [...rows];
      let i = targetRow.index;
      switch(i) {
        case 0:
          copiedRows[i].role = "Admin";
          copiedRows[i].description = "Admin description";
          break;
        case 1:
          copiedRows[i].role = "Provider";
          copiedRows[i].description = "Provider description";
          break;
        case 2:
          copiedRows[i].role = "MOA";
          copiedRows[i].description = "MOA description";
          break;
        case 3:
          copiedRows[i].role = "Patient";
          copiedRows[i].description = "Patient description";
          break;
        default:
          return;
      }
      copiedRows[i].isEdited = false;
      setRows(copiedRows);
    }

  return (
    <Grid container component={Paper} elevation={3} className={rootClassNames}>
      <Grid item xs={12} className={classes.paperComponment}>
        <Box
          onClick={handleExpandClick}
          className={clsx(classes.labelWithEdit, classes.labelWithEditDropdown)}
        >
          <Typography
            className={clsx(classes.label, {
              [classes.expandedLabel]: expanded,
            })}
          >
            Role Management
          </Typography>
          <Typography className={classes.edit}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              disabled={org.disabled}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid item md={12} sm={12}><Divider className={classes.divider} /></Grid>
        <Box className={!state.isEdit ? classes.disabledBackground : ''}>
            <TableContainer>
                  <Table
                    className={classes2.table}
                    aria-label="customized table"
                  >
                      <TableRow>
                        <StyledTableCell style={{'fontWeight' : '600'}}>Role</StyledTableCell>
                        <StyledTableCell style={{'fontWeight' : '600'}} align="left">Description</StyledTableCell>
                        <StyledTableCell align="right">
                          <Button
                            color="primary"
                            onClick={() => {setAddNewRoleOpen(true)}}
                            disabled={!state.isEdit}
                            style={{ fontSize: "12px" }}
                          >
                            ADD&nbsp;NEW&nbsp;ROLE
                          </Button>
                          <ChangeRoleFlow user={user} open={addNewRoleOpen} onClose={closeAdd} rows={rows} setRows={setRows} mode={"Add New"} createData={createData}/>
                        </StyledTableCell>
                      </TableRow>
                    <>
                      {rows.map((row, index) => ( 
                        <StyledTableRow key={row.role} style={!state.isEdit ? {'backgroundColor' : '#F5F5F5'} : {}}>
                          <StyledTableCell component="th" scope="row">
                            {row.role}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.description}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <IconButton onClick={ (e) => handleClick(e, index)}>
                                <MoreIcon />
                            </IconButton>
                            <Menu
                            classes={{ list: classes.menu }}
                            open={Boolean(rows[index].menuOpen)}
                            anchorEl={rows[index].menuOpen}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={(e) => handleClose(e, index)}
                            >
                            <MenuItem className={classes2.menuItem} onClick={(e) => {editHandler(e, index)}}>Edit Role</MenuItem>
                            {index < defaultRows.length && row.isEdited == true ?
                              <MenuItem className={classes2.menuItem} onClick={(e) => {openConfirmModal(e, index, "reset")}}>Reset Role</MenuItem>
                              : null}
                            {index >= defaultRows.length ?
                              <MenuItem className={classes2.menuItem} onClick={(e) => {openConfirmModal(e, index, "del")}}>Delete Role</MenuItem>
                              : null}
                            </Menu>

                            <ChangeRoleFlow user={user} open={rows[index].editOpen} onClose={(e) => closeEdit(e, index)} row={row} index={index} rows={rows} setRows={setRows} mode={"Edit"}/>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                    <AlertModal 
                      open={confirmModalOpen} 
                      onClose={(setIsSuccess) => {
                        setConfirmModalOpen(false);
                      }}
                      targetRow={targetRow}
                      deleteRole={deleteRole}
                      resetRole={resetRole}
                      mode={alertMode}
                      >
                    </AlertModal>
                  </Table>
                </TableContainer>
        </Box>
        <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12}>
              {state.isEdit ? (
                <Box>
                  {state.isLoading ? (
                    <Box display="flex" justifyContent="flex-end">
                      <CircularProgress className={classes.loadingCircle} />
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      className={classes.saveBtnGroup}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setState({ ...state, isEdit: false });
                        }}
                      >
                        Save Changes
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.saveChangesBtn}
                        onClick={() => {
                          setState(defaultUser);
                          loadOldState();
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className={classes.saveBtnGroup}
                >
                  <Button
                    xs={12}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setState({ ...state, isEdit: true });
                      saveOldState();
                    }}
                  >
                    Edit
                  </Button>
                </Box>
              )}
            </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default RoleManagementSetting;
