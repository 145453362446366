import {
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import GlobalContext from "../context/GlobalContext";
// import PortonIcon from '../img/portonlogorbg.png';
import "../styles/LoginPageStyles.css";
// import { LoginFooterContainer } from './containers/Footer';
import "./styles/LoginStyles.css";
import Footer from "./LoginFooter.js";
import Header from "./LoginHeader.js";
import LoginPageStyles from "./styles/LoginPageStyle";

export default function LoginPage() {
  const global = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");

  const history = useHistory();

  const routeForgotPass = () => {
    let path = `/forgotpassword`;
    history.push(path);
  };
  const handleResetClose = () => {
    setResetOpen(false);
  };

  const handleSubmit = async () => {
    const res = await global.api.checkForceReset({ email: values.email });

    let path = "/changePassword/" + res.data.user.resetPasswordToken;
    history.push(path);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    history.push('/createUserAccount');
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const result = await global.api.login(values.email, values.password);
    if(result.status === 500 && result.error.message === "Unable to login"){
      setLoading(false);
      alert("Unable to login")
      return
    }
    if (
        result.status !== 200 &&
        result.error.response.data.error.message !== "Must force reset Password"
    ) {
      setMessage(
          result.error.response.data.error.message
              ? result.error.response.data.error.message
              : result.error.message
      );
      setOpen(true);
    } else if (
        result.error &&
        result.error.response.data.error.message === "Must force reset Password"
    ) {
      setResetOpen(true);
    }

    setLoading(false);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: "25ch",
    },
    dialogAction: {
      margin: "0 2rem",
      justifyContent: "space-between",
    },
  }));

  const classes = useStyles();
  const styles = LoginPageStyles();
  const [value, setValue] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  // const handleChange = (prop) => (event) => {
  //     setValue({ ...value, [prop]: event.target.value });
  // };

  const handleClickShowPassword = () => {
    setValue({ ...value, showPassword: !value.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
      <div className={styles.loginPage}>
        {/* Header */}
        <Header />
        {/* Login Modal */}
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="70vh"
            className={styles.loginBox}
        >
          <Dialog
              open={resetOpen}
              keepMounted
              maxWidth="xs"
              onClose={handleResetClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
                className={classes.dialogTitle}
                id="alert-dialog-slide-title"
            >
              Reset Password
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.contentText}>
                You need to reset your password to continue. Would you like to
                reset it now?
              </DialogContentText>
            </DialogContent>

            <DialogActions className={classes.dialogAction}>
              <Button
                  onClick={handleResetClose}
                  style={{ color: "#ea8030" }}
                  className={classes.actionBtn}
              >
                Cancel
              </Button>
              <Button
                  onClick={handleSubmit}
                  style={{ color: "#ea8030" }}
                  className={classes.actionBtn}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <Paper className={styles.loginPaper} elevation={3}>
            <Typography align="center" className={styles.loginTitle}>
              AdminApp Log In
            </Typography>
            <form onSubmit={handleLogin}>
              <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  style={{ display: "block", width: "100%" }}
              >
                <Typography align="left" variant="body1">
                  Email
                </Typography>
                <OutlinedInput
                    id="email"
                    fullWidth
                    placeholder="Enter email"
                    onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                    }
                    className="emailInput"
                />
              </FormControl>
              <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  style={{ display: "block", width: "100%" }}
              >
                <Typography align="left" variant="body1">
                  Password
                </Typography>
                <OutlinedInput
                    id="password"
                    fullWidth
                    placeholder="Enter password"
                    onChange={(e) =>
                        setValues({ ...values, password: e.target.value })
                    }
                    className="passwordInput"
                    type={value.showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                          {value.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                />
              </FormControl>
              <CardActions>
                <Button
                    size="large"
                    type="submit"
                    direction="column"
                    className="forgotYourPasswordButton"
                >
                  <Typography
                      className="forgotYourPasswordText"
                      noWrap
                      onClick={routeForgotPass}
                  >
                    FORGOT YOUR PASSWORD?
                  </Typography>
                </Button>
              </CardActions>
              <Box display="flex" justifyContent="center" className="signInBox">
                {loading ? (
                    <CircularProgress className="circularProgress" />
                ) : (
                    <Button
                        type="submit"
                        variant="contained"
                        className="signInButton"
                        fullWidth
                    >
                      Log In
                    </Button>
                )}
              </Box>
              {/*<Grid className={styles.createAccountGrid}>*/}
              {/*  <Typography className={styles.noAccountText} noWrap>*/}
              {/*    Don't have an account?{" "}*/}
              {/*    <Button className={styles.createAccountButton} onClick={handleClick}>*/}
              {/*      CREATE ACCOUNT*/}
              {/*    </Button>*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}
            </form>
          </Paper>
        </Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <Typography variant="body1">{message}</Typography>
          </DialogContent>
          <Button
              onClick={handleClose}
              style={{ margin: "0.5rem" }}
              variant="outlined"
              color="primary"
          >
            Ok
          </Button>
        </Dialog>
        {/* Footer */}
        <Footer className={styles.newFooter} />
        {/* <LoginFooterContainer/> */} {/* old footer */}
      </div>
  );
}