import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';
import HelpStyles from '../../../styles/HelpStyles';


const TechSupp = () => {
    const classes = HelpStyles()

    return (
        <div className={classes.boxPadding}>
            <Box className={classes.spreadHeader}>
                <Typography className={classes.heading}>Technical Suport</Typography>
                <Link className={classes.button} href="mailto:support.services@portonhealth.ca" >CONTACT</Link>
            </Box>
            <Box className={classes.subHeadingTopMargin}>
                <Typography className={classes.subHeadingText}>
                    If your question was not answered from Porton Health’s FAQ page or if you need further assistance,
                    please contact technical support.
                </Typography>
            </Box>
        </div>
  )
}

export default TechSupp
