import { Box, Link, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import GlobalContext from "../../../context/GlobalContext";
import HelpStyles from '../../../styles/HelpStyles';

const Tutorial = (props) => {
    const classes = HelpStyles()
    const global = useContext(GlobalContext)
    
    //Starts the tour needs to be global so that panels can open by checking 
    //tourOpen bool
    const setOpen = () => {
        global.setTourOpen(true)
    }


    return (
        <div className={classes.boxPadding}>
            
            <Box className={classes.spreadHeader}>
                <Typography className={classes.heading}>AdminApp Tutorial</Typography>
                <Link className={classes.button} onClick={setOpen}>START</Link>
            </Box>
            <Box className={classes.subHeadingTopMargin}>
                <Typography className={classes.subHeadingText}>
                    You will activate AdminApp’s onboarding tutorial.
                </Typography>
            </Box>
        </div>
  )
}

export default Tutorial
