/**
 * TO DO: 
 * Connect this component to the database...
 * 1) Retrieve provider data from database, save info to state, and display.
 * 2) Edits made to provider data must update in the database (handleSave).
 * 
 * Other minor enhancements:
 * 1) Possibly need to add more dropdown options for Title
 * 2) Switch current dropdown selects for country, year, to be virtualized lists for faster loading
 */

import { Switch, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from "@material-ui/core/ButtonBase";
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useContext } from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle';
import UserPageStyles from '../../../../../styles/usersPageStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, UserPageStyles);

export default function EditPatientProfile(props) {
    // temporary default values..
    // Real values should be pulled from database in the future
    const userInfo = {
        walkInEnabled: false,
    }
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({...userInfo});
    const global = useContext(GlobalContext)
    const _id = props._id
    const closeParentModal = props.handleClose
    const classes = combinedStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    let org = {}
    if (global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0) {
        org = global.state.selectedOrg
    } else if (global.state.defaultOrg && Object.keys(global.state.defaultOrg).length !== 0) {
        org = global.state.defaultOrg
    }

    const pullServices = async () => {
        let resultDoctor
        let pulledServices
        let pulledModes
        let walkInEnabled
        try {
            resultDoctor = await global.api.getProviderDoctorByID(_id, org._id) 
            pulledServices = resultDoctor.data.data.data.org_services;
            pulledModes = resultDoctor.data.data.data.modes;
            walkInEnabled = resultDoctor.data.data.data.settings && resultDoctor.data.data.data.settings[org._id] ? resultDoctor.data.data.data.settings[org._id].allowWalkIns : false;
        } catch (error) {
            console.log(error.response ? error.response.data : error);
            return;
        }

        let arr = []
        for(let i = 0; i < org.services.length; i++){
            if(pulledServices && pulledServices !== null && pulledServices.includes(org.services[i].name)){
                arr.push({id:i, name:org.services[i].name, enabled: true})
            } else{
                arr.push({id:i, name:org.services[i].name, enabled: false})
            }
        }

        let arr2 = []

        if(org?.org_services) {
            //converts org.org_services into a format that is acceptable by translateappointmentmodeArray
            let tempModesArray = Object.keys(org.org_services).map(key => {
                return key;
            })

            let modesObject = await global.api.translateAppintmentModeArray(tempModesArray);
            //converting org.org_services into an array with enabled values, as translateappointmentmodearray does not allow true or false values

            tempModesArray = Object.keys(org.org_services).map(key => {
                return {key:key, enabled: org.org_services[key]}
            })

            //searches through available modes for corresponding id in org_services, as sometimes the order between the two are different
            for(let i = 0; i < modesObject.data.length; i++) {
                let index = tempModesArray.findIndex((mode) => (mode.key === modesObject.data[i]._id));

                if(index !== -1 && tempModesArray[index].enabled) {
                    if(pulledModes && pulledModes !== null && pulledModes.includes(tempModesArray[index].key)) {
                        arr2.push({id: modesObject.data[i]._id, name: modesObject.data[i].name, enabled: true})
                    } else{
                        arr2.push({id: modesObject.data[i]._id, name: modesObject.data[i].name, enabled: false})
                    } 
                }
            }

            setState({...state, walkInEnabled:walkInEnabled, services:arr, modes:arr2})
        }
    }

    React.useEffect(() => {
        pullServices();
    }, [props, global.state.selectedOrg, global.state.defaultOrg])

    const handleClose = () => {
        closeParentModal()
        setOpen(false);
    };

    const handleChangeModes = id => (event) => {
        if (id === undefined) {
            return;
        }
        let index = state.modes.findIndex(x => String(x.id) === String(id));
        if (index === -1) {
            return; //error
        } else {
            setState({...state, modes: [
               ...state.modes.slice(0,index),
               {id:state.modes[index].id, name:state.modes[index].name, enabled:event.target.checked},
               ...state.modes.slice(index+1)
            ],
          });
        }
    }

    const handleChangeServices = id => (event) => {
        if (id === undefined) {
            return;
        }
        let index = state.services.findIndex(x => String(x.id) === String(id));
        if (index === -1) {
            return; //error
        } else {
            setState({...state, services: [
               ...state.services.slice(0,index),
               {id:state.services[index].id, name:state.services[index].name, enabled:event.target.checked},
               ...state.services.slice(index+1)
            ],
          });
        }
    }

    const handleSave = async () => {
        //filter services for text array to upload to db, the same format as db
        const tempArr = []
        for(let i = 0; i < state.services.length; i++){
            if(state.services[i].enabled){
                tempArr.push(state.services[i].name)
            }
        }

        try {
            await global.api.updateProviderServices(org._id, _id, tempArr)
            const tempArr2 = []
            for(let i = 0; i < state.modes.length; i++){
                if(state.modes[i].enabled){
                    tempArr2.push(state.modes[i].id)
                }
            }
            await global.api.updateDoctorModes(org._id, _id, tempArr2)
            await global.api.updateProviderAllowWalkIns({
                orgId: org._id,
                doctorId: _id,
                allowWalkIns: state.walkInEnabled
        })} catch(error) {
            console.log("Appointment settings update error", error)
        }
        handleClose();
    }

    const handleChangeWalkIn = () => {
        setState({...state, walkInEnabled: !state.walkInEnabled})
    }

    return (
        <div>
            <ButtonBase className={props.className} onClick={handleClickOpen}> <Typography className={classes.typography}>Edit Appointment Setting</Typography></ButtonBase>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="xs"
            >
                <DialogTitle  className={classes.dialogTitle} style={{textAlign:"center",marginBottom:-40,marginTop:10,}} id="alert-dialog-slide-title">
                <IconButton  onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} /><h3>Edit Appointment Setting</h3> </DialogTitle>

                <DialogContent className={classes.content}>
                    <Grid container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Switch 
                                            checked={state.walkInEnabled}
                                            onChange={handleChangeWalkIn} 
                                            color="primary" 
                                            size='small'
                                            name="Accept Walk-In Patients"
                                        />
                                    }
                                    label="Accept&nbsp;Walk-In&nbsp;Patients"
                                    style={{color:"black"}}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className={classes.row}>
                            <Grid item xs={12} sm={6}>
                                <Typography style={{fontSize: 18,fontYeight: 700,marginBottom:-5}} className={classes.labelSmall}>Appointment Type</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.checkBoxMargin}>
                            {org && org.org_services && state && state.modes && state.modes.map(mode => (
                                <Grid container item xs={6}>
                                    <FormControlLabel
                                        key={mode.id}
                                        control={
                                            <Checkbox
                                                color={'primary'}
                                                checked={mode.enabled}
                                                onChange={handleChangeModes(mode.id)}
                                            />
                                        }
                                        label={<Typography style={{padding:"15px"}} className={classes.typography}>{mode.name}</Typography>}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        
                        <Grid container spacing={3} className={classes.row}>
                            <Grid item xs={12} sm={6}>
                                <Typography style={{fontSize: 18,fontYeight: 700}}className={classes.labelSmall}>Appointment Reason</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.checkBoxMargin}>
                            {org && org.services && state && state.services && state.services.map(service => (
                                <Grid container item xs={6}>
                                    <FormControlLabel
                                        key={service.id} control={<Checkbox color={'primary'} checked={service.enabled} onChange={handleChangeServices(service.id)} />}
                                        label={<Typography className={classes.typography}>{service.name}</Typography>}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button style={{fontWeight:700}} onClick={handleClose} color="primary" className={classes.actionBtn}>
                        Cancel
                    </Button>
                    <Button style={{fontWeight:700}} onClick={handleSave} color="primary" className={classes.actionBtn}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
