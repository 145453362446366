import { Box, Button, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from "../../../../context/GlobalContext";
import AccountProfileStyle from '../../../../styles/AccountProfileStyles';
import ConfirmPopup from './orginizationComponents/confirmPopup';
import MorePopup from './orginizationComponents/morePopup';






const Org = (props) => {
    const classes = AccountProfileStyle()
    const global = useContext(GlobalContext)
    const [defaultOrg, setDefaultOrg]=useState({})
    const [providerOrgs, setProviderOrgs]=useState([])
    const [openAlert, setOpenAlert] = useState(false);

    useEffect (()=>{
        global.api.getOrgById(props.userState.default_org).then(setDefaultOrg)
        Promise.all(props.userState.orgs.map(global.api.getOrgById)).then(allData => { setProviderOrgs(allData)})
        
        //ToDo this doesnt render on load. why?
        // for (let i=0; i<props.user.orgs.length; i++){
        //     global.api.getOrgById(props.user.orgs[i]).then((data)=>{temp.push(data)}).then(setProviderOrgs(temp))
        // }
        
    }, [])
    const result=[]
    
    //ToDo calling this onload when not needed and using hacky cancel trigger
    useEffect(() => { 
        global.api.getOrgById(props.userState.default_org).then(setDefaultOrg)
        Promise.all(props.userState.orgs.map(global.api.getOrgById)).then(allData => { setProviderOrgs(allData)})

    },[props.cancel])
    
    const setDefault = (e) => {
        const prevDefault=defaultOrg
        const newDefault=providerOrgs[e.currentTarget.id]
        setDefaultOrg(newDefault)
        const temp=[...providerOrgs]
        temp[e.currentTarget.id]=prevDefault
        setProviderOrgs(temp)
        const temp2=[]
        for (let i=0; i<temp.length; i++){
            temp2.push(temp[i]._id)
        }
        props.setUserState({...props.userState, default_org:newDefault._id, orgs:temp2})
        setOpenAlert(true)
        
    }

    const test=()=>{
        console.log(defaultOrg)
        global.setState((prev) => {
            return { ...prev, selectedOrg: defaultOrg }
        })
    }
    
    return(
        <>
        <ConfirmPopup setOpenAlert={setOpenAlert} openAlert={openAlert} defaultOrgName={defaultOrg.org_name} />
        <Box className={classes.spacingTopHeader}>
            <Typography className={classes.headingLabel}>My Organizations</Typography>
            <Button disableRipple className={classes.editLabel} >Join an Organization</Button>
        </Box>
        
        <Box className={classes.spreadHeader} >
            <Box>
                <Typography className={classes.headingLabel}>{defaultOrg.org_name}</Typography>
                <Typography>(Default Organization)</Typography>
            </Box>
            
            <MorePopup default={true}/>
        </Box>
        <Box className={classes.bodyMargin}>
            <Typography className={classes.subText}>{defaultOrg.info}</Typography>
        </Box>

        {providerOrgs.map((org,i)=>{
            return(
                <Box key={i} >
                    <Box className={classes.spreadHeader} >
                        <Box>
                            <Typography className={classes.headingLabel}>{org.org_name}</Typography>
                        </Box>
                        <MorePopup default={false} setDefault={setDefault} id={i} />
                    </Box>
                    <Box className={classes.bodyMargin}>
                        <Typography className={classes.subText}>{org.info}</Typography>
                    </Box>
                </Box>
            )
        })}


        {/* <Button onClick={()=>{console.log(global.state)}}>test</Button>
        <Button onClick={test}>testoing</Button> */}
        </>
    )


}

export default Org