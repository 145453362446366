import {
  Button,
  Divider,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";

export default function SidePanelComponent(props) {
  // const classes = props.classes;
  // const state = props.state;
  // const maxLength = props.maxLength;
  // const handleChange = props.handleChange;
  // const handleChangeText = props.handleChangeText;

  const {
    handleChangeDropdown,
    classes,
    state,
    maxLength,
    handleChange,
    handleChangeText,
  } = props;

  const [newsItems, setNewsItems] = useState([]);

  useEffect(() => {
    let theNewsItems = [];
    [1, 2, 3].forEach((serial) => {
      if (state[`title${serial}`] || state[`news${serial}`]) {
        theNewsItems.push({
          serial,
          title: state[`title${serial}`],
          content: state[`news${serial}`],
        });
      }
    });

    setNewsItems(theNewsItems);
  }, [state]);

  const addNewItem = () => {
    setNewsItems((curr) => {
      return [...curr, { serial: curr.length + 1, title: "", content: "" }];
    });
  };

  return (
    <div className="sidePanelTour">
      <Grid item xs={12} className={classes.paperComponment}>
        <Typography className={classes.labelSmall}>
          <strong>Side Panel Display</strong>
        </Typography>
        <Box className={classes.labelWithEdit}>
          <Typography className={classes.labelSmall}>Clinic Map</Typography>

          <Switch
            color="primary"
            size="small"
            className={classes.switch}
            checked={state.mapToggle === undefined ? false : state.mapToggle}
            onChange={handleChange}
            name="mapToggle"
            disabled={!state.isEdit}
          />
        </Box>
        <Box className={classes.labelWithEdit}>
          <Typography className={classes.labelSmall}>
            Allow File Upload
          </Typography>

          <Switch
            color="primary"
            size="small"
            className={classes.switch}
            checked={
              state.allowFileUpload === undefined
                ? false
                : state.allowFileUpload
            }
            onChange={handleChange}
            name="allowFileUpload"
            disabled={!state.isEdit}
          />
        </Box>
        {/*  allow Multiple Appointments toggle under apps/ book app setting */}
        <Box className={classes.labelWithEdit}>
          <Typography className={classes.labelSmall}>
            Allow Multiple Appointments
          </Typography>

          <Switch
            color="primary"
            size="small"
            className={classes.switch}
            checked={
              state.allowMultipleAppointments === undefined
                ? false
                : state.allowMultipleAppointments
            }
            onChange={handleChange}
            name="allowMultipleAppointments"
            disabled={!state.isEdit}
          />
        </Box>

        {/*  Require Patient Phone For SMS toggle under apps/ book app setting  */}
        <Box className={classes.labelWithEdit}>
          <Typography className={classes.labelSmall}>
            Require Patient Phone For SMS
          </Typography>

          <Switch
            color="primary"
            size="small"
            className={classes.switch}
            checked={
              state.requirePatientPhone === undefined
                ? false
                : state.requirePatientPhone
            }
            onChange={handleChange}
            name="requirePatientPhone"
            disabled={!state.isEdit}
          />
        </Box>

        <Box className={classes.labelWithEdit}>
          <Typography className={classes.labelSmall}>
            Enable Minimum Appointment Slot Interval
          </Typography>

          <Switch
            color="primary"
            size="small"
            className={classes.switch}
            checked={
              state.minimumSlotInterval === undefined
                ? false
                : state.minimumSlotInterval
            }
            onChange={handleChange}
            name="minimumSlotInterval"
            disabled={!state.isEdit}
          />
        </Box>

        <Box className={classes.subButton}>
          <FormControl className={classes.formControl}>
            <Select
              displayEmpty
              disableUnderline
              value={state !== undefined ? state.minimumInterval : 10}
              onChange={handleChangeDropdown}
              name="minimumInterval"
              className={classes.textList}
              placeholder="Minimum Appointment Slot Interval"
              inputProps={{
                "aria-label": "Without label",
                disableUnderline: true,
              }}
            >
              <option
                className={classes.optionList}
                value={state !== undefined ? state.minimumInterval : 10}
                hidden
              >
                {" "}
                Minimum Appointment Slot Interval{" "}
              </option>

              <MenuItem className={classes.optionList} value={10}>
                <Typography variant="body2" className={classes.labelText}>
                  10 hours
                </Typography>
              </MenuItem>

              <MenuItem className={classes.optionList} value={12}>
                <Typography variant="body2" className={classes.labelText}>
                  12 hours
                </Typography>
              </MenuItem>
              <MenuItem className={classes.optionList} value={15}>
                <Typography variant="body2" className={classes.labelText}>
                  15 hours
                </Typography>
              </MenuItem>
              <MenuItem className={classes.optionList} value={24}>
                <Typography variant="body2" className={classes.labelText}>
                  24 hours
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Grid item xs={12}>
          <Divider style={{ marginTop: "8px" }} />
        </Grid>
        <Typography className={classes.labelSmall}>Announcement Box</Typography>

        {newsItems.map((newsItem, idx) => {
          return (
            <Box key={newsItem.serial}>
              <Typography variant="body2" className={classes.labelText}>
                Title
              </Typography>
              <TextField
                InputProps={{ disableUnderline: true }}
                onChange={handleChangeText(newsItem.title)}
                value={newsItem.title}
                variant="outlined"
                fullWidth
                className={classes.textBox}
                placeholder="Enter Title"
                name={`title${newsItem.serial}`}
                size="small"
              />
              <Typography variant="body2" className={classes.labelText}>
                Content
              </Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                name={`news${newsItem.serial}`}
                rows={4}
                onChange={handleChangeText(newsItem.content)}
                value={newsItem.content}
                variant="outlined"
                className={classes.textBox}
                placeholder="Enter Message"
              />
              <div className={classes.textCounter}>
                {newsItem.content ? newsItem.content.length : 0}/{maxLength}
              </div>
            </Box>
          );
        })}

        {newsItems.length < 3 && (
          <Button
            variant="outlined"
            onClick={addNewItem}
            style={{
              color: "#003FBA",
              border: "1px solid #003FBA",
            }}
          >
            {newsItems.length > 0 ? "Add Another" : "Add Announcement"}
          </Button>
        )}
      </Grid>
    </div>
  );
}
