import { MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import BootstrapInput from '../../../../../../styles/BootstrapInput';

export default function UrlComponent(props) {
    const classes = props.classes;
    const state = props.state;
    const handleChange = props.handleChange;
    return (
        
        <Grid container spacing={3} className={classes.row}>
            <Grid item xs={12} sm={6}>
                <InputLabel className={classes.formLabel} shrink htmlFor="provider-input">
                    Provider
                </InputLabel>
                <FormControl fullWidth variant="outlined" className={classes.dropdown}>
                    <Select
                        fullWidth
                        name="provider"
                        value={state.provider}
                        onChange={(e) => handleChange(e)}
                        input={<BootstrapInput/>}
                        id="provider-input"
                        disabled={state && state.userList && !state.patientSelected}
                    >
                        {state && state.userList && state.userList.length !== 0 && state.userList.map(user => {
                            return (
                                <MenuItem key={user.id} className={classes.optionList} value={user.id}> {user.name} </MenuItem>
                            )
                        })}
        
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputLabel className={classes.formLabel} shrink htmlFor="provider-input">
                    Payment To
                </InputLabel>
                <FormControl fullWidth variant="outlined" className={classes.dropdown}>
                    <Select
                        fullWidth
                        name="payTo"
                        value={state.payTo}
                        onChange={(e) => handleChange(e)}
                        input={<BootstrapInput/>}
                        id="payTo-input"
                    >
                        {state && state.userList && state.userList.length !== 0 && state.userList.map(user => {
                            return (
                                <MenuItem key={user.id} className={classes.optionList} value={user.id}> {user.name} </MenuItem>
                            )
                        })}
                        {state && !state.patientSelected && state.userList && state.userList.length !== 0 && 
                            <MenuItem className={classes.optionList} value={state.userList.length}> Organization </MenuItem>
                        }
                    </Select>
                </FormControl>
            </Grid>
            
        </Grid>
    );
}

