/*
 * @Author: David Seiler
 * @Date: 2022-03-02
 */

import { Box, InputBase, Paper, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import GlobalContext from '../../context/GlobalContext';
import useStyles from '../../styles/sharedStyles';


export default function UserSearchBar({className, placeholder, autoComplete, setUsers, setSelectedUser, ...props}) {
    const classes = useStyles();

    const global = React.useContext(GlobalContext)

    const [searchText, setSearchText] = useState("");
    
    const [options, setOptions] = useState([]);
    const [loadUsers, setLoadUsers] = useState(true);
    const [value, setValue] = useState(null);


    useEffect(() => {
        if (loadUsers) {
          (async () => {
            let currentOrg;
            if (!global.state) return;
            if (!global.state || !global.state.selectedOrg || global.state.selectedOrg == null || JSON.stringify(global.state.selectedOrg) == "{}") {
                currentOrg = global.state.defaultOrg;
            } else {
                currentOrg = global.state.selectedOrg;
            }
            const providers = await global.api.searchUsersByOrg(currentOrg._id, "doctor", "", -1);
            const patients = await global.api.searchUsersByOrg(currentOrg._id, "patient", "", -1);
            if(providers && patients) {
                setOptions([...providers.data.data, ...patients.data.data]);
                setUsers([providers.data.data, ...patients.data.data]);
            }
            setLoadUsers(false);
          })();
        }
      }, [loadUsers]);
  

    const handleValueChange = (event, newValue) => {
        setValue(newValue);
        setSelectedUser(newValue);
  }

    const handleInputValueChange = async (event, newInputValue) => {
        setSearchText(newInputValue);
    }

    return (
        <Paper elevation={3} component="div" className={classes.root}>
            <Box className={classes.iconButton} aria-label="search">
                <SearchIcon />
            </Box>
            <Autocomplete
                id="custom-input-demo"
                options={searchText.length == 0 ? [] : options}
                className={classes.input}
                classes={{paper:classes.listBox, option:classes.listItem}}
                value={value}
                onChange={handleValueChange}
                inputValue={searchText}
                onInputChange={handleInputValueChange}
                autoHighlight
                noOptionsText={searchText.length == 0 ? "Oops your search bar is empty.." : "Sorry No Results"}
                getOptionLabel={(option) => option.contact_name ? option.contact_name : `${option.firstname} ${option.lastname}`}
                ListboxComponent={(listboxProps) => (
                        <ul {...listboxProps}
                        />
                    )}
                renderInput={(params) => (
                    <InputBase
                    fullWidth
                        placeholder={placeholder}
                        className={classes.input}
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps} />
                )}
                renderOption={(option) => {
                    let name = option.contact_name ? option.contact_name : `${option.firstname} ${option.lastname}`;
                    let role;
                    switch (option.roles[0]) {
                        case 'doctor':
                            role = 'Provider';
                            break;
                        case 'patient':
                            role = 'Patient';
                            break;
                        default:
                            role = '';
                            break;
                    }
                    return (
                        <React.Fragment>
                                <Typography className={classes.fullWidth}>
                                    <span className={classes.alignleft}>{name}</span>
                                    <span className={classes.alignright}>{role}</span>
                                </Typography>
                        </React.Fragment>
                    )
                }}
          />
        </Paper>
    );
};

UserSearchBar.propTypes = {
    value: PropTypes.string,
    users: PropTypes.array,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element,
		PropTypes.object,
	]),
	className: PropTypes.string,
    wrapperClass: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.bool,
};

UserSearchBar.defaultProps = {
    placeholder: 'Search Users',
}
