import {
  Box,
  Button,
  makeStyles,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Hidden,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MoreIcon from "@material-ui/icons/MoreHorizSharp";
import React, { useState } from "react";
import { SketchPicker } from "react-color";
import GlobalContext from "../../../../../context/GlobalContext";
import settingsStyles from "../../../../../styles/settingsPageStyles";
import clsx from "clsx";
import { AppointmentTypeReasonsEditForm } from "./AppointmentTypeReasonsEditForm";

const useStyles = makeStyles(() => ({
  inputFields: {
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  hoverColor: {
    "&:hover": {
      backgroundColor: "#D3E2FF",
    },
  },
  dropDown: {
    width: "190px",
    height: "48px",
  },
}));
export default function ServiceTypes({
  isEdit,
  state,
  setState,
  services,
  modeServiceMap,
  setModeServiceMap,
}) {
  const componentClasses = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedAppointmentType, setSelectedAppointmentType] = useState();

  const handleClick = (i) => (event) => {
    setAnchorEl(event.currentTarget);

    handleMenuClick(i);
  };

  const handleMenuClick = (index) => {
    setState({
      ...state,
      modes: [
        ...state.modes.slice(0, index),
        {
          id: state.modes[index].id,
          name: state.modes[index].name,
          type: state.modes[index].type,
          enabled: state.modes[index].enabled,
          description: state.modes[index].description,
          theme: state.modes[index].theme,
          menuOpen: !state.modes[index].menuOpen,
          index: state.modes[index].index,
        },
        ...state.modes.slice(index + 1),
      ],
    });
  };

  const classes = settingsStyles();
  const global = React.useContext(GlobalContext);

  let org = {};
  if (
    global.state.selectedOrg &&
    Object.keys(global.state.selectedOrg).length !== 0
  ) {
    org = global.state.selectedOrg;
  } else if (
    global.state.defaultOrg &&
    Object.keys(global.state.defaultOrg).length !== 0
  ) {
    org = global.state.defaultOrg;
  }

  let settingsPermission =
    global.state.user.document.roles.includes("admin") ||
    global.state.user.document.roles.includes("healthOrgAdmin");

  const handleChangeModes = (index) => (event) => {
    setState({
      ...state,
      modes: [
        ...state.modes.slice(0, index),
        {
          id: state.modes[index].id,
          description: state.modes[index].description,
          name: state.modes[index].name,
          type: state.modes[index].type,
          enabled: event.target.checked,
          index: state.modes[index].index,
          theme: state.modes[index].theme,
          menuOpen: state.modes[index].menuOpen,
        },
        ...state.modes.slice(index + 1),
      ],
    });
  };
  const confirmEditTheme = (index) => (event) => {
    if (index === undefined) {
      return;
    }
    if (
      state.tempTitle === "" ||
      state.tempDescription === "" ||
      state.tempId === ""
    ) {
      alert("No field can be empty");
      return;
    }
    //tests if there are any non-numeric numbers
    if (state.tempId !== null && /\D/.test(state.tempId)) {
      alert("Id must only contain numbers");
      return;
    }
    if (index >= state.modes.length && state.tempId) {
      let arr = state.modes;
      let tempId = state.tempId;
      for (let i = 0; i < state.modes.length; i++) {
        if (tempId === state.modes[i].id) {
          alert("Id cannot be the same as another appointment type");
          return;
        }
      }
      arr.push({
        id: tempId,
        name: state.tempTitle,
        description: state.tempDescription,
        type: state.tempType,
        enabled: false,
        theme: state.tempTheme,
        index: index,
        menuOpen: false,
      });
      setState({ ...state, modes: arr, editTheme: !state.editTheme });
      return;
    }
    setState({
      ...state,
      modes: [
        ...state.modes.slice(0, index),
        {
          id: state.modes[index].id,
          name: state.tempTitle,
          type: state.tempType,
          description: state.tempDescription,
          enabled: state.modes[index].enabled,
          theme: state.tempTheme,
          index: state.modes[index].index,
          menuOpen: state.modes[index].menuOpen,
        },
        ...state.modes.slice(index + 1),
      ],
      editTheme: !state.editTheme,
    });
  };

  const handleTextChange = (event) => {
    return setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleChangeComplete = (color) => {
    setState({ ...state, tempTheme: color.hex });
  };

  const cancelEditTheme = () => {
    setState({ ...state, editTheme: !state.editTheme });
  };
  const handleServiceChange = (map, modeId, serviceName) => {
    // Make a copy of the existing modeServiceMap
    const updatedModeServiceMap = { ...map };

    const modeServices = updatedModeServiceMap[modeId];

    const serviceIndex = modeServices.indexOf(serviceName);

    if (serviceIndex !== -1) {
      // Remove the service from the array
      modeServices.splice(serviceIndex, 1);
    } else {
      // Add the service to the array
      modeServices.push(serviceName);
    }

    // Update the modeServiceMap with the updated modeServices
    updatedModeServiceMap[modeId] = modeServices;

    setModeServiceMap(updatedModeServiceMap);
  };

  const editDetails = (mode, index) => {
    setSelectedAppointmentType(mode);
    setState({
      ...state,
      modes: [
        ...state.modes.slice(0, index),
        {
          id: state.modes[index].id,
          name: state.modes[index].name,
          type: state.modes[index].type,
          description: state.modes[index].description,
          enabled: state.modes[index].enabled,
          theme: state.modes[index].theme,
          menuOpen: !state.modes[index].menuOpen,
          index: state.modes[index].index,
        },
        ...state.modes.slice(index + 1),
      ],
      editTheme: true,
      tempId: null,
      index: state.modes[index].index,
      tempType: state.modes[index].type,
      tempTitle: state.modes[index].name,
      tempDescription: state.modes[index].description,
      tempTheme: state.modes[index].theme,
    });
  };

  const findNextAvailableModeId = (modeId) => {
    let numNewModeId = parseInt(modeId, 10);
    numNewModeId += 1;
  
    // Convert the number back to a string
    let strNewModeId = numNewModeId.toString();
  
    // Pad the string with leading zeros to ensure it has at least 3 characters
    while (strNewModeId.length < 3) {
      strNewModeId = '0' + strNewModeId;
    }
  
    return strNewModeId;
  }

  const addNewAppointmentType = () => {
    const sortedModes = [...state.modes].sort((a,b)=> {
      const aId = parseInt(a.id);
      const bId = parseInt(b.id);
      if(aId < bId) {
        return -1;
      } else if(aId > bId) {
        return 1;
      }
      return 0;
    });

    const newTempId = findNextAvailableModeId(sortedModes[sortedModes.length-1].id);

    setState({
      ...state,
      tempId: newTempId,
      editTheme: true,
      index: state.modes.length,
      tempTitle: "",
      tempDescription: "",
      tempType: "inPerson",
      tempTheme: "#FFFFFF",
    });
    // add this new id in the modeToService map
    setModeServiceMap({...modeServiceMap, [newTempId]: []})
    // pass the correct mode id to reason edit selector thingy,
    setSelectedAppointmentType({
      id: newTempId
    })
  };

  const removeMode = (i) => {
    const removedMode = state.modes[i];
    const removedModes = state.removedModes;

    removedModes.push(removedMode);
    setState({
      ...state,
      modes: [...state.modes.slice(0, i), ...state.modes.slice(i + 1)],
      removedModes: removedModes,
    });
  };

  const handleChangeDropdown = (event) => {
    return setState({ ...state, [event.target.name]: event.target.value });
  };

  return (
    <Box xs={12} className={classes.paperComponment}>
      <Grid container xs={12}>
        <Grid item xs={11}>
          <Box className={classes.labelWithEdit}>
            <Typography className={classes.labelWithAdd}>
              {" "}
              Appointment Type{" "}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          {settingsPermission && [
            <Button
              className={classes.appointmentTypeButton}
              color="primary"
              onClick={addNewAppointmentType}
            >
              ADD&nbsp;
              <Hidden mdDown>APPOINTMENT&nbsp;TYPE</Hidden>
            </Button>,
          ]}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {state &&
          state.modes &&
          state.modes.map((mode, index) => (
            <Grid container key={index} style={{ marginLeft: "7px" }}>
              <Grid item xs={11}>
                <FormControlLabel
                  key={mode.id}
                  control={
                    <Checkbox
                      color={!isEdit ? "#767676" : "primary"}
                      checked={mode.enabled}
                      onChange={handleChangeModes(mode.index)}
                    />
                  }
                  label={
                    <Box className={classes.appointmentModeSampleBox}>
                      <Box
                        className={classes.appointmentModeSampleColor}
                        bgcolor={mode.theme}
                      />
                      <Typography className={classes.typography}>
                        {mode.name}
                      </Typography>
                    </Box>
                  }
                />
              </Grid>
              <Grid item xs={1}>
                {settingsPermission && (
                  <>
                    <IconButton
                      className={clsx(
                        classes.dropDownSelectSpacing,
                        componentClasses.hoverColor
                      )}
                      onClick={handleClick(index)}
                    >
                      <MoreIcon />
                    </IconButton>
                    <Menu
                      classes={{ list: classes.menu }}
                      open={state.modes[index].menuOpen}
                      onClose={() => handleMenuClick(index)}
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        className={classes.menuItem}
                        onClick={() => editDetails(mode, index)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        className={classes.menuItem}
                        onClick={() => removeMode(index)}
                      >
                        Remove
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Grid>
            </Grid>
          ))}
        {state.editTheme && (
          <Paper elevation={3} className={classes.smallContainer}>
            <Box xs={12} className={classes.editUrlBox}>
              {state && state.tempId !== null ? (
                <>
                  <Typography className={classes.labelSmallerSpacing}>
                    New Appointment Type
                  </Typography>
                  <Typography variant="body2" className={classes.labelText}>
                    ID for new Appointment Type
                  </Typography>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    onChange={handleTextChange}
                    value={state.tempId ? state.tempId : ""}
                    variant="outlined"
                    fullWidth
                    className={classes.textBox}
                    placeholder="Enter Id"
                    name="tempId"
                    size="small"
                    disabled
                  />
                </>
              ) : (
                <Typography className={classes.labelSmallerSpacing}>
                  Edit Appointment Type
                </Typography>
              )}
              <Typography variant="body2" className={classes.labelText}>
                Title
              </Typography>
              <TextField
                InputProps={{ disableUnderline: true }}
                onChange={handleTextChange}
                value={state.tempTitle ? state.tempTitle : ""}
                variant="outlined"
                fullWidth
                className={classes.textBox}
                placeholder="Enter Title"
                name="tempTitle"
                size="small"
              />
              <Typography variant="body2" className={classes.labelText}>
                Type
              </Typography>
              <FormControl className={classes.formControl}>
                <Select
                  displayEmpty
                  disableUnderline
                  value={state && state.tempType}
                  onChange={handleChangeDropdown}
                  name="tempType"
                  className={classes.textList}
                  inputProps={{
                    "aria-label": "Without label",
                    disableUnderline: true,
                  }}
                  defaultValue={"inPerson"}
                >
                  <MenuItem className={classes.optionList} value={"inPerson"}>
                    {" "}
                    In Person
                  </MenuItem>
                  <MenuItem className={classes.optionList} value={"voice"}>
                    {" "}
                    Voice{" "}
                  </MenuItem>
                  <MenuItem className={classes.optionList} value={"video"}>
                    {" "}
                    Video{" "}
                  </MenuItem>
                  <MenuItem className={classes.optionList} value={"report"}>
                    {" "}
                    Report{" "}
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography variant="body2" className={classes.labelText}>
                Description
              </Typography>
              <TextField
                InputProps={{ disableUnderline: true }}
                onChange={handleTextChange}
                value={state.tempDescription ? state.tempDescription : ""}
                variant="outlined"
                fullWidth
                className={classes.textBox}
                placeholder="Description of Appointment Type"
                name="tempDescription"
                size="small"
              />
              <Typography variant="body2" className={classes.labelText}>
                Reason
              </Typography>
              <AppointmentTypeReasonsEditForm
                handleServiceChange={handleServiceChange}
                servicesToModeMap={modeServiceMap}
                allOrgReasons={services}
                modeToEdit={selectedAppointmentType}
              />

              <Typography variant="body2" className={classes.labelText}>
                Colour
              </Typography>
              <Box className={classes.labelWithEdit}>
                <SketchPicker
                  color={state.tempTheme}
                  onChangeComplete={handleChangeComplete}
                  width="100%"
                />
              </Box>
              <Grid container justify={"space-between"}>
                <Button
                  color="primary"
                  className={classes.subButton}
                  onClick={cancelEditTheme}
                  disabled={!isEdit}
                >
                  <Typography variant="body2">CANCEL</Typography>
                </Button>
                <Button
                  color="primary"
                  className={classes.subButton}
                  onClick={confirmEditTheme(state.index)}
                  disabled={!isEdit}
                >
                  <Typography variant="body2">SAVE CHANGES</Typography>
                </Button>
              </Grid>
            </Box>
          </Paper>
        )}
      </Grid>
    </Box>
  );
}
