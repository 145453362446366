/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-10 02:32:33
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-07-10 03:13:31
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import MicIcon from "@material-ui/icons/Mic";
import PhoneIcon from "@material-ui/icons/Phone";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../../../src/context/GlobalContext";
import combineStyles from "../../../../styles/combineStyles";
import popupStyles from "../../../../styles/PopupStyle";
import UserPageStyles from "../../../../styles/usersPageStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSendEmail } from "../../../../SendEmail";

let adminAppUri = process.env.REACT_APP_PORTON_ADMIN_API || "/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const combinedStyles = combineStyles(popupStyles, UserPageStyles);

function ContactButton({ info, ...props }) {
  const [state, setState] = React.useState({
    selected: "email",
    value: info.email || "",
    currentUploadedFile: null,
    title: "",
    content: "",
  });
  const globalState = props.globalState;
  const global = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const id = open ? "simple-popover" : undefined;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { sendEmail, EMAIL_STATES, emailState } = useSendEmail({
    email: state.value,
    subject: state.title,
    content: state.content,
    emailType: "contactEmail",
    attachmentMetadata: state.currentUploadedFile,
    otherBodyProps: {
      sentFrom: "Admin",
      logo: global.state.selectedOrg.logo,
      orgName: global.state.selectedOrg.org_name,
    },
  });

  useEffect(() => {
    switch (emailState) {
      case EMAIL_STATES.LOADING:
        setIsLoading(true);
        break;
      case EMAIL_STATES.SUCCESS:
        alert("Message have been sent successfully");
        setIsLoading(false);
        setState({
          ...state,
          value: info.email ? info.email : "",
          currentUploadedFile: null,
          title: "",
          content: "",
        });
        handleClose();
        break;
      case EMAIL_STATES.ERROR:
        alert("Error sending message");
        setIsLoading(false);
        break;
    }
  }, [emailState]);

  const handleChange = (event) => {
    setState({ ...state, value: event.target.value });
  };
  const fileUploadButton = async (event) => {
    setState({ ...state, currentUploadedFile: event.target.files[0] });
    event.target.value = null;
  };
  const viewFile = () => {
    window.open(URL.createObjectURL(state.currentUploadedFile));
  };

  const downloadFile = () => {
    let link = document.createElement("a");
    link.href = URL.createObjectURL(state.currentUploadedFile);
    link.download = state.currentUploadedFile.name;
    link.click();
  };

  const handleTextChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const orgNotifications = { email: true, phone: true, voice: true };

  const classes = combinedStyles();

  React.useEffect(() => {
    setState({
      ...state,
      selected: "email",
      value: info.email ? info.email : "",
      currentUploadedFile: null,
      title: "",
      content: "",
    });
  }, [globalState, props.info]);
  return (
    <Box>
      <Button
        aria-describedby={id}
        variant="contained"
        className={classes.colorButton}
        onClick={handleClickOpen}
      >
        Contact
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="alert-dialog-slide-title"
        >
          Contact {info.contact_name}
          <IconButton
            onClick={handleClose}
            className={classes.dialogClose}
            children={<ClearIcon />}
          />{" "}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container>
            <Grid item xs={12}>
              <Grid className={classes.notification}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item md={2} xs={4}>
                    {orgNotifications.email ? (
                      <Button
                        className={classes.notifButton}
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          setState({
                            ...state,
                            selected: "email",
                            value: info.email ? info.email : "",
                          });
                        }}
                      >
                        <EmailIcon />{" "}
                        <Typography className={classes.text}>Email</Typography>
                      </Button>
                    ) : (
                      <Button
                        className={classes.notifButton}
                        disabled
                        fullWidth
                        variant="outlined"
                      >
                        <EmailIcon />{" "}
                        <Typography className={classes.disabledText}>
                          Email
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                  <Grid item md={2} xs={4}>
                    {orgNotifications.phone ? (
                      <Button
                        className={classes.notifButton}
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          setState({
                            ...state,
                            selected: "phone",
                            value: info.phone ? info.phone : "",
                          });
                        }}
                      >
                        <PhoneIcon />{" "}
                        <Typography className={classes.text}>SMS</Typography>
                      </Button>
                    ) : (
                      <Button
                        className={classes.notifButton}
                        disabled
                        fullWidth
                        variant="outlined"
                      >
                        <PhoneIcon />{" "}
                        <Typography className={classes.disabledText}>
                          SMS
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                  <Grid item md={2} xs={4}>
                    {orgNotifications.voice ? (
                      <Button
                        className={classes.notifButton}
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          setState({
                            ...state,
                            selected: "voice",
                            value: info.phone ? info.phone : "",
                          });
                        }}
                      >
                        <MicIcon />{" "}
                        <Typography className={classes.text}>Voice</Typography>
                      </Button>
                    ) : (
                      <Button
                        className={classes.notifButton}
                        disabled
                        fullWidth
                        variant="outlined"
                      >
                        <MicIcon />{" "}
                        <Typography className={classes.disabledText}>
                          Voice
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div className={classes.input}>
                      <div className={classes.icon}>
                        {state.selected === "voice" ? (
                          <MicIcon />
                        ) : state.selected === "phone" ? (
                          <PhoneIcon />
                        ) : (
                          <EmailIcon />
                        )}
                      </div>
                      {state.selected === "voice" ||
                      state.selected === "phone" ? (
                        <InputBase
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                          className={classes.typeSpacing}
                          fullWidth
                          name={state.selected}
                          value={state.value}
                          onChange={handleChange}
                          type={state.selected === "email" ? "email" : "text"}
                          // inputProps={{
                          //     inputComponent: NumberFormatCustom,
                          // }}
                        />
                      ) : (
                        <InputBase
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                          fullWidth
                          inputProps={{ "aria-label": "search" }}
                          name={state.selected}
                          value={state.value}
                          onChange={handleChange}
                          type={state.selected === "email"}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.row}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelText}>
                  Subject
                </Typography>
                <TextField
                  InputProps={{ disableUnderline: true }}
                  variant="outlined"
                  fullWidth
                  className={classes.textBox}
                  placeholder="Subject example"
                  onChange={handleTextChange}
                  value={state.title}
                  name="title"
                  size="small"
                  color="#222222"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.row}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelText}>
                  Content
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  name="content"
                  rows={5}
                  value={state.content}
                  onChange={handleTextChange}
                  variant="outlined"
                  className={classes.textBox}
                  placeholder="Write your message here"
                />
              </Grid>
            </Grid>
            {state.selected === "email" && (
              <Grid container spacing={3} className={classes.row}>
                {/* <Grid item xs={12}>
                  <input
                    accept=".pdf"
                    className={classes.hideInput}
                    multiple
                    id="fileButtonContact"
                    onChange={fileUploadButton}
                    type="file"
                  />
                  <label htmlFor="fileButtonContact">
                    <Button
                      variant="contained"
                      component="span"
                      className={classes.fileUpload}
                      startIcon={<AddIcon />}
                    >
                      Upload File
                    </Button>
                  </label>

                  {state && state.currentUploadedFile && (
                    <Card className={classes.fileManagement}>
                      <Box className={classes.iconButtonContainer}>
                        <Typography className={classes.fileButtonText}>
                          {state.currentUploadedFile.name}
                        </Typography>
                        <IconButton aria-label="View" onClick={viewFile}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          aria-label="Download"
                          onClick={downloadFile}
                        >
                          <GetAppIcon />{" "}
                        </IconButton>
                        <IconButton
                          aria-label="Delete"
                          onClick={() =>
                            setState({ ...state, currentUploadedFile: null })
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Card>
                  )}
                </Grid> */}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.actionBtn}
          >
            Cancel
          </Button>
          {isLoading ? (
            <CircularProgress
              style={{ margin: "1px", color: "#1766b3", marginRight: "20px" }}
            />
          ) : (
            <Button
              onClick={sendEmail}
              color="primary"
              className={classes.actionBtn}
            >
              Send
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ContactButton;
