import _ from "lodash";
import { useCallback, useContext, useState } from "react";
import GlobalContext from "./context/GlobalContext";

let adminAppUri = process.env.REACT_APP_PORTON_ADMIN_API || "/api";
let portonAdminAppBaseURL = process.env.REACT_APP_PORTON_ADMIN_API;
if (!process.env.REACT_APP_PORTON_ADMIN_API) {
    portonAdminAppBaseURL = "/api";
}

let AdminAppServerUrl = {
    contactEmail: `${adminAppUri}/auth/sendContactMessage`,
    patientActionEmail: `${portonAdminAppBaseURL}/auth/sendNewMessage`,
    patientInviteEmail: `${portonAdminAppBaseURL}/auth/sendPatientInviteMessage`,
    appointmentReminderEmail: `${portonAdminAppBaseURL}/appointment/sendAppointmentReminder`,
    forgotPassword: `${portonAdminAppBaseURL}/auth/forgotPassword`,
};

const getFileAttachment = (fileMetadata) => {
    return new Promise((resolve, reject) => {
        if (!fileMetadata) {
            resolve([]);
        }
        const reader = new FileReader();
        reader.readAsDataURL(fileMetadata);
        reader.addEventListener("load", (event) => {
            if (event?.target?.result) {
                resolve([{ path: event.target.result }]);
            }
            reject(new Error("Error while reading file"));
        });
    });
};

const getSmtpSettings = (globalContextObj) => {
    const emailNotification = _.get(
        globalContextObj,
        "state.selectedOrg.communicationSetting.notification_setting.email",
        false
    );
    const smtpSettingsFromDB = _.get(
        globalContextObj,
        "state.selectedOrg.communicationSetting.smtp_setting",
        null
    );

    if (emailNotification && smtpSettingsFromDB) {
        return {
            host: smtpSettingsFromDB.smtpserver,
            port: smtpSettingsFromDB.smtpport,
            secure: true,
            auth: {
                user: smtpSettingsFromDB.smtpuser,
                pass: smtpSettingsFromDB.smtppassword,
            },
        };
    } else return null;
};

export const useSendEmail = ({
    email,
    subject,
    content,
    emailType,
    otherBodyProps,
    attachmentMetadata,
}) => {
    const EMAIL_STATES = {
        IDLE: "idle",
        LOADING: "loading",
        SUCCESS: "success",
        ERROR: "error",
    };
    const [emailState, setEmailState] = useState(EMAIL_STATES.IDLE); // states: idle | loading | success | error
    const global = useContext(GlobalContext);
    const { logo, org_name: orgName } = global.state.selectedOrg;

    const sendEmail = useCallback(async () => {
        try {
            const attachment = await getFileAttachment(attachmentMetadata);
            setEmailState(EMAIL_STATES.LOADING);
            let url = AdminAppServerUrl[emailType];

            const body = JSON.stringify({
                email,
                subject,
                content,
                sentFrom: "Admin",
                attachments: attachment,
                smtpSetting: getSmtpSettings(global),
                ...otherBodyProps,
            });

            const response = await fetch(`${url}`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body,
            });

            if (response.ok) {
                setEmailState(EMAIL_STATES.SUCCESS);
            } else {
                setEmailState(EMAIL_STATES.ERROR);
            }
        } catch (err) {
            setEmailState(EMAIL_STATES.ERROR);
        }
    }, [email, subject, content, otherBodyProps, attachmentMetadata]);

    return { sendEmail, EMAIL_STATES, emailState };
};

export const useSendSecureEmail = ({
    email,
    subject,
    content,
    emailType,
    org,
    otherBodyProps,
    attachmentMetadata,
}) => {
    const EMAIL_STATES = {
        IDLE: "idle",
        LOADING: "loading",
        SUCCESS: "success",
        ERROR: "error",
    };
    const [emailState, setEmailState] = useState(EMAIL_STATES.IDLE); // states: idle | loading | success | error
    const global = useContext(GlobalContext);
    const { logo, org_name: orgName } = global.state.selectedOrg;

    const sendEmail = useCallback(async () => {
        try {

            const attachment = await getFileAttachment(attachmentMetadata);
            setEmailState(EMAIL_STATES.LOADING);
            let url = AdminAppServerUrl[emailType];
            const emailNotification = _.get(
                org,
                "communicationSetting.notification_setting.email",
                false
            );


            const smtpSettings = _.get(
                org,
                "communicationSetting.smtp_setting",
                null
            );
            let smtpSetting;

            if (emailNotification && smtpSettings) {
                smtpSetting = {
                    host: smtpSettings.smtpserver,
                    port: smtpSettings.smtpport,
                    secure: true,
                    auth: {
                        user: smtpSettings.smtpuser,
                        pass: smtpSettings.smtppassword,
                    },
                };
            }

            const body = JSON.stringify({
                email,
                subject,
                content,
                sentFrom: "Admin",
                attachments: attachment,
                smtpSetting: smtpSetting,
                ...otherBodyProps,
            });

            const response = await fetch(`${url}`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body,
            });

            if (response.ok) {
                setEmailState(EMAIL_STATES.SUCCESS);
            } else {
                setEmailState(EMAIL_STATES.ERROR);
            }
        } catch (err) {
            setEmailState(EMAIL_STATES.ERROR);
        }
    }, [email, subject, content, otherBodyProps, attachmentMetadata]);

    return { sendEmail, EMAIL_STATES, emailState };
};

export const useSendInviteEmail = () => {
    const EMAIL_STATES = {
        IDLE: "idle",
        LOADING: "loading",
        SUCCESS: "success",
        ERROR: "error",
    };
    const [emailState, setEmailState] = useState(EMAIL_STATES.IDLE); // states: idle | loading | success | error

    const global = useContext(GlobalContext);
    const { logo, org_name: orgName } = global.state.selectedOrg;

    let booking_url = `${process.env.REACT_APP_BOOK_APP}/${orgName}`;
    // let booking_url = `${process.env.REACT_APP_BOOKAPP_BASE_URL}${orgName}`;


    booking_url = booking_url.replace(/\/$/, "");

    const sendEmail = useCallback(
        async ({ email, contactName, subject, content }) => {
            try {
                setEmailState(EMAIL_STATES.LOADING);
                let url = AdminAppServerUrl.patientInviteEmail;

                const body = JSON.stringify({
                    email,
                    contactName,
                    subject,
                    content,
                    sentFrom: "Admin",
                    smtpSetting: getSmtpSettings(global),
                    booking_url,
                    logo,
                    orgName,
                });

                const response = await fetch(`${url}`, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body,
                });

                if (response.ok) {
                    setEmailState(EMAIL_STATES.SUCCESS);
                } else {
                    setEmailState(EMAIL_STATES.ERROR);
                }
            } catch (err) {
                setEmailState(EMAIL_STATES.ERROR);
            }
        },
        []
    );

    return { sendEmail, EMAIL_STATES, emailState };
};
