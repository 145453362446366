import { Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import settingsStyles from "../../../../../styles/settingsPageStyles";

const PhoneSetting = ({ phoneSetting, setPhoneSetting }) => {
  const classes = settingsStyles();

  const handleChangeText = (event) => {
    setPhoneSetting({
      ...phoneSetting,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Grid container className={classes.section}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 600 }} className={classes.labelSmall}>
          SMS/Voice API Settings
        </Typography>
        <Typography className={classes.labelMedSmall}>
          Set up your SMS/Voice API settings to send messages using a phone
          number of your preference
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.labelText}>
          Outgoing(Clinic)Number
        </Typography>
        <TextField
          InputProps={{ disableUnderline: true }}
          onChange={handleChangeText}
          variant="outlined"
          value={phoneSetting.sms_voice_number || ""}
          fullWidth
          className={classes.textBox}
          placeholder="Outgoing Clinic Number"
          name="sms_voice_number"
          size="small"
        />
        <Typography variant="body2" className={classes.labelText}>
          Twilio Account SID
        </Typography>
        <TextField
          InputProps={{ disableUnderline: true }}
          onChange={handleChangeText}
          variant="outlined"
          value={phoneSetting.twilio_account_sid || ""}
          fullWidth
          className={classes.textBox}
          placeholder="Twilio Account SID"
          name="twilio_account_sid"
          size="small"
        />
        <Typography variant="body2" className={classes.labelText}>
          Twilio Auth Token
        </Typography>
        <TextField
          InputProps={{ disableUnderline: true }}
          onChange={handleChangeText}
          variant="outlined"
          value={phoneSetting.twilio_auth_token || ""}
          fullWidth
          className={classes.textBox}
          placeholder="Twilio Auth Token"
          name="twilio_auth_token"
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export default PhoneSetting;
