import {
  Box,
  Grid, Typography
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import settingsStyles from '../../../../../styles/settingsPageStyles';




export default function ServiceTypes({ 
    isEdit,
    state,
    setState,
  }
  ) {

  const classes = settingsStyles();
  const global = React.useContext(GlobalContext)
  
  let org = {}
  if (global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0) {
      org = global.state.selectedOrg
  } else if (global.state.defaultOrg && Object.keys(global.state.defaultOrg).length !== 0) {
      org = global.state.defaultOrg
  }
  const handleChangeModes = index => (event) => {
    
      setState({...state, modes: [
          ...state.modes.slice(0,index),
          {id:state.modes[index].id, description:state.modes[index].description, name:state.modes[index].name, theme:state.modes[index].theme, enabled:event.target.checked},
          ...state.modes.slice(index+1)
      ],
      
      });
    
  }
  return (
    <Box xs={12} className={classes.paperComponment}>
      <Grid container >
          <Grid item xs={11}>
            <Box className={classes.labelWithEdit}>
              <Typography className={classes.labelWithAdd}>Appointment Type</Typography>
            </Box>
          </Grid>
      </Grid>
      <Grid container className={classes.containerSpacing}>
          {state && state.modes && state.modes.map((mode, i) => (
              <Grid container key={i}>
                <Grid container item xs={10}>
                    <FormControlLabel
                        key={mode.id} control={<Checkbox color={!isEdit ? '#767676' : 'primary'} checked={mode.enabled} onChange={handleChangeModes(i)} />}
                        label={
                          
                          <Box className={classes.appointmentModeSampleBox}>
                            <Box className={classes.appointmentModeSampleColor} bgcolor={mode.theme}/> 
                            <Typography className={classes.typography}>{mode.name}</Typography>
                          </Box>
                          
                        }
                    />
                </Grid>
                
              </Grid>
          ))}
         
      </Grid>
    </Box>

  );
}