import {
    Card,
    CardContent,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PatientActionEmailForm from "./PatientActionEmailForm";
import PatientActionReminderEmailForm from "./PatientActionReminderEmailForm";
import PatientActionsLayout from "./PatientActionsLayout";
import GlobalContext from "../../context/GlobalContext";


let portonAdminAppBaseURL = process.env.REACT_APP_PORTON_ADMIN_API;
  if (!process.env.REACT_APP_PORTON_ADMIN_API) {
    portonAdminAppBaseURL = "/api";
  }

const PatientActions = () => {
    const { orgId, patientHin } = useParams();
    const global = React.useContext(GlobalContext);


    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: "90%",
            margin: "0 auto",
        },
        typography: {
            color: "#003FBA",
            fontWeight: 800,
            margin: 20,
            fontSize: "20px",
            lineheight: "23px",
            fontFamily: "Roboto",
        },
        typographyBtn: {
            color: "#444444",
            fontWeight: 600,
            margin: 25,
            fontSize: "20px",
            lineheight: "23px",
            textAlign: "center",
            marginBottom: "-7px",
            fontFamily: "Roboto",
        },
        containerCard: {
            width: "70%",
        },
        actionBtn: {
            float: "right",
            margin: 10,
            marginBottom: 10,
            border: "1px solid #003FBA",
        },
        loading: {
            fontSize: 20,
            textAlign: "center",
            marginLeft: "250px",
            justifyContent: "center",
            fontFamily: "Roboto",
        },
        patientInfo: {
            color: "black",
            fontWeight: 800,
            textalign: "center",
            margin: 30,
            padding: "10px 150px",
            fontSize: "20px",
            marginBottom: "23px",
            fontFamily: "Roboto",
        },
        Header: {
            color: "#444444",
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "38px",
            fontWeight: "900",
            marginBottom: "7px",
            "@media only screen and (max-width:674px)": {
                fontSize: "20px",
            },
            "@media only screen and (max-width:320px)": {
                fontSize: "17px",
            },
        },
        Header1: {
            color: "#E57627",
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "130px",
            fontWeight: "700",
            marginBottom: "0px",
            "@media only screen and (max-width:674px)": {
                fontSize: "20px",
            },
            "@media only screen and (max-width:320px)": {
                fontSize: "17px",
            },
        },
        mainBody: {
            minHeight: "70vh",
            padding: "35px",
        },
        SubTitle: {
            color: "black",
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "18px",
            "@media only screen and (max-width:674px)": {
                fontSize: "15px",
            },
        },
    }));

    const classes = useStyles();
    const [isError, setIsError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [patient, setPatient] = useState({});
    const [orgDetails, setOrgDetails] = useState({});
    const [org, setOrg] = useState({});
    const [nextAppointment, setNextAppointment] = useState({});
    const [emailFormOpen, setEmailFormOpen] = useState(false);
    const [emailReminderFormOpen, setEmailReminderFormOpen] = useState(false);

    const showEmailForm = () => {
        setEmailFormOpen(true);
    };

    const handleEmailFormClose = useCallback(() => {
        setEmailFormOpen(false);
    }, [setEmailFormOpen]);

    const showEmailReminderForm = () => {
        setEmailReminderFormOpen(true);
    };

    const handleEmailReminderFormClose = useCallback(() => {
        setEmailReminderFormOpen(false);
    }, [setEmailReminderFormOpen]);
    
    useEffect(() => {
        const getOrgDetails = async () => {
          try {
            const { data } = await global.api.getOrgById(orgId);
            setOrgDetails(data);
          } catch (err) {
            console.log("error in getting org details", err);
          }
        };
        getOrgDetails();
      }, [global, global.api]);
    
    useEffect(() => {
        const fetchPatient = async () => {
            setIsError(false);
            setIsLoading(true);

            try {
                const response = await fetch(
                    `${portonAdminAppBaseURL}/patient/${orgId}/${patientHin}`
                );

                if (!response.ok) {
                    throw new Error("something went wrong");
                }

                const patientDataJson = await response.json();

                setPatient(patientDataJson);
            } catch (err) {
                setIsError(true);
                setPatient({});
            }
            setIsLoading(false);
        };

        const fetchOrg = async () => {
            if (isError) {
                // already in error state, dont do anything
                return;
            }

            setIsLoading(true);

            try {
                const response = await fetch(
                    `${portonAdminAppBaseURL}/orgs/${orgId}`
                );

                if (!response.ok) {
                    throw new Error("something went wrong");
                }

                const orgDataJson = await response.json();

                setOrg(orgDataJson);
            } catch (err) {
                setIsError(true);
                setOrg({});
            }
            setIsLoading(false);
        };

        fetchPatient();
        fetchOrg();
    }, [orgId, patientHin]);

    useEffect(() => {
        const fetchNextAppointment = async () => {
            if (isError || !patient?._id || !org?._id) {
                // already in error state, or patient/org are not yet fetched, dont do anything
                return;
            }

            const now = new Date();
            setIsLoading(true);
            try {
                const response = await fetch(
                    `${portonAdminAppBaseURL}/appointment/search?patientId=${
                        patient._id
                    }&orgId=${org._id}&startDate=${now.toISOString()}`,
                    {
                        method: "get",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("something went wrong");
                }

                const appointmentDataJson = await response.json();

                if (
                    !appointmentDataJson?.length ||
                    appointmentDataJson.length < 1
                ) {
                    throw new Error("No appointment");
                }

                // TODO - sort the appointmentDataJson by "start" (Date) field, before taking the first element as the next appointment.

                setNextAppointment(appointmentDataJson[0]);
            } catch (err) {
                console.log("Something went wrong, ", err);
                setIsError(true);
            }
            setIsLoading(false);
        };

        fetchNextAppointment();
    }, [patient, org, isError]);

    if (isError) {
        return (
            <PatientActionsLayout>
                <div>
                    <Fragment>
                        <main id="main" className={classes.mainBody}>
                            <Card>
                                <CardContent>
                                    <Typography
                                        variant="body2"
                                        component="h1"
                                        className={classes.Header1}
                                        color="warning"
                                    >
                                        404
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="h5"
                                        className={classes.Header}
                                    >
                                        Page Not Found
                                    </Typography>
                                    <Typography
                                        component="p"
                                        className={classes.SubTitle}
                                    >
                                        Invalid Patient / Org or there are no
                                        appointments for this patient.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </main>
                    </Fragment>
                </div>
            </PatientActionsLayout>
        );
    }

    return (
        <PatientActionsLayout>
            <div>
                <Typography
                    md={{ padding: "10px 150px" }}
                    className={classes.patientInfo}
                >
                    {patient?.firstname} {patient?.lastname}
                     {/*<br />({patientHin})*/}
                </Typography>
                <Container className={classes.containerCard}>
                    <Card className={classes.root}>
                        {isLoading ? (
                            <div
                                style={{
                                    minHeight: 90,
                                    justifyContent: "center",
                                }}
                            >
                                <span>
                                    <strong className={classes.loading}>
                                        {" "}
                                        Loading ...... Please Wait
                                    </strong>
                                </span>
                                <CircularProgress md={{ display: "flex" }} />
                            </div>
                        ) : (
                            <CardContent>
                                <Typography
                                    variant="h5"
                                    component="h4"
                                    className={classes.typography}
                                >
                                    Forms and Contact
                                </Typography>
                                <Divider />
                                   
                                    <RadioGroup
                                    
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="Send reminder for next appointment"
                                        name="radio-buttons-group"
                                    >
                                         <FormLabel
                                        className={classes.typographyBtn}
                                        id="demo-radio-buttons-group-label"
                                    >
                                        Please pick from one of the follwing choices
                                    </FormLabel>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                style={{ display: "flex" }}
                                                xs={12}
                                            >
                                                {/* <Button
                                            color="primary"
                                            style={{
                                                backgroundColor: "#003FBA",
                                                color: "white",
                                            }}
                                        >
                                            Send OSCAR eForm <strong></strong>
                                        </Button> */}
                                            </Grid>

                                            <Grid
                                                item
                                                style={{ display: "flex" }}
                                                xs={12}
                                            >
                                                <FormControlLabel
                                                    color="primary"
                                                    onClick={() =>
                                                        showEmailForm()
                                                    }
                                                    className={classes.btn}
                                                    value="Send secure email"
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    label="Send secure email"
                                                    style={{
                                                        margin: "0 auto",
                                                        width: "38%",
                                                    }}
                                                >
                                                </FormControlLabel>
                                                <PatientActionEmailForm
                                                    open={emailFormOpen}
                                                    handleClose={
                                                        handleEmailFormClose
                                                    }
                                                    patient={patient}
                                                    orgDetails={orgDetails}
                                                    nextAppointment={nextAppointment}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                style={{ display: "flex" }}
                                                xs={12}
                                            >
                                                <FormControlLabel
                                                    color="primary"
                                                    value="Send reminder for next appointment"
                                                    onClick={
                                                        showEmailReminderForm
                                                    }
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    label="Send reminder for next appointment
                                            "
                                                    style={{
                                                        margin: "0 auto",
                                                        width: "38%",

                                                    }}
                                                >
                                                </FormControlLabel>
                                                <PatientActionReminderEmailForm
                                                    open={emailReminderFormOpen}
                                                    handleClose={
                                                        handleEmailReminderFormClose
                                                    }
                                                    patient={patient}
                                                    org={org}
                                                    nextAppointment={
                                                        nextAppointment
                                                    }
                                                />
                                            </Grid>
                                             {  nextAppointment.mode == "Video" &&
                                                typeof nextAppointment.links !=
                                                    "undefined" &&
                                                nextAppointment.links.patient &&
                                                nextAppointment.links.patient !=
                                                    null && (
                                                    <Grid
                                                        item
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                        xs={12}
                                                    >
                                                        <FormControlLabel
                                                            color="primary"
                                                            value="Resend MeetApp video link "
                                                            control={
                                                                <Radio color="primary" />
                                                            }
                                                            onClick={
                                                                showEmailReminderForm
                                                            }
                                                            label=" Resend MeetApp video link"
                                                            style={{
                                                                margin: "0 auto",
                                                                width: "38%",
                                                            }}
                                                        >
                                                        </FormControlLabel>
                                                    </Grid>
                                                 )} 
                                        </Grid>
                                    </RadioGroup>
                            </CardContent>
                        )}
                    </Card>
                </Container>
            </div>
        </PatientActionsLayout>
    );
};
export default PatientActions;
