/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-09 06:56:31
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-10-08 02:50:46
 * @Description: All the API calls the global states stores here.
 */
import axios from "axios";
import React from "react";
import GlobalContext from "../context/GlobalContext";
import DrawerContainer from "./DrawerMenuContainer";
import _ from "lodash";
import Cookies from "js-cookie";

axios.defaults.headers.common["X-CSRF-Token"] = Cookies.get("sessionId");

export default function GlobalContainer(props) {
  const baseURL = process.env.REACT_APP_PORTON_API;
  let PortonAdminAppBaseURL = process.env.REACT_APP_PORTON_ADMIN_API;
  if (!process.env.REACT_APP_PORTON_ADMIN_API) {
    PortonAdminAppBaseURL = "/api";
  }

  const [globalState, setGlobalState] = React.useState({
    defaultOrg: {},
    selectedOrg: {},
    reminderSettings: {},
  });

  axios.defaults.headers.common["Default-ID"] = _.get(
    globalState,
    "defaultOrg._id",
    null
  );
  //adminApp tutorial for help page
  const [tourOpen, setTourOpen] = React.useState(false);

  // This state gets updated when a new notification arrives, triggering an update of the queue
  const [emrCount, setEmrCount] = React.useState(false);

  //Headers:{Authorization: `Bearer ${accessToken}}
  const Global = GlobalContext;
  const [API] = React.useState({
    login: async function (email, password) {
      let result = await axios
        .post(`${PortonAdminAppBaseURL}/auth/login`, {
          email: email,
          password: password,
        })
        .catch((e) => {
          if (e.response && e?.response?.status) {
            return { status: e?.response?.status, error: e };
          } else {
            return { status: 500, error: e };
          } //Error example always need a field for status code and a field for other error messages
        });

      if (result && result.status === 200) {
        const roles = _.get(result, "data.user.document.roles[0]", null);
        if (
          roles &&
          (roles === "clinic" ||
            roles === "doctor" ||
            roles === "oa" ||
            roles === "moa")
        ) {
          return { status: 500, error: { message: "Unable to login" } };
        } else {
          localStorage.setItem("token", result.data.token);
          setGlobalState((prev) => {
            return {
              ...prev,
              login: true,
              defaultOrg: result.data.default_org,
              user: result.data.user,
            };
          });
          return { status: 200, token: result.data.token }; //Success example comes with a status field and other informations
        }
      } else {
        return result;
      }
    },
    verifyPassword: async function (email, password) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `verifyPassword`,
          data: {
            email: email,
            password: password,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        } //Error example always need a field for status code and a field for other error messages
      });

      return result;
    },

    sendEmailVerificationCodeEmail: async function (email) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `sendEmailVerificationCodeEmail`,
          data: {
            email: email,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        } //Error example always need a field for status code and a field for other error messages
      });

      return result;
    },

    verifyEmailVerificationCode: async function (email, verificationCode) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `verifyEmailVerificationCode`,
          data: {
            email: email,
            verificationCode: verificationCode,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        } //Error example always need a field for status code and a field for other error messages
      });

      return result;
    },

    tokenLogin: async function () {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `tokenlogin`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        setGlobalState((prev) => {
          return {
            ...prev,
            login: true,
            defaultOrg: result.data.defaultOrg.document,
            user: result.data.user,
          };
        });
        return { status: 200, token: result.data.token }; //Success example comes with a status field and other informations
      } else {
        setGlobalState((prev) => {
          return { ...prev, login: false };
        });
        localStorage.removeItem("token");
        return result;
      }
    },

    sendMessage: async (
      selected,
      sendTo,
      file,
      title,
      content,
      sentFrom,
      contactName
    ) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `sendMessage`,
          data: {
            sendTo: sendTo,
            selected: selected,
            file: file,
            title: title,
            content: content,
            sentFrom: sentFrom,
            contactName: contactName,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        } //Error example always need a field for status code and a field for other error messages
      });

      return result;
    },

    sendRegisterationEmail: async (sendTo, theName, password) => {
      let result = await axios
        .post(`${PortonAdminAppBaseURL}/auth/sendRegisterationEmail`, {
          sendTo: sendTo,
          subject: "Registration",
          theName: theName,
          password: password,
        })
        .catch((e) => {
          if (e.response && e?.response?.status) {
            return { status: e?.response?.status, error: e };
          } else {
            return { status: 500, error: e };
          } //Error example always need a field for status code and a field for other error messages
        });

      if (result && result.status === 200) {
        return { status: 200, data: result.data }; //Success example comes with a status field and other informations
      } else {
        return result;
      }
    },
    // createAccount: async (user_data) => {
    //   let url;
    //   if (user_data.role)
    //     url = `${PortonAdminAppBaseURL}/auth/createUserAccount`;
    //   else url = `${PortonAdminAppBaseURL}/auth/createAccount`;
    //
    //   let result = await axios
    //     .post(url, { user_data: user_data })
    //     .catch((e) => {
    //       if (e.response && e?.response?.status) {
    //         return { status: e?.response?.status, error: e.response.data };
    //       } else {
    //         return { status: 500, error: e };
    //       } //Error example always need a field for status code and a field for other error messages
    //     });
    //
    //   if (result && result.status === 200) {
    //     return { status: 200, data: result.data }; //Success example comes with a status field and other informations
    //   } else {
    //     return result;
    //   }
    // },

    retrieveFilesFromSessionId: async (data) => {
      let result = await axios
        .post(`${PortonAdminAppBaseURL}/file/retrieveFilesFromSessionId`, {
          data,
        })
        .catch((e) => {
          if (e.response && e?.response?.status) {
            return { status: e?.response?.status, error: e.response.data };
          } else {
            return { status: 500, error: e };
          } //Error example always need a field for status code and a field for other error messages
        });

      if (result && result.status === 200) {
        return { status: 200, data: result.data }; //Success example comes with a status field and other informations
      } else {
        return result;
      }
    },

    getOrgDetails: async (orgId) => {
      try {
        const response = await axios.get(
          `${PortonAdminAppBaseURL}/orgs/${orgId}/getReport`
        );
        console.log("response",response)
        const status = _.get(response, "status", 500);
        return { status, data: response.data };
      } catch (error) {
        if (error.response && error.response.status) {
          return { status: error.response.status, error: error.response.data };
        } else {
          return {
            status: 500,
            error: error.message || "Internal Server Error",
          };
        }
      }
    },

    sendCreateAccountEmail: async (user_data) => {
      let result = await axios
        .post(`${PortonAdminAppBaseURL}/auth/sendCreateAccountEmail`, {
          user_data,
        })
        .catch((e) => {
          if (e.response && e?.response?.status) {
            return { status: e?.response?.status, error: e };
          } else {
            return { status: 500, error: e };
          } //Error example always need a field for status code and a field for other error messages
        });

      if (result && result.status === 200) {
        return { status: 200, data: result.data }; //Success example comes with a status field and other informations
      } else {
        return result;
      }
    },

    fetchDoctorList: async (orgID, timeout) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "appointment",
          path: `doctorList`,
          data: {
            params: { org: orgID },
            timeout: timeout,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        const returnResult = { ...result, status: 400 };
        return returnResult;
      }
    },

    fetchAppointments: async (orgID, date, timeout) => {
      let result = await axios
        .get(`${PortonAdminAppBaseURL}/appointment/getAppointments`, {
          params: { org: orgID, date: date },
          timeout: timeout,
          headers: {
            "auth-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
          },
        })
        .catch((e) => {
          if (e.response && e?.response?.status) {
            return { status: e?.response?.status, error: e };
          } else {
            return { status: 500, error: e };
          }
        });

      if ((result = 200)) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    // does not work and same thing with disable email
    enableAccountEmail: async (email, contact_name, logo) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `sendEnableEmail`,
          data: {
            email: email,
            contact_name: contact_name,
            logo: logo,
          },
        },

        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if ((result = 200)) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    sendNewMessage: async (email, subject, content, sentFrom, patientName) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `sendNewMessage`,
          data: {
            email: email,
            content: content,
            subject: subject,
            sentFrom: sentFrom,
            contactName: patientName,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        } //Error example always need a field for status code and a field for other error messages
      });

      return result;
    },

    sendInviteEmail: async (
      firstname,
      lastname,
      email,
      org,
      org_name,
      logo,
      password
    ) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `sendInviteEmail`,
          data: {
            firstname: firstname,
            lastname: lastname,
            email: email,
            org: org,
            org_name: org_name,
            logo: logo,
            password: password,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        } //Error example always need a field for status code and a field for other error messages
      });

      return result;
    },

    sendUnlinkFromOrgEmail: async (
      first_name,
      last_name,
      email,
      org_name,
      org_email,
      contact_info,
      logo
    ) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `sendUnlinkFromOrgEmail`,
          data: {
            firstname: first_name,
            lastname: last_name,
            email: email,
            org_name: org_name,
            adminEmail: org_email,
            adminPhone: contact_info,
            logo: logo,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        } //Error example always need a field for status code and a field for other error messages
      });

      return result;
    },

    // does not work
    sendDisableEmail: async (email, contactname, logo) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `sendDisableEmail`,
          data: {
            contactname: contactname,
            email: email,
            logo: logo,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    registerOrg: async (org_name, email, password) => {
      let result = await axios
        .post(`${PortonAdminAppBaseURL}/auth/registerOrg`, {
          org_name: org_name,
          email: email,
        })
        .catch((e) => {
          if (e.response && e?.response?.status) {
            return { status: e?.response?.status, error: e };
          } else {
            return { status: 500, error: e };
          } //Error example always need a field for status code and a field for other error messages
        });

      if (result && result.status === 200) {
        return { status: 200, data: result.data }; //Success example comes with a status field and other informations
      } else {
        return result;
      }
    },

    registerOrgGroup: async (orgArray) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `registerOrgGroup`,
          data: {
            orgArray: orgArray,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        } //Error example always need a field for status code and a field for other error messages
      });

      return result;
    },

    register: async (
      firstname,
      lastname,
      phone,
      email,
      password,
      org,
      role
    ) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `register`,
          data: {
            firstname: firstname,
            lastname: lastname,
            email: email,
            password: password,
            phone: phone,
            org: org,
            role: role,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return {
            status: e?.response?.status,
            error: e.response.data.error,
          };
        } else {
          return { status: 500, error: e };
        } //Error example always need a field for status code and a field for other error messages
      });

      return result;
    },

    registerGroup: async (userArray, org, role) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/auth/registerGroup`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          userArray: userArray,
          org: org,
          role: role,
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data }; //Success example comes with a status field and other informations
      } else {
        return result;
      }
    },

    logout: async function () {
      try {
        const response = await axios({
          method: "post",
          url: `${PortonAdminAppBaseURL}/proxy`,
          data: {
            method: "get",
            resource: "auth",
            path: "logout",
          },
          headers: {
            "auth-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
          },
        });
        // Check if the response has a status code
        if (response && response.status) {
          // If the response has a status code, update the global state
          setGlobalState((prev) => {
            return { ...prev, login: false };
          });
          // Remove token from localStorage
          localStorage.removeItem("token");
          // Return response status
        }
      } catch (error) {
        // Handle axios request errors
        if (error.response && error.response.status) {
          // If error response has a status code, return it
          return { status: error.response.status, error: error };
        } else {
          // If no status code found in the error response, return a generic error
          return { status: 500, error: error };
        }
      }
    },

    listOrgs: async function () {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "orgs",
          path: `list`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    searchOrg: async function (query = " ", limit = 5) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "orgs",
          path: `search?q=${query}&limit=${limit}`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    //unused?
    getAppointments: async function (orgId, patientId, doctorId) {
      let result = await axios({
        method: "get",
        url: ` ${PortonAdminAppBaseURL}/appointment/search`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          orgId: orgId,
          patientId: patientId,
          doctorId: doctorId,
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        result.data.map((v) => {
          v.id = v._id;
        });
        return { status: 200, data: result.data };
      } else {
        const returnResult = { ...result, status: 400 };
        return returnResult;
      }
    },

    // does not work. Calendar page.
    getAppintmentById: async function (id) {
      if (!id) {
        return { status: 500, error: "id is required" };
      }
      let result = await axios({
        method: "get",
        url: ` ${PortonAdminAppBaseURL}/appointment/${id}`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          with: "doctor,patient",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        const returnResult = { ...result, status: 400 };
        return returnResult;
      }
    },

    getPatientsByOrgs: async function (orgId, page, perpage) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "orgs",
          path: `${orgId}/patients`,
          params: {
            page: page,
            limit: perpage || 6,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        //return result incase of no patients
        if (!result.data.status) return result.data;
        return { status: 200, data: result.data.data };
      } else {
        const returnResult = { ...result, status: 400 };
        return returnResult;
      }
    },

    getDoctorsByOrgs: async function (orgId, page, perpage) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "get",
          resource: "orgs",
          path: `${orgId}/doctors`,
          params: {
            page: page || 1,
            limit: perpage || 6,
          },
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        //return result incase of no doctors
        if (!result.data.status) return result.data;
        return { status: 200, data: result.data.data };
      } else {
        const returnResult = { ...result, status: 400 };
        return returnResult;
      }
    },

    searchUsersByOrg: async function (orgId, role, query, limit, page) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "get",
          resource: "orgs",
          path: `${orgId}/searchusers`,
          params: {
            role: role,
            q: query,
            page: page || 1,
            limit: limit || 6,
          },
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        const returnResult = { ...result, status: 400 };
        return returnResult;
      }
    },

    searchImportedProvidersByOrg: async function (
      orgId,
      role,
      query,
      limit,
      page
    ) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "orgs",
          path: `${orgId}/searchimportedproviders`,
          params: {
            role: role,
            q: query,
            page: page || 1,
            limit: limit || 6,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        const returnResult = { ...result, status: 400 };
        return returnResult;
      }
    },

    getOrgById: async function (orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "orgs",
          path: `${orgId}`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    translateAppintmentModeArray: async function (array) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "modes",
          path: `array-trans`,
          data: {
            array: array,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    removeFromModeList: async function (modeInfoArr) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "post",
          resource: "modes",
          path: `removeFromModeList`,
          data: {
            modeInfoArr: modeInfoArr,
          },
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    findAllModes: async function () {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "modes",
          path: `findAllModes`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    addToModeList: async function (modeInfoArr) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "post",
          resource: "modes",
          path: `addToModeList`,
          data: {
            modeInfoArr: modeInfoArr,
          },
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateModesList: async function (modeInfoArr) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "post",
          resource: "modes",
          path: `updateModesList`,
          data: {
            modeInfoArr: modeInfoArr,
          },
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    // does not work. Patient calendar.
    initModeCollection: async function () {
      let result = await axios({
        method: "get",
        url: `${PortonAdminAppBaseURL}/modes/init`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    // does not work, add appoiment in user's page
    createAppointment: async function (
      patientId,
      doctorId,
      orgId,
      date,
      start,
      duration,
      patientEmail,
      patientPhone,
      info,
      mode,
      reason,
      reschedule,
      patientUtcOffset
    ) {
      let data = {
        patientId: patientId,
        doctorId: doctorId,
        orgId: orgId,
        date: date,
        start: start,
        duration: duration,
        patientEmail: patientEmail,
        patientPhone: patientPhone,
        info: info,
        mode: mode,
        reason: reason,
        reschedule: reschedule,
        patientUtcOffset: patientUtcOffset,
      };
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/appointment/createAppointment`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: data,
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    setAppointmentBlockCode: async function (orgId, code) {
      //example for using JWT in the header
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/setAppointmentBlockCode`,
          data: {
            appointmentBlockCode: code,
          },
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    UpdateReminderSettings: async function (orgId, settings) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/UpdateReminderSettings`,
          data: {
            reminderSettings: settings,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    UpdateCheckInSettings: async function (orgId, settings) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/UpdateCheckInSettings`,
          data: {
            checkInSettings: settings,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    UpdateBookAppSettings: async function (orgId, settings) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/UpdateBookAppSettings`,
          data: {
            bookAppSettings: settings,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    UpdatePatientAppSettings: async function (orgId, settings) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/UpdatePatientAppSettings`,
          data: {
            patientAppSettings: settings,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateAppSettingsJanitor: async function (orgId, settings) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateAppSettingsJanitor`,
          data: {
            appSettingsJanitor: settings,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateEnableDisableApps: async function (orgId, settings) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateEnableDisableApps`,
          data: {
            settings: settings,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    UpdateVirtualAppSettings: async function (orgId, settings) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/UpdateVirtualAppSettings`,
          data: {
            virtualAppSettings: settings,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    UpdateTextInDb: async function (orgId, text, name) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/UpdateTextInDb`,
          data: {
            text: text,
            name: name,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    UpdateNews: async function (orgId, message, title, name) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/UpdateNews`,
          data: {
            message: message,
            title: title,
            name: name,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateInfoDisplay: async function (orgId, settings) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateInfoDisplay`,
          data: {
            infoDisplaySettings: settings,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateDoctorMessage: async function (orgId, doctors) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateDoctorMessage`,
          data: {
            doctors: doctors,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateOrgLogo: async function (orgId, logo) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateOrgLogo`,
          data: {
            logo,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateOrgProfileSettigs: async function (orgId, data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateOrgProfileSettigs`,
          data: { settings: data },
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    updateDisableOrg: async function (orgId, disabled) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateDisableOrg`,
          data: { disabled },
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    updateOrgThemeColor: async function (orgId, themeColor) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateOrgThemeColor`,
          data: { themeColor },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    // AWS functions require exra header authentication
    DeleteFileFromAws: async function (data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/file/delete`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          Key: data.Key,
          BUCKET_NAME: process.env.REACT_APP_S3_BUCKETNAME
            ? process.env.REACT_APP_S3_BUCKETNAME
            : "porton-shared",
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    // UploadFileToAws: async function (data) {
    //   let result = await axios({
    //     method: "post",
    //     url: `${PortonAdminAppBaseURL}/file/upload`,
    //     data,
    //     headers: {
    //       "auth-token": localStorage.getItem("token"),
    //       "Access-Control-Allow-Origin": "*",
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //   }).catch((e) => {
    //     return { status: 900, error: e }; //e.response.status
    //   });

    //   if (result.status === 200) {
    //     return { status: 200, data: result.data };
    //   } else {
    //     return result;
    //   }
    // },

    DownloadFileFomAws: async function (data) {
      let result = await axios({
        method: "get",
        url: `${PortonAdminAppBaseURL}/file/download`,
        params: data,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).catch((e) => {
        return { status: 900, error: e }; //e.response.status
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    GetEnvVariable: async function (data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "auth",
          path: `getEnvVariable`,
          params: data,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateAppointmentStatus: async function (data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "appointment",
          path: `updateAppointmentsStatus`,
          data: data,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    createNewEmrIntegration: async function (orgId, data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/createNewEmrIntegration`,
          data,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        return { status: 900, error: e };
      });
      return result;
    },

    deleteEmrIntegration: async function (orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "delete",
          resource: "orgs",
          path: `${orgId}/deleteEmrIntegration`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    getEmrIntegrationRequestTokens: async function (orgId, data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/getEmrIntegrationRequestTokens`,
          data,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        return { status: 900, error: e };
      });
      return result;
    },

    updateEmrIntegrationAccessTokens: async function (orgId, data) {
      let result = await axios({
        method: "put",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "put",
          resource: "orgs",
          path: `${orgId}/updateEmrIntegrationAccessTokens`,
          data,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        return { status: 900, error: e };
      });
      return result;
    },

    createSoapAuth: async function (orgId, data) {
      let result = await axios({
        method: "put",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "put",
          resource: "orgs",
          path: `${orgId}/createSoapAuth`,
          data,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        return { status: 500, error: e };
      });
      return result;
    },

    importRightNow: async function (orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "orgs",
          path: `${orgId}/importRightNow`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    sendImportCompletedEmail: async (sendTo, clinicName) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `sendImportCompletedEmail`,
          data: {
            sendTo: sendTo,
            clinicName: clinicName,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    sendProviderRegisterationEmail: async (
      providerUsername,
      providerLastname,
      providerPassword
    ) => {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `sendProviderRegisterationEmail`,
          data: {
            sendTo: providerUsername,
            subject: `Registration for Dr.${providerLastname}`,
            emailContent: {
              password: providerPassword,
              lastName: providerLastname,
              username: providerUsername,
            },
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    //unused?
    updateAppointment: async function (data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "appointment",
          path: `updateAppointment`,
          data: data,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    // //Unused? Found on calendar page.
    // getAppointments: async function (data) {
    //   let result = await axios({
    //     method: "get",
    //     url: `${PortonAdminAppBaseURL}/appointment/getAppointments`,
    //     params: data,
    //     headers: {
    //       "auth-token": localStorage.getItem("token"),
    //       "Access-Control-Allow-Origin": "*",
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //   }).catch((e) => {
    //     return { status: 900, error: e };
    //   });
    //   if (result.status === 200) {
    //     return { status: 200, data: result.data };
    //   } else {
    //     return result;
    //   }
    // },

    getAppointmentByQueue: async function (orgID, Date, Timeout) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "appointment",
          path: `getAppointments`,
          params: { org: orgID, date: Date },
          timeout: Timeout,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    getAddress: async function (orgID, demographicNo) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "appointment",
          path: `getAddress`,
          params: { org: orgID, demo: demographicNo },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    // fetchDoctorList: async function (orgID, timeout) {
    //   let result = await axios({
    //     method: "post",
    //     url: `${PortonAdminAppBaseURL}/proxy`,
    //     data: {
    //       method: "get",
    //       resource: "appointment",
    //       path: `doctorList`,
    //       params: { org: orgID },
    //       timeout: timeout,
    //     },
    //     headers: {
    //       "auth-token": localStorage.getItem("token"),
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   }).catch((e) => {
    //     if (e.response && e?.response?.status) {
    //       console.log({ status: e?.response?.status, error: e });
    //       return { status: e?.response?.status, error: e };
    //     } else {
    //       return { status: 500, error: e };
    //     }
    //   });
    //   if (result && result.status === 200) {
    //     return { status: 200, data: result.data };
    //   } else {
    //     const returnResult = { ...result, status: 400 };
    //     console.log("error result", returnResult);
    //     return returnResult;
    //   }
    // },

    updateAppointmentSettings: async function (orgId, settings) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/UpdateAppointmentSettings`,
          data: settings,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    sendNotification: async function (data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "appointment",
          path: `sendNotification`,
          params: data,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    createAppointmentService: async function (orgId, services) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/createAppointmentService`,
          data: services,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response) {
          return { status: e?.response?.status, error: e };
        }
      });

      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    updateAppointmentService: async function (orgId, services) {
      let result = await axios({
        method: "put",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "put",
          resource: "orgs",
          path: `${orgId}/updateAppointmentService`,
          data: services,
        },
      }).catch((e) => {
        if (e.response) {
          return { status: e?.response?.status, error: e };
        }
      });

      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    deleteAppointmentService: async function (orgId, service) {
      let result = await axios({
        method: "delete",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "delete",
          resource: "orgs",
          path: `${orgId}/deleteAppointmentService`,
          data: service,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response) {
          return { status: e?.response?.status, error: e };
        }
      });

      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    updateServiceCodes: async function (orgId, serviceCodes) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateServiceCodes`,
          data: {
            serviceCodes: serviceCodes,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response) {
          return { status: e?.response?.status, error: e };
        }
      });

      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    updateProviderServices: async function (orgId, provider, services) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/${provider}/updateProviderServices`,
          data: { services: services },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateDoctorModes: async function (orgId, doctorId, modes) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/${doctorId}/updateDoctorModes`,
          data: { modes: modes },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    getProviderDoctorByID: async function (doctor, orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/getProviderDoctorByID`,
          data: { doctor: doctor },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateProviderAllowWalkIns: async function ({
      orgId,
      doctorId,
      allowWalkIns,
    }) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/doctors/${doctorId}/updateProviderAllowWalkIns`,
          data: { allowWalkIns },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    // cannot covert to proxy.
    fetchStatuses: async function (data) {
      let result = await axios({
        method: "get",
        url: `${PortonAdminAppBaseURL}/appointment/fetchStatuses`,
        params: { org: data },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).catch((e) => {
        return { status: 900, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    getScheduleTemplateCode: async function (orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "appointment",
          path: `getScheduleTemplateCode/${orgId}`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateAppointmentStatuses: async function (orgId, statuses) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateStatuses`,
          data: statuses,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response) {
          return { status: e?.response?.status, error: e };
        }
      });

      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    updateAppointmentStatusCodes: async function (orgId, statuses) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateStatusCodes`,
          data: statuses,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response) {
          return { status: e?.response?.status, error: e };
        }
      });

      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    updateProviderModes: async function (orgId, modes) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/orgs/${orgId}/updateProviderModes`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          modes: modes,
        },
      }).catch((e) => {
        if (e.response) {
          return { status: e?.response?.status, error: e };
        }
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },
    // cannot convert the caches to proxy.
    postCache: async function (data) {
      try {
        let result = await axios({
          method: "post",
          url: `${PortonAdminAppBaseURL}/appointment/postCache`,
          params: data,
          headers: {
            "auth-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (result) return { status: 200, result: result };
      } catch (error) {
        return { status: 500, error: error };
      }
    },
    fetchAccountSettings: async function (orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "orgs",
          path: `${orgId}/fetchAccountSettings`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    getCache: async function (data) {
      try {
        let result = await axios({
          method: "get",
          url: `${PortonAdminAppBaseURL}/appointment/getCache`,
          params: data,
          headers: {
            "auth-token": localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (result) return { status: 200, result: result };
      } catch (error) {
        return { status: 500, error: error };
      }

      /* let result = await axios(
        {
          method: "post",
          url: `${PortonAdminAppBaseURL}/proxy`,
          // url: `${PortonAdminAppBaseURL}/orgs/${orgId}/searchimportedproviders`,
          data:{
            method: "get",
            resource: "appointment",
            path: `getCache`,
            data:{
              params: data
            }
          },
          headers: {
            "auth-token": localStorage.getItem("token"),
            'Access-Control-Allow-Origin': '*'
          },
        }
      ).catch((e) => {
        if (e.response && e?.response?.status) {
          console.log({ status: e?.response?.status, error: e })
          return { status: e?.response?.status, error: e }
        } else {
          return { status: 500, error: e }
        }
      })

      if (result && result.status === 200) {
        return { status: 200, data: result.data};
      } else {
        const returnResult = { ...result, status: 400 }
        console.log("error result", returnResult)
        return returnResult
      }*/
    },

    encryptPatientEmails: async function (data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "user",
          path: `encryptPatientEmails`,
          data: data,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateAccount: async function (user) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `updateAccount`,
          data: user,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateAccountSettings: async function (orgId, accountSettings) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `updateAccountSettings`,
          data: { org_id: orgId, account_settings: accountSettings },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },
    updateNotificationSettings: async function (orgId, email, browser) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/updateNotificationSettings`,
          data: { orgId: orgId, email: email, browser: browser },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    removeAccount: async function (user) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `removeAccount`,
          data: user,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    // may not load
    getDoctorsFromEmr: async function (id) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "get",
          resource: "appointment",
          path: `doctorList`,
          params: { org: id },
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        //return result incase of no doctors
        if (!result.data.status) return result.data;
        return { status: 200, data: result.data.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    test: async function () {
      //example for using JWT in the header

      let result = await axios({
        method: "get",
        url: `${PortonAdminAppBaseURL}/auth/test`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        alert("you have successfully make a request with JWT");
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    //does not work
    forgotPassword: async function (email) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/auth/forgotPassword`,
        data: email,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    //does not work because of change pass
    resetTokenValidate: async function (params) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "auth",
          path: `reset/${params}`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    //does not work
    changePass: async function (info) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/auth/changePassword`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: info,
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    removeOrg: async function (orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `removeOrg`,
          data: orgId,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    forceResetPass: async function (data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "auth",
          path: `forceReset`,
          data: data,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    checkForceReset: async function (data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "post",
          resource: "auth",
          path: `checkForceReset`,
          data: data,
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    getPullAppOrganizationReports: async function (orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "appointment",
          path: `orgReport/${orgId}`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    turnOffSendRegistrationEmail: async function (providerId, orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "delete",
          resource: "orgs",
          path: `${orgId}/${providerId}/turnOffSendRegistrationEmail`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    removeTemporaryPassword: async function (providerId, orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "delete",
          resource: "orgs",
          path: `${orgId}/${providerId}/removeTemporaryPassword`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        const returnResult = { ...result, status: 400 };
        return returnResult;
      }
    },

    savePullAppAutomationSetting: async function (orgId, pullAppAutomation) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/savePullAppAutomationSetting`,
          data: {
            pullAppAutomation,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    getPullAppAutomationSetting: async function (orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "orgs",
          path: `${orgId}/getPullAppAutomationSetting`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },
    //routing: Api -> pull -> pullController
    setAutoPullAppToggle: async function (orgId, toggles) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "pull",
          path: `${orgId}/setAutoPull`,
          data: toggles,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    //routing: Api -> pull -> pullController
    getAutoPullAppToggle: async function (orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "pull",
          path: `${orgId}/getAutoPull`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });

      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        const returnResult = { ...result, status: 400 };
        return returnResult;
      }
    },

    saveIntegrationComplete: async function (orgId, integrationComplete) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/saveIntegrationComplete`,
          data: integrationComplete,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    getIntegrationComplete: async function (orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "orgs",
          path: `${orgId}/getIntegrationComplete`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    getEmrPatientByDemoNo: async function (orgId, demographic_no) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "get",
          resource: "orgs",
          path: `${orgId}/patient/${demographic_no}/`,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    updateCommunicationSetting: async function (orgId, setting) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/communicationSetting`,
          data: setting,
        },
      }).catch((e) => {
        return { status: e?.response?.status, error: e };
      });
      if (result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return result;
      }
    },

    decryptSetting: async function (setting) {
      try {
        let result = await axios.post(
          `${PortonAdminAppBaseURL}/orgs/decryptSetting`,
          setting
        );
        if (result && result.status == 200 && result.data) return result.data;
        else {
          return {};
        }
      } catch (error) {
        return {};
      }
    },

    updateOperationHours: async function (data) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `updateOperationHours`,
          data: data,
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },

    unlinkProviderFromOrg: async function (providerId, orgId) {
      let result = await axios({
        method: "post",
        url: `${PortonAdminAppBaseURL}/proxy`,
        data: {
          method: "post",
          resource: "orgs",
          path: `${orgId}/${providerId}/unlinkProviderFromOrg`,
          data: {
            providerId: providerId,
            orgId: orgId,
          },
        },
        headers: {
          "auth-token": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
        },
      }).catch((e) => {
        if (e.response && e?.response?.status) {
          return { status: e?.response?.status, error: e };
        } else {
          return { status: 500, error: e };
        }
      });
      if (result && result.status === 200) {
        return { status: 200, data: result.data };
      } else {
        return { ...result, status: 400 };
      }
    },
  });
  return (
    <Global.Provider
      value={{
        api: API,
        state: globalState,
        count: emrCount,
        tourOpen: tourOpen,
        setCount: setEmrCount,
        setState: setGlobalState,
        setTourOpen: setTourOpen,
      }}
    >
      <DrawerContainer />
    </Global.Provider>
  );
}

//module.exports = typeof self == 'object' ? self.FormData : window.FormData;
