
import React from 'react'
import {withRouter, useHistory} from "react-router-dom";
import Reactour from "reactour";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Typography, Box } from '@material-ui/core';
import HelpStyles from '../../../styles/HelpStyles'

//used in drawermenu inside the router so it is able to go to different paths

const Tour = withRouter(
  ({ isOpen, closeTour, location: { pathname }, history }) => {
    const classes = HelpStyles()

    const usehistory=useHistory()
    
    const tourTab = (title, description) =>{
      return(
      <Box>
        <Typography className={classes.heading}>{title}</Typography>
        <Box className={classes.tourMargin}>
          <Typography>{description}</Typography>
        </Box>
      </Box>
      )


    }

    const disableBody = target => {disableBodyScroll(target)}
    const enableBody = (target) => {
      enableBodyScroll(target)
      const path='/help'
      usehistory.push(path)
    }
    
    const steps = [
      
      {
        // setting
        action: () => {
          if (pathname === "/help") {
            history.push("/settings");
          }
        },
        selector: ".orgHeaderTour",
        content: tourTab("Organization Information", "The organization’s name, logo, address, phone, and website can be edited by selecting the ellipsis option. All subscribed apps will refer to this information.")
      },
      {
        //setting
        action: () => {
          if(pathname==="/appsetting"){
            history.push("/settings");
          }
        },
        selector: ".emrTour",
        content: tourTab("Add EMR", "By integrating an EMR, it allows the app to update important information and status automatically. Specific EMR information like the EMR client key is needed. ")
      
      },
      {
        //app setting
        action: () => {
          if (pathname === "/settings") {
            history.push("/appsetting");
          }
        },
        selector: ".sidePanelTour",
        content: tourTab("Book App", "The side panel in Bookapp is customizable with a map and messages. The message will display when the title and contents are both completed.")
      },
      {
        selector: ".panelDisplayTour",
        content: tourTab("Book App", "A default message will display for the cancellation policy and health and safety procedure if the contents are not completed.")
      },
      {
        //app setting
        action: () => {
          if (pathname === "/patientqueue") {
            history.push("/appsetting");
          }
        },
        selector: ".checkInProviderTour",
        content: tourTab("Check-In","Modify the selection for the providers who accept check-ins, to be shown on the Check-In App.")
      },
      {
        //patient queue
        action: () => {
          if (pathname === "/appsetting") {
            history.push("/patientqueue");
          }
        },
        selector: ".filterTour",
        content: tourTab("Queue Page", "To manage the list of current patient queue and upcoming appointments, the ‘Filter By’ button or the ellipsis can be used to show options.")
      },


    ];
    return (
      <Reactour
        startAt={0}
        steps={steps}
        isOpen={isOpen}
        onRequestClose={closeTour}
        update={pathname}
        goToStep={steps}
        getCurrentStep={(curr) => curr + 1}
        onAfterOpen={disableBody} 
        onBeforeClose={enableBody}  
        disableInteraction
        showNavigation={false}
      />
    );
  }
);

  export default Tour