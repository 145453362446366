import React from 'react';
import {usePagination} from '@material-ui/lab/Pagination';
import {makeStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles({
    root: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
    },
});

export default function UsePagination({count, onChange, page}) {
    const classes = useStyles();
    const {items} = usePagination({
        count: count,
        onChange: onChange,
        page: page,
    });

    return (
        <div className={classes.root}>
            {items.map(({page, type, selected, ...item}, index) => {
                let children = null;

                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                    children = '…';
                } else if (type === 'page') {
                    children = (
                        <Button type="button" style={{fontWeight: selected ? 'bold' : undefined}} {...item}>
                            {page}
                        </Button>
                    );
                } else if (type === 'previous') {
                    children = (
                        <Button startIcon={<KeyboardArrowLeftIcon/>} type="button" {...item}>
                            {type}
                        </Button>
                    );
                } else if (type === 'next') {
                    children = (
                        <Button endIcon={<ChevronRightIcon/>} type="button" {...item}>
                            {type}
                        </Button>
                    );
                }

                return <div key={index}>{children}</div>;
            })}
        </div>
    );
}