import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import AlertIcon from "../../../../../img/AlertIcon.png";
import Tick from "../../../../../frame/styles/tick.png";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    dialogActions: {
        display: "flex",
        justifyContent: "space-between",
        padding: "1rem 2rem",
    },
    dialogTitle: {
        justifyContent: "center",
        color: "444444",
        textAlign: "center",
        fontSize: "24px",
        fontWeight: "300",
    },
    tick: {
        maxWidth: "100px",
        justifyContent: "center",
        marginTop: "2rem",
    },
    contentText: {
        color: "#000000",
        fontSize: "30",
        fontWeight: 600,
    },
    contentText2: {
        color: "#767676",
        fontSize: "15px",
        fontWeight: 400,
        textAlign: "center",
        marginTop: "1rem",
    },
    buttonStyle: {
        fontSize: "18px",
        marginBottom: "20px",
        fontWeight:700,
    }
}));
 
const AlertModal = ({
    open,
    onClose,
    targetRow,
    deleteRole,
    resetRole,
    mode,
}) => {
    const classes = useStyles();
    const [isSuccess, setIsSuccess] = useState(false);
    const setIsSuccessOnClose = () => {
        setIsSuccess(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle
                id="alert-dialog-title"
                className={classes.dialogTitle}
            >
                {/* ICON HERE */}
                {isSuccess ? 
                    <img className={classes.tick} src={Tick} alt={Tick} />
                    : <img className={classes.tick} src={AlertIcon} alt={AlertIcon} />
                }
            </DialogTitle>
            <DialogContent>
                { mode === "del" ? 
                    <DialogContentText id="alert-dialog-description">
                    {isSuccess ? 
                            <>
                                <Typography className={clsx(classes.dialogTitle, classes.contentText)}>
                                    Delete role successful
                                </Typography>
                                <Typography className={classes.contentText2}>Non-default role 1 has been deleted</Typography>
                            </>
                        :   
                        <Typography className={clsx(classes.dialogTitle, classes.contentText)}>
                                {"Are you sure you want to delete " + targetRow.role + "?"} 
                        </Typography> 
                    }
                    </DialogContentText>
                    :
                    <DialogContentText id="alert-dialog-description">
                    {isSuccess ? 
                            <>
                                <Typography className={clsx(classes.dialogTitle, classes.contentText)}>
                                    Reset features successful
                                </Typography>
                                <Typography className={classes.contentText2}>{targetRow.role} role features has been reset to default</Typography>
                            </>
                        :   
                        <Typography className={clsx(classes.dialogTitle, classes.contentText)}>
                                {"Are you sure you want to reset features to default for " + targetRow.role + "?"} 
                        </Typography> 
                    }
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions className={classes.dialogActions} style={isSuccess ? {'justifyContent' : 'center'} : null}>
                {!isSuccess ? 
                    <>
                        <Button onClick={onClose} color="primary" className={classes.buttonStyle}>
                            CANCEL
                        </Button>
                        <Button
                            onClick={() => {
                                if (mode === "del") {
                                    deleteRole();
                                } else {
                                    resetRole();
                                }
                                setIsSuccess(true);
                            }}
                            color="primary"
                            className={classes.buttonStyle}
                        >
                            YES
                        </Button>
                    </>
                :
                    <Button
                        onClick={() => {
                            onClose();
                            setTimeout(setIsSuccessOnClose, 2000);
                        }}
                        color="primary"
                        className={classes.buttonStyle}
                    >
                        OK
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default AlertModal;
