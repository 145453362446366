import React, { useState } from "react";
import {
  Typography,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import settingsStyles from "../../../../../styles/settingsPageStyles";
import EmailIcon from '@material-ui/icons/Email';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PhoneIcon from '@material-ui/icons/Phone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

export default function PatientIdentifier(props) {
  const classes = settingsStyles();
  const boxStates = props.patientIdentifiers;

  const handleChange = (event) => {
    props.setPatientIdentifiers({ ...boxStates, [event.target.name]: event.target.checked });
  };

  return (
    <Box p={2} className={classes.pepercomponent}>
      <Box className={classes.labelWithEdit}>
        <Typography className={classes.labelWithAdd}>
          {" "}
          Patient Identifier{" "}
        </Typography>
      </Box>

      <FormGroup style={{"margin" : "0 7px"}}>
        <FormControlLabel
          control={
            <>
            <Checkbox
                checked={boxStates.email}
                onChange={handleChange}
                name="email"
                color="primary"
            />
            <EmailIcon />
            </>
          }
          label="&nbsp;&nbsp;Email"
        />
        <FormControlLabel
          control={
            <>
            <Checkbox
              checked={boxStates.phone}
              onChange={handleChange}
              name="phone"
              color="primary"
            />
            <PhoneIcon />
            </>
          }
          label="&nbsp;&nbsp;Phone Number"
        />
        <FormControlLabel
          control={
            <>
            <Checkbox
              checked={boxStates.hin}
              onChange={handleChange}
              name="hin"
              color="primary"
            />
            <LocalHospitalIcon />
            </>
          }
          label="&nbsp;&nbsp;Health Insurance Number"
        />
        <FormControlLabel
          control={
            <>
            <Checkbox
              checked={boxStates.fingerprint}
              onChange={handleChange}
              name="fingerprint"
              color="primary"
            />
            <FingerprintIcon />
            </>
          }
          label="&nbsp;&nbsp;Fingerprint"
        />
        <FormControlLabel
          control={
            <>
            <Checkbox
              checked={boxStates.iris}
              onChange={handleChange}
              name="iris"
              color="primary"
            />
            <VisibilityIcon/>
            </>
          }
          label="&nbsp;&nbsp;Iris Scan"
        />
      </FormGroup>
    </Box>
  );
}
