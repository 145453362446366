import DateFnsUtils from "@date-io/date-fns";
import { makeStyles, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import Appointment, { AppointmentTypes } from "../../../models/Appointment";
import { get } from "lodash";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    boxSizing: "border-box",
    borderRadius: "4px",
    border: "1px solid #c7c7c7",
    padding: "3px",
  },
}));
const style= {
  root: {
    '& label.Mui-focused': {
      color: 'blue',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'blue',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'blue',
      },
    },
  },
}
const CssTextField = withStyles(style)(TextField);

const CssTimePicker = withStyles(style)(KeyboardTimePicker);
const CssSelect = withStyles(style)(Select);
export default function EditForm(props) {
  const global = React.useContext(GlobalContext);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState();
  const [newStartTime, setNewStartTime] = useState("");
  const [reason, setReason] = useState("");
  const [validReason, setValidReason] = useState(true);

  const orgID = useMemo(
    () =>
      get(global, "state.selectedOrg._id") ||
      get(global, "state.defaultOrg._id"),
    [global.state.defaultOrg, global.state.selectedOrg]
  );

  const appointment = useMemo(() => new Appointment(props.post), [props.post]);

  useEffect(() => {
    //fetch statuses from oscar whenever an org is selected/changed
    const fetchItems = async () => {
      try {
        let statuses = await global.api.fetchStatuses(orgID);
        if(statuses?.data?.message) {
          setStatuses(statuses.data.message);
        }
      } catch (error) {
        // console.log("error getting statuses for selected org", error);
      }
    };
    fetchItems();
  }, [orgID]);

  const handleChange = (event) => {
    let { name, value } = event.target;
    appointment[name] = value;
    if (name === "reason") {
      setReason(value);
    }
  };
  const getNumIssues = () => {
    let issues = 0;
    if (reason) {
      let isValidReason = /^[^,]+$/.test(reason);
      setValidReason(isValidReason);

      if (!isValidReason) {
        issues++;
      }
    }
    return issues;
  };

  const handleClose = () => {
    props.close();
  };

  const handleStartTimeChange = (date) => {
    // calculate end time with duration from appointment object
    const momentEndDate = moment(date).add(
      get(appointment, "duration", 15),
      "minutes"
    ); // default duration: 15 mins
    appointment.appointmentStartDateTime = date.toISOString();
    appointment.appointmentEndDateTime = momentEndDate.toISOString();
    setNewStartTime(appointment.appointmentStartDateTime);
  };

  const saveChanges = async () => {
    let numIssues = getNumIssues();
    if (numIssues > 0) return;
    setLoading(true);

    // format reason to include phone (if telephone appointment) and comment if included by patient
    const reason = appointment.getOscarValidatedReason();

    try {
      await global.api.updateAppointment({
        appointment_id: appointment.id,
        org_id: orgID,
        newData: {
          ...appointment,
          reason,
        },
      });
      handleClose();
    } catch (e) {
      // console.error(e);
    } finally {
      setLoading(false);
      props.refreshQueue();
    }
  };

  return (
    <Dialog
      open={props.open}
    >
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            Edit Appointment Information
          </Grid>
          <Grid item xs={1}>
            <DialogActions>
              <IconButton onClick={handleClose} edge="end" size="small">
                <CloseIcon style={{ color: "#000000" }} />
              </IconButton>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ overflowY: "hidden" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Name"
              defaultValue={appointment.patientName}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CssTimePicker
                id="time"
                label="Time"
                name="startTime"
                value={newStartTime || appointment.appointmentStartDateTime}
                placeholder="08:00 AM"
                mask="__:__ _M"
                onChange={handleStartTimeChange}
                inputVariant="outlined"
                variant="inline"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <CssTextField
              id="date"
              label="Date"
              type="date"
              name="appointmentDate"
              defaultValue={appointment.appointmentDate}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Provider"
              //type="number"
              defaultValue={appointment.doctorName || appointment.providerNo}
              fullWidth
              variant="outlined"
              disabled
              //name="providerNo"
              //onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            {/* <TextField
                    label="Status"
                    defaultValue={appointment.status}
                    fullWidth
                    name="status"
                    variant="outlined"
                    onChange={handleChange}
                /> */}
            <FormControl className={classes.formControl} variant="outlined"
                         margin="0.5"
                         style={{ width: "100%", padding:"0px 0px", border:'0px'}}>
              <InputLabel id="status-value"> Status </InputLabel>
              <CssSelect
                displayEmpty
                disableUnderline={true}
                // inputProps={{
                //   "aria-label": "Without label",
                //   disableUnderline: true,
                // }}
                value={appointment.status}
                color='warning'
                name="status"
                onChange={handleChange}
                label="Status"
              >
                {statuses &&
                  statuses.map(
                    (status, i) =>
                      status.active && (
                        <MenuItem value={status.status} key={i}>
                          {status.status + " - " + status.description}
                        </MenuItem>
                      )
                  )}
              </CssSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CssTextField
              error={!validReason}
              helperText={!validReason && "Sorry comma(,) is not acceptable"}
              label="Reason for Visit"
              defaultValue={appointment.reason ? appointment.reason : "N/A"}
              fullWidth
              name="reason"
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CssTextField
              label="Comments"
              defaultValue={appointment.comment ? appointment.comment : "N/A"}
              fullWidth
              name="comment"
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
          {/* <Grid item xs={12}>
                <TextField
                    label="Comments"
                    fullWidth
                    name="notes"
                    variant="outlined"
                    onChange={handleChange}
                />
            </Grid>
            */}
        </Grid>
      </DialogContent>
      {loading ? (
        <DialogActions>
          <CircularProgress
            style={{ margin: "10px", color: "#1766b3", marginRight: "50px" }}
          />
        </DialogActions>
      ) : (
        <DialogActions style={{ padding: '8px 18px' }} >
          <Button  style={{margin: "auto",marginRight: "73%" }}color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" autoFocus onClick={saveChanges}>
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
