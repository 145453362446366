import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import Tick from "../../../src/img/tick.png";

const useStyles = makeStyles((theme) => ({
    dialogActions: {
        display: "flex",
        justifyContent: "center",
    },
 
    dialogClose: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
    },

    tick: {
        maxWidth: "100px",
        justifyContent: "center",
        marginLeft: "28%",
        marginTop: "1rem",

    },
}));

const RemoveSuccessDialog = ({ open, onClose }) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ margin: "auto", width: "350px", borderRadius: "8px" }}

        >
            <DialogTitle id="alert-dialog-title">
                <img className={classes.tick} src={Tick} alt={Tick} />
                <IconButton
                    className={classes.dialogClose}
                    onClick={onClose}
                    children={<ClearIcon />}
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography
                        style={{
                            justifyContent: "center",
                            fontWeight: "500",
                            fontSize: "24px",
                            color: "#444444",
                            textAlign: "center",
                            fontFamily:"Roboto"

                        }}
                    >
                         Organization has been removed
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={onClose}
                    color="primary"
                    style={{
                        fontWeight: "500",
                        fontSize: "16px",
                    }}
                >
                    VIEW ALL ORGANIZATIONS
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemoveSuccessDialog;
