/**
 * List of all medical specialties as an array.
 * 
 * MAY NEED TO ADD MORE HERE...
 */

export const specialties = [
    "Anatomical Pathology",
    "Anesthesiology",
    "Cardiac Surgery",
    "Dermatology",
    "Diagnostic Radiology",
    "Emergency Medicine",
    "General Pathology",
    "General Surgery",
    "Hematological Pathology",
    "Internal Medicine",
    "Medical Genetics and Genomics",
    "Medical Microbiology",
    "Neurology",
    "Neuropathology",
    "Neurosurgery",
    "Nuclear Medicine",
    "Obstetrics and Gynecology",
    "Ophthalmology",
    "Orthopedic Surgery",
    "Otolaryngology-Head and Neck Surgery",
    "Pediatrics",
    "Physical Medicine and Rehabilitation",
    "Plastic Surgery",
    "Psychiatry",
    "Public Health and Preventive Medicine",
    "Radiation Oncology",
    "Urology",
    "Vascular Surgery",
]

export default specialties;