/*
 * @Author: Vincent Tran
 * @Date: 2020-07-16 2:34:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-07-20 1:25:00
 * @Description: file content
 */
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Slide from "@material-ui/core/Slide";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import successTick from "../../../img/tick.png";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function DummyUsersPopup(props) {
    const [open, setOpen] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [usersCreated] = React.useState(5);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSuccessClose = () => {
        setOpenSuccess(false);
    };
    const handleSave = () => {
        // call method to create dummy users here..

        handleClose();
        setOpenSuccess(true);
    };
    const classes = props.classes;

    return (
        <div>
            <Typography className={classes.toolBtn} onClick={handleClickOpen}>
                CREATE
            </Typography>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    id="alert-dialog-slide-title"
                >
                    Create Dummy Users
                    <IconButton
                        onClick={handleClose}
                        className={classes.dialogClose}
                        children={<ClearIcon />}
                    />{" "}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.dialogDescription}>
                        Would you like to create user testing accounts?
                    </DialogContentText>
                    <Box className={classes.listBox}>
                        <List subheader={<li />}>
                            <ListSubheader className={classes.listSubheader}>
                                Current user test accounts
                            </ListSubheader>
                            <ul className={classes.listItems}>
                                {[0, 1, 2, 3, 4, 5].map((userId) => (
                                    <ListItem
                                        key={`item-${userId}`}
                                        className={classes.userEmailItem}
                                    >
                                        <ListItemText
                                            primary={`User ${userId}`}
                                            classes={{
                                                primary: classes.userEmail,
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </ul>
                        </List>
                    </Box>
                    <Box className={classes.listBox}>
                        <List subheader={<li />}>
                            <ListSubheader className={classes.listSubheader}>
                                Missing user test accounts
                            </ListSubheader>
                            <ul className={classes.listItems}>
                                {[6, 7, 8, 9, 10].map((userId) => (
                                    <ListItem
                                        key={`item-${userId}`}
                                        className={classes.userEmailItem}
                                    >
                                        <ListItemText
                                            primary={`User ${userId}`}
                                            classes={{
                                                primary: classes.userEmail,
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </ul>
                        </List>
                    </Box>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        className={classes.actionBtn}
                    >
                        CANCEL
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        className={classes.actionBtn}
                    >
                        YES
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openSuccess}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleSuccessClose}
                className={classes.resultsModal}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <img
                        className={classes.tick}
                        src={successTick}
                        alt="successTick"
                    />
                    <DialogContentText className={classes.resultsDescription}>
                        {usersCreated} user testing accounts were successfully
                        created.
                    </DialogContentText>
                    <Box className={classes.listBox}>
                        <List subheader={<li />}>
                            <ListSubheader className={classes.listSubheader}>
                                Created Users
                            </ListSubheader>
                            <ul className={classes.listItems}>
                                {[6, 7, 8, 9, 10].map((userId) => (
                                    <ListItem
                                        key={`item-${userId}`}
                                        className={classes.userEmailItem}
                                    >
                                        <ListItemText
                                            primary={`User ${userId}`}
                                            classes={{
                                                primary: classes.userEmail,
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </ul>
                        </List>
                    </Box>
                </DialogContent>
                <DialogActions className={classes.resultsAction}>
                    <Button
                        onClick={handleSuccessClose}
                        color="primary"
                        className={classes.actionBtn}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
