/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-10 04:27:42
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-10-10 04:20:53
 * @Description: file content
 */
/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-09 03:39:12
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-07-10 03:28:55
 * @Description: file content
 */
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import GlobalContext from "../../context/GlobalContext";
//Org selector frame
import OrgSelectorFrame from "../../frame/OrgSeletor";
import BookApp from "./component/BookApp";
import CheckInApp from "./component/CheckInApp";
import ManageServices from "./component/ManageServices/";
import ReminderApp from "./component/ReminderApp";
//Different subpages
import VirtualApp from "./component/VirtualApp/";
// custom tab styles
import {get,isUndefined} from 'lodash';

const useStyles = makeStyles((theme) => ({
    head: {
        display: 'flex',
    },
    title: {
        paddingTop: "1rem",
        fontWeight: 500,
        display: "block",
        marginRight: "2rem",
        fontSize: "2rem",
        '@media (max-width:960px)': { 
            display: 'none',
        },
    },
    titleAdmin: {
        paddingTop: "1rem",
        fontWeight: 500,
        display: "block",
        marginRight: "2rem",
        fontSize: "2rem",
    },
    appBox: {
        margin: "2rem 0"
    }

}));
// Tabpanel

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

function App(props) {
    const global = React.useContext(GlobalContext)
    const classes = useStyles()
    // let { path, url } = useRouteMatch();
    // const [status, setStatus] = React.useState(false);
    const [translateMode, setTranslateMode] = React.useState(false)

    let org = {}
   // let orgID = global.state.selectedOrg != 'undefined' && global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0 ? global.state.selectedOrg._id : global.state.defaultOrg._id
    if (global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0) {
        org = global.state.selectedOrg
    } else if (global.state.defaultOrg && Object.keys(global.state.defaultOrg).length !== 0) {
        org = global.state.defaultOrg
    }
    let settingsPermission = global.state.user && global.state.user.document.roles[0] === 'admin';
    
    useEffect(()=> {
        /* const fetchStatus = async () => {
        let  getStatus = await global.api.fetchStatuses(org._id)
        setStatus(getStatus.data.message)     
        }     */

        const getTranslateModeArray = async () => {
            //converts org.org_services into a format that is acceptable by translateappointmentmodeArray
            if(org.org_services) {
                let tempModesArray = Object.keys(org.org_services).map(key => {
                    return key;
                })

                let modesObject = await global.api.translateAppintmentModeArray(tempModesArray)
                modesObject && setTranslateMode(modesObject.data)
            }
        }

        getTranslateModeArray()

        if (settingsPermission) {
            props.setTitle("Select Organization");
        } else {
            props.setTitle("Apps");
        }
    }, [])


    const defaultOrg = get(global,'state.defaultOrg')
    return (
        <>
            {settingsPermission &&
                <OrgSelectorFrame />
            }
            <Box className={classes.head}>
                <Typography className={settingsPermission ? classes.titleAdmin : classes.title} variant="h4" >Apps</Typography>
            </Box>
            {settingsPermission &&
                <Box className={classes.appBox}>
                    <ManageServices />
                </Box>
            }
            {(settingsPermission || !org.appSettingsJanitor || (org.appSettingsJanitor && org.appSettingsJanitor.meetToggle)) &&
                <Box className={classes.appBox}>
                    <VirtualApp />
                </Box>
            }
            {(settingsPermission || !org.appSettingsJanitor || (org.appSettingsJanitor && org.appSettingsJanitor.bookToggle)) &&
                <Box className={classes.appBox}>
                    <BookApp translateMode={translateMode}/>
                </Box>
            }
            {(settingsPermission || !org.appSettingsJanitor || (org.appSettingsJanitor && org.appSettingsJanitor.checkInToggle)) &&
                <Box className={classes.appBox}>
                    <CheckInApp/>
                </Box>
            }
            {(((isUndefined(defaultOrg) ||
                defaultOrg === get(global, 'state.selectedOrg'))) && settingsPermission || !org.appSettingsJanitor || (org.appSettingsJanitor && org.appSettingsJanitor.reminderToggle)) &&
                <Box className={classes.appBox}>
                    <ReminderApp />
                </Box>
            }

            {/* {(settingsPermission || !org.appSettingsJanitor || (org.appSettingsJanitor && org.appSettingsJanitor.patientToggle)) &&
                <Box className={classes.appBox}>
                    <PatientApp />
                </Box>
            } */}
        </>
    );
}

export default App;
