import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  pageRefresh: {
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  footer: {
    //position: 'fixed',
    bottom: "5%",
    paddingLeft: "5%",
    paddingRight: "5%",
    width: "90%",
    [theme.breakpoints.down(1000)]: {
      position: "static",
    },
  },

  footerText: {
    [theme.breakpoints.down(440)]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "0.8rem",
      paddingLeft: "2%",
      paddingRight: "2%",
    },
  },

  header: {
    padding: "4% 0 0 7%",
  },

  portonImgHeader: {
    width: "auto",
    height: "auto",
    minWidth: "104.25px",
    maxWidth: "150px",
  },

  portonImgFooter: {
    width: "auto",
    height: "90px",
    "@media (max-width: 768px)": {
      height: "50px",
    },
  },
}));
