/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-10 05:07:32
 * @LastEditors: Tyler Bowman
 * @LastEditTime: 2021-01-22 10:49:56
 * @Description: Style Reformating
 */
import { CircularProgress, Switch, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React from "react";
import GlobalContext from "../../../../context/GlobalContext";
import ToolStyle from "../../../../styles/ToolStyle.js";
import combineStyles from "../../../../styles/combineStyles";
import settingsStyles from "../../../../styles/settingsPageStyles";
import AppointmentBlockEditor from "./components/AppointmentBlockEditor";
import ConfirmationPanel from "./components/ConfirmationPanelComponent";
import ManageScheduleDays from "./components/ManageScheduleDays";
import MessageComponent from "./components/MessageComponent";
import SidePanelDisplay from "./components/SidePanelComponent";
import StatusCodes from "./components/StatusCodeDesignation";
import TemplateCodeEditor from "./components/TemplateCodeEditor";
import UrlComponent from "./components/UrlComponent";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    border: "1px solid #c7c7c7",
    borderRadius: "5px",
    width: "100%",
    padding: "2px 8px",
  },
  disabledSettingsBackground: {
    backgroundColor: "#F5F5F5",
    pointerEvents: "none",
  },

  divider: {
    marginTop: 8,
  },
}));

let oldState;
let oldDoctors;
let oldTemplateState;
//combines both styles from this file and from settingPageStyles
const combinedStyles = combineStyles(settingsStyles, ToolStyle, useStyles);
let oldWhiteLabelUrl = "";

function RoomURL() {
  const [expanded, setExpanded] = React.useState(false);
  const classes = combinedStyles();
  const global = React.useContext(GlobalContext);
  const [state, setState] = React.useState({});
  const [doctors, setDoctors] = React.useState({});
  const [templateState, setTemplateState] = React.useState({});
  const maxLength = 500;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChange = (event) => {
    if (event.target.hasOwnProperty("checked")) {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else if (event.target.hasOwnProperty("value")) {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };
  const handleTextChange = (oldMessage) => (event) => {
    //checks to see if oldmessage is defined first, then if so checks length to allow the user to input up to the maxlength
    //also allows the user to delete if the message has somehow exceeded the maxlength
    if (
      oldMessage === undefined ||
      (event.target.value.length > maxLength &&
        oldMessage.length < event.target.value.length)
    ) {
      return;
    }
    return setState({ ...state, [event.target.name]: event.target.value });
  };
  const handleChangeMessage = (oldMessage) => (event) => {
    if (
      oldMessage === undefined ||
      (event.target.value.length > maxLength &&
        oldMessage.length < event.target.value.length)
    ) {
      return;
    }
    updateProviderMessage(state.selectedProvider, event.target.value);
  };

  const updateProviderMessage = (id, itemAttributes) => {
    if (
      doctors.doctorList === undefined ||
      doctors.doctorList.length === 0 ||
      id === undefined
    ) {
      return;
    }
    let index = doctors.doctorList.findIndex(
      (x) => String(x.key) === String(id)
    );
    if (index === -1) {
      return; //error
    } else {
      setDoctors({
        ...doctors,
        doctorList: [
          ...doctors.doctorList.slice(0, index),
          {
            key: doctors.doctorList[index].key,
            id: doctors.doctorList[index].id,
            doctorName: doctors.doctorList[index].doctorName,
            message: itemAttributes,
            textLength: itemAttributes.length,
          },
          ...doctors.doctorList.slice(index + 1),
        ],
      });
    }
  };
  const handleChangeDropdown = (event) => {
    return setState({ ...state, [event.target.name]: event.target.value });
  };

  const urlTextChange = (event) => {
    if (event.target.value === undefined) {
      return;
    }
    //prevents special characters except _ and -
    return setState({
      ...state,
      [event.target.name]: event.target.value.replace(/[^-\w]/gi, ""),
    });
  };

  const editUrl = () => {
    setState({ ...state, editUrl: !state.editUrl });
    oldWhiteLabelUrl = state.whiteLabelUrl;
  };
  const cancelEditUrl = () => {
    setState({
      ...state,
      whiteLabelUrl: oldWhiteLabelUrl,
      editUrl: !state.editUrl,
    });
  };
  const confirmEditUrl = () => {
    setState({
      ...state,
      whiteLabelUrl: state.whiteLabelUrl,
      editUrl: !state.editUrl,
    });
  };

  const saveOldState = () => {
    oldState = JSON.parse(JSON.stringify(state));
    oldDoctors = JSON.parse(JSON.stringify(doctors));
    oldTemplateState = JSON.parse(JSON.stringify(templateState));
    setState({ ...state, isEdit: true });
  };

  const loadOldState = () => {
    setState({ ...oldState, isEdit: false });
    setDoctors({ ...oldDoctors });
    setTemplateState({ ...oldTemplateState });
  };

  let defaultSettings = {
    bookAppSettings: {
      checkedEnableSettings: true,
      mapToggle: false,
      allowFileUpload: false,
      issueMessage: {
        title: "",
        content: "",
      },
      scheduleDays: 90,
      minimumInterval: 10,
      allowMultipleAppointments: false,
      requirePatientPhone: false,
      minimumSlotInterval: false,
    },
  };

  let orgID =
    global.state.selectedOrg !== "undefined" &&
    global.state.selectedOrg &&
    Object.keys(global.state.selectedOrg).length !== 0
      ? global.state.selectedOrg._id
      : global.state.defaultOrg._id;

  let currentOrgSettings;
  let whiteLabelURL;
  let settingsPermission = global.state.user.document.roles.includes("admin");
  React.useEffect(() => {
    const fetchItems = async () => {
      let arr = [];
      let resultDoctors;
      setDoctors({ ...doctors, doctorList: arr });
      try {
        resultDoctors = await global.api.getDoctorsByOrgs(orgID, 1, -1);
        if (
          resultDoctors &&
          resultDoctors.status === 200 &&
          resultDoctors.data.status !== false
        ) {
        } else if (
          resultDoctors &&
          resultDoctors.status === false &&
          resultDoctors.message === "Organization have no doctors"
        ) {
          return;
        } else {
          return;
        }
      } catch (error) {
        return;
      }
      let len = resultDoctors.data.data.length;
      for (let i = 0; i < len; i++) {
        let message = resultDoctors.data.data[i].message;
        if (!resultDoctors.data.data[i].message) {
          message = "";
        }
        arr.push({
          key: i,
          id: resultDoctors.data.data[i]._id,
          doctorName: resultDoctors.data.data[i].contact_name,
          message: message,
          textLength: message.length,
        });
      }

      setDoctors({ ...doctors, doctorList: arr });
    };
    fetchItems();

    let getBookEnvVar = async () => {
      let bookEnvVar;
      let statuses;
      let org;
      try {
        let getEnvVar = await global.api.GetEnvVariable({
          name: "book-app",
        });
        bookEnvVar = getEnvVar.data.res;
        statuses = await global.api.fetchStatuses(orgID);
        org = await global.api.getOrgById(orgID);
      } catch (error) {
        // console.log(error.response ? error.response.data : error);
      }

      if (
        typeof global.state.selectedOrg !== "undefined" &&
        global.state.selectedOrg &&
        global.state.selectedOrg !== null &&
        JSON.stringify(global.state.selectedOrg) != "{}"
      ) {
        orgID = global.state.selectedOrg._id;
        whiteLabelURL = global.state.selectedOrg.whiteLabelURL;
        if (
          global.state.selectedOrg &&
          typeof global.state.selectedOrg != "undefined"
        ) {
          currentOrgSettings = global.state.selectedOrg;
        } else {
          currentOrgSettings = defaultSettings;
        }
      } else {
        //default org
        orgID = global.state.defaultOrg._id;
        whiteLabelURL = global.state.defaultOrg.whiteLabelURL;
        if (
          typeof global.state.defaultOrg != "undefined" &&
          global.state.defaultOrg
        ) {
          currentOrgSettings = global.state.defaultOrg;
        } else {
          currentOrgSettings = defaultSettings;
        }
      }

      setState({
        ...state,
        bookEnvVar: bookEnvVar,
        selectedProvider: -1,
        checkedEnableSettings: currentOrgSettings.bookAppSettings
          ? currentOrgSettings.bookAppSettings.checkedEnableSettings
          : true,
        isLoading: false,
        isEdit: false,
        btnColor: "primary",
        editUrl: false,
        whiteLabelUrl: whiteLabelURL,
        appointmentBlockCode: currentOrgSettings.appointmentBlockCode
          ? currentOrgSettings.appointmentBlockCode.toString().split(",")
          : [],
        statuses: statuses.data ? statuses.data.message : null,
        statusCodes: org.data.statusCodes
          ? org.data.statusCodes
          : {
              inPerson: { value: -1 },
              voiceCall: { value: -1 },
              videoCall: { value: -1 },
              textChat: { value: -1 },
              appointmentTypes: null,
            },
        mapToggle: currentOrgSettings.bookAppSettings
          ? currentOrgSettings.bookAppSettings.mapToggle
          : false,
        allowFileUpload: currentOrgSettings.bookAppSettings
          ? currentOrgSettings.bookAppSettings.allowFileUpload
          : false,
        issueMessage:
          currentOrgSettings.bookAppSettings &&
          currentOrgSettings.bookAppSettings.issueMessage
            ? currentOrgSettings.bookAppSettings.issueMessage.content
            : currentOrgSettings.issueMessage
            ? currentOrgSettings.issueMessage
            : "",
        issueTitle:
          currentOrgSettings.bookAppSettings &&
          currentOrgSettings.bookAppSettings.issueMessage
            ? currentOrgSettings.bookAppSettings.issueMessage.title
            : "",
        procedureMessage: currentOrgSettings.procedureMessage
          ? currentOrgSettings.procedureMessage
          : "",
        title1:
          currentOrgSettings.news && currentOrgSettings.news[0]
            ? currentOrgSettings.news[0].title
            : "",
        title2:
          currentOrgSettings.news && currentOrgSettings.news[1]
            ? currentOrgSettings.news[1].title
            : "",
        title3:
          currentOrgSettings.news && currentOrgSettings.news[2]
            ? currentOrgSettings.news[2].title
            : "",
        news1:
          currentOrgSettings.news && currentOrgSettings.news[0]
            ? currentOrgSettings.news[0].content
            : "",
        news2:
          currentOrgSettings.news && currentOrgSettings.news[1]
            ? currentOrgSettings.news[1].content
            : "",
        news3:
          currentOrgSettings.news && currentOrgSettings.news[2]
            ? currentOrgSettings.news[2].content
            : "",
        scheduleDays: currentOrgSettings.bookAppSettings
          ? currentOrgSettings.bookAppSettings.scheduleDays
          : 90,
        minimumInterval: currentOrgSettings.bookAppSettings
          ? currentOrgSettings.bookAppSettings.minimumInterval
          : 10,
        allowMultipleAppointments: currentOrgSettings.bookAppSettings
          ? currentOrgSettings.bookAppSettings.allowMultipleAppointments
          : false,
        requirePatientPhone: currentOrgSettings.bookAppSettings
          ? currentOrgSettings.bookAppSettings.requirePatientPhone
          : false,
        minimumSlotInterval: currentOrgSettings.bookAppSettings
          ? currentOrgSettings.bookAppSettings.minimumSlotInterval
          : false,
      });
    };

    getBookEnvVar();
  }, [global.state.defaultOrg, global.state.selectedOrg]);

  orgID =
    JSON.stringify(global.state.selectedOrg) != "{}" &&
    global.state.selectedOrg &&
    typeof global.state.selectedOrg != "undefined" &&
    global.state.selectedOrg
      ? global.state.selectedOrg._id
      : global.state.defaultOrg._id;

  const saveSettings = async () => {
    //save setDisplay configs to Porton DB

    let org = {};
    if (
      global.state.selectedOrg &&
      Object.keys(global.state.selectedOrg).length !== 0
    ) {
      org = global.state.selectedOrg;
    } else if (
      global.state.defaultOrg &&
      Object.keys(global.state.defaultOrg).length !== 0
    ) {
      org = global.state.defaultOrg;
    }

    if (!org) {
      alert("No org!");
      return;
    }

    if (state.whiteLabelUrl.length === 0) {
      alert("Invalid WhitelabelUrl");
      return;
    }

    let len = 0;
    setState({ ...state, isLoading: true });

    if (doctors.doctorList !== undefined) {
      len = doctors.doctorList.length;
    }

    let result = await global.api.UpdateTextInDb(
      orgID,
      state.whiteLabelUrl,
      "whiteLabelURL"
    );

    if (result.status && result.status === 400) {
      alert(
        "Error updating WhiteLabelUrl, This whitelabel may already be in use by another organization"
      );
      setState({ ...state, isLoading: false });
      return;
    }
    let changedDoctors = [];
    //makes array of doctors with their messages changed
    for (let i = 0; i < len; i++) {
      if (oldDoctors.doctorList[i].message !== doctors.doctorList[i].message) {
        changedDoctors.push({
          _id: doctors.doctorList[i].id,
          message: doctors.doctorList[i].message,
        });
      }
    }
    if (changedDoctors.length !== 0) {
      await global.api.updateDoctorMessage(orgID, changedDoctors);
    }

    let settings = {
      checkedEnableSettings: state.checkedEnableSettings,
      mapToggle: state.mapToggle,
      allowFileUpload: state.allowFileUpload,
      issueMessage: {
        title: state.issueTitle,
        content: state.issueMessage,
      },
      scheduleDays: state.scheduleDays,
      minimumInterval: state.minimumInterval,
      allowMultipleAppointments: state.allowMultipleAppointments,
      requirePatientPhone: state.requirePatientPhone,
      minimumSlotInterval: state.minimumSlotInterval,
    };

    if (
      oldState.procedureMessage !== state.procedureMessage ||
      state.procedureMessage == ""
    ) {
      await global.api.UpdateTextInDb(
        orgID,
        state.procedureMessage,
        "procedureMessage"
      );
    }
    if (
      oldState.news1 !== state.news1 ||
      state.news1 == "" ||
      oldState.title1 !== state.title1 ||
      state.title1 == ""
    ) {
      await global.api.UpdateNews(orgID, state.news1, state.title1, "news.0");
    }
    if (
      oldState.news2 !== state.news2 ||
      state.news2 == "" ||
      oldState.title2 !== state.title2 ||
      state.title2 == ""
    ) {
      await global.api.UpdateNews(orgID, state.news2, state.title2, "news.1");
    }
    if (
      oldState.news3 !== state.news3 ||
      state.news3 == "" ||
      oldState.title3 !== state.title3 ||
      state.title3 == ""
    ) {
      await global.api.UpdateNews(orgID, state.news3, state.title3, "news.2");
    }
    let codeResult = await global.api.setAppointmentBlockCode(
      orgID,
      state.appointmentBlockCode.join()
    );

    const templateCodes = org.serviceCodes;
    if (templateState.codesInfo) {
      for (let i = 0; i < templateState.codesInfo.length; i++) {
        const tempArr = [];

        for (let r = 0; r < templateState.codesInfo[i].codes.length; r++) {
          if (templateState.codesInfo[i].codes[r].enabled) {
            tempArr.push(templateState.codesInfo[i].codes[r].id);
          }
        }
        if (
          !(
            tempArr.length === 0 &&
            templateCodes[templateState.codesInfo[i].key] === undefined
          )
        ) {
          templateCodes[templateState.codesInfo[i].key] = tempArr;
        }
      }
    }
    await global.api.updateServiceCodes(orgID, templateCodes);
    result = await global.api.UpdateBookAppSettings(orgID, settings);

    await global.api.updateAppointmentStatusCodes(orgID, state.statusCodes);

    if (
      codeResult.status &&
      codeResult.status === 200 &&
      result.status &&
      result.status === 200
    ) {
      if (
        JSON.stringify(global.state.selectedOrg) != "{}" &&
        global.state.selectedOrg &&
        typeof global.state.selectedOrg != "undefined"
      ) {
        let org = global.state.selectedOrg;
        org.bookAppSettings = settings;
        org.whiteLabelURL = state.whiteLabelUrl;
        org.procedureMessage = state.procedureMessage;
        if (org.news && org.news[0]) {
          org.news[0].content = state.news1;
          org.news[0].title = state.title1;
        }
        if (org.news && org.news[1]) {
          org.news[1].content = state.news2;
          org.news[1].title = state.title2;
        }
        if (org.news && org.news[2]) {
          org.news[2].content = state.news3;
          org.news[2].title = state.title3;
        }
        org.appointmentBlockCode = state.appointmentBlockCode;

        global.setState((prev) => {
          return { ...prev, selectedOrg: org };
        });
      } else {
        //default org
        let org = global.state.defaultOrg;
        org.bookAppSettings = settings;
        org.whiteLabelURL = state.whiteLabelUrl;
        org.procedureMessage = state.procedureMessage;
        if (org.news && org.news[0]) {
          org.news[0].content = state.news1;
          org.news[0].title = state.title1;
        }
        if (org.news && org.news[1]) {
          org.news[1].content = state.news2;
          org.news[1].title = state.title2;
        }
        if (org.news && org.news[2]) {
          org.news[2].content = state.news3;
          org.news[2].title = state.title3;
        }
        org.appointmentBlockCode = state.appointmentBlockCode;

        global.setState((prev) => {
          return { ...prev, defaultOrg: org };
        });
      }
    }

    // console.log("Check-in| state 'after' sumbiting:::::::", global.state)
    setState({ ...state, isLoading: false, isEdit: false, editUrl: false });
  };

  function getDoctorName(post) {
    if (
      doctors.doctorList === undefined ||
      doctors.doctorList.length === 0 ||
      post === undefined
    ) {
      return;
    }

    const doctor = doctors.doctorList.find(
      (x) => String(x.key) === String(post)
    );

    if (doctor !== null) {
      return `Dr. ${doctor.doctorName}`;
    }
  }

  function getMessage(post) {
    if (
      doctors.doctorList === undefined ||
      doctors.doctorList.length === 0 ||
      post === undefined
    ) {
      return;
    }
    const match = doctors.doctorList.find(
      (x) => String(x.key) === String(post)
    );
    if (match === undefined) {
      return;
    }

    return match.message;
  }

  function selectProviderMessage() {
    if (doctors.doctorList === undefined || doctors.doctorList.length === 0) {
      return `No Provider Listed`;
    }
    return `Select A Provider (${doctors.doctorList.length} Providers Listed)`;
  }

  return (
    <Paper
      elevation={3}
      className={classes.container}
      style={{
        border: expanded
          ? "2px solid #003FBA"
          : "2px solid rgba(255,255,255,0)",
        borderRadius: "10px",
      }}
    >
      <Grid container>
        <Grid item xs={12} className={classes.paperComponment}>
          <Box
            onClick={handleExpandClick}
            className={clsx(
              classes.labelWithEdit,
              classes.labelWithEditDropdown
            )}
          >
            <Typography
              className={clsx(classes.label, {
                [classes.expandedLabel]: expanded,
              })}
            >
              BookApp
            </Typography>
            <Typography className={classes.edit}>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Collapse
            in={global.tourOpen || expanded}
            timeout="auto"
            unmountOnExit
          >
            <Box className={!state.isEdit ? classes.disabledBackground : ""}>
              <Grid container>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>

                <Grid item xs={12} className={classes.paperComponment}>
                  <Box
                    className={classes.labelWithEdit}
                    style={{ width: "99.3%" }}
                  >
                    {!state.isEditPressed && (
                      <Typography
                        style={{ color: "#767676" }}
                        className={classes.labelSmall}
                      >
                        Enable BookApp
                      </Typography>
                    )}
                    {state.isEditPressed && (
                      <Typography
                        style={{ color: "#767676" }}
                        className={classes.labelSmallEnabled}
                      >
                        Enable BookApp
                      </Typography>
                    )}
                    <Switch
                      color="primary"
                      size="small"
                      className={classes.switch}
                      checked={
                        state.checkedEnableSettings === undefined
                          ? true
                          : state.checkedEnableSettings
                      }
                      onChange={handleChange}
                      name="checkedEnableSettings"
                      disabled={!state.isEdit}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Grid container flex>
                    <Grid item md={6} xs={12} className={classes.dividerBorder}>
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                      <UrlComponent
                        state={state}
                        classes={classes}
                        handleChange={handleChange}
                        editUrl={editUrl}
                        setState={setState}
                        urlTextChange={urlTextChange}
                        cancelEditUrl={cancelEditUrl}
                        confirmEditUrl={confirmEditUrl}
                        settingsPermission={settingsPermission}
                      />

                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>

                      <SidePanelDisplay
                        state={state}
                        classes={classes}
                        maxLength={maxLength}
                        handleChangeText={handleTextChange}
                        handleChange={handleChange}
                        handleChangeDropdown={handleChangeDropdown}
                      />

                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>

                      <StatusCodes state={state} setState={setState} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                      <MessageComponent
                        state={state}
                        doctors={doctors}
                        classes={classes}
                        maxLength={maxLength}
                        handleChangeDropdown={handleChangeDropdown}
                        handleChangeText={handleChangeMessage}
                        getMessage={getMessage}
                        getDoctorName={getDoctorName}
                        selectProviderMessage={selectProviderMessage}
                      />

                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>

                      <ConfirmationPanel
                        state={state}
                        classes={classes}
                        maxLength={maxLength}
                        handleChangeText={handleTextChange}
                        handleChange={handleChange}
                      />

                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>

                      <AppointmentBlockEditor
                        state={state}
                        setState={setState}
                      />

                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>

                      <TemplateCodeEditor
                        state={templateState}
                        setState={setTemplateState}
                      />

                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>

                      <ManageScheduleDays
                        classes={classes}
                        state={state}
                        handleChangeDropdown={handleChangeDropdown}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Grid item md={12} sm={12}>
              <Divider className={classes.divider} />
            </Grid>
            {state.isEdit ? (
              <Grid item md={12} sm={12}>
                <Box
                  item
                  md={12}
                  sm={12}
                  className={clsx(
                    classes.labelWithEditBottomSpacing,
                    classes.labelWithEdit
                  )}
                >
                  <Box
                    className={clsx(
                      classes.labelWithEdit,
                      classes.labelWithEditWidth
                    )}
                  >
                    <div md={7} sm={0}></div>
                    {state.isLoading ? (
                      <CircularProgress className={classes.loadingCircle} />
                    ) : (
                      <Button
                        md={5}
                        sm={12}
                        className={clsx(
                          classes.saveChangesBtn,
                          classes.editToggleBtn
                        )}
                        variant="outlined"
                        color={state.btnColor}
                        onClick={saveSettings}
                      >
                        Save Changes
                      </Button>
                    )}
                  </Box>
                  {state.isLoading ? (
                    <CircularProgress className={classes.loadingCircle} />
                  ) : (
                    <Button
                      md={5}
                      sm={12}
                      className={classes.editToggleBtn}
                      variant="outlined"
                      color={state.btnColor}
                      onClick={() => loadOldState()}
                    >
                      Cancel
                    </Button>
                  )}
                </Box>
              </Grid>
            ) : (
              <Grid item md={12} sm={12}>
                <Box
                  item
                  md={12}
                  sm={12}
                  className={clsx(
                    classes.labelWithEditBottomSpacing,
                    classes.labelWithEdit
                  )}
                >
                  <Box
                    className={clsx(
                      classes.labelWithEdit,
                      classes.labelWithEditWidth
                    )}
                  >
                    <div md={7} sm={0}></div>
                    <Button
                      md={5}
                      sm={12}
                      className={classes.editToggleBtn}
                      variant="outlined"
                      color={state.btnColor}
                      onClick={() => saveOldState()}
                    >
                      Edit
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default RoomURL;
