/*
 * @Author: Vincent Tran
 * @Date: 2020-08-05 04:39:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-08-20 02:00:00
 * @Description: file content
 */
/**
 * TO DO: 
 * Connect this component to the database...
 * 1) Retrieve provider data from database, save info to state, and display.
 * 2) Edits made to provider data must update in the database (handleSave).
 * 
 * Other minor enhancements:
 * 1) Possibly need to add more dropdown options for Title
 * 2) Switch current dropdown selects for country, year, to be virtualized lists for faster loading
 */

import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import BootstrapInput from '../../../../../styles/BootstrapInput';
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle';
import UserPageStyles from '../../../../../styles/usersPageStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, UserPageStyles);

export default function EditPatientProfile(props) {
    // temporary default values..
    // Real values should be pulled from database in the future
    let arr = []
    if(!props.account.specialty){
        if(props.account.specialty_1 && props.account.specialty_1 !== ""){
            arr.push(props.account.specialty_1)
        }
        if(props.account.specialty_2 && props.account.specialty_2 !== ""){
            arr.push(props.account.specialty_2)
        }
        if(props.account.specialty_3 && props.account.specialty_3 !== ""){
            arr.push(props.account.specialty_3)
        }
    }
    
    const userInfo = {
        fName: props.account.firstname || props.account.first_name,
        lName: props.account.lastname || props.account.last_name,
       
    }
    const global = React.useContext(GlobalContext);
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({...userInfo});
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    };
    const handleChange = (event) => {
        setState({ 
            ...state, 
            [event.target.name]: event.target.value
        })
    }
    const handleSave = async () => {
        // save user profile info to database here...
        let result = await global.api.updateAccount({
            id: props.account._id,
            firstname: state.fName,
            lastname: state.lName,
            profileSet: true,
            contact_name: state.fName + " " + state.lName,
            role: props.account.roles[0],
            orgId: global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id
        });
        if (result && result.status === 200) {
            handleClose();
            props.refresh();
        } else {
            alert('Unable to update profile.');
        }
    }

    const classes = combinedStyles();

    return (
        <div>
            <ButtonBase className={props.className} onClick={handleClickOpen}> <Typography className={classes.typography}>Edit Profile</Typography></ButtonBase>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >

                <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">Edit Admin Information<IconButton onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} /> </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid container>
                        <Grid container spacing={3} className={classes.row}>
                            <Grid item xs={12} >
                                <InputLabel className={classes.formLabel} shrink htmlFor="fName-input">
                                    First Name
                                </InputLabel>
                                <BootstrapInput 
                                    fullWidth 
                                    name="fName" 
                                    value={state.fName} 
                                    placeholder="First Name"
                                    id="fName-input" 
                                    onChange={(e) => handleChange(e)} />
                            </Grid>
                            <Grid item xs={12} >
                                <InputLabel className={classes.formLabel} shrink htmlFor="lName-input">
                                    Last Name
                                </InputLabel>
                                <BootstrapInput 
                                    fullWidth 
                                    name="lName" 
                                    value={state.lName} 
                                    placeholder="Last Name"
                                    id="lName-input" 
                                    onChange={(e) => handleChange(e)} />
                            </Grid>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleClose} color="primary" className={classes.actionBtn}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" className={classes.actionBtn}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
