/*
 * @Author: William Lee
 * @Date: 2021-07-07 14:30:00
 * @LastEditors: William Lee
 * @LastEditTime: 2021-07-07 15:15:00
 * @Description: Fingerprint Scan modal
 */ 

import { Box, Dialog, DialogTitle, IconButton, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import React from 'react';
import settingsStyles from '../../../../../styles/settingsPageStyles';

const steps = [
  '',
  '',
  ''
];

// Progress Bar
  const ProgressStepper = () => {
    return (
      <Box sx={{ width: '75%' }}>
        <Stepper style={{ width: '100%' }} activeStep={0} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel style={{ color: 'green' }}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }

const FingerprintScan = ({open, close }) => {
    const classes = settingsStyles()

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography className={classes.label}>Reset Fingerprint</Typography>
                    <IconButton>
                        <CloseIcon onClick={close} />
                    </IconButton>
                </Box>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography style={{color:'#767676', marginTop:'2%'}}>Lift and rest your finger on your fingerprint sensor 3 times</Typography>
                </Box>
                <div className={classes.fingerprintIconDiv}>
                    <FingerprintIcon className={classes.fingerprintIcon}/>
                </div>
                <div className={classes.fingerprintIconDiv}>
                    <ProgressStepper/>
                </div>
            </DialogTitle>
        </Dialog>
    )
}

export default FingerprintScan;