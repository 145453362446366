import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  Grid,
  IconButton,
  Menu,
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreHorizSharp";
import clsx from 'clsx';
import settingsStyles from "../../../../../styles/settingsPageStyles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "97%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    margin: "0 13px -3px",
    fontSize: "0.8rem",
  },
  hoverColor: {
    '&:hover': {
      backgroundColor: "#D3E2FF"
    },
  },
}));

export default function PaymentMethod(props) {
  const classes = settingsStyles();
  const classes2 = useStyles();
  const [manageOpen, setManageOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleChange = (event) => {};

  return (
    <Box p={2} className={classes.pepercomponent}>
      <Box className={classes.labelWithEdit}>
        <Typography className={classes.labelWithAdd}>
          {" "}
          Payment Method{" "}
        </Typography>
      </Box>

      <Typography variant="body2" className={classes2.label}>
        Currency
      </Typography>
      <FormControl variant="outlined" className={classes2.formControl}>
        <Select
          value={props.paymentMethod.currency}
          onChange={handleChange}
          displayEmpty
        >
          <MenuItem value={"CAD"}>CAD</MenuItem>
        </Select>
      </FormControl>

      <Typography variant="body2" className={classes2.label}>
        Primary Payment Method
      </Typography>
      <FormControl variant="outlined" className={classes2.formControl}>
        <Select
          value={props.paymentMethod.primaryPM}
          onChange={handleChange}
          displayEmpty
        >
          <MenuItem value={"Insurance"}>Insurance</MenuItem>
        </Select>
      </FormControl>

      <Typography variant="body2" className={classes2.label}>
        {"Secondary Payment Method (Optional)"}
      </Typography>
      <FormControl variant="outlined" className={classes2.formControl}>
        <Select
          value={props.paymentMethod.secondaryPM}
          onChange={handleChange}
          displayEmpty
        >
          <MenuItem value={"Credit/Debit Card"}>Credit/Debit Card</MenuItem>
        </Select>
      </FormControl>

      <Typography variant="body2" className={classes2.label}>
        Payment Service Provider
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <FormControl variant="outlined" className={classes2.formControl}>
            <Select
              value={props.paymentMethod.paymentServiceProvider}
              onChange={handleChange}
              displayEmpty
            >
              <MenuItem value={"Stripe Payments (North A)"}>
                {"Stripe Payments (North A)"}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
              <IconButton
                className={clsx(
                  classes.dropDownSelectSpacing,
                  classes2.hoverColor
                )}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setManageOpen(true);
                }}
              >
                <MoreIcon />
              </IconButton>
              <Menu
                classes={{ list: classes.menu }}
                open={manageOpen}
                onClose={() => {
                  setManageOpen(false)}
                }
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  className={classes.menuItem}
                  onClick={null}
                >
                  Manage
                </MenuItem>
              </Menu>
        </Grid>
      </Grid>
    </Box>
  );
}
