/*
 * @Author: Vincent Tran
 * @Date: 2020-07-31 03:54:00
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-27 01:23:57
 * @Description: Passing prop type down to MeatBallButton
 */

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import UsePagination from "../../PatientQueue/component/UsePagination";
import { ReactComponent as WelcomeImage } from "./imgs/welcome.svg";
import CalendarButton from "./subComponents/CalendarButton";
import ContactButton from "./subComponents/ContactButton";
import MeatBallButton from "./subComponents/MeatBallButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  container: {
    marginTop: "2rem",
  },
  paper: {
    padding: theme.spacing(1.75),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  typography: {
    fontWeight: 500,
    padding: theme.spacing(2),
  },
  invitePopOver: {
    marginTop: "0.5rem",
    width: "100%",
  },
  actionFieldLayout: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  contactButton: {
    marginRight: "2rem",
  },
}));

/**
 * @description: apply styles to a make a customized tablecell
 * @return:
 */
const StyledTableCell = withStyles((theme) => ({
  head: {
    paddingTop: "2rem",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: "1rem",
    fontWeight: 600,
    borderBottom: "1px solid #BDBDBD",
  },
  body: {
    fontSize: "1rem",
    borderBottom: "none",
    fontWeight: 480,
  },
}))(TableCell);
/**
 * @description: apply styles to a make a customized tablerow
 * @return:
 */
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

//table end

function UsersTable(props) {
  const classes = useStyles();
  const global = React.useContext(GlobalContext);
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [doctors, setDoctors] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [emptyUsers, setEmptyUsers] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [maxPage, setMaxPage] = React.useState(1);
  const [orgDetails, setOrgDetails] = React.useState({});
  const details = () => {
    let data =
      Object.keys(global.state.selectedOrg).length > 0
        ? global.state.selectedOrg
        : global.state.defaultOrg;
    setOrgDetails(data);
    return;
  };
  React.useEffect(details, []);

  const rowsPerPage = 6;
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const loadUserData = async () => {
    let currentOrg;
    if (!global.state) return;
    if (
      !global.state ||
      !global.state.selectedOrg ||
      global.state.selectedOrg == null ||
      JSON.stringify(global.state.selectedOrg) == "{}"
    ) {
      currentOrg = global.state.defaultOrg;
    } else {
      currentOrg = global.state.selectedOrg;
    }

    let response;
    switch (props.type) {
      case "patients":
        response = await global.api.searchUsersByOrg(
          currentOrg._id,
          "patient",
          "",
          -1
        );
        break;

      case "providers":
        response = await global.api.searchUsersByOrg(
          currentOrg._id,
          "doctor",
          "",
          -1
        );
        break;

      case "moas":
        response = await global.api.searchUsersByOrg(
          currentOrg._id,
          "oa",
          "",
          -1
        );
        break;

      case "admins":
        response = await global.api.searchUsersByOrg(
          currentOrg._id,
          "healthOrgAdmin",
          "",
          -1
        );
        break;
    }
    setUserList([]);
    if (response && (response.status == true || response.status == 200)) {
      if (response.data) {
        let data = response.data.data;
        let formatedData = data.map((v, i) => {
          if (!v.contact_name) {
            if (v.firstname && v.lastname)
              v.contact_name = v.firstname + " " + v.lastname;
            if (v.name) v.contact_name = v.name;
            if (!v.contact_name) v.contact_name = "N/A";
          }
          if (!v.email && v.emails) {
            if (v.email === "") {
              v.email = "N/A";
            } else {
              if (v.emails[0] === undefined) {
                v.email = "N/A";
              } else {
                v.email = v.emails[0].address;
              }
            }
          } else if (!v.email) {
            v.email = "N/A";
          }
          if (!v.phone) {
            v.phone = "N/A";
            if (v.applicant) v.phone = v.applicant;
          }

          return v;
        });
        if (props.type != "patients") {
          setUserList(formatedData);
          setRows(formatedData);
          let max = Math.ceil(formatedData.length / 6);
          setMaxPage(max);
        } else if (props.type == "patients") {
          setUserList(formatedData);
        }
        if (formatedData.length == 0) {
          setEmptyUsers(true);
        } else {
          setEmptyUsers(false);
        }
      }
    }
  };

  //speed up refresh attempt only refresh user on the page you are on
  const refreshUsers = async () => {
    let currentOrg;
    if (!global.state) return;
    if (
      !global.state ||
      !global.state.selectedOrg ||
      global.state.selectedOrg == null ||
      JSON.stringify(global.state.selectedOrg) == "{}"
    ) {
      currentOrg = global.state.defaultOrg;
    } else {
      currentOrg = global.state.selectedOrg;
    }

    let response;
    if (props.type == "patients") {
      response = await global.api.searchUsersByOrg(
        currentOrg._id,
        "patient",
        "",
        6,
        page
      );
    }
    if (props.type == "providers") {
      response = await global.api.searchUsersByOrg(
        currentOrg._id,
        "doctor",
        "",
        6,
        page
      );
    }
    if (props.type == "moas") {
      response = await global.api.searchUsersByOrg(
        currentOrg._id,
        "oa",
        "",
        6,
        page
      );
    }
    if (props.type == "admins") {
      response = await global.api.searchUsersByOrg(
        currentOrg._id,
        "healthOrgAdmin",
        "",
        6,
        page
      );
    }
    let arr = [];
    // setUserList([])
    if (response && (response.status == true || response.status == 200)) {
      if (response.data) {
        let data = response.data.data;
        let formatedData = data.map((v, i) => {
          if (!v.contact_name) {
            if (v.firstname && v.lastname)
              v.contact_name = v.firstname + " " + v.lastname;
            if (v.name) v.contact_name = v.name;
            if (!v.contact_name) v.contact_name = "N/A";
          }
          if (!v.email && v.emails) {
            v.email = v.emails[0].address;
          } else if (!v.email) {
            v.email = "N/A";
          }
          if (!v.phone) {
            v.phone = "N/A";
            if (v.applicant) v.phone = v.applicant;
          }
          arr.push(v);
          return v;
        });
        const tempRow = [...rows];
        const PAGE_SIZE = 6;
        for (let i = 0; i < PAGE_SIZE; i++) {
          tempRow[(page - 1) * PAGE_SIZE + i] = formatedData[i];
        }
        setUserList(tempRow);
        setRows(tempRow);
      }
    }
  };

  const enableAccount = async (id, email) => {
    let [contact] = rows.filter((i) => i && i["email"] === email);
    let result = await global.api.updateAccount({
      id: id,
      accountDisabled: false,
      orgId: global.state.selectedOrg._id
        ? global.state.selectedOrg._id
        : global.state.defaultOrg._id,
    });
    if (result && result.status === 200) {
      loadUserData();
      let emailResult = await global.api.enableAccountEmail(
        email,
        contact.contact_name,
        orgDetails.logo
      );
      if (emailResult && emailResult.status === 200) {
        alert("Mail Sent");
      }
    }
  };

  // search through the table
  React.useEffect(() => {
    let result = [];

    result = userList.filter(
      (name) =>
        name &&
        name.contact_name.toLowerCase().includes(props.searchText.toLowerCase())
    );
    if (
      props.type === "providers" ||
      props.type === "moas" ||
      props.type === "Organizations" ||
      props.type === "admins"
    ) {
      setRows(result);
      let max = Math.ceil(result.length / 6);
      setMaxPage(max);
      setPage(1);
    }
    if (props.type == "patients" && props.searchText.trim() != "") {
      setRows(result);
      let max = Math.ceil(result.length / 6);
      setMaxPage(max);
      setPage(1);
    }

    if (props.type == "patients" && props.searchText.trim() == "") {
      setRows([]);
    }
  }, [props.searchText]);

  React.useEffect(() => {
    setLoading(true);
    setRows([]);
    setUserList([]);
    if (
      props.type === "patients" ||
      props.type === "providers" ||
      props.type === "moas" ||
      props.type === "Organizations" ||
      props.type === "admins"
    ) {
      loadUserData();
    }
    setLoading(false);
  }, [props.type, global.state.selectedOrg, global.state.defaultOrg]); //removed searchText and page

  /*React.useEffect(() => {
        setLoading(true)
        setRows([])
        setUserList([])
        if (props.type === "patients" || props.type === "providers" || props.type === "moas" || props.type === "Organizations" || props.type === 'admins') {
            refreshUsers()
        } 
       setLoading(false)

    }, [page])*/

  React.useEffect(() => {
    props.resetSearchText();
    setPage(1);
    setEmptyUsers(false);
  }, [props.type]);

  const returnPropType = () => {
    switch (props.type) {
      case "moas":
        return "MOAs";
      case "patients":
        return "Patients";
      case "providers":
        return "Providers";
      case '"Organizations':
        return '"Organization';
      case "admins":
        return "Admins";
    }
  };

  React.useEffect(() => {
    let currentOrg = {};
    if (!global.state) return;
    if (
      !global.state.selectedOrg ||
      !Object.keys(global.state.selectedOrg).length
    ) {
      currentOrg = global.state.defaultOrg;
    } else {
      currentOrg = global.state.selectedOrg;
    }
    const fetchDoctorList = async () => {
      try {
        const response = await global.api.getDoctorsFromEmr(currentOrg._id);
        response && setDoctors(response.data);
      } catch (error) {
        // console.log(error.response ? error.response.data : error);
      }
    };
    fetchDoctorList();
  }, [global.state.selectedOrg, global.state.defaultOrg]);

  return (
    <Box className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                {rows.length > 0 && (
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Phone</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {rows.length > 0 &&
                  rows
                    .slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
                    .map(
                      (row, i) =>
                        row && (
                          <StyledTableRow key={i}>
                            <StyledTableCell component="th" scope="row">
                              {row.contact_name}
                            </StyledTableCell>

                            <StyledTableCell>
                              {!row.accountDisabled ? row.phone : ""}
                            </StyledTableCell>
                            <StyledTableCell>
                              {!row.accountDisabled
                                ? row.email
                                  ? row.email
                                  : "N/A"
                                : ""}
                            </StyledTableCell>
                            {!row.accountDisabled ? (
                              <StyledTableCell align="right">
                                {row.action || (
                                  <Box className={classes.actionFieldLayout}>
                                    {/* only shows the meatballbutton if you are viewing patients, or if you are janitor/hadmin*/}
                                    {global.state.user &&
                                      (global.state.user.document.roles[0] ===
                                        "healthOrgAdmin" ||
                                        global.state.user.document.roles[0] ===
                                          "admin" ||
                                        row.roles[0] === "patient") && (
                                        <MeatBallButton
                                          globalState={global.state}
                                          type={props.type}
                                          _id={row._id}
                                          account={row}
                                          refresh={refreshUsers}
                                          doctors={doctors}
                                        />
                                      )}
                                    {/* temporary meatballbutton showing for moa when selecting doctor, for issue 4243*/}
                                    {global.state.user &&
                                      global.state.user.document.roles[0] ===
                                        "oa" &&
                                      row.roles[0] === "doctor" && (
                                        <MeatBallButton
                                          globalState={global.state}
                                          type={props.type}
                                          _id={row._id}
                                          account={row}
                                          refresh={refreshUsers}
                                          doctors={doctors}
                                        />
                                      )}
                                    <ContactButton
                                      info={row}
                                      globalState={global.state}
                                      className={classes.contactButton}
                                    />
                                    {(row.roles[0] === "patient" ||
                                      row.roles[0] === "doctor") && (
                                      <CalendarButton
                                        type={props.type}
                                        id={row._id}
                                        className={classes.contactButton}
                                      />
                                    )}
                                  </Box>
                                )}
                              </StyledTableCell>
                            ) : (
                              <StyledTableCell align="right">
                                {global.state.user &&
                                (global.state.user.document.roles[0] ===
                                  "healthOrgAdmin" ||
                                  global.state.user.document.roles[0] ===
                                    "admin") ? (
                                  <Button
                                    color="secondary"
                                    onClick={() =>
                                      enableAccount(row._id, row.email)
                                    }
                                  >
                                    Enable Account
                                  </Button>
                                ) : (
                                  <Typography color="secondary">
                                    Account Disabled
                                  </Typography>
                                )}
                              </StyledTableCell>
                            )}
                          </StyledTableRow>
                        )
                    )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          item
          xs={12}
        >
          {loading == false &&
          rows.length == 0 &&
          userList.length != 0 &&
          props.type == "patients" ? (
            <>
              <WelcomeImage style={{ width: "auto", height: "250px" }} />
              <Typography className={classes.typography}>
                Please search for the patient inside the search bar.
              </Typography>
            </>
          ) : (
            ""
          )}

          {rows.length == 0 && userList.length == 0 && !emptyUsers ? (
            <CircularProgress className={classes.loadingCircle} />
          ) : (
            ""
          )}
          {emptyUsers ? (
            <>
              <WelcomeImage style={{ width: "auto", height: "250px" }} />
              <Typography className={classes.typography}>
                You currently have no {returnPropType()}.
              </Typography>
            </>
          ) : (
            ""
          )}
        </Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          item
          xs={12}
        >
          {rows.length != 0 && (
            <Grid item xs={6}>
              <UsePagination
                count={maxPage}
                page={page}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default withRouter(UsersTable);
