import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from "@material-ui/core";
import React, { useState, useContext } from "react";
import CloseIcon from "@material-ui/icons/Close";
import GlobalContext from "../../../../../context/GlobalContext";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    Title: {
        color: "black",
        fontFamily: "Roboto",
        fontSize: "1.5rem",
        fontWeight: 600,
    },
    TitleBody: {
        color: "black",
        fontFamily: "Roboto",
        marginTop: "-6px",
        marginBottom: "19px",
        fontSize: "15px",
    },
    TitleChangeEmail: {
        color: "black",
        fontFamily: "Roboto",
        marginTop: "-6px",
        marginBottom: "9px",
        fontSize: "15px",
    },
    TitleVerifyCode: {
        color: "black",
        fontFamily: "Roboto",
        marginTop: "-6px",
        marginBottom: "9px",
        fontSize: "17px",
    },
    textField:{
        width:"100px"
    }
}));

const ChangePasswordFlow = ({ user, open = false, onClose = () => {} }) => {
    const global = useContext(GlobalContext);

    const [state, setState] = useState({
        step: 1,
        password: "",
        email: "",
        verificationCode: "",
        error: "",
    });
    const classes = useStyles();

    const handlePasswordVerify = async () => {
        const result = await global.api.verifyPassword(
            user.email || user.emails[0].address,
            state.password
        );

        if (result.status === 200) {
            // password is valid, go to next step
            setState({ ...state, error: "", step: 2 });
        } else {
            // password was incorrect,
            setState({ ...state, error: "Invalid Password" });
        }
    };

    const handleEmailEntry = async () => {
        const result = await global.api.sendEmailVerificationCodeEmail(
            state.email
        );

        if (result.status === 200) {
            setState({ ...state, error: "", step: 3 });
        } else {
            setState({ ...state, error: "Something went wrong" });
        }
    }

    const actuallyUpdateTheEmail = async () => {
        const userWithUpdatedEmail = {
            ...user,
            id: user._id,
            email: state.email,
            profileSet: true,
            orgId: global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id
        };
        
        const result = await global.api.updateAccount(
            userWithUpdatedEmail
        );

        if (result.status === 200) {
            onClose();
        } else {
            setState({ ...state, error: "Something went wrong" });
        }
    }

    const handleVerificationCodeEntry = async () => {
        const result = await global.api.verifyEmailVerificationCode(
            state.email,
            state.verificationCode
        );

        if (result.status === 200) {

            actuallyUpdateTheEmail();

        } else {
            setState({ ...state, error: "Something went wrong" });
        }
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs">
            <DialogTitle
                xs={{
                    m: 0,
                    p: 2,
                }}
                className={classes.Title}
            >
                Change Email
                {onClose ? (
                    <IconButton
                        onClick={onClose}
                        style={{
                            marginLeft: "210px",
                            color: "black",
                            fontWeight: 500,
                        }}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                {state.step === 1 && (
                    <>
                        <Typography className={classes.TitleBody}>
                            Please enter your password to continue
                        </Typography>
                        <TextField
                            name="password"
                            fullWidth
                            type="password"
                            label="Password"
                            variant="outlined"
                            value={state.password}
                            onChange={(e) =>
                                setState({ ...state, password: e.target.value })
                            }
                        ></TextField>
                        <DialogActions>
                            <Button
                                onClick={handlePasswordVerify}
                                color="primary"
                                disabled={
                                    !state.password ||
                                    state.password.trim().length < 3
                                }
                            >
                                CONFIRM
                            </Button>
                        </DialogActions>
                    </>
                )}
                {state.step === 2 && (
                    <>
                        <Typography className={classes.TitleChangeEmail}>Please Enter Your new Email</Typography>
                        <TextField
                            name="email"
                            fullWidth
                            type="email"
                            label="Enter New Email"
                            variant="outlined"
                            value={state.email}
                            onChange={(e) =>
                                setState({ ...state, email: e.target.value })
                            }
                        ></TextField>
                        <DialogActions>
                            <Button
                                onClick={handleEmailEntry}
                                color="primary"
                                disabled={
                                    !state.email ||
                                    state.email.trim().length < 3
                                }
                            >
                                CONFIRM
                            </Button>
                        </DialogActions>
                    </>
                    
                )}
                {state.step === 3 && (
                    <>
                        <Typography className={classes.TitleVerifyCode}> We have sent you an email with a verification code, please enter that code below</Typography>
                        <TextField
                            name="verification-code"
                            type="text"
                            fullWidth
                            label="Verification Code"
                            variant="outlined"
                            value={state.verificationCode}
                            onChange={(e) =>
                                setState({ ...state, verificationCode: e.target.value })
                            }
                        ></TextField>
                        <DialogActions>
                            <Button
                                onClick={handleVerificationCodeEntry}
                                color="primary"
                                disabled={
                                    !state.verificationCode ||
                                    state.verificationCode.trim().length < 6
                                }
                            >
                                VERIFY
                            </Button>
                        </DialogActions>
                    </>
                )}
                {state.error && (
                    <>
                        <Typography>{state.error}</Typography>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ChangePasswordFlow;
