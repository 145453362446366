/*
 * @Author: Vincent Tran
 * @Date: 2020-07-16 4:05:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-07-20 11:05:00
 * @Description: file content
 */
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import successTick from "../../../img/tick.png";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CleanupPopup(props) {
    const [open, setOpen] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [documents] = React.useState(100);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSuccessClose = () => {
        setOpenSuccess(false);
    };
    const handleCleanup = () => {
        // call method to create user testing accounts here..

        handleClose();
        setOpenSuccess(true);
    }
    const classes = props.classes;

    return (
        <div className={classes.root}>
            <Typography className={classes.toolBtn} onClick={handleClickOpen}>CLEAN-UP</Typography>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">
                    Clean-up
                    <IconButton onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.dialogDescription}>
                        Please include description of the currently implemented database clean-up here.<br/><br/>Proceed to database clean-up?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleClose} color="primary" className={classes.actionBtn}>
                        CANCEL
                    </Button>
                    <Button onClick={handleCleanup} color="primary" className={classes.actionBtn}>
                        YES
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openSuccess}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleSuccessClose}
                className={classes.resultsModal}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent >
                    <img className={classes.tick} src={successTick} alt="Success"/>
                    <DialogContentText className={classes.resultsDescription}>
                        {documents} documents in the database were affected by the clean-up.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.resultsAction}>
                    <Button onClick={handleSuccessClose} color="primary" className={classes.actionBtn}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}
