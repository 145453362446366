/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-10 05:07:32
 * @LastEditors: Tyler Bowman
 * @LastEditTime: 2021-01-22 10:49:56
 * @Description: Style Reformating
 */
import { CircularProgress, Switch, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import {
    makeStyles
} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';
import GlobalContext from "../../../../context/GlobalContext";
import combineStyles from '../../../../styles/combineStyles';
import settingsStyles from '../../../../styles/settingsPageStyles';
import ToolStyle from '../../../../styles/ToolStyle.js';

const useStyles = makeStyles((theme) => ({
    
    formControl: {
        minWidth: 120,
        border: "1px solid #c7c7c7",
        borderRadius: "5px",
        width: "100%",
        padding: "2px 8px",
    },
    disabledSettingsBackground: {
        backgroundColor: "#F5F5F5",
        pointerEvents: "none",
    },

}));

let oldState;
// let oldDoctors;
// let oldTemplateState;
//combines both styles from this file and from settingPageStyles
const combinedStyles = combineStyles(settingsStyles, ToolStyle, useStyles);
let oldWhiteLabelUrl = "";

function RoomURL() {
    const [expanded, setExpanded] = React.useState(false);
    const classes = combinedStyles()
    const global = React.useContext(GlobalContext)
    const [state, setState] = React.useState({})
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleChange = (event) => {
        if(event.target.hasOwnProperty('checked')){
            if(!event.target.checked && event.target.name === "reminderToggle") {
                setState({ ...state, 
                    emailToggle: false,
                    voiceToggle: false,
                    [event.target.name]: event.target.checked})
            } else {
                setState({ ...state, [event.target.name]: event.target.checked})
            }
            
        } else if(event.target.hasOwnProperty('value')){
            setState({ ...state, [event.target.name]: event.target.value})
        }
    }

    const packageChange = (event) => {
        
        if(event.target.checked){
            if(event.target.name === "simpleToggle") {
                setState({ ...state, 
                    meetToggle: true,
                    bookToggle: false,
                    checkInToggle: false,
                    reminderToggle: false,
                    patientToggle:false,
                    soloToggle: false,
                    clinicToggle: false,
                    [event.target.name]: event.target.checked})
            } else if(event.target.name === "soloToggle") {
                setState({ ...state, 
                    meetToggle: true,
                    bookToggle: true,
                    checkInToggle: false,
                    reminderToggle: false,
                    patientToggle:false,
                    simpleToggle: false,
                    clinicToggle: false,
                    [event.target.name]: event.target.checked})
            } else {
                setState({ ...state, 
                    meetToggle: true,
                    bookToggle: true,
                    checkInToggle: false,
                    reminderToggle: false,
                    patientToggle:false,
                    soloToggle: false,
                    simpleToggle: false,
                    [event.target.name]: event.target.checked})
            }
        } else{
    
            setState({...state, [event.target.name]: event.target.checked})
        }
        
    }
    

    let defaultSettings = {
        
        simpleToggle: false,
        soloToggle: false,
        clinicToggle: false,
        meetToggle: false,
        bookToggle: false,
        checkInToggle: false,
        reminderToggle: false,
        patientToggle:false,
        emailToggle: false,
        voiceToggle: false
        
        
    }

    const saveOldState = () => {
        oldState = JSON.parse(JSON.stringify(state));
        setState({ ...state, isEdit: true })
    }

    const loadOldState = () => {
        setState({ ...oldState, isEdit: false});
    }
    let org = {}
    if (global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0) {
        org = global.state.selectedOrg
    } else if (global.state.defaultOrg && Object.keys(global.state.defaultOrg).length !== 0) {
        org = global.state.defaultOrg
    }
    let orgID = global.state.selectedOrg != 'undefined' && global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0 ? global.state.selectedOrg._id : global.state.defaultOrg._id

    React.useEffect(() => {
        if(org.appSettingsJanitor){
            setState(org.appSettingsJanitor)
        } else {
            setState(defaultSettings)
        }

    }, [global.state.defaultOrg, global.state.selectedOrg])

    orgID = JSON.stringify(global.state.selectedOrg) != "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg._id : global.state.defaultOrg._id

    const saveSettings = async () => {
        //save setDisplay configs to porton DB

        if (!org){
            alert("No org!")
            return
        }
        setState({ ...state, isLoading: true})
        let settings = {
            simpleToggle: state.simpleToggle,
            soloToggle: state.soloToggle,
            clinicToggle: state.clinicToggle,
            meetToggle: state.meetToggle,
            bookToggle: state.bookToggle,
            checkInToggle: state.checkInToggle,
            reminderToggle: state.reminderToggle,
            patientToggle:state.false,
            emailToggle: state.emailToggle,
            voiceToggle: state.voiceToggle
        }
        
        let result = await global.api.updateAppSettingsJanitor(orgID, settings)
        let enabledSettings = {
            enableMeetApp: state.meetToggle,
            enableBookApp: state.bookToggle,
            enableCheckIn: state.checkInToggle,
            enableReminderApp: state.reminderToggle,
            enablePatientApp: state.patientToggle,
            enableEmail: state.emailToggle,
            enableVoice: state.voiceToggle
        }
        result = await global.api.updateEnableDisableApps(orgID, enabledSettings)
        if (result.status && result.status === 200) {
            if(JSON.stringify(global.state.selectedOrg) != "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' ){
                let org = global.state.selectedOrg
                org.appSettingsJanitor = settings
                org.virtualAppSettings.checkedEnableSettings = state.meetToggle
                org.bookAppSettings.checkedEnableSettings = state.bookToggle
                org.checkInSettings.checkedEnableSettings = state.checkInToggle
                org.reminderSettings.sms.on = state.voiceToggle
                org.reminderSettings.voice.on = state.voiceToggle
                org.reminderSettings.email.on = state.emailToggle
                // org.patientSettings.checkedEnableSettings = state.patientToggle
                global.setState((prev)=>{
                    return { ...prev, selectedOrg: org }
                })
            }else { //default org
                let org = global.state.defaultOrg
                org.appSettingsJanitor = settings

                org.virtualAppSettings.checkedEnableSettings = state.meetToggle
                org.bookAppSettings.checkedEnableSettings = state.bookToggle
                org.checkInSettings.checkedEnableSettings = state.checkInToggle
                org.reminderSettings.checkedEnableSettings = state.reminderToggle
                org.reminderSettings.sms.on = state.voiceToggle
                org.reminderSettings.voice.on = state.voiceToggle
                org.reminderSettings.email.on = state.emailToggle
                // org.patientSettings.checkedEnableSettings = state.patientToggle
                global.setState((prev)=>{
                    return { ...prev, defaultOrg: org }
                })
            }
        }
        setState({ ...state, isLoading: false, isEdit: false})
    }
    

    return (
        <Paper elevation={3} className={clsx(classes.container, expanded ? classes.borderExpanded : classes.borderClosed)}>
            <Grid container>   
                <Grid item xs={12} className={classes.paperComponment}>
                    <Box onClick={handleExpandClick} className={classes.labelWithEdit, classes.labelWithEditDropdown}>
                        <Typography className={clsx(classes.label, {
	                        	[classes.expandedLabel]: expanded
	                        })}>Manage Services For Organization</Typography>
                        <Typography className={classes.edit}>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Typography>
                    </Box>
                </Grid>
               
                <Grid item xs={12}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Box className={!state.isEdit ? classes.disabledBackground : ''}>
                            <Grid container>
                                
                                <Grid item xs={12}>
                                    <Grid container flex>
                                        <Grid item md={6} xs={12} className={classes.dividerBorder}>
                                            <Grid item xs={12}>
                                                <Divider className={classes.divider} />
                                            </Grid>
                                            <Grid item xs={12} className={classes.paperComponment}>
                                                <Typography className={classes.labelSmall}>Packages</Typography>
                                                <Box className={classes.labelWithEdit}>
                                
                                                    <Typography className={classes.labelSmall}>Simple Package</Typography>
                                                    <Switch
                                                        color='primary'
                                                        size='small'
                                                        className={classes.switch}
                                                        checked={state.simpleToggle === undefined ? false : state.simpleToggle}
                                                        onChange={packageChange}
                                                        name='simpleToggle'
                                                        disabled={!state.isEdit}
                                                    />
                                                    
                                                    
                                                </Box>
                                                
                                                <Box className={classes.labelWithEdit}>
                                
                                                    <Typography className={classes.labelSmall}>Solo Package</Typography>
                                                    <Switch
                                                        color='primary'
                                                        size='small'
                                                        className={classes.switch}
                                                        checked={state.soloToggle === undefined ? false : state.soloToggle}
                                                        onChange={packageChange}
                                                        name='soloToggle'
                                                        disabled={!state.isEdit}
                                                    />
                                                </Box>

                                                <Box className={classes.labelWithEdit}>
                                
                                                    <Typography className={classes.labelSmall}>Clinic Package</Typography>
                                                    <Switch
                                                        color='primary'
                                                        size='small'
                                                        className={classes.switch}
                                                        checked={state.clinicToggle === undefined ? false : state.clinicToggle}
                                                        onChange={packageChange}
                                                        name='clinicToggle'
                                                        disabled={!state.isEdit}
                                                    />
                                                </Box>
                                                
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6} xs={12} className={classes.dividerBorder}>
                                            
                                            
                                            <Grid item xs={12}>
                                                <Divider className={classes.divider} />
                                            </Grid>
                                        
                                            <Grid item xs={12} className={classes.paperComponment}>
                                                <Typography className={classes.labelSmall}>Apps</Typography>
                                                <Box className={classes.labelWithEdit}>
                                
                                                    <Typography className={classes.labelSmall}>MeetApp</Typography>
                                                    <Switch
                                                        color='primary'
                                                        size='small'
                                                        className={classes.switch}
                                                        checked={state.meetToggle === undefined ? false : state.meetToggle}
                                                        onChange={handleChange}
                                                        name='meetToggle'
                                                        disabled={!state.isEdit}
                                                    />
                                                    
                                                    
                                                </Box>
                                                
                                                <Box className={classes.labelWithEdit}>
                                
                                                    <Typography className={classes.labelSmall}>BookApp</Typography>
                                                    <Switch
                                                        color='primary'
                                                        size='small'
                                                        className={classes.switch}
                                                        checked={state.bookToggle === undefined ? false : state.bookToggle}
                                                        onChange={handleChange}
                                                        name='bookToggle'
                                                        disabled={!state.isEdit}
                                                    />
                                                </Box>

                                                <Box className={classes.labelWithEdit}>
                                
                                                    <Typography className={classes.labelSmall}>CheckInApp</Typography>
                                                    <Switch
                                                        color='primary'
                                                        size='small'
                                                        className={classes.switch}
                                                        checked={state.checkInToggle === undefined ? false : state.checkInToggle}
                                                        onChange={handleChange}
                                                        name='checkInToggle'
                                                        disabled={!state.isEdit}
                                                    />
                                                </Box>
                                                <Box className={classes.labelWithEdit}>
                                
                                                    <Typography className={classes.labelSmall}>PatientApp</Typography>
                                                    <Switch
                                                        color='primary'
                                                        size='small'
                                                        className={classes.switch}
                                                        //Change this out
                                                        checked={state.checkInToggle === undefined ? false : state.patientToggle}
                                                        onChange={handleChange}
                                                        name='patientToggle'
                                                        disabled={!state.isEdit}
                                                    />
                                                </Box>
                                                <Box className={classes.labelWithEdit}>
                                
                                                    <Typography className={classes.labelSmall}>ReminderApp</Typography>
                                                    <Switch
                                                        color='primary'
                                                        size='small'
                                                        className={classes.switch}
                                                        checked={state.reminderToggle === undefined ? false : state.reminderToggle}
                                                        onChange={handleChange}
                                                        name='reminderToggle'
                                                        disabled={!state.isEdit}
                                                    />
                                                </Box>
                                                {/* moved to patient app section I think */}
                                                


                                                {state && state.reminderToggle &&
                                                    <Grid item xs={12} className={classes.paperComponment}>
                                                    
                                                    <Box className={classes.labelWithEdit}>
                                    
                                                        <Typography className={classes.labelSmall}>Email Reminder</Typography>
                                                        <Switch
                                                            color='primary'
                                                            size='small'
                                                            className={classes.switch}
                                                            checked={state.emailToggle === undefined ? false : state.emailToggle}
                                                            onChange={handleChange}
                                                            name='emailToggle'
                                                            disabled={!state.isEdit}
                                                        />
                                                        
                                                        
                                                    </Box>
                                                    
                                                    <Box className={classes.labelWithEdit}>
                                    
                                                        <Typography className={classes.labelSmall}>{"Voice & SMS Reminder"}</Typography>
                                                        <Switch
                                                            color='primary'
                                                            size='small'
                                                            className={classes.switch}
                                                            checked={state.voiceToggle === undefined ? false : state.voiceToggle}
                                                            onChange={handleChange}
                                                            name='voiceToggle'
                                                            disabled={!state.isEdit}
                                                        />
                                                    </Box>
    
                                                </Grid>

                                                }
                                            </Grid>
                                        </Grid>     

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid item md={12} sm={12}><Divider className={classes.divider} /></Grid>
                        {state.isEdit ?
                            (<Grid item md={12} sm={12} >
                                <Box item md={12} sm={12} className={clsx(classes.labelWithEditBottomSpacing, classes.labelWithEdit)}>
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        {state.isLoading ? 
                                            (<CircularProgress className={classes.loadingCircle}/>) : 
                                            <Button md={5} sm={12} className={clsx(classes.saveChangesBtn, classes.editToggleBtn)}
                                                variant="outlined" color={"primary"} onClick={saveSettings}>Save Changes
                                            </Button>
                                        }
                                    </Box>
                                    {state.isLoading ? 
                                        (<CircularProgress className={classes.loadingCircle}/>) : 
                                        <Button md={5} sm={12} className={classes.editToggleBtn} 
                                            variant="outlined" color={"primary"} onClick={() => loadOldState()}>Cancel
                                        </Button>
                                    }
                                </Box>
                            </Grid>)
                        :
                            (<Grid item md={12} sm={12}>
                                <Box item md={12} sm={12} className={clsx(classes.labelWithEditBottomSpacing, classes.labelWithEdit)}>
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        <Button md={5} sm={12} className={classes.editToggleBtn} 
                                            variant="outlined" color={"primary"} onClick={() => saveOldState()} 
                                            >
                                                Edit
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>)
                        }
                    </Collapse>
                </Grid>
            </Grid>

        </Paper>
    );
}

export default RoomURL;
