import { Box, Switch, Typography } from "@material-ui/core";
import React, { useState } from "react";
import GlobalContext from "../../../../context/GlobalContext";
import popupStyles from "../../../../styles/PopupStyle";
import ToolStyle from "../../../../styles/ToolStyle";
import combineStyles from "../../../../styles/combineStyles";
import settingsStyles from "../../../../styles/settingsPageStyles";

export default function ImportPullSetting({ pullAppAutomation, autoPullSetting, setAutoPullSetting }) {
    const combinedStyles = combineStyles(
        settingsStyles,
        ToolStyle,
        popupStyles
    );

    const classes = combinedStyles();
    const global = React.useContext(GlobalContext);
    let orgId = global.state.selectedOrg._id
        ? global.state.selectedOrg._id
        : global.state.defaultOrg._id;

    const [isLoading, setIsLoading] = useState(false);

    const handleChange = async (event) => {
        setIsLoading(true);
        await setAutoPullSetting({
            ...autoPullSetting,
            [event.target.name]: event.target.checked,
        });
        // save on API
        try {
            await global.api.setAutoPullAppToggle(orgId, autoPullSetting);
        } catch (err) {
            // console.log(err);
        }
        setIsLoading(false);
    };

    return (
        <div>
            <Box style={{ display: "flex", flexDirection: "column" }}>
                <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Typography className={classes.labelSmall}>
                        Doctor Automatic Import
                    </Typography>
                    {!isLoading && pullAppAutomation ? (
                        <Switch
                            size="small"
                            defaultChecked={false}
                            checked={autoPullSetting.doctorToggle}
                            onChange={handleChange}
                            className={classes.switch}
                            color="primary"
                            name="doctorToggle"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    ) : (
                        <Switch
                            size="small"
                            disabled
                            defaultChecked={false}
                            className={classes.switch}
                            color="primary"
                            checked={autoPullSetting.doctorToggle}
                            name="doctorToggle"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    )}
                </Box>
                {/* <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Typography className={classes.labelSmall}>
                        Patient Automatic Import
                    </Typography>
                    {!isLoading && pullAppAutomation ? (
                        <Switch
                            size="small"
                            defaultChecked={false}
                            checked={autoPullSetting.PatientToggle}
                            onChange={handleChange}
                            className={classes.switch}
                            color="primary"
                            name="PatientToggle"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    )
                     : (
                        <Switch
                            size="small"
                            disabled
                            defaultChecked={false}
                            className={classes.switch}
                            color="primary"
                            checked={autoPullSetting.PatientToggle}
                            name="PatientToggle"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    )}
                </Box> */}
                <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Typography className={classes.labelSmall}>
                        Appointment Automatic Import
                    </Typography>
                    {!isLoading && pullAppAutomation ? (
                        <Switch
                            size="small"
                            defaultChecked={false}
                            checked={autoPullSetting.AppointmentToggle}
                            onChange={handleChange}
                            className={classes.switch}
                            color="primary"
                            name="AppointmentToggle"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    ) : (
                        <Switch
                            size="small"
                            disabled
                            defaultChecked={false}
                            className={classes.switch}
                            color="primary"
                            checked={autoPullSetting.AppointmentToggle}
                            name="AppointmentToggle"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    )}
                </Box>
                {/* <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Typography className={classes.labelSmall}>
                        Queue Automatic Import
                    </Typography>
                    {!isLoading && pullAppAutomation ? (
                        <Switch
                            size="small"
                            checked={autoPullSetting.QueueToggle}
                            onChange={handleChange}
                            defaultChecked={false}
                            className={classes.switch}
                            color="primary"
                            name="QueueToggle"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    ) : (
                        <Switch
                            size="small"
                            disabled
                            defaultChecked={false}
                            checked={autoPullSetting.QueueToggle}
                            className={classes.switch}
                            color="primary"
                            name="QueueToggle"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                    )}
                </Box> */}
            </Box>
        </div>
    );
}
