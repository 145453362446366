import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useSendSecureEmail } from "../../SendEmail";

let portonAdminAppBaseURL = process.env.REACT_APP_PORTON_ADMIN_API;
if (!process.env.REACT_APP_PORTON_ADMIN_API) {
  portonAdminAppBaseURL = "/api";
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PatientActionReminderEmailForm = ({
  open,
  handleClose,
  patient,
  org,
  nextAppointment,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { sendEmail, EMAIL_STATES, emailState } = useSendSecureEmail({
    emailType: "appointmentReminderEmail",
    org: org,
    otherBodyProps: {
      patient,
      appointment: nextAppointment,
      org,
    },
  });

  console.log("org details", org);
  useEffect(() => {
    switch (emailState) {
      case EMAIL_STATES.LOADING:
        setIsLoading(true);
        break;
      case EMAIL_STATES.SUCCESS:
        setIsLoading(false);
        alert("Message have been sent successfully");
        handleClose();
        break;
      case EMAIL_STATES.ERROR:
        setIsLoading(false);
        console.log("Enable to send email");
        alert("Error sending message");
        handleClose();
        break;
    }
  }, [emailState]);
  const useStyles = makeStyles((theme) => ({
    TextField: {
      marginTop: "5px",
    },

    title: {
      fontSize: "16px",
      color: "#444444",
      fontweight: "400",
      marginTop: "-28px",
      fontFamily: "Roboto",
    },
    TextFieldEmail: {
      marginTop: "5px",
      border: "1px solid black",
      borderRadius: "10px",
    },
    icon: {
      float: "right",
      color: "black",
      marginTop: "-36px",
      marginRight: "-20px",
    },
    TitleTop: {
      color: "#444444",
      fontFamily: "Roboto",
      fontSize: "1.10rem",
      fontWeight: 900,
    },
  }));

  const classes = useStyles();

  // const sendEmail = async () => {
  //     setIsLoading(true)
  //     try {
  //         const response = await fetch(
  //             `${portonAdminAppBaseURL}/appointment/sendAppointmentReminder`,
  //             {
  //                 method: "post",
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                 },
  //                 body: JSON.stringify({
  //                     patient,
  //                     appointment: nextAppointment,
  //                     org
  //                 }),
  //             }
  //         );
  //
  //         ///// FOR sentFrom address, should we try to find the admin email within the default org ? ??
  //         if (response.ok) {
  //             setIsLoading(false)
  //             alert("Message have been sent successfully");
  //         } else {
  //             throw new Error("something went wrong");
  //         }
  //     } catch (err) {
  //         setIsLoading(false)
  //         console.log("Enable to send email");
  //         alert("Error sending message");
  //     }
  //
  //     handleClose();
  // };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="lg"
      style={{
        margin: "auto",
        marginTop: "5px",
      }}
    >
      <DialogTitle style={{ marginTop: "7px" }}>
        <Typography className={classes.TitleTop}>
          {" "}
          Send reminder Or Video Link for next appointment
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <DialogContentText className={classes.title}>
              <p>
                Are you sure to send a reminder Or Video Link <br></br>for the
                next appointment ?
              </p>
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <CircularProgress
            style={{ margin: "1px", color: "#1766b3", marginRight: "20px" }}
          />
        ) : (
          <>
            <Button color={"primary"} onClick={handleClose}>
              Cancel
            </Button>
            <Button color={"primary"} onClick={sendEmail}>
              Send
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PatientActionReminderEmailForm;
