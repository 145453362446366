/*
 * @Author: Andy Qiu
 * @Date: 2020-10-29 11:50:12
 * @LastEditors: Michael Zaghi
 * @LastEditTime: 2021-01-15 10:08:12 AM
 * @Description: appointments table
 */

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import PhoneIcon from "@material-ui/icons/Phone";
import VideocamIcon from "@material-ui/icons/Videocam";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import More from "./More";
import PatientContact from "./PatientContact";
import PatientInfo from "./PatientInfo";
import ServerDownModal from "./ServerDownModal";
import UsePagination from "./UsePagination";

const ADMIN_APP_BASE_URL = process.env.REACT_APP_PORTON_ADMIN_API || "/api";

function createHeadData(id, label) {
  return { id, label };
}

function formatString(str) {
  if (str) {
    return str
      .replace(/(\B)[^ ]*/g, (match) => match.toLowerCase())
      .replace(/^[^ ]/g, (match) => match.toUpperCase());
  } else {
    return;
  }
}

function getName(post) {
  if (post) {
    var res;
    res = post.split(",");
    return `${formatString(res[1])} ${formatString(res[0])}`;
  } else {
    return "NO NAME";
  }
}

function getTime(post) {
  let str = post.replace("Z", "");
  const date = new Date(str);
  return `${date.toDateString()} ${date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })}`;
}

const useStyles = makeStyles({
  table: {
    minWidth: 600,
  },
  button: {
    color: "#3b3b3b",
    borderRadius: "10px",
    padding: "0px",
    margin: "5px",
    background: "#EAEAEA",
    border: "1.5px solid #BDBDBD",
    boxSizing: " border-box",
  },
  view: {
    "@media screen and (max-width: 601px)": {
      width: "390px",
    },
    "@media (max-width: 425px)": {
      width: "400px",
    },

    "@media screen and (min-width: 1024px)": {
      width: "100%",
    },
  },
  dotIcon: {
    display: "flex",
    alignItems: "center",
  },
  rowColor: {
    backgroundColor: "#66C6A7",
    color: "white",
  },
  checkInButton: {
    margin: "8px 0",
    background: "rgba(102, 198, 167, 0.3)",
    borderRadius: "4px",
    border: "1.5px solid #71BDA4",
    padding: "7px 16px",
    color: "#000",
  },
  notCheckInButton: {
    margin: "8px 0",
    background: "rgba(241, 190, 0, 0.3)",
    borderRadius: "4px",
    padding: "7px 16px",
    color: "#000",
  },
  cancelledButton: {
    margin: "8px 0",
    background: "rgb(194, 194, 214)",
    borderRadius: "4px",
    padding: "7px 16px",
    color: "#000",
  },
  billedButton: {
    padding: "7px",
    margin: "8px 0",
    background: "rgba(93, 148, 200, 0.3)",
    borderRadius: "4px",
    color: "#000",
  },
  pickedButton: {
    padding: "7px",
    margin: "8px 0",
    background: "rgba(196, 149, 205, 0.3)",
    borderRadius: "4px",
    color: "#000",
  },
  emptyRoomButton: {
    padding: "7px",
    margin: "8px 0",
    background: "rgb(204, 255, 255)",
    borderRadius: "4px",
    color: "#000",
  },
  noShowButton: {
    padding: "7px",
    margin: "8px 0",
    background: "rgba(219, 88, 88, 0.3)",
    borderRadius: "4px",
    color: "#000",
  },
  noScrollContent: {
    overflowY: "visible",
  },
  statusButton: {
    padding: "7px",
    margin: "8px 0",
    borderRadius: "4px",
    color: "#000",
  },
  selectedBtn: {
    backgroundColor: "#D3E2FF",
  },
  sortPanel: {
    boxShadow: "0px 2px 4px rgba(8, 35, 48, 0.24)",
    borderRadius: "8px",
    padding: "4px 16px 4px 10px",
    marginBottom: "15px",
    backgroundColor: "#fff",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function headName(label) {
  // return the full name of the cell for the table head
  if (label === "email") {
    return "Email";
  } else if (label === "provider") {
    return "Provider";
  } else if (label === "hin") {
    return "Health Insurance Number";
  } else if (label === "phone") {
    return "Phone Number";
  } else if (label === "time") {
    return "Time";
  } else if (label === "type") {
    return "Type";
  } else if (label === "status") {
    return "Status";
  }
}

export default function AppointmentTable({
  queueOption,
  displayValues,
  data,
  filter,
  setData,
  setDataDoctors,
  searchResults,
  providerFilter,
  checkedIn,
  setCheckedIn,
  setAllStatuses,
  providerFilters,
  statusFilters,
}) {
  const [address, setAddress] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [doctors, setDoctors] = useState();
  const [loading, setLoading] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(7);
  const [selectedId, setSelectedId] = useState([]);
  const [post, setPost] = useState();
  const [openPatient, setOpenPatient] = useState(false);
  const [callInIfHover, setCallInIfHover] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [contactInfo, setContactInfo] = useState();
  const [statuses, setStatuses] = useState();
  const [allStatusTypes, setAllStatusTypes] = useState([]);

  //sorting states
  const [sortBy, setSortBy] = useState("Time");
  const [sortState, setSortState] = useState({
    openDoctorDialog: false,
    openStatusDialog: false,
    applyDoctorDialog: false,
    applyStatusDialog: false,
    sortProviderAsc: false,
    sortProviderDesc: true,
    sortStatusAsc: false,
    sortStatusDesc: true,
    sortTimeAsc: false,
    sortTimeDesc: true,
  });
  const [providerFilterNames, setProviderFilterNames] = useState({});
  const [statusFilter, setStatusFilter] = useState({});
  const [cache, setCache] = useState();
  const [ttl, setTtl] = useState();
  const [date, setDate] = useState();

  // error Alert when server down
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const date = new Date();
    const currDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    const ttl = 86400 - date.getHours() * 3600;
    setDate(currDate);
    setTtl(ttl);
    const key = `${global.state.selectedOrg._id}_sentNotification`;
    const fetchCache = async () => {
      const result = await global.api.getCache({ key: key });
      // console.log('result', result)
      if (result.status === 500) {
        const createCache = await global.api.postCache({
          key: key,
          value: { [currDate]: {} },
          ttl: ttl,
        });
      } else {
        setCache(JSON.parse(result.result.data.data));
      }
    };
    fetchCache();
  }, []);

  const global = React.useContext(GlobalContext);
  let orgID;
  let org;

  let headCells = [];
  let headCellsForData = [];

  if (displayValues) {
    headCells.push(createHeadData("Name", "Name"));
    headCellsForData.push(createHeadData("Name", "Name"));
    for (var i in displayValues) {
      if (displayValues[i]) {
        headCellsForData.push(createHeadData(headName(i), headName(i)));
        headCells.push(createHeadData(headName(i), headName(i)));
      }
    }
    // headCells.push(createHeadData("", ""));
    // headCells.push(createHeadData("", ""));
  }

  //when the app gets opened the org object is empty so we need to check for nullity
  orgID =
    global.state.selectedOrg && Object.keys(global.state.selectedOrg).length
      ? global.state.selectedOrg._id
      : global.state.defaultOrg._id;
  org =
    global.state.selectedOrg && Object.keys(global.state.selectedOrg).length
      ? global.state.selectedOrg
      : global.state.defaultOrg;

  useEffect(() => {
    const date = new Date();

    // orgID = global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg._id : global.state.defaultOrg._id
    // org = global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg : global.state.defaultOrg

    setLoading(true);
    
    const fetchItems = async () => {
      //this code gets excuted whenever a an org is selected, it pulls the appoinments from emr and then it does some data cleaning
      //it gets excuted when count var changes, to refresh the queue
      try {
        let sts = await global.api.fetchStatuses(orgID);
        if(sts?.data?.message) {
          setStatuses(sts.data.message);
          setAllStatuses(sts.data.message);
        }
      } catch (error) {
        // setIsError(true);
        // console.log("error getting statuses for selected org", error);
      }
      let response = null;
      try {
        response = await global.api.getAppointmentByQueue(
          orgID,
          date.toDateString(),
          5000
        );
        response?.data && setAppointments(response.data);
      } catch (e) {
        setIsError(true);
      }

      try {
        const response2 = await global.api.fetchDoctorList(orgID, 5000);

        if(response2?.data) {
          setDoctors(response2.data);
          setDataDoctors(response2.data);
        }
      } catch (e) {
        setIsError(true);
      }

      if (response?.data) {
        var appList = [];
        let counter = 0;
        response.data.map((post) => {
          if (post) {
            var temp = post[Object.keys(post)[0]];
            temp.map((post2) => {
              if (post2?.demographicNo !== 0) {
                appList.push(post2);
              }
              if (post2.status === org.statuses.checked_in) {
                counter++;
              }
            });
          }
        });
        setCheckedIn(counter);
        setData(appList);
        setLoading(false);
      }
    };

    orgID && fetchItems();

    const interval = setInterval(() => {
      fetchItems();
    }, 2 * 60 * 1000); //refresh the page every 3 minutes

    return () => {
      clearInterval(interval);
    };
  }, [global.state.defaultOrg, global.state.selectedOrg, global.count]);

  useEffect(() => {
    setInfoLoading(true);

    let postValue;
    for (const i in post) {
      if (Object.keys(post[i]).includes("post")) {
        postValue = post[i].post;
      }
    }
    const fetchInfo = async () => {
      try {
        //   const response = await axios.get(`${ADMIN_APP_BASE_URL}/appointment/getAddress`, {params: {org: orgID, demo: postValue.demographicNo}});
        if(postValue?.demographicNo) {
          const response = await global.api.getAddress(
            orgID,
            postValue.demographicNo
          );
          response?.data && setAddress(response.data);
          setInfoLoading(false);
        }
      } catch (error) {
        setInfoLoading(false);
      }
    };
    fetchInfo();
  }, [post]);

  const [openSnackBar, setOpenSnackBar] = React.useState({
    open: false,
    name: null,
  });

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar({ open: false, name: null });
  };

  const removeFromQueue = async (appointment) => {
    const changeTo = org.statuses.picked;
    let apt;
    for (const i in appointment) {
      if (appointment[i].post) apt = appointment[i].post;
    }

    let updateStatus = await global.api.updateAppointmentStatus({
      appointment_id: apt.id,
      org_id: orgID,
      change_to: changeTo,
    });
    setSelectedId([...selectedId, apt.id]);
    global.setCount(!global.count);
  };

  const classes = useStyles();

  // function to get doctors name
  function getDoctorName(post) {
    const doctor = doctors.find((x) => x.providerNo === String(post));
    if (doctor) {
      return `Dr. ${doctor.firstName} ${doctor.lastName}`;
    }
  }

  function getStatus(post) {
    //get the status description from oscar by using the status code

    let statusDescription = "";
    let statusCode = post.charAt(0);
    statuses &&
      statuses.forEach((status) => {
        // To do: optimize this by using other data struture algoritm
        if (status.status === statusCode) {
          statusDescription = status.description;
        }
      });

    !allStatusTypes.includes(statusCode) &&
      setAllStatusTypes([...allStatusTypes, statusCode]); //avoid pushing duplicates
    return statusDescription;
  }

  function createData(head, post) {
    let arr = [];
    for (let i in head) {
      if (head[i].id === "Name") {
        arr.push({ name: getName(post.name) });
        // } else if (head[i].id === 'Email') {
        //     arr.push({email: post.email})
      } else if (head[i].id === "Provider") {
        arr.push({ provider: getDoctorName(post.providerNo) });
        // } else if (head[i].id === 'Health Insurance Number') {
        //     arr.push({hin: post.hin})
        // } else if (head[i].id === 'Phone Number') {
        //     arr.push({phone: post.phone})
      } else if (head[i].id === "Time") {
        arr.push({ time: getTime(post.appointmentStartDateTime) });
      } else if (head[i].id === "Type") {
        arr.push({ type: post.type });
      } else if (head[i].id === "Status") {
        arr.push({ status: getStatus(post.status) });
      }
    }
    arr.push({ post: post });
    return arr;
  }

  // non filtered appointment list
  var appList = [];
  if (appointments !== []) {
    appointments.map((post) => {
      if (post) {
        var temp = post[Object.keys(post)[0]];
        temp.map((post2) => {
          if (post2?.demographicNo !== 0) {
            appList.push(post2);
          }
        });
      }
    });
  }
  // filtered appointment list; only checked in and not checked in
  var appListFiltered = [];
  if (appList !== []) {
    appList.map((post) => {
      if (post) {
        if (
          (post?.demographicNo !== 0 &&
            post.status === org.statuses.checked_in) ||
          post.status === org.statuses.not_checked_in
        ) {
          appListFiltered.push(post);
        }
      }
    });
  }

  //sort the queue by status, provider, time
  if (sortBy === "Status" || sortBy === "Provider" || sortBy === "Time") {
    sortBy === "Provider" &&
      sortState.sortProviderAsc &&
      appList.sort((a, b) =>
        getDoctorName(a.providerNo) > getDoctorName(b.providerNo) ? 1 : -1
      );
    sortBy === "Provider" &&
      sortState.sortProviderDesc &&
      appList.sort((a, b) =>
        getDoctorName(a.providerNo) < getDoctorName(b.providerNo) ? 1 : -1
      );

    sortBy === "Status" &&
      sortState.sortStatusAsc &&
      appList.sort((a, b) => {
        let getFullNameOfStatusA = getStatus(a.status)
          ? getStatus(a.status)
          : "zzzz"; //rare case -> put status in the end of queue
        let getFullNameOfStatusB = getStatus(b.status)
          ? getStatus(b.status)
          : "zzzz";
        return getFullNameOfStatusA.localeCompare(getFullNameOfStatusB);
      });

    sortBy === "Status" &&
      sortState.sortStatusDesc &&
      appList.sort((a, b) => {
        let getFullNameOfStatusA = getStatus(a.status)
          ? getStatus(a.status)
          : "zzzz";
        let getFullNameOfStatusB = getStatus(b.status)
          ? getStatus(b.status)
          : "zzzz";
        return getFullNameOfStatusB.localeCompare(getFullNameOfStatusA);
      });

    sortBy === "Time" &&
      sortState.sortTimeAsc &&
      appList.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
    sortBy === "Time" &&
      sortState.sortTimeDesc &&
      appList.sort((a, b) => (a.startTime < b.startTime ? 1 : -1));
    //default
    // sortBy != 'Time' && !sortState.sortProviderAsc && !sortState.sortProviderDesc && !sortState.sortStatusAsc && !sortState.sortStatusDesc && appList.sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
  }

  //default
  // sortBy === '' && appList.sort((a, b) => (a.startTime > b.startTime ? 1 : -1))

  //for live queue
  appListFiltered.sort((a, b) => (a.startTime < b.startTime ? 1 : -1));

  // puts all the appointments into single array
  if (!loading && appointments !== [] && address !== []) {
    // if (!loading && appointments !== []) {
    var rows = [];
    if (filter !== "" && searchResults && queueOption) {
      //queueOption is for live queue
      searchResults.map((post) => {
        if (
          post.status === org.statuses.checked_in ||
          post.status === org.statuses.not_checked_in
        ) {
          if (
            (providerFilterNames[getDoctorName(post.providerNo)] &&
              statusFilter[getStatus(post.status)]) || //if the status and provider for this appt allowed to display
            (!providerFilterNames && !statusFilter) || //if provider and status filters are empty
            (providerFilterNames[getDoctorName(post.providerNo)] &&
              !Object.keys(statusFilter).length) || //if provider allowed to display but status filter is empty
            (statusFilter[getStatus(post.status)] &&
              !Object.keys(providerFilterNames).length) || //if status allowed to display but provider filter is empty
            (providerFilterNames[getDoctorName(post.providerNo)] &&
              Object.keys(statusFilter).every((k) => !statusFilter[k])) || //if provider allowed to display and status filter statuses all not checked
            (statusFilter[getStatus(post.status)] &&
              Object.keys(providerFilterNames).every(
                (k) => !providerFilterNames[k]
              )) ||
            (!Object.keys(statusFilter).length &&
              !Object.keys(providerFilterNames).length) ||
            (Object.keys(statusFilter).every((k) => !statusFilter[k]) &&
              Object.keys(providerFilterNames).every(
                (k) => !providerFilterNames[k]
              )) //if providers in filter, and status filter statuses all not checked
          ) {
            rows.push(createData(headCellsForData, post));
          }
        }
      });
    } else if (filter !== "" && searchResults) {
      searchResults.map((post) => {
        if (
          (providerFilterNames[getDoctorName(post.providerNo)] &&
            statusFilter[getStatus(post.status)]) ||
          (!providerFilterNames && !statusFilter) ||
          (providerFilterNames[getDoctorName(post.providerNo)] &&
            !Object.keys(statusFilter).length) ||
          (statusFilter[getStatus(post.status)] &&
            !Object.keys(providerFilterNames).length) ||
          (providerFilterNames[getDoctorName(post.providerNo)] &&
            Object.keys(statusFilter).every((k) => !statusFilter[k])) ||
          (statusFilter[getStatus(post.status)] &&
            Object.keys(providerFilterNames).every(
              (k) => !providerFilterNames[k]
            )) ||
          (!Object.keys(statusFilter).length &&
            !Object.keys(providerFilterNames).length) ||
          (Object.keys(statusFilter).every((k) => !statusFilter[k]) &&
            Object.keys(providerFilterNames).every(
              (k) => !providerFilterNames[k]
            ))
        ) {
          rows.push(createData(headCellsForData, post));
        }
      });
    } else if (queueOption) {
      appListFiltered.map((post) => {
        if (
          (providerFilterNames[getDoctorName(post.providerNo)] &&
            statusFilter[getStatus(post.status)]) ||
          (!providerFilterNames && !statusFilter) ||
          (providerFilterNames[getDoctorName(post.providerNo)] &&
            !Object.keys(statusFilter).length) ||
          (statusFilter[getStatus(post.status)] &&
            !Object.keys(providerFilterNames).length) ||
          (providerFilterNames[getDoctorName(post.providerNo)] &&
            Object.keys(statusFilter).every((k) => !statusFilter[k])) ||
          (statusFilter[getStatus(post.status)] &&
            Object.keys(providerFilterNames).every(
              (k) => !providerFilterNames[k]
            )) ||
          (!Object.keys(statusFilter).length &&
            !Object.keys(providerFilterNames).length) ||
          (Object.keys(statusFilter).every((k) => !statusFilter[k]) &&
            Object.keys(providerFilterNames).every(
              (k) => !providerFilterNames[k]
            ))
        ) {
          rows.push(createData(headCellsForData, post));
        }
      });
    } else {
      appList.map((post) => {
        if (
          (providerFilterNames[getDoctorName(post.providerNo)] &&
            statusFilter[getStatus(post.status)]) ||
          (!providerFilterNames && !statusFilter) ||
          (providerFilterNames[getDoctorName(post.providerNo)] &&
            !Object.keys(statusFilter).length) ||
          (statusFilter[getStatus(post.status)] &&
            !Object.keys(providerFilterNames).length) ||
          (providerFilterNames[getDoctorName(post.providerNo)] &&
            Object.keys(statusFilter).every((k) => !statusFilter[k])) ||
          (statusFilter[getStatus(post.status)] &&
            Object.keys(providerFilterNames).every(
              (k) => !providerFilterNames[k]
            )) ||
          (!Object.keys(statusFilter).length &&
            !Object.keys(providerFilterNames).length) ||
          (Object.keys(statusFilter).every((k) => !statusFilter[k]) &&
            Object.keys(providerFilterNames).every(
              (k) => !providerFilterNames[k]
            ))
        ) {
          rows.push(createData(headCellsForData, post));
        }
      });
    }
  }

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  let currentPosts = null;
  const [filters, setFilters] = useState([]);
  // To generate a filter list after clicking "Filter By" button
  useEffect(() => {
    let newFilters = [];
    if (Object.keys(providerFilters).length) {
      for (let providerNo in providerFilters) {
        if (
          providerNo !== "selectAll" &&
          providerFilters[providerNo] === true
        ) {
          newFilters.push({ providerNo: providerNo });
        }
      }
    }
    if (Object.keys(statusFilters).length) {
      for (let status in statusFilters) {
        if (status !== "selectAll" && statusFilters[status] === true) {
          let found = statuses.find((e) => e.description === status);
          if (found) newFilters.push({ status: found.status });
        }
      }
    }
    setFilters(newFilters);
  }, [providerFilters, statusFilters]);

  // Update rows here if "Filter By" is applied
  if (!loading && rows !== [] && appointments !== []) {
    if (filters.length) {
      let filteredArr = [];
      rows.map((post) => {
        post.map((apt) => {
          if (apt.post) {
            for (let filter of filters) {
              if (
                filter.providerNo &&
                String(filter.providerNo) === String(apt.post.providerNo)
              ) {
                filteredArr.push(apt.post.id);
              } else if (
                filter.status &&
                String(filter.status) === String(apt.post.status)
              ) {
                filteredArr.push(apt.post.id);
              }
            }
          }
        });
      });
      // Remove dupicates by condition
      let removeDuplicats = [];
      if (
        filters.find((e) => Object.keys(e)[0] === "providerNo") &&
        filters.find((e) => Object.keys(e)[0] === "status")
      ) {
        removeDuplicats = filteredArr.filter(
          (id, index) => filteredArr.indexOf(id) !== index
        );
      } else {
        removeDuplicats = filteredArr.filter(
          (id, index) => filteredArr.indexOf(id) === index
        );
      }
      // Update rows
      let newRows = [];
      removeDuplicats.map((id) => {
        for (let row of rows) {
          for (let post of row) {
            if (post.post) {
              if (String(post.post.id) === String(id)) {
                newRows.push(row);
              }
            }
          }
        }
      });
      rows = newRows;
    }
    currentPosts = rows.slice(indexOfFirstPost, indexOfLastPost);
  }

  // Pagination Number
  const pageNumbers = [];
  if (!loading && rows !== [] && appointments !== []) {
    for (let i = 1; i <= Math.ceil(rows.length / postsPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  const handleProviderSelect = (event) => {
    setProviderFilterNames({
      ...providerFilterNames,
      ["Dr. " + event.target.name]: event.target.checked,
    });
  };

  const handleStatusSelect = (event) => {
    setStatusFilter({
      ...statusFilter,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [
    providerFilter,
    searchResults,
    queueOption,
    providerFilterNames,
    statusFilter,
    providerFilters,
    statusFilters,
  ]);

  // Change Pagination Number
  const paginate = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function returnPost(post) {
    if (post) {
      for (const i in post) {
        if (post[i].post) {
          return post[i].post;
        }
      }
    }
  }

  function returnPostId(post) {
    if (post) {
      for (const i in post) {
        if (post[i].post) {
          return post[i].post.id;
        }
      }
    } else return;
  }

  function returnStatus(post) {
    if (post) {
      for (const i in post) {
        if (post[i].post) {
          return post[i].post.status;
        }
      }
    } else return;
  }

  const aptType = (post) => {
    //display different icons for different appoinment types
    if (post === "In Person") {
      return (
        <Grid item alignItems="center" wrap={"nowrap"}>
          <PersonPinCircleIcon className={classes.icon} fontSize="small" />
          {post}
        </Grid>
      );
    } else if (post === "Telephone" || post === "Phone") {
      return (
        <Grid container item alignItems="center" wrap={"nowrap"}>
          <PhoneIcon className={classes.icon} fontSize="small" />
          {post}
        </Grid>
      );
    } else if (post === "Video") {
      return (
        <Grid container item alignItems="center" wrap={"nowrap"}>
          <VideocamIcon className={classes.icon} fontSize="small" />
          {post}
        </Grid>
      );
    } else {
      return (
        <Grid container item alignItems="center" wrap={"nowrap"}>
          <PersonPinCircleIcon className={classes.icon} fontSize="small" />
          {post}
        </Grid>
      );
    }
  };

  const handleHover = (postId, type) => {
    //console.log('type', type)
    if (type === "email") {
      setCallInIfHover({ on: true, id: postId, type: "email" });
    } else if (type === "voice") {
      setCallInIfHover({ on: true, id: postId, type: "voice" });
    } else if (type === "phone") {
      setCallInIfHover({ on: true, id: postId, type: "phone" });
    } else {
      setCallInIfHover({ on: false, id: postId });
    }
  };

  const colorByStatus = (postStatus, post) => {
    let status = postStatus.status;
    let postId = returnPostId(post);
    let postPost = returnPost(post);

    let bg = "";
    let isCheckedIn = "";

    statuses &&
      statuses.forEach((st) => {
        if (status.includes(st.description)) {
          bg = st.color;
        }
        if (st.status === org.statuses.checked_in) {
          isCheckedIn = st.description;
        }
      });

    if (status === isCheckedIn) {
      // for some checked in users, display 'call-in' option when hovering btn
      let parseComment;
      let type;
      let string;
      if (postPost.notes) {
        parseComment = postPost.notes.replace(/\s/g, "");
        parseComment = parseComment.split(/[:|]/);
        var indexType = null,
          indexString = null;
        for (let i = 0; i < parseComment.length; i++) {
          if (
            parseComment[i] === "email" ||
            parseComment[i] === "voice" ||
            parseComment[i] === "phone"
          ) {
            indexType = i;
            indexString = i + 1;
            break;
          }
        }

        if (indexType !== null) {
          type = parseComment[indexType];
          string = parseComment[indexString];
        }
      }
      return !callInIfHover.on || callInIfHover.id !== postId ? (
        <div
          className={classes.statusButton}
          style={{ backgroundColor: bg }}
          onMouseEnter={() => handleHover(postId, type)}
        >
          {postPost.status.charAt(1) === "S" ? (
            <Typography
              style={{
                textAlign: "center",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >{`${status}: Signed`}</Typography>
          ) : (
            <Typography
              style={{
                textAlign: "center",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              {status}
            </Typography>
          )}
        </div>
      ) : (
        callInIfHover.id === postId && (
          <div
            className={classes.statusButton}
            style={{ backgroundColor: bg }}
            style={{
              backgroundColor: "#118761",
              color: "#fff",
              cursor: "pointer",
            }}
            onMouseLeave={() => handleHover(postId)}
            onClick={() => {
              setContactInfo({ type: type, string: string, info: postPost });
              setOpenContact(true);
            }}
          >
            {callInIfHover.type === "email" ? (
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Email In
              </Typography>
            ) : callInIfHover.type === "voice" ? (
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Call In
              </Typography>
            ) : (
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Text In
              </Typography>
            )}
          </div>
        )
      );
    } else {
      return (
        <div className={classes.statusButton} style={{ backgroundColor: bg }}>
          {postPost.status.charAt(1) === "S" ? (
            <Typography
              style={{
                textAlign: "center",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >{`${status}: Signed`}</Typography>
          ) : (
            <Typography
              style={{
                textAlign: "center",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              {status}
            </Typography>
          )}
        </div>
      );
    }
  };

  if (
    (typeof global.state.selectedOrg != "undefined" &&
      global.state.selectedOrg &&
      global.state.selectedOrg != null &&
      JSON.stringify(global.state.selectedOrg) != "{}") ||
    (typeof global.state.defaultOrg != "undefined" &&
      global.state.defaultOrg &&
      global.state.defaultOrg != null &&
      JSON.stringify(global.state.defaultOrg) != "{}")
  ) {
    return (
      <div>
        {isError && <ServerDownModal />}
        {loading && address !== [] ? (
          orgID ? (
            <Skeleton
              animation="wave"
              height="250px"
              width="100%"
              background="#fff"
            />
          ) : (
            <div style={{ padding: "10px" }}> No appointments for this org</div>
          )
        ) : (
          <>
            {Object.keys({ ...statusFilter, ...providerFilterNames }).length &&
            !(
              Object.keys(statusFilter).every((k) => !statusFilter[k]) &&
              Object.keys(providerFilterNames).every(
                (k) => !providerFilterNames[k]
              )
            ) ? (
              <Grid container className={classes.sortPanel}>
                <Grid item md={1} xs={1} style={{ alignSelf: "center" }}>
                  <typography style={{ color: "#767676" }}>
                    Filter By{" "}
                  </typography>
                </Grid>

                <Grid container md={10} xs={10}>
                  {statusFilter &&
                    Object.keys(statusFilter).map(
                      (status, i) =>
                        statusFilter[status] && (
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key={i}
                                  color="primary"
                                  defaultChecked={true}
                                  onChange={(event) => {
                                    setStatusFilter({
                                      ...statusFilter,
                                      [event.target.name]: false,
                                    });
                                  }}
                                  name={status}
                                />
                              }
                              label={
                                <Typography className={classes.typography}>
                                  {status ? status : "Status Not Recognized"}
                                </Typography>
                              }
                            />
                          </Grid>
                        )
                    )}

                  {providerFilterNames &&
                    Object.keys(providerFilterNames).map(
                      (provider, i) =>
                        providerFilterNames[provider] && (
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key={i}
                                  color="primary"
                                  defaultChecked={true}
                                  onChange={(event) => {
                                    setProviderFilterNames({
                                      ...providerFilterNames,
                                      [event.target.name]: false,
                                    });
                                  }}
                                  name={provider}
                                />
                              }
                              label={
                                <Typography className={classes.typography}>
                                  {provider}
                                </Typography>
                              }
                            />
                          </Grid>
                        )
                    )}
                </Grid>

                <Grid item md={1} xs={1}>
                  <Button
                    onClick={() => {
                      setSortState({
                        sortProviderAsc: false,
                        sortProviderDesc: false,
                        sortStatusAsc: false,
                        sortStatusDesc: false,
                      });
                      setStatusFilter({});
                      setProviderFilterNames({});
                    }}
                    style={{ color: "#D14444", padding: 0 }}
                  >
                    Clear All{" "}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            <TableContainer className={classes.view} component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell, i) => (
                      <TableCell>
                        <Grid
                          key={i}
                          container
                          direction="row"
                          alignItems="center"
                          whiteSpace="nowrap"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: "18px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {headCell.label}
                          </Typography>
                          {headCell.label &&
                            headCell.label !== "Name" &&
                            headCell.label !== "Type" &&
                            !queueOption &&
                            (sortState[`sort${headCell.label}Asc`] ? (
                              <ExpandLessIcon
                                onClick={() => {
                                  setSortBy(headCell.label);
                                  setSortState({
                                    ...sortState,
                                    [`sort${headCell.label}Desc`]: true,
                                    [`sort${headCell.label}Asc`]: false,
                                    [`sort${sortBy}Desc`]: true,
                                    [`sort${sortBy}Asc`]: false,
                                  });
                                }}
                              />
                            ) : (
                              <ExpandMoreIcon
                                onClick={() => {
                                  setSortBy(headCell.label);
                                  setSortState({
                                    ...sortState,
                                    [`sort${headCell.label}Desc`]: false,
                                    [`sort${headCell.label}Asc`]: true,
                                    [`sort${sortBy}Desc`]:
                                      sortBy !== headCell.label ? true : false,
                                    [`sort${sortBy}Asc`]:
                                      sortBy !== headCell.label ? false : true,
                                  });
                                }}
                              />
                            ))}
                        </Grid>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPosts.map((post, i) =>
                    !selectedId.includes(returnPostId(post)) ? (
                      <StyledTableRow
                        key={`post-${i}`}
                        style={{
                          backgroundColor:
                            post.status === "Checked-In" &&
                            "rgba(102, 198, 167, 0.3)",
                        }}
                      >
                        {post.map((post2, i) => (
                          <>
                            {post2.name ? (
                              <StyledTableCell
                                key={i}
                                style={{ cursor: "pointer", width: "20%" }}
                                onClick={() => {
                                  setPost(post);
                                  setOpenPatient(true);
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {post2.name}
                                </Typography>
                              </StyledTableCell>
                            ) : post2.provider ? (
                              <StyledTableCell
                                style={{ cursor: "pointer", width: "15%" }}
                                onClick={() => {
                                  setPost(post);
                                  setOpenPatient(true);
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {post2.provider}
                                </Typography>
                              </StyledTableCell>
                            ) : post2.time ? (
                              <StyledTableCell
                                style={{ cursor: "pointer", width: "20%" }}
                                onClick={() => {
                                  setPost(post);
                                  setOpenPatient(true);
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {post2.time}
                                </Typography>
                              </StyledTableCell>
                            ) : post2.type ? (
                              <StyledTableCell
                                style={{ cursor: "pointer", width: "16%" }}
                                onClick={() => {
                                  setPost(post);
                                  setOpenPatient(true);
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "16px",
                                  }}
                                  noWrap
                                >
                                  {aptType(post2.type)}
                                </Typography>
                              </StyledTableCell>
                            ) : selectedId.includes(returnPostId(post)) ? (
                              "Picked"
                            ) : post2.status ? (
                              <StyledTableCell style={{ width: "15%" }}>
                                {colorByStatus(post2, post)}
                              </StyledTableCell>
                            ) : post2.post ? (
                              <></>
                            ) : (
                              <StyledTableCell
                                style={{
                                  cursor: "pointer",
                                  paddingTop: "35px",
                                  paddingBottom: "35px",
                                }}
                                onClick={() => {
                                  setPost(post);
                                  setOpenPatient(true);
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  Data Not Available
                                </Typography>
                              </StyledTableCell>
                            )}
                          </>
                        ))}
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell
                          onClick={() => {
                            setPost(post);
                          }}
                        >
                          {returnStatus(post) === org.statuses.checked_in ||
                          returnStatus(post) === org.statuses.not_checked_in ? (
                            <More
                              post={post}
                              onRemove={() => {
                                removeFromQueue(post);
                              }}
                              populatedValues={address}
                            />
                          ) : (
                            <> </>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      <></>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justify="center" md={12} xs={12}>
              <UsePagination
                count={pageNumbers.length}
                onChange={paginate}
                page={currentPage}
                shape="rounded"
                style={{ marginTop: "20px" }}
              />
            </Grid>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={openSnackBar.open}
              autoHideDuration={6000}
              onClose={handleCloseSnackBar}
              message={`Patient, ${openSnackBar.name} just checked in`}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleCloseSnackBar}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
            {post && (
              <PatientInfo
                selectedValue={post}
                populatedValues={address}
                doctors={doctors}
                loading={infoLoading}
                open={openPatient}
                onClose={() => setOpenPatient(false)}
                refreshQueue={() => global.setCount(!global.count)}
              />
            )}
            <PatientContact
              open={openContact}
              info={contactInfo}
              onClose={() => setOpenContact(false)}
              doctors={doctors}
              org={global.state.selectedOrg}
              cache={cache}
              ttl={ttl}
              date={date}
            />

            <Dialog
              open={sortState.openDoctorDialog}
              onClose={() =>
                setSortState({ ...sortState, openDoctorDialog: false })
              }
              maxWidth="xl"
            >
              <DialogTitle>{"Filter By Provider "}</DialogTitle>
              <DialogContent>
                {doctors &&
                  doctors.map((post, i) => (
                    <Grid item md={12} xs={12} key={i}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            defaultChecked={
                              providerFilterNames[
                                String(`Dr. ${post.firstName} ${post.lastName}`)
                              ]
                            }
                            onChange={handleProviderSelect}
                            name={String(`${post.firstName} ${post.lastName}`)}
                          />
                        }
                        label={
                          <Typography className={classes.typography}>
                            {getDoctorName(post.providerNo)}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
              </DialogContent>
              <DialogContent className={classes.noScrollContent}>
                <DialogContentText style={{ color: "black" }}>
                  Sort Results By:
                </DialogContentText>
                <Grid container direction="row">
                  <Grid container item xs={6}>
                    <Button
                      className={
                        sortState.sortProviderAsc && classes.selectedBtn
                      }
                      onClick={() => {
                        setSortState({
                          ...sortState,
                          sortProviderAsc: true,
                          sortProviderDesc: false,
                        });
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      {"A -> Z"}
                    </Button>
                  </Grid>
                  <Grid container item xs={6}>
                    <Button
                      className={
                        sortState.sortProviderDesc && classes.selectedBtn
                      }
                      onClick={() => {
                        setSortState({
                          ...sortState,
                          sortProviderAsc: false,
                          sortProviderDesc: true,
                        });
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      {"Z -> A"}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() =>
                    setSortState({ ...sortState, openDoctorDialog: false })
                  }
                  color="primary"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={sortState.openStatusDialog}
              onClose={() =>
                setSortState({ ...sortState, openStatusDialog: false })
              }
              maxWidth="xl"
            >
              <DialogTitle>{"Filter By Status"}</DialogTitle>
              <DialogContent>
                <DialogContent>
                  {allStatusTypes &&
                      allStatusTypes.map((status) => (
                          <Grid item md={12} xs={12} key={i}>
                            <FormControlLabel
                                control={
                                  <Checkbox
                                      color="primary"
                                      defaultChecked={
                                        statusFilter[String(getStatus(status))]
                                      }
                                      onChange={handleStatusSelect}
                                      name={String(getStatus(status))}
                                  />
                                }
                                label={
                                  <Typography className={classes.typography}>
                                    {getStatus(status)
                                        ? getStatus(status)
                                        : "Status Not Recognized"}
                                  </Typography>
                                }
                            />
                          </Grid>
                      ))}
                </DialogContent>
              </DialogContent>
              <DialogContent>
                <DialogContentText style={{ color: "black" }}>
                  Sort Results By:
                </DialogContentText>
                <Grid container direction="row">
                  <Grid container item xs={6}>
                    <Button
                      className={sortState.sortStatusAsc && classes.selectedBtn}
                      onClick={() => {
                        setSortState({
                          ...sortState,
                          sortStatusAsc: true,
                          sortStatusDesc: false,
                        });
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      {"A -> Z"}
                    </Button>
                  </Grid>
                  <Grid container item xs={6}>
                    <Button
                      className={
                        sortState.sortStatusDesc && classes.selectedBtn
                      }
                      onClick={() => {
                        setSortState({
                          ...sortState,
                          sortStatusAsc: false,
                          sortStatusDesc: true,
                        });
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      {"Z -> A"}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() =>
                    setSortState({ ...sortState, openStatusDialog: false })
                  }
                  color="primary"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}
