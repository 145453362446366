import { Box, Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import AccountProfileStyle from '../../../../../styles/AccountProfileStyles';


const ConfirmPopup = (props) =>{
    const classes = AccountProfileStyle()

    const handleClose = () => {
        props.setOpenAlert(false);
    };


    //to end org agreement have to end on the orgs end as well
    return(
        <div>
            <Dialog
                open={props.openAlert}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box>
                    <CheckCircleIcon fontSize='large'/>
                </Box>
                <DialogTitle className={classes.dialogText} id="alert-dialog-title">{props.defaultOrgName} has been set as your default organization.</DialogTitle>
                <DialogActions className={classes.centerButton}>
                    <Button onClick={handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ConfirmPopup