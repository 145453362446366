import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import InputBase from '@material-ui/core/InputBase';
import EmailIcon from '@material-ui/icons/Email';
import MicIcon from '@material-ui/icons/Mic';
import PhoneIcon from '@material-ui/icons/Phone';
import React from 'react';

export default function UrlComponent(props) {
    const classes = props.classes;
    const state = props.state;
    const setState = props.setState;
    const handleChange = props.handleChange;
    return (
        
        <Grid className={classes.notification}>
            <Grid container  >
                <Grid container direction="row" justify="space-between" className={classes.notificationPadding} item xs={12} sm={6}>
                    <Grid item md={4} xs={4} className={classes.iconButtonSpacing}>
                        
                        <Button className={classes.notifButton} fullWidth variant="outlined" onClick={() => {setState({...state, selected:"email"})}}>
                            <EmailIcon/> <Typography className={classes.text}>Email</Typography>
                        </Button>
                        
                    </Grid>
                    <Grid item md={4} xs={4} className={classes.iconButtonSpacing}>
                        
                        <Button className={classes.notifButton} fullWidth variant="outlined" onClick={() => {setState({...state, selected:"phone"})}}>
                            <PhoneIcon/> <Typography className={classes.text}>SMS</Typography>
                        </Button>    
                                    
                    </Grid>
                    <Grid item md={4} xs={4} >
                        
                        <Button className={classes.notifButton} fullWidth variant="outlined" onClick={() => {setState({...state, selected:"voice"})}}>
                            <MicIcon/> <Typography className={classes.text}>Voice</Typography>
                        </Button>   
                                
                    </Grid>
                </Grid>
                
                <Grid item sm={6} xs={12} className={classes.notificationTextboxSpacing}>
                    <div className={classes.input}>
                        <div className={classes.icon}>
                            {state.selected === 'voice' ?
                                <MicIcon/>
                                : state.selected === 'phone' ?
                                    <PhoneIcon/>
                                    : <EmailIcon/>
                            }                                        
                        </div>
                        {state.selected === 'voice' || state.selected === 'phone' ? 
                            <InputBase
                                classes={{
                                    root: classes.inputRoot, 
                                    input: classes.inputInput,
                                }}
                                className={classes.typeSpacing}
                                fullWidth
                                name={state.selected}
                                value={state.phone}
                                onChange={handleChange}
                                type={state.selected === 'email' ? 'email' : 'text'}
                                // inputProps={{
                                //     inputComponent: NumberFormatCustom,
                                // }}
                            />    
                        :
                            <InputBase
                                classes={{
                                    root: classes.inputRoot, 
                                    input: classes.inputInput,
                                }}
                                fullWidth
                                inputProps={{ 'aria-label': 'search' }}
                                name={state.selected}
                                value={state.email}
                                onChange={handleChange}
                                type={state.selected === 'email'}
                            />    
                        }
                    </div>
                </Grid>
            </Grid>
        </Grid>
        
    );
}

