/*
 * @Author: Andy Qiu
 * @Date: 2020-10-30 11:50:12
 * @LastEditors: Andy Qiu
 * @LastEditTime: 2020-10-30 11:50:12
 * @Description: more button
 */

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useState } from 'react';
import ContactForm from "./ContactForm";
import RemoveDialog from './RemoveDialog';

export default function More({post, onRemove, populatedValues}) {
    const [openRemove, setOpenRemove] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState()

    // Contact
    const [openContact, setOpenContact] = useState(false);

    const recordButtonPosition = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    }
  
    let closeMenu = () => {
        setMenuOpen(false);
    }
    let patientName;
    for (let i in post) {
        if (post[i].name) {
            patientName = post[i].name;
        }
    }

    const handleClose = () => {
        setOpenContact(false);
        setOpenRemove(false);
    };


    return (
        <div>

            <IconButton onClick={recordButtonPosition}>
                <MoreHorizIcon style={{color: '#3b3b3b'}}/>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={closeMenu}
            >
                <MenuItem onClick={() => (closeMenu(), setOpenRemove(true))}>Remove From Queue</MenuItem>
                <MenuItem onClick={() => (closeMenu(), setOpenContact(true))}>Contact</MenuItem>
            </Menu>
            <ContactForm open={openContact} onClose={handleClose} patientName={patientName} contactInfo={populatedValues}/>
            <RemoveDialog selectedValue={post} open={openRemove} onClose={handleClose} onRemove={onRemove}/>
        </div>
    )
}
