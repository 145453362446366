import { Typography } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import React from 'react';




export default function SMSVoiceMessage(props) {
    const classes = props.classes;
    const state = props.state;
    const maxLength = props.maxLength;
    const handleChange = props.handleChange;


    return (
        
        <div style={{paddingRight:'10px'}}>

            <Grid item md={12} xs={12} style={{paddingLeft: '25px'}}>
                <Typography className={classes.labelSmall} >Automated SMS & Voice Message</Typography>
                <TextField
                    id="outlined-multiline-static"
                    //label="Multiline"
                    multiline
                    rows={4}
                    name="checkInInstructions"
                    onChange={handleChange}
                    //disabled={!state.checkedCustomMessage}
                    value={state.checkInInstructions}
                    variant="outlined"
                    style={{"width": "100%", "marginTop": "7px", "marginBottom": "20px", paddingRight:'10px'}}
                />
                <div className={classes.textCounter} style={{ paddingRight:'10px' }}>
                    {state.cancellationPolicy ? state.cancellationPolicy.length : 0}/{ maxLength }
                </div>
            </Grid>

        </div>
        
    );
}