import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import React from "react";

export default function CheckInForms(props) {
    const classes = props.classes;
    const state = props.state;
    const setState = props.setState;
    const doctors = props.doctors;
    const providerFilter = props.providerFilter;
    const handleProviderSelect = props.handleProviderSelect;
    const getDoctorName = props.getDoctorName;

    return (
        //for tour might need to adjust height of dive for different screen size.
        <div
            className="checkInProviderTour"
            style={{ height: props.tourOpen ? "250px" : "" }}
        >
            <Grid
                container
                xs={12}
                direction="row"
                style={{ padding: "0 25px" }}
            >
                <Grid container xs={12} direction="row" justify="space-between">
                    <Grid container item xs={8}>
                        <Typography className={classes.labelSmall}>
                            Check-In System Provider List
                        </Typography>
                    </Grid>
                    <Grid container item xs={2} justifyContent="flex-end">
                        <Button
                            onClick={() =>
                                setState({ ...state, isProviderListOpen: true })
                            }
                            color={!state.isEditPressed ? "#767676" : "primary"}
                            style={{ marginLeft: "7%",fontWeight:600 }}
                            className={classes.blues}
                        >
                            EDIT LIST
                        </Button>
                    </Grid>
                </Grid>

                {doctors &&
                    providerFilter &&
                    Object.keys(doctors).length !== 0 &&
                    doctors.constructor !== Object &&
                    doctors.slice(0, 5).map((post, i) => (
                        <Grid container item sm={6} xs={12} key={i}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={
                                            !state.isEditPressed
                                                ? "#767676"
                                                : "primary"
                                        }
                                        checked={
                                            providerFilter[post.providerNo]
                                                ? false
                                                : true
                                        }
                                        onChange={handleProviderSelect}
                                        name={String(post.providerNo)}
                                    />
                                }
                                label={
                                    <Typography className={classes.typography}>
                                        {getDoctorName(post.providerNo)}
                                    </Typography>
                                }
                            />
                        </Grid>
                    ))}

                {doctors && doctors.length > 5 && (
                    <Grid container item sm={6} xs={12}>
                        <Button
                            className={
                                !state.isEditPressed
                                    ? classes.checkInAppMoreButtonDisabled
                                    : classes.checkInAppMoreButton
                            }
                            variant="contained"
                            onClick={() =>
                                setState({ ...state, isProviderListOpen: true })
                            }
                            color={!state.isEditPressed ? "#767676" : "primary"}
                        >
                            {" "}
                            {`+${doctors.length - 5} MORE - SEE ALL`}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
