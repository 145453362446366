import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Switch
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import GlobalContext from "../../../../../context/GlobalContext";
import settingsStyles from "../../../../../styles/settingsPageStyles";
import ImportPullSetting from "../../PullAppSetting/ImportPullSetting";

const autoPullInitialSettings = {
    doctorToggle: false,
    PatientToggle: false,
    AppointmentToggle: false,
    QueueToggle: false,
}

const ControlPullDataAutomation = () => {
    const [state, setState] = useState({});
    const [pullAppAutomation, setPullAppAutomation] = useState(false);
    const [isEmrIntegrationComplete, setIsEmrIntegrationComplete] = useState(
        false
    );

    const [autoPullSetting, setAutoPullSetting] = useState(autoPullInitialSettings);

    const classes = settingsStyles();
    const global = React.useContext(GlobalContext);
    let orgId = global.state.selectedOrg._id
        ? global.state.selectedOrg._id
        : global.state.defaultOrg._id;

    const saveChanges = async () => {
        try {
            await global.api.setAutoPullAppToggle(orgId, autoPullSetting);
        } catch (err) {
            // console.log(err);
        }
        // to do
        setState({ ...state, isEdit: false });
    };


    const getAutoPullToggle = async () => {
        // start routing
        // get toggles setting from mongoDB
        let result = await global.api.getAutoPullAppToggle(orgId);
        if (result.status === 200) {
            setAutoPullSetting({
                doctorToggle: result.data.data.data.doctorToggle,
                PatientToggle: result.data.data.data.PatientToggle,
                AppointmentToggle: result.data.data.data.AppointmentToggle,
                QueueToggle: result.data.data.data.QueueToggle,
            });
        } else {
            // not found default true
            // console.log(
            //     "WARNING: no org autoPullSettings found, setting defaults."
            // );
        }
    };

    const checkIfEmrIntegrationIsComplete = async () => {
        try {
            let res = await global.api.getIntegrationComplete(orgId);
            if (res.status === 200) {
                setIsEmrIntegrationComplete(res.data.integrationComplete);
                if (res.data.integrationComplete === false) {
                    // check if we need this later
                    // savePullAppAutomation(false)}
                }
            }
        } catch (error) {
            // console.log(
            //     "FAILED, emr integration complete status failed",
            //     error
            // );
            setIsEmrIntegrationComplete(false);
        }
    };

    const getPullAppAutomation = async () => {
        try {
            let res = await global.api.getPullAppAutomationSetting(orgId);
            if (res.status === 200) {
                setPullAppAutomation(res.data.savePullAppAutomation);
            }
        } catch (error) {
            // console.log("Get automation failed, error", error);
        }
    };
    const handleChange = (event) => {
        if (event.target.hasOwnProperty("checked")) {
            savePullAppAutomation(event.target.checked);
        }
    };

    const savePullAppAutomation = async (newValue) => {
        try {
            let res = await global.api.savePullAppAutomationSetting(orgId, {
                savePullAppAutomation: newValue,
            });
            if (res.status === 200) {
                await getPullAppAutomation();
            }
            if (!newValue) {
                // when pull app automation is turned off, turn off all other settings as well.
                await global.api.setAutoPullAppToggle(orgId,autoPullInitialSettings);
                setAutoPullSetting(autoPullInitialSettings)
            }
        } catch (error) {
            // console.log("save automation failed, error", error);
        }
    };

    useEffect(() => {
        checkIfEmrIntegrationIsComplete();
        getPullAppAutomation();
        getAutoPullToggle();
    }, []);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    className={!state.isEdit ? classes.disabledBackground : ""}
                >
                    <Grid item className={classes.toolBox} xs={12}>
                        <Box className={classes.labelWithBtn}>
                            <h3>Control Pull Data Automation</h3>
                            <Switch
                                color="primary"
                                size="small"
                                className={classes.switch}
                                checked={pullAppAutomation}
                                onClick={handleChange}
                                disabled={!isEmrIntegrationComplete}
                            ></Switch>
                        </Box>
                        {isEmrIntegrationComplete ? (
                            <p>
                                Turning on this option would pull data from
                                Oscar every 20 minutes automatically. Turning
                                off could help save CPU usage.
                            </p>
                        ) : (
                            <p>
                                Please set up integrating your clinic first
                                before you can access this feature.
                            </p>
                        )}
                        {
                            <ImportPullSetting
                                pullAppAutomation={pullAppAutomation}
                                autoPullSetting={autoPullSetting}
                                setAutoPullSetting={setAutoPullSetting}
                            />
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                {state.isEdit ? (
                    <Box>
                        {state.isLoading ? (
                            <Box display="flex" justifyContent="flex-end">
                                <CircularProgress
                                    className={classes.loadingCircle}
                                />
                            </Box>
                        ) : (
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                className={classes.saveBtnGroup}
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={saveChanges}
                                >
                                    Done 
                                </Button>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        className={classes.saveBtnGroup}
                    >
                        <Button
                            xs={12}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setState({ ...state, isEdit: true });
                            }}
                        >
                            Edit 
                        </Button>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default ControlPullDataAutomation;
