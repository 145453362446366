/*
 * @Author:Samaneh Vajdi
 * @Date: 2021-07-27 02:15:00
 * @LastEditors:
 * @LastEditTime:
 * @Description: file content
 */

import { Grid, Switch, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../../../context/GlobalContext";
import combineStyles from "../../../../../styles/combineStyles";
import popupStyles from "../../../../../styles/PopupStyle";
import UserPageStyles from "../../../../../styles/usersPageStyles";

// Make this component to have the styles and pop up showing.
const combinedStyles = combineStyles(popupStyles, UserPageStyles);


export default function EditNotification(props) {
    const global = useContext(GlobalContext)
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        emailNotification: props.account?.settings?.notificationSettings?.email,
        whatsAppNotification: props.account?.settings?.notificationSettings?.whatsapp,
    });
    
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    };
    
    useEffect(() => {
        if (props.account && props.account.settings && props.account.settings.notificationSettings) {
            // console.log("props changed", props.account.settings);
            setState({
                ...state,
                emailNotification:
                props.account.settings.notificationSettings.email,
                whatsAppNotification:
                props.account.settings.notificationSettings.whatsapp,
            });
        }
    }, [props]);

    const handleSave = async () => {
        let result = await global.api.updateAccount({
            id: props.account._id,
            profileSet: true,
            role: props.account.roles[0],
            settings: {notificationSettings: {
                email: state.emailNotification,
                whatsapp: state.whatsAppNotification
            }},
            orgId: global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id
        });
        if (result && result.status === 200) {
            handleClose();
            props.refresh();
        } else {
            alert('Unable to update profile.');
        }
    };
    
    const classes = combinedStyles();
    
    return (
        <div>
            <ButtonBase className={props.className} onClick={handleClickOpen}>
                {" "}
                <Typography className={classes.typography}>
                    Edit Notification
                </Typography>
            </ButtonBase>

            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    id="alert-dialog-slide-title"
                >
                    Edit Notification
                    <IconButton
                        onClick={handleClose}
                        className={classes.dialogClose}
                        children={<ClearIcon />}
                    />
                </DialogTitle>
                <DialogContent>
                    <Grid container style={{ minWidth: 300 }} spacing={2}>
                        <Grid item style={{ display: "flex" }} xs={12}>
                            <Switch
                                name="emailNotification"
                                color={"primary"}
                                size="small"
                                checked={state.emailNotification}
                                onChange={handleChange}
                                inputProps={{ "aria-label": "Switch A" }}
                            />
                            <EmailIcon
                                style={{ color: "#767676", margin: "0 8px" }}
                            />
                            <Typography>Email</Typography>
                        </Grid>
                        <Grid item style={{ display: "flex" }} xs={12}>
                            <Switch
                                name="whatsAppNotification"
                                color={"primary"}
                                size="small"
                                checked={state.whatsAppNotification}
                                onChange={handleChange}
                                inputProps={{ "aria-label": "Switch A" }}
                            />
                            <WhatsAppIcon
                                style={{ color: "#767676", margin: "0 8px" }}
                            />
                            <Typography>WhatsApp</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className={classes.dialogAction}>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        className={classes.actionBtn}
                    >
                        CANCEL
                    </Button>
                    <Button color="primary" className={classes.actionBtn} onClick={handleSave}>
                        SAVE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
