import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import React from 'react';


const OperationHourSelect = (props) => {
    const { Weekday } = props;

    const handleChangeOpen = (e) => {
        props.setOperationHours({...props.operationHours,[Weekday]:{open:e.target.value, close: props.operationHours[Weekday].close }})
    }
    const handleChangeClose = (e) => {
        props.setOperationHours({...props.operationHours,[Weekday]:{close:e.target.value, open: props.operationHours[Weekday].open }})
    }
    return (
        <Grid container xs={12} style={{padding:'0.5%'}}>
            <Grid item xs={4}>
                <Typography style={{fontWeight:'400', marginTop:'10%'}}>{ Weekday }</Typography>
            </Grid>
            <Grid item xs={4}>
                <FormControl variant="outlined" className='InputForm' style={{ padding:'1%' }} fullWidth>
                <InputLabel id="demo-simple-select-outlined-label" style={{ padding:'1%' }} fullWidth></InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    
                    value={props.operationHours ? props.operationHours[Weekday].open : 'Closed'}
                    onChange={handleChangeOpen}
                >
                    <MenuItem value={'Closed'}>Close</MenuItem>
                    <MenuItem value={'00:00'}>00:00</MenuItem>
                    <MenuItem value={'00:30'}>00:30</MenuItem>
                    <MenuItem value={'01:00'}>01:00</MenuItem>
                    <MenuItem value={'01:30'}>01:30</MenuItem>
                    <MenuItem value={'02:00'}>02:00</MenuItem>
                    <MenuItem value={'02:30'}>02:30</MenuItem>
                    <MenuItem value={'03:00'}>03:00</MenuItem>
                    <MenuItem value={'03:30'}>03:30</MenuItem>
                    <MenuItem value={'04:00'}>04:00</MenuItem>
                    <MenuItem value={'04:30'}>04:30</MenuItem>
                    <MenuItem value={'05:00'}>05:00</MenuItem>
                    <MenuItem value={'05:30'}>05:30</MenuItem>
                    <MenuItem value={'06:00'}>06:00</MenuItem>
                    <MenuItem value={'06:30'}>06:30</MenuItem>
                    <MenuItem value={'07:00'}>07:00</MenuItem>
                    <MenuItem value={'07:30'}>07:30</MenuItem>
                    <MenuItem value={'08:00'}>08:00</MenuItem>
                    <MenuItem value={'08:30'}>08:30</MenuItem>
                    <MenuItem value={'09:00'}>09:00</MenuItem>
                    <MenuItem value={'09:30'}>09:30</MenuItem>
                    <MenuItem value={'10:00'}>10:00</MenuItem>
                    <MenuItem value={'10:30'}>10:30</MenuItem>
                    <MenuItem value={'11:00'}>11:00</MenuItem>
                    <MenuItem value={'11:30'}>11:30</MenuItem>
                    <MenuItem value={'12:00'}>12:00</MenuItem>
                    <MenuItem value={'12:30'}>12:30</MenuItem>
                    <MenuItem value={'13:00'}>13:00</MenuItem>
                    <MenuItem value={'13:30'}>13:30</MenuItem>
                    <MenuItem value={'14:00'}>14:00</MenuItem>
                    <MenuItem value={'14:30'}>14:30</MenuItem>
                    <MenuItem value={'15:00'}>15:00</MenuItem>
                    <MenuItem value={'15:30'}>15:30</MenuItem>
                    <MenuItem value={'16:00'}>16:00</MenuItem>
                    <MenuItem value={'16:30'}>16:30</MenuItem>
                    <MenuItem value={'17:00'}>17:00</MenuItem>
                    <MenuItem value={'17:30'}>17:30</MenuItem>
                    <MenuItem value={'18:00'}>18:00</MenuItem>
                    <MenuItem value={'18:30'}>18:30</MenuItem>
                    <MenuItem value={'19:00'}>19:00</MenuItem>
                    <MenuItem value={'19:30'}>19:30</MenuItem>
                    <MenuItem value={'20:00'}>20:00</MenuItem>
                    <MenuItem value={'20:30'}>20:30</MenuItem>
                    <MenuItem value={'21:00'}>21:00</MenuItem>
                    <MenuItem value={'21:30'}>21:30</MenuItem>
                    <MenuItem value={'22:00'}>22:00</MenuItem>
                    <MenuItem value={'22:30'}>22:30</MenuItem>
                    <MenuItem value={'23:00'}>23:00</MenuItem>
                    <MenuItem value={'23:30'}>23:30</MenuItem>
                </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl variant="outlined" className='InputForm' style={{ padding:'1%' }} fullWidth>
                <InputLabel id="demo-simple-select-outlined-label" style={{ padding:'1%' }} fullWidth></InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    
                    value={props.operationHours ? props.operationHours[Weekday].close : 'Closed'}
                    onChange={handleChangeClose}
                >
                    <MenuItem value={'Closed'}>Close</MenuItem>
                    <MenuItem value={'00:00'}>00:00</MenuItem>
                    <MenuItem value={'00:30'}>00:30</MenuItem>
                    <MenuItem value={'01:00'}>01:00</MenuItem>
                    <MenuItem value={'01:30'}>01:30</MenuItem>
                    <MenuItem value={'02:00'}>02:00</MenuItem>
                    <MenuItem value={'02:30'}>02:30</MenuItem>
                    <MenuItem value={'03:00'}>03:00</MenuItem>
                    <MenuItem value={'03:30'}>03:30</MenuItem>
                    <MenuItem value={'04:00'}>04:00</MenuItem>
                    <MenuItem value={'04:30'}>04:30</MenuItem>
                    <MenuItem value={'05:00'}>05:00</MenuItem>
                    <MenuItem value={'05:30'}>05:30</MenuItem>
                    <MenuItem value={'06:00'}>06:00</MenuItem>
                    <MenuItem value={'06:30'}>06:30</MenuItem>
                    <MenuItem value={'07:00'}>07:00</MenuItem>
                    <MenuItem value={'07:30'}>07:30</MenuItem>
                    <MenuItem value={'08:00'}>08:00</MenuItem>
                    <MenuItem value={'08:30'}>08:30</MenuItem>
                    <MenuItem value={'09:00'}>09:00</MenuItem>
                    <MenuItem value={'09:30'}>09:30</MenuItem>
                    <MenuItem value={'10:00'}>10:00</MenuItem>
                    <MenuItem value={'10:30'}>10:30</MenuItem>
                    <MenuItem value={'11:00'}>11:00</MenuItem>
                    <MenuItem value={'11:30'}>11:30</MenuItem>
                    <MenuItem value={'12:00'}>12:00</MenuItem>
                    <MenuItem value={'12:30'}>12:30</MenuItem>
                    <MenuItem value={'13:00'}>13:00</MenuItem>
                    <MenuItem value={'13:30'}>13:30</MenuItem>
                    <MenuItem value={'14:00'}>14:00</MenuItem>
                    <MenuItem value={'14:30'}>14:30</MenuItem>
                    <MenuItem value={'15:00'}>15:00</MenuItem>
                    <MenuItem value={'15:30'}>15:30</MenuItem>
                    <MenuItem value={'16:00'}>16:00</MenuItem>
                    <MenuItem value={'16:30'}>16:30</MenuItem>
                    <MenuItem value={'17:00'}>17:00</MenuItem>
                    <MenuItem value={'17:30'}>17:30</MenuItem>
                    <MenuItem value={'18:00'}>18:00</MenuItem>
                    <MenuItem value={'18:30'}>18:30</MenuItem>
                    <MenuItem value={'19:00'}>19:00</MenuItem>
                    <MenuItem value={'19:30'}>19:30</MenuItem>
                    <MenuItem value={'20:00'}>20:00</MenuItem>
                    <MenuItem value={'20:30'}>20:30</MenuItem>
                    <MenuItem value={'21:00'}>21:00</MenuItem>
                    <MenuItem value={'21:30'}>21:30</MenuItem>
                    <MenuItem value={'22:00'}>22:00</MenuItem>
                    <MenuItem value={'22:30'}>22:30</MenuItem>
                    <MenuItem value={'23:00'}>23:00</MenuItem>
                    <MenuItem value={'23:30'}>23:30</MenuItem>
                </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default OperationHourSelect;