/*
 * @Author: Vincent Tran
 * @Date: 2020-07-31 03:35:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-07-31 03:40:00
 * @Description: file content
 */
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useContext } from "react";
import GlobalContext from "../../../../../context/GlobalContext";
import combineStyles from "../../../../../styles/combineStyles";
import popupStyles from "../../../../../styles/PopupStyle";
import UserPageStyles from "../../../../../styles/usersPageStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, UserPageStyles);

export default function ForceResetPass(props) {
    const global = useContext(GlobalContext);
    const [open, setOpen] = React.useState(false);
    const [orgDetails, setOrgDetails] = React.useState({});
    const handleClickOpen = () => {
        setOpen(true);
    };

    const details = () => {
        let data =
            Object.keys(global.state.selectedOrg).length > 0
                ? global.state.selectedOrg
                : global.state.defaultOrg;
        console.log(data);
        setOrgDetails(data);
        return;
    };
    React.useEffect(details, []);

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = async () => {
        console.log(props.account);
        const data = {
            orgId: global.state.selectedOrg._id
                ? global.state.selectedOrg._id
                : global.state.defaultOrg._id,
            userId: props.account._id,
        };
        try {
            await global.api.forceResetPass(data);
            await global.api.forgotPassword({
                email: props.account.email,
                contactName: props.account.contact_name,
                orgName: orgDetails.org_name,
                logo: orgDetails.logo,
            });
        } catch (error) {
            console.log(error.response ? error.response.data : error);
        }
        handleClose();
    };

    const classes = combinedStyles();

    return (
        <div>
            <ButtonBase className={props.className} onClick={handleClickOpen}>
                {" "}
                <Typography className={classes.typography}>
                    Force Reset Password
                </Typography>
            </ButtonBase>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    className={classes.dialogTitle}
                    id="alert-dialog-slide-title"
                >
                    Force Reset Password
                    <IconButton
                        onClick={handleClose}
                        className={classes.dialogClose}
                        children={<ClearIcon />}
                    />{" "}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.contentText}>
                        Are you sure you want to force {props.account.firstname}{" "}
                        {props.account.lastname} to reset their password? They
                        will receive an email for setting the new password.
                    </DialogContentText>
                </DialogContent>

                <DialogActions className={classes.dialogAction}>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        className={classes.actionBtn}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        className={classes.actionBtn}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
