import { makeStyles, MenuItem } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useContext, useState } from "react";
import GlobalContext from "../../context/GlobalContext";

const useStyles = makeStyles((theme) => ({
    muiListItemButton: {
        "&:hover": {
            backgroundColor: "#D3E2FF",
        },
        dialogActions: {
            display: "flex",
            justifyContent: "center",
        },

        dialogClose: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
    },
}));
const DisableOrg = ({
    org,
    onMenuClick = () => {},
    onDisableComplete = () => {},
}) => {
    const classes = useStyles();
    const [disableOrgOpen, setDisableOrgOpen] = useState(false);
    const global = useContext(GlobalContext);

    const handleDisableOrg = async (evt) => {
        evt.preventDefault();
        const res = await global.api.updateDisableOrg(org._id, true);

        if (res.status === 200) {
            onDisableComplete();
            setDisableOrgOpen(false);
        }
    };

    const handleOnMenuClick = () => {
        setDisableOrgOpen(true);
        onMenuClick();
    };

    return (
        <>
            <MenuItem
                onClick={handleOnMenuClick}
                className={classes.muiListItemButton}
            >
                Disable Organization
            </MenuItem>
            <Dialog
                open={disableOrgOpen}
                onClose={() => setDisableOrgOpen(false)}
                maxWidth="xs"
                style={{
                    margin: "auto",
                    width: "400px",
                    marginTop: "5px",
                    borderRadius: "30px",
                    
                    
                }}
            >
                <DialogTitle >
                    <p style={{
                            fontSize: "24px",
                            fontFamily:"Roboto",
                            color: "#222222",
                            marginTop: "15px",
                            marginBottom: "5px"
                        }}> Disable Organizations </p>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        style={{
                            fontSize: "16px",
                            color: "#000000",
                            fontWeight: "400",
                            marginTop: "-28px",
                            fontFamily: "Roboto",
                        }}
                    >
                        <p>
                            Are you sure you want to disable{" "}
                            <strong> {org.org_name}?</strong> The organization
                            will not be able to log in until the account is
                            re-enabled.
                        </p>
                        <p> You can re-enable the account anytime.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ marginBottom: "20px" }}>
                    <Box
                        style={{
                            margin: "auto",
                            marginLeft: "2%",
                            fontweight: "400",
                        }}
                    >
                        <Button
                            onClick={() => setDisableOrgOpen(false)}
                            color="primary"
                            style={{ float: "left", fontWeight: "bold" }}
                        >
                            BACK
                        </Button>
                    </Box>
                    <Box
                        style={{
                            margin: "auto",
                            marginRight: "6%",
                            fontweight: "400",
                        }}
                    >
                        <Button
                            onClick={handleDisableOrg}
                            type="submit"
                            style={{ float: "right", color: "#D14444", fontWeight: "bold" }}
                            autoFocus
                        >
                            DISABLE
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DisableOrg;
