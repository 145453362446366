import { MenuItem, Select, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import BootstrapInput from '../../../../../../styles/BootstrapInput';

export default function UrlComponent(props) {
    const classes = props.classes;
    const state = props.state;
    const setState = props.setState;
    const handleChange = props.handleChange;
    const handlePatientChange = props.handlePatientChange;
    
    return (
        
        <Grid container spacing={3} className={classes.row}>
            <Grid item xs={12} sm={6}>
                <InputLabel className={classes.formLabel} shrink htmlFor="provider-input">
                    Search Patient Using:
                </InputLabel>
                <FormControl fullWidth variant="outlined" className={classes.dropdown}>
                    <Select
                        fullWidth
                        name="searchSelect"
                        value={state.searchSelect}
                        onChange={(e) => handleChange(e)}
                        input={<BootstrapInput/>}
                        id="searchSelect-input"
                        disabled={state.patientSelected}
                    >
                        
                        <MenuItem className={classes.optionList} value={0}>Patient Name</MenuItem>
                        <MenuItem className={classes.optionList} value={1}>Phone Number</MenuItem>
                        <MenuItem className={classes.optionList} value={2}>Email</MenuItem>
                        <MenuItem className={classes.optionList} disabled value={3}>Health Insurance Number</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <InputLabel className={classes.formLabel} shrink htmlFor="patientName-input">
                    Patient
                </InputLabel>
                <Autocomplete
                    id="custom-input-demo"
                    options={state && state.inputValue.length === 0 ? [] : state.patientList}
                    name="autoCompleteSelect"
                    value={state.autoCompleteSelect}
                    onChange={(event, value) => handlePatientChange(value)}
                    inputValue={state.inputValue}
                    onInputChange={(event, inputValue) => {setState({...state, inputValue:inputValue})}}

                    autoHighlight
                    noOptionsText={state && state.patientList && state.patientList.length === 0 ? "No doctor in this clinic" : state.inputValue.length === 0 ? "Oops your search bar is empty.." : "Sorry No Results"}
                    getOptionLabel={(option) => {
                        if(state.searchSelect === 0){
                            return option.name ? option.name : "";
                        } else if(state.searchSelect === 1){
                            return option.phone ? option.phone : "";
                        } else if(state.searchSelect === 2){
                            return option.email ? option.email : "";
                        }
                        }}
                    ListboxComponent={(listboxProps) => (

                        <ul
                            {...listboxProps}
                        />
                    )}
                    renderInput={(params) => (

                        <BootstrapInput
                            fullWidth
                            //className={classes.input}
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps} />
                    )}
                    renderOption={(option) => {

                        return (
                            <React.Fragment>
                                <Box className={classes.listItem}>

                                    <Typography variant="body2" color="#767676">
                                        {option.name}
                                    </Typography>

                                </Box>

                            </React.Fragment>
                        )

                    }}
                    disabled={state.patientSelected}
                />
            </Grid>
            
        </Grid>
        
    );
}

