
import React from 'react'
import PortonLogo from './../img/Porton_Default.png';
import useStyles from './styles/LoginPageStyle';

export default function Header() {
    const classes = useStyles();
    return (
        <header className={classes.header}>
            <img className={classes.portonImgHeader} src={PortonLogo} alt="Porton"/>
        </header>
    )
}