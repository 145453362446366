import React from 'react'

import {Container, Box} from '@material-ui/core';

import Profile from "./Profile/profile"

import AccountProfileStyle from '../../styles/AccountProfileStyles'

const Account = (props) => {
    const classes = AccountProfileStyle()


    return (
        <>
        <Container>
            <Box className={classes.mainBody}>
                <Profile setTitle={props.setTitle}></Profile>
            </Box>
        </Container>
        </>

    )
}

export default Account