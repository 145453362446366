import { Grid, IconButton, Menu, MenuItem,TextField, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import MoreIcon from '@material-ui/icons/MoreHorizSharp';
import PhoneIcon from '@material-ui/icons/Phone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PasswordIcon from '@material-ui/icons/VpnKeySharp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React, { useState } from 'react';
import settingsStyles from '../../../../../styles/settingsPageStyles';
import ChangeEmailFlow from './ChangeEmailFlow';
import ChangePassword from './ChangePassword';
import FingerprintScan from './FingerprintScan';
import IrisScan from './IrisScan';



const LoginInfo = ({ user, setPassword }) => {
    const classes = settingsStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [state, setState] = useState({
        emailMenuOpen: false,
        emailEditOpen: false,
        emailOpen: false,
        passwordOpen: false,
        changePassword: false,
        fingerprintOpen: false,
        setupFingerprint: false,
        irisOpen: false,
        setupIris: false,
    })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        // make separate conditionals for Phone and WhatsApp once backend is implemented
        if (event.currentTarget.name === 'email') {
            setState({ ...state, emailMenuOpen: true })
        } else if (event.currentTarget.name === 'phone') {
            setState({ ...state, emailOpen: true }) 
        } else if (event.currentTarget.name === 'whatsapp') {
            setState({ ...state, emailOpen: true }) 
        } else if (event.currentTarget.name === 'password') {
            setState({ ...state, passwordOpen: true })
        } else if (event.currentTarget.name === 'fingerprint') {
            setState({ ...state, fingerprintOpen: true })
        } else if (event.currentTarget.name === 'iris') {
            setState({ ...state, irisOpen: true })
        }
    };

    const handleEmailEditClose = () => {
        setState({...state, emailEditOpen: false})
    }

    return (
        <Grid container className={classes.section}>
            <Grid item xs={12}>
                <Typography className={classes.labelSmall}>Login Information</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item xs={1}>
                        <EmailIcon />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.labelSmall}>Email</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography>
                        <TextField
            	    InputProps={{ disableUnderline: true }}
            	   variant="outlined"
            	   fullWidth
                   value={user.email || user.emails[0].address}
            	    className={classes.textBox}
            	    placeholder=""
            	   size="small"
            	/></Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClick} name='email'>
                            <MoreIcon />
                        </IconButton>
                        <Menu
                            classes={{ list: classes.menu }}
                            open={state.emailMenuOpen}
                            onClose={() => setState({ ...state, emailMenuOpen: false })}
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => setState({ ...state, emailEditOpen: true, emailMenuOpen: false })}>Edit</MenuItem>
                            
                        </Menu>

                        <ChangeEmailFlow user={user} open={state.emailEditOpen} onClose={handleEmailEditClose} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item xs={1}>
                        <PhoneIcon />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.labelSmall}>Phone</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography>
                        <TextField
            	    InputProps={{ disableUnderline: true }}
            	   variant="outlined"
            	   fullWidth
                   value={user.phone || ''}
            	    className={classes.textBox}
            	    placeholder="+1 111 111 1111"
            	   size="small"
            	/></Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClick} name='phone'>
                            <MoreIcon />
                        </IconButton>
                        <Menu
                            classes={{ list: classes.menu }}
                            open={state.emailOpen}
                            onClose={() => setState({ ...state, emailOpen: false })}
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => setState({ ...state, emailOpen: false })}>Remove</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item xs={1}>
                        <WhatsAppIcon />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.labelSmall}>WhatsApp</Typography>
                    </Grid>
                    {/*Needs to fetch WhatsApp number*/}
                    <Grid item xs={7}>
                    <Typography>
                    <TextField
            	    InputProps={{ disableUnderline: true }}
            	   variant="outlined"
            	   fullWidth
                   value={user.phone || ''}
            	    className={classes.textBox}
            	    placeholder="+1 111 111 1111"
            	   size="small"
            	/></Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClick} name='whatsapp'>
                            <MoreIcon />
                        </IconButton>
                        <Menu
                            classes={{ list: classes.menu }}
                            open={state.emailOpen}
                            onClose={() => setState({ ...state, emailOpen: false })}
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => setState({ ...state, emailOpen: false })}>Remove</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item xs={1}>
                        <PasswordIcon />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.labelSmall}>Password</Typography>
                    </Grid>
                    {/*Currently hard coded, add backend*/}
                    <Grid item xs={7}>
                        <Typography>***********</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClick} name='password'>
                            <MoreIcon />
                        </IconButton>
                        <Menu
                            classes={{ list: classes.menu }}
                            open={state.passwordOpen}
                            onClose={() => setState({ ...state, passwordOpen: false })}
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => setState({ ...state, passwordOpen: false, changePassword: true })}>Change</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
            <ChangePassword
                user={user}
                open={state.changePassword}
                close={() => setState({ ...state, changePassword: false })}
                setPassword={setPassword}
            />
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item xs={1}>
                        <FingerprintIcon />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.labelSmall}>Fingerprint</Typography>
                    </Grid>
                    {/*Currently hard coded, add backend*/}
                    <Grid item xs={7}>
                        <Typography>Fingerprint Saved</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClick} name='fingerprint'>
                            <MoreIcon />
                        </IconButton>
                        <Menu
                            classes={{ list: classes.menu }}
                            open={state.fingerprintOpen}
                            onClose={() => setState({ ...state, fingerprintOpen: false })}
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => setState({ ...state, fingerprintOpen: false, setupFingerprint: true })}>Set Up</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
            <FingerprintScan
                user={user}
                open={state.setupFingerprint}
                close={() => setState({ ...state, setupFingerprint: false })}
                setPassword={setPassword}
            />
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item xs={1}>
                        <VisibilityIcon />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.labelSmall}>Iris</Typography>
                    </Grid>
                    {/*Currently hard coded, add backend*/}
                    <Grid item xs={7}>
                        <Typography>Not configured</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleClick} name='iris'>
                            <MoreIcon />
                        </IconButton>
                        <Menu
                            classes={{ list: classes.menu }}
                            open={state.irisOpen}
                            onClose={() => setState({ ...state, irisOpen: false })}
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => setState({ ...state, irisOpen: false, setupIris: true })}>Set Up</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
            <IrisScan
                user={user}
                open={state.setupIris}
                close={() => setState({ ...state, setupIris: false })}
                setPassword={setPassword}
            />
        </Grid>
    )
}

export default LoginInfo;