import { Box, Button, CircularProgress, Collapse, Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from "../../../../context/GlobalContext";
import settingsStyles from '../../../../styles/settingsPageStyles';
import EmailSetting from './components/EmailSetting';
import FaxSetting from './components/FaxSetting';
import NotificationSetting from './components/NotificationSetting';
import PhoneSetting from './components/PhoneSetting';
import SMTPSetting from './components/SMTPSetting';

const CommunicationSetting = () => {
    const classes = settingsStyles()
    const global = useContext(GlobalContext)
    const [expanded, setExpanded] = useState(false)
    let org = global.state.selectedOrg !== 'undefined' && global.state.selectedOrg && Object.keys(global.state.selectedOrg).length !== 0 ? global.state.selectedOrg : global.state.defaultOrg
    let defaultSetting = {
        isEdit: false,
        isLoading: false,
        communicationSetting: {
            smtp_setting: {},
            sms_voice_setting: {},
            fax_setting: {},
            email_setting: {}
        }
    }
    const [state, setState] = useState(defaultSetting)

    useEffect(() => {
        const loadSetting = async (req, res) => {
            if(org.communicationSetting){
                let communicationSetting = await global.api.decryptSetting(org.communicationSetting)
                communicationSetting.smtp_setting = communicationSetting.smtp_setting || {};
                communicationSetting.sms_voice_setting = communicationSetting.sms_voice_setting || {};
                communicationSetting.fax_setting = communicationSetting.fax_setting || {};
                communicationSetting.email_setting = communicationSetting.email_setting || {};
                defaultSetting.communicationSetting = communicationSetting
                setState({
                    ...state,
                    communicationSetting: communicationSetting,
                })
            }
        }

        loadSetting()
        
    }, [])


    const setSmtpSetting = (smtpSetting) => {
        state.communicationSetting.smtp_setting = smtpSetting
        setState({
            ...state,
        })
    }

    const setPhoneSetting = (phoneSetting) => {
        state.communicationSetting.sms_voice_setting = phoneSetting
        setState({
            ...state, 
        })
    }

    const setEmailSetting = (emailSetting) => {
        state.communicationSetting.email_setting = emailSetting
        setState({
            ...state, 
        })
    }

    const setFaxSetting = (faxSetting) => {
        state.communicationSetting.fax_setting = faxSetting
        setState({
            ...state, 
        })
    }

    const setNotificationSetting = (notificationSetting) => {
        state.communicationSetting.notification_setting = notificationSetting
        setState({
            ...state, 
        })
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    const saveChanges = async () => {
        setState({ ...state, isLoading: true})
        const result = await global.api.updateCommunicationSetting(org._id, {setting: state.communicationSetting})
        if (result.status && result.status === 200) {
            if(JSON.stringify(global.state.selectedOrg) !== "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' ){
                let org = global.state.selectedOrg
                org.communicationSetting = state.communicationSetting
                global.setState((prev)=>{
                    return { ...prev, selectedOrg: org }
                })
                setState({...state, isEdit: false, isLoading: false})
            }else { //default org
                let org = global.state.defaultOrg
                org.checkInSettings = state.communicationSetting
                global.setState((prev)=>{
                    return { ...prev, defaultOrg: org }
                })
                setState({...state, isEdit: false, isLoading: false})
            }
        }
        else{
            setState(defaultSetting)
            alert('Update communicationcom settings failed.')
        }
    }

    const rootClassNames = clsx({
        [classes.rootExpanded]: expanded,
        [classes.root]: !expanded,
        [classes.disabled]: org.disabled
    })

    return (
        <Grid container component={Paper} elevation={3} className={rootClassNames}>
            <Grid item xs={12} className={classes.paperComponment}>
            <Box onClick={handleExpandClick} className={classes.labelWithEdit}>
                    <Typography className={clsx(classes.label, {
	                        	[classes.expandedLabel]: expanded
	                        })}>Communication Settings</Typography>
                    <Typography className={classes.edit}>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            disabled={org.disabled}
                        >
                            
                            <ExpandMoreIcon />
                        </IconButton>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <Grid container>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className={!state.isEdit ? classes.disabledBackground : ''}>
                                <Grid item md={6} xs={12} className={classes.dividerBorder}>
                                    <SMTPSetting smtpSetting={state.communicationSetting.smtp_setting} setSmtpSetting={setSmtpSetting}/>
                                    <Divider/>
                                    <PhoneSetting phoneSetting={state.communicationSetting.sms_voice_setting} setPhoneSetting={setPhoneSetting}/>
                                    <Divider />
                                    <EmailSetting emailSetting={state.communicationSetting.email_setting} setEmailSetting={setEmailSetting}/>
                                </Grid>
                                <Grid item md={6} xs={12} className={classes.dividerBorder}>
                                    <FaxSetting faxSetting={state.communicationSetting.fax_setting} setFaxSetting={setFaxSetting}/>
                                    <Divider/>
                                    <NotificationSetting notificationSetting={state.communicationSetting.notification_setting} setNotificationSetting={setNotificationSetting}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.divider}/>
                        </Grid>
                        <Grid item xs={12}>
                            {state.isEdit ?
                                <Box>
                                    {state.isLoading ?
                                        <Box display='flex' justifyContent='flex-end'>
                                            <CircularProgress className={classes.loadingCircle} />
                                        </Box>
                                        :
                                        <Box display='flex' justifyContent='flex-end' className={classes.saveBtnGroup}>
                                            <Button variant="outlined" color='primary' onClick={saveChanges}>
                                                Save Changes
                                            </Button>
                                            <Button variant="outlined" color='primary' className={classes.saveChangesBtn} onClick={() => setState(defaultSetting)}>
                                                Cancel
                                            </Button>
                                        </Box>
                                    }
                                </Box>
                                :
                                <Box display='flex' justifyContent='flex-end' className={classes.saveBtnGroup}>
                                    <Button xs={12} variant="outlined" color='primary' onClick={() => setState({ ...state, isEdit: true })}>
                                        Edit
                                    </Button>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    )
}

export default CommunicationSetting