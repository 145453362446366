/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-09 03:16:35
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-07-21 01:32:31
 * @Description: file content
 */
import {
  ThemeProvider
} from "@material-ui/core/styles";
import React from 'react';
import './App.css';
import GlobalProvider from "./frame/APIContainer";
import styles from './styles/GlobleStyle';
const theme = styles
function App() {

  return (
  
      <ThemeProvider theme={theme}>
          <GlobalProvider/>
       
     </ThemeProvider>
  
  );
}

export default App;
