import { Switch, Typography, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';

export default function VersionControlComponent(props) {
    const classes = props.classes;
    const state = props.state;
    const handleChange = props.handleChange;

    return (

        <Grid item xs={12} className={classes.paperComponment}>
            <Box className={classes.labelWithEdit}>
                <Typography className={classes.labelSmall} style={{'marginLeft' : '9px'}}>Enable New CheckIn Step</Typography>
                <Switch
                    color='primary'
                    size='small'
                    className={classes.switch}
                    checked={state.versionToggle === undefined ? false : state.versionToggle}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name='versionToggle'
                    disabled={!state.isEditPressed}
                />

            </Box>
        </Grid>
    );
}
