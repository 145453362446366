/*
 * @Author: Vincent Tran
 * @Date: 2020-07-31 03:35:00
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-14 04:35:15
 * @Description: file content
 */
import { Typography } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import moment from "moment-timezone";
import React from 'react';
import GlobalContext from "../../../context/GlobalContext";
import combineStyles from '../../../styles/combineStyles';
import popupStyles from '../../../styles/PopupStyle';
import CopyTextBox from "./copyTextField";



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    typography: {
        fontWeight: 500,
        padding: theme.spacing(2),
    },
    formLabel: {
        color: "#222222"
    },
    contentText: {
        width: "360px",
        color: "#222222",
        fontSize: "1rem",
        fontWeight: 500
    },
    setReminder: {
        margin: "1rem 0 1rem 0",
        fontSize: "0.8rem",
        color: "#003FBA",
        fontWeight: 600,
        marginRight: "0.5rem",
        cursor: "pointer"
    },
    providerName: {
        fontSize: "1.2rem",
        fontWeight: "500",
        color: "#343434"
    },
    emailAndPhone: {
        color: "#767676",
        fontSize: "0.9rem",
        fontWeight: "450",
    },
    reason: {
        color: "#767676",
        fontSize: "0.9rem",
        fontWeight: "450",
    },
    eventType: {
        fontSize: "1.8rem",
        fontWeight: "550",
        color: "#343434"
    },
    eventTypeBox:{
        display:"flex",
        alignItems:"center"
    },
    colorBox:{
        margin:"0 0.5rem 0 0",
        width:"1.1rem",
        height:"1.1rem",
        borderRadius:"20%"
    },
    smallTitles:{
        color: "#343434",
        fontSize: "0.8rem",
        fontWeight: "450",
    },
    smallBody:{
        color: "#767676",
        fontSize: "0.8rem",
        fontWeight: "450",
    }
}));

// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, useStyles);

export default function ForceResetPass(props) {
    const global = React.useContext(GlobalContext)
    const classes = combinedStyles();
    const [open, setOpen] = React.useState(false);
    const [appointmentDetials, setAppointmentDetials] = React.useState(null)
    const [appointmentFormattedInfo, setAppointmentFormattedInfo] = React.useState(null)
    const color = {
        inPerson: "#EFCF59",
        video: "#71BDA4",
        voice: "#7EAAD3",
        text: "#CC9DD4"
    }
    const text = {
        inPerson: "In Person",
        video: "Video Call",
        voice: "Voice Call",
        text: "Secure Text"
    }

    const handleClose = () => {
        setAppointmentDetials(null)
        setAppointmentFormattedInfo(null)
        props.setOpen(false);
    };
    const handleSubmit = () => {
        // Force reset the user's password here..
        setAppointmentDetials(null)
        setAppointmentFormattedInfo(null)
        handleClose();
    }
    const getAppointmentInfo = async () => {
        //console.log("fetch appointment")
        const result = await global.api.getAppintmentById(props.appointmentId)
        if (result.status == 200) {
            setAppointmentDetials(result.data)
            //console.log(result.data)
        }
    }
    React.useEffect(() => {
        //console.log(appointmentDetials)
        if (!appointmentDetials) {
            return
        }
 
        let data = {}
        if(appointmentDetials.doctor){
            data.doctorName = appointmentDetials.doctor.contact_name_enc ? appointmentDetials.doctor.contact_name_enc : "No Doctor Name"
            data.doctorEmail = appointmentDetials.doctor.emails[0].address ? appointmentDetials.doctor.emails[0].address : "No Email"
            data.docPhone = appointmentDetials.doctor.phone_enc && appointmentDetials.doctor.phone_enc.length > 5 ? appointmentDetials.doctor.phone_enc : "No Phone"
        }
      
        //console.log(appointmentDetials.start)

        const startDate = moment(appointmentDetials.start).tz(props.timeZone).format('HH:mm z')
        data.start = startDate
        data.end = moment(appointmentDetials.end).tz(props.timeZone).format('HH:mm z')
        data.date = moment(appointmentDetials.start).tz(props.timeZone).format("ddd, MMM Do")
        data.duration = appointmentDetials.duration
        data.eventType = text[appointmentDetials.eventType]
        data.reason = appointmentDetials.reason || "No Visit Reason"
        data.info = appointmentDetials.info || "No Additional Comment"
        data.patientRoomLink = appointmentDetials.links ? appointmentDetials.links.patient : null
        
        data.doctorRoomLink = appointmentDetials.links ? appointmentDetials.links.doctor : null
        data.colorTag=color[appointmentDetials.eventType]
        // console.log(start)
        // console.log(moment(data.start).tz(props.timeZone).format('HH:mm z'))
        // console.log(moment(data.end).tz(props.timeZone).format('HH:mm z'))
        // console.log(moment(data.start).tz(props.timeZone).format("ddd, MMM Do"))
        //console.log("formatted",data)
        setAppointmentFormattedInfo(data)

    }, [appointmentDetials])
    React.useEffect(() => {
        //console.log("appointment id", props.appointmentId)
        getAppointmentInfo()

    }, [props.appointmentId])
    return (
        <div>
            {/* <ButtonBase className={props.className} onClick={props.handleClickOpen}> <Typography className={classes.typography}>Force Reset Password</Typography></ButtonBase> */}

            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}

            >
                {appointmentFormattedInfo ?
                    <>

                        <DialogTitle className={classes.dialogTitle}>
                            <Typography className={classes.providerName}>{appointmentFormattedInfo.doctorName} <IconButton onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} /> </Typography>
                            <Typography className={classes.emailAndPhone} variant="body1">{appointmentFormattedInfo.doctorEmail} | {appointmentFormattedInfo.docPhone}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText className={classes.contentText}>
                                <Typography className={classes.setReminder}>SET REMINDERS</Typography>
                            </DialogContentText>
                            <DialogContentText className={classes.contentText}>

                                <Typography className={classes.reason}>{appointmentFormattedInfo.reason}</Typography>
                                <Box className={classes.eventTypeBox}>
                                    <Box style={{backgroundColor:appointmentFormattedInfo.colorTag}}className={classes.colorBox}></Box>
                                    <Typography className={classes.eventType}>{appointmentFormattedInfo.eventType}</Typography>
                                </Box>

                                <Typography className={classes.reason}>{appointmentFormattedInfo.date} | {appointmentFormattedInfo.start} - {appointmentFormattedInfo.end} | {appointmentFormattedInfo.duration} mintues</Typography>
                            </DialogContentText>
                            <DialogContentText className={classes.contentText}>
                                <Typography className={classes.smallTitles}>Addtional Comments</Typography>
                                <Typography className={classes.smallBody}>{appointmentFormattedInfo.info ? appointmentFormattedInfo.info : "None"}</Typography>
                            </DialogContentText>
                            <DialogContentText className={classes.contentText}>
                                {appointmentFormattedInfo.doctorRoomLink &&
                                    <>
                                        <Typography className={classes.smallTitles}>Provider Room</Typography>
                                        
                                        <CopyTextBox  value={appointmentFormattedInfo.doctorRoomLink}/>
                                    </>}
                                {appointmentFormattedInfo.patientRoomLink &&
                                    <>
                                        <Typography className={classes.smallTitles}>Patient Room</Typography>
                                
                                        <CopyTextBox value={appointmentFormattedInfo.patientRoomLink}/>
                                    </>
                                }
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions className={classes.dialogAction}>
                            <Button onClick={handleClose} color="primary" className={classes.actionBtn}>
                                Remove
                    </Button>
                            <Button onClick={handleSubmit} color="primary" className={classes.actionBtn}>
                                EDIT
                    </Button>
                        </DialogActions>
                    </>
                    :
                    <>

                        <DialogTitle className={classes.dialogTitle}>
                            <Typography>Loading... <IconButton onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} /></Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText className={classes.contentText}>
                                <Typography>Loading...</Typography>
                            </DialogContentText>
                        </DialogContent>
                    </>}
            </Dialog>
        </div>
    );
}