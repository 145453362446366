import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "@material-ui/icons/Email";
import MicIcon from "@material-ui/icons/Mic";
import PhoneIcon from "@material-ui/icons/Phone";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import _ from "lodash";
import { useSendEmail } from "../../../SendEmail";
import CircularProgress from "@material-ui/core/CircularProgress";

let adminAppUri = process.env.REACT_APP_PORTON_ADMIN_API || "/api";
const useStyles = makeStyles({
  button: {
    background: "white",
    border: "2px solid #003FBA",
    color: "#003FBA",
    borderRadius: "10px",
    // padding: '10px',
    // margin: '5px',
    // width: calc(100 / 3),
  },
});

export default function ContactForm({ open, onClose, patientName, contactInfo }) {
  const [selected, setSelected] = useState("email");
  const [email, setEmail] = useState(contactInfo?.email || "");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const global = useContext(GlobalContext);

  const getSentFrom = global.state.user.document.contact_name
    ? global.state.user.document.contact_name
    : global.state.user.document.name;

  const { sendEmail, EMAIL_STATES, emailState } = useSendEmail({
    email: email ? email : contactInfo?.email,
    subject: subject,
    content: content,
    emailType: "contactEmail",
    otherBodyProps: {
      contactName: patientName,
      logo: global.state.selectedOrg.logo,
      orgName: global.state.selectedOrg.org_name,
    },
  });

  useEffect(() => {
    switch (emailState) {
      case EMAIL_STATES.LOADING:
        setIsLoading(true);
        break;
      case EMAIL_STATES.SUCCESS:
        alert("Message have been sent successfully");
        handleClose();
        setSubject("");
        setEmail("");
        setContent("");
        setIsLoading(false);
        break;
      case EMAIL_STATES.ERROR:
        alert("Error sending message");
        setIsLoading(false);
        break;
    }
  }, [emailState]);

  let sentFrom;
  if (!getSentFrom || getSentFrom === " ") {
    sentFrom = "Admin";
  } else sentFrom = getSentFrom;
  const handleClose = () => {
    onClose();
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubject = (event) => {
    setSubject(event.target.value);
  };

  const handleContent = (event) => {
    setContent(event.target.value);
  };

  // const handleSend = async () => {
  //   const email = contactInfo.email ? contactInfo.email : email;
  //   // const result = await global.api.sendNewMessage(email, subject, content, sentFrom, patientName);
  //   console.log("User data is ", global.state.user.document);
  //   let emailType = "contactEmail";
  //   let body = JSON.stringify({
  //     email: email,
  //     subject: subject,
  //     content: content,
  //     sentFrom: sentFrom,
  //     contactName: patientName,
  //     logo: global.state.selectedOrg.logo,
  //     orgName: global.state.selectedOrg.org_name,
  //   });
  //   const result = await fetch(`${adminAppUri}/auth/sendContactMessage`, {
  //     method: "post",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       email: email,
  //       subject: subject,
  //       content: content,
  //       sentFrom: sentFrom,
  //       contactName: patientName,
  //       logo: global.state.selectedOrg.logo,
  //       orgName: global.state.selectedOrg.org_name,
  //     }),
  //   });
  //
  //   if (result.ok) {
  //     alert("Message sent");
  //   }
  //
  //   handleClose();
  //
  //   setSubject("");
  //   setContent("");
  // };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            Contact {patientName}
          </Grid>
          <Grid item xs={1}>
            <DialogActions>
              <IconButton onClick={handleClose} edge="end" size="small">
                <CloseIcon style={{ color: "#3b3b3b" }} />
              </IconButton>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ overflowY: "hidden" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Grid container direction="row" justifyContent="space-between">
                  <Button
                    className={classes.button}
                    onClick={() => setSelected("email")}
                  >
                    <EmailIcon /> Email
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={() => setSelected("phone")}
                  >
                    <PhoneIcon /> SMS
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={() => setSelected("voice")}
                  >
                    <MicIcon /> Voice
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {selected && (
                  <TextField
                    id="input-with-icon-adornment"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ color: "#003FBA" }}
                        >
                          {selected === "email" ? (
                            <EmailIcon />
                          ) : selected === "phone" ? (
                            <PhoneIcon />
                          ) : (
                            <MicIcon />
                          )}
                        </InputAdornment>
                      ),
                      value: selected === "email" ? contactInfo?.email : email,
                    }}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Subject"
              fullWidth
              variant="outlined"
              margin="dense"
              onChange={handleSubject}
              value={subject}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Content"
              id="outlined-multiline-static"
              fullWidth
              variant="outlined"
              rows={5}
              multiline
              onChange={handleContent}
              value={content}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ margin: "auto", marginRight: "75%" }}
          color="primary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        {isLoading ? (
          <CircularProgress
            style={{ margin: "1px", color: "#1766b3", marginRight: "20px" }}
          />
        ) : (
          <Button onClick={sendEmail} color="primary" autoFocus>
            Send
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
