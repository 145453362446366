import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
// import PortonIcon from '../img/portonlogorbg.png';
import "../styles/LoginPageStyles.css";
import Footer from "./LoginFooter";
import Header from "./LoginHeader.js";
import LoginPageStyles from "./styles/LoginPageStyle";
// import { LoginFooterContainer } from './containers/Footer';
import "./styles/LoginStyles.css";

export default function ForgotPass() {
  const global = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState({
    email: "",
  });
  const [emailSent, setEmailSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const handlePassReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (email.email !== "") {
      const result = await global.api.forgotPassword(email);

      if (result.status === 200) {
        setEmailSent(true);
        setInvalidEmail(false);
      } else if (result.status === 400) {
        setInvalidEmail(true);
      } else {
        // console.log("didnt work");
      }
    } else {
      setInvalidEmail(true);
    }
    setLoading(false);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: "25ch",
    },
  }));

  const classes = useStyles();
  const styles = LoginPageStyles();



  return (
    <div className={styles.loginPage}>
      {/* Header */}
      <Header />

      {/* Login Modal */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="70vh"
        className={styles.loginBox}
      >
        <Paper className={styles.loginPaper} elevation={3}>
          {!emailSent ? (
            <Box>
              <Typography
                align="center"
                className={styles.loginTitle}
                variant="h4"
              >
                Forgot Your Password?
              </Typography>
              <Typography align="center" variant="body1">
                Please enter your email address and we will send you a link to
                reset your password.
              </Typography>
              {invalidEmail && (
                <Typography className="invalidEmail">Invalid Email!</Typography>
              )}
              <form onSubmit={handlePassReset}>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  style={{ display: "block", width: "100%" }}
                >
                  <InputLabel htmlFor="outlined-adornment-email">
                    Email
                  </InputLabel>
                  <OutlinedInput
                    id="email"
                    label="Email"
                    fullWidth
                    placeholder="Email"
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      setEmail({ ...email, email: e.target.value });
                      setInvalidEmail(false);
                    }}
                    className="emailInput"
                  />
                </FormControl>
                <Box
                  display="flex"
                  justifyContent="center"
                  className="signInBox"
                >
                  {loading ? (
                    <CircularProgress className="circularProgress" />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      className="signInButton"
                      style={{ marginLeft: "6px" }}
                      fullWidth
                    >
                      Reset Password
                    </Button>
                  )}
                </Box>
                {/* <Grid className={styles.createAccountGrid}>
                  <Typography className={styles.noAccountText} noWrap>
                    Don't have an account?{" "}
                    <Button className={styles.createAccountButton}>
                      CREATE ACCOUNT
                    </Button>
                  </Typography>
                </Grid> */}
              </form>
            </Box>
          ) : (
            <Box>
              <Typography
                align="center"
                className="SignInTypography"
                variant="h4"
              >
                Please Check Your Email
              </Typography>
              <Typography className="description">
                An email has been sent to your account, please follow the
                instructions to reset your password.
              </Typography>
              <Typography className="descriptionBot">
                If you don’t see the email, check other places it might be like
                your junk, spam, or other folders.
              </Typography>
              <form
                onSubmit={() => {
                  setEmailSent(false);
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  marginTop="1.5rem"
                  className="signInBox"
                >
                  {loading ? (
                    <CircularProgress className="circularProgress" />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      className="signInButton"
                      fullWidth
                    >
                      Resend Email
                    </Button>
                  )}
                </Box>
              </form>
            </Box>
          )}
        </Paper>
      </Box>
      {/* Footer */}
      <Footer />
    </div>
  );
}
