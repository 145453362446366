//material UI components and icons
import { Box, Button, Dialog, DialogActions, DialogTitle, FormControl, TextField, Typography } from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment } from 'react';
//resources
import { specialties } from '../../../../../resources/specialties';
//styling
import AccountProfileStyle from '../../../../../styles/AccountProfileStyles';



//check to see if dialogcontent component used correctly and form control
const Popup = (props) => {
    const classes = AccountProfileStyle()

return(
    <>
    <Dialog onBackdropClick={props.close} open={props.open} aria-labelledby="form-dialog-title">
        <Box className={classes.spreadSubHeader}>
            <DialogTitle>Specialties</DialogTitle>
            <CloseIcon className={classes.closeIcon} onClick={props.close}/>
        </Box>
        <Box className={classes.specialtiesBox}  >
            {props.specList.map((name,key)=>{
                return (
                    <Fragment key={key}>
                        <Box className={classes.specialties} >
                            <Typography display="inline" >{name}</Typography>
                            <CancelRoundedIcon id={key} color='disabled' className={classes.removeIcon} onClick={props.remove}/>
                        </Box>
                    </Fragment>
                )
            })}
        </Box>
        <Box className={classes.searchBar}>
            <FormControl>
                    <Autocomplete
                        onChange={(event, value) => props.add(value)}
                        size='small'
                        options={specialties}
                        getOptionDisabled={(option) =>  props.specList.includes(option)}
                        getOptionLabel={(option) => option}
                        style={{ width: '25rem' }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}  
                    />
            </FormControl>
        </Box>
        
        <DialogActions className={classes.cancelSave}>
            <Button className={classes.cancelSaveButton} onClick={props.close} color="primary">CANCEL</Button>
            <Button className={classes.cancelSaveButton} onClick={props.done} color="primary">DONE</Button>
        </DialogActions>
      </Dialog>
    </>
)}

export default Popup