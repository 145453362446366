import {
Button, Dialog,DialogActions, DialogContent,DialogTitle, Grid,InputBase, InputLabel,makeStyles, MenuItem,withStyles} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { ValidatorForm } from "react-material-ui-form-validator";
import validator from "validator";
import GlobalContext from "../../context/GlobalContext";

const useStyles = makeStyles({
    formRow: {
        padding: "8px",
    },
    dialog: {
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        color: "black",
        bottom: "0px",
        borderRadius: "8px",
    },
    dialogActions: {
        display: "flex",
        justifyContent: "space-between",
        margin: 11,
    },
    muiListItemButton: {
        "&:hover": {
            backgroundColor: "#D3E2FF",
        },
    },
});

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: "2px",
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.common.white,
        color: "#767676",
        border: "1px solid #343434",
        fontSize: 16,
        width: "100%",
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

const EditOrgProfile = ({
    org,
    onClose = () => {},
    onMenuClick = () => {},
    onSaveComplete = () => {},
}) => {
    const classes = useStyles();
    const formRef = useRef();
    const global = useContext(GlobalContext);
    const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
    const [formInput, setFormInput] = useState({
        orgName: org.org_name,
        address: org.address,
        phNumber: org.contact_info,
        website: org.website,
    });

    useEffect(() => {
        setFormInput({
            orgName: org.org_name,
            address: org.address,
            phNumber: org.contact_info,
            website: org.website,
        });
    }, [org, isEditProfileOpen]);

    const handleOnMenuClick = () => {
        onMenuClick();
        setIsEditProfileOpen(true);
    };
    const handleFormInputChange = (event) => {
        setFormInput({ ...formInput, [event.target.name]: event.target.value });
    };

    const submitProfileSettings = async (evt) => {
        evt.preventDefault();
        let tempInfo = JSON.parse(JSON.stringify(formInput));
        const res = await global.api.updateOrgProfileSettigs(org._id, tempInfo);

        if (res.status === 200) {
            onSaveComplete();
            setIsEditProfileOpen(false);
        }
    };

    useEffect(() => {
        /*
        Input validation rules. These are used to add error helperText and supplement set input contstraints.
        */
        ValidatorForm.addValidationRule("validNumber", (ph) => {
            // The last digit must be a number
            return ph.slice(-1) !== " ";
        });

        ValidatorForm.addValidationRule("validAddress", (address) => {
            // Must contain at least one letter and one number
            return /(?=.*?[0-9])(?=.*?[A-Za-z]).+/.test(address);
        });

        ValidatorForm.addValidationRule("minValidInput", (input) => {
            return input.length > 2;
        });

        ValidatorForm.addValidationRule("validUrl", (url) => {
            return validator.isURL(url);
        });
    });

    return (
        <>
            <MenuItem
                onClick={handleOnMenuClick}
                className={classes.muiListItemButton}
            >
                Edit Profile
            </MenuItem>
            <Dialog
                className={classes.dialog}
                open={isEditProfileOpen}
                onClose={onClose}
            >
                <DialogTitle>
                 <p style={{
                            fontSize: "24px",
                            fontFamily:"Roboto",
                            fontWeight: "bold",
                            color: "#222222",
                            marginTop: "15px",
                            marginBottom: "-15px"
                        }}> Edit Profile </p>
                </DialogTitle>
                <ValidatorForm
                    ref={formRef}
                    onSubmit={submitProfileSettings}
                    borderRadius={8}
                    style={{ width: "320px", marginTop: "-20px" }}
                >
                    <DialogContent>
                        <Grid
                            container
                            spacing={2}
                            className={classes.InputLabel}
                        >
                            <Grid xs={12} className={classes.formRow}>
                                <InputLabel
                                    shrink
                                    htmlFor="bootstrap-input"
                                    style={{
                                        color: "#343434",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        marginTop: "12px",
                                    }}
                                >
                                    Organization Name
                                </InputLabel>
                                <BootstrapInput
                                    required
                                    label="Organization Name"
                                    name="orgName"
                                    fullWidth={true}
                                    value={formInput.orgName}
                                    type="text"
                                    variant="outlined"
                                    onChange={handleFormInputChange}
                                    validators={["minValidInput"]}
                                    errorMessages={[
                                        "Please enter a valid organization name",
                                    ]}
                                />
                            </Grid>
                            <Grid xs={12} className={classes.formRow}>
                                <InputLabel
                                    shrink
                                    htmlFor="bootstrap-input"
                                    style={{
                                        color: "#343434",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        marginTop: "12px",
                                    }}
                                >
                                    Address
                                </InputLabel>
                                <BootstrapInput
                                    required
                                    label="Address"
                                    name="address"
                                    fullWidth={true}
                                    value={formInput.address}
                                    type="text"
                                    variant="outlined"
                                    onChange={(event) => {
                                        // For some reason, this regex does not work using pattern inputProps
                                        if (
                                            /^[\w-#',/ .]*$/.test(
                                                event.target.value
                                            )
                                        ) {
                                            handleFormInputChange(event);
                                        }
                                    }}
                                    validators={[
                                        "validAddress",
                                        "minValidInput",
                                    ]}
                                    errorMessages={[
                                        "Please enter a valid address",
                                        "Please enter a valid address",
                                    ]}
                                />
                            </Grid>
                            <Grid xs={12} className={classes.formRow}>
                                <InputLabel
                                    shrink
                                    htmlFor="bootstrap-input"
                                    style={{
                                        color: "#343434",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        marginTop: "12px",
                                    }}
                                >
                                    Phone Number
                                </InputLabel>
                                <InputMask
                                    mask="(999) 999 9999"
                                    value={formInput.phNumber}
                                    onChange={handleFormInputChange}
                                    disabled={false}
                                    maskChar=" "
                                >
                                    {() => (
                                        <BootstrapInput
                                            required
                                            variant="outlined"
                                            fullWidth={true}
                                            label="Phone Number"
                                            name="phNumber"
                                            validators={["validNumber"]}
                                            errorMessages={[
                                                "Please enter a 10-digit number (includes area code)",
                                            ]}
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                            <Grid xs={12} className={classes.formRow}>
                                <InputLabel
                                    shrink
                                    htmlFor="bootstrap-input"
                                    style={{
                                        color: "#343434",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        marginTop: "12px",
                                    }}
                                >
                                    Website
                                </InputLabel>
                                <BootstrapInput
                                    required
                                    label="Website"
                                    name="website"
                                    fullWidth={true}
                                    value={formInput.website}
                                    type="text"
                                    variant="outlined"
                                    onChange={handleFormInputChange}
                                    validators={["validUrl"]}
                                    errorMessages={[
                                        "Please enter a valid website URL",
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={() => setIsEditProfileOpen(false)}
                            color="primary"
                            style={{fontWeight: "bold" }}
                        >
                            CANCEL
                        </Button>
                        <Button 
                            type="submit" 
                            color="primary"
                            style={{fontWeight: "bold" }}>
                            SAVE
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </>
    );
};

export default EditOrgProfile;
