/*
 * @Author: Vincent Tran
 * @Date: 2020-07-31 03:16:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-07-31 03:31:00
 * @Description: file content
 */
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useContext } from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle';
import UserPageStyles from '../../../../../styles/usersPageStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, UserPageStyles);

export default function DisablePopup(props) {
    const global = useContext(GlobalContext);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = async () => {
        let logo = global.state.selectedOrg.logo ? global.state.selectedOrg.logo : global.state.defaultOrg.logo
        let contactname = props.account.contact_name
        setOpen(false);
        console.log(props.account.contact_name);
        let result = await global.api.sendDisableEmail(props.account.email, contactname, logo);
        if (result == 200) {
          props.handleClose();
        }
        
    };
    const handleSubmit = async () => {
        // disable or re-enable the account here..
        let result = await global.api.updateAccount({ id: props.account._id, accountDisabled: true, orgId: global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id });
        
        if (result && result.status === 200) {
            handleClose();
            props.refresh();
        } else {
            alert('Unable to disable account.');
        }
    }

    const classes = combinedStyles();

    return (
        <div>
            <ButtonBase className={props.className} onClick={handleClickOpen}> <Typography className={classes.typography}>Disable Account</Typography></ButtonBase>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">Disable {props.type === 'patients' ? 'Patient' : props.type === 'providers' ? 'Provider': props.type === 'moas' ? 'MOA' : props.type === 'admins' ? 'HAdmin' : props.type}<IconButton onClick={handleClose} className={classes.dialogClose} children={<ClearIcon />} /> </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.contentText}>
                        Are you sure you want to disable {props.account.contact_name}'s account? {props.account.contact_name} will not be able to log in until the account is re-enabled.
                        <br/><br/>You can re-enable the account anytime by clicking on it.
                    </DialogContentText>
                </DialogContent>

                <DialogActions className={classes.dialogAction}>
                    <Button onClick={handleClose} color="primary" className={classes.actionBtn}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" className={classes.actionBtn}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}