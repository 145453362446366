import { InputAdornment, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import GlobalContext from "../../../../../context/GlobalContext";




export default function CheckInForms(props) {
    const classes = props.classes;
    const state = props.state;
    const setState = props.setState;
    const handleChange = props.handleChange;
    const handleCloseSnackbar = props.handleCloseSnackbar;
    const editUrl = props.editUrl;
    const urlTextChange = props.urlTextChange;
    const cancelEditUrl = props.cancelEditUrl;
    const confirmEditUrl = props.confirmEditUrl;
    const settingsPermission = props.settingsPermission;
    const global = React.useContext(GlobalContext)
    const [checkEnv, setcheckEnv] = React.useState(false)
    
    useEffect(()=> {
        let getCheckinEnvVar = async () => {
            let checkinEnvVar
                
            try {
                let getEnvVar = await global.api.GetEnvVariable({name: 'checkin-app'})
                checkinEnvVar = getEnvVar.data.res
            } catch (error) {
                // console.log(error.response ? error.response.data : error)
            }
            checkinEnvVar && setcheckEnv(checkinEnvVar)
        }
        getCheckinEnvVar()
    },[])

    return (
        <>
            <Box xs={12} className={classes.paperComponment}>
                <Box className={classes.labelWithEdit} >
                    <Typography className={classes.labelSmall} >Portal and Website Integration</Typography>
                    {settingsPermission && [
                        <Button color="primary" onClick={editUrl} name={"editUrl"} disabled={!state.isEditPressed}>
                            <Typography variant="subtitle2" className={classes.editportalbutton}>EDIT&nbsp;URL</Typography>
                        </Button>
                    ]}
                </Box>

                <Box className={classes.urlTextBox}>
                    <TextField
                        className={clsx(classes.urlTextField, classes.disabledBackground)}
                        style={{'paddingLeft' : '15px'}}
                        fullWidth
                        id="outlined-multiline-static"
                        name="portalWebsiteIntegration"
                        onChange={handleChange}
                        value={`${checkEnv}/${state.whiteLabelURL}`}
                        InputProps={{ disableUnderline: true,}}
                    />
                     <Button
                        className={!state.isEditPressed ? classes.disabledCopyUrlButton : classes.copyUrlButton}
                        variant="contained"
                        onClick={() => {
                            navigator.clipboard.writeText(checkEnv + "/" + state.whiteLabelURL)
                            setState({...state, openSnackbar: true})
                        }}>
                        Copy
                    </Button>
                </Box>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={state.openSnackbar}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={2000}
                    message="URL copied to clipboard"
                    key={'bottom' + 'center'}
                />

                {state.editUrl && [

                <Paper elevation={3} className={classes.smallContainer} style={{marginBottom:'5%'}}>
                    <Box xs={12} className={classes.editUrlBox}>
                        
                        <Typography className={classes.labelSmallerSpacing}>Edit Portal URL</Typography>
                        <Typography className={classes.urlTextDisplay}>{`${checkEnv}/${state.whiteLabelURL}`}</Typography>
                        <Typography className={classes.labelText}>Room URL</Typography>
                        <TextField 
                            InputProps={{ disableUnderline: true }}
                            onChange={urlTextChange}
                            value={state.whiteLabelURL ? state.whiteLabelURL : ""}
                            variant="outlined"
                            fullWidth
                            className={classes.textBox}
                            placeholder="Enter Url"
                            name="whiteLabelURL"
                            size="small"
                        />
                        <Grid container justify={"space-between"}>
                            <Button color="primary" className={classes.subButton} onClick={cancelEditUrl} disabled={!state.isEditPressed}>
                                <Typography variant="body2" className={classes.editportalbutton}>CANCEL</Typography>
                            </Button>
                            <Button color="primary" className={classes.subButton} onClick={confirmEditUrl} disabled={!state.isEditPressed}>
                                <Typography variant="body2" className={classes.editportalbutton}>SAVE CHANGES</Typography>
                            </Button>
                        </Grid>
                        
                    </Box>
                </Paper>

                ]}
            </Box>
        </>
    );
}

