import React from 'react';
import { Grid, Typography, IconButton, Switch, Box } from '@material-ui/core';
import settingsStyles from '../../../../../styles/settingsPageStyles';
import WebIcon from '@material-ui/icons/Web';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const Notifications = ({  handleEmailChange, handleBrowserChange, email, browser }) => {
    const classes = settingsStyles();


    return (
        <Grid container className={classes.section}>
            <Grid item xs={12}>
                <Typography className={classes.labelSmall}>Notifications</Typography>
            </Grid>
            <Grid item md={12} sm={12} >
                
                <Box className={classes.labelWithEdit} item>
                    <IconButton style={{"padding-left": 0}} aria-label="Browser"><WebIcon/></IconButton>
                    <Typography className={classes.labelSmall}  style={{"margin": "auto 0"}}>Browser</Typography>
                    <Switch 
                        color='primary'
                        checked={browser}
                        onClick={handleBrowserChange}
                        className={classes.switch}
                    />
                </Box>
                
            </Grid>    
            <Grid item md={12} sm={12} >
                
                <Box className={classes.labelWithEdit} item>
                    <IconButton style={{"padding-left": 0}} aria-label="Email"><EmailIcon/></IconButton>
                    <Typography className={classes.labelSmall}  style={{"margin": "auto 0"}}>Email</Typography>
                    <Switch
                        color='primary'
                        checked={email}
                        onClick={handleEmailChange}
                        className={classes.switch}
                        disabled={true}
                    />
                </Box>
                
            </Grid>                                               
            <Grid item md={12} sm={12} >
                <Box item className={classes.labelWithEdit}>
                    <IconButton style={{"padding-left": 0}} aria-label="SMS"><SmsIcon/></IconButton>
                    <Typography className={classes.labelSmall} style={{"margin": "auto 0"}} >SMS</Typography>
                    <Switch
                        color='primary'
                        className={classes.switch}
                        disabled={true}
                    />
                </Box>
            </Grid>
            <Grid item md={12} sm={12} >
                <Box className={classes.labelWithEdit}>
                    <IconButton style={{"padding-left": 0}} aria-label="WhatsApp" ><WhatsAppIcon/></IconButton>
                    <Typography className={classes.labelSmall} style={{"margin": "auto 0"}} >WhatsApp</Typography>
                    <Switch
                        color='primary'
                        className={classes.switch}
                        disabled={true}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default Notifications;