/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-09 03:39:12
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-07-29 05:38:06
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import Select from "@material-ui/core/Select";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import OrgSeletorFrame from "../../frame/OrgSeletor";
import SignUp from "../../frame/SignUp";
//Different tables
import UsersTable from "./component/UsersTable";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";

// custom tab styles
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: 10,
    "&": {
      marginTop: "-10",
      height: 5,
    },
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#003FBA",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#767676",
    // fontWeight: theme.typography.fontWeightRegular,
    fontSize: "1.5rem",
    fontWeight: 500,
    "&:hover": {
      opacity: 1,
    },
    "&:focus": {
      opacity: 1,
      color: "#003FBA",
    },
    "&$selected": {
      color: "#003FBA",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme) => ({
  head: {
    display: "flex",
  },
  title: {
    paddingTop: "0.9rem",
    fontWeight: 500,
    display: "block",
    marginRight: "0rem",
    fontSize: "2rem",
    "@media (max-width:960px)": {
      display: "none",
    },
  },
  titleAdmin: {
    paddingTop: "0.9rem",
    fontWeight: 500,
    display: "block",
    marginRight: "0rem",
    fontSize: "2rem",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  container: {
    marginTop: "2rem",
  },
  paper: {
    padding: theme.spacing(1.75),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  buttonPapar: {
    width: "100%",
    height: "100%",
  },
  buttonInvites: {
    width: "100%",
    height: "100%",
    fontSize: "1rem",
    boxShadow: "none",
    backgroundColor: "#FFFFFF",
    alignItems: "left",

    "&:hover": {
      backgroundColor: "#eeeeee",
      // borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#dddddd",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
  typography: {
    fontWeight: 500,
    padding: theme.spacing(2),
  },
  dropDownText: {
    fontWeight: 500,
    padding: "5px",
    paddingLeft: "12px",
  },
  formControl: {
    width: "100%",
  },
  inviteButtonContentContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inviteButtonLabel: {
    fontWeight: 500,
  },
  invitePopOver: {
    marginTop: "0.5rem",
    width: "100%",
  },
  invitePopOverItemLayout: {
    width: "100%",
    display: "flex",
    justifyContent: "left",
  },
  icon: {
    color: "#767676",
    margin: " 0rem 0rem 0rem 1rem",
  },
  clearButton: {
    paddingRight: "1rem",
    fontSize: "1.1rem",
    opacity: 0.5,
    "&:hover": {
      opacity: 0.8,
      background: "none",
    },
    "&:active": {},
  },
}));
// Tabpanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function App(props) {
  const global = React.useContext(GlobalContext);
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [dropdown, setDropdown] = React.useState(4);
  const [pendingUsers] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [openConfirmEncryptEmails, setOpenConfirmEncryptEmails] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [openSignUp, setOpenSignUp] = React.useState();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeEncryptEmails = () => {
    setOpenConfirmEncryptEmails(false);
  };

  const handlePendingUsers = () => {
    //placeholder
  };

  const handleChangeDropdown = (event) => {
    return setDropdown({
      ...dropdown,
      [event.target.name]: event.target.value,
    });
  };

  //select postion end
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const open2 = Boolean(anchorEl2);

  const handleInvite = () => {
    handleClose();
    setOpenSignUp(true);
  };

  const handleEncryptEmails = async () => {
    // disable or re-enable the account here
    setOpenConfirmEncryptEmails(true);
    setIsLoading(true);
    let result = await global.api.encryptPatientEmails({
      orgId: global.state.selectedOrg._id
        ? global.state.selectedOrg._id
        : global.state.defaultOrg._id,
    });

    if (result && result.status === 200) {
      setIsLoading(false);
      handleClose();
    } else {
      alert("Unable to encrypt patient emails.");
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSignUpClose = () => {
    setOpenSignUp(false);
  };

  const resetSearchText = () => {
    setSearchText("");
  };

  var baseURL = window.location.href.split("/");

  useEffect(() => {
    var route = baseURL[4];

    switch (route) {
      case "admins":
        setValue(4);
        break;
      case "providers":
        setValue(3);
        break;
      case "moas":
        setValue(2);
        break;
      case "patients":
        setValue(1);
        break;
    }
  }, [baseURL]);

  let settingsPermission =
    global.state.user && global.state.user.document.roles[0] === "admin";
  useEffect(() => {
    if (settingsPermission) {
      props.setTitle("Select Organization");
    } else {
      props.setTitle("Manage Users");
    }
  }, []);

  return (
    <Container>
      <Dialog
        open={openConfirmEncryptEmails}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {isLoading ? (
          <>
            <DialogTitle
              className={classes.dialogTitle}
              id="alert-dialog-slide-title"
            >
              Encrypting Emails
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.contentText}>
                Patient Emails are being encrypted.
              </DialogContentText>
              <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CircularProgress size={30} />
              </Grid>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle
              className={classes.dialogTitle}
              id="alert-dialog-slide-title"
            >
              Emails Encrypted
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.contentText}>
                Patient Emails have been encrypted successfully.
              </DialogContentText>
            </DialogContent>
          </>
        )}

        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={closeEncryptEmails}
            color="primary"
            className={classes.actionBtn}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {settingsPermission && <OrgSeletorFrame />}
      <Box className={classes.head}>
        <Typography
          className={settingsPermission ? classes.titleAdmin : classes.title}
          variant="h4"
        >
          Manage&nbsp;Users
        </Typography>
      </Box>
      <Box>
        <div className={classes.demo2}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="styled tabs example"
            TabIndicatorProps={{
              style: {
                height: "10px",
              },
            }}
          >
            <StyledTab
              label="Groups"
              component={Link}
              to={`${url}/Groups`}
              {...a11yProps(1)}
            />
          </StyledTabs>
          <Typography className={classes.padding} />
        </div>
      </Box>
      <Box className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={8} md={2}>
            <Paper elevation={3} className={classes.buttonPapar}>
              <FormControl className={classes.formControl}>
                <Select
                  displayEmpty
                  disableUnderline
                  value={dropdown !== undefined ? dropdown.sectionValue : 4}
                  onChange={handleChangeDropdown}
                  name="sectionValue"
                  defaultValue={4}
                  inputProps={{
                    "aria-label": "Without label",
                    disableUnderline: true,
                  }}
                  className={classes.selectDropdown}
                >
                  {global.state &&
                    global.state.selectedOrg &&
                    (JSON.stringify(global.state.selectedOrg) == "{}" ||
                      global.state.selectedOrg === global.state.defaultOrg) && (
                      <MenuItem
                        component={Link}
                        value={0}
                        to={`${url}/Organizations`}
                        disabled
                      >
                        {" "}
                        <Typography className={classes.dropDownText}>
                          Organizations{" "}
                        </Typography>{" "}
                      </MenuItem>
                    )}
                  <MenuItem component={Link} value={1} to={`${url}/patients`}>
                    {/*<Typography className={classes.dropDownText}>*/}
                    {/*  Patients{" "}*/}
                    {/*</Typography>{" "}*/}
                  </MenuItem>
                  <MenuItem component={Link} value={2} to={`${url}/moas`}>
                    <Typography className={classes.dropDownText}>
                      MOAs{" "}
                    </Typography>{" "}
                  </MenuItem>
                  <MenuItem component={Link} value={3} to={`${url}/providers`}>
                    <Typography className={classes.dropDownText}>
                      Providers{" "}
                    </Typography>{" "}
                  </MenuItem>
                  <MenuItem component={Link} value={4} to={`${url}/admins`}>
                    <Typography className={classes.dropDownText}>
                      Admins{" "}
                    </Typography>{" "}
                  </MenuItem>
                </Select>
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={8} md={5}>
            <Paper elevation={3} component="div" className={classes.root}>
              {" "}
              {}
              <IconButton
                disabledtype="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                fullWidth
                className={classes.input}
                placeholder="Search Users"
                style={{fontWeight:"700"}}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              {searchText && (
                <Button
                  className={classes.clearButton}
                  onClick={() => setSearchText("")}
                >
                  CLEAR
                </Button>
              )}
            </Paper>
          </Grid>
          <Grid item xs={5} md={2}>
            <Paper elevation={3} className={classes.buttonPapar}>
              <Button
                className={classes.buttonInvites}
                variant="contained"
                disabled={!pendingUsers}
                onClick={handlePendingUsers}
              >
                <Box className={classes.inviteButtonContentContainer}>
                  <Typography className={classes.inviteButtonLabel}>
                    Pending Users
                  </Typography>
                </Box>
              </Button>
            </Paper>
          </Grid>
          {global.state.user &&
            (global.state.user.document.roles[0] === "admin" ||
              global.state.user.document.roles[0] === "healthOrgAdmin" ||
              global.state.user.document.roles[0] === "doctor" ||
              global.state.user.document.roles[0] === "oa") && (
              <Grid item xs={3} md={2}>
                <Paper elevation={3} className={classes.buttonPapar}>
                  <Button
                    className={classes.buttonInvites}
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                  >
                    <Box className={classes.inviteButtonContentContainer}>
                      <Typography className={classes.inviteButtonLabel}>
                        Manage
                      </Typography>
                      <ArrowDropDownIcon />
                    </Box>
                  </Button>
                  <Popover
                    className={classes.invitePopOver}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <ButtonBase
                      className={classes.invitePopOverItemLayout}
                      onClick={handleInvite}
                    >
                      <Typography className={classes.typography}>
                        Invite New Account{" "}
                      </Typography>
                    </ButtonBase>
                    {/* <ButtonBase className={classes.invitePopOverItemLayout}>
                      <Typography className={classes.typography}>
                        Assign Role
                      </Typography>
                    </ButtonBase> */}
                    <ButtonBase
                      className={classes.invitePopOverItemLayout}
                      onClick={handleEncryptEmails}
                    >
                      <Typography className={classes.typography}>
                        Encrypt Emails
                      </Typography>
                    </ButtonBase>
                  </Popover>
                </Paper>
              </Grid>
            )}
          <SignUp
            open={openSignUp}
            onClose={handleSignUpClose}
            role={
              global.state.user && global.state.user.document.roles[0]
                ? global.state.user.document.roles[0]
                : null
            }
          />
        </Grid>
      </Box>
      <Switch>
        <Route exact path={path}>
          <Redirect
            to={{
              pathname: `${path}/admins`,
            }}
          />
        </Route>
        <Route path={`${path}/patients`}>
          <UsersTable
            type="patients"
            searchText={searchText}
            resetSearchText={resetSearchText}
          />
        </Route>
        <Route path={`${path}/moas`}>
          <UsersTable
            type="moas"
            searchText={searchText}
            resetSearchText={resetSearchText}
          />
        </Route>
        <Route path={`${path}/providers`}>
          <UsersTable
            type="providers"
            searchText={searchText}
            resetSearchText={resetSearchText}
          />
        </Route>

        <Route path={`${path}/admins`}>
          <UsersTable
            type="admins"
            searchText={searchText}
            resetSearchText={resetSearchText}
          />
        </Route>
      </Switch>
    </Container>
  );
}

export default App;
