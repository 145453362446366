import React from "react";

import useStyles from "./styles/GetReportHeader";
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Typography from "@material-ui/core/Typography";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

export default function PatientReportHeader({
  logo,
  address,
  email,
  contactNum,
}) {
  const classes = useStyles();

  const theme = useTheme();
  const middleScreen = useMediaQuery(theme.breakpoints.down(1000));
  const smallScreen = useMediaQuery(theme.breakpoints.down(450));
  let gapSize;
  if (smallScreen) {
    gapSize = "0px";
  } else if (middleScreen) {
    gapSize = "50px";
  } else {
    gapSize = "50px";
  }
  return (
    <header className={classes.header}>
      <div
        className={smallScreen ? classes.columnContainer : classes.rowContainer}
      >
        <div className={classes.portonImgHeaderDiv}>
          <img
            className={classes.getReportPortonImgHeader}
            src={logo}
            alt="Porton"
          />
        </div>

        <div className={classes.addressAndPhone} style={{ gap: gapSize }}>
          {address && (address !== "null" || address !== "") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton>
                <LocationOnIcon style={{ color: "#347abc" }} />
              </IconButton>
              <Typography className={classes.footerText} variant="body2">
                {address}
              </Typography>
            </div>
          )}

          {contactNum && (contactNum !== "null" || contactNum !== "") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton>
                <PhoneIcon style={{ color: "#347abc" }} />
              </IconButton>
              <Typography className={classes.footerText} variant="body2">
                {contactNum}
              </Typography>
            </div>
          )}

          {/*{email && (email !== "null" || email !== "") && (*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      display: "flex",*/}
          {/*      flexDirection: "column",*/}
          {/*      alignItems: "center",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <IconButton>*/}
          {/*      <EmailIcon style={{ color: "#347abc" }} />*/}
          {/*    </IconButton>*/}
          {/*    <Typography className={classes.footerText} variant="body2">*/}
          {/*      {email}*/}
          {/*    </Typography>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>
    </header>
  );
}
