import { Box, Collapse, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';
import combineStyles from '../../../../styles/combineStyles';
import settingsStyles from '../../../../styles/settingsPageStyles';
import ToolStyle from '../../../../styles/ToolStyle.js';
import ControlPullDataAutomation from './component/ControlPullDataAutomation';

const combinedStyles = combineStyles(settingsStyles, ToolStyle);

function ControlPullData() {
	const classes = combinedStyles();
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
	    setExpanded(!expanded);
	};

	return (
		 <Paper elevation={3} className={classes.container} style={{border:expanded?"2px solid #003FBA":"2px solid rgba(255,255,255,0)",borderRadius:"10px", width:'100%'}}>
            <Grid container>   
                <Grid item xs={12} className={classes.paperComponment}>
                    <Box onClick={handleExpandClick} className={classes.labelWithEdit, classes.labelWithEditDropdown}>
                        <Typography 
                        	className={clsx(classes.label, {
	                        	[classes.expandedLabel]: expanded
	                        })}>
	                        Control Pull Data
	                        </Typography>
                        <Typography className={classes.edit}>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Typography>
                    </Box>
                </Grid>
               
                <Grid item xs={12}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
											<ControlPullDataAutomation />
                    </Collapse>
                </Grid>
            </Grid>

        </Paper>
	)
}


export default ControlPullData;