import {
  Box,Switch, Typography
} from '@material-ui/core';
import React, { useEffect } from 'react';
import settingsStyles from '../../../../../styles/settingsPageStyles';

export default function Bookings({ state, setState }) {

  const classes = settingsStyles();

  useEffect(() => {
    // Check for (new) undefined values
    if(typeof state.patientSettings.allowWalkIns === 'undefined') {
      setState({ ...state, patientSettings:{... state.patientSettings, allowWalkIns: false }});
    };
    if(typeof state.patientSettings.restrictPrimary === 'undefined') {
      setState({ ...state, patientSettings:{... state.patientSettings, restrictPrimary: false }});
    };
    if(typeof state.patientSettings.allowNewEmrPatients === 'undefined') {
      setState({ ...state, patientSettings:{... state.patientSettings, allowNewEmrPatients: false }});
    };
  }, []);

  const handleChange = (event) => {
    setState({ ...state, patientSettings:{... state.patientSettings, [event.target.name]: event.target.checked }});
  };

  return (
    <Box>
      <Box className={classes.labelWithEdit} style ={{width: "100%", alignItems: 'center'}}>
        <Typography className={classes.labelSmall}> Allow Walk-In Patients </Typography>
        <Switch 
          size="small"
          checked={state.patientSettings.allowWalkIns}
          onChange={handleChange}
          color={!state.isEdit ? '#767676' : 'primary'}
          name="allowWalkIns"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Box>
      <Box className={classes.labelWithEdit} style ={{width: "100%", alignItems: 'center'}}>
        <Typography className={classes.labelSmall}> Allow New Patient Registration on EMR </Typography>
        <Switch 
          size="small"
          checked={state.patientSettings.allowNewEmrPatients}
          onChange={handleChange}
          color={!state.isEdit ? '#767676' : 'primary'}
          name="allowNewEmrPatients"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Box>
      <Box className={classes.labelWithEdit} style ={{width: "100%", alignItems: 'center'}}>
        <Typography className={classes.labelSmall}> Restrict patients to book only with primary provider </Typography>
        <Switch 
          size="small"
          checked={state.patientSettings.restrictPrimary}
          onChange={handleChange}
          color={!state.isEdit ? '#767676' : 'primary'}
          name="restrictPrimary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Box>
  </Box>
  );
}