import React from "react";
import { ReactComponent as PortonLogo } from "../img/virtualapplogo.svg";
// Style
import useStyles from "./styles/NewFooterStyle";

export default function ReportFooter({ address, contactNum, email }) {
  const classes = useStyles();

  // Your component code...
  return (
    <footer className={classes.footer}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          <PortonLogo className={classes.portonImgFooter} />
        </div>
      </div>
    </footer>
  );
}
