import { Box, Button, Dialog, DialogActions, DialogContent, List, ListItem, makeStyles, Step, StepIcon, StepLabel, Stepper, Typography } from "@material-ui/core";
import { CheckCircleOutlineOutlined, CheckCircleRounded, KeyboardArrowRight } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import GlobalContext from "../../../../../context/GlobalContext";
import spinerIcon from './img/spinner.gif';
import OnImportSuccess from './successImport';


const useStyles = makeStyles((theme) => ({
    labelSmall: {
        ...theme.labelSmall,
        width: 'unset',
        margin: 0,
        marginBottom: 0,
        lineHeight: 'unset',
        flex: '1',
        fontSize:"15px",
        fontWeight:400,
        fontFamily:"Roboto",

    },
    iconTextWrapper: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap'
    },
    spinnerLoader:{
      position:"absolute",
      zIndex:9,
      margin:350,
      marginTop:35,
      textAlign: "center"
    },
    deletebtn:{
        marginTop:'10px', 
        border: "1px solid #003FBA",
        color:"#003FBA",
        marginBottom: "12px"
    },
    detailsBtn:{
        marginTop:'10px', 
        border: "1px solid #003FBA",
        color:"#003FBA",
    },
    stepper:{
        backgroundColor:"transparent",
        "& .MuiStepIcon-active": { color: "red !important" },
        "& .MuiStepIcon-completed": { color: "green !important" },
        "& .Mui-disabled .MuiStepIcon-root": { color: "cyan !important" }
    },
    importBtn:{
        border: "1px solid #003FBA",
        color:"#003FBA",
        fontWeight:500,
    },
    emrUrl:{
        color:"black",
        fontWeight:500
    },
    checkCircle:{
        color:"#71BDA4",
        marginTop: "auto",
        marginLeft:"5px"
    },

    list:{
        fontFamily:"Roboto",
    },

  }));

  const useIconStyles = makeStyles((theme) => ({
      
      numberIcon: {
          display: "inline-flex",
          width: 24,
          height: 24,
          alignItems: "center",
          justifyContent: "center",
          background: "transparent",
          border: "1px solid #71BDA4",
          borderRadius: "50%",
          color: "#71BDA4"
      }
    

  }))

  const CustomStepIcon = (props) => {
      const {active, completed, icon} = props;

      const iconClasses = useIconStyles();

      if (active) {
        return <div className={iconClasses.numberIcon}><span>{icon}</span></div>;
      }

      if (completed) {
          return <CheckCircleRounded style={{color: "#71BDA4"}}/>;
      }
    
      return <StepIcon {...props} classes={iconClasses.icon}/>
  }


const EMRIntegrationStatus = ({deleteEmr,onClose, setNewProviderAdded, expandImportProviderDrawer}) => {
    const global = useContext(GlobalContext);
    const user = global.state?.user?.document || {};
    const classes = useStyles();

    const [uploadingStart,setUploadingStart] = useState(0);
    const [successOpen,setsuccessOpen] = useState(false);
    const [isClientKeyPopoverShown, setIsClientKeyPopoverShown] = useState(false);


    const {emr: globalEmr = {}, _id: orgId, orgName} =  Object.keys(global.state.selectedOrg).length !== 0 ? global.state.selectedOrg || {} : global.state.defaultOrg || {};

    let legacyEmrData = false;

    // Handle for old stuff
    if(!globalEmr?.authenticationMethods && globalEmr?.emrName && globalEmr?.authorized) {
        // this means we are in old data without authentication methods
        legacyEmrData = true;
    }

    if(!legacyEmrData && !globalEmr?.authenticationMethods) {
        return null;
    }

    const isRest = legacyEmrData || globalEmr?.authenticationMethods?.rest;
    const isSoap = legacyEmrData || globalEmr?.authenticationMethods?.soap;

    const handleClose = () => {
        setIsClientKeyPopoverShown(false);
      };
    

    const steps = [{
        label: "Add EMR",
        done: !!globalEmr?.emrName
    }]

    if(isRest) {
        steps.push({
            label: "REST",
            done: !!globalEmr?.authorized
        })
    }

    if(isSoap) {
        steps.push({
            label: "SOAP",
            done: !!globalEmr?.securityId
        })
    }

    steps.push({
        label: "IMPORT",
        done: false
    })

    const showImportButton = () => {
        if(legacyEmrData || (globalEmr?.authenticationMethods?.rest && globalEmr?.authenticationMethods?.soap) ) {
            return steps[1].done && steps[2].done; // if we have both auth methods, then both the integrations should have been completed
        }

        // if there is only one auth method, then just return the done status of that
        return steps[1].done;
    }

    const closeSuccessPopup = () =>{
        setUploadingStart(0);
        setsuccessOpen(false);
    }

    const handleImport = async () => {
        try {
          setUploadingStart(1);
          
          await global.api.importRightNow(orgId).then(data=>{
            setUploadingStart(2);
            setsuccessOpen(true);
            setNewProviderAdded(true)
            expandImportProviderDrawer()
          });
          await global.api.sendImportCompletedEmail(user.email || user.emails[0].address, orgName);
            
        } catch (err) {
        }
      };

      const showKeysButton = (legacyEmrData) || (globalEmr?.authenticationMethods?.rest && steps[1].done);
        
    return (
        <div style={{display: 'flex'}}>
            <div>
                <div style={{display: "inline-flex"}}>
                <Typography className={classes.labelSmall}>{globalEmr?.emrName}</Typography>
                {showImportButton() ? <CheckCircleRounded className={classes.checkCircle}/>:<CheckCircleOutlineOutlined style={{color:"#71BDA4"}}/>}
                </div>
                <Box>
                    <Typography className={classes.emrUrl} variant="caption">{globalEmr?.emrURL}</Typography>
                </Box>
                {showKeysButton && <Box>
                    <Button className={classes.detailsBtn} variant="outlined" onClick={() => setIsClientKeyPopoverShown(true)}>SHOW EMR DETAILS</Button>
                </Box>}
                <div>
                    <Button className={classes.deletebtn} variant="outlined" color="error" onClick={deleteEmr}>Delete</Button>
                </div>
            </div>
            <div style={{flex: '1'}}>
                <Stepper className={classes.stepper}  alternativeLabel activeStep={showImportButton() ? steps.length-1 : undefined}>
                    {steps.map(({label, done}) => {
                        const stepProps = {};

                        stepProps.completed = done;

                        return (<Step key={label} {...stepProps}>
                            <StepLabel StepIconComponent={CustomStepIcon} >{label}</StepLabel>
                        </Step>);
                    })}
                </Stepper>
            </div>
             <div>
                {showImportButton() && <Button
                    // disabled
                    variant='outlined'
                    className={classes.importBtn}
                    onClick={handleImport}
                    endIcon={<KeyboardArrowRight/>}>
                    
                    IMPORT DOCTORS
                </Button>}
            </div> 
            { uploadingStart === 1 && 
                (<p className={classes.spinnerLoader}>
                    <img src={spinerIcon} alt="spiner"/>
                </p>)
            }
            { uploadingStart === 2 && 
                (<OnImportSuccess 
                    open = {successOpen}
                    onClose = {closeSuccessPopup}
                />)
            }
      <Dialog
        open={isClientKeyPopoverShown}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
        <List component="div" className={classes.root} aria-label="contacts">
            
              <Typography style={{textAlign:"center"}}>
                  EMR Integration 
                 </Typography>
                    <ListItem button>
                        <Typography className={classes.list}>Client KEY : {globalEmr.clientKey}</Typography>
                    </ListItem>
                    <ListItem button>
                        <Typography>Client SECRET  : {globalEmr.clientSecret}</Typography>
                    </ListItem>
                </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" 
          onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
        </div>
    );
}

export default EMRIntegrationStatus;