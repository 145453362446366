import { Divider, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import settingsStyles from '../../../../../styles/settingsPageStyles';


const CancellationPolicy = (props) => {
    const classes = settingsStyles()
    const maxLength=500
    
    const handleCharCount = (e)=> {
        props.setCharsCancellation(e.target.value.slice(0,maxLength))
    }

    return (
        <Grid container className={classes.section}>
            <Grid item xs={12}>
                <Typography className={classes.labelOperation}>Cancellation Policy</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={9}
                    value={props.charsCancellation}
                    name="checkInInstructions"
                    onChange={handleCharCount}
                    variant="outlined"
                    style={{"width": "100%", "marginTop": "7px", "marginBottom": "2px", paddingRight:'10px'}}
                />
                <div className={classes.textCounter} style={{  "marginBottom": "0px", "paddingBottom": "0px",}}>
                    {props.charsCancellation.length}/{ maxLength }
                </div>
                </Grid>
            </Grid>
               
        </Grid>
    )
}

export default CancellationPolicy;