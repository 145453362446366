import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";


const useStyles = makeStyles({
    dialogActions: {
        borderRadius: "40px",
        display: "flex",
        justifyContent: "space-between",
        margin: 9,
        },
    });

const OrgSelectorConfirmationDialog = ({
    open,
    onClose,
    onConfirm,
    newOrgName = "Default Organization",
}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            
        >
            <DialogTitle
                id="alert-dialog-title">
            <p style={{
                    fontSize: "24px",
                    fontFamily:"Roboto",
                    color: "#222222",
                    marginBottom: "5px",}}> 
                    Switch Organizations </p>
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    style={{
                        marginTop: "-25px",
                        fontSize: "16px",
                        width: "272px",
                        color: "#000000",
                        fontFamily:"Roboto"
                    }}
                >
                    <p>
                        Are you sure you want to switch the current organization to{" "}
                        <strong> {newOrgName}?</strong>{" "}Any unsaved changes you have made will be lost.
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={onClose}
                    color="primary"
                    className={classes.actionBtn}
                    style={{fontWeight: "bold" }}
                >
                    CANCEL
                </Button>
                <Button 
                    onClick={onConfirm} 
                    color="primary" 
                    style={{fontWeight: "bold" }}
                >
                    YES
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrgSelectorConfirmationDialog;
