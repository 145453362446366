/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-08 12:16:35
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2021-02-16 12:01:00
 * @Description: file content
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
// console.log(`${process.env.REACT_APP_S3_BUCKETNAME?process.env.REACT_APP_S3_BUCKETNAME:"porton-shared"}`)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
