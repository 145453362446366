import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import React from "react";
import GlobalContext from "../../../../../context/GlobalContext";
import Tick from "./styles/tick.png";

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: "center",
        justifyContent: "center",
        fontFamily: "Roboto",
        color:"#00000"
    },
    dialogActions: {
        display: "flex",
        justifyContent: "center",
    },

    dialogClose: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
    },

    tick: {
        maxWidth: "70px",
        justifyContent: "center",
        marginTop: "1rem",
    },
}));


 
const OnImportSuccess = ({
    open,
    onClose,
    handleClose,
    account,
    unlink
}) => {
    const classes = useStyles();
    const global = React.useContext(GlobalContext)
    return (
        <Dialog
            open={open}
            onClose={() => {onClose(); handleClose(); unlink();}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle
                id="alert-dialog-title"
                className={classes.dialogTitle}
            >
                {/* ICON HERE */}
                <img className={classes.tick} src={Tick} alt={Tick}/>
                
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography className={classes.dialogTitle}
                    style={{
                        
                        color: "#000000",
                        fontSize:"30",
                        fontWeight:600

                    }}
                    >
                        
                        {account.contact_name} has been removed from {global.state.selectedOrg.org_name}
                         
                      
                    </Typography>
                    <Typography 
                    style={{
                        
                        fontSize: "20px",
                        margintop: "10px",
                        textAlign: "center",
                        marginTop: "10px"
                    }}
                    >

                        An email has been sent to {account.contact_name} to notify them
                    
                    </Typography>
                    
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={() => {onClose(); handleClose(); unlink()}}
                    color="primary"
                    style={{
                        fontSize: "16px",
                        marginBottom: "20px",
                        fontWeight:700
                    }}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OnImportSuccess;
