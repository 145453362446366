import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import EmailIcon from '@material-ui/icons/Email';
import MicIcon from '@material-ui/icons/Mic';
import SmsIcon from '@material-ui/icons/Sms';
import React from 'react';





export default function CheckInForms(props) {
    const classes = props.classes;
    const state = props.state;
    const notifications = props.notifications;
    const handleNotificationChange = props.handleNotificationChange;

    return (
        <Grid container xs={12} direction='row' style={{padding: '0 25px'}}>
            <Box item md={12} sm={12} className={classes.labelWithEdit} style={{width: "100%"}}>
                <Typography className={classes.labelSmall} >Call-In Notification</Typography>
            </Box>
            <Typography className={classes.subLabel}>Please select the call-in notification options presented to the patient during check-in</Typography>
            <Grid item md={12} sm={12} xs={12}>
                
                <Box className={classes.labelWithEdit} item>
                    <IconButton style={{"padding-left": 0}} aria-label="Email"><EmailIcon/></IconButton>
                    <Typography className={classes.labelSmall}  style={{"margin": "auto 0"}}>Email</Typography>
                    <Switch 
                        className={classes.switch}
                        size="small"
                        checked={notifications.email}
                        onChange={handleNotificationChange}
                        color={!state.isEditPressed ? '#767676' : 'primary'}
                        name="email"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disabled={!state.isEditPressed}
                        />
                </Box>
                
            </Grid>                                               
            <Grid item md={12} sm={12} xs={12}>
                <Box item className={classes.labelWithEdit}>
                    <IconButton style={{"padding-left": 0}} aria-label="SMS"><SmsIcon/></IconButton>
                    <Typography className={classes.labelSmall} style={{"margin": "auto 0"}} >SMS</Typography>
                    <Switch 
                    className={classes.switch}
                    size="small"
                    checked={notifications.phone}
                    onChange={handleNotificationChange}
                    color={!state.isEditPressed ? '#767676' : 'primary'}
                    name="phone"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={!state.isEditPressed}
                    />
                </Box>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <Box className={classes.labelWithEdit}>
                    <IconButton style={{"padding-left": 0}} aria-label="Voice"><MicIcon/></IconButton>
                    <Typography className={classes.labelSmall} style={{"margin": "auto 0"}} >Voice</Typography>
                    <Switch 
                    className={classes.switch}
                    size="small"
                    checked={notifications.voice}
                    onChange={handleNotificationChange}
                    color={!state.isEditPressed ? '#767676' : 'primary'}
                    name="voice"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={!state.isEditPressed}
                    />
                </Box>
            </Grid>
            
        </Grid>
        
    );
}

