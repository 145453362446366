import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme)=>({

    pageMargin:{ marginTop:'1px', 
        
    },
    boxPadding:{
        padding:'1.5rem 2rem'
    },
    spreadHeader:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    heading:{
        fontSize:'1.25rem',
        fontWeight:'500'
    },
    subHeadingTopMargin:{
        marginTop:'1rem'
    },
    subHeadingText:{
        color:'#767676',
    },
    title:{
        fontWeight:'500',
        fontSize:'2.5rem',
        marginBottom:'1rem',
        fontFamily:'roboto',
        '@media (max-width:960px)': { 
            display: 'none',
        }
    },
    button:{
        fontWeight:'500',

    },
    borderRightSide:{
        borderRight:'solid 1px rgba(0, 0, 0, 0.12)',
    },
    tourMargin:{
        marginTop:'1rem'
    }


}))

