/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-23 00:29:17
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-07-24 02:33:28
 * @Description: file content
 */
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';
import React from "react";
import AutoComplete from "./component/AutoComplete";
import RegisterNewOrg from "./component/RegisterNewOrg.js";
import OrgSelectorPopup from "./OrgSelectorPopup";

const useStyles = makeStyles((theme) => ({
    head: {
        display: "flex",
    },
    title: {
        paddingTop: "0.2rem",
        fontWeight: 500,
        display: "block",
        marginRight: "2rem",
        fontSize: "2rem",
        marginBottom: "2rem",
        '@media (max-width:960px)': { 
            display: 'none',
        },
    },
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    divider: {
        marginTop: "0rem",
        marginBottom: "0rem",
    },
    container: {
        marginTop: "2rem",
    },
    typography: {
        padding: theme.spacing(1),
        "@media (max-width:1375px)": {
            fontSize: "70%",
        },
        "@media (max-width:1024px)": {
            fontSize: "80%",
        },
    },
    paper: {
        padding: theme.spacing(1.75),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    buttonPaper: {
        width: "100%",
        height: "77.5%",
    },
    buttonViewAllPatients: {
        width: "100%",
        height: "100%",
        fontSize: "1rem",
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#eeeeee",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#dddddd",
        },
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
        },
    },
    buttonInvites: {
        width: "100%",
        height: "100%",
        fontSize: "1rem",
        boxShadow: "none",
        color: "white",
        background:
            "radial-gradient(196.67% 240.67% at -54.22% -111.25%, #FFFFFF 0, #003FBA 100%)",
        alignItems: "left",

        "&:hover": {
            backgroundColor: "#eeeeee",
            // borderColor: '#0062cc',
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#dddddd",
        },
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
        },
    },
    iconButton: {
        padding: 10,
    },
}));

function OrgSelector(props) {
    const classes = useStyles();
    // const global = React.useContext(GlobalContext);
    const [openDialog, setOpenDialog] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSignUpOrg, setOpenSignUpOrg] = React.useState();
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInvite = () => {
        handleClose();
        setOpenSignUpOrg(true);
    };

    const handleSignUpOrgClose = () => {
        setOpenSignUpOrg(false);
    };


    const screenSizeSm = useMediaQuery(
        json2mq({
            maxWidth: 1024,
        }),
    );

    return (
        <div>
            <Typography className={classes.title} variant="h4">
                Select&nbsp;Organization
            </Typography>
            <Grid container spacing={3}>
                <Grid item md={8}xs={12}>
                    <AutoComplete />
                </Grid>
                <Grid item  md={2}xs={6}>
                    <Paper elevation={3} className={classes.buttonPaper}>
                        <Button
                            className={classes.buttonViewAllPatients}
                            onClick={() => {
                                setOpenDialog(true);
                            }}
                            variant="contained"
                            color="white"
                        >
                            {screenSizeSm && 
                            <Typography className={classes.typography} variant="button">
                                VIEW ORGS
                            </Typography>
                            }
                            {!screenSizeSm && 
                            <Typography className={classes.typography} variant="button">
                                VIEW ALL ORGS
                            </Typography>
                            }
                        </Button>
                    </Paper>
                </Grid>
                <Grid item md={2}xs={6}>
                    <Paper elevation={3} className={classes.buttonPaper}>
                        <Button
                            className={classes.buttonInvites}
                            onClick={handleInvite}
                            variant="contained"
                        >
                            {screenSizeSm && 
                            <Typography className={classes.typography} variant="button">
                                CREATE ORG
                            </Typography>
                            }
                            {!screenSizeSm && 
                            <Typography className={classes.typography} variant="button">
                                CREATE AN ORG
                            </Typography>
                            }
                        </Button>
                    </Paper>
                </Grid>
                <RegisterNewOrg
                    open={openSignUpOrg}
                    onClose={handleSignUpOrgClose}
                />
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
            </Grid>
            {props.children}
            <OrgSelectorPopup open={openDialog} onClose={() => setOpenDialog(false)}/>
{/* 

        */}
        </div>
    );
}

export default OrgSelector;
