/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-10 05:07:32
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2021-02-16 11:59:34
 * @Description: Style Reformating
 */

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import {
    fade,
    makeStyles, withStyles
} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAlert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import React, { useState } from 'react';
import GlobalContext from "../../../../context/GlobalContext";
import combineStyles from '../../../../styles/combineStyles';
import settingsStyles from '../../../../styles/settingsPageStyles';
import ToolStyle from '../../../../styles/ToolStyle.js';
import CallInNotification from './components/CallInNotification';
import CheckInForms from './components/CheckInForms';
import CheckinProviderList from './components/CheckinProviderList';
import PatientUpdateInfo from './components/PatientUpdateInformation';
import PatientVerification from './components/PatientVerification';
import SMSVoiceMessage from './components/SMSVoiceMessage';
import StatusCode from './components/StatusCodeDesignation';
import SubHeaderComponent from './components/SubHeaderComponent';
import VersionControlComponent from "./components/VersionControlComponent";





const CustomTextInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(0.5),
        },
        width: "100%",
        '&$disabled': {
            color: "black"
        },
    },
    input: {
        width: "100%",
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#DBDBDB",
        border: 'none',
        fontSize: 16,
        color: "black",
        fontWeight: 500,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),

        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
        '&$disabled': {
            color: "black"
        },
    },

}))(InputBase);
const CustomTextInputLabel = withStyles((theme) => ({
    root: {
        fontSize: "1.5rem",
        color: "black",
        fontWeight: 500,
        width: "100%"
    },

}))(InputLabel);
const useStyles = makeStyles((theme) => ({
    disabledSettingsBackground: {
        backgroundColor: "#F5F5F5",
        pointerEvents: "none",
    },

}));

let oldState;
let oldNotifications;
let oldVerification;
let oldProviderFilter;
let oldWhiteLabelURL;

const combinedStyles = combineStyles(settingsStyles, ToolStyle, useStyles);

function RoomURL({status}) {

   

    let defaultStatuses = {
        checked_in: 'H', //As default for new oscar org
        not_checked_in: 't',
        picked: '',
        noshow: '',
        cancel: '',
    }
    const [statusesNewConfig, setStatusesNewConfig] = useState(defaultStatuses);
    const [statuses, setStatuses] = useState([]);

    const [expanded, setExpanded] = React.useState(false);
    const classes = combinedStyles()
    const [doctors, setDoctors] = React.useState({})
    const [providerFilter, setProviderFilter] = React.useState({})
    const [notifications, setNotifications] = React.useState({
        email: true,
        phone: true,
        voice: true
    })
    const [verification, setVerification] = React.useState({
        enabled: false,
        dob: false,
        hin: false,
        phone: false,
        email: false,
        postal: false,
    })
    const [updateInformation, setUpdateInformation] = React.useState({
        enabled: false,
        address: false,
        city: false,
        province: false,
        postal: false,
        phone: false,
        email: false,
    })
    const maxLength = 120;
    const [providerFilterPopUp, setProviderFilterPopUp] = React.useState({})
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const global = React.useContext(GlobalContext) 
    const BUCKET_NAME = process.env.REACT_APP_S3_BUCKETNAME?process.env.REACT_APP_S3_BUCKETNAME:"porton-shared"
 /*   var fs = require('fs'); */

    let defaultSettings = {
        checkInSettings: {
            verificationOptions: {
                enabled: false,
                dob: true,
                hin: true,
                phone: false,
                email: false,
                postal: false,
            },
            updateInformation: {
                enabled: false,
                address: false,
                city: false,
                province: false,
                phone: false,
                email: false,
                postal: false,
            },
            CheckInForms: {
                checkedEnableForms: false,
                forms: [],
            },
            checkedEnableContact: false,
            checkInInstructions: 'Please fill in the form',
            //portalWebsiteIntegration: 'nothing.com',
            checkedEnableSettings: false,
            checkedDoctors: {},
            checkInNotification: false,
            notificationTypes: {
                email: true,
                phone: true,
                voice: true
            },
            versionToggle: false
        }
    }

    let currentOrgSettings
    let orgID = global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg._id : global.state.defaultOrg._id
    let [state, setState] = React.useState({});
    //const [selectedVerification, setSelectedVerification] = React.useState('hinOn');
    let whiteLabelURL
    
    React.useEffect(()=>{
        //gets the settings from porton db if exist, otherwise apply default settings and set the state
        let PortonAdminAppBaseURL = process.env.REACT_APP_PORTON_ADMIN_API
        if(!PortonAdminAppBaseURL){
            //console.log("not detecting the REACT_APP_PORTON_ADMIN_API")
            PortonAdminAppBaseURL="/api"
        }
        const fetchItems = async () => {
            try {
                const response = await global.api.getDoctorsFromEmr(orgID)

                response && setDoctors(response);

                if(global.state.selectedOrg.statuses && typeof global.state.selectedOrg.statuses !='undefined'){
                    setStatusesNewConfig( global.state.selectedOrg.statuses)
                }else{
                    //to do: disable check-in settings for moa until he complete the translation table
                }

            } catch (error) {
                // console.error(error);
            }
        }
        fetchItems();

        const fetchStatuses = async () => {
            try {
                let statuses = await global.api.fetchStatuses(orgID)
                statuses && setStatuses(statuses.data.message)
            } catch (error) {
                // console.error('error getting statuses for selected org', error)
            }
        }
        fetchStatuses()

        let envVar = async() => {
            if (typeof global.state.selectedOrg != 'undefined' && global.state.selectedOrg && global.state.selectedOrg != null  && JSON.stringify(global.state.selectedOrg) != "{}") {
                orgID = global.state.selectedOrg._id
                whiteLabelURL = global.state.selectedOrg.whiteLabelURL
                if(global.state.selectedOrg.checkInSettings && typeof global.state.selectedOrg.checkInSettings !='undefined'){
                    currentOrgSettings = global.state.selectedOrg.checkInSettings
                    // console.log("checkIn | 1")
                }else{
                    // console.log("checkIn | 2")
                    currentOrgSettings = defaultSettings.checkInSettings
                }
        
            } else { //default org
                orgID = global.state.defaultOrg._id
                whiteLabelURL = global.state.defaultOrg.whiteLabelURL 
                if(typeof global.state.defaultOrg.checkInSettings !='undefined' && global.state.defaultOrg.checkInSettings ){
                    currentOrgSettings = global.state.defaultOrg.checkInSettings
                    // console.log("checkIn | 3")
                }else{
                    currentOrgSettings = defaultSettings.checkInSettings
                    // console.log("checkIn | 4")
                }
            }
            //console.log("currentOrgSettings checkin: ", currentOrgSettings)
            setState({ ...state, 
                verificationOptions: currentOrgSettings.verificationOptions ? currentOrgSettings.verificationOptions : defaultSettings.checkInSettings.verificationOptions,
                updateInformation: currentOrgSettings.updateInformation ? currentOrgSettings.updateInformation : defaultSettings.checkInSettings.updateInformation,
                checkedEnableContact: currentOrgSettings.checkedEnableContact ? currentOrgSettings.checkedEnableContact : false,
                checkedEnableForms: currentOrgSettings.CheckInForms && currentOrgSettings.CheckInForms.checkedEnableForms ? currentOrgSettings.CheckInForms.checkedEnableForms : false,
                forms: currentOrgSettings.CheckInForms && currentOrgSettings.CheckInForms.forms ? currentOrgSettings.CheckInForms.forms : [],
                checkInInstructions: currentOrgSettings.checkInInstructions ? currentOrgSettings.checkInInstructions : '',
                checkedDoctors: currentOrgSettings.checkedDoctors ? currentOrgSettings.checkedDoctors : null,
                checkInNotification: currentOrgSettings.checkInNotification ? currentOrgSettings.checkInNotification : null,
                notificationTypes: currentOrgSettings.notificationTypes ? currentOrgSettings.notificationTypes : null,
                //portalWebsiteIntegration: currentOrgSettings.portalWebsiteIntegration ? currentOrgSettings.portalWebsiteIntegration : '',
                checkedEnableSettings: currentOrgSettings.checkedEnableSettings ? currentOrgSettings.checkedEnableSettings : '',
                versionToggle: currentOrgSettings.versionToggle ? currentOrgSettings.versionToggle : false,
                btnColor: "primary",
                openSnackbar: false,
                saveBtnSnackbarOpened: false,
                integrationUrlEditPressed: false,
                currentUploadedFile: null,
                formsToBeDeletedFromAws: [],
                whiteLabelURL: whiteLabelURL,
                isLoading: false,
                isEditPressed: false,
                isProviderListOpen: false,
                editUrl: false,
            })
            setProviderFilter(currentOrgSettings.checkedDoctors ? currentOrgSettings.checkedDoctors : {} )
            setNotifications(currentOrgSettings.notificationTypes ? currentOrgSettings.notificationTypes : {})
            setVerification(currentOrgSettings.verificationOptions ? currentOrgSettings.verificationOptions : defaultSettings.checkInSettings.verificationOptions)
            setUpdateInformation(currentOrgSettings.updateInformation ? currentOrgSettings.updateInformation : defaultSettings.checkInSettings.updateInformation)
        }
        envVar()
    },[global.state.defaultOrg, global.state.selectedOrg ])

    // React.useEffect(() => {
    //     if (state && doctors) {
    //         if (state.checkedDoctors === null) {
    //             for (const i in doctors) {
    //                 setProviderFilter(providerFilter => ({...providerFilter, [doctors[i].providerNo]: true}))
    //                 setState({...state, checkedDoctors: providerFilter})
    //             }
    //         } else {
    //             setProviderFilter(state.checkedDoctors)
    //         }            
    //     }

    // }, [doctors])
       
    orgID = JSON.stringify(global.state.selectedOrg) != "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg._id : global.state.defaultOrg._id

    const handleChange = (event) => {
        if(event.target.hasOwnProperty('checked')){
            return setState({ ...state, [event.target.name]: event.target.checked})
        } else if(event.target.hasOwnProperty('value')){
            return setState({ ...state, [event.target.name]: event.target.value})
        }
    }
    //saves all old settings to be applied when canceled
    //updated everytime edit is pressed, so should be consistent to old settings when cancelling
    const saveOldState = () => {
        oldState = JSON.parse(JSON.stringify(state));
        oldNotifications = JSON.parse(JSON.stringify(notifications));
        oldVerification = JSON.parse(JSON.stringify(verification));
        oldProviderFilter = JSON.parse(JSON.stringify(providerFilter));
        setState({ ...state, isEditPressed: true });
    }

    const loadOldState = () => {
        setState({ ...oldState, isEditPressed: false});
        setNotifications({...oldNotifications});
        setVerification({...oldVerification});
        setProviderFilter({...oldProviderFilter});
    }
    const handleNotificationChange = (event) => {
        setNotifications({ ...notifications, [event.target.name]: event.target.checked})
    }

    const handleVerificationChange = (event) => {
        if(event.target.name === "enabled" && !event.target.checked) {
            // set all of the verification options to false because verification is turned off
            setVerification({
                enabled: false,
                dob: false,
                hin: false,
                phone: false,
                email: false,
                postal: false,
            })
        } else {
            setVerification({ ...verification, [event.target.name]: event.target.checked})
        }
    }

    const handleUpdateInformationChange = (event) => {
        if(event.target.name === "enabled" && !event.target.checked) {
            // set all of the verification options to false because verification is turned off
            setUpdateInformation({
                enabled: false,
                address: false,
                city: false,
                province: false,
                phone: false,
                email: false,
                postal: false,
            })
        } else {
            setUpdateInformation({ ...updateInformation, [event.target.name]: event.target.checked})
        }
    }

    const handleCloseSnackbar = () => {
        setState({ ...state, openSnackbar: false });
    };
    
    const handleCloseSaveBtnSnackbar = (event, reason) => {
        if (reason === 'clickaway') return
        setState({ ...state, saveBtnSnackbarOpened: false });
      };

      function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

    const fileUploadButton = async (event) => {
        setState({ ...state, currentUploadedFile: event.target.files[0] });
        event.target.value = null
    }

    const handleProviderSelect = (event) => {
        setProviderFilter({...providerFilter, [event.target.name]: event.target.checked?false:true})
    }

    const handleProviderSelectPopUp = (event) => {
        setProviderFilterPopUp({...providerFilterPopUp, [event.target.name]: event.target.checked? false: true})
    }
    // console.log('providerFilter', providerFilter)

    function getDoctorName(post) {
        if (!doctors) return;
        const doctor = doctors.find(x => x.providerNo === String(post))
        if (doctor !== null) {
            return `Dr. ${doctor.firstName} ${doctor.lastName}`
        }
    }

    const DeleteUploadedFileAWS = async() => {
        let deleteForms = state.formsToBeDeletedFromAws
        let deletedNumber = 0
        for (const formKey of deleteForms){
            let result = await global.api.DeleteFileFromAws({            
                Key: formKey,
                BUCKET_NAME:  BUCKET_NAME
            })
            result.status === 200 && deletedNumber++
        }
        //console.log("number of files got deleted from AWS:", deletedNumber)
    }

    const DeleteUploadedFileDB = (formKey) => {
        let filteredForms = state.forms.filter(function(form ) {
            //if(form.key === formKey) formsToBeDeletedFromAws.push(form.key)
            form.key === formKey && state.formsToBeDeletedFromAws.push(form.key)
            return form.key !== formKey;
        });
        setState({...state, forms: filteredForms})
        //console.log("filteredForms: ", filteredForms)
        //console.log("formsToBeDeletedFromAws: ", state.formsToBeDeletedFromAws)
    }

    function saveByteArray(fileName, byte) {
        var bytes = new Uint8Array(byte)
        var blob = new Blob([bytes], {type: "application/pdf"})  //resultByte to bytes
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName
        link.click()
    }

    const fileRetrieveFromAWS =async (fileKey, fileName) => {
        let res = await global.api.DownloadFileFomAws({fileKey, fileName, BUCKET_NAME})
        res && saveByteArray(fileName, res.data.Body.data)
    }

    const {enabled,  dob, hin, phone, email, postal } = verification;

    const urlTextChange = (event) => {
        if (event.target.value === undefined) {
            return;
        }
        //prevents special characters except _ and -
        return setState({ ...state, [event.target.name]: event.target.value.replace(/[^-\w]/gi, "")})
    }
    
    const editUrl = () => {
        setState({ ...state, editUrl: !state.editUrl})
        oldWhiteLabelURL = state.whiteLabelURL;
    }
    const cancelEditUrl = () => {
        setState({ ...state, whiteLabelURL: oldWhiteLabelURL, editUrl: !state.editUrl})
    }
    const confirmEditUrl = () => {
        setState({ ...state, whiteLabelURL: state.whiteLabelURL, editUrl: !state.editUrl})
    }

    const saveSettings = async () => {
        //upload files to AWS by calling admin-api if that is needed 
        //save settings to porton DB
        //delete files from AWS if that is needed 
        //update global state

        if (enabled && [dob, hin, phone, email, postal].filter((v) => v).length === 0) {
            alert("Must select at least one verification option")
            return
        }

        if(updateInformation.enabled && Object.keys(updateInformation).filter(key => updateInformation[key]).length <= 1) {
            alert("Must select at least one information updating option")
            return
        }

        if(!global.state.selectedOrg && !global.state.defaultOrg){
            alert("No org!")
            return
        }
        if (state.whiteLabelURL.length === 0) {
            alert("Invalid whiteLabelURL")
            return
        }

        setState({ ...state, isLoading: true})

        let updateResult = await global.api.updateAppointmentStatuses(orgID, statusesNewConfig)
        if (updateResult && updateResult.status === 200) {
            if(JSON.stringify(global.state.selectedOrg) != "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' ){
                let org = global.state.selectedOrg
                org.statuses = statusesNewConfig
                global.setState((prev) => {
                    return { ...prev, selectedOrg: org }
                })
            }else { //default org
                let org = global.state.defaultOrg
                org.statuses = statusesNewConfig
                global.setState((prev) => {
                    return { ...prev, defaultOrg: org }
                })
            }
        }

        let whitelabelURLResult = await global.api.UpdateTextInDb(orgID, state.whiteLabelURL, "whiteLabelURL")
        
        if (whitelabelURLResult.status && whitelabelURLResult.status === 400){
            alert("Error updating whiteLabelURL, This whitelabel may already be in use by another organization")
            setState({ ...state, isLoading: false})
            return
        }
        let doc = {}
        if(state.currentUploadedFile){
            const data = new FormData()
            data.append('file',  state.currentUploadedFile)
            data.append('filename', state.currentUploadedFile.name)
            data.append('mimetype', 'PDF')
            data.append('contentType', 'application/pdf')
            data.append('BUCKET_NAME', process.env.REACT_APP_S3_BUCKETNAME?process.env.REACT_APP_S3_BUCKETNAME:"porton-shared")
            data.append('folderDir', 'docs/orgs/checkin-docs')

            let res = await global.api.UploadFileToAws(data)

            if (res.status === 200) {
                doc = {
                        name: state.currentUploadedFile.name,
                        location: res.data.Location,
                        key: res.data.key,
                }
                state.forms.push(doc)
            }else{

            }
        }

        let settings = {
            verificationOptions: {...verification},
            updateInformation: {...updateInformation},
            CheckInForms: {
                checkedEnableForms: state.checkedEnableForms,
                forms: state.forms,
            },
            checkedEnableContact: state.checkedEnableContact,
            checkInInstructions: state.checkInInstructions,
            //portalWebsiteIntegration: state.portalWebsiteIntegration,
            checkedEnableSettings: state.checkedEnableSettings,
            checkedDoctors: providerFilter,
            checkInNotification: notifications.email || notifications.phone || notifications.voice,
            notificationTypes: {
                email: notifications.email,
                phone: notifications.phone,
                voice:  notifications.voice
            },

            //toggle for switching steps for book app
            versionToggle: state.versionToggle,
        }

        let result = await global.api.UpdateCheckInSettings(orgID, settings)

        await DeleteUploadedFileAWS()
        if (result.status && result.status === 200) {
            if(JSON.stringify(global.state.selectedOrg) != "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' ){
                let org = global.state.selectedOrg
                org.checkInSettings = settings
                global.setState((prev)=>{
                    return { ...prev, selectedOrg: org }
                })
            }else { //default org
                let org = global.state.defaultOrg
                org.checkInSettings = settings
                global.setState((prev)=>{
                    return { ...prev, defaultOrg: org }
                })
            }
        }

        setState({ ...state, isLoading: false, isEditPressed: false, currentUploadedFile: null, editUrl: false})
        //result.status === 200 && setState({...state, btnColor : 'inherit'})
    }

    let settingsPermission = global.state.user.document.roles.includes("admin")

// if(!global.state.login) return null
if((typeof global.state.selectedOrg != 'undefined' && global.state.selectedOrg && global.state.selectedOrg != null  && JSON.stringify(global.state.selectedOrg) != "{}" || (typeof global.state.defaultOrg != 'undefined' && global.state.defaultOrg && global.state.defaultOrg != null  && JSON.stringify(global.state.defaultOrg) != "{}")))
{
    return (
        <Paper elevation={3} className={classes.container} style={{border:expanded?"2px solid #003FBA":"2px solid rgba(255,255,255,0)",borderRadius:"10px"}}>
            <Grid container>
               
                <Grid item xs={12} className={classes.paperComponment}>
                    <Box onClick={handleExpandClick} className={clsx(classes.labelWithEdit, classes.labelWithEditDropdown)}>
                        <Typography className={clsx(classes.label, {
	                        	[classes.expandedLabel]: expanded
	                        })}>CheckInApp</Typography>
                        <Typography className={classes.edit}>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton></Typography>
                    </Box>
                </Grid>
               
                <Grid item xs={12}>
                    <Collapse in={expanded || global.tourOpen} timeout="auto" unmountOnExit>
                        <Grid item md={12} sm={12} ><Divider className={classes.divider} /> </Grid>
                      
                        <Box className={!state.isEditPressed && classes.disabledSettingsBackground} style={{paddingTop: '14px'}}>
                            <Grid container>
                                <Grid item md={12} sm={12} xs={12} style={{padding: '0 16px'}}>
                                    <Box item md={12} sm={12} xs={12} className={classes.labelWithEdit} style={{"marginBottom": "15px"}}>
                                        <Box className={classes.labelWithEdit} style ={{"width": "99.3%"}}>
                                            {!state.isEditPressed && 
                                                <Typography  style={{color:"#767676"}} className={classes.labelSmall}>Enable CheckInApp</Typography>
                                            }
                                            {state.isEditPressed && 
                                                <Typography className={classes.labelSmallEnabled}>Enable CheckInApp</Typography>
                                            }
                                            <Switch 
                                                className={classes.switch}
                                                size="small"
                                                checked={state.checkedEnableSettings}
                                                onChange={handleChange}
                                                color={!state.isEditPressed ? '#767676' : 'primary'}
                                                name="checkedEnableSettings"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                disabled={!state.isEditPressed}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item md={12} sm={12} xs={12}><Divider className={classes.divider} /></Grid>

                                
                                <Grid container>
                                    
                                    <Grid item md={6} sm={12} >

                                        <SubHeaderComponent state={state} classes={classes} setState={setState}
                                        handleChange={handleChange} handleCloseSnackbar={handleCloseSnackbar}
                                        settingsPermission={settingsPermission} editUrl={editUrl} 
                                        urlTextChange={urlTextChange} cancelEditUrl={cancelEditUrl}
                                        confirmEditUrl={confirmEditUrl}/>

                                        <Grid item md={12} sm={12} ><Divider className={classes.divider} /></Grid>

                                        <VersionControlComponent state={state} classes={classes}
                                                                 setState={setState} handleChange = {handleChange}
                                        />
                                        <Grid item md={12} sm={12} ><Divider className={classes.divider} /></Grid>
                                        <CheckinProviderList state={state} classes={classes} setState={setState} tourOpen={global.tourOpen}
                                        doctors={doctors} providerFilter={providerFilter} handleProviderSelect={handleProviderSelect} getDoctorName={getDoctorName}/>

                                        <Grid item md={12} sm={12} style={{'margin-top': "15px"}}><Divider className={classes.divider} /></Grid>
                                        <CallInNotification state={state} classes={classes} setState={setState}
                                        handleChange={handleChange} notifications={notifications} handleNotificationChange={handleNotificationChange} />

                                        <Grid item><Divider className={classes.divider} /></Grid>
                                        <SMSVoiceMessage style={{marginRight:'5%'}} state={state} classes={classes} setState={setState}
                                        handleChange={handleChange} fileUploadButton={fileUploadButton} fileRetrieveFromAWS={fileRetrieveFromAWS} DeleteUploadedFileDB={DeleteUploadedFileDB} maxLength={maxLength}/>
                                        
                                    </Grid>
                                    
                                    <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}}/>

                                    <Grid item md={6} xs={12}>

                                        <PatientVerification classes={classes} 
                                        verification={verification} handleVerificationChange={handleVerificationChange} isEditPressed={state.isEditPressed}/>

                                        <Grid item><Divider className={classes.divider} /></Grid>
                                        <PatientUpdateInfo classes={classes} updateInformation={updateInformation} handleUpdateInformationChange={handleUpdateInformationChange} isEditPressed={state.isEditPressed}/>

                                        <Grid item><Divider className={classes.divider} /></Grid>
                                        <CheckInForms state={state} classes={classes} setState={setState}
                                        handleChange={handleChange} fileUploadButton={fileUploadButton} fileRetrieveFromAWS={fileRetrieveFromAWS} DeleteUploadedFileDB={DeleteUploadedFileDB} maxLength={maxLength}/>
                                    
                                    </Grid>

                                </Grid>

                                <Grid item md={12} sm={12} xs={12} style={{marginTop: "20px"}}><Divider className={classes.divider} /></Grid>

                                <Grid item md={12} sm={12} style={{paddingLeft: '20px'}}>
                                    <StatusCode                                 
                                    statusesNewConfig={statusesNewConfig} 
                                    setStatusesNewConfig={setStatusesNewConfig}
                                    statuses={statuses}
                                    />
                                </Grid>

                                <div style={{'marginTop': "25px"}}/>

                            </Grid>
                        </Box>
                        <Grid item md={12} sm={12}><Divider className={classes.divider} /></Grid>

                        {state.isEditPressed ?
                            (<Grid item md={12} sm={12} >
                                <Box item md={12} sm={12} className={clsx(classes.labelWithEdit, classes.labelWithEditBottomSpacing)}>
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        {state.isLoading ? 
                                            (<CircularProgress className={classes.loadingCircle} />) : 
                                            <Button md={6} sm={12} xs={12} className={clsx(classes.saveChangesBtn, classes.editToggleBtn, classes.saveChangeCheckinBtn)} 
                                                variant="outlined" color={state.btnColor} onClick={saveSettings}>Save Changes 
                                            </Button>
                                        }
                                    </Box>
                                        <Button md={6} sm={12} xs={12} className={clsx(classes.editToggleBtn, classes.saveChangeCheckinBtn)} 
                                            variant="outlined" color={state.btnColor} onClick={() => loadOldState()}>Cancel
                                        </Button>
                                </Box>
                            </Grid>)
                        :
                            (<Grid item md={12} sm={12}>
                                <Box item md={12} sm={12} className={clsx(classes.labelWithEdit, classes.labelWithEditBottomSpacing)}>
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        <Button md={5} sm={12} className={classes.editToggleBtn} 
                                            variant="outlined" color={state.btnColor} onClick={() => saveOldState()}>Edit
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>)
                        }
                    </Collapse>
                </Grid>
            </Grid>

            <Dialog
                open={state.isProviderListOpen}
                onClose={() => setState({ ...state, isProviderListOpen: false})}
                maxWidth='xl'
            >
                <DialogTitle>{"Check-In System Provider List"}</DialogTitle>
                <DialogContent>
                    <DialogContent>
                        {doctors && providerFilter && Object.keys(doctors).length !== 0 && doctors.constructor !== Object && doctors.map((post, i) => (
                            <Grid container item key={i}>
                                <FormControlLabel
                                    control={<Checkbox color='primary' defaultChecked={providerFilter[post.providerNo]?false:true} onChange={handleProviderSelectPopUp} name={String(post.providerNo)}/>}
                                    label={<Typography className={classes.typography}>{getDoctorName(post.providerNo)}</Typography>}
                                />
                            </Grid>
                        ))}
                    </DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setState({ ...state, isProviderListOpen: false})} color="primary">
                        CANCEL
                    </Button>

                    <Button onClick={() => {setProviderFilter({...providerFilter, ...providerFilterPopUp}); setState({ ...state, isProviderListOpen: false})}} color="primary">
                        SAVE
                    </Button>

                </DialogActions>
            </Dialog>
        </Paper>
    );
}else return(<div></div>)
}

export default RoomURL;
