import { Card, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ReportFooter from "../../frame/GetReportFooter";
import { useParams } from "react-router-dom";
import GetPatientReportForm from "./GetPatientReportForm";
import PatientReportHeader from "../../frame/PatientReportHeader";
import _ from "lodash";
import GlobalContext from "../../context/GlobalContext";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: "#F5F6FA",
    minHeight: "100vh",
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },
  pageContent: {
    flex: 1,
  },
  cardWrapper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "85%",
    },
  },
}));

const GetPatientReports = () => {
  const global = useContext(GlobalContext);
  const { orgName, orgId, hashedId, sessionId } = useParams();
  const [logo, setLogo] = useState(
    "https://porton-shared.s3.ca-central-1.amazonaws.com/images/Porton_New_Logo.png"
  );

  const [orgInfo, setOrgInfo] = useState({
    address: "",
    contactNum: "",
    email: "",
  });

  const getOrgDetails = async (orgId) => {
    try {
      let result = await global.api.getOrgDetails(orgId);
      console.log("result", result);

      if (result.status === 200) {
        const orgLogo = _.get(
          result,
          "data.logo",
          "https://porton-shared.s3.ca-central-1.amazonaws.com/images/Porton_New_Logo.png"
        );
        if (orgLogo) {
          setLogo(orgLogo);
        }

        const address = _.get(result, "data.address", "");
        const contactNum = _.get(result, "data.contactNum", "");
        const email = _.get(result, "data.email", "");
        setOrgInfo({ address, contactNum, email });
      }
    } catch (error) {
      console.error("Error retrieving files:", error);
      // Handle error, show error message, or fallback behavior
    }
  };

  useEffect(() => {
    const fetchOrgDetails = async () => {
      await getOrgDetails(orgId);
    };
    fetchOrgDetails();
  }, []);
  const classes = useStyles();
  return (
    <div className={classes.page}>
      <div className={classes.pageContent}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={8} className={classes.cardWrapper}>
            <PatientReportHeader
              logo={logo}
              address={orgInfo.address}
              contactNum={orgInfo.contactNum}
              email={orgInfo.email}
            />
            <Card elevation={2}>
              <GetPatientReportForm
                orgName={orgName}
                orgId={orgId}
                hashedId={hashedId}
                sessionId={sessionId}
                logo={logo}
              />
            </Card>
          </Grid>
          <ReportFooter
            address={orgInfo.address}
            contactNum={orgInfo.contactNum}
            email={orgInfo.email}
          />
        </Grid>
      </div>
    </div>
  );
};

export default GetPatientReports;
