/*
 * @Author: Vincent Tran
 * @Date: 2020-07-16 01:06:00
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-10-01 07:03:51
 * @Description: file content
 */
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import GlobalContext from "../../../../../context/GlobalContext";
import combineStyles from '../../../../../styles/combineStyles';
import popupStyles from '../../../../../styles/PopupStyle.js';
import settingsStyles from '../../../../../styles/settingsPageStyles';

// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, settingsStyles);
let oldBlockCode = ""
function App(props) {
    const global = React.useContext(GlobalContext)
    const classes = combinedStyles();
    
    const state = props.state;
    const setState = props.setState;
    
    const editStatusCode = () => {
        setState({...state, editStatus:true, tempCodes: state.appointmentBlockCode})
        oldBlockCode = state.appointmentBlockCode;
    }
    const removeCode = (i) =>{
        setState({...state, appointmentBlockCode: [
            ...state.appointmentBlockCode.slice(0,i),
            ...state.appointmentBlockCode.slice(i+1)
         ]})
        
    }

    const getColour = (key) => {
        if(state.statuses){
            const statusForColour = state.statuses.find(status => (status.status === key.trim()))
            if(statusForColour){
                return statusForColour.color
            }
            return "#C495CD"
        }
        return "#EFCF59"
    }
    
    
    const changeText = (event) => {

        let codes = event.target.value.split(",")
        setState({...state, tempCodes: codes});
    }
    const cancelEditStatus = () => {
        setState({...state, tempCodes:oldBlockCode, editStatus:false})
    }
    const submitChange = () =>{
        
        for(let i = 0; i < state.tempCodes.length; i++){
            if(state.tempCodes[i].trim().length > 1 || state.tempCodes[i].trim().length < 1){
                alert("Each Appointment Block Code must only be 1 letter, separated by commas")
                return
            }
            
        }
        setState({...state, appointmentBlockCode:state.tempCodes, editStatus:false})
        
    }
    React.useEffect(() => {
        setState({...state, tempCodes: state.appointmentBlockCode});
    }, [global.state.selectedOrg, global.state.defaultOrg])
    return (
        <>
            <Box xs={12} className={classes.paperComponment}>
                <Grid container xs={12} direction='row' justifyContent="space-between" alignItems='center'>
                    <Grid item xs={7}>
                        <Typography className={classes.labelSmall}>
                            Appointment Block Code Editor
                        </Typography>
                    </Grid>
                    <Grid container item xs={5} justifyContent='flex-end'>
                        <Button color="primary" onClick={editStatusCode} disabled={!state.isEdit} style={{'fontSize' : '12px'}}>
                            IMPORT&nbsp;STATUS&nbsp;CODE
                        </Button>
                    </Grid>
                </Grid>
                
                <Typography className={classes.labelSmall}>
                    Current Appointment Block Code:
                </Typography>
                <Grid className={classes.blockCodeGrid} container>
                    {state && state.appointmentBlockCode && state.appointmentBlockCode.map((code, i) => (
                        <Box key={i} className={classes.appointmentBlockCodeColor} bgcolor={getColour(code)}>
                        <Typography className={classes.labelBlock}>
                            {code}
                        </Typography>
                        <Box className={classes.cancelIconButtonColor} bgcolor={"#FFFFFF"}>
                            <IconButton aria-label="View" target = '_blank' onClick={() => {removeCode(i)}} className={classes.iconButtonSpacing} ><ClearIcon className={classes.iconButtonSize}/></IconButton>
                        
                        </Box> 
                    
                    </Box> 
                    ))}
                </Grid>
                
                <Typography  className={classes.statusText}>
                    BookApp will not allow new bookings into appointment slots with Status Codes that are designated as Block Codes. 
                </Typography>
                {state.editStatus && [
                    <Paper elevation={3} className={classes.smallContainer}>
                        <Box xs={12} className={classes.editUrlBox}>
                            <Typography className={classes.labelSmall}>Import Appointment Status Code</Typography>
                            <Typography  className={classes.statusText}>
                                Enter the status code letter, or enter multiple status code letters separated by commas (,).
                            </Typography>
                            <Typography className={classes.labelSmall}>
                                Status code format: A or A, B, C   
                                   
                            </Typography>
                            

                            <Typography variant="body2" className={classes.labelText}>Appointment Status Code</Typography>
                            <Box className={classes.subButton}>
                                <TextField
                                    className={classes.textBox}
                                    value={state.tempCodes}
                                    onChange={changeText}
                                    variant="outlined"
                                    placeholder="Appointment Status Code"
                                    size="small"
                                >

                                </TextField>
                            
                            </Box>
                            <Grid container justify={"space-between"}>
                                <Button color="primary" className={classes.subButton} onClick={cancelEditStatus} disabled={!state.isEdit}>
                                    <Typography variant="subtitle2">CANCEL</Typography>
                                </Button>
                                <Button color="primary" className={classes.subButton} onClick={submitChange} disabled={!state.isEdit}>
                                    <Typography variant="subtitle2">IMPORT STATUS CODE</Typography>
                                </Button>
                            </Grid>
                            
                        </Box>
                    </Paper>
                   
                ]}
            </Box>

        </>

    );
}

export default App;