/*
 * @Author: Vincent Tran
 * @Date: 2020-08-04 04:30:00
 * @LastEditors: Tyler Bowman
 * @LastEditTime: 2021-04-15 03:58:00
 * @Description: file content
 */

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment-timezone";
import React from "react";
import GlobalContext from "../../../../../context/GlobalContext";
import combineStyles from "../../../../../styles/combineStyles";
import popupStyles from "../../../../../styles/PopupStyle";
import UserPageStyles from "../../../../../styles/usersPageStyles";
import AppointmentRow from "./componentsAppointments/AppointmentRow";
import ContactBar from "./componentsAppointments/ContactBar";
import MiscRow from "./componentsAppointments/MiscRow";
import PatientSearch from "./componentsAppointments/PatientSearch";
import ProviderRow from "./componentsAppointments/ProviderRow";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, UserPageStyles);

export default function AddAppointment(props) {
  // temporary default values..
  // Real values should be pulled from database in the future
  const userInfo = {
    name: "",
    searchSelect: 0,
    patientSelected: true,
    provider: 0,
    email: "johndoe@email.com",
    phone: "+1 555 555 5555",
    payTo: 0,
    modesSelect: -1,
    reasonSelect: -1,
    inputValue: "",
    autoCompleteSelect: 0,
    patientList: [],
    comments: "",
    date: "",
    time: "",
    loading: false,
  };
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({ ...userInfo });
  const info = props.info;
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };
  const handleChange = (e) => {
    if (e.target.name === "provider") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        modesSelect: -1,
        reasonSelect: -1,
      });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  const classes = combinedStyles();
  const global = React.useContext(GlobalContext);

  let org = {};
  if (
    global.state.selectedOrg &&
    Object.keys(global.state.selectedOrg).length !== 0
  ) {
    org = global.state.selectedOrg;
  } else if (
    global.state.defaultOrg &&
    Object.keys(global.state.defaultOrg).length !== 0
  ) {
    org = global.state.defaultOrg;
  }

  const handleSave = async () => {
    // save user profile info to database here...

    if (
      state.autoCompleteSelect.patientId === "" ||
      state.userList[state.provider].doctorId === "" ||
      org._id === "" ||
      state.time === "" ||
      state.date === "" ||
      state.email === "" ||
      state.modesSelect === -1 ||
      state.reasonSelect === -1
    ) {
      alert("none of the fields except comments can be empty");
      return;
    }

    if (
      state.time.substring(0, 2) < 0 ||
      state.time.substring(0, 2) > 23 ||
      state.time.substring(3, 5) > 59 ||
      state.time.substring(3, 5) < 0
    ) {
      alert("invalid Time");
      return;
    }
    setState({ ...state, loading: true });

    let date;
    let offset;
    if (org.location) {
      let time = moment().tz(org.location).format("Z");
      date = state.date + "T" + state.time + ":00" + time;
      let tempDate = new Date().getTime();
      offset = moment.tz.zone(org.location).utcOffset(tempDate) / 60;
      offset = offset * -1;
    } else {
      offset = new Date().getTimezoneOffset();
      date = state.date + "T" + state.time + ":00-0" + offset + ":00";
      offset = offset * -1;
    }

    let response = await global.api.createAppointment(
      state.autoCompleteSelect.patientId,
      state.userList[state.provider].doctorId,
      org._id,
      date,
      date,
      15,
      state.email,
      state.phone,
      state.comments,
      state.userList[state.provider].modes[state.modesSelect].name,
      state.userList[state.provider].reasons[state.reasonSelect],
      false,
      offset
    );

    if (response && response.status !== 200) {
      alert(
        "an error has occured during appointment booking, please make sure that the booking time slot is not already in use"
      );
      setState({ ...state, loading: false });
      return;
    }
    alert("Completed");
    setState({ ...state, loading: false });
    handleClose();
  };

  const handlePatientChange = (value) => {
    setState({
      ...state,
      autoCompleteSelect: value,
      email: value.email,
      phone: value.phone,
    });
  };
  const pullUsers = async () => {
    let response;
    let searchQuery = undefined;
    if (info.type === "providers") {
      response = await global.api.searchUsersByOrg(
        org._id,
        "patient",
        searchQuery,
        -1,
        1
      );
    } else if (info.type === "patients") {
      response = await global.api.searchUsersByOrg(
        org._id,
        "doctor",
        searchQuery,
        -1,
        1
      );
    }

    let arr = [];

    for (let i = 0; i < response.data.data.length; i++) {
      if (info.type === "providers") {
        let name = response.data.data[i].contact_name
          ? response.data.data[i].contact_name
          : response.data.data[i].firstname +
            " " +
            response.data.data[i].lastname;
        let phone = response.data.data[i].phone
          ? response.data.data[i].phone
          : "";
        let email = response.data.data[i].email
          ? response.data.data[i].email
          : response.data.data[i].emails &&
            response.data.data[i].emails !== null &&
            response.data.data[i].emails.length !== 0
          ? response.data.data[i].emails[0].address
            ? response.data.data[i].emails[0].address
            : response.data.data[i].emails[0]
          : "";

        if (
          phone.includes("(") ||
          phone.includes("(") ||
          phone.includes(" ") ||
          phone.includes("-")
        ) {
          phone = phone.replace(/\(/g, "");
          phone = phone.replace(/\)/g, "");
          phone = phone.replace(/\-/g, "");
          phone = phone.replace(/ /g, "");
        }
        arr.push({
          id: i,
          name: name,
          phone: phone,
          patientId: response.data.data[i]._id,
          email: email,
        });
      } else if (info.type === "patients") {
        let modesObject;
        if (
          response.data.data[i].modes &&
          response.data.data[i].modes.length !== 0
        ) {
          modesObject = await global.api.translateAppintmentModeArray(
            response.data.data[i].modes
          );
        }

        let name = response.data.data[i].contact_name
          ? response.data.data[i].contact_name
          : response.data.data[i].firstname +
            " " +
            response.data.data[i].lastname;
        if (modesObject) {
          arr.push({
            id: i,
            name: name,
            doctorId: response.data.data[i]._id,
            reasons: response.data.data[i].org_services,
            modes: modesObject.data,
          });
        } else {
          arr.push({
            id: i,
            name: name,
            doctorId: response.data.data[i]._id,
            modes: [],
            reasons: response.data.data[i].org_services,
          });
        }
      }
    }

    if (info.type === "providers") {
      let modesObject = await global.api.translateAppintmentModeArray(
        info.account.modes
      );
      let arr2 = [
        {
          id: 0,
          name: info.account.contact_name,
          doctorId: info.account._id,
          reasons: info.account.org_services,
          modes: modesObject.data,
        },
      ];
      setState({
        ...state,
        userList: arr2,
        patientList: arr,
        selected: "email",
        patientSelected: false,
        email: "",
        phone: "",
        provider: 0,
      });
    } else if (info.type === "patients") {
      let email = info.account.email
        ? info.account.email
        : info.account.emails !== null
        ? info.account.emails[0].address
          ? info.account.emails[0].address
          : info.account.emails[0]
        : "";
      let arr2 = [
        {
          id: 0,
          name: info.account.contact_name,
          patientId: info.account._id,
          phone: info.account.phone,
          email: email,
        },
      ];
      setState({
        ...state,
        userList: arr,
        patientList: arr2,
        selected: "email",
        email: email,
        phone: info.account.phone ? info.account.phone : "",
        autoCompleteSelect: arr2[0],
      });
    }
  };
  React.useEffect(() => {
    if(open) {
      pullUsers().then(() => console.log("PULL USERS DONE"));
    }
  }, [open]);
  return (
    <div>
      <ButtonBase className={props.className} onClick={handleClickOpen}>
        {" "}
        <Typography className={classes.typography}>Add Appointment</Typography>
      </ButtonBase>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="alert-dialog-slide-title"
        >
          Add Appointment
          <IconButton
            onClick={handleClose}
            className={classes.dialogClose}
            children={<ClearIcon />}
          />{" "}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container>
            <PatientSearch
              classes={classes}
              state={state}
              setState={setState}
              handlePatientChange={handlePatientChange}
              handleChange={handleChange}
            />

            <Grid className={classes.notificationRowSpacing} item xs={12}>
              <ContactBar
                classes={classes}
                state={state}
                setState={setState}
                handleChange={handleChange}
              />
            </Grid>

            <ProviderRow
              classes={classes}
              state={state}
              setState={setState}
              handleChange={handleChange}
            />

            <AppointmentRow
              classes={classes}
              state={state}
              setState={setState}
              handleChange={handleChange}
            />

            <MiscRow
              classes={classes}
              state={state}
              setState={setState}
              handleChange={handleChange}
            />
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.actionBtn}
          >
            Cancel
          </Button>
          {!state.loading ? (
            <Button
              onClick={handleSave}
              color="primary"
              className={classes.actionBtn}
            >
              ADD
            </Button>
          ) : (
            <Button disabled color="primary" className={classes.actionBtn}>
              Loading
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
