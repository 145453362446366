/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-23 02:03:32
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-07-24 02:56:10
 * @Description: file content
 */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import match from "autosuggest-highlight/match";
//Highligt
import parse from "autosuggest-highlight/parse";
import React from "react";
//useContext
import GlobalContext from "../../context/GlobalContext";

const useStyles = makeStyles((theme) => ({
    head: {
        display: "flex",
    },
    title: {
        paddingTop: "0rem",
        fontWeight: 500,
        display: "block",
        marginRight: "2rem",
        fontSize: "3rem",
        color: "red",
    },
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: "6px 10px 0 10px",
    },
    invitePopOver: {
        marginTop: "1rem",
    },
    autoComplete: {
        border: "none",
    },
    listBox: {
        marginTop: "1rem",
    },
}));

function App() {
    const classes = useStyles();
    const [result, setResult] = React.useState([
        {
            _id: "3CL9gabscpeD5qtEp",
            org_name: "Network Error...",
            address: "loading...",
            info: "testing team",
        },
    ]);
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [valueForDialog, setValueForDialog] = React.useState();
    const global = React.useContext(GlobalContext);
    //clear autocomplete content when the selected org has been changed somewhere else

    React.useEffect(() => {
        if (global.state.selectedOrg == null) {
            setValue(null);
            setInputValue("");
        }
    }, [global.state.selectedOrg]);

    const setSelectOrg = (object) => {
        global.setState((prev) => {
            return { ...prev, selectedOrg: object };
        });
    };
    const handleValueChange = (event, newValue) => {
        setValueForDialog(newValue);
        if (newValue) setOpenDialog(true);
    };

    const changeOrg = () => {
        setSelectOrg(valueForDialog);
        setValue(valueForDialog);
        setOpenDialog(false);
    };
    const handleInputValueChange = async (event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.length > 0) {
            let options = await global.api.searchOrg(newInputValue);
            if (options.status == 200) {
                setResult(options.data);
            }
        }
    };

    return (
        <Box>
            <Paper elevation={3} className={classes.root}>
                <Box className={classes.iconButton} aria-label="search">
                    <SearchIcon />
                </Box>
                <Autocomplete
                    id="custom-input-demo"
                    options={inputValue.length == 0 ? [] : result}
                    className={classes.input}
                    classes={{ paper: classes.listBox }}
                    value={value}
                    onChange={handleValueChange}
                    inputValue={inputValue}
                    onInputChange={handleInputValueChange}
                    autoHighlight
                    noOptionsText={
                        inputValue.length == 0
                            ? "Oops your search bar is empty.."
                            : "Sorry No Results"
                    }
                    getOptionLabel={(option) => option.org_name}
                    ListboxComponent={(listboxProps) => (
                        <ul {...listboxProps} />
                    )}
                    renderInput={(params) => (
                        <InputBase
                            fullWidth
                            placeholder="SEARCH FOR AN ORGANIZATION"
                            className={classes.input}
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            style={{ color: "#003FBA", fontSize: "18px" }}
                        />
                    )}
                    renderOption={(option) => {
                        const nameMatches = match(option.org_name, inputValue);
                        const nameParts = parse(option.org_name, nameMatches);
                        const addMatches = match(option.address, inputValue);
                        const addParts = parse(option.address, addMatches);
                        return (
                            <React.Fragment>
                                <Box className={classes.listItem}>
                                    <Typography color="primary">
                                        {nameParts.map((p, i) => {
                                            return (
                                                <span
                                                    key={i}
                                                    style={{
                                                        fontWeight: p.highlight
                                                            ? 700
                                                            : 400,
                                                    }}
                                                >
                                                    {p.text}
                                                </span>
                                            );
                                        })}
                                    </Typography>

                                    <Typography variant="body2" color="#767676">
                                        {addParts.map((p, i) => {
                                            return (
                                                <span
                                                    key={i}
                                                    style={{
                                                        fontWeight: p.highlight
                                                            ? 700
                                                            : 400,
                                                    }}
                                                >
                                                    {p.text}
                                                </span>
                                            );
                                        })}
                                    </Typography>
                                </Box>
                            </React.Fragment>
                        );
                    }}
                />
            </Paper>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
                style={{
                    margin: "auto",
                    width: "400px",
                }}
            >
                <DialogTitle
                    style={{
                        marginLeft: "10px",
                        marginTop: "5px",
                        marginBottom: "-20px",
                        color: "black",
                        fontSize: "40px",
                        fontWeight: 500,
                    }}
                >
                    Switch Organizations
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p style={{ color: "#343434", marginLeft: "10px" }}>
                            Are you sure you want to switch the <br></br>
                            current organization to{" "}
                            <strong>
                                {valueForDialog && valueForDialog.org_name}
                            </strong>
                            ? <br></br>
                            Any unsaved changes you have made will be lost.
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            margin: "auto",
                            marginRight: "50%",
                            marginBottom: "17px",
                            fontWeight: 600,
                        }}
                        onClick={() => setOpenDialog(false)}
                        color="primary"
                    >
                        CANCEL
                    </Button>
                    <Button
                        style={{
                            marginRight: "6px",
                            marginBottom: "17px",
                            fontWeight: 500,
                        }}
                        onClick={changeOrg}
                        color="primary"
                    >
                        YES
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default App;
