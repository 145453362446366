import {
    Box, Button,CircularProgress, Collapse,
    Divider, Grid,
IconButton, Paper, Typography
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import {ThemeProvider, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
//import ContactButton from './subComponents/ContactButton'
//import CalendarButton from "./subComponents/CalendarButton"
import Pagination from "@material-ui/lab/Pagination";
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from "../../../../context/GlobalContext";
import settingsStyles from '../../../../styles/settingsPageStyles';
import APIKey from './components/APIKey';
// import EmrIntegrationold from './components/eEmrIntegration';
import EmrIntegrationNext from './components/EMRIntegrationNext';
import spinerIcon from './components/img/spinner.gif';
import OnImportSuccess from './components/successImportProviders';


// Styles for child componenets
const theme = {
    labelSmall: {
        fontSize: "1rem",
        color: "#343434",
        fontWeight: 500,
        width: "100%",
        fontFamily: "Roboto",
        fontStyle: "normal",
        lineHeight: "19px",
        letterApacing: "0.15px",
        marginBottom: "15px",
    },
};

/**
 * @description: apply styles to a make a customized tablecell
 * @return: 
 */
const StyledTableCell = withStyles((theme) => ({
    head: {
        paddingTop: "2rem",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: "1rem",
        fontWeight: 600,
        borderBottom: "1px solid #BDBDBD",
        textAlign:"left"
    },
    body: {
        fontSize: "1rem",
        borderBottom: "none",
        fontWeight: 480,
        textAlign:"left"
    },


}))(TableCell);
/**
 * @description: apply styles to a make a customized tablerow
 * @return: 
 */
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },

    },
}))(TableRow);


//table end


function Integrations(props) {

    
    const [page, setPage] = React.useState(1);
    const [rows, setRows] = React.useState([])
    const [searchText, setSearchText] = React.useState("")

    const [expanded, setExpanded] = useState(false);
    
    const [expanded2, setExpanded2] = useState(false);
    
    const classes = settingsStyles()
    const global = useContext(GlobalContext)
    let org = global.state.selectedOrg != 'undefined' && global.state.selectedOrg ? global.state.selectedOrg : global.state.defaultOrg;
    // const user = global.state.user.document;
   
    
    const [userList, setUserList] = React.useState([])
    const [state, setState] = useState({ isEdit: false, isLoading: false })
    const [statuses, setStatuses] = useState();
    const [prevSelectedOrg, setPrevSelectedOrg] = useState(global.state.selectedOrg);
    
    
    const [maxPage, setMaxPage] = React.useState(1);
    const rowsPerPage=6
    const [providerList, setProviderList] = React.useState([]);
    const [isCheck, setIsCheck] = React.useState([])
    const [checkAll, setCheckAll] = React.useState(false); 
    const [uploadingStart,setUploadingStart] = useState(0);
    const [successOpen,setsuccessOpen] = useState(false);


    let defaultStatuses = {
        checked_in: 'H', //As default for new oscar org
        not_checked_in: 't',
        picked: '',
        noshow: '',
        cancel: '',
    }
    const [statusesNewConfig, setStatusesNewConfig] = useState(defaultStatuses);    
    const [newProviderAdded, setNewProviderAdded] = useState(false);
    const [editForStep2, setEditForStep2] = useState(false)
    const [isEmrIntegrationComplete, setIsEmrIntegrationComplete] = useState(false)
    
    
    useEffect(()=> {
        checkIfEmrIntegrationIsComplete();
    }, [isEmrIntegrationComplete])

    

    useEffect(() => {

        const fetchItems = async () => {

            if (typeof global.state.selectedOrg != 'undefined' && global.state.selectedOrg && global.state.selectedOrg != null  && JSON.stringify(global.state.selectedOrg) != "{}") {
                org = global.state.selectedOrg
                if(global.state.selectedOrg.statuses && typeof global.state.selectedOrg.statuses !='undefined'){
                    setStatusesNewConfig( global.state.selectedOrg.statuses)
                }else{
                    //to do: disable check-in settings for moa until he complete the translation table
                }
        
            } else { //default org
                org = global.state.defaultOrg
                if(typeof global.state.defaultOrg.statuses !='undefined' && global.state.defaultOrg.statuses ){
                    setStatusesNewConfig( global.state.defaultOrg.statuses)
                }else{
                    //to do: disable check-in settings for moa until he complete the translation table
                }
            }

            try {
                let statuses = await global.api.fetchStatuses(org._id)
                statuses && setStatuses(statuses.data.message)
            } catch (error) {

            }
            setState({ ...state, isLoading: false, isEdit: editForStep2})
        }
        
        fetchItems()

    }, [global.state.defaultOrg, global.state.selectedOrg])

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    const handleExpand2Click = () => {
        setExpanded2(!expanded2);
    }

    const expandImportProviderDrawer = () => {
        setExpanded2(true);

    }

    const handleEditForStep2 = () => {
        setEditForStep2(true)
    }
    const saveOldState = () => {
        setState({ ...state, isEdit: true })
    }

    const loadOldState = () => {
        setStatusesNewConfig(org.statuses ? org.statuses : defaultStatuses)
        setState({ ...state, isEdit: editForStep2});
    }

    useEffect(() => {

        // Integration state needs to be refreshed after org switch
        if(global.state.selectedOrg._id !== prevSelectedOrg._id){
            setExpanded(false);
            setExpanded2(false);
            setPrevSelectedOrg(global.state.selectedOrg);
            
            if(typeof org.statuses === 'undefined'){
                setStatusesNewConfig(defaultStatuses);
            }else {
                setStatusesNewConfig(org.statuses);
            }
        }

    }, [org]);

    const saveSettings = async () => {
        //save to porton db and then update global state
        if (!global.state.selectedOrg && !global.state.defaultOrg) {
            alert("No org!")
            return
        }

        setState({ ...state, isLoading: true })

        let result = await global.api.updateAppointmentStatuses(org._id, statusesNewConfig)
        if (result && result.status === 200) {
        if(JSON.stringify(global.state.selectedOrg) != "{}" && global.state.selectedOrg && typeof global.state.selectedOrg != 'undefined' ){
            org.statuses = statusesNewConfig
            global.setState((prev) => {
                return { ...prev, selectedOrg: org }
            })
        }else { //default org
            org.statuses = statusesNewConfig
            global.setState((prev) => {
                return { ...prev, defaultOrg: org }
            })
        }
        }
        setState({ ...state, isLoading: false, isEdit: editForStep2 })
    }

    useEffect(() => {
        if(typeof props.location.state !== 'undefined') {
            setExpanded(true); 
            setExpanded2(true);
        }
    }, []);
    
    const paperClassNames = clsx(classes.container,{
        [classes.disabled]: org.disabled
    })



    const loadUserData= async () => {
        let currentOrg
        if (!global.state) return
        if (!global.state || !global.state.selectedOrg || global.state.selectedOrg == null || JSON.stringify(global.state.selectedOrg) == "{}") {
            currentOrg = global.state.defaultOrg
        } else {
            currentOrg = global.state.selectedOrg
        }
        
        let response
        
        response = await global.api.searchImportedProvidersByOrg(currentOrg._id, "doctor", "", -1)
        setUserList([])
        if (response && (response.status == true || response.status == 200)) {
            if (response.data) {
                let data = response.data.data
                let formatedData = data.map((v, i) => {
                    
                    if (!v.contact_name) {
                        if(v.firstname&&v.lastname)  v.contact_name = v.firstname + " " + v.lastname
                        if(v.name) v.contact_name =v.name
                        if(!v.contact_name) v.contact_name="N/A"
                    }
                    if (!v.email && v.emails ) {
                      if(v.email===""){
                          v.email="N/A"
                      }else{
                        v.email = v.emails[0].address
                      }
                    } else if (!v.email ) {
                        v.email = "N/A"
                    } 
                    if (!v.phone){
                        v.phone = "N/A"
                        if(v.applicant) v.phone=v.applicant
                    } 

                    return v
                })
                setUserList(formatedData)
                setRows(formatedData)
                let max= Math.ceil(formatedData.length/6)
                setMaxPage(max)

            }
        }
    }

    //speed up refresh attempt only refresh user on the page you are on
    const refreshUsers = async ()=>{
        let currentOrg 
        if (!global.state) return
        if (!global.state || !global.state.selectedOrg || global.state.selectedOrg == null || JSON.stringify(global.state.selectedOrg) == "{}") {
            currentOrg = global.state.defaultOrg
        } else {
            currentOrg = global.state.selectedOrg

        }
        
        let response
     
        response = await global.api.searchImportedProvidersByOrg(currentOrg._id, "doctor", "", 6, page)
        let arr=[]
        if (response && (response.status == true || response.status == 200)) {
            if (response.data) {
                let data = response.data.data
                let formatedData = data.map((v, i) => {
                  
                    if (!v.contact_name) {
                        if(v.firstname&&v.lastname)  v.contact_name = v.firstname + " " + v.lastname
                        if(v.name) v.contact_name =v.name
                        if(!v.contact_name) v.contact_name="N/A"
                    }
                    if (!v.email && v.emails) {
                        v.email = v.emails[0].address
                    } else if (!v.email) {
                        v.email = "N/A"
                    }
                    if (!v.phone){
                        v.phone = "N/A"
                        if(v.applicant) v.phone=v.applicant
                   
                    } 
                    arr.push(v)
                    return v
                })
                const tempRow=[...rows]
                const PAGE_SIZE = 6
                for(let i=0; i<PAGE_SIZE; i++){
                    tempRow[((page-1)*PAGE_SIZE)+i]=formatedData[i]
                }
                setUserList(tempRow)
                setRows(tempRow)

            }
        }
    }
    

//search through the table
React.useEffect(()=>{
    let result=[]

    result=userList.filter(name => name && name.contact_name.toLowerCase().includes(searchText.toLowerCase()))
   
    setRows(result)
    let max=Math.ceil(result.length/6)
    setMaxPage(max)
    setPage(1)

}, [searchText])


React.useEffect(() => {
    setRows([])
   loadUserData()
}, [global.state.selectedOrg, global.state.defaultOrg, successOpen, newProviderAdded]) //removed searchText and page

/*React.useEffect(() => {
  
    setRows([])
  
    refreshUsers()


}, [page])*/


const handleCheck = (event, i) => {
  
    var updatedProviderList = [...providerList];
    if (event.target.checked) {
        updatedProviderList = [...providerList, event.target.value];
        
    } else {
        updatedProviderList.splice(providerList.indexOf(event.target.value), 1);
        setCheckAll(event.target.checked?true:false)
        
    }
 
    setProviderList(updatedProviderList);
    setIsCheck({...isCheck, [i]: event.target.checked?true:false})

}

const handleCheckAll = (event) => {
    setProviderList([]);
    
    let tempProviderList = []
    if (event.target.checked) {
        setCheckAll(true)
        rows.length > 0 && rows.map((row) => (
            row &&  (isCheck[row._id] = true)
          ))
        rows.length > 0 && rows.map((row) => (
          row &&  tempProviderList.push(row._id)
        ))
    } else {
        setCheckAll(false)
        rows.length > 0 && rows.map((row) => (
            row &&  (isCheck[row._id] = false)
          ))
  } 
  setProviderList(tempProviderList)
}
useEffect(()=> {
    
    var result = 0;

rows.length > 0 && rows.map((row) => (
    row &&  (isCheck[row._id] === true) ? result++ : 0
  ))
    if(result == rows.length  && result != 0 ){
        setCheckAll(true)
    }
},[isCheck])


const checkIfEmrIntegrationIsComplete = async () => {
      
    
        let currentOrg 
        if (!global.state) return
        if (!global.state || !global.state.selectedOrg || global.state.selectedOrg == null || JSON.stringify(global.state.selectedOrg) == "{}") {
            currentOrg = global.state.defaultOrg
        } else {
            currentOrg = global.state.selectedOrg
        }
        try{
            let res = await global.api.getIntegrationComplete(currentOrg._id);
                if(res.status === 200){
                      setIsEmrIntegrationComplete(res.data.integrationComplete);  
                     } } catch(error) {

                  }
      }

const sendEmail = async () => {
     setUploadingStart(1);
    let currentOrg 
    if (!global.state) return
    if (!global.state || !global.state.selectedOrg || global.state.selectedOrg == null || JSON.stringify(global.state.selectedOrg) == "{}") {
        currentOrg = global.state.defaultOrg
    } else {
        currentOrg = global.state.selectedOrg
    }
   
        for(var i =0; i < providerList.length; i++){
            
            let res = await global.api.getProviderDoctorByID(providerList[i],currentOrg._id)
            
            if (res && res.status === 200) {
                await global.api.sendProviderRegisterationEmail(res.data.data.data.emails[0].address, res.data.data.data.last_name, res.data.data.data.tempPassword)
                await global.api.removeTemporaryPassword(providerList[i],currentOrg._id)
                await global.api.turnOffSendRegistrationEmail(providerList[i],currentOrg._id)
            } 
        }
        setUploadingStart(2);
        setsuccessOpen(true);

        setCheckAll(false)
        rows.length > 0 && rows.map((row) => (
            row &&  (isCheck[row._id] = false)
          ))
        setProviderList([])
}

const closeSuccessPopup = () =>{
    setUploadingStart(0);
    setsuccessOpen(false);
}
const rootClassNames = clsx({
    [classes.rootExpanded]: expanded,
    [classes.root]: !expanded,
    [classes.disabled]: org.disabled
})

    return (
       
            <Grid container component={Paper} elevation={3} className={rootClassNames}>
                <Grid item xs={12} className={classes.paperComponment}>
                <Box onClick={handleExpandClick} className={classes.labelWithEdit, classes.labelWithEditDropdown}>
                    <Typography className={clsx(classes.label, {
	                        	[classes.expandedLabel]: expanded
	                        })}>Integrations</Typography>
                        <Typography className={classes.edit}>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                                disabled={org.disabled}

                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} >
                    <Collapse in={expanded || global.tourOpen} timeout="auto" unmountOnExit>
                        <Box className={!state.isEdit && classes.disabledBackground}>
     
                        <Grid item xs={12}>
                            <Grid container flex></Grid>
                                <Grid item xs={12} >

                                    <Grid item xs={12}>
                                        <Divider className={classes.divider} />
                                    </Grid>  
                                    {/* Integration must be setup before status code settings can become accessible
                                    Currently, emr setup is complete when there are 14 fields */}
                                    <APIKey/>
                                </Grid>
                                <Grid item xs={12} className={classes.dividerBorder}>
                                    <Grid item xs={12}>
                                        <Divider className={classes.divider} />
                                    </Grid>
                                    {global.state.user.document.roles.includes("admin") && (global.state.selectedOrg._id === global.state.defaultOrg._id || !global.state.selectedOrg._id) ? "" 
                                    :
                                    <ThemeProvider theme={theme}>
                                        {/* <EmrIntegration 
                                        setShowStatuses = { setShowStatuses }
                                        setStatusesNewConfig = { setStatusesNewConfig }
                                        defaultStatuses = { defaultStatuses }
                                        statuses = { Object.keys(global.state.selectedOrg).length !== 0 ? global.state.selectedOrg.statuses : global.state.defaultOrg.statuses }
                                        orgId = { global.state.selectedOrg._id ? global.state.selectedOrg._id : global.state.defaultOrg._id }
                                        role = {global.state.user.document.roles[0]}
                                        emr = { Object.keys(global.state.selectedOrg).length !== 0 ? global.state.selectedOrg.emr : global.state.defaultOrg.emr }
                                        importRightNow = {global.api.importRightNow}
                                        updateGlobalState = { global.setState }
                                        createNewEmrIntegration = { global.api.createNewEmrIntegration }
                                        deleteEmrIntegration = { global.api.deleteEmrIntegration }
                                        getEmrIntegrationRequestTokens = { global.api.getEmrIntegrationRequestTokens }
                                        updateEmrIntegrationAccessTokens = {global.api.updateEmrIntegrationAccessTokens}
                                        createSoapAuth = {global.api.createSoapAuth}
                                        prevUrl = { typeof props.location.state !== 'undefined' ?  props.location.state.prevUrl : ''}
                                        sendImportCompletedEmail={global.api.sendImportCompletedEmail}
                                        user={user}
                                        orgName={global.state.selectedOrg._id ? global.state.selectedOrg.org_name : global.state.defaultOrg.org_name}
                                        /> */}
                                        {/* New EmrIntegration */}
                                        <EmrIntegrationNext 
                                            prevUrl = { typeof props.location.state !== 'undefined' ?  props.location.state.prevUrl : ''}
                                            setNewProviderAdded={setNewProviderAdded} expandImportProviderDrawer={expandImportProviderDrawer}
                                            setProviderList={setProviderList}
                                            handleEditForStep2={handleEditForStep2}
                                        />
                                    </ThemeProvider>}
                                    
                                </Grid>     

                                <Grid item xs={12} >
                                    {!global.state.user.document.roles.includes("admin") ?
                                    <Grid item xs={12}>
                                        <Divider className={classes.divider} />
                                    </Grid>  
                                    :""}
                                    {/* Integration must be setup before status code settings can become accessible
                                    Currently, emr setup is complete when there are 14 fields */}
                                    
                                    {/*{showStatuses && 
                                        <StatusCode                                 
                                        statusesNewConfig={statusesNewConfig} 
                                        setStatusesNewConfig={setStatusesNewConfig}
                                        statuses={statuses}
                                        />   
                                    }*/}
                                </Grid>
                            
                        </Grid>
                       
                        <Grid item md={12} sm={12}>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} className={classes.paddingOscar}>
                        <Box paddingLeft={1} paddingRight={1}onClick={handleExpand2Click} className={ classes.labelWithEditDropdown}>
                        <Typography className={clsx(classes.labelSmall)} style={{paddingBottom: "0px"}}>Notify Newly Imported OSCAR Accounts</Typography>
                        <Typography className={classes.edit}>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded2,
                                })}
                                onClick={handleExpand2Click}
                                aria-expanded={expanded2}
                                aria-label="show more"
                                disabled={org.disabled}

                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Typography>
                        </Box>
                        </Grid>

                    <Collapse in={expanded2 || global.tourOpen} timeout="auto" unmountOnExit>
                        <Box paddingLeft={3}paddingRight={3} paddingTop={0} paddingBottom={0}className={!state.isEdit && classes.disabledBackground}>
                        
                        <Typography style={{color:'#767676', padding: "1rem 0rem 1rem 0rem", }}>Select which new Provider Accounts to notify.</Typography>
                        <Grid item md={12} sm={12}>
                        <Paper elevation={3} component="div" className={classes.root}>
                            <IconButton disabledtype="submit" className={classes.iconButton} aria-label="search">
                             <SearchIcon /> 
                            </IconButton>
                            <InputBase 
                                style = {{width: 500, paddingLeft:"10px"}}
                                placeholder="Search Providers"
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value) }}
                            />
                            {searchText && <Button className={classes.clearButton} onClick={()=>setSearchText("")} >CLEAR</Button>}
                            
                        </Paper>

                        <Box className={classes.container}>
                         <Grid container spacing={3}>
                     
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        
                                        <StyledTableCell>Name</StyledTableCell> 
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"right"}}><Typography style={{marginRight:"40px",marginBottom: "-20px" ,fontFamily: "Roboto", fontWeight:500 }}>Select All </Typography>
                                           
                        <input  type="checkbox"  style={{width:'15px',height:'15px' }}onChange={handleCheckAll} checked={checkAll}/>
                    </StyledTableCell>
 
                                    </TableRow>
                                    
                                </TableHead>
                               
                                <TableBody>
                                {(!isEmrIntegrationComplete) && <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                                
                                            </StyledTableCell>
                                            <StyledTableCell >
                                        <Typography style={{color:'#767676', padding: "1rem 1rem", paddingTop:"0px", textAlign:"center"}}>No EMR Connected.</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell ></StyledTableCell>
                                            </StyledTableRow>}
                                {rows.length == 0 && <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                                
                                            </StyledTableCell>
                                            <StyledTableCell >
                                        <Typography style={{color:'#767676', padding: "1rem 1rem", paddingTop:"0px", textAlign:"center"}}>No new accounts to be notified.<br/>
                                            Check back later or click Import Now for quick import.</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell ></StyledTableCell>
                                            </StyledTableRow>}
                                  { uploadingStart === 1 && 
                                    ( <StyledTableRow>
                                        <StyledTableCell component="th" scope="row"></StyledTableCell> 
                                        <StyledTableCell >
                                        <p className={classes.spinnerLoader} style={{position:"absolute", zIndex: "9", margin:"350", marginTop:"35"
                                  }}>
                                 <img src={spinerIcon} alt="spiner"/>
                                 </p> </StyledTableCell>  <StyledTableCell ></StyledTableCell>
                                            </StyledTableRow>)
                                    }
                                    { uploadingStart === 2 && 
                                    (<OnImportSuccess 
                                        open = {successOpen}
                                         onClose = {closeSuccessPopup}
                                    />)
                                     } 
                                    {rows.length > 0 && isEmrIntegrationComplete && rows.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage).map((row) => (
                                        row && (<StyledTableRow key={row._id}>
                                    
                                            <StyledTableCell component="th" scope="row">
                                                {row.contact_name}
                                            </StyledTableCell>
                                       
                                            <StyledTableCell >{!row.accountDisabled ? (row.email ? row.email : "N/A") :"" } 
                                            </StyledTableCell>
                                            
                                            
                                            <StyledTableCell style={{textAlign:"right" }} >
                                                
                                           <input style={{width:'15px',height:'15px' }}value={row._id} type="checkbox" onChange={(e)=>handleCheck(e, row._id)} checked={isCheck[row._id]}/> 
                                          </StyledTableCell>
                             </StyledTableRow>)           
                                    ))}
                                   
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid container
                        flex
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        item xs={12} md={12}>
                    
                        <Grid spacing={0} justify="center" item xs={12} md={12}>
                       <Pagination count={maxPage} page={page} onChange={handleChangePage}/>
                        
                        </Grid> 
                    </Grid>     
                </Grid>
                </Box >
                    </Grid>
                    </Box>
                    </Collapse>
                            <Grid item md={12} sm={12}>
                            <Divider className={classes.divider} style={{marginTop: "13px"}}/>
                        </Grid>
                        </Box>

                        <Grid item md={12} sm={12} >
                            <Box className={clsx(classes.labelWithEditBottomSpacing, classes.labelWithEdit)}>
                                {state.isEdit ?
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        {state.isLoading ?
                                            <CircularProgress className={classes.loadingCircle} />
                                            :
                                            <Box md={5} sm={12}>
                                                <Button md={6} sm={12} className={classes.editToggleBtn} variant="outlined" color='primary' onClick={() => loadOldState()}>
                                                    Cancel
                                                </Button>
                                                <Button md={6} sm={12} disabled={providerList.length > 0 ? false : true} className={clsx(classes.saveChangesBtn, classes.editToggleBtn)} variant="outlined" color='primary' onClick={sendEmail}>
                                                    Save Changes
                                                </Button>
                                            </Box>
                                        }
                                    </Box>
                                    :
                                    <Box className={clsx(classes.labelWithEdit, classes.labelWithEditWidth)}>
                                        <div md={7} sm={0}></div>
                                        <Button md={5} sm={12} className={classes.editToggleBtn} variant="outlined" color='primary' onClick={() => saveOldState()}>
                                            Edit
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>
        
    );
}

export default Integrations;