import React from "react";
import {
    MenuItem,
    DialogContent,
    DialogActions,
    Dialog,
    DialogTitle,
    Button,
    makeStyles,
    DialogContentText,
} from "@material-ui/core";
import { useState } from "react";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { ChromePicker } from "react-color";
import { useEffect } from "react";

const useStyles = makeStyles({
    dialog: {
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        color: "black",
        bottom: "0px",
        borderRadius: "8px",
    },
    dialogActions: {
        display: "flex",
        justifyContent: "space-between",
        margin: 11,
    },
    muiListItemButton: {
        "&:hover": {
            backgroundColor: "#D3E2FF",
        },
    },
});

const EditOrgTheme = ({
    org,
    onClose = () => {},
    onMenuClick = () => {},
    onSaveComplete = () => {},
}) => {
    const classes = useStyles();
    const global = useContext(GlobalContext);

    const [isEditThemeOpen, setIsEditThemeOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState(
        org.themeColor || "#ff0000"
    );

    const handleOnMenuClick = () => {
        onMenuClick();
        setIsEditThemeOpen(true);
    };

    const handleColorChange = async (color) => {
        setSelectedColor(color.hex);
    };

    const saveThemeColor = async (evt) => {
        evt.preventDefault();

        const res = await global.api.updateOrgThemeColor(
            org._id,
            selectedColor
        );

        if (res.status === 200) {
            setIsEditThemeOpen(false);
            onSaveComplete();
        }
    };

    useEffect(() => {
        setSelectedColor(org.themeColor || "#0000ff");
    }, [org]);

    return (
        <>
            <MenuItem
                onClick={handleOnMenuClick}
                className={classes.muiListItemButton}
                
            >
                Edit Theme
            </MenuItem>

            <Dialog
                className={classes.dialog}
                open={isEditThemeOpen}
                onClose={onClose}
            >
                <DialogTitle>
                    <p style={{
                            fontSize: "24px",
                            fontFamily:"Roboto",
                            color: "#222222",
                            marginTop: "15px",
                            marginBottom: "5px"
                        }}> Change Theme </p>
                    <DialogContentText
                        style={{
                            fontSize: "15px",
                            fontWeight: "300",
                            fontFamily: "Roboto",
                            marginBottom: "-5px"
                        }}
                    >
                        Change your clinic’s theme colour
                    </DialogContentText>
                </DialogTitle>
                <DialogContent style={{ padding: "24px" }}>
                    <ChromePicker
                        color={selectedColor}
                        onChangeComplete={handleColorChange}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        color="primary"
                        onClick={() => setIsEditThemeOpen(false)}
                         style={{fontWeight: "bold" }}
                         >
                        CANCEL
                    </Button>
                    <Button 
                        color="primary" 
                        onClick={saveThemeColor}
                        style={{fontWeight: "bold" }}
                        >
                        SAVE
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditOrgTheme;
