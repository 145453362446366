/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-11 00:48:34
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-07-14 06:02:10
 * @Description: file content
 */
import { TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import EmailIcon from "@material-ui/icons/Email";
import MicIcon from "@material-ui/icons/Mic";
import SmsIcon from "@material-ui/icons/Sms";
import React from "react";
import GlobalContext from "../../../../context/GlobalContext";
import combineStyles from "../../../../styles/combineStyles";
import popupStyles from "../../../../styles/PopupStyle";
import ProviderAutoComplete from "./ProviderAutoComplete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  buttonPaper: {
    width: "100%",
    height: "42px",
  },
  URL: {
    padding: "0 3rem 0 1.5rem",
    maxWidth: "20rem",
    color: "#767676",
    wordWrap: "break-word",
  },
  formLabel: {
    color: "#222222",
  },
  buttonInvites: {
    width: "100%",
    height: "100%",
    fontSize: "1rem",
    boxShadow: "none",
    paddingTop: "0rem",
    paddingBottom: "0rem",
    // height: "42px",
    color: "white",
    background:
      "radial-gradient(196.67% 240.67% at -54.22% -111.25%, #FFFFFF 0, #003FBA 100%)",
    alignItems: "left",

    "&:hover": {
      backgroundColor: "#eeeeee",
      // borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#dddddd",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
  inputBoxContainer: {
    marginTop: "1rem",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    width: "100%",
    "label + &": {
      marginTop: theme.spacing(0.4),
    },
  },
  input: {
    width: "100%",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #343434",
    fontSize: 16,

    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      //boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, useStyles);

export default function AddAppoinmentBtn() {
  const global = React.useContext(GlobalContext);
  const [open, setOpen] = React.useState(false);
  // const [URL, setURL] = React.useState("keefermedical");
  const [selectedOrgFullInfo, setSelectedOrgFullInfo] = React.useState();
  const [selectedDoctor, setSelectedDoctor] = React.useState([]);
  const [doctorAppointmentModes, setDoctorAppointmentModes] = React.useState(
    []
  );
  const handleClickOpen = () => {
    setOpen(true);
  };
  // const handleURLChange = (e) => {
  //     setURL(e.target.value);
  // }

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    // save kiosk info to database here..
    handleClose();
  };

  const loadClinicOptions = async () => {
    let currentOrg;
    if (
      !global.state.selectedOrg ||
      global.state.selectedOrg == null ||
      JSON.stringify(global.state.selectedOrg) == "{}"
    ) {
      currentOrg = global.state.defaultOrg;
    } else {
      currentOrg = global.state.selectedOrg;

      const result = await global.api.getOrgById(currentOrg._id);
      if (result && result.data?.org_services) {
        currentOrg = result.data;
      } else {
        // console.log("unknown error org full-info", result);
      }
    }

    setSelectedOrgFullInfo(currentOrg);
  };
  const loadDoctorModes = async () => {
    if (selectedDoctor.modes && selectedDoctor.modes.length >= 0) {
      const result = await global.api.translateAppintmentModeArray(
        selectedDoctor.modes
      );
      if (result.status == 200) {
        setDoctorAppointmentModes(result.data);
      }
    } else {
      // console.log("bad modes");
      // setDoctorAppointmentModes([])
    }
  };
  React.useEffect(() => {
    loadClinicOptions();
    // if (global.state.selectedOrg == null) {
    //     // setValue(null)
    //     // setInputValue("")
    // } else {

    // }
  }, [global.state.selectedOrg, global.state.defaultOrg]);
  React.useEffect(() => {
    if (selectedDoctor) {
      setDoctorAppointmentModes([]);
      loadDoctorModes();
    }
  }, [selectedDoctor]);
  const classes = combinedStyles();

  return (
    <div>
      <Paper elevation={3} className={classes.buttonPaper}>
        <Button
          className={classes.buttonInvites}
          onClick={handleClickOpen}
          variant="contained"
        >
          ADD APPOINTMENT
        </Button>
      </Paper>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <div>{selectedDoctor && selectedDoctor.contact_name ? selectedDoctor.contact_name : "no selected doctor"}</div> */}
        <DialogTitle
          className={classes.dialogTitle}
          id="alert-dialog-slide-title"
        >
          Add Appointment
          <IconButton
            onClick={handleClose}
            className={classes.dialogClose}
            children={<ClearIcon />}
          />{" "}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className={classes.inputBoxContainer}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Search Patient Using:
                </InputLabel>
                <Select
                  disabled
                  fullWidth
                  name="bType"
                  defaultValue={-1}
                  input={<BootstrapInput />}
                >
                  <option value={-1} hidden>
                    {" "}
                    Patient Name{" "}
                  </option>
                </Select>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.inputBoxContainer}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Patient
                </InputLabel>
                <BootstrapInput fullWidth id="bootstrap-input" />
              </Box>
            </Grid>
            <Grid item md={2} xs={4}>
              <Button
                style={{ fontSize: 14, paddingRight: "27px" }}
                className={classes.button3}
                size="small"
                variant="outlined"
                startIcon={
                  <EmailIcon style={{ fontSize: 20, marginLeft: 15 }} />
                }
              >
                EMAIL
              </Button>
            </Grid>

            <Grid item md={2} xs={4}>
              <Button
                style={{ fontSize: 14, paddingRight: "27px" }}
                className={classes.button3}
                size="small"
                variant="outlined"
                startIcon={<SmsIcon style={{ fontSize: 20, marginLeft: 15 }} />}
              >
                SMS
              </Button>
            </Grid>
            <Grid item md={2} xs={4}>
              <Button
                style={{ fontSize: 14, paddingRight: "27px" }}
                className={classes.button3}
                size="small"
                variant="outlined"
                startIcon={<MicIcon style={{ fontSize: 20, marginLeft: 15 }} />}
              >
                VOICE
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <BootstrapInput fullWidth id="bootstrap-input" />
            </Grid>
            <Grid item md={6} xs={6}>
              <Box>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Provider
                </InputLabel>
                <Select
                  defaultValue={-1}
                  disabled
                  fullWidth
                  name="bType"
                  input={<BootstrapInput />}
                >
                  <option value={-1} hidden>
                    {" "}
                    Provider
                  </option>
                  <ProviderAutoComplete
                    setSelectedDoctor={setSelectedDoctor}
                    selectedDoctor={selectedDoctor}
                  />
                </Select>
              </Box>
            </Grid>
            <Grid item md={6} xs={6}>
              <Box>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Payment To
                </InputLabel>
                <Select
                  disabled
                  fullWidth
                  name="bType"
                  defaultValue={-1}
                  input={<BootstrapInput />}
                >
                  <option value={-1} hidden>
                    {" "}
                    Select Provider
                  </option>
                </Select>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Appointment Type
                </InputLabel>
                <Select
                  disabled={
                    selectedDoctor
                      ? !doctorAppointmentModes ||
                        doctorAppointmentModes.length == 0
                        ? true
                        : false
                      : true
                  }
                  fullWidth
                  name="bType"
                  defaultValue={-1}
                  // onChange={(e) => handleChange(e)}
                  input={<BootstrapInput />}
                  // id="bType-input"
                >
                  <option value={-1} hidden>
                    {" "}
                    Select Type{" "}
                  </option>
                  {doctorAppointmentModes.map((v) => {
                    return <MenuItem key={v._id} value={v._id}>{v.name}</MenuItem>;
                  })}
                </Select>
                <FormHelperText>
                  {!selectedDoctor
                    ? " Please select a doctor first"
                    : !doctorAppointmentModes ||
                      doctorAppointmentModes.length == 0
                    ? ""
                    : ""}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Appointment Reason
                </InputLabel>
                <Select
                  disabled={
                    selectedDoctor
                      ? !doctorAppointmentModes ||
                        doctorAppointmentModes.length == 0
                        ? true
                        : false
                      : true
                  }
                  fullWidth
                  name="bType"
                  defaultValue={-1}
                  // onChange={(e) => handleChange(e)}
                  input={<BootstrapInput />}
                  // id="bType-input"
                >
                  <option value={-1} hidden>
                    {" "}
                    Select Reason{" "}
                  </option>
                  {selectedOrgFullInfo &&
                    selectedOrgFullInfo.services &&
                    selectedOrgFullInfo.services.map((v) => {
                      return <MenuItem key={v.name} value={v.name}>{v.name}</MenuItem>;
                    })}
                </Select>
                <FormHelperText>
                  {!selectedDoctor
                    ? " Please select a doctor first"
                    : selectedOrgFullInfo &&
                      selectedOrgFullInfo.services &&
                      selectedOrgFullInfo.services.length > 0
                    ? ""
                    : "No service for this clinic"}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Date
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  defaultValue="keefermedical"
                  type="date"
                  id="bootstrap-input"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Time
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  defaultValue="keefermedical"
                  type="time"
                  id="bootstrap-input"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid className={classes.inputBoxContainer} item md={17} xs={12}>
            <InputLabel
              className={classes.formLabel}
              shrink
              htmlFor="bootstrap-input"
            >
              Comments
            </InputLabel>
            <TextField rows={6} variant="outlined" fullWidth />
          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.actionBtn}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            className={classes.actionBtn}
          >
            ADD
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
