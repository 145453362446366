import {
  Box,Button, Grid,Hidden,IconButton, makeStyles, Paper, TextField, Typography} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// Dropdown
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
// Icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// Utilities
import clsx from 'clsx';
import React, { createRef, useEffect, useState } from 'react';
import settingsStyles from '../../../../../styles/settingsPageStyles';

const useStyles = makeStyles(() => ({
  inputFields: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  hoverColor: {
    '&:hover': {
      backgroundColor: "#D3E2FF"
    },
  },
  dropDown: {
    width:"190px",
    height: "48px"
  },
}));

export default function ServiceTypes(
  { 
    orgId, 
    role, 
    createAppointmentService, 
    updateAppointmentService, 
    updateGlobalState, 
    deleteAppointmentService, 
    services,
    revealAddServicesForm,
    setRevealAddServicesForm,
    updateProviderServices,
    serviceData,
    setServiceData,
    getDoctorsByOrgs,
    roles,
    isEdit
  }
  ) {

  const classes = settingsStyles();
  const componentClasses = useStyles();

  const [newForm, setNewForm] = useState(true);
  const [openDropdown, setOpenDropdown] = useState({});
  const [editService, setEditService] = useState(false);
  const [dropDownRefs, setDropDownRefs] = useState([]);
  const [indexToUpdate, setIndexToUpdate] = useState(null);

  useEffect(() => {

    if(services){
      setDropDownRefs(services.map(() => createRef()));
      services.forEach((service, i) => setOpenDropdown({...openDropdown, [i]: false }));

      let tempDropDownHandler = {};
      services.forEach((service, i) =>  tempDropDownHandler[i] = false)
      setOpenDropdown(tempDropDownHandler);
    }
    

  }, [services]);

  const handleChange = (e) => {
    setServiceData({...serviceData, [e.target.name]: e.target.value.trim()})
  };
  let settingsPermission = roles.includes('admin') || roles.includes('healthOrgAdmin')
  const handleCloseDropdown = (event, i) => {
    if (dropDownRefs[i].current && dropDownRefs[i].current.contains(event.target)) {
      return;
    }
    setOpenDropdown(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenDropdown(false);
    }
  };
  const updateServiceTypesGlobalState = (serviceList) => {
    updateGlobalState((prevState)=>{
      if(role === 'healthOrgAdmin' || role === 'oa'){
        return { ...prevState, defaultOrg: {...prevState.defaultOrg, services: serviceList }};
      }else {
        // The user is a Janitor
        if(Object.keys(prevState.selectedOrg).length) {
          return { ...prevState, selectedOrg: {...prevState.selectedOrg, services: serviceList }};
        }else {
          return { ...prevState, defaultOrg: {...prevState.defaultOrg, services: serviceList }};
        }
      }
    });
  };

  const createServiceData = async (e) => {
    e.preventDefault();
    // serviceDescription can be left blank
    if(serviceData.serviceName !== '' && typeof serviceData.serviceName !== 'undefined'){
      
      const result = await createAppointmentService(orgId, serviceData);
      if(result.status === 200) {
        updateServiceTypesGlobalState(result.data);
      }else {

      }
      setRevealAddServicesForm(false);
      setServiceData({serviceName: '', serviceDescription: ''});
    }else {
      setNewForm(false);
    }
  };

  const updateServiceData = async (e) => {
    e.preventDefault();
    // serviceDescription can be left blank
    if(serviceData.serviceName !== '' && typeof serviceData.serviceName !== 'undefined'){

      const payload = serviceData;
      payload.index =  indexToUpdate;

      const result = await updateAppointmentService(orgId, payload);
      if(result.status === 200) {
        updateDoctorServices(result.data);
        updateServiceTypesGlobalState(result.data);
      }else {

      }
      setRevealAddServicesForm(false);
      setServiceData({serviceName: '', serviceDescription: ''});
    }else {
      setNewForm(false);
    }
  };

  const deleteServiceData = async (service) => {
    const result = await deleteAppointmentService(orgId, services[service]);
    if(result.status === 200) {
      updateDoctorServices(result.data);
      updateServiceTypesGlobalState(result.data);
      setRevealAddServicesForm(false);
      setServiceData({serviceName: '', serviceDescription: ''});
    }else {

    }
  };

  //note: confusingly org_services for orgs is different from org_services in doctors
  //org_services in org refers to modes in orgs, while here it refers to appointment reasons
  //this is due to how modes in an org used to be called services, so now org uses the old convention still while doctors use the new convention...
  const updateDoctorServices = async (serviceList) => {
    let arr = [];
    let resultDoctors;
    try {
      resultDoctors = await getDoctorsByOrgs(orgId, 1, -1)
    } catch (error) {
      return;
    }    

    let len = resultDoctors.data.data.length;
    for (let i = 0; i < len; i++) {
      let servicesArr = resultDoctors.data.data[i].org_services;
      if (!servicesArr) {
        servicesArr = [];
      }
      
      //filters out services that are no longer in the services array from the services offered by the doctor
      let temp = servicesArr.filter(word => {
        for(let j = 0; j < serviceList.length; j++){
          if(serviceList[j].name === word){
            return true;
          }
        }
        return false;
      })
      
      
      arr.push({
        key: i,
        id: resultDoctors.data.data[i]._id,
        org_services: temp,
      });
    }
    
    for(let i = 0; i < arr.length; i++){
      await updateProviderServices(orgId, arr[i].id, arr[i].org_services)
    }
  }

  const handleDropdown = (i) => {
    setOpenDropdown({...openDropdown, [i]: !openDropdown[i]});
  };

  const loadDropdown = (i) => {
   
    if(typeof openDropdown[i] !== 'undefined') {
      return (
      
        <Popper open={openDropdown[i]} anchorEl={dropDownRefs[i].current} role={undefined} transition disablePortal placement='left'>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={e => handleCloseDropdown(e, i)}>
                  <MenuList autoFocusItem={openDropdown[i]} id="menu-list-grow-profile" onKeyDown={handleListKeyDown} disablePadding>
                    <MenuItem
                    className={clsx(componentClasses.hoverColor, componentClasses.dropDown)} 
                    onClick={(e) => { 
                      handleCloseDropdown(e, i);
                      setIndexToUpdate(i);
                      setServiceData({serviceName: services[i].name, serviceDescription: services[i].description});
                      setEditService(true);
                      setRevealAddServicesForm(true); 
                      }}>
                        EDIT
                    </MenuItem>
                    <MenuItem 
                      className={clsx(componentClasses.hoverColor, componentClasses.dropDown)} 
                      onClick={(e) => {handleCloseDropdown(e, i); deleteServiceData(i)}}
                    >
                      REMOVE
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )
    }

  };
  
  return (
    <Box p={2} className={classes.pepercomponent}>
      <Grid container xs={12}>
        <Grid item xs={11} >
        <Box className={classes.labelWithEdit}>
          <Typography className={classes.labelWithAdd}> Appointment Reason  </Typography>
          </Box>
         </Grid>
         <Grid item  xs={1}>
          {settingsPermission && [
            <Button onClick={() => setRevealAddServicesForm(true)} color="primary" className={classes.appointmentReasonAddButton}> ADD&nbsp;<Hidden mdDown>APPOINTMENT&nbsp; REASON </Hidden></Button>
         ] }  
            </Grid> 
            
       
      </Grid>
      <Grid  item xs={12}>
      {
        services && services.map((service, i) => (
        
          <Grid container key={i} style={{'marginLeft' : '5px'}}>
            <Grid item xs={11}>
                
                <Typography className={clsx(classes.appointmentReasonListName, classes.labelSmall)}> {service.name} </Typography>
                <Typography className={clsx(classes.appointmentReasonListDescription, classes.subLabel)}> {service.description} </Typography>
          
            </Grid>
            
            <Grid item md={1} xs={1}>
            {settingsPermission && 
              <Box >
                <IconButton 
                  className={clsx(classes.dropDownSelectSpacing, componentClasses.hoverColor)}                
                  ref={dropDownRefs[i]}
                  aria-controls={openDropdown[i] ? 'menu-list-grow-profile' : undefined}
                  aria-haspopup="true"
                  onClick={() => handleDropdown(i)}
                >
                  <MoreHorizIcon />
                </IconButton>
                { loadDropdown(i) }

                </Box>
            }
            </Grid>
          </Grid>
        ))

      }

      { revealAddServicesForm &&
        <Paper elevation={3} className={classes.appointmentReasonAddForm}>
          <Box p={2}>
            <form>
              <Grid container>
                <Grid item md={12} sm={12} className={classes.appointmentReasonFormTextSpacing}>
                  <Typography className={clsx(classes.appointmentReasonFormText, classes.labelSmall)} > 
                    {editService ? 'Edit Appointment Reason' : 'New Appointment Reason' }
                  </Typography>
                </Grid>

                <Grid item md={12} sm={12} className={classes.inputFields}>
                  <TextField
                    fullWidth
                    error={!serviceData.serviceName && !newForm}
                    helperText={!serviceData.serviceName && !newForm ? 'Field required' : null}
                    id="serviceName"
                    name="serviceName"
                    label="Service Name"
                    placeholder="Service Name"
                    variant="outlined"
                    margin="dense"
                    value={serviceData.serviceName}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item md={12} sm={12} className={classes.inputFields}>
                  <TextField
                    fullWidth
                    error={!serviceData.serviceDescription && !newForm}
                    helperText={!serviceData.serviceDescription && !newForm ? 'Field required' : null}
                    id="serviceDescription"
                    name="serviceDescription"
                    label="Service Description"
                    placeholder="Service Description"
                    variant="outlined"
                    margin="dense"
                    value={serviceData.serviceDescription}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container className={componentClasses.inputFields}>
              <Grid item md={6} sm={6}>
                <Button 
                  color="primary"
                  size="medium"
                  className={classes.appointmentReasonCancel}
                  onClick={ () => {
                    setServiceData({serviceName: '', serviceDescription: ''});
                    setRevealAddServicesForm(false);
                    setNewForm(true);
                    setEditService(false);
                  }} 
                >
                    CANCEL
                </Button>
              </Grid>
              <Grid item md={6} sm={6}>
                <Button 
                type="submit" 
                className={classes.appointmentReasonConfirm}
                color="primary"
                onClick={(e) => {
                  editService ? updateServiceData(e) : createServiceData(e);
                  setEditService(false);
                }}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
            </form>
          </Box>
        </Paper> 
      }
     </Grid>
    </Box>
    

  );
}