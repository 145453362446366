import {
  Box,

  Switch, Typography
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import settingsStyles from '../../../../../styles/settingsPageStyles';

export default function Bookings({ state, setState }) {

  const classes = settingsStyles();

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  
  return (
    <Box>
      <Box className={clsx(classes.labelWithEdit, classes.bookingBox)} >
        <Typography className={classes.labelSmall}> Allow Walk-In Patients </Typography>
        <Switch 
          size="small"
          checked={state.allowWalkIns}
          onChange={handleChange}
          color={!state.isEdit ? '#767676' : 'primary'}
          name="allowWalkIns"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Box>
  </Box>
  );
}