import { Box, Link, Typography } from "@material-ui/core";
import React from "react";
import HelpStyles from "../../../styles/HelpStyles";

const FAQ = () => {
    const classes = HelpStyles();

    return (
        <div className={classes.boxPadding}>
            <Box className={classes.spreadHeader}>
                <Typography className={classes.heading}>
                    Frequently Asked Questions
                </Typography>
                <Link
                    className={classes.button}
                    href="https://www.portonhealth.com/faq/"
                >
                    OPEN
                </Link>
            </Box>
            <Box className={classes.subHeadingTopMargin}>
                <Typography className={classes.subHeadingText}>
                    You will be redirected to Porton Health's FAQ that contains
                    answers and instructions related to new users' material and
                    different topics
                </Typography>
            </Box>
        </div>
    );
};

export default FAQ;
