import { Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import settingsStyles from "../../../../../styles/settingsPageStyles";

const SMTPSetting = ({ smtpSetting, setSmtpSetting }) => {
    const classes = settingsStyles();

    const handleChangeText = (event) => {
        setSmtpSetting({
            ...smtpSetting,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <Grid container className={classes.section}>
            <Grid item xs={12}>
                <Typography
                    style={{ fontWeight: 600 }}
                    className={classes.labelSmall}
                >
                    SMTP Settings
                </Typography>
                <Typography className={classes.labelMedSmall}>
                    Set up your SMTP Server to send, receive, and relay messages
                    using an email of your preference. This settings will be
                    applicable to use with CheckinApp, ReminderApp and
                    PatientApp.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelText}>
                    Username
                </Typography>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    onChange={handleChangeText}
                    variant="outlined"
                    value={smtpSetting.smtpuser || ""}
                    fullWidth
                    className={classes.textBox}
                    placeholder="Username"
                    name="smtpuser"
                    size="small"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelText}>
                    Password
                </Typography>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    onChange={handleChangeText}
                    variant="outlined"
                    value={smtpSetting.smtppassword || ""}
                    fullWidth
                    className={classes.textBox}
                    placeholder="Password"
                    name="smtppassword"
                    size="small"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelText}>
                    Server
                </Typography>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    onChange={handleChangeText}
                    variant="outlined"
                    value={smtpSetting.smtpserver || ""}
                    fullWidth
                    className={classes.textBox}
                    placeholder="Server"
                    name="smtpserver"
                    size="small"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelText}>
                    Port
                </Typography>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    onChange={handleChangeText}
                    variant="outlined"
                    value={smtpSetting.smtpport || ""}
                    fullWidth
                    className={classes.textBox}
                    placeholder="Port"
                    name="smtpport"
                    size="small"
                />
            </Grid>
        </Grid>
    );
};

export default SMTPSetting;
