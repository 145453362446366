import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import Papa from "papaparse/papaparse.min.js";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import UserPageStyles from "../../styles/usersPageStyles";
import { FormLabel, withStyles } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const style = {
  root: {
    "& label.Mui-focused": {
      color: "#003FBA",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#003FBA",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "green",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#003FBA",
      },
    },
  },
};
const CssTextField = withStyles(style)(TextField);
export default function SignUp({ open, onClose }) {
  const global = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    name: "",
    tabSelect: 0,
    currentUploadedFile: null,
  });


  const [error,setError] = useState({
    email: false,
    name: false,

  })

  const handleSignUp = async () => {
    setLoading(true);
    const result = await global.api.registerOrg(
        values.name,
        values.email,
    );
    if (result && result.status === 200) {

      alert(`Account created successfully`);

    } else if (result && result.status === 400) {
      alert(
          `Failed to create account: Email may already be in use by another user`
      );
    } else {
      alert(
          `Failed to create account: Another Org may already be using this name`
      );
    }

    setLoading(false);
    setValues({
      ...values,
      email: "",
      name: "",
      tabSelect: 0,
      currentUploadedFile: null,
    });
    onClose();
  };

  useEffect(() => {}, []);

  // const handleChange = (event, newValue) => {
  //   setValues({ ...values, tabSelect: newValue });
  // };

  const fileUploadButton = async (event) => {
    setValues({ ...values, currentUploadedFile: event.target.files[0] });
    event.target.value = null;
  };
  //Called by papaparse after it is done reading the file.
  //Result is an object with the csv file's information
  const dataRead = async (result) => {
    let temp = result.data;

    let returned;
    let errors = [];

    for (let i = 0; i < result.data.length; i++) {
      if (result.data[i].org_name !== "") {
        let password = "";
        for (let i = 0; i < 5; i++) {
          password += (((1 + Math.random()) * 0x10000) | 0)
              .toString(16)
              .substring(1);
        }
        temp[i].password = password;
      }
    }
    returned = await global.api.registerOrgGroup(temp);

    if (returned && returned.status === 200 && returned.data.data.data) {
      for (let i = 0; i < returned.data.data.data.length; i++) {
        if (
            returned.data.data.data[i] &&
            returned.data.data.data[i].status !== 200
        ) {
          errors.push(i + 2);
        } else if (returned.data.data.data[i]) {
          await global.api.sendRegisterationEmail(
              temp[i].email,
              temp[i].password
          );
        }
      }
    }
    if (errors.length === 0) {
      alert("Completed: No Errors");
    } else {
      alert(
          `Errors on lines: ${errors} Please check if formatting is correct, whether another org is using the same name or whether emails may already be in use. All other lines aside from those with errors have been registered successfully`
      );
    }

    setLoading(false);
    setValues({
      ...values,
      email: "",
      name: "",
      tabSelect: 0,
      currentUploadedFile: null,
    });
    onClose();
  };

  const handleFiles = async (files) => {
    let fileName = values.currentUploadedFile.name.toLowerCase();
    if (!fileName.endsWith(".csv")) {
      alert("Please upload a CSV file");
      return false;
    }
    setLoading(true);
    Papa.parse(values.currentUploadedFile, {
      complete: dataRead,
      header: true,
    });
  };

  const emailValidate = (email) => {
    if (email === "") {
      return false;
    }
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(email).toLowerCase());
  };

  const classes = UserPageStyles();
  return (
      <div>
        <Dialog open={open} onClose={onClose}>
          <DialogTitle className={classes.styledHeader}>
            New Organizations
            <IconButton
                style={{ padding: "0px" }}
                onClick={onClose}
                className={classes.dialogClose}
                children={<ClearIcon />}
            />
          </DialogTitle>

          <DialogContent>
            <form>
              <>
                <Typography
                    style={{ marginBottom: "24px" }}
                    className={classes.labelSmall}
                >
                  Input the organization's name and email
                </Typography>
                <CssTextField
                    id="name"
                    label="Organization’s Name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                    onBlur={(e) => {
                      setError({...error,name:values.name.length < 1})
                    }}
                    className={clsx(classes.marginSpacing, classes.upperSpacing)}
                    required
                    error={error['name']}


                />
                <CssTextField
                    id="email"
                    label="Organization’s Email"
                    type="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                    }
                    onBlur={(e) => {
                      setError({...error,email:!/^\S+@\S+\.\S+$/.test(values.email)})
                    }}
                    className={classes.marginSpacing}
                    required
                    //should validate email
                    error={error['email']}
                    helperText={
                      emailValidate(values.email) ? "Please Enter Valid Email" : ""

                    }
                />
                <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      padding: "10px 0px",
                    }}
                >
                  <FormLabel
                      style={{
                        color: "#003FBA",
                        fontSize: "0.9rem",
                        fontWeight: "500",
                      }}
                      onClick={onClose}
                  >
                    CANCEL
                  </FormLabel>

                  {loading ? (
                      <CircularProgress
                          style={{ margin: "5px", color: "#1766b3" }}
                          size={30}
                          className={classes.loadingCircle}
                      />
                  ) : (
                      <FormLabel
                          style={{
                            color: "#003FBA",
                            fontSize: "0.9rem",
                            fontWeight: "500",
                          }}
                          color="#003FBA"
                          onClick={handleSignUp}
                      >
                        SAVE
                      </FormLabel>
                  )}
                </div>
                {/*<h4*/}
                {/*  style={{*/}
                {/*    width: "100%",*/}
                {/*    color: "#767676",*/}
                {/*    textAlign: "center",*/}
                {/*    borderBottom: "1px solid #BDBDBD",*/}
                {/*    lineHeight: "0.0em",*/}
                {/*    margin: "20px 0 20px",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  {" "}*/}
                {/*  <span style={{ background: "#fff", padding: "0 10px" }}>*/}
                {/*    {" "}*/}
                {/*    or*/}
                {/*  </span>*/}
                {/*</h4>*/}
                {/*<Divider />*/}
              </>
              {/*<>*/}
              {/*  <div style={{ padding: "10px 0px" }}>*/}
              {/*    <Typography className={classes.labelSmall}>*/}
              {/*      Upload csv to invite multiple orgs*/}
              {/*    </Typography>*/}
              {/*    <a*/}
              {/*      href="/sampleFiles/sampleOrgRegisteration.csv"*/}
              {/*      download="sample.csv"*/}
              {/*    >*/}
              {/*      <Typography>Download Sample.csv</Typography>*/}
              {/*    </a>*/}
              {/*  </div>*/}
              {/*  {values.currentUploadedFile && (*/}
              {/*    <Box className={classes.fileBox}>*/}
              {/*      <Typography>{values.currentUploadedFile.name}</Typography>*/}
              {/*      <IconButton*/}
              {/*        aria-label="Delete"*/}
              {/*        onClick={() =>*/}
              {/*          setValues({ ...values, currentUploadedFile: null })*/}
              {/*        }*/}
              {/*        className={classes.iconSpacing}*/}
              {/*      >*/}
              {/*        <DeleteIcon />*/}
              {/*      </IconButton>*/}
              {/*    </Box>*/}
              {/*  )}*/}
              {/*  <input*/}
              {/*    accept=".csv"*/}
              {/*    className={classes.hideInput}*/}
              {/*    multiple*/}
              {/*    id="fileButtonRegister"*/}
              {/*    onChange={fileUploadButton}*/}
              {/*    type="file"*/}
              {/*  />*/}
              {/*  <label htmlFor="fileButtonRegister">*/}
              {/*    <Button*/}
              {/*      variant="contained"*/}
              {/*      component="span"*/}
              {/*      className={classes.fileUpload}*/}
              {/*      startIcon={<AddIcon />}*/}
              {/*    >*/}
              {/*      Upload File*/}
              {/*    </Button>*/}
              {/*  </label>*/}
              {/*</>*/}
            </form>
          </DialogContent>
        </Dialog>
      </div>
  );
}
