/*
 * @Author: Vincent Tran
 * @Date: 2020-08-05 01:55:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-08-05 01:58:00
 * @Description: Standard Input text field component
 */
/*
 * @Author: Vincent Tran
 * @Date: 2020-07-31 11:26:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-07-31 11:27:00
 * @Description: Standard Input text field component
 */

import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
    root: {
        width:"100%",
        'label + &': {
            marginTop: theme.spacing(0.5),
        },
        borderRadius: 4,
        border: '1px solid #767676',

    },
    input: {
        width:"100%",
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        // the following highlights the input blue if the cursor is inside the input
        // '&:focus': {
        //     //boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        //     borderColor: theme.palette.primary.main,
        // },
    },
}))(InputBase);

export default BootstrapInput;