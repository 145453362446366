import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useState } from "react";
import { useSendEmail } from "../../SendEmail";
import GlobalContext from "../../context/GlobalContext";
import popupStyle from "../../styles/PopupStyle";
import combineStyles from "../../styles/combineStyles";
import usersPageStyles from "../../styles/usersPageStyles";

let portonAdminAppBaseURL = process.env.REACT_APP_PORTON_ADMIN_API;
if (!process.env.REACT_APP_PORTON_ADMIN_API) {
  portonAdminAppBaseURL = "/api";
}
const combinedStyles = combineStyles(popupStyle, usersPageStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PatientActionEmailForm = ({
  open,
  handleClose,
  patient,
  orgDetails,
  nextAppointment,
}) => {
  const global = React.useContext(GlobalContext);
  const logo = orgDetails.logo;
  const orgName = orgDetails.org_name;
  const [state, setState] = React.useState({
    subject: "",
    content: "",
    currentUploadedFile: null,
    email: "",
    name: "",
    forms: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const { sendEmail, EMAIL_STATES, emailState } = useSendEmail({
    email: state.email,
    subject: state.title,
    content: state.content,
    emailType: "patientActionEmail",
    attachmentMetadata: state.currentUploadedFile,
    otherBodyProps: {
      sentFrom: "Admin",
      logo: logo,
      orgName: orgName,
      contactName: state.name,
      appointmentId: nextAppointment._id,
    },
  });

  useEffect(() => {
    switch (emailState) {
      case EMAIL_STATES.LOADING:
        setIsLoading(true);
        break;
      case EMAIL_STATES.SUCCESS:
        alert("Message have been sent successfully");
        handleClose();
        setState({
          ...state,
          subject: "",
          content: "",
          currentUploadedFile: null,
        });
        setIsLoading(false);
        break;
      case EMAIL_STATES.ERROR:
        alert("Error sending message");
        setIsLoading(false);
        break;
    }
  }, [emailState]);
  const useStyles = makeStyles((theme) => ({
    TextField: {
      marginTop: "5px",
    },

    title: {
      marginTop: "3px",
      marginLeft: "4px",
    },
    TextFieldEmail: {
      marginTop: "5px",
      border: "1px solid black",
      borderRadius: "10px",
    },
    icon: {
      float: "right",
      color: "#444444",
      marginTop: "-6px",
      marginLeft: "34px",
    },

    titleTop: {
      color: "#444444",
      fontFamily: "Roboto",
    },
  }));

  const classes = useStyles();
  const classe = combinedStyles();

  const handleTextChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const viewFile = () => {
    window.open(URL.createObjectURL(state.currentUploadedFile));
  };
  const downloadFile = () => {
    let link = document.createElement("a");
    link.href = URL.createObjectURL(state.currentUploadedFile);
    link.download = state.currentUploadedFile.name;
    link.click();
  };

  useEffect(() => {
    setState({
      ...state,
      email: patient.emails?.length
        ? patient.emails[0].address
        : patient.email || "N/A",
      name: `${patient.firstname} ${patient.lastname}`,
      currentUploadedFile: null,
    });
  }, [patient]);

  const fileUploadButton = async (event) => {
    setState({ ...state, currentUploadedFile: event.target.files[0] });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="md"
    >
      <DialogTitle className={classes.titleTop} style={{ marginTop: "7px" }}>
        Send Secure Email
        <IconButton
          className={classes.icon}
          onClick={handleClose}
          children={<ClearIcon />}
        />
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title} variant="body2">
              Email
            </Typography>
            <TextField
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
              placeholder="Email"
              value={state.email}
              name="email"
              size="small"
              color="#222222"
              disabled
              className={classes.TextFieldEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.title} variant="body2">
              Subject
            </Typography>
            <TextField
              InputProps={{ disableUnderline: true }}
              variant="outlined"
              fullWidth
              placeholder="Enter a subject ..."
              onChange={handleTextChange}
              value={state.subject}
              name="subject"
              size="small"
              color="#222222"
              className={classes.TextField}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.title} variant="body2">
              Content
            </Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              name="content"
              rows={5}
              fullWidth
              value={state.content}
              onChange={handleTextChange}
              variant="outlined"
              placeholder="Write your message here"
              className={classes.TextField}
            />
          </Grid>
          <div
            className="col-sm-3 col-lg-3 col-md-4 "
            style={{
              width: "fit-content",
            }}
          >
            <input
              accept=".pdf"
              className={classe.hideInput}
              multiple
              id="fileButtonContact"
              onChange={fileUploadButton}
              type="file"
            />
            <label htmlFor="fileButtonContact">
              <Button
                variant="contained"
                component="span"
                className={classe.fileUpload}
                startIcon={<AddIcon />}
              >
                Upload File
              </Button>
            </label>

            {state && state.currentUploadedFile && (
              <Card className={classe.fileManagement}>
                <Box className={classe.iconButtonContainer}>
                  <Typography className={classe.fileButtonText}>
                    {state.currentUploadedFile.name}
                  </Typography>
                  <IconButton aria-label="View" onClick={viewFile}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton aria-label="Download" onClick={downloadFile}>
                    <GetAppIcon />{" "}
                  </IconButton>
                  <IconButton
                    aria-label="Delete"
                    onClick={() =>
                      setState({ ...state, currentUploadedFile: null })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Card>
            )}
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <CircularProgress
            style={{ margin: "1px", color: "#1766b3", marginRight: "20px" }}
          />
        ) : (
          <>
            <Button color={"primary"} onClick={handleClose}>
              Cancel
            </Button>
            <Button color={"primary"} onClick={() => sendEmail()}>
              Send
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PatientActionEmailForm;
