import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import Tick from "../../src/img/tick.png";

const useStyles = makeStyles((theme) => ({
   dialogActions: {
        display: "flex",
        justifyContent: "center",
    },
    dialogTitle: {
        justifyContent: "center",
        color: "444444",
        textAlign: "center",
        fontSize: "24px",
        fontWeight: "300",
        fontFamily:"Roboto"

    },
    dialogClose: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
    },

    tick: {
        maxWidth: "70px",
        justifyContent: "center",
        marginTop: "1rem",
    },
}));

const OrgSelectorSuccessDialog = ({
    open,
    onClose,
    newOrgName = "Default Organization",
}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle
                id="alert-dialog-title"
                className={classes.dialogTitle}
            >
                {/* ICON HERE */}
                <img className={classes.tick} src={Tick} alt={Tick} />
                <IconButton
                    // onClick={() => setOpenDialog(false)}
                    className={classes.dialogClose}
                    onClick={onClose}
                    children={<ClearIcon />}
                    style={{
                        cursor: "pointer",
                        float: "right",
                        margin: "-8px",
                        color: "#000000",
                    }}
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography className={classes.dialogTitle}>
                        <strong>
                            Organization has been changed to {newOrgName}
                        </strong>
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={onClose}
                    color="primary"
                    style={{
                        fontSize: "16px",
                        marginBottom: "20px",
                    }}
                >
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrgSelectorSuccessDialog;
