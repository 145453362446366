import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { INITIAL_EVENTS, createEventId } from "./components/event-utils";
import plugin from "./components/plugin";
import GlobalContext from "../../context/GlobalContext";
import DetailPopup from "./components/PatientAppointmentDetailsPopup";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import AddAppointmentBtn from "./components/AddAppointment/AddAppoinmentBtn";
import { Button, Hidden, Typography } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import "../../styles/calendarStyleOverride.css";
import styled from "@emotion/styled";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import interactionPlugin from "@fullcalendar/interaction";
import Calendar from "react-calendar";
import UserSearchBar from "../shared/UserSearchBar";
import "../../styles/small-calendar.css";
import { useParams } from "react-router-dom";
import OrgSelector from "../../frame/OrgSeletor";

//color for each appointment mode
const color = {
  inPerson: "#EFCF59",
  video: "#71BDA4",
  voice: "#7EAAD3",
  text: "#CC9DD4",
};
//short text for each appointment mode
const text = {
  inPerson: "Phys.",
  video: "Video",
  voice: "Voice",
  text: "Text",
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginTop: "2rem",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  smallCalenderpaper: {
    marginTop: "2rem",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  calendarPaper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: "8px",
  },
  calendarHeadBox: {
    marginTop: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  calendarTitle: {
    fontSize: "1.8rem",
    fontWeight: 500,
    marginRight: "1rem",
    minWidth: "15rem",
  },
  pageTitle: {
    fontSize: "1.8rem",
    fontWeight: 500,
    marginRight: "1rem",
    minWidth: "11rem",
    marginTop: "0.5rem",
  },
  calendarOptionBtn: {
    backgroundColor: "#FFFFFF",
    width: "120px",
    height: "42px",
  },
  calendarOptionPopup: {
    marginTop: "0.3rem",
  },
  calendarOptionListItem: {
    width: "120px",
  },
  calendarOptionListFont: {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#343434",
    marginLeft: "0.2rem",
  },
  addAppointmentWrapper: {
    marginTop: "11px",
  },
  appointmentModeWrapper: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    textAlign: "left",
  },

  appointmentModeTitile: {
    marginLeft: "1rem",
    marginBottom: "0.5rem",
    fontSize: "1rem",
    fontWeight: 500,
    color: "#343434",
  },
  appointmentModeSampleBox: {
    textAlign: "left",
    padding: "0.4rem 1rem 0.4rem 1rem",
    display: "flex",
    alignItems: "center",
  },
  appointmentModeSampleColor: {
    textAlign: "left",
    height: "1rem",
    width: "1rem",
    marginRight: "1rem",
    borderRadius: "10%",
    backgroundColor: "black",
  },
  appointmentModeSampleText: {
    textAlign: "left",
    color: "#343434",
    fontSize: "0.9rem",
    fontWeight: 500,
  },
}));
//style for big calendar
const BigCalendarStyleWrapper = styled.div`
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }
  thead tr:first-child th:first-child {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  //table head display none
  .fc-theme-standard th {
    border: 1px solid #ededed;
    border: none;
  }
  //top left border display none
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  .fc-scrollgrid-sync-table tbody tr td {
    border: 1px solid #ededed;
  }
  .fc-scrollgrid-sync-table tbody tr td:last-child {
    border: none;
    border-top: 1px solid #ededed;
  }
  .fc-col-header {
    min-height: 2rem;
  }
  .fc-col-header-cell {
    margin: auto;
    vertical-align: middle;
  }
  .fc-col-header-cell-cushion {
    color: #767676;
    font-weight: 500;
    text-transform: uppercase;
  }
  .fc .fc-daygrid-day-top {
    justify-content: center;
  }
  .fc-daygrid-day-number {
    color: #343434;
    font-weight: 500;
  }
  .fc-scrollgrid-section-header {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.1),
      0px 5px 8px 0px rgba(0, 0, 0, 0.07), 0px 1px 14px 0px rgba(0, 0, 0, 0.06);
    z-index: 90;
  }
  .fc .fc-day-other .fc-daygrid-day-top {
    opacity: 0.7;
  }
`;
//small calendar style wrapper
const SmallCalendarStyleWrapper = styled.div`
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }
  thead tr:first-child th:first-child {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  //table head display none
  .fc-theme-standard th {
    border: 1px solid #ededed;
    border: none;
  }
  //top left border display none
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  .fc-scrollgrid-sync-table tbody tr td {
    border: 1px solid #ededed;
  }
  .fc-scrollgrid-sync-table tbody tr td:last-child {
    border: none;
    border-top: 1px solid #ededed;
  }
  .fc-col-header {
    min-height: 2rem;
  }
  .fc-col-header-cell {
    margin: auto;
    vertical-align: middle;
  }
  .fc-col-header-cell-cushion {
    color: #767676;
    font-weight: 500;
    text-transform: uppercase;
  }
  .fc .fc-daygrid-day-top {
    justify-content: center;
  }
  .fc-daygrid-day-number {
    color: #343434;
    font-weight: 500;
  }
  .fc-scrollgrid-section-header {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.1),
      0px 5px 8px 0px rgba(0, 0, 0, 0.07), 0px 1px 14px 0px rgba(0, 0, 0, 0.06);
    z-index: 90;
  }
  .fc .fc-day-other .fc-daygrid-day-top {
    opacity: 0.7;
  }
`;

export default function DemoApp(props) {
  const global = React.useContext(GlobalContext);
  const classes = useStyles();
  const calendarRef = React.useRef();
  const [weekendsVisible, setWeekendsVisible] = React.useState(true);
  const [detailPopUpOpen, setDetailPopUpOpen] = React.useState(false);
  const [appointmentId, setAppointmentId] = React.useState(null);
  const [timeZone, setTimezone] = React.useState("America/Los_Angeles");
  const [currentEvents, setCurrentEvents] = React.useState([]);
  const [smallCalendarDate, setSmallCalendarDate] = React.useState(new Date());
  const [calendarTitle, setCalendarTitle] = React.useState("loading");
  const [calendarAPI, setCalendarAPI] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [calendarView, setCalendarView] = React.useState("MONTH");
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState(null);

  let { patientId, doctorId } = useParams();

  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible);
  };

  React.useEffect(() => {
    // TODO: reload calendar data here for the new selected user once api calls and other calendar features are implemented
  }, [selectedUser]);

  const handleDateSelect = (selectInfo) => {
    let title = prompt("Please enter a new title for your event");
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  const handleCalendarNext = () => {
    calendarAPI.next();
    setCalendarTitle(calendarAPI.view.title);
  };
  //handle calendar click prev
  const handleCalendarPrev = () => {
    calendarAPI.prev();
    setCalendarTitle(calendarAPI.view.title);
  };
  //control calendar event click
  const handleEventClick = (clickInfo) => {
    setDetailPopUpOpen(true);
    setAppointmentId(clickInfo.event._def.extendedProps._id);
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  const getAppointmentsInfo = async () => {
    let currentOrg;
    if (!global.state) return;
    if (
      !global.state ||
      !global.state.selectedOrg ||
      global.state.selectedOrg == null ||
      JSON.stringify(global.state.selectedOrg) == "{}"
    ) {
      currentOrg = global.state.defaultOrg;
    } else {
      currentOrg = global.state.selectedOrg;
    }

    const result = await global.api.getAppointments(
      currentOrg._id,
      patientId,
      doctorId
    );
    if (result.status === 200) {
      const appointmentsRow = result.data;

      const appointments = result.data.map((v, i) => {
        // //console.log(v)
        let selectedColor = color[v.eventType];
        return { ...v, color: selectedColor };
      });
      setCurrentEvents(appointments);
    }
  };
  //control pop over for view change for calendar
  const handleCalendarOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCalendarOptionClose = () => {
    setAnchorEl(null);
  };
  //control pop over for view change for calendar Click
  const handleDateClick = (option) => {
    calendarAPI.changeView(option);
    setCalendarTitle(calendarAPI.view.title);
    const btnName = {
      dayGridMonth: "MONTH",
      timeGridDay: "DAY",
      timeGridWeek: "WEEK",
    };
    setCalendarView(btnName[option]);
  };

  const handleSmallCalendarDateChange = (selectedDate) => {
    //get ride of timezone
    let date = new Date(
      Date.UTC(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      )
    );

    let utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
    let tzDate = new Date(date.toLocaleString("en-US", { timeZone: timeZone }));
    let offset = utcDate.getTime() - tzDate.getTime();
    date.setTime(date.getTime() + offset);
    calendarAPI.gotoDate(date);
    setCalendarView("DAY");
    calendarAPI.changeView("timeGridDay");
    setCalendarTitle(calendarAPI.view.title);
    setSmallCalendarDate(selectedDate);
  };
  //load calendar API
  React.useEffect(() => {
    const ref = calendarRef.current;
    if (ref) {
      setCalendarAPI(ref.getApi());
      setCalendarTitle(ref.getApi().view.title);
    } else {
    }
  }, [
    calendarRef.current ? calendarRef.current.getApi() : calendarRef.current,
  ]);
  React.useEffect(() => {
    getAppointmentsInfo();
  }, [patientId, doctorId, global.state.defaultOrg, global.state.selectedOrg]);

  let settingsPermission =
    global.state.user && global.state.user.document.roles[0] === "admin";
  React.useEffect(() => {
    if (settingsPermission) {
      props.setTitle("Select Organization");
    } else {
      props.setTitle("Calendar");
    }
  }, []);

  //manage calendar option
  const openCalendarOption = Boolean(anchorEl);
  const id = openCalendarOption ? "simple-popover" : undefined;
  return (
    <Container>
      {/* <div>patient id {patientId}</div>
      <div>doctor id {doctorId}</div> */}
      {settingsPermission && <OrgSelector />}
      <DetailPopup
        open={detailPopUpOpen}
        setOpen={setDetailPopUpOpen}
        appointmentId={appointmentId}
        timeZone={timeZone}
      />
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <UserSearchBar
            users={users}
            setSelectedUser={setSelectedUser}
            setUsers={setUsers}
            placeholder="Search Providers, Patients"
            autoComplete
          />
        </Grid>
        <Grid item xs={9}>
          {selectedUser && (
            <Typography className={classes.pageTitle}>
              {selectedUser.contact_name
                ? selectedUser.contact_name
                : `${selectedUser.firstname} ${selectedUser.lastname}`}
              's Calendar
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          <Box className={classes.calendarHeadBox}>
            <Box>
              <Typography className={classes.calendarTitle}>
                {calendarTitle}
              </Typography>
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={handleCalendarPrev}>
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton onClick={handleCalendarNext}>
                <KeyboardArrowRightIcon />
              </IconButton>
              <Button
                className={classes.calendarOptionBtn}
                aria-describedby={id}
                variant="contained"
                onClick={handleCalendarOptionClick}
                endIcon={
                  openCalendarOption ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
              >
                {calendarView}
              </Button>
            </Box>
            <Popover
              id={id}
              open={openCalendarOption}
              anchorEl={anchorEl}
              onClose={handleCalendarOptionClose}
              className={classes.calendarOptionPopup}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <List>
                {calendarView != "MONTH" && (
                  <ListItem
                    button
                    className={classes.calendarOptionListItem}
                    onClick={() => {
                      handleDateClick("dayGridMonth");
                    }}
                  >
                    <Typography className={classes.calendarOptionListFont}>
                      MONTH
                    </Typography>
                  </ListItem>
                )}
                {calendarView != "WEEK" && (
                  <ListItem
                    button
                    className={classes.calendarOptionListItem}
                    onClick={() => {
                      handleDateClick("timeGridWeek");
                    }}
                  >
                    <Typography className={classes.calendarOptionListFont}>
                      WEEK
                    </Typography>
                  </ListItem>
                )}
                {calendarView != "DAY" && (
                  <ListItem
                    button
                    className={classes.calendarOptionListItem}
                    onClick={() => {
                      handleDateClick("timeGridDay");
                    }}
                  >
                    <Typography className={classes.calendarOptionListFont}>
                      DAY
                    </Typography>
                  </ListItem>
                )}
              </List>
            </Popover>
          </Box>
        </Grid>
        <Grid item xs={5} md={3}>
          <Box className={classes.addAppointmentWrapper}>
            <AddAppointmentBtn />
          </Box>
        </Grid>
        <Grid item xs={10} md={9}>
          <Paper elevation={5} className={classes.calendarPaper}>
            <BigCalendarStyleWrapper>
              <FullCalendar
                ref={calendarRef}
                timeZone={timeZone}
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  plugin,
                  momentTimezonePlugin,
                  interactionPlugin,
                ]}
                headerToolbar={{
                  left: "",
                  center: "",
                  right: "",
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                duration="00:15:00"
                // eventDisplay='list-item'
                selectMirror={true}
                dayMaxEvents={true}
                weekends={weekendsVisible}
                initialEvents={INITIAL_EVENTS}
                events={currentEvents ? currentEvents : []} // alternatively, use the `events` setting to fetch from a feed
                select={handleDateSelect}
                eventContent={renderEventContent} // custom render function
                eventTimeFormat={{
                  // like '14:30:00'
                  hour: "2-digit",
                  minute: "2-digit",

                  meridiem: false,
                }}
                eventClick={handleEventClick}
                complete={function () {
                  alert("complete");
                }}
                viewRender={() => {
                  alert("complete");
                }}
                slotDuration="00:05"
                //  slotLabelFormat="HH:mm"
                //  eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                //you can update a remote database when these fire:
                //eventAdd={function(){}}
                //eventChange={function(){}}
                //eventRemove={function(){}}
              />
            </BigCalendarStyleWrapper>
          </Paper>
        </Grid>
        <Hidden mdDown>
          <Grid item md={3}>
            {/* <Paper elevation={5}><br />xs=3<br /></Paper> */}

            <Paper elevation={5} className={classes.smallCalenderpaper}>
              <Calendar
                value={smallCalendarDate}
                locale={"en-US"}
                onChange={handleSmallCalendarDateChange}
                onActiveStartDateChange={({ activeStartDate }) =>
                  setSmallCalendarDate(activeStartDate)
                }
              />
            </Paper>
            <Paper
              elevation={5}
              className={clsx(
                classes.smallCalenderpaper,
                classes.appointmentModeWrapper
              )}
            >
              <Typography className={classes.appointmentModeTitile}>
                Appointment Mode
              </Typography>
              <Box className={classes.appointmentModeSampleBox}>
                <Box
                  style={{ backgroundColor: color.inPerson }}
                  className={classes.appointmentModeSampleColor}
                />
                <Typography className={classes.appointmentModeSampleText}>
                  Physical
                </Typography>
              </Box>
              <Box className={classes.appointmentModeSampleBox}>
                <Box
                  style={{ backgroundColor: color.video }}
                  className={classes.appointmentModeSampleColor}
                />
                <Typography className={classes.appointmentModeSampleText}>
                  Video Call
                </Typography>
              </Box>
              <Box className={classes.appointmentModeSampleBox}>
                <Box
                  style={{ backgroundColor: color.voice }}
                  className={classes.appointmentModeSampleColor}
                />
                <Typography className={classes.appointmentModeSampleText}>
                  Voice Call
                </Typography>
              </Box>
              <Box className={classes.appointmentModeSampleBox}>
                <Box
                  style={{ backgroundColor: color.text }}
                  className={classes.appointmentModeSampleColor}
                />
                <Typography className={classes.appointmentModeSampleText}>
                  Text Chat
                </Typography>
              </Box>
            </Paper>
            {/* <button onClick={getAppointmentsInfo}>
                        load appintment data(only works after login)
                    </button>
                    <button onClick={global.api.initModeCollection}>
                        init modes collection
                    </button> */}
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
}

function renderEventContent(eventInfo) {
  let selectColor = "#000000";
  let selectText = "none";
  if (color[eventInfo.event._def.extendedProps.eventType]) {
    selectColor = color[eventInfo.event._def.extendedProps.eventType];
    selectText = text[eventInfo.event._def.extendedProps.eventType];
  }
  if (eventInfo.view.type == "dayGridMonth") {
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            border: `calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color, ${selectColor})`,
            marginTop: 5,
          }}
          className="fc-daygrid-event-dot"
        ></div>
        <b style={{ fontWeight: 500 }}>{eventInfo.timeText}</b>
        <b style={{ fontWeight: 500 }}>&nbsp;{selectText}</b>
      </div>
    );
  }
  if (
    eventInfo.view.type == "timeGridWeek" ||
    eventInfo.view.type == "timeGridDay"
  ) {
    return (
      <div>
        <div style={{ fontWeight: 500 }}>{eventInfo.timeText}</div>
        <div style={{ fontWeight: 500 }}>{selectText}</div>
      </div>
    );
  }
}
