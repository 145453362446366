import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import settingsStyles from '../../../../../styles/settingsPageStyles';


const EmailSetting = ({emailSetting, setEmailSetting}) => {
    const classes = settingsStyles()

    const handleChangeText = (event) => {
        setEmailSetting({...emailSetting, [event.target.name]: event.target.value})
    }

    return (
        <Grid container className={classes.section}>
            <Grid item xs={12}>
                <Typography style={{fontWeight:600}} className={classes.labelSmall}>Email Setting</Typography>
                <Typography className={classes.labelMedSmall}>
                	Enter the email address where the BookApp  contact emails will be sent to.
                </Typography>
            </Grid>
            <Grid item xs={12}>
            	<Typography variant="body2" className={classes.labelText}>Email Address (Clinic)</Typography>
            	<TextField
            	    InputProps={{ disableUnderline: true }}
            	    onChange={handleChangeText}
            	    variant="outlined"
                    value={emailSetting.email || ""}
            	    fullWidth
            	    className={classes.textBox}
            	    placeholder="Email Address (clinic)"
            	    name="email"
            	    size="small"
            	/>
            </Grid>   
        </Grid>
    )
}

export default EmailSetting;