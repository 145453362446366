import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
//import GlobalContext from "../../context/GlobalContext"
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import GlobalContext from '../../../../../../context/GlobalContext';


const FormData = require('form-data');

export default function SendFaxModal({open, close, state, setState, fileRetrieveFromAWS, DeleteUploadedFileDB, classes}) {
   
   const AdminApiUrl = (url) => (process.env.REACT_APP_ADMIN_API) + url
   const global = useContext(GlobalContext)
//    const BUCKET_NAME = process.env.REACT_APP_S3_BUCKETNAME? process.env.REACT_APP_S3_BUCKETNAME :"porton-shared"
   let PortonAdminAppBaseURL = process.env.REACT_APP_PORTON_ADMIN_API
   if(!process.env.REACT_APP_PORTON_ADMIN_API){
     PortonAdminAppBaseURL="/api"
   }

   const [values, setValues] = useState({
     currentUploadedFile: null
   });

   const [faxTo, setFaxTo] = useState('');
   const [faxFrom, setFaxFrom] = useState('');


   const handleToChange = (event) => {
    setFaxTo(event.target.value)
   
   }

   const handleFromChange = event => {
     setFaxFrom(event.target.value);
   }

    const handleCancel = () => {
        //setValues(defaultValues)
        close()
    }

    const handleSend = async () => {
      const formData = new FormData();
      formData.append("file", values.currentUploadedFile);
      formData.append("faxTo", faxTo);


      const result = await axios.post(AdminApiUrl('/api/fax/sendFax'), formData, {
         headers: {'Content-Type': 'multipart/form-data'}
      }).catch((error) => {
        // console.log(error);
      })


      if (result) {
        alert('Fax Sent')
      }
      
      handleCancel();
    }


    const handleFileChange = async (event) => {
      await setValues({
        currentUploadedFile: event.target.files[0],
      })

    }


    const handleFileUpload = () => {

    }

    
    
    // const handleFiles = async files => {

    //    // let fileName = values.currentUploadedFile.name.toLowerCase();
    //    /* if(!fileName.endsWith('.csv')){
    //         alert('Please upload a CSV file');
    //         return false;
    //     }
    //     setLoading(true)
    //     Papa.parse(values.currentUploadedFile, {
    //         complete: dataRead,
    //         header: true
    //     });*/
        
    // }
    // const styles = { 

    //     root: { },
    //     paper: { borderRadius: 15 } 
    
    // } 


    return (
            <div style={{borderRadius: "18px"}}>
            <Dialog 
            classes={{ 
                root: classes.root, 
                paper: classes.paper }}
            fullWidth
            maxWidth="xs"
                open={open}>
            
                <DialogTitle>
                <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.extraPadding} style={{marginBottom: "-16px"}} >
                    <Typography style={{fontFamily: "Roboto", fontWeight: 500, fontSize: "24px", lineHeight: "28.14px", 
                     }}>Send Fax</Typography>
                    <IconButton>
                        <CloseIcon onClick={close} style={{color: "black"}}/>
                    </IconButton>
                </Box>
            </DialogTitle>
                
                <DialogContent  style={{height: "261px"}}>
                <FormControl style={{ width: "100%"}}>
                        
                         
                        
                            <>
                            <Typography style={{fontFamily: "Roboto", fontWeight: 400, fontSize: "12px", lineHeight: "14.06px", paddingBottom: "3px",
     paddingLeft: "5px"}}>Sender</Typography>
                                <TextField
                                    id="name"
                                    type="number"
                                    value={faxFrom}
                                    onChange={handleFromChange}
                                
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="+1 000 000 0000"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                //    onChange={e => setValues({...values, name: e.target.value})}
                                   // className={clsx(classes.marginSpacing, classes.upperSpacing)}
                                    
                                />      

                                      <Typography style={{fontFamily: "Roboto", fontWeight: 400, fontSize: "12px", lineHeight: "14.06px", paddingBottom: "3px",
    paddingTop: "12px", paddingLeft: "5px"}}>Recipient's Fax Number</Typography>            
                                <TextField
                                    id="name"
                                    
                                    type="number"
                                    placeholder="+1 000 000 0000"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={faxTo}
                                    onChange={handleToChange}
                                  
                                   // className={classes.marginSpacing}
                                    required
                                   
                                />
                            </>
                        
                        <>
                            
                        <input
                    accept=".pdf"
                    style={{"display": "none"}}
                    className={classes.input}
                    multiple
                    id="fileButton"
                    onChange={handleFileChange}
                    type="file"
                    />
                <label htmlFor="fileButton">
                    <Button
                        style={{
                            fontFamily: "Roboto", 
                            fontWeight: "500",
                            fontSize: "12px",
                            lineHeight: "14.06px",
                            line: "0.5px",
                            marginTop: "15px"
                        }}
                        variant="contained"
                        color="default"
                        component="span"
                        className={classes.buttonUpload}
                        startIcon={<AddIcon />}
                        onClick={handleFileUpload}>
                        Upload File
                    </Button>
                </label>
                {state.currentUploadedFile && 
                    <Box className={classes.labelWithEdit} style ={{"width": "100%", "marginTop": "15px"}}>
                        <Typography >{state.currentUploadedFile.name}</Typography>
                        <ButtonGroup variant="text" size="small" aria-label="text primary button group">
                            {/* 
                            <IconButton aria-label="View" target = '_blank' href={state.currentUploadedFile.location}><VisibilityIcon /></IconButton>
                            <IconButton aria-label="Download" download={state.currentUploadedFile.name} href={'./'+state.currentUploadedFile.location}><GetAppIcon/></IconButton>
                            */}
                            <IconButton aria-label="Delete" onClick={() => setState({ ...state, currentUploadedFile: null })}><DeleteIcon/></IconButton>
                        </ButtonGroup>
                    </Box>
                }
               
                   { /*state.forms &&
                    state.forms.map((form, i) => ( */}
                        <Box className={classes.labelWithEdit} style={{"width": "100%", "marginTop": "15px", borderRadius:'5px'}}>
                            <Typography style={{padding:'2%', paddingLeft:'2.5%', paddingTop: "11px", marginLeft: "11px"}}>sample.pdf</Typography>
                            <Grid>
                                <IconButton aria-label="View" target = '_blank' style={{marginRight:'-5%'}}><VisibilityIcon /> </IconButton>
                              {/*  <Divider orientation="vertical"  flexItem variant="middle"/> */}
                                <IconButton aria-label="Download" onClick={() => fileRetrieveFromAWS()} style={{marginRight:'-5%'}}><GetAppIcon/> </IconButton>
                                <IconButton aria-label="Delete" onClick={() => DeleteUploadedFileDB()}><DeleteIcon/></IconButton>
                            </Grid>
                        </Box>
               { /*   )) */} 
                   
                            
                        </>
                    
                       
                        </FormControl>
                 
                    
                </DialogContent>
            <DialogActions classes={{ root: classes.dialogActions }} style={{ marginBottom: "16px"}}>
                <Button onClick={handleCancel} color='primary' style={{fontFamily: "Roboto", fontWeight:500, fontSize: "16px",  
                lineHeight: "18.75px"
}}>Cancel</Button>
                <Button onClick={handleSend} color='primary' style={{fontFamily: "Roboto", fontWeight:500, fontSize: "16px",  
                lineHeight: "18.75px"
}}>Send</Button>
            </DialogActions>

            
            </Dialog>
        </div>
    )
}