import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import React from 'react';


export default function UrlComponent(props) {
    const classes = props.classes;
    const state = props.state;
    const maxLength = 500;
    const handleChange = props.handleChange;
    const [count, setCount] = React.useState(0);
    
    return (
        <>
            <Grid item xs={12} className={classes.paperComponment}>
                <Box className={classes.labelWithEdit} >
                    <Typography className={classes.labelSmall}>Room Settings</Typography>
                    
                </Box>
                <Typography variant="body1">Customize your virtual room by applying theme colour and announcements.</Typography>
            </Grid>
            <Grid item xs={12} className={classes.paperComponment}>

                <Typography className={classes.labelSmall}>Announcement</Typography>
        
                <TextField
                        id="outlined-multiline-static"
                        multiline
                        name="announcement"
                        rows={4}
                        // onChange={handleTextChange(state.announcement)}
                        value={state.announcement}
                        onChange={handleChange}
                        variant="outlined"
                        placeholder="Enter Message"
                        className={classes.textBox}
                        // onChange={e => {setCount(e.target.value.length)}} 
                    />
                <div className={classes.textCounter}>
                    {count}/{ maxLength }
                </div>
            </Grid>
        </>
    );
}