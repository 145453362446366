import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
        height: "40px",
        borderRadius: "8px",
    },
    iconButton: {
        padding: '6px 6px 0 6px',
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
    },
    listItem: {
        width: '100%',
        '&:hover': {
            background: '#D3E2FF',
        }
    },
    listBox: {
        boxShadow: '0px 2px 4px rgba(8, 35, 48, 0.24)',
        marginTop: '6px',

        borderRadius: '4px',
        position: 'absolute',
        left: '-39px',
        right: '-4px',
        width:'105.8%',
    },
    alignleft: {
        float:'left',
    },
    alignright: {
        float: 'right',
    },
    fullWidth: {
        width: '100%',
    },
    popper: {
        padding: '0px',
        marginTop: '4px',
        position: 'absolute',
        height: '210px',
        left: '300px',
        top: '264px',
    }, 
   
   
}));