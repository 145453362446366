import React from "react";
// Material UI
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { ReactComponent as PortonLogo } from "../img/virtualapplogo.svg";
// Style
import useStyles from "./styles/NewFooterStyle";

export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  const middleScreen = useMediaQuery(theme.breakpoints.down(1000));
  const smallScreen = useMediaQuery(theme.breakpoints.down(450));

  return (
    <footer className={classes.footer}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        direction={middleScreen ? "column" : "row"}
      >
        <Grid item md={6} xs={12}>
          <Grid
            container
            spacing={smallScreen ? 2 : 4}
            justify="flex-start"
            wrap="nowrap"
          >
            <Grid item xs>
              <Link component="button" underline="none">
                <a
                  href="https://www.portonhealth.com/privacy-and-security/"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <Typography className={classes.footerText} variant="body2">
                    Privacy&nbsp;Policy
                  </Typography>
                </a>
              </Link>
            </Grid>
            <Grid item xs>
              <Link component="button" underline="none">
                <a
                  href="https://www.portonhealth.com/privacy-and-security/"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <Typography className={classes.footerText} variant="body2">
                    Terms&nbsp;&&nbsp;Conditions
                  </Typography>
                </a>
              </Link>
            </Grid>
            <Grid item xs>
              <Link component="button" underline="none">
                <a
                  href="https://www.portonhealth.com/contact-us/"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <Typography className={classes.footerText} variant="body2">
                    Contact&nbsp;Us
                  </Typography>
                </a>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <PortonLogo className={classes.portonImgFooter} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}
