import { MenuItem, Select, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import clsx from "clsx";
import React from 'react';


export default function MessageComponent(props) {
    const classes = props.classes;
    const state = props.state;
    const doctors = props.doctors;
    const maxLength = props.maxLength;
    const selectProviderMessage = props.selectProviderMessage;
    const getMessage = props.getMessage;
    const getDoctorName = props.getDoctorName;
    const handleChangeDropdown = props.handleChangeDropdown;
    const handleChangeText = props.handleChangeText;
    return (
        <Grid item xs={12} className={classes.paperComponment}>
        
            <Box>
                <Typography className={classes.labelSmall} style={{'marginBottom' : '10px'}}>Provider Message Board</Typography>
                <Typography variant="body2" className={classes.labelText}>Provider Name</Typography>
                <Box className={classes.subButton}>
                <FormControl className={classes.formControl}>
                    <Select
                        displayEmpty
                        disableUnderline
                        value={state !== undefined ? state.selectedProvider : -1}
                        onChange={handleChangeDropdown}
                        name='selectedProvider'
                        className={state.selectedProvider === -1 ? clsx(classes.listDefault, classes.textList) : classes.textList}
                        inputProps={{ 'aria-label': 'Without label', disableUnderline: true}}
                    >
                    <option value={-1} hidden> {selectProviderMessage()} </option>
                    
                    {doctors.doctorList !== undefined && doctors.doctorList.length !== 0 && doctors.doctorList.map(doctor => {
                        return (
                            <MenuItem className={classes.optionList} key={doctor.key} value={doctor.key}> {getDoctorName(doctor.key)} </MenuItem>
                        )
                    })}
                        
                        
                    </Select>
                </FormControl>
                </Box>

                {state.selectedProvider !== undefined && state.selectedProvider !== -1 && doctors.doctorList !== undefined && doctors.doctorList.length !== 0 && [
                    <>
                        <Typography variant="body2" className={classes.labelText}>Provider Message</Typography>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            name="providerMessage"
                            rows={4}
                            onChange={handleChangeText(getMessage(state.selectedProvider))}
                            value={getMessage(state.selectedProvider)}
                            variant="outlined"
                            className={classes.textBox}
                            placeholder="Enter provider message"
                        />
                        <div className={classes.textCounter}>
                            { doctors.doctorList[state.selectedProvider].textLength }/{ maxLength }
                        </div>
                    </>
                ]}
                
            </Box>
        </Grid>
    );
}

