/*
 * @Author: Dongchen Xie
 * @Date: 2020-07-23 02:03:32
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-08-21 03:23:08
 * @Description: file content
 */
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
//useContext
import GlobalContext from "../../../../context/GlobalContext";

const useStyles = makeStyles((theme) => ({
    head: {
        display: 'flex',
    },
    title: {
        paddingTop: "0rem",
        fontWeight: 500,
        display: "block",
        marginRight: "2rem",
        fontSize: "3rem"
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    input: {
        //  marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    invitePopOver: {
        marginTop: "1rem",

    },
    listItem: {

    },
    autoComplete: {
        border: "none"
    },
    listBox: {
        marginTop: "1rem",


    }

}));
const BootstrapInput = withStyles((theme) => ({
    root: {
        width: "100%",
        'label + &': {
            marginTop: theme.spacing(0.4),
        },
        marginLeft: 0,
    },
    input: {
        width: "100%",
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #343434',
        fontSize: 16,
        marginLeft: 0,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            //boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);
// const options = ['Option 1', 'Option 2'];

function App(props) {
    const classes = useStyles()
    const [result, setResult] = React.useState([])
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    // const inputEl = React.useRef(null)
    const global = React.useContext(GlobalContext)
    //clear autocomplete content when the selected org has been changed somewhere else
    const loadClinicOptions = async () => {
        //check if selected org is empty, if so we will use default org as the selected org
        let currentOrg
        if (!global.state.selectedOrg || global.state.selectedOrg == null || JSON.stringify(global.state.selectedOrg) == "{}") {
            currentOrg = global.state.defaultOrg
        } else {
            currentOrg = global.state.selectedOrg
        }
        //get Doctors list
        const resultDoctors = await global.api.getDoctorsByOrgs(currentOrg._id, 1, -1)
        if (resultDoctors && resultDoctors.status == 200 && resultDoctors.data.status != false) {
            setResult(resultDoctors.data.data)
        } else if (resultDoctors && resultDoctors.status == false && resultDoctors.message == "Organization have no doctors") {
            setResult([])
        } else {
            setResult([])
        }
        //
    }
    React.useEffect(() => {
        //console.log("global state:", JSON.stringify(global.state))

        if (global.state.selectedOrg == null) {
            setValue(null)
            setInputValue("")
        } else {
            loadClinicOptions()

        }

    }, [global.state.selectedOrg, global.state.defaultOrg])


    // const handleClick = (event) => {
    //     setAnchorEl(inputEl.current);
    // }
    // const handleClose = () => {
    //     setAnchorEl(null);
    // }
    // const setSelectOrg = (object) => {
    //     global.setState((prev) => {
    //         return { ...prev, selectedOrg: object }
    //     })
    // }
    const handleValueChange = (newValue) => {
        // setSelectOrg(newValue)
        // setInputValue(newValue.org_name)
        props.setSelectedDoctor(newValue)
        setValue(newValue);
    }
    const handleInputValueChange = async (event, newInputValue) => {
        setInputValue(newInputValue);
    }
    // const handleSelectClinic = (clinicObj) => {
    //     //console.log(clinicObj)
    // }
    React.useEffect(()=>{
        props.setSelectedDoctor(null)
    },[])
    // const options = ['Option 1', 'Option 2'];
    return (
        <Autocomplete
            id="custom-input-demo"
            options={inputValue.length == 0 ? [] : result}
            className={classes.input}
            classes={{ paper: classes.listBox }}
            value={props.SelectedDoctor}
            onChange={handleValueChange}
            inputValue={inputValue}
            onInputChange={handleInputValueChange}

            autoHighlight
            noOptionsText={result.length == 0 ? "No doctor in this clinic" : inputValue.length == 0 ? "Oops your search bar is empty.." : "Sorry No Results"}
            getOptionLabel={(option) => option.first_name + " " + option.last_name}
            ListboxComponent={(listboxProps) => (



                <ul
                    {...listboxProps}
                />
            )}
            renderInput={(params) => (

                <BootstrapInput
                    fullWidth
                    //className={classes.input}
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps} />
            )}
            renderOption={(option) => {

                return (
                    <React.Fragment>
                        <Box className={classes.listItem}>

                            <Typography variant="body2" color="#767676">
                                {option.first_name + " " + option.last_name}
                            </Typography>

                        </Box>

                    </React.Fragment>
                )

            }}
        />



    );
}

export default App;