//material UI components and icons
import { Box, Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import AccountProfileStyle from '../../../../styles/AccountProfileStyles';


const SaveEdit = (props) => {
    const classes = AccountProfileStyle()
    // const global = useContext(GlobalContext)
    // const user = global.state.user && global.state.user.document ? global.state.user.document : {}

    return(
        <>
        {props.state.isEdit ?
            <Box>
                {props.state.isLoading ?
                    <Box className={classes.loadingIcon}>
                        <CircularProgress className={classes.loadingCircle} />
                    </Box>
                    :
                    <Box className={classes.saveEditBtn}>
                        <Button className={classes.buttonColor} variant="outlined" color='primary' onClick={() => { props.editCheck(); props.save() }} >
                            Save Changes
                        </Button>
                        <Button className={classes.cancelBtn} variant="outlined" color='primary'  onClick={() => { props.editCheck(); props.cancelTrigger() }} >
                            Cancel
                        </Button>
                    </Box>
                }     
            </Box>
            :
            <Box className={classes.saveEditBtn}>
                <Button className={classes.buttonColor} xs={12} variant="outlined" color='primary' onClick={props.editCheck} >
                    Edit
                </Button>
            </Box>
        }
        </>
    )


}


export default SaveEdit