import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { Collapse, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
//Header components
import OrgSelector from "../../frame/OrgSeletor";
//Default Porton logo
import portonLogo from "../../img/porton.png";
import AccountSetting from "./component/AccountSetting";
//Different subpages
import AppointmentSetting from "./component/AppointmentSetting";
import AppointmentSettingDoctors from "./component/AppointmentSettingDoctors";
import CommunicationSetting from "./component/CommunicationSetting";
import Integrations from "./component/Integrations";
import OperationSetting from "./component/OperationSetting";
import OrgInfoHeader from "./component/OrgInfoHeader";
import RoleManagementSetting from "./component/RoleManagementSetting";




// custom tab styles
const useStyles = makeStyles((theme) => ({
    head: {
        display: "flex",
    },
    title: {
        paddingTop: "1rem",
        fontWeight: 500,
        display: "block",
        marginRight: "2rem",
        fontSize: "2rem",
        '@media (max-width:960px)': { 
            display: 'none',
        },
    },
    appBox: {
        margin: "2rem 0",
    },
}));

function App(props) {
    const global = React.useContext(GlobalContext);
    const classes = useStyles();
    let settingsPermission = global.state.user && global.state.user.document.roles[0] === 'admin';

    useEffect(() => {
        if (settingsPermission) {
            props.setTitle("Select Organization");
        } else {
            props.setTitle("Settings");
        }
    }, []);

    return (
        <Container maxWidth="xl">
            <div>
                <div style={{marginBottom:'2.5%', }}></div>
                {settingsPermission && (<OrgSelector />)}
                {global.state.user &&
                    (global.state.user.document.roles[0] === "admin" ||
                        global.state.user.document.roles[0] === "oa" ||
                        global.state.user.document.roles[0] ===
                            "healthOrgAdmin") && (
                        <div className="orgHeaderTour">
                            <OrgInfoHeader
                                orgId={
                                    global.state.selectedOrg._id
                                        ? global.state.selectedOrg._id
                                        : global.state.defaultOrg._id
                                }
                                defaultOrg={global.state.defaultOrg._id}
                                role={global.state.user.document.roles[0]}
                                logo={
                                    global.state.selectedOrg &&
                                    global.state.selectedOrg.logo
                                        ? global.state.selectedOrg.logo
                                        : global.state.defaultOrg.logo
                                        ? global.state.defaultOrg.logo
                                        : portonLogo
                                }
                                address={
                                    global.state.selectedOrg.address
                                        ? global.state.selectedOrg.address
                                        : ""
                                }
                                phone={
                                    global.state.selectedOrg.contact_info
                                        ? global.state.selectedOrg.contact_info
                                        : ""
                                }
                                website={
                                    global.state.selectedOrg.website
                                        ? global.state.selectedOrg.website
                                        : ""
                                }
                                orgName={
                                    global.state.selectedOrg.org_name
                                        ? global.state.selectedOrg.org_name
                                        : ""
                                }
                                // uploadAwsImage={global.api.UploadFileToAws}
                                deleteAwsImage={global.api.DeleteFileFromAws}
                                updateOrgLogo={global.api.updateOrgLogo}
                                updateGlobalState={global.setState}
                                updateOrgProfileSettigs={
                                    global.api.updateOrgProfileSettigs
                                }
                                removeOrganization={global.api.removeOrg}
                                disabled={
                                    global.state.selectedOrg?._id
                                        ? global.state.selectedOrg.disabled
                                        : global.state.defaultOrg.disabled
                                }
                            />
                        </div>
                    )}
                {global.state.user &&
                    (global.state.user.document.roles[0] === "admin" ||
                        global.state.user.document.roles[0] === "oa" ||
                        global.state.user.document.roles[0] ===
                            "healthOrgAdmin") && (
                        <Box className={classes.appBox}>
                            <Grid item xs={10}>
                            <OperationSetting
                                location={
                                    typeof props.location !== "undefined"
                                        ? props.location
                                        : ""
                                }
                            />
                                </Grid>
                        </Box>
                    )}
                {global.state.user &&
                    (global.state.user.document.roles[0] === "admin" ||
                        global.state.user.document.roles[0] === "oa" ||
                        global.state.user.document.roles[0] ===
                            "healthOrgAdmin") && (
                        <Box className={classes.appBox} >
                            <Grid item xs={10}>
                            <AppointmentSetting />
                            </Grid>
                        </Box>
                    )}

                {global.state.user &&
                    global.state.user.document.roles[0] === "doctor" && (
                        <Box className={classes.appBox}>
                             <Grid item xs={10}>
                            <AppointmentSettingDoctors />
                            </Grid>
                        </Box>
                    )}

                {global.state.user &&
                    (global.state.user.document.roles[0] === "admin" ||
                        global.state.user.document.roles[0] === "oa" ||
                        global.state.user.document.roles[0] ===
                            "healthOrgAdmin") && (
                        <Box className={classes.appBox}>
                            <Grid item xs={10}>
                            <Integrations
                                location={
                                    typeof props.location !== "undefined"
                                        ? props.location
                                        : ""
                                }
                            />
                            </Grid>
                        </Box>
                    )}
                {global.state.user &&
                    (global.state.user.document.roles[0] === "admin" ||
                        global.state.user.document.roles[0] === "oa" ||
                        global.state.user.document.roles[0] ===
                            "healthOrgAdmin") && (
                        <Box className={classes.appBox}>
                            <Grid item xs={10}>
                            <AccountSetting
                                location={
                                    typeof props.location !== "undefined"
                                        ? props.location
                                        : ""
                                }
                            />
                            </Grid>
                        </Box>
                    )}
                {global.state.user &&
                    (global.state.user.document.roles[0] === "admin" ||
                        global.state.user.document.roles[0] === "oa" ||
                        global.state.user.document.roles[0] ===
                            "healthOrgAdmin") && (
                        <Box className={classes.appBox}>
                            <Grid item xs={10}>
                            <CommunicationSetting />
                            </Grid>
                        </Box>
                        
                    )}
                {global.state.user &&
                    (global.state.user.document.roles[0] === "admin" ||
                        global.state.user.document.roles[0] === "oa" ||
                        global.state.user.document.roles[0] ===
                            "healthOrgAdmin") && (
                        <Box className={classes.appBox}>
                            <Grid item xs={10}>
                            <RoleManagementSetting />
                            </Grid> 
                        </Box>
                        
                    )}
            </div>
        </Container>
    );
}

export default App;
