import { InputAdornment, Snackbar, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import LaunchIcon from "@material-ui/icons/Launch";
import clsx from "clsx";
import React, { useEffect } from "react";
import GlobalContext from "../../../../../context/GlobalContext";

export default function UrlComponent(props) {
    const classes = props.classes;
    const state = props.state;
    const setState = props.setState;
    const cancelEditUrl = props.cancelEditUrl;
    const editUrl = props.editUrl;
    const confirmEditUrl = props.confirmEditUrl;
    const urlTextChange = props.urlTextChange;
    const settingsPermission = props.settingsPermission;
    const global = React.useContext(GlobalContext);
    const [bookEnv, setbookEnv] = React.useState(false);

    useEffect(() => {
        let getBookEnvVar = async () => {
            let bookEnvVar;
            try {
                let getEnvVar = await global.api.GetEnvVariable({
                    name: "book-app",
                });
                bookEnvVar = getEnvVar.data.res;
            } catch (error) {
                // console.log(error.response ? error.response.data : error);
            }

            bookEnvVar && setbookEnv(bookEnvVar);
        };
        getBookEnvVar();
    }, []);

    return (
        <Box xs={12} className={classes.paperComponment}>
            <Box className={classes.labelWithEdit}>
                <Typography className={classes.labelSmall}>
                    Portal and Website Integration
                </Typography>
                {settingsPermission && [
                    <Button
                        color="primary"
                        onClick={editUrl}
                        disabled={!state.isEdit}
                    >
                        <Typography variant="subtitle2">
                            EDIT&nbsp;URL
                        </Typography>
                    </Button>,
                ]}
            </Box>
            <Box className={classes.urlTextBox}>
                <TextField
                    className={clsx(
                        classes.urlTextField,
                        classes.disabledBackground
                    )}
                    value={`${bookEnv}/${state.whiteLabelUrl}`}
                    fullWidth
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <LaunchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    className={
                        !state.isEdit
                            ? classes.disabledCopyUrlButton
                            : classes.copyUrlButton
                    }
                    variant="contained"
                    onClick={() => {
                        navigator.clipboard.writeText(
                            `${bookEnv}/${state.whiteLabelUrl}`
                        );
                        setState({ ...state, openSnackbar: true });
                    }}
                >
                    COPY
                </Button>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={state.openSnackbar}
                onClose={() => {
                    setState({ ...state, openSnackbar: false });
                }}
                autoHideDuration={2000}
                message="URL copied to clipboard"
                key={"bottom" + "center"}
            />
            {state.editUrl && [
                <Paper elevation={3} className={classes.smallContainer}>
                    <Box xs={12} className={classes.editUrlBox}>
                        <Typography className={classes.labelSmallerSpacing}>
                            Edit Portal URL
                        </Typography>
                        <Typography
                            className={classes.urlTextDisplay}
                        >{`${bookEnv}/${state.whiteLabelUrl}`}</Typography>
                        <Typography className={classes.labelText}>
                            Room URL
                        </Typography>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            onChange={urlTextChange}
                            value={
                                state.whiteLabelUrl ? state.whiteLabelUrl : ""
                            }
                            variant="outlined"
                            fullWidth
                            className={classes.textBox}
                            placeholder="Enter Url"
                            name="whiteLabelUrl"
                            size="small"
                        />
                        <Grid container justify={"space-between"}>
                            <Button
                                color="primary"
                                className={classes.subButton}
                                onClick={cancelEditUrl}
                                disabled={!state.isEdit}
                            >
                                <Typography variant="body2">CANCEL</Typography>
                            </Button>
                            <Button
                                color="primary"
                                className={classes.subButton}
                                onClick={confirmEditUrl}
                                disabled={!state.isEdit}
                            >
                                <Typography variant="body2">
                                    SAVE CHANGES
                                </Typography>
                            </Button>
                        </Grid>
                    </Box>
                </Paper>,
            ]}
        </Box>
    );
}
