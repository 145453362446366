/*
 * @Author: Vincent Tran
 * @Date: 2020-08-04 04:30:00
 * @LastEditors: Vincent Tran
 * @LastEditTime: 2020-08-05 03:58:00
 * @Description: file content
 */

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import ClearIcon from "@material-ui/icons/Clear";
import { get } from "lodash";
import moment from "moment";
import React from "react";
import GlobalContext from "../../../../../context/GlobalContext";
import BootstrapInput from "../../../../../styles/BootstrapInput";
import combineStyles from "../../../../../styles/combineStyles";
import popupStyles from "../../../../../styles/PopupStyle";
import UserPageStyles from "../../../../../styles/usersPageStyles";
import { TextMaskDate, TextMaskPhoneSimple } from "../../TextMask";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// combine specific styles created here in useStyles and general styles from PopupStyles.js
// NOTE: Include styles in params from general to specific
const combinedStyles = combineStyles(popupStyles, UserPageStyles);

export default function EditPatientProfile(props) {
  const global = React.useContext(GlobalContext);
  const orgId = global.state.selectedOrg._id
    ? global.state.selectedOrg._id
    : global.state.defaultOrg._id;
  // temporary default values..
  // Real values should be pulled from database in the future
  const userInfo = {
    fName: props.account.firstname || props.account.first_name,
    lName: props.account.lastname || props.account.last_name,
    address: props.account.address || "",
    provider:
      props.account.emrData && props.account.emrData[orgId]
        ? props.account.emrData[orgId].primaryProviderEmrId
        : "",
    hin: props.account.hin || "",
    dob: props.account.birthdate
      ? moment(props.account.birthdate).format("YYYY-MM-DD")
      : "",
    email: get(props, "account.emails[0].address", ""),
    phone: props.account.phone || "",
    sex: props.account.gender
      ? props.account.gender.toLowerCase()
      : "undefined",
    bType: props.account.blood || "",
    height: props.account.height || null,
    weight: props.account.weight || null,
    allergies: props.account.allergy || "",
    famHistory: props.account.fhistory || "",
  };
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({ ...userInfo });

  React.useEffect(() => {
    // fetch the Patient HIN from OSCAR using demoNo, since it is not stored in portonDB
    if (!userInfo.hin) {
      if (!props.account.emrData[orgId].emrId) {
        alert("Patient registration not complete"); // temp fix for black screen until invite patient registration/pending users is implemented
        return;
      }
      global.api
        .getEmrPatientByDemoNo(orgId, props.account.emrData[orgId].emrId)
        .then((res) => {
          // In future could use this to add extra fields to the patient profile
          userInfo.hin = res.data.hin;
          setState(userInfo);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSave = async () => {
    // save user profile info to database here...
    console.log(
      global.state.selectedOrg._id
        ? global.state.selectedOrg._id
        : global.state.defaultOrg._id
    );
    let result = await global.api.updateAccount({
      id: props.account._id,
      updateOscar: true,
      firstname: state.fName,
      lastname: state.lName,
      contact_name: state.fName + " " + state.lName,
      birthdate: state.dob,
      emails: { address: state.email, verified: true },
      phone: state.phone.replace(/\s/g, ""),
      gender: state.sex,
      blood: state.bType,
      height: state.height,
      weight: state.weight,
      allergy: state.allergies,
      fhistory: state.famHistory,
      hin: state.hin,
      emrData: props.account.emrData
        ? {
            ...props.account.emrData,
            [orgId]: {
              ...props.account.emrData[orgId],
              primaryProviderEmrId: state.provider,
            },
          }
        : {},
      profileSet: true,
      role: "patient",
      address: state.address,
      orgId: global.state.selectedOrg._id
        ? global.state.selectedOrg._id
        : global.state.defaultOrg._id,
    });
    if (result && result.status === 200) {
      handleClose();
      props.refresh();
    } else {
      alert("Unable to update profile.");
    }
  };

  const classes = combinedStyles();
  return (
    <div>
      <ButtonBase className={props.className} onClick={handleClickOpen}>
        {" "}
        <Typography className={classes.typography}>Edit Profile</Typography>
      </ButtonBase>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="alert-dialog-slide-title"
        >
          Edit Patient Information
          <IconButton
            onClick={handleClose}
            className={classes.dialogClose}
            children={<ClearIcon />}
          />{" "}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container>
            <Grid container spacing={3} className={classes.row}>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="fName-input"
                >
                  First Name
                </InputLabel>
                <BootstrapInput
                  disabled
                  required
                  fullWidth
                  name="fName"
                  value={state.fName}
                  id="fName-input"
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="lName-input"
                >
                  Last Name
                </InputLabel>
                <BootstrapInput
                  disabled
                  required
                  fullWidth
                  name="lName"
                  value={state.lName}
                  id="lName-input"
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="address-input"
                >
                  Address
                </InputLabel>
                <BootstrapInput
                  required
                  fullWidth
                  name="address"
                  value={state.address}
                  id="address-input"
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="provider-input"
                >
                  Primary Provider
                </InputLabel>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.dropdown}
                >
                  <Select
                    fullWidth
                    name="provider"
                    value={state.provider}
                    onChange={(e) => handleChange(e)}
                    input={<BootstrapInput />}
                    id="provider-input"
                  >
                    {props.doctors &&
                      props.doctors.length &&
                      props.doctors.map((doctor, i) => (
                        <MenuItem key={i} value={doctor.providerNo}>
                          {doctor.firstName} {doctor.lastName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.row}>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="hin-input"
                >
                  Health Insurance Number
                </InputLabel>
                <BootstrapInput
                  disabled
                  fullWidth
                  name="hin"
                  value={state.hin}
                  id="hin-input"
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="dob-input"
                >
                  Date of Birth
                </InputLabel>
                <BootstrapInput
                  disabled
                  fullWidth
                  name="dob"
                  type="date"
                  value={state.dob}
                  id="dob-input"
                  onChange={(e) => handleChange(e)}
                  inputComponent={TextMaskDate}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="email-input"
                >
                  Email
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  name="email"
                  value={state.email}
                  id="email-input"
                  placeholder={"patient@email.com"}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="phone-input"
                >
                  Mobile Number
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  name="phone"
                  value={state.phone}
                  id="phone-input"
                  onChange={(e) => handleChange(e)}
                  inputComponent={TextMaskPhoneSimple}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.row}>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="sex-input"
                >
                  Sex
                </InputLabel>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.dropdown}
                >
                  <Select
                    disabled
                    fullWidth
                    name="sex"
                    value={state.sex}
                    onChange={(e) => handleChange(e)}
                    input={<BootstrapInput />}
                    id="sex-input"
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="transgendered">Transgendered</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                    <MenuItem value="undefined">Prefer not to answer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="bType-input"
                >
                  Blood Type
                </InputLabel>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.dropdown}
                >
                  <Select
                    fullWidth
                    name="bType"
                    value={state.bType}
                    onChange={(e) => handleChange(e)}
                    input={<BootstrapInput />}
                    id="bType-input"
                  >
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                    <MenuItem value="AB">AB</MenuItem>
                    <MenuItem value="O">O</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="height-input"
                >
                  Height
                </InputLabel>
                <BootstrapInput
                  type="number"
                  fullWidth
                  name="height"
                  value={state.height}
                  id="height-input"
                  onChange={(e) => handleChange(e)}
                  endAdornment={
                    <InputAdornment position="start">cm</InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="weight-input"
                >
                  Weight
                </InputLabel>
                <BootstrapInput
                  type="number"
                  fullWidth
                  name="weight"
                  value={state.weight}
                  id="weight-input"
                  onChange={(e) => handleChange(e)}
                  endAdornment={
                    <InputAdornment position="start">kg</InputAdornment>
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="allergies-input"
                >
                  Allergies
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  multiline
                  rows={4}
                  name="allergies"
                  value={state.allergies}
                  id="allergies-input"
                  className={classes.textArea}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  className={classes.formLabel}
                  shrink
                  htmlFor="famHistory-input"
                >
                  Family History
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  multiline
                  rows={4}
                  name="famHistory"
                  value={state.famHistory}
                  id="famHistory-input"
                  className={classes.textArea}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.actionBtn}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            className={classes.actionBtn}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
