import { Box, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import React from "react";




export default function PatientUpdateInfo(props) {
    const {
        classes,
        isEditPressed,
        updateInformation,
        handleUpdateInformationChange,
    } = props;
    const {
        enabled,
        city,
        email,
        postal,
        province,
        address,
        phone,
    } = updateInformation;

    return (
        <div style={{ padding: "0 25px" }}>
            <Box className={classes.labelWithEdit} style={{ width: "100%" }}>
                <Typography className={classes.labelSmall}>
                    Patient Information Updating
                </Typography>
                <Switch
                    className={classes.switch}
                    size="small"
                    checked={enabled}
                    onChange={handleUpdateInformationChange}
                    color={!isEditPressed ? "#767676" : "primary"}
                    name="enabled"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    disabled={!isEditPressed}
                />
            </Box>

            <Grid container item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color={!isEditPressed ? "#767676" : "primary"}
                            checked={address}
                            onChange={handleUpdateInformationChange}
                            name="address"
                            disabled={!enabled}
                        />
                    }
                    label={
                        <Typography className={classes.typography}>
                            Address
                        </Typography>
                    }
                />
            </Grid>

            <Grid container item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color={!isEditPressed ? "#767676" : "primary"}
                            checked={city}
                            onChange={handleUpdateInformationChange}
                            name="city"
                            disabled={!enabled}
                        />
                    }
                    label={
                        <Typography className={classes.typography}>
                            City
                        </Typography>
                    }
                />
            </Grid>

            <Grid container item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color={!isEditPressed ? "#767676" : "primary"}
                            checked={province}
                            onChange={handleUpdateInformationChange}
                            name="province"
                            disabled={!enabled}
                        />
                    }
                    label={
                        <Typography className={classes.typography}>
                            Province
                        </Typography>
                    }
                />
            </Grid>

            <Grid container item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color={!isEditPressed ? "#767676" : "primary"}
                            checked={postal}
                            onChange={handleUpdateInformationChange}
                            name="postal"
                            disabled={!enabled}
                        />
                    }
                    label={
                        <Typography className={classes.typography}>
                            Postal Code
                        </Typography>
                    }
                />
            </Grid>

            <Grid container item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color={!isEditPressed ? "#767676" : "primary"}
                            checked={email}
                            onChange={handleUpdateInformationChange}
                            name="email"
                            disabled={!enabled}
                        />
                    }
                    label={
                        <Typography className={classes.typography}>
                            Email
                        </Typography>
                    }
                />
            </Grid>

            <Grid container item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color={!isEditPressed ? "#767676" : "primary"}
                            checked={phone}
                            onChange={handleUpdateInformationChange}
                            name="phone"
                            disabled={!enabled}
                        />
                    }
                    label={
                        <Typography className={classes.typography}>
                            Phone Number
                        </Typography>
                    }
                />
            </Grid>
        </div>
    );
}
