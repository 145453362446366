/*
 * @Author: Andy Qiu
 * @Date: 2020-10-30 11:50:12
 * @LastEditors: Dongchen Xie
 * @LastEditTime: 2020-12-07 14:26:53
 * @Description: remove patient from queue modal
 */

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import ContactForm from "./ContactForm";
import EditForm from "./EditForm";
import Appointment, {AppointmentTypes} from "../../../models/Appointment";

const useStyles = makeStyles({
  text: {
    display: "inline-block",
    width: "100px",
    color: "black",
    fontWeight: "500",
  },
});

export default function PatientInfo({
  onClose,
  selectedValue,
  open,
  populatedValues,
  loading,
  doctors,
  refreshQueue
}) {
  const classes = useStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  const handleClose = () => {
    onClose();
    setOpenEdit(false);
    setOpenContact(false);
  };

  let appointment = new Appointment(selectedValue)

  function getDoctorName(post) {
    if (doctors) {
      const doctor = doctors.find((x) => x.providerNo === String(post));
      if (doctor !== null) {
        return `Dr. ${doctor.firstName} ${doctor.lastName}`;
      }
    }
  }

  return (
    <div>
      <Dialog open={open}>
        {loading ? (
          <DialogContent>
            <CircularProgress style={{ margin: "10px", color: "#1766b3" }} />{" "}
          </DialogContent>
        ) : (
          <>
            <DialogTitle style={{ padding: '15px 15px' }}>
              <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item xs={11}>
                  {appointment.patientName}
                </Grid>
                <Grid item xs={1}>
                  <DialogActions>
                    <IconButton onClick={handleClose} edge="end" size="small">
                      <CloseIcon style={{ color: "#000000" }} />
                    </IconButton>
                  </DialogActions>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent style={{ padding: '15px 15px' }}>
              <DialogContentText>
                <span className={classes.text}>HIN:</span>{" "}
                {populatedValues?.hin ? populatedValues.hin : "N/A"}
              </DialogContentText>
              <DialogContentText>
                <span className={classes.text}>Email:</span>{" "}
                {populatedValues?.email ? populatedValues.email : "N/A"}
              </DialogContentText>
              <DialogContentText>
                <span className={classes.text}>Phone:</span>{" "}
                {populatedValues?.phone ? populatedValues.phone : "N/A"}
              </DialogContentText>
              {
                appointment.type === AppointmentTypes.TELEPHONE && appointment.patientPhone &&
                <DialogContentText>
                  <span className={classes.text}>Appointment Phone:</span>{" "}
                  {appointment.patientPhone}
                </DialogContentText>
              }
              <DialogContentText>
                <span className={classes.text}>Date:</span>{" "}
                {appointment.appointmentDate}
              </DialogContentText>
              <DialogContentText>
                <span className={classes.text}>Time:</span>{" "}
                {appointment.startTime}
              </DialogContentText>
              <DialogContentText>
                <span className={classes.text}>Provider:</span>{" "}
                {getDoctorName(appointment.providerNo)
                  ? getDoctorName(appointment.providerNo)
                  : "N/A"}
              </DialogContentText>
              <DialogContentText>
                <span className={classes.text}>Status:</span> {appointment.status}
              </DialogContentText>
              <DialogContentText>
                <span className={classes.text}>Reason:</span>{" "}
                {appointment.reason ? appointment.reason : "N/A"}
              </DialogContentText>
              <DialogContentText>
                <span className={classes.text}>Comments:</span>{" "}
                {appointment.comment ? appointment.comment : "N/A"}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: '15px 15px' }}>
              <Button color="primary" onClick={() => {setOpenEdit(true); onClose();}}>
                Edit Appointment
              </Button>
              <Button color="primary" onClick={() => {setOpenContact(true); onClose();}}>
                Contact
              </Button>
              {/*
                        <Button color="primary" onClick={() => setOpenContact(true)}>
                        Contact
                    </Button>
                    <Button color="primary">
                        Call In
                    </Button>
                        */}
            </DialogActions>
          </>
        )}
      </Dialog>

      <EditForm post={selectedValue} open={openEdit} close={handleClose} refreshQueue={refreshQueue}/>
      <ContactForm open={openContact} onClose={handleClose} patientName={appointment.patientName} contactInfo={populatedValues}/>
    </div>
  );
}
